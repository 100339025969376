// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { compose } from '../../core';
// import { employeeList } from '../../feedback/containers/feedbackOperations'
import { withCreateMeeting} from '../containers/One_on_oneOperations';
import { Row, Col, Button,Spin,Input,Form ,Popover, Modal  } from "antd";
import {getIntFromString,scrolltoview } from "../../look";
import MultiSelectEmployee from './MultiSelectEmployee'
import { withApollo } from '@apollo/client/react/hoc';
import { GET_MANAGER_EMPLOYEE_LIST_SEARCH } from '../graphql/getEmployeSerach.gql';
import Instant_icon from '../../assets/instant-icon.svg'
import Meeting_close from '../../assets/agenda-close.svg'
import Meeting_title from '../../assets/meeting-title-icon.svg'
import { InfoCircleOutlined } from '@ant-design/icons';
const Quick1_1 = props => {
    const {me,createMeeting,onClose,client}=props
    const [title, setTitle] = React.useState();
    const [titleInvalid, settitleInvalid] = React.useState(false);
    const [employeeList,setemployeeList]= React.useState([]);
    const [memberlist,setmemberlist]= React.useState([]);
    // eslint-disable-next-line no-unused-vars
    const [memberListInvalid, setmemberListInvalid] = React.useState(false);
    const [loading, setloading] = React.useState(false); 
    const [form] = Form.useForm();
    const [visible,setVisible]=React.useState(false)
    const [enableButton,setEnableButton]=React.useState(false)
      React.useEffect(()=>{
        if(me){
          AllEmployeeList()
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[me])
      const AllEmployeeList= async(searchValue)=>{
        const { data } = await client.query({
          query: GET_MANAGER_EMPLOYEE_LIST_SEARCH,
          variables: {user_LastName: searchValue,user_FirstName: searchValue},
          fetchPoicy:'network-only'
          });
          let lastFilter
          let firstFilter
          if(data?.first){
            let allEmpoloyee =data?.first
            if(allEmpoloyee){
              let list
              let teamMembersEmployee = [].concat.apply(
                [],allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({node})=>node?.managers?.edges.map(({node})=>node?.user)))
              let teamManager= [].concat.apply(
                [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.vertical?.heads?.edges.map(({node})=>node?.user)))
              let teamManagerEmployee= [].concat.apply(
                  [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.employees?.edges.map(({node})=>node?.user)))
              let orgPocEmployee = [].concat.apply(
                  [],allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              let orgCeoEmployee=[].concat.apply(
                [],allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              let orgVerticalEmployee=[].concat.apply(
                [],allEmpoloyee?.employee?.verticalHeadEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              // let verticalMembersEmployee=[].concat.apply(
              //   [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
                let verticalHeadEmployee=[].concat.apply(
                  [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.heads.edges.map(({node})=>node.user)))
              list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee,orgVerticalEmployee,verticalHeadEmployee)
              let data =list.filter((value, index, self) =>
              index === self.findIndex((t) => (
                t.firstName === value.firstName && t.id === value.id
              )))
              firstFilter = data.filter(item=>item?.employee?.id!==me?.employee?.id)
            }
          }
          if(data?.last){
            let allEmpoloyee=data?.last
            if(allEmpoloyee){
              let list
              let teamMembersEmployee = [].concat.apply(
                [],allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({node})=>node?.managers?.edges.map(({node})=>node?.user)))
              let teamManager= [].concat.apply(
                [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.vertical?.heads?.edges.map(({node})=>node?.user)))
              let teamManagerEmployee= [].concat.apply(
                  [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.employees?.edges.map(({node})=>node?.user)))
              let orgPocEmployee = [].concat.apply(
                  [],allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              let orgCeoEmployee=[].concat.apply(
                [],allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              let orgVerticalEmployee=[].concat.apply(
                [],allEmpoloyee?.employee?.verticalHeadEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              // let verticalMembersEmployee=[].concat.apply(
              //   [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
              let verticalHeadEmployee=[].concat.apply(
                [],allEmpoloyee?.employee?.verticalMembersEmployee?.edges.map(({node})=>node?.heads.edges.map(({node})=>node.user)))
              list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee,orgVerticalEmployee,verticalHeadEmployee)
              let data =list.filter((value, index, self) =>
              index === self.findIndex((t) => (
                t.firstName === value.firstName && t.id === value.id
              )))
              lastFilter = data.filter(item=>item?.employee?.id!==me?.employee?.id)
            }
            let user =firstFilter.concat(lastFilter)
            var setObj = new Set(); // create key value pair from array of array
            var result = user.reduce((acc,item)=>{
              if(!setObj.has(item?.employee?.id)){
                setObj.add(item?.employee?.id)
                acc.push(item)
              }
              return acc;
            },[])
            
            setemployeeList(result)
          }   
      }
      
      
      const Capitalize = (value) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : '';
      const handleMenuClick=(members)=>{
        setmemberlist(members)
        if(members?.length){
          setTitle(`${Capitalize(members[0]?.firstName)} and ${Capitalize(me?.firstName)}'s 1:1`)
          setEnableButton(true)

        }
    
      }

      const handleSubmit=async()=>{
        if(title && memberlist?.length){
        setloading(true)
          settitleInvalid(false)
          setmemberListInvalid(false)
          let meetingData= {
            title:title,
            meetingType: "One-On-One",
            when: new Date().toISOString(),
            scheduleDuration:60,
            isInstant:true
          }
          let members = memberlist.map(user=>getIntFromString(user?.employee?.id))
          members.push(getIntFromString(me?.employee?.id))
          const response = await createMeeting({members:members,meetingData:meetingData});
          if(response && response?.id){
            setloading(false)
            onClose({close:true,meeting:response?.id})
          }else{
            setloading(false)
          }
        }
        else{
          if(!title){
            settitleInvalid(true)
          }
          if(!memberlist?.length){
            setmemberListInvalid(true)
          }
        }
      }


      const handleTitleSubmit=(e)=>{
        let title =e.target.value
        if(title.length>=200){
          setVisible(true)
          settitleInvalid(true)
        }
        else{
          setVisible(false)
          settitleInvalid(false)
        }
        setTitle(e.target.value)
      }

      const hide=()=>{
        setVisible(false)
      }

      const searchInput=(value)=>{
          AllEmployeeList(value)
      }
  const [visible1, setVisible1] = React.useState(false);
  const showModal1 = () => {
    setVisible1(true);
  };
  const handleCancel1 = () => {
    setVisible1(false);
  };

      
      return (
          <>
            <div className='meeting-instant-popup'>
              <Row justify='center' align='center' style={{width:"100%",position:"relative"}}>
              <h4 className='instant-meeting-head' style={{fontSize:'20px',textAlign:"center"}}><img src={Instant_icon} alt=''/> INSTANT 1:1       <InfoCircleOutlined
        onClick={showModal1}
        style={{
          cursor: 'pointer',
          fontSize: '24px',
          color: '#1890ff',
          transition: 'color 0.3s ease',
        }}
        onMouseOver={(e) => e.target.style.color = '#40a9ff'}
        onMouseOut={(e) => e.target.style.color = '#1890ff'}  /></h4>

<Modal
        title="Instant 1 on 1 guide"
        visible={visible1}
        onCancel={handleCancel1}
        footer={null}
        closable={true}
        width={800}
      >
        <img src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1737544539/MApp/UI/Videos/Learning%20Paths/1%20on%201/Instant_1_on_1_veq4tp.gif" alt="instant demo"  style={{width:"100%"}}/>
      </Modal>
              <img src={Meeting_close} alt="" onClick={()=>{onClose({close:true})}} style={{position:"absolute",right:'10px',top:"0px",cursor:"pointer"}}/>
              {/* <Button shape="circle"  style={{marginLeft:'20px'}} onClick={()=>{onClose({close:true})}}>
              <CloseOutlined />
              </Button> */}
              </Row>
              <div className='meeting-instant-popup-inner'>
                <Form
                form={form}
                name={"1:1"}
                onFinish={() =>{handleSubmit()} }
                >
                    <Col span={24}>
                    <p className='instant-mmeting-form-title'><span style={{color:'#ff4d4f'}}>*</span>Participant</p>
                        {/* <Dropdown overlay={menu}>
                            <DropdownButton id="1_1_member" style={{border: !memberListInvalid?'1px solid #CACACA':'1px solid #ff4d4f', }} onClick={()=>{scrolltoview('1_1_member')}}>
                        
                                <p style={{marginTop:'10px',width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left'}}>{memberlist?.length?memberName():'Select member'}</p>
                                <DownOutlined />
                                
                            </DropdownButton>
                          </Dropdown> */}
                        <MultiSelectEmployee
                        fromQuickMeeting = {true}
                          dataList = {employeeList}
                          onChange = {(e)=>{handleMenuClick(e)}}
                          searchKey={e=>searchInput(e)}
                        />
                    </Col>
                    <Col span={24} style={{marginTop:'25px'}}>
                          <p className='instant-mmeting-form-title' ><span style={{color:'#ff4d4f'}}>*</span> Title</p>
                          <Popover
                            content={<a onClick={hide}>Close</a>}
                            title="Character Limit Exceeded"
                            trigger="click"
                            visible={visible}
                            placement="topRight"
                          >
                          <div style={{position:"relative",display:'flex',alignItems:'center'}}>
                              <Input
                                // disabled={memberlist?.length?false:true}
                                style={{
                                  border: !titleInvalid?'1px solid #CACACA':'1px solid #ff4d4f',    
                                  paddingLeft:'37px'           
                                }}
                                id="1_1_title_name"
                                value={title}
                                maxLength={200}

                                // onChange={(e) => (setTitle(e.target.value))}
                                onChange={(e) =>handleTitleSubmit(e)}    
                                onClick={(e)=>{scrolltoview('1_1_title_name')}}
                                className='one-on-one-meeting-title-input'
                                placeholder='Title of your 1:1'
                                autoComplete='off'
                              />
                              <img className='instant-meeting-form-icon' src={Meeting_title} alt="" style={{position:"absolute",left:"12px",bottom:'17px'}} />
                            </div>
                          </Popover>
                      </Col>
                      <div align="center">
                        {
                            !loading?
                                <Button className='instant-start-btn' htmlType="submit" disabled={!enableButton}>Start 1:1</Button>
                            :
                            <Button className='instant-start-btn'>
                                <Spin spinning={true}>

                                </Spin>
                            </Button>
                        }
                    </div>
                </Form>
              </div>
            </div>
          </>
      )
}

export default compose(withCreateMeeting,withApollo)(Quick1_1);
import { Button, Dropdown } from "antd";
import React from "react";


const CustomEmployeeFilterView = (props) => {
    const { verticals, teams, user_types, status_list, getTeamList, filter } = props
    const [dropdownVisible, setDropDownVisible] = React.useState(false)
    const [selected_verticals, setSelectedVerticals] = React.useState([])
    const [selected_teams, setSelectedTeams] = React.useState([])
    const [selected_user_types, setSelectedUserTypes] = React.useState([])
    const [selected_status, setSelectedStatus] = React.useState([])

    const selectVertical = (id) => {
        let ids = [...selected_verticals]
        if (ids.includes(id)) {
            let index = ids.indexOf(id)
            ids.splice(index, 1)
        }
        else {
            ids.push(id)
        }

        if (ids?.length === 0) {
            setSelectedTeams([])
        }
        getTeamList({ id: id, id_list: ids });
        setSelectedVerticals(ids)

    }
    const selectTeam = (id) => {
        let ids = [...selected_teams]
        if (ids.includes(id)) {
            let index = ids.indexOf(id)
            ids.splice(index, 1)
        }
        else {
            ids.push(id)
        }
        setSelectedTeams(ids)
    }
    const selectUserType = (key) => {
        let keys = [...selected_user_types]
        if (keys.includes(key)) {
            let index = keys.indexOf(key)
            keys.splice(index, 1)
        }
        else {
            keys.push(key)
        }
        setSelectedUserTypes(keys)
    }
    const selectedStatus = (key) => {
        let keys = [...selected_status]
        if (keys.includes(key)) {
            let index = keys.indexOf(key)
            keys.splice(index, 1)
        }
        else {
            keys.push(key)
        }
        setSelectedStatus(keys)
    }
    const clearFilter = () => {
        setSelectedVerticals([])
        setSelectedTeams([])
        setSelectedUserTypes([])
        setSelectedStatus([])
        setDropDownVisible(false)
        filter({
            status:[],
            teams:[],
            user_types:[],
            verticals:[]
        })
    }
    const onFilter = () => {
        filter({
            verticals: selected_verticals,
            teams: selected_teams,
            user_types: selected_user_types,
            status: selected_status
        })
        setDropDownVisible(false)
    }
    const filterMenu = () => (
        <>
            <div className="custom-filter-container">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', gap: "1em", width: "100%" }}>
                    <h4 className="filter-title">Filter</h4>
                    {
                        (selected_verticals?.length || selected_teams?.length || selected_user_types?.length || selected_status?.length) ?
                        <Button className="clear-filter-btn" onClick={() => clearFilter()}>Clear filter</Button>:null
                    }
                    
                </div>
                <div style={{ display: 'flex', gap: "1em", justifyContent: "start", alignItems: "start", width: "fit-content", paddingTop: ".5em" }}>
                    {verticals?.length > 0 && <div>
                        <h4 className="filter-title" style={{ fontWeight: "500" }}>Vertical {selected_verticals?.length > 0 ? `/${selected_verticals?.length}` : ''} </h4>
                        <div className="custom-filter-scroll" style={{ maxHeight: "10em", overflow: 'auto', paddingRight: "10px" }}>
                            {verticals?.map(vertical => (
                                <div className={`filter-data-container ${selected_verticals?.includes(vertical?.id) ? 'filter-data-container-selected' : ''}`} onClick={() => selectVertical(vertical?.id)}>
                                    <h4 style={{ margin: 0, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "10em", fontWeight: "400", color: selected_verticals?.includes(vertical?.id) ? '#fff' : '#000', fontSize: ".9em" }}>{vertical?.name}</h4>
                                    <h4 style={{ margin: 0, color: selected_verticals?.includes(vertical?.id) ? '#fff' : '#000', fontWeight: "400", fontSize: ".9em" }}>{vertical?.teamSet?.totalCount}</h4>
                                </div>

                            ))}
                        </div>
                    </div>}
                    {teams?.length > 0 && <div>
                        <h4 className="filter-title" style={{ fontWeight: "500" }}>Team</h4>
                        <div className="custom-filter-scroll" style={{ maxHeight: "10em", overflow: 'auto', paddingRight: "10px" }}>
                            {teams?.map(team => (
                                <div className={`filter-data-container ${selected_teams?.includes(team?.id) ? 'filter-data-container-selected' : ''}`} onClick={() => selectTeam(team?.id)}>
                                    <h4 style={{ margin: 0, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "auto", fontWeight: "400", color: selected_teams?.includes(team?.id) ? '#fff' : '#000', fontSize: ".9em" }}>{team?.name}</h4>
                                </div>
                            ))}
                        </div>
                    </div>}
                    <div>
                        <h4 className="filter-title" style={{ fontWeight: "500" }}>Position</h4>
                        <div className="custom-filter-scroll" style={{ maxHeight: "10em", overflow: 'auto', paddingRight: "10px" }}>
                            {user_types?.map(user_type => (
                                <div className={`filter-data-container ${selected_user_types?.includes(user_type?.key) ? 'filter-data-container-selected' : ''}`} onClick={() => selectUserType(user_type?.key)}>
                                    <h4 style={{ margin: 0, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "auto", fontWeight: "400", color: selected_user_types?.includes(user_type?.key) ? '#fff' : '#000', fontSize: ".9em" }}>{user_type?.title}</h4>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h4 className="filter-title" style={{ fontWeight: "500" }}>Status</h4>
                        <div className="custom-filter-scroll" style={{ maxHeight: "10em", overflow: 'auto', paddingRight: "10px" }}>
                            {status_list?.map(status => (
                                <div className={`filter-data-container ${selected_status?.includes(status?.key) ? 'filter-data-container-selected' : ''}`} style={{ justifyContent: "start" }} onClick={() => selectedStatus(status?.key)}>
                                    <div style={{ width: ".65em", height: ".65em", background: status?.color }}></div>
                                    <h4 style={{ margin: 0, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "10em", fontWeight: "400", color: selected_status?.includes(status?.key) ? '#fff' : '#000', fontSize: ".9em", textAlign: "left" }}>{status?.title}</h4>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                    <Button className="poc-active-btn" style={{ marginTop: "1em", fontSize: "1em", fontWeight: "500" }} onClick={() => onFilter()}>Apply</Button>
                </div>
            </div>
        </>
    )
    return (
        <>
            <Dropdown visible={dropdownVisible} overlay={filterMenu} placement={"bottomRight"}>
                <Button className="filter-btn" onClick={() => setDropDownVisible(!dropdownVisible)}>Filter</Button>
            </Dropdown>
            {dropdownVisible ? (<div className='overlay-dynamic-island' onClick={() => { setDropDownVisible(false) }} />) : null}
        </>
    )
}

export default CustomEmployeeFilterView
import React from 'react';
import { Row, Col, Form } from 'antd';
import moment from 'moment-timezone';

import {
  TextField,
  OrganizationField,
  VerticalField,
  TeamField,
  getIntFromString,
  ButtonsComponent,
  CheckBoxField,
  DatePickerField,
  CKEditorField
} from '../../look';

export const CKEditorFieldName = ['surveyData', 'description'];
export const CKEditorFieldName1 = ['surveyData', 'termsConditions'];

const SurveyFormComponent = props => {
  const { SurveyById, onSubmit } = props;
  const [button, setButton] = React.useState('');
  const initialValue = {
    surveyData: {
      name: SurveyById && SurveyById.name,
      description: SurveyById && SurveyById.description,
      termsConditions: SurveyById && SurveyById.termsConditions,
      shuffle: SurveyById && SurveyById.shuffle,
      reportQuery: SurveyById && SurveyById.reportQuery,
      public: SurveyById && SurveyById.public,
      activeFrom:
        (SurveyById && SurveyById.activeFrom && moment.utc(SurveyById.activeFrom).local()) ||
        moment(new Date(), 'YYY-MM-DD'),
      activeTo:
        (SurveyById && SurveyById.activeTo && moment.utc(SurveyById.activeTo).local()) || moment(new Date(), 'YYY-MM-DD')
    },
    organizations:
      SurveyById &&
      SurveyById.organization &&
      SurveyById.organization.edges &&
      SurveyById.organization.edges.map(({ node }) => Number(getIntFromString(node.id))),
    verticals:
      SurveyById &&
      SurveyById.vertical &&
      SurveyById.vertical.edges &&
      SurveyById.vertical.edges.map(({ node }) => Number(getIntFromString(node.id))),
    teams:
      SurveyById &&
      SurveyById.team &&
      SurveyById.team.edges &&
      SurveyById.team.edges.map(({ node }) => Number(getIntFromString(node.id)))
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          layout="vertical"
          onFinish={values => onSubmit(values, button)}
          initialValues={initialValue}
          required={false}
          scrollToFirstError={true}
        >
          <Row>
            <Col span={10} offset={1}>
              <Row>
                <Col lg={20} md={20}>
                  <TextField
                    name={['surveyData', 'name']}
                    label="Name"
                    rules={[{ required: true, message: 'name is required!', whitespace: true }]}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <OrganizationField
                    mode="multiple"
                    name="organizations"
                    label="Organizations"
                    offset={1}
                    // rules={[{ required: true, message: 'Organization is required!' }]}
                    onAdd
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <DatePickerField
                    margin={false}
                    name={['surveyData', 'activeFrom']}
                    label="Active From"
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    rules={[{ type: 'object', /* required: true, */ message: 'Please select time!' }]}
                  // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <CheckBoxField name={['surveyData', 'shuffle']} label="Shuffle" />
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <TextField
                    name={['surveyData', 'reportQuery']}
                    label="Report Query"
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={10} md={13} xs={24}>
              <Row>
                <Col span={24}>
                  <VerticalField
                    mode="multiple"
                    name="verticals"
                    label="Verticals"
                    offset={1}
                  // rules={[{ required: true, message: 'vertical is required!' }]}
                  // onAdd
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <TeamField
                    mode="multiple"
                    name="teams"
                    label="Teams"
                    offset={1}
                  // rules={[{ required: true, message: 'teams is required!' }]}
                  // onAdd
                  />
                </Col>
              </Row>
              <Row>
                <Col span={17}>
                  <DatePickerField
                    margin={false}
                    name={['surveyData', 'activeTo']}
                    label="Active To"
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    offset={2}
                    rules={[
                      { type: 'object', /* required: true, */ message: 'Please select time!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            (getFieldValue(['surveyData', 'activeFrom']) &&
                              moment(value).isAfter(getFieldValue(['surveyData', 'activeFrom'])))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Please select Valid time!'));
                        }
                      })
                    ]}
                  // rules={[{ required: true, message: 'Active To is required!', whitespace: true }]}
                  // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <CheckBoxField name={['surveyData', 'public']} label="Public" />
                </Col>
              </Row>
            </Col>
            <Col lg={3} md={0} xs={0} />
            <Col span={22} offset={1}>
              <CKEditorField
                name={CKEditorFieldName}
                label="Description"
                initialValue={SurveyById && SurveyById.description}
                offset={0}
              // rules={[{ required: true, message: 'All questions are required!' }]}
              />
            </Col>
            <Col span={22} offset={1}>
              <CKEditorField
                name={CKEditorFieldName1}
                label="Terms Conditions"
                initialValue={SurveyById && SurveyById.termsConditions}
                offset={0}
              // rules={[{ required: true, message: 'All questions are required!' }]}
              />
            </Col>
          </Row>
          {/* <MarkdownField
            name={['surveyData', 'description']}
            label="Description"
            initialValue={SurveyById && SurveyById.description}
            // rules={[{ required: true, message: 'All questions are required!' }]}
          /> */}
          <ButtonsComponent setButton={setButton} />
        </Form>
      </Col>
    </Row>
  );
};

export default SurveyFormComponent;

import React from 'react';
import RoleDefinitionSearchView from './RoleDefinitionSearchView';
import { competency_icons, role_icons } from '../icon';
import { getIntFromString } from 'modules/look';
import { useHistory } from 'react-router-dom';
import { Modal, Popover, Select, Spin } from 'antd';
import RoleDefinitionConfirmation from '../../look-v2/Components/ConfirmationDialog';
import RoleDefinitionpagination from './roleDefinitionPagination';
import CreateAssignEmployeeContainer from '../containers/CreateAssignEmployeeContainer';


const RoleHomeView = (props) => {
    const { SetRoleTab, role_list, role_loading, role_delete, SetRoleDelete, deleteSelectedRole, getRoleLevel, role_page_info, search_with_Education, setCurrentTab, education_list } = props
    const { Option } = Select;

    const [assign_Employee_view, SetAssignEmployeeView] = React.useState(false)
    const [show_filter, setFilterVisible] = React.useState(false)
    const history = useHistory()
    const experiance_list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
    const detailPageNavigation = (id) => {
        history.push(`role-definition/role/${getIntFromString(id)}`)
    }

    const navigation = (view) => {
        SetRoleTab({ key: view })
    }

    const filter_data = [
        {
            key: "title_Icontains",
            ui_flow: "input_box",
            label: "Role",
            default: true
        },
        {
            key: "employeeLevel_Name_Icontains",
            label: "Level",
            ui_flow: "input_box",
        },
        {
            key: "vertical_Name_Icontains",
            label: "Vertical",
            ui_flow: "input_box",
        }
    ]

    const navigateToMember = () => {
        let nav = {
            parent_route: { key: 'member', label: 'Employee' },
            child_route: { key: 'member_home_view', open_modal: true }
        }
        localStorage.setItem('role_definition_navigation', JSON.stringify(nav))
        setCurrentTab({ key: 'member', label: 'Employee' })
    }

    return (
        <>
            {/* confirmation dialog for delete employee level */}
            <Modal
                visible={role_delete}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                width={'90%'}
                style={{ maxWidth: "39em" }}
                className='rd-modal'
            >
                <RoleDefinitionConfirmation
                    message={`Are you sure you want to delete this Role ?`}
                    onCancel={() => { SetRoleDelete(null) }}
                    onConfirm={() => { deleteSelectedRole(getIntFromString(role_delete?.id)) }}
                />
            </Modal>

            { /* Add new competency modal*/}
            <Modal
                visible={assign_Employee_view}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal cp-modal-max-width'
                width={"90%"}
            >
                <CreateAssignEmployeeContainer {...props} onCancel={() => SetAssignEmployeeView(false)} />
            </Modal>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', gap: ".5em", flexWrap: "wrap", width: "100%" }}>
                <div className='rd-root  rd-coll-btn'>
                    <button className='rd-add-button' style={{ width: "8em", marginRight: "1.5em" }} onClick={() => { navigation('role_add_edit_view') }}>+ Add </button>
                    <button className='rd-add-button' style={{ width: "9em", }} onClick={() => { navigateToMember() }}>+ Assign Role </button>
                </div>
                <div className='rd-search-container'>
                    <RoleDefinitionSearchView filterData={filter_data} onfilterChange={e => getRoleLevel(e)}{...props} />
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: 'end', alignItems: "end" }}>
                <h4 className='rd-advace-filter-title' onClick={() => setFilterVisible(!show_filter)}>Advance Filter</h4>
            </div>
            {show_filter && <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", flexWrap: "wrap", marginTop: '1em' }}>
                <div className='rd-role-filter-container'>
                    <Select
                        className="rd-select-filed rd-type-select"
                        dropdownStyle={{zIndex:"1050"}}
                        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                        suffixIcon={<img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                        showSearch
                        onSearch={(e) => search_with_Education({ stream_Icontains: e })}
                        filterOption={false}
                        onSelect={(e) => getRoleLevel({ requiredEligibility_Education_Stream: e })}
                        allowClear
                        onClear={() => getRoleLevel({ requiredEligibility_Education_Stream: "" })}
                        placeholder='select Education'
                        {...props}
                    >
                        {education_list?.map(value => (
                            <Option
                                className='rd-select-option'
                                key={value?.id}
                                value={value?.stream}
                            >
                                {value?.stream}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className='rd-role-filter-container'>
                    <Select
                        className="rd-select-filed rd-type-select"
                        dropdownStyle={{zIndex:"1050"}}
                        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                        suffixIcon={<img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                        showSearch
                        placeholder='select Experience Year'
                        onSelect={(e) => getRoleLevel({ requiredEligibility_QuantitativeExperienceMin: e, requiredEligibility_QuantitativeExperienceMax: e })}
                        allowClear
                        onClear={() => getRoleLevel({ requiredEligibility_QuantitativeExperienceMin: null })}
                        {...props}
                    >
                        {experiance_list?.map((value, index) => (
                            <Option
                                className='rd-select-option'
                                key={index}
                                value={value}
                            >
                                {value}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>}
            {/* role table listing */}
            <Spin spinning={role_loading}>
                <div className='rd-table-container'>
                    <table className='rd-table'>
                        <thead>
                            <tr className='rd-row-header'>
                                <th className='rd-table-row-padding-left rd-left-radious'>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Name</h4>
                                </th>
                                <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Level</h4>
                                </th>
                                <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Vertical</h4>
                                </th>
                                <th className='rd-table-row-padding-rigth rd-content-center rd-table-header-label rd-right-radious'>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Actions</h4>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                role_list?.map(item => (
                                    <tr className='rd-row-body'>
                                        <td className='rd-table-row-padding-left rd-left-radious'>
                                            <h4 className='rd-table-body-label'>{item?.title}</h4>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <h4 className='rd-table-body-label'>{item?.employeeLevel?.name}</h4>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <h4 className='rd-table-body-label'>{item?.vertical?.name}</h4>
                                        </td>
                                        <td className='rd-table-row-padding-rigth rd-content-center rd-right-radious'>
                                            <div className='rd-table-actions'>
                                                <Popover overlayClassName="rd-add-new-popover" style={{ background: "rgba(170, 186, 198, 1)" }} placement="topRight" content={"Role detail page view"} trigger="hover">
                                                    <img className='rd-delete-icon' src={role_icons?.detail_page_view} onClick={() => detailPageNavigation(item?.id)} alt="" />
                                                </Popover>
                                                <Popover overlayClassName="rd-add-new-popover" style={{ background: "rgba(170, 186, 198, 1)" }} placement="topRight" content={"Delete role"} trigger="hover">
                                                    <img className='rd-delete-icon' src={role_icons?.delete_icon} onClick={() => { SetRoleDelete(item) }} alt="" />
                                                </Popover>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {
                        (!role_list?.length && !role_loading) ?
                            <div className='rd-nodata'>
                                <h4 className='rd-nodata-label'>No data found</h4>
                            </div> : ''
                    }
                    {
                        (role_page_info?.hasNextPage && (role_page_info?.minimum_cursor || role_page_info?.maximum_cursor)) && (
                            <RoleDefinitionpagination on_pagination={() => { getRoleLevel({ minimum_cursor: role_page_info?.minimum_cursor, maximum_cursor: role_page_info?.maximum_cursor }) }} />
                        )
                    }
                </div>
            </Spin>
        </>
    )
}

export default RoleHomeView
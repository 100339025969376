import { message } from "antd";
import { graphql } from "@apollo/client/react/hoc";
import {
  CREATE_SCAPE,
  DELETE_SCAPE,
  UPDATE_SCAPE,
} from "../graphql/scapeMutations.gql";
import {
  CREATE_PARAMETRIC_GROUP,
  DELETE_PARAMETRIC_GROUP,
  UPDATE_PARAMETRIC_GROUP,
} from "../graphql/parametricGroupMutation.gql";
import {
  CREATE_PARAMETER,
  DELETE_PARAMETER,
  UPDATE_PARAMETER,
} from "../graphql/parameterMutation.gql";

//crate scape Mutations
export const withCreateScape = (Component) =>
  graphql(CREATE_SCAPE, {
    props: ({ mutate, history }) => ({
      createScape: async (values) => {
        try {
          const {
            data: { createScape },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          //   message.success('Submitted response!!');
          return createScape.scape;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);

//update scape Mutations

export const withUpdateScape = (Component) =>
  graphql(UPDATE_SCAPE, {
    props: ({ mutate, history }) => ({
      updateScape: async (values) => {
        try {
          const {
            data: { updateScape },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          //   message.success('Submitted response!!');
          return updateScape.scape;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);

//Delete scape Mutations

export const withDeleteScape = (Component) =>
  graphql(DELETE_SCAPE, {
    props: ({ mutate, history }) => ({
      deleteScape: async (values) => {
        try {
          const {
            data: { deleteScape },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          //   message.success('Submitted response!!');
          return deleteScape.scape;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);

//Create Parametric Group Mutation

export const withCreateParametricGroup = (Component) =>
  graphql(CREATE_PARAMETRIC_GROUP, {
    props: ({ mutate, history }) => ({
      createParametricGroup: async (values) => {
        try {
          const {
            data: { createParametricGroup },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          //   message.success('Submitted response!!');
          return createParametricGroup.parametricGroup;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);

//Update Parametric Group Mutation

export const withUpdateParametricGroup = (Component) =>
  graphql(UPDATE_PARAMETRIC_GROUP, {
    props: ({ mutate, history }) => ({
      updateParametricGroup: async (values) => {
        try {
          const {
            data: { updateParametricGroup },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          //   message.success('Submitted response!!');
          return updateParametricGroup.parametricGroup;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);

//Delete Parametric Group Mutation

export const withDeleteParametricGroup = (Component) =>
  graphql(DELETE_PARAMETRIC_GROUP, {
    props: ({ mutate, history }) => ({
      deleteParametricGroup: async (values) => {
        try {
          const {
            data: { deleteParametricGroup },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          //   message.success('Submitted response!!');
          return deleteParametricGroup.parametricGroup;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);

//Create Parametric Group Mutation

export const withCreateParameter = (Component) =>
  graphql(CREATE_PARAMETER, {
    props: ({ mutate, history }) => ({
      createParameter: async (values) => {
        try {
          const {
            data: { createParameter },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          //   message.success('Submitted response!!');
          return createParameter.parameter;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);

//Update Parametric Group Mutation

export const withUpdateParameter = (Component) =>
  graphql(UPDATE_PARAMETER, {
    props: ({ mutate, history }) => ({
      updateParameter: async (values) => {
        try {
          const {
            data: { updateParameter },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          //   message.success('Submitted response!!');
          return updateParameter.parameter;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);

//Delete Parametric Group Mutation

export const withDeleteParameter = (Component) =>
  graphql(DELETE_PARAMETER, {
    props: ({ mutate, history }) => ({
      deleteParameter: async (values) => {
        try {
          const {
            data: { deleteParameter },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          //   message.success('Submitted response!!');
          return deleteParameter.parameter;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);

// @ts-nocheck
import React from "react";
import "../kudos.css"; 
import { Spin,Button } from "antd";
import delete_bg_logo from '../../assets/delete-badge.svg'
import { getIntFromString } from "modules/look";
const KudosDeleteConfiramtion = (props) => {
 const {loading,deleteType,closeModal,deleteBadge,deleteBadgeFamily}=props

 const HandleDelete=async()=>{
    if(deleteType.type==='Kudos'){
        let res = await deleteBadge({badgeId:getIntFromString(deleteType.id)})
        if(res){
            closeModal()
        }
    }
    else if(deleteType.type==='Kudos_Family'){
        let res = await deleteBadgeFamily({badgeFamilyId:getIntFromString(deleteType.id)})
        if(res){
            closeModal()
        }
    }
 }

 return(
    <>
    <Spin spinning={loading}>
        <div style={{width:"100%"}} align="center" className="kudos-root-class">
            <div style={{width:"100%"}} align="end" className="align-logo">
                <img src={delete_bg_logo} className="delete-logo" alt="" srcset="" />
            </div>
            <h4 className="kudos-delete-title" style={{fontSize:'1.2em'}}>Are you sure you want to delete the <span>{deleteType.type?.replace('_',' ')}</span>?</h4>
            <div>
                <Button className="kudos-delete-btn" onClick={()=>{HandleDelete()}}>DELETE</Button>
                <Button className="kudos-cancel-btn" onClick={()=>{closeModal()}}>CANCEL</Button>
            </div>
        </div>
    </Spin>
    </>
 )
};

export default KudosDeleteConfiramtion;

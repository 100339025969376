// @ts-nocheck
import { Form, Button, Slider, Input, message, Spin,Modal } from 'antd';
import MultiSelectFormDropdownlabel from 'modules/look-v2/Components/MultiSelectFormDropdownLabel';
import React from 'react';
import CreateEducationContainer from '../containers/CreateEducation'

const CreateEligibilityView = (props) => {

    const { onCancel, education_list, SetEducationList, getEducationList, onSubmitEligibility, eligibility_loading,eligibility_detail } = props
    const [eligiblity_must_have, SetEligibilityMustHave] = React.useState(true)
    const [quantitativeExperience, SetQuantitativeExperience] = React.useState([0, 0])
    const [selected_education, SetSelectedEducation] = React.useState([])
    const [qualitativeExperience, SetQualitativeExperience] = React.useState()
    const [add_education, SetAddEducation] = React.useState(false)
    const default_exp = [0, 0]

    React.useEffect(()=>{
        if(eligibility_detail){
            SetEligibilityMustHave(eligibility_detail?.must_have)
            SetQualitativeExperience(eligibility_detail?.eligibilityData?.qualitativeExperience)
            let exp =[ parseInt(eligibility_detail?.eligibilityData?.quantitativeExperience?.quantitativeExperienceMin),parseInt(eligibility_detail?.eligibilityData?.quantitativeExperience?.quantitativeExperienceMax) ]
            SetQuantitativeExperience(exp)
            SetSelectedEducation(eligibility_detail?.education)
        }
    },[eligibility_detail])

    const handleEducationSelection = (data) => {
        let list = selected_education.concat([])
        let doc = list?.find(item => item?.id === data?.id)
        if (doc) {
            list = list.filter(item => String(item?.id) !== String(data?.id))
        } else {
            list.push(data)
        }
        SetSelectedEducation(list)
    }

    const handleValidation = () => {
        if (!selected_education?.length) {
            message.error('please select any education')
        }else if(quantitativeExperience?.length !== 2 || (quantitativeExperience[0]>quantitativeExperience[1])){
            message.error('please select proper experince')
        } else {
            let data = {
                education: selected_education,
                eligibilityData: {
                    quantitativeExperience: {
                        quantitativeExperienceMin:quantitativeExperience[0]?.toFixed(1),
                        quantitativeExperienceMax:quantitativeExperience[1]?.toFixed(1),
                    },
                    qualitativeExperience: qualitativeExperience
                },
                must_have: eligiblity_must_have
            }
            onSubmitEligibility(data)
        }
    }

    const handleCreateEducationResponse=(data)=>{
        SetAddEducation(false)
        let list = [data].concat(education_list)
        SetEducationList(list)
        let selected_list = selected_education.concat([{...data,label:data?.stream}])
        SetSelectedEducation(selected_list)
    }

    return (
        <Spin spinning={eligibility_loading}>

            {/* to create education */}
            <Modal
                visible={add_education}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal rd-modal-max-width'
                width={"90%"}
            >
                <CreateEducationContainer
                    OnResponse={(res) => { handleCreateEducationResponse(res) }}
                    onCancel={() => SetAddEducation(false)} />
            </Modal>

            <Form
                className='rd-align-center rd-add-edit-competency-container'
                style={{ gap: '.5em', marginTop: '1em' }}
            >
                <div style={{ width: "100%" }} align="start">
                    <h4 className='rd-input-title'>{eligibility_detail?'Update eligibility':'Add new eligibility'}</h4>
                    <div className="role-row-alignment" style={{ marginTop: "1.5em" }}>
                        {/* must have radio btn */}
                        <div className="must-have-section" style={{ border: "1px solid #0098F0" }} onClick={() => SetEligibilityMustHave(true)}>
                            <h4 className="must-have-label" style={{ color: "#0098F0" }}>Must have</h4>
                            <div className={`must-have-parent ${eligiblity_must_have ? 'competency-blue' : ''}`}>
                                <div className={`must-have-child ${eligiblity_must_have ? 'competency-child-blue' : ''}`}></div>
                            </div>
                        </div>
                        {/* good to have radio btn */}
                        <div className="must-have-section" style={{ border: "1px solid #4CCB1F" }} onClick={() => SetEligibilityMustHave(false)}>
                            <h4 className="must-have-label" style={{ color: "#4CCB1F" }}>Good to have</h4>
                            <div className={`must-have-parent ${!eligiblity_must_have ? 'competency-green' : ''}`}>
                                <div className={`must-have-child ${!eligiblity_must_have ? 'competency-child-green' : ''}`}></div>
                            </div>
                        </div>
                    </div>

                    <div className='rd-input-container' style={{ marginTop: "1.5em" }}>
                        <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Add required education:</h4>
                        <MultiSelectFormDropdownlabel
                            onSelect={(e) => { handleEducationSelection(e) }}
                            selected_data={selected_education}
                            onSearch={(e) => { getEducationList({ stream_Icontains: e }) }}
                            placeholder={'Add Education'}
                            choice_list={education_list?.map(item => { return { ...item, label: item?.stream } })}
                            add_btn='Add Education Details'
                            on_add={(e) => { SetAddEducation(true) }}
                        />
                    </div>

                    <div className='rd-input-container' style={{ marginTop: "1.5em" }}>
                        <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Required Experience:</h4>


                        <div className="slider-container">
                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <div className='slider-label-row'>
                                    <h4><span className='rd-input-star'>*</span> Minimum</h4>
                                    <h4><span className='rd-input-star'>*</span> Maximum</h4>
                                </div>
                                <Slider
                                    className='rd-eligibility-slider'
                                    defaultValue={default_exp}
                                    value={quantitativeExperience}
                                    range={{ draggableTrack: false }}
                                    min={0} max={100}
                                    step={1}
                                    trackStyle={{ background: "#4CCB1F" }}
                                    handleStyle={{ background: "#4CCB1F" }}
                                    style={{ width: "100%", }}
                                    onChange={(e) => { SetQuantitativeExperience(e) }}
                                    onAfterChange={(e) => { SetQuantitativeExperience(e) }}
                                />
                            </div>
                            <h4 className='eligible-year'>{quantitativeExperience[0]} - {quantitativeExperience[1]} years</h4>
                        </div>
                    </div>

                    <div className='rd-input-container' style={{ marginTop: "1.5em" }}>
                        <Input className="rd-text-input" onChange={(e) => { SetQualitativeExperience(e?.target?.value) }} value={qualitativeExperience}/>
                    </div>
                </div>
                <div className='rd-confirmation-action-container' style={{ margin: "0.5em 2em 0em 2em" }}>
                    <Button className='global-submit-btn' onClick={() => { handleValidation() }} >Save</Button>
                    <Button className='global-cancel-btn' onClick={() => onCancel()}>Cancel</Button>
                </div>

            </Form>
        </Spin>
    )

}

export default CreateEligibilityView
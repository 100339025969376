import React from "react";
import { Progress, Form } from "antd";

import { getStrokeColor } from "../../../look";

import { withTeamsMAppPulseCheck } from "../../containers/DashboardOperations";

const FormItem = Form.Item;

const TeamsMAppPulseCheck = ({ loading, reportingTeamsMAppPulseCheck }) => (
  <FormItem
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24 }}
    label={"Reporting Teams MApp Pulse Check"}
  >
    <Progress
      format={(percent) => percent / 10 + "%"}
      percent={
        (reportingTeamsMAppPulseCheck &&
          JSON.parse(reportingTeamsMAppPulseCheck)?.overall?.toFixed(2) * 10) ||
        0
      }
      strokeColor={getStrokeColor(
        (reportingTeamsMAppPulseCheck &&
          JSON.parse(reportingTeamsMAppPulseCheck)?.overall?.toFixed(2)) ||
          0
      )}
    />
  </FormItem>
);

export default withTeamsMAppPulseCheck(TeamsMAppPulseCheck);

// modifications on lines
// 6
// 10(2)
// 11
// 12
// 16(2)
// 19(2)
// 25(2)

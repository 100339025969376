import React from 'react';
import { getItem } from 'modules/core';
import home_route from '../../home/route';
import { ACCESS_TOKEN } from '../../../config';

const NotFound = props => {
  const {navigateRoute} = props
  React.useEffect(()=>{
    try {
        getItem(ACCESS_TOKEN).then((token)=>{
            if(token){
                if(navigateRoute){
                    navigateRoute(home_route.userdashboard)
                }
            }else{
                if(navigateRoute){
                    navigateRoute("/intro")
                }
            }
        })
    } catch (error) {
        if(navigateRoute){
            navigateRoute("/intro")
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <></>
  );
};

export default NotFound;

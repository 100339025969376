import React from 'react';
import { message, Modal, Row, Col,Button, Form } from 'antd';
import {
  RenderTable,
  displayDataCheck,
  TextField,
  DeleteIcon,
  EditIcon,
  getIntFromString,
  GraphicsField,
  withMarkdownFieldValue
} from '../../look';
import { PlusOutlined, MenuOutlined } from '@ant-design/icons';
import { withDeleteSimpleFieldChoice } from '../containers/FormSectionOperation';
import { sortableHandle } from 'react-sortable-hoc';

const CKEditorFieldName = ['graphic'];

const SimpleFieldChoice = props => {
  const { choices, setChoices, deleteSimpleFieldChoice, onSubmit } = props;
  const [selectedRowKeys, setKeys] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({});
  // eslint-disable-next-line no-unused-vars
  const [edit, setEdit] = React.useState(false);

  const handleEdit = record => {
    setEdit(true);
    setInitialValues({
      id: record.id,
      label: record.label,
      value: record.value,
      sequence: record.sequence,
      graphic: record.graphic
    });
    setVisible(true);
  };
  const handleDelete = async record => {
    if (record.id) {
      try {
        const response = await deleteSimpleFieldChoice(getIntFromString(record.id));
        message.success('Success!');
        if (response) {
          let remainChoices = choices.filter(choice => choice.id !== record.id);
          setChoices([...remainChoices]);
        }
      } catch (e) {
        message.error('Failed!');
        throw Error(e.message);
      }
    } else {
      let remainChoices = choices.filter((choice, idx) => idx !== record.idx);
      setChoices([...remainChoices]);
    }
  };
  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999', margin: '-16px', padding: '16px' }} />
  ));

  const columns = [
    {
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Label',
      key: 'label',
      render: (text, record) => <>{record && displayDataCheck(record.label)}</>
    },
    {
      title: 'Value',
      key: 'value',
      render: (text, record) => <>{record && displayDataCheck(record.value)}</>
    },
    {
      title: 'Graphic',
      key: 'graphic',
      render: (text, record) => <>{record && displayDataCheck(record.graphic)}</>
    },
    {
      title: 'Sequence',
      key: 'sequence',
      render: (text, record) => <>{record && record.sequence}</>
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      render: (text, record) => (
        <>
          <EditIcon tooltipSuffix="Choice" size={'sm'} onClick={() => handleEdit(record)} />
          &nbsp;&nbsp;
          <DeleteIcon tooltipSuffix="Choice" size={'sm'} onClick={() => handleDelete(record)} />
        </>
      )
    }
  ];

  const RenderTable1 = () => (
    <>
      <RenderTable
        tableData={{
          edges: choices
            .sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
            .map(node => ({ node }))
        }}
        loading={false}
        rowKey={'index'}
        onPaginationChange={() => {}}
        columns={columns}
        selectedRowKeys={selectedRowKeys}
        handleChange={setKeys}
        components={true}
        onSort={e => setChoices(e.map(node => ({ ...node, sequence: node.index + 1 })))}
      />
    </>
  );
  const layout = {
    labelCol: { span: 8, style: { textAlign: 'left' } },
    wrapperCol: { span: 16 }
  };
  return (
    <>
      <div>
        <Row>
          <Row gutter={24}>
            <Col span={10}>
              <h2>Choices</h2>
            </Col>
            <Col span={14}>
              <Button
                size="md"
                type="primary"
                ghost
                onClick={() => {
                  setInitialValues({});
                  setVisible(true);
                  setEdit(false);
                }}
              >
                <PlusOutlined />
                Add Choice
              </Button>
            </Col>
          </Row>
          <br />
          <Col span={23}>
            {/* {loading && <Loading />} */}
            {choices && <RenderTable1 />}
          </Col>
        </Row>
        <br />
        <Modal
          footer={null}
          centered
          width="38%"
          visible={/* true || */ visible}
          destroyOnClose={true}
          onCancel={() => setVisible(false)}
          // bodyStyle={{ padding: '0px' }}
        >
          <Row>
            <Col span={23}>
              <h3>Add Choice</h3>
            </Col>
          </Row>
          <Form
            onFinish={async values => {
              /* const response = */ await onSubmit(withMarkdownFieldValue(CKEditorFieldName, values));
              /* response &&  */ setVisible(false);
            }}
            initialValues={initialValues}
          >
            <Row justify="start">
              <Col lg={20} md={24} xs={24}>
                <TextField
                  {...layout}
                  name="label"
                  label="Label"
                  rules={[{ required: true, message: 'Label is required!' }]}
                />
                <TextField
                  {...layout}
                  name="value"
                  label="Value"
                  rules={[{ required: true, message: 'Value is required!' }]}
                />
                <TextField
                  {...layout}
                  name="sequence"
                  label="Sequence"
                  // rules={[{ required: true, message: 'Sequence is required!' }]}
                />
              </Col>
              <Col span={24}>
                <GraphicsField
                  name={CKEditorFieldName}
                  label="Graphic"
                  initialValue={initialValues && initialValues.graphic}
                  // rules={[{ required: true, message: 'Graphic is required!' }]}
                />
              </Col>
            </Row>
            <Row justify="end">
              <Button type="link" htmlType="submit">
                Done
              </Button>
            </Row>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default withDeleteSimpleFieldChoice(SimpleFieldChoice);

import React from "react";
import rank1 from '../../../assets/rank1.svg'
import rank2 from '../../../assets/rank2.svg'
import rank3 from '../../../assets/rank3.svg'
import rank4 from '../../../assets/rank4.svg'
import rank5 from '../../../assets/rank5.svg'
import { Modal, Spin } from "antd";
import readiness_close_icon from '../../../assets/readiness-close-icon.svg'
const SoulCompletedResponseView = (props) => {
    const { response_data, onClose, show_view,loading } = props
    const ranks = {
        rank1,
        rank2,
        rank3,
        rank4,
        rank5
    }
    return (
        <Modal
            visible={show_view}
            footer={false}
            closable={false}
            centered={true}
            destroyOnClose={true}
            className="congrats-modal-value">
            <Spin spinning={loading}>
            <div style={{ width: '100%' }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: '100%', padding: '1em' }}>
                    <div></div>
                    <img src={readiness_close_icon} alt="readiness_close_icon" onClick={() => onClose()} style={{ cursor: "pointer",marginTop:'1em',marginRight:'1em'}} />
                </div>
                <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: '1em',width:'100%' }}>
                    <h2 className="landing-card-title" style={{ fontWeight: 700, fontSize: "2.5em", textAlign: "center" }}>Congratulations!!</h2>
                    <h4 className="landing-card-title" style={{ fontWeight: 600, fontSize: "1.75em", textAlign: "center" }}>Your <span style={{fontWeight:700}}>Top 5</span> {show_view?.type} Preference is</h4>
                    {
                        response_data?.map((d, i) => (
                            <div className="learn-value-list">
                                <img src={ranks[`rank${i + 1}`]} alt="" style={{ marginRight: "10px" }} />
                                <h4>{d.answer}</h4>
                            </div>
                        ))
                    }
                </div>
            </div>
            </Spin>
        </Modal>

    )
}

export default SoulCompletedResponseView;
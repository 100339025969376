// @ts-nocheck
import React, { useState } from "react";
import { MetaTags, getIntFromString } from "../../look";
import { GROW_ADD_TOOL_ID } from "../../../config";
import SinglePageQuizView from '../../survey/components/SinglePageQuizView';
import '../css/CreateConversationView.css'
import gorw_route from '../route/index'
import '../css/newEngagementDesign.css';
import new_back_arrow from '../../assets/new_back_arrow.svg';
import coaching_engagement_route from "../route/index";
import SideBulbToolContainer from "modules/look-v2/Container/SideBulbToolContainer";
const CreateConversationView = props => {

  const { history, me, orgSurveyById, setloading, createCoachingConversation, newCreateActionItem, addCoacheeComm, navigateRoute} = props
  // eslint-disable-next-line no-unused-vars
  const [ prevTotalDuration, setPrevTotalDuration ] = React.useState( 0 );
  // eslint-disable-next-line no-unused-vars
  const [ userId, setUserId ] = useState()
  const startTime = new Date().getTime();
  const submitted = false;
  const [ai_trigger,SetAITrigger] = React.useState()
  const handleSubmit = async answers => {
    setloading( true )
    let relatedToVale = ""
    if ( answers[ 'cocheerel' ] === "key_result" ) {
      relatedToVale = answers[ 'kr' ]
    } else if ( answers[ 'cocheerel' ] === "milestone" ) {
      relatedToVale = answers[ 'msf' ]
    } else if ( answers[ 'cocheerel' ] === "skill" ) {
      relatedToVale = answers[ 'skill' ]
    } else if ( answers[ 'cocheerel' ] === "okr" ) {
      relatedToVale = answers[ 'okr' ]
    }
    let createCoachingData = {
      coacheeId: answers[ 'cochee' ][ 1 ],
      relatedTo: relatedToVale,
      followUpOn: answers[ 'follow_up' ]
    }
    if ( !answers[ 'follow_up' ] ) {
      delete createCoachingData.followUpOn
    }
    let ansValues = []
    for ( const [ q, ans ] of Object.entries( answers ) ) {
      try {
        let decode = window.atob( q );
        if ( decode?.includes( "QuestionType:" ) && ans ) {
          if ( typeof ( ans ) === 'object' ) {
            let mappedData = ans?.map( ( item, index ) => {
              return {
                'questionId': getIntFromString( q ),
                answer: item,
                sequence: index
              }
            } )
            ansValues = ansValues.concat( mappedData )
          }
          else {
            ansValues.push( { 'questionId': getIntFromString( q ), answer: ans, sequence: 0 } )
          }
        }
      } catch ( e ) {
        // not base 64 key... canbe skipped as all survey answer keys will be base 64
      }
    }

    const response = await createCoachingConversation( { answers: ansValues, createCoachingData: createCoachingData, surveyId: getIntFromString( orgSurveyById?.id ) } );
    if ( response && response?.id ) {
      answers[ 'when_need' ].map( item => {
        return { title: item[ 0 ], deadline: new Date( item[ 1 ] ) }
      } ).map( item => addCoacheeComm( item.title, 40, '  ', getIntFromString( me.id ), response?.id, item.deadline ) )
      answers[ 'when_next_step' ].map( item => {
        return { title: item[ 0 ], deadline: new Date( item[ 1 ] ) }
      } ).map( item => addCoacheeComm( item.title, 40, '  ', answers[ 'cochee' ][ 2 ], response?.id, item.deadline ) )

      if ( answers?.when_need?.length || answers?.when_next_step?.length ) {
        if ( answers?.when_next_step?.length ) {
          for await ( const action of answers?.when_next_step ) {
            let actionItemData = {
              ownerId: answers[ 'cochee' ][ 1 ],
              title: action[ 0 ],
              dueDate: action[ 1 ],
              relatedTo: response?.id
            }
            await newCreateActionItem( { actionItemData } )
          }
        }
        if ( answers?.when_need?.length ) {
          for await ( const actionCoach of answers?.when_need ) {
            let actionItemData = {
              ownerId: getIntFromString( me?.employee?.id ),
              title: actionCoach[ 0 ],
              dueDate: actionCoach[ 1 ],
              relatedTo: response?.id
            }
            await newCreateActionItem( { actionItemData } )
          }
        }
        history.push( gorw_route?.grow_home )
      } else {
        history.push( gorw_route?.grow_home )
      }
    } else {
      setloading( false )
    }
  }

  return (
    <>
      <MetaTags
        title="create conversation"
        description="create conversation page."
      />
      <div className="coaching-conversation-root">
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "1em" }}>
          <img src={new_back_arrow} style={{ width: "20px", height: "20px", cursor: "pointer", marginLeft: '7px' }} onClick={() => { navigateRoute( coaching_engagement_route?.grow_home ) }} alt="" />
          <h4 className="coaching-engagement-page-title">Coaching Conversation</h4>
          <div style={{ position: 'relative' }}>
            <SideBulbToolContainer
              {...props}
              widthOfContainer={24}
              containerBorder={""}
              spacing={'0px'}
              hideTopDivider={true}
              keepDesign={true}
              top={"104px"}
              toolReturn={e=>{}}
              tool_id={GROW_ADD_TOOL_ID}
            />
          </div>
        </div>
        <div style={{ background: "#fff", boxShadow: " 0px 10px 15px 0px rgba(170, 186, 198, 0.2)", width: '100%', padding: '1em 2em', borderRadius: ".5em", overflow: "auto", height: "76.5vh",marginTop:"1em" }}>
          <SinglePageQuizView
            setUserId={setUserId}
            submitted={submitted}
            onSubmit={handleSubmit}
            setPrevTotalDuration={setPrevTotalDuration}
            quizStartTime={startTime}
            coachingConversation={true}
            // showTermsAndCondition={showTermsAndCondition}
            showAdditonalCoahee={true}
            showActionItem={true}
            ai_trigger={ai_trigger}
            SetAITrigger={SetAITrigger}
            {...props}
          />
        </div>
      </div>
    </>
  );
};

export default CreateConversationView

import React from 'react';
import { jobfamily_icons } from '../icon';


const JobFamilySubInfoDetail =(props)=>{
    const {sub_info_detail,closeInfo}=props
    return (
        <div className='rd-job-family-sub-info-container'>
            <img className='jf-modal-close-icon' src={jobfamily_icons?.jf_modal_close} alt="" onClick={()=>closeInfo()} />
            <div className='rd-job-family-sub-info-heading-container'>
                <h4 className='rd-job-family-sub-info-title'>{sub_info_detail?.title}</h4>
            </div>
            <div className='rd-job-family-sub-info-label-container'>
            {
                sub_info_detail?.content?.length>0? sub_info_detail?.content?.map((item)=>(
                    <h4 className='rd-job-family-sub-info-label'>{item?.label}</h4>
                )):
                <div className='jf-no-data-fund-container'>
                    <h4 className='rd-job-family-sub-info-label'>No {sub_info_detail?.title} Found</h4>
                </div>
            }
            </div>
            
        </div>
    )
}


export default JobFamilySubInfoDetail
import { Button,  Col, Dropdown, Form, Input, Menu, Modal, Row, Select, Spin, Switch } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import white_back_icon from '../../assets/white_back_icon.svg'
import email_icon from '../../assets/email.svg'
import '../css/SalesPageStyles.css'
import svg_log from '../../assets/new-map-logo.svg'
// import up_arrow from '../../assets/up-arrow-advance-options.svg'
import user_name from '../../assets/user_name.svg'
import companyname from '../../assets/organization-vector.svg'
import get_in_touch_2 from '../../assets/get_in_touch_2.png'
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import ThankyouView from './ThankYouView';

const GetInTouchView = props => {

    const { contactRequest } = props

    const history = useHistory()

    const [name, setName] = React.useState("");
    const [company_name, setCompanyName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [contactNumber, setContactNumber] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [contactType, setContactType] = React.useState("");
    const [teamSize, setTeamSize] = React.useState(undefined);
    const [city, setCity] = React.useState(undefined);
    const [industry, setIndustry] = React.useState(undefined);
    const [loading, setLoading] = React.useState(false)
    const [selectedCountryCode, setSelectedCountryCode] = React.useState(91);
    const [selectedShort, setSelectedShort] = React.useState("IN");
    const [submissionDisabled, setSubmissionDisabled] = React.useState(true)
    const [invalidContactNumber, setInvalidContactNumber] = React.useState(false)
    const [invalidEmail, setInvalidEmail] = React.useState(false)
    const [invalidTeamSize, setInvalidTeamSize] = React.useState(false)
    const [advancedOptions, setAdvancedOptions] = React.useState(true)
    const [thankYouModalVisible, setThankYouModalVisible] = React.useState(false);
    const [isArrowClicked, setIsArrowClicked] = useState(false)
    const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false)

    const [form] = Form.useForm();


    // eslint-disable-next-line no-useless-escape
    let validEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


    const handleFormSubmit = () => {

        let inValidInput = checkValidityOfInput()
        if (!inValidInput) {
            setLoading(true)
            const response = contactRequest({
                name: name,
                email: email,
                mobileNumber: "+" + selectedCountryCode + contactNumber,
                contactType: contactType,
                teamSize: teamSize ? teamSize : "",
                message: message,
                city:city,
                industry:industry,
                companyName:company_name,
                contactMe: isCheckBoxChecked
            })

            if (response) {
                setLoading(false)
                setName("")
                setContactNumber("")
                setEmail("")
                setCompanyName("")
                setTeamSize('');
                setMessage("")
                setContactType('')
                setInvalidContactNumber(false)
                setInvalidEmail(false)
                setInvalidTeamSize(false)
                setSubmissionDisabled(true)
                setThankYouModalVisible(true)
            }
        }

    }

    const checkValidityOfInput = () => {

        var pattern = new RegExp(/^[0-9\b]+$/);
        let validContactNumber = pattern.test(contactNumber)


        if (validContactNumber && contactNumber.length <= 10) {
            setInvalidContactNumber(false)
        } else {
            setInvalidContactNumber(true)
        }

        let inValidEmail = !validEmailRegex.test(email);
        setInvalidEmail(inValidEmail)

        return inValidEmail || !(validContactNumber && contactNumber.length <= 10)

    }

    React.useEffect(() => {
        if (name && email && contactNumber && message && teamSize && selectedCountryCode && industry && city && company_name) {
            setSubmissionDisabled(false)
        } else {
            setSubmissionDisabled(true)
        }
        if (invalidContactNumber || invalidEmail) {
            checkValidityOfInput()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, email, contactNumber, message, teamSize,industry,city,company_name])


    const TeamSizes = ["5 - 25", "26 - 50", "51 - 100", "101 - 300", "301 - 500", "501 - 1000", "1001 - 5000", "5001 - 10000", "10000 +"];
    const industries = [
        "Accounting", "Airlines/Aviation", "Alternative Dispute Resolution", "Alternative Medicine", "Animation", "Apparel/Fashion", "Architecture/Planning", "Arts/Crafts", "Automotive", "Aviation/Aerospace", "Banking/Mortgage", "Biotechnology/Greentech", "Broadcast Media", "Building Materials", "Business Supplies/Equipment",
        "Capital Markets/Hedge Fund/Private Equity", "Chemicals", "Civic/Social Organization", "Civil Engineering", "Commercial Real Estate", "Computer Games", "Computer Hardware", "Computer Networking", "Computer Software/Engineering", "Computer/Network Security", "Construction", "Consumer Electronics", "Consumer Goods", "Consumer Services", "Cosmetics",
        "Dairy", "Défense/Space", "Design", "E-Learning", "Education Management", "Electrical/Electronic Manufacturing", "Entertainment/Movie Production", "Environmental Services", "Events Services", "Executive Office", "Facilities Services", "Farming", "Financial Services", "Fine Art", "Fishery",
        "Food Production", "Food/Beverages", "Fundraising", "Furniture", "Gambling/Casinos", "Glass/Ceramics/Concrete", "Government Administration", "Government Relations", "Graphic Design/Web Design", "Health/Fitness", "Higher Education/Academia", "Hospital/Health Care", "Hospitality", "Human Resources/HR", "Import/Export",
        "Individual/Family Services", "Industrial Automation", "Information Services", "Information Technology/IT", "Insurance", "International Affairs", "International Trade/Development", "Internet", "Investment Banking/Venture", "Investment Management/Hedge Fund/Private Equity", "Judiciary", "Law Enforcement", "Law Practice/Law Firms", "Legal Services", "Legislative Office",
        "Leisure/Travel", "Library", "Logistics/Procurement", "Luxury Goods/Jewelry", "Machinery", "Management Consulting", "Maritime", "Market Research", "Marketing/Advertising/Sales", "Mechanical or Industrial Engineering", "Media Production", "Medical Equipment", "Medical Practice", "Mental Health Care", "Military Industry",
        "Mining/Metals", "Motion Pictures/Film", "Museums/Institutions", "Music", "Nanotechnology", "Newspapers/Journalism", "Non-Profit/Volunteering", "Oil/Energy/Solar/Greentech", "Online Publishing", "Other", "Outsourcing/Offshoring", "Package/Freight Delivery", "Packaging/Containers", "Paper/Forest Products", "Performing Arts",
        "Pharmaceuticals", "Philanthropy", "Photography", "Plastics", "Political Organization", "Primary/Secondary Education", "Printing", "Professional Training", "Program Development", "Public Relations/PR", "Public Safety", "Publishing Industry", "Railroad Manufacture", "Ranching", "Real Estate/Mortgage",
        "Recreational Facilities/Services", "Religious Institutions", "Renewables/Environment", "Research Industry", "Restaurants", "Retail Industry", "Security/Investigations", "Semiconductors", "Shipbuilding", "Sporting Goods", "Sports", "Staffing/Recruiting", "Supermarkets", "Telecommunications", "Textiles",
        "Think Tanks", "Tobacco", "Translation/Localization", "Transportation", "Utilities", "Venture Capital/VC", "Veterinary", "Warehousing", "Wholesale", "Wine/Spirits", "Wireless", "Writing/Editing"
    ]

    const TeamSizeOptions = () => (
        <>
            <Menu className='team-size-options-menu-item' style={{ borderRight: 'none' }}>
                {
                    TeamSizes?.map((size) =>
                        <div className="team-size-menu-element" onClick={(e) => setTeamSize(size)} >{`${size} People`}</div>
                    )
                }
            </Menu>
        </>
    );
    React.useEffect(() => {

        let element = document.getElementById('get-in-touch-page-sales')
        if (element) {
            element.scrollIntoView({ block: 'start', inline: 'start', behavior: 'smooth' })
        }
    }, [])

    // eslint-disable-next-line no-unused-vars
    const tackleArrow = (e) => {
        setContactType("")
        e.preventDefault()
        setAdvancedOptions(!advancedOptions)
        setIsArrowClicked(!isArrowClicked)
    }

    return (
        <>
            <Spin spinning={loading}>
                <Row id='get-in-touch-page-sales' className='poppins get-in-touch-image-info-wrapper'  >

                    {/* Image container */}
                    <Col sm={24} xs={24} md={10} lg={8}>
                        <img alt='' className='get-in-touch-sales' src={get_in_touch_2} />
                        <Row justify='start' className='text-content-get-in-touch' >

                            {/* top container */}

                            <Row align='top' justify='start' style={{ flexDirection: 'column', rowGap: '20px' }}>
                                <Row align='middle' justify='start' style={{ cursor: 'pointer' }} onClick={(e) => history.goBack()}>
                                    <img alt='' className="back-icon-get-in-touch" src={white_back_icon} />
                                    <div className="info-text-get-in-touch">Back to site</div>
                                </Row>
                                <img alt='' src={svg_log} />
                            </Row>

                            {/* bottom contact info container */}
                            <div className='info-container-get-in-touch'>
                                <div className="info-text-get-in-touch contact-label-get-in-touch">Address</div>
                                <div className="info-text-get-in-touch">D-606, Solitaire co-operative Society,
                                    Dhanori-Lohegao road, Dhanori, Pune-411015, Maharashtra, India</div>
                                <div className="info-text-get-in-touch contact-label-get-in-touch">Let's Talk</div>
                                <div className="info-text-get-in-touch">+91 9920917193</div>
                                <div className="info-text-get-in-touch contact-label-get-in-touch">General Support</div>
                                <div className="info-text-get-in-touch">info@brain-ayan.com</div>
                            </div>
                        </Row>
                    </Col>


                    {/* Form container column */}
                    <Col sm={22} xs={22} md={9} lg={9} className="form-col-get-in-touch" style={{ paddingTop: '0px', paddingBottom: '80px', margin: '2em auto auto auto' }}>


                        <div className='form-header-get-in-touch'>Let's Connect!<br></br>
                            We're Eager to Hear from You!</div>
                        <div className='messgae-tag-get-in-touch'>Leave us a message and we'll get back to you within 24 hours!</div>

                        <Form className='input-form-get-in-touch' form={form} onFinish={(e) => handleFormSubmit()}>
                            <p className='form-label-get-in-touch'>Your name</p>
                            <Input autoComplete='off' value={name} suffix={<img alt='' src={user_name} />} onChange={(e) => setName(e.target.value)} placeholder='Your Name' className='input-box-get-in-touch' />
                            <p className='form-label-get-in-touch'>Work Email</p>
                            <Input autoComplete='off' value={email} suffix={<img  alt='' src={email_icon} />} placeholder='Email address' onChange={(e) => setEmail(e.target.value)}
                                className='input-box-get-in-touch' style={{ border: invalidEmail ? '1px solid red' : 'none' }} />
                            {
                                invalidEmail &&
                                <p style={{ color: 'red', fontSize: '12px', marginTop: '-12px' }}>Please enter a valid input</p>
                            }
                            <p className='form-label-get-in-touch'>Company name</p>
                            <Input autoComplete='off' value={company_name} suffix={<img alt='' src={companyname} />} onChange={(e) => setCompanyName(e.target.value)} placeholder='Company Name' className='input-box-get-in-touch' />
                            <div className='contact-number-container' style={{ marginBottom: '15px' }}>
                                <p className='form-label-get-in-touch'>Phone number</p>
                                <ConfigProvider locale={en}><CountryPhoneInput value={{ phone: contactNumber, code: selectedCountryCode, short: selectedShort }} onChange={(e) => { setSelectedCountryCode(e.code); setContactNumber(e.phone); setSelectedShort(e.short) }}
                                    style={{ border: invalidContactNumber ? '1px solid red' : 'none', cursor: 'pointer' }} /></ConfigProvider>
                                {
                                    invalidContactNumber &&
                                    <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>Please enter a valid input</p>
                                }
                            </div>
                            <div style={{ marginBottom: '1em' }}>
                                <p className='form-label-get-in-touch' >Industry</p>
                                <Select
                                    className="sales-menu-container"
                                    style={{ width: "100%" }}
                                    placeholder="Select Your Industry"
                                    onChange={(value) => setIndustry(value)}
                                    showSearch={true}>
                                    {
                                        industries?.map(item => {
                                            return <Select.Option className='team-size-options-menu-item' style={{ borderRight: 'none', maxHeight: "300px", overflow: 'auto' }} key={item} value={item} >{item}</Select.Option>
                                        }
                                        )
                                    }
                                </Select>
                            </div>
                            <div style={{ marginBottom: '1em' }}>
                                <p className='form-label-get-in-touch' >City</p>
                                <Input autoComplete='off' value={city} onChange={(e) => setCity(e.target.value)} placeholder='Your City Name' className='input-box-get-in-touch' />
                            </div>
                            <p className='form-label-get-in-touch'>Describe Your Request in Detail</p>
                            <textarea value={message} onChange={(e) => setMessage(e.target.value)} className='input-box-get-in-touch textarea-get-in-touch your-msg-box' />

                            <div style={{ marginBottom: '1em' }}>
                                <p className='form-label-get-in-touch' >Employee Strength</p>
                                <Dropdown className="team-size-dropdown-container" overlay={TeamSizeOptions} placement={"bottomCenter"}  >
                                    <Button className='team-size-drop' style={{ width: '100%', background: '#ECF1F4', border: 'none', borderRadius: '8px', height: '40px' }}>
                                        <div className='poppins sales-page-link selected-team-size' style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', fontSize: "14px" }} >{teamSize ? `${teamSize} People` : 'Select Team Size'}</div>
                                    </Button>
                                </Dropdown>
                            </div>
                            {
                                invalidTeamSize &&
                                <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>Please enter a valid input</p>
                            }
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: '1em', marginTop: "1em", marginBottom: '1em' }}>
                                <p className='form-label-get-in-touch' style={{ margin: "0px" }}>I authorize MApp to contact me: </p>
                                <Switch checked={isCheckBoxChecked} onChange={(checked) => setIsCheckBoxChecked(checked)} />
                            </div>
                            <Button htmlType="submit" disabled={submissionDisabled} className={submissionDisabled ? 'send-msg-button send-msg-button-disbaled' : 'send-msg-button send-msg-button-enabled'} style={{ marginTop: '1em' }}>Send Message</Button>
                        </Form>

                    </Col>
                </Row>
            </Spin>


            {/* popup shown after submitting the form */}
            <div >
                <Modal visible={thankYouModalVisible}
                    footer={null}
                    centered
                    width={850}
                    className='thank-you-modal'
                    destroyOnClose={true}
                    onCancel={(e) => setThankYouModalVisible(false)}>
                    <ThankyouView />
                </Modal>
            </div>
        </>
    );
};

export default GetInTouchView;



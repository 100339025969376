// @ts-nocheck
import React from 'react';
import { Spin, Modal, Popover } from 'antd';
import { jobfamily_icons } from '../icon';
import { getIntFromString } from 'modules/look';
import RoleDefinitionConfirmation from '../../look-v2/Components/ConfirmationDialog';
import RoleDefinitionSearchView from './RoleDefinitionSearchView';
import JobFamilySubInfoDetail from './JobFamiltySubInfoDetailPopup';
import RoleDefinitionpagination from './roleDefinitionPagination';

const JobFamilyHomeView = (props) => {

    const { SetJobFamilyTab, jobfamily_loading, jobfamily_list, jobfamily_delete, SetJobFamilyDelete, jobfamily_page_info, removeJobFamily, getJobFamilyList, history } = props
    const [sub_info_detail, SetSubInfo] = React.useState()

    const navigation = (view) => {
        SetJobFamilyTab({ key: view })
    }

    const detailPageNavigation = (id) => {
        history.push(`role-definition/jobfamily/${getIntFromString(id)}`)
    }

    const GetVerticalCount = (data) => {
        let vertical_list = data?.filter(({ node }) => node?.vertical)?.map(({node})=>node?.vertical)
        var setObj = new Set();
        var result = vertical_list?.reduce((acc, item) => {
            if (!setObj.has(item?.id)) {
                setObj.add(item?.id)
                acc.push(item)
            }
            return acc;
        }, [])
        return result?.length || 0
    }

    // to handle the popup to show role,competency and vertical
    const viewSubinfo = (data, type) => {
        var setObj = new Set();
        if (type === 'role') {
            let info = {
                title: 'Role',
                content: data?.role?.edges?.map(({ node }) => {
                    return {
                        id: node?.id,
                        label: node?.title
                    }
                })
            }
            var result = info?.content?.reduce((acc, item) => {
                if (!setObj.has(item?.id)) {
                    setObj.add(item?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            info.content = result
            SetSubInfo({ ...info })
        }
        else if (type === 'competency') {
            let info = {
                title: 'Generic Communication',
                content: data?.genericCompetency?.edges?.map(({ node }) => {
                    return {
                        id: node?.id,
                        label: node?.title
                    }
                })
            }
            // eslint-disable-next-line no-redeclare
            var result = info?.content?.reduce((acc, item) => {
                if (!setObj.has(item?.id)) {
                    setObj.add(item?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            info.content = result
            SetSubInfo({ ...info })
        }
        else if (type === 'vertical') {
            let vertical_list = data?.role?.edges.map(({ node }) => node?.vertical)?.filter(item => item)
            let info = {
                title: 'Vertical',
                content: vertical_list?.map((item) => {
                    return {
                        id: item?.id,
                        label: item?.name
                    }
                })
            }
            // eslint-disable-next-line no-redeclare
            var result = info?.content?.reduce((acc, item) => {
                if (!setObj.has(item?.id)) {
                    setObj.add(item?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            info.content = result
            SetSubInfo({ ...info })
        }
    }

    //Filter datas
    const filter_data = [
        {
            key: "title_Icontains",
            label: "Name",
            ui_flow: "input_box",
            default: true
        },
        {
            key: "genericCompetency_Title_Icontains",
            label: "Competency",
            ui_flow: "input_box"
        },
        {
            key: "role_Title_Icontains",
            label: "Role",
            ui_flow: "input_box"
        }
    ]

    return (
        <>
            {/* confirmation dialog for delete job family */}
            <Modal
                visible={jobfamily_delete}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal'
            >
                <RoleDefinitionConfirmation
                    message={`Do you want to delete the Job Family ${jobfamily_delete?.title}?`}
                    onCancel={() => { SetJobFamilyDelete(null) }}
                    onConfirm={() => { removeJobFamily(getIntFromString(jobfamily_delete?.id)) }}
                />
            </Modal>

            {/* modal to show sub info of job family*/}
            <Modal
                visible={sub_info_detail}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
            >
                <JobFamilySubInfoDetail sub_info_detail={sub_info_detail} closeInfo={() => SetSubInfo()} />
            </Modal>


            <div className='rd-coll-competency' style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: 'center', gap: ".5em", flexWrap: "wrap", width: "100%" }}>
                <div className='rd-root'>
                    <button className='rd-add-button' style={{ width: "11em", marginRight: "1.5em" }} onClick={() => { navigation('jobfamily_add_edit_view') }}>+ Add Job family</button>
                </div>
                <div className='rd-search-container'>
                    <RoleDefinitionSearchView filterData={filter_data} onfilterChange={e => getJobFamilyList(e)} {...props} />
                </div>
            </div>
            {/* jobfamily table listing */}
            <Spin spinning={jobfamily_loading}>
                <div className='rd-table-container'>
                    <table className='rd-table'>
                        <thead>
                            <tr className='rd-row-header'>
                                <th className='rd-table-row-padding-left rd-left-radious'>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Name</h4>
                                </th>
                                <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Role</h4>
                                </th>
                                <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Generic competency</h4>
                                </th>
                                <th className='rd-table-header-label' style={{ textAlign: "center" }}>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Vertical</h4>
                                </th>
                                <th className='rd-table-row-padding-rigth rd-content-center rd-table-header-label rd-right-radious'>
                                    <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Actions</h4>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                jobfamily_list?.map(item => (
                                    <tr className='rd-row-body'>
                                        <td className='rd-table-row-padding-left rd-left-radious'>
                                            <h4 className='rd-table-body-label'>{item?.title}</h4>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <h4 className='rd-table-body-label' style={{ color: "#0098F0", cursor: "pointer" }} onClick={() => { viewSubinfo(item, 'role') }}>{item?.role?.edges?.length}</h4>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <h4 className='rd-table-body-label' style={{ color: "#0098F0", cursor: "pointer" }} onClick={() => { viewSubinfo(item, 'competency') }}>{item?.genericCompetency?.edges?.length}</h4>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <h4 className='rd-table-body-label' style={{ color: "#0098F0", cursor: "pointer" }} onClick={() => { viewSubinfo(item, 'vertical') }}>{GetVerticalCount(item?.role?.edges)}</h4>
                                        </td>
                                        <td className='rd-table-row-padding-rigth rd-content-center rd-right-radious'>
                                            <div className='rd-table-actions'>
                                                <Popover overlayClassName="rd-add-new-popover" style={{ background: "rgba(170, 186, 198, 1)" }} placement="topRight" content={"Job family detail page view"} trigger="hover">
                                                    <img className='rd-delete-icon' src={jobfamily_icons?.detail_page_view} onClick={() => detailPageNavigation(item?.id)} alt="" />
                                                </Popover>
                                                <Popover overlayClassName="rd-add-new-popover" style={{ background: "rgba(170, 186, 198, 1)" }} placement="topRight" content={"Delete job family"} trigger="hover">
                                                    <img className='rd-delete-icon' src={jobfamily_icons?.delete_icon} onClick={() => { SetJobFamilyDelete(item) }} alt="" />
                                                </Popover>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {
                        (!jobfamily_list?.length && !jobfamily_loading) ?
                            <div className='rd-nodata'>
                                <h4 className='rd-nodata-label'>No data found</h4>
                            </div> : ''
                    }
                    {
                        (jobfamily_page_info?.hasNextPage && jobfamily_page_info?.endCursor) && (
                            <RoleDefinitionpagination on_pagination={() => { getJobFamilyList({ after: jobfamily_page_info?.endCursor }) }} />
                        )
                    }
                </div>
            </Spin>
        </>
    )

}
export default JobFamilyHomeView
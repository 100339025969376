import { Col,Row} from "antd";
import React from "react";
import OKRTableContainer from "../container/OKRTableContainer";
import OKRVerticalBar from "./OkrVerticalBar";
import '../OkrStyles/OkrHome.css'
import { MetaTags } from "modules/look";
import OkrAiTipContainer from "modules/ai-tips/container/okr-ai-tip-container";
import SideBulbToolContainer from "modules/look-v2/Container/SideBulbToolContainer";
import { OKR_TOOL_ID } from "config";
const OKRHome = (props) => {
  const {permission} = props
  return (
    <div className="rd-root-style">
      {
        (permission && permission?.runpod_permission) && (
          <OkrAiTipContainer {...props} />
        )
      }
     
      <MetaTags title="OKR" description="OKR Home" />
      <OKRVerticalBar {...props} current_tab={
         {
          key: 'home',
          label: 'Objective key results',
        }
      }/>
      <Row className="okr-table-gap">
      
        <Col span={24}>
            <div style={{ position: 'relative' }}>
                <SideBulbToolContainer
                  {...props}
                  widthOfContainer={24}
                  containerBorder={""}
                  spacing={'0px'}
                  hideTopDivider={true}
                  keepDesign={true}
                  top={"104px"}
                  toolReturn={e => {}}
                  tool_id={OKR_TOOL_ID}
                />
            </div>
          <Col className='okr-tab'>
            <Col className="tab-inner" style={{ marginTop: "33px", minHeight: "550px" }}>

              <OKRTableContainer {...props} />
            </Col>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

export default OKRHome


// @ts-nocheck
import React from "react";
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc';
import OKRVerticalPopupView from "../OkrVerticalPopupView";
import { TEAMS_UNDER_VERTICAL, VERTICAL_ALL_CASCADING_OBJECTIVES, VERTICAL_ALL_NON_CASCADING_OBJECTIVES, VERTICAL_OKR_PERFORMANCE } from "modules/Objective-keyresult/graphql/VerticalDashboardQueries";
import { displayUtcToUserTimezone, getIntFromString, getVerticalBase64StringFromInt } from "modules/look";
const OkrVerticalContainerPopup=(props)=>{
    const{client,me,verticalDetailModal,dateRangeTofilter}=props
    const [okrPerformanceForVertical, setokrPerformanceForVertical] = React.useState();
    const [okrPerformanceForTeam,setokrPerformanceForTeam] = React.useState();
    const [team_manager_pop,setteam_manager] = React.useState();
    const [barloader,setBarloader]= React.useState(true)
    const [donutloader,setdonutloader]= React.useState(true)
    const [nonCascadedOkr, setNonCascadedOkr] = React.useState();
    const [cascadedOkr, setCascadedOkr] = React.useState();
    let cachedDataForTeams = React.useRef()
    React.useEffect(()=>{
        if(me && verticalDetailModal && dateRangeTofilter){
            getTeams(getIntFromString(verticalDetailModal?.id))
            getOkrPerformanceForvertical(5,0)
            getAllCascadedObjectives(me?.employee?.id,0)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[me,verticalDetailModal,dateRangeTofilter])

    const getOkrPerformanceForvertical = async (first,offset,pagination=false) => {
        setBarloader(true)
        const { data } = await client.query({
          query: VERTICAL_OKR_PERFORMANCE,
          variables: {
            okr_Duedate_Range: dateRangeTofilter,
            role_In: ["COLLABORATOR", "ASSIGNED"],
            managerId: verticalDetailModal?.managerId || [],
            savedOkrType: "OBJECTIVE",
            offset: offset,
            first: first,
            orderBy: ["okr__duedate"]
          },
          fetchPolicy: 'network-only'
        });
    
        if (data?.performance) {
            let performanceData
           if(!pagination){
            performanceData ={
              totalCount:data?.performance?.totalCount,
              edgeCount:data?.performance?.edgeCount,
              edges:data?.performance?.edges?.map(({node})=>{
                return {
                  id:node?.okr?.id,
                  title:`${node?.okr?.goalStatement} by ${displayUtcToUserTimezone(node?.okr?.duedate,'DD MMM YY')}`,
                  progress:node?.progress
                }
              })
            }
           }else{
            performanceData ={
              totalCount:data?.performance?.totalCount,
              edgeCount:data?.performance?.edgeCount+okrPerformanceForVertical?.edgeCount,
              edges:[...okrPerformanceForVertical?.edges,
              ...data?.performance?.edges?.map(({node})=>{
                return {
                  id:node?.okr?.id,
                  title:`${node?.okr?.goalStatement} by ${displayUtcToUserTimezone(node?.okr?.duedate,'DD MMM YY')}`,
                  progress:node?.progress
                }
              })]
            }
           }
           setokrPerformanceForVertical(performanceData)
           setBarloader(false)
        }
      }

    const getTeams = async(verticalid)=>{
        // setdataSourceLoader(true)
        setBarloader(true)
        const { data } = await client.query({
          query: TEAMS_UNDER_VERTICAL,
          variables: {
            id:getVerticalBase64StringFromInt(verticalid)
          },
        //   fetchPolicy: 'network-only'
        });
        if(data?.vertical){
            const vertical = data?.vertical
            let teamsList = vertical?.teamSet?.edges?.map(({node})=>{
                return {
                  id:node?.id,
                  title:node?.name,
                  managers:getTeamManagers(node?.managers)
                }
              })
              setteam_manager(teamsList)
              setBarloader(true)
        }
      }

      const getTeamManagers=(managers)=>{
        let list = managers?.edges?.map(({node})=>getIntFromString(node?.id))
        return list || []
      }
    
      const getOkrPerformanceForTeam = async (first,offset,pagination=false) => {
        setBarloader(true)
        const { data } = await client.query({
          query: VERTICAL_OKR_PERFORMANCE,
          variables: {
            okr_Duedate_Range: dateRangeTofilter,
            role_In: ["COLLABORATOR", "ASSIGNED"],
            managerId: [].concat.apply([],team_manager_pop?.map(item=>item?.managers)),
            savedOkrType: "OBJECTIVE",
            offset: offset,
            first: first,
            orderBy: ["okr__duedate"]
          },
          fetchPolicy: 'network-only'
        });
    
        if (data?.performance) {
          if(!pagination){
            cachedDataForTeams['current']=data?.performance
          }else{
            cachedDataForTeams['current']={edges:[...cachedDataForTeams['current']?.edges,...data?.performance?.edges]}
          }
            let performanceData 
            const edges= getTeamWiseProgress(!pagination?data?.performance:cachedDataForTeams['current'])
           if(!pagination){
            performanceData ={
              totalCount:data?.performance?.totalCount,
              edgeCount:data?.performance?.edgeCount,
              edges:edges
            }
           }else{
            performanceData ={
              totalCount:data?.performance?.totalCount,
              edgeCount:data?.performance?.edgeCount+okrPerformanceForTeam?.edgeCount,
              edges:edges
            }
           }
           setokrPerformanceForTeam(performanceData)
           setBarloader(false)
        }
      }
    
      const getTeamWiseProgress=(data)=>{
        let teamlistwithOKR = []
        team_manager_pop.forEach(item=>{
          let filter_data = data?.edges?.filter(({node})=>item?.managers?.includes(getIntFromString(node?.employee?.id)))
          teamlistwithOKR.push({...item,contributorSet:filter_data?.map(({node})=>node)})
        })
        let progress = teamlistwithOKR?.map(item=>{
          return{
            ...item,
            progress:getProgress(item?.contributorSet)
          }
        })
        return progress
      }
    
      const getProgress=(set)=>{
        if(!set?.length){
          return 0
        }
        let data =set?.map(item=>item?.progress)
        let progress =data.reduce((a, b) => a+ b, 0)
        return (!progress || isNaN(progress) || isNaN(progress/data?.length))?0:progress/data?.length
      }
      const getAllCascadedObjectives = async (employeeid,offset,pagination=false) => {
        setdonutloader(true)
        setBarloader(false)
        const { data } = await client.query({
          query: VERTICAL_ALL_CASCADING_OBJECTIVES,
          variables: {
            duedate_Range: dateRangeTofilter,
            role_In: ["COLLABORATOR", "ASSIGNED"],
            managerId: verticalDetailModal?.managerId || [],
            offset: offset * 100,
            first: 100
          },
          // fetchPolicy: 'network-only'
        });
    
        if (data?.AllCascadingObjectives) {
    
          if (pagination) {
            let obj = {
              edgeCount: cascadedOkr?.edgeCount + data?.AllCascadingObjectives?.edgeCount,
              edges: [...cascadedOkr?.edges, ...data?.AllCascadingObjectives?.edges],
              totalCount: data?.AllCascadingObjectives?.totalCount
            }
            setCascadedOkr(obj)
          } else {
            setCascadedOkr(data?.AllCascadingObjectives)
          }
          setdonutloader(false)
        }
      }
      
      const getAllNonCascadedObjectives = async (offset,pagination=false) => {
        setdonutloader(true)
        setBarloader(false)
        const { data } = await client.query({
          query: VERTICAL_ALL_NON_CASCADING_OBJECTIVES,
          variables: {
            id:verticalDetailModal?.id,
            duedate_Range: dateRangeTofilter,
            role_In: ["OWNER"],
            managerId: verticalDetailModal?.managerId || [],
            offset: offset * 100,
            first: 100
          },
          fetchPolicy: 'network-only'
        });
    
        if (data?.AllNonCascadingObjectives) {
            let emp_edges= data.AllNonCascadingObjectives.employees.edges.map(({node})=>{
              return node?.okrContributorSet?.edges?.map((item=>item))
            })
            let head_edges= data.AllNonCascadingObjectives.heads.edges.map(({node})=>{
              return node?.okrContributorSet?.edges?.map((item=>item))
            })
            let edges=[...emp_edges,...head_edges]
            edges = [].concat.apply([],edges) 
            var setObj = new Set(); 
          edges = edges.reduce((acc,item)=>{
            if(!setObj.has(item?.node?.okr?.id)){
              setObj.add(item?.node?.okr?.id)
              acc.push(item)
            }
            return acc;
          },[])
          let fetchedData ={edges:edges,edgeCount:edges?.length,totalCount:edges?.length}
          if (pagination) {
            let obj = {
              edgeCount: nonCascadedOkr?.edgeCount + fetchedData?.edgeCount,
              edges: [...nonCascadedOkr?.edges, ...fetchedData?.edges],
              totalCount: fetchedData?.totalCount
            }
            setNonCascadedOkr(obj)
          } else {
            setNonCascadedOkr(fetchedData)
          }
          setdonutloader(false)
        }
    
      }
    return (
        <>
           <OKRVerticalPopupView 
           donutloader={donutloader}
           barloader={barloader}
           okrPerformanceForVertical={okrPerformanceForVertical}
           getOkrPerformanceForvertical={(first,offset,pagination)=>{getOkrPerformanceForvertical(first,offset,pagination)}}
           okrPerformanceForTeam={okrPerformanceForTeam}
           getOkrPerformanceForTeam={(first,offset,pagination)=>{getOkrPerformanceForTeam(first,offset,pagination)}}
           team_manager_pop={team_manager_pop}
           getAllCascadedObjectives={(empid,offset,pagination)=>{getAllCascadedObjectives(empid,offset,pagination)}} 
           getAllNonCascadedObjectives={(e,offset,pagination)=>{getAllNonCascadedObjectives(offset,pagination)}}
           cascadedOkrModal={cascadedOkr} 
           nonCascadedOkrModal={nonCascadedOkr}
          {...props} /> 
        </>
    )
}

export default  compose(withApollo)(OkrVerticalContainerPopup)
// @ts-nocheck
import { message } from 'antd';
import { graphql } from '@apollo/client/react/hoc';
import { CREATE_OKR_MUTATION } from '../graphql/CreateOKR.gql';
import { UPDATE_DISCARD_MUTATION } from '../graphql/UpdateDiscardMutation.gql';
import { OKR_BY_ID_QUERY } from '../graphql/OKRById.gql';
import { getOKRBase64StringFromInt, subscribeToQuery } from 'modules/look';
import { CREATE_CONTRIBUTOR_MUTATION } from '../graphql/CreateContributor.gql';
import { UPDATE_OKR_MUTATION } from '../graphql/UpdateOKR.gql';
import { CREATE_DISCARD_MUTATION } from '../graphql/CreateDiscardMutation.gql';
import { DELETE_DISCARD_MUTATION } from '../graphql/DeleteDiscardMutation.gql';
import { COLLABORATOR_LIST } from '../graphql/CollaboratoresList.gql';
import { ASSIGNES_LIST } from '../graphql/AssignesList.gql';
import { DELETE_OKR_MUTATION } from '../graphql/DeleteOKR.gql';
import { CREATE_TRACKING_MUTATION } from '../graphql/CreateTracking.gql'
import { UPDATE_TRACKING_MUTATION } from '../graphql/updateTracking.gql'
import { TRACKING_LIST } from '../graphql/trackingList.gql'
import { UPDATE_CONTRIBUTOR_REQUEST_MUTATION } from '../graphql/UpdateContributorRequest.gql';
import { UPDATE_OKR_CONTRIBUTOR_MUTATION } from '../graphql/UpdateContributor.gql';
import { DELETE_CONTRIBUTOR_MUTATION } from '../graphql/DeleteContributor.gql';
import { DISCARD_REQUEST_LIST } from '../graphql/DiscardRequestList.gql';
import { USER_ROLE } from '../graphql/UserRoles.gql'
import { CREATE_ACTION_ITEM } from '../graphql/CreateActionItem.gql';
import { MESSAGE_BY_OKR_SUBSCRIPTION, OKR_MESSAGES } from '../graphql/OkrMessages.gql';
import { PENDING_COUNTS } from '../graphql/TabPendingCounts.gql';
import moment from 'moment-timezone';
import { GET_ORGANISATION_ROLES_OF_USER, GET_ROLES_OF_USER, GET_VERTICAL_ROLES_OF_USER } from '../graphql/okrDahboardQueries.gql';
import { UPDATE_CORRECTION_DEADLINE } from '../graphql/ExtendCorrectionDeadline.gql';
export const withCreateOKR = Component =>
  graphql(CREATE_OKR_MUTATION, {
    props: ({ mutate, history }) => ({
        createOkr: async values => {
        try {
          const {
            data: { createOkr }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('created successfully');
          return createOkr.OKR;
        } catch (e) {
          message.destroy();
          message.error("Failed to create");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUpdateDiscardRequest= Component =>
  graphql(UPDATE_DISCARD_MUTATION, {
    props: ({ mutate, history }) => ({
      updateDiscardRequest: async values => {
        try {
          const {
            data: { updateDiscardRequest }
          } = await mutate({
            variables: {
              ...values
            }
          });
          message.success('Submitted response!!');
          return updateDiscardRequest?.discardRequest;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
        }
      }
    })
  })(Component);
  
  export const getOKRById = Component =>
    graphql(OKR_BY_ID_QUERY , {
        options: props => {
            let id = '0';
            if (props.match) {
                id = getOKRBase64StringFromInt(props.match.params.okrId);
            } else if (props.navigation) {
                id = getOKRBase64StringFromInt(props.navigation.state.params.okrId);
            }
            return {
                variables: { id: id ,tz:moment.tz.guess()},
                fetchPolicy: 'network-only'
            };
        },
        props({ data: { loading, error, okrById } }) {
            if (error) {
                throw new Error(error.message);
            }
            return { loading, error, okrById };
        }
    })(Component);

    export const withCreateContributor = Component =>
  graphql(CREATE_CONTRIBUTOR_MUTATION, {
    props: ({ mutate, history }) => ({
      createContributor: async values => {
        try {
          const {
            data: { createContributor }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('created successfully');
          return createContributor.contributor;
        } catch (e) {
          message.destroy();
          message.error("Failed to create");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUpdateOKR = Component =>
  graphql(UPDATE_OKR_MUTATION, {
    props: ({ mutate, history }) => ({
      updateOkr: async values => {
        try {
          const {
            data: { updateOkr }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Updated successfully');
          return updateOkr.OKR;
        } catch (e) {
          message.destroy();
         
          if(e.toString().includes("Error: Correction Deadline is over.")){
            message.error("Correction Deadline is over");
          }else{
            message.error("Failed to Update");
          }
          console.error(e);
        }
      }
    })
  })(Component);


  export const withUpdateOKRCompetencies = Component =>
  graphql(UPDATE_OKR_MUTATION, {
    props: ({ mutate, history }) => ({
      updateOkr: async values => {
        try {
          const {
            data: { updateOkr }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('created successfully');
          return updateOkr.OKR;
        } catch (e) {
          message.destroy();
          // message.error("Failed to create");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUpdateContributorWeights = Component =>
  graphql(UPDATE_OKR_CONTRIBUTOR_MUTATION, {
    props: ({ mutate, history }) => ({
      updateContributor: async values => {
        try {
          const {
            data: { updateContributor }
          } = await mutate({
            variables: {
              ...values
            }
          });
          message.destroy();
          message.success('updated successfully');
          return updateContributor.contributor;
        } catch (e) {
          message.destroy();
          message.error("Failed to create");
          console.error(e);
        }
      }
    })
  })(Component);



  export const withCreateDiscardRequest= Component =>
  graphql(CREATE_DISCARD_MUTATION, {
    props: ({ mutate, history }) => ({
      createDiscardRequest: async values => {
        try {
          const {
            data: { createDiscardRequest }
          } = await mutate({
            variables: {
              ...values
            }
          });
          message.success('Submitted response!!');
          return createDiscardRequest?.discardRequest;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
        }
      }
    })
  })(Component);



  export const withDeleteDiscardRequest= Component =>
  graphql(DELETE_DISCARD_MUTATION, {
    props: ({ mutate, history }) => ({
      deleteDiscardRequest: async values => {
        try {
          const {
            data: { deleteDiscardRequest }
          } = await mutate({
            variables: {
              ...values
            }
          });
          message.success('Submitted response!!');
          return deleteDiscardRequest?.discardRequest;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
        }
      }
    })
  })(Component);

  export const withDeleteOKR= Component =>
  graphql(DELETE_OKR_MUTATION, {
    props: ({ mutate, history }) => ({
      deleteOkr: async values => {
        try {
          const {
            data: { deleteOkr }
          } = await mutate({
            variables: {
              ...values
            }
          });
          message.success('Submitted response!!');
          return deleteOkr?.OKR;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
        }
      }
    })
  })(Component);

  export const assigneList = Component =>
  graphql(ASSIGNES_LIST, {
    props({ data: { loading, error, assignes } }) {
      return { loading, error, assignes };
    }

  })(Component);

  export const collaboratorList = Component =>
  graphql(COLLABORATOR_LIST, {
    props({ data: { loading, error, collaborator } }) {
      return { loading, error, collaborator };
    }

  })(Component);

  export const withCreateTracking = Component =>
  graphql(CREATE_TRACKING_MUTATION, {
    props: ({ mutate, history }) => ({
      createTracking: async values => {
        try {
          const {
            data: { createTracking }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('created successfully');
          return createTracking.tracking;
        } catch (e) {
          message.destroy();
          message.error("Failed to create");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUpdateTracking = Component =>
  graphql(UPDATE_TRACKING_MUTATION, {
    props: ({ mutate, history }) => ({
      updateTracking: async values => {
        try {
          const {
            data: { updateTracking }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('created successfully');
          return updateTracking.tracking;
        } catch (e) {
          message.destroy();
          message.error("Failed to create");
          console.error(e);
        }
      }
    })
  })(Component);

  export const getTrackingByOKRId = Component =>
  graphql(TRACKING_LIST , {
      options: props => {
          let id = props.okr.id;
       
          return {
              variables: { okr: id },
              fetchPolicy: 'network-only'
          };
      },
      props({ data: { loading, error, trackings } }) {
          if (error) {
              throw new Error(error.message);
          }
          return { loading, error, trackings };
      }
  })(Component);


  export const withDeleteContributor= Component =>
  graphql(DELETE_CONTRIBUTOR_MUTATION, {
    props: ({ mutate, history }) => ({
      deleteContributor: async values => {
        try {
          const {
            data: { deleteContributor }
          } = await mutate({
            variables: {
              ...values
            }
          });
          message.success('deleted successfully!!');
          return deleteContributor?.contributor;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
        }
      }
    })
  })(Component);

  export const getDiscardRequestList = Component =>
  graphql(DISCARD_REQUEST_LIST , {
      options: props => {
          return {
              variables: {},
              fetchPolicy: 'network-only'
          };
      },
      props({ data: { loading, error, Incomming,Outgoing } }) {
          if (error) {
              throw new Error(error.message);
          }
          return { loading, error, Incomming,Outgoing };
      }
  })(Component);

  export const getUserRole = Component =>
  graphql(USER_ROLE, {
    props({ data: { loading, error, user_level } }) {
      return { loading, error, user_level };
    }

  })(Component);

  export const withUpdateContributorRequest = Component =>
  graphql(UPDATE_CONTRIBUTOR_REQUEST_MUTATION, {
    props: ({ mutate, history }) => ({
      updateContributorRequestStatus: async values => {
        try {
          const {
            data: { updateContributorRequestStatus }
          } = await mutate({
            variables: {
              ...values
            }
          });
          message.destroy();
          message.success('updated successfully');
          return updateContributorRequestStatus;
        } catch (e) {
          message.destroy();
          message.error("Failed to create");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withCreateActionItem = Component =>
  graphql(CREATE_ACTION_ITEM, {
    props: ({ mutate, history }) => ({
      createActionItem: async values => {
        try {
          const {
            data: { createActionItem }
          } = await mutate({
            variables: {
              ...values
            }
          });
          message.destroy();
          message.success('created successfully');
          return createActionItem.actionItem;
        } catch (e) {
          message.destroy();
          message.error("Failed to create");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withMessage = Component =>
  graphql(OKR_MESSAGES, {
    options: props => {
      return {
        variables: {okr:props?.okrId}
      };
    },
    props({ data: { loading, error, okrMessages, subscribeToMore, updateQuery, refetch } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, okrMessages, subscribeToMore, updateQuery, refetch };
    }
  })(Component);

  export const subscribeToMessage = (subscribeToMore, variables) => {
    return subscribeToQuery(
        subscribeToMore,
        {
            document: MESSAGE_BY_OKR_SUBSCRIPTION,
            subscriptionName: 'okrMessageSubscription',
            nodeName: 'okrMessage',
            queryName: 'okrMessage'
        },
        variables
    );
};


export const getTotalPendingCount = Component =>
  graphql(PENDING_COUNTS , {
      options: props => {
          return {
              variables: {employee:props?.me?.employee?.id},
              fetchPolicy: 'network-only'
          };
      },
      props({ data: { loading, error, discardCount,AssigneCount,CollabCount } }) {
          if (error) {
              throw new Error(error.message);
          }
          return { loading, error, discardCount,AssigneCount,CollabCount};
      }
  })(Component);


  export const getTeamRoles = Component =>
  graphql(GET_ROLES_OF_USER , {
      options: props => {
          return {
              // fetchPolicy: 'network-only'
          };
      },
      props({ data: { loading, error, teamRoles } }) {
          if (error) {
              throw new Error(error.message);
          }
          return { loading, error, teamRoles};
      }
  })(Component);

  export const getVerticalRoles = Component =>
  graphql(GET_VERTICAL_ROLES_OF_USER , {
    options: props => {
        return {
            // fetchPolicy: 'network-only'
        };
    },
    props({ data: { loading, error, verticalRoles } }) {
        if (error) {
            throw new Error(error.message);
        }
        return { loading, error, verticalRoles};
    }
})(Component);

export const getOrganisationRoles = Component =>
graphql(GET_ORGANISATION_ROLES_OF_USER , {
  options: props => {
      return {
          // fetchPolicy: 'network-only'
      };
  },
  props({ data: { loading, error, organisationRoles } }) {
      if (error) {
          throw new Error(error.message);
      }
      return { loading, error, organisationRoles};
  }
})(Component);

export const withUpdateCorrectionDeadline = Component =>
graphql(UPDATE_CORRECTION_DEADLINE, {
  props: ({ mutate, history }) => ({
    extendOkrCorrectionDeadline: async values => {
      try {
        const {
          data: { extendOkrCorrectionDeadline }
        } = await mutate({
          variables: {
            ...values
          }
        });
        message.destroy();
        message.success('created successfully');
        return extendOkrCorrectionDeadline.OKR;
      } catch (e) {
        message.destroy();
        message.error("Failed to update");
        console.error(e);
      }
    }
  })
})(Component);

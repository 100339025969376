import React from 'react';
// import { message } from 'antd';

import { compose } from '../../core';
import { AdminLayout, displayDataCheck, GetColumnSearchProps, getOrderBy, displayUtcToUserTimezone } from '../../look';
import { withApollo } from '@apollo/client/react/hoc';
import { withPageState, withToggleContactStatus, withPageFilterUpdating } from './PageOperations';
import { subscribeToContact } from './PageSubscriptions';
import { PAGINATION_LIMIT } from '../../../config';
import { ALL_CONTACT_REQUESTS_QUERY } from '../graphql/AllContactRequestsQuery.gql';

const Contact = props => {
  const {
    loading,
    onPaginationChange,
    // toggleContactStatus,
    onOrderByChange,
    orderBy,
    onNameChange,
    onEmailChange,
    onMessageChange,
    onFiltersRemove,
    subscribeToMore,
    filter,
    client
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [allContactRequests,setAllcontactRequests] = React.useState(undefined);
  const [paginationFilter,setPaginationFilter] = React.useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [paginationProps,setPaginationProps] = React.useState(undefined);

  React.useEffect(() => {
    const subscribe = subscribeToContact(subscribeToMore /* , props.filter */);
    return () => subscribe();
  });

  React.useEffect(()=>{
    
    if(allContactRequests){
    
      let  paginationData = !(allContactRequests && allContactRequests?.totalCount)
        ? {}
        : {
            defaultCurrent: 1,
    
            showTotal: (total, range) =>
            allContactRequests.totalCount && (
                <>
                  {total < PAGINATION_LIMIT
                    ? `Showing 1 to ${total} of ${total} entries.`
                    : `Showing ${paginationFilter?.offset + 1} to ${
                      paginationFilter?.offset + paginationFilter?.first <= total ?
                      paginationFilter?.offset + paginationFilter?.first : total
                      } of ${total} entries.`}
                </>
              ),
            onChange: (page, pageSize) =>
              getAllContactRequests({
                  offset: page * PAGINATION_LIMIT - PAGINATION_LIMIT,
                  first: PAGINATION_LIMIT
                }),
               
             
            defaultPageSize: PAGINATION_LIMIT,
            total: allContactRequests?.totalCount,
            style: {
              textAlign: 'right'
            }
          };

          setPaginationProps(paginationData)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allContactRequests])





  React.useEffect(()=>{
   let updatedFilters;
    if(filter?.name_Icontains?.length || filter?.email_Icontains?.length || filter?.message_Icontains?.length || filter?.searchText?.length){
      
      updatedFilters = {...filter}
     
    }else{
      updatedFilters = {...paginationFilter,...filter}
    }
   
    getAllContactRequests(updatedFilters)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filter])


    
  const getAllContactRequests = async (filters) => {
      
    if(filters?.first){
      setPaginationFilter({...filters})
    }
    //Added try catch because getting unhandled rejection error on /page/contact-us/admin-panel page
    try {
      const { data } = await client.query({
        query: ALL_CONTACT_REQUESTS_QUERY,
        variables: {...filters, orderBy } ,
        fetchPolicy: 'network-only',
      });
      
      if (data?.allContactRequests) {
        setAllcontactRequests(data?.allContactRequests);
      }
    } catch (error) {
      console.error('Error in fetching all contact requests in Contact.jsx', error);
    }
}

  


  const columns = [
    {
      title: <span style={{whiteSpace:"nowrap",textAlign:"left"}}>Name</span>,
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
      ...GetColumnSearchProps('name', onNameChange),
      render: (text, record) => <>{record && displayDataCheck(record.name)}</>
    },
    {
      title: <span style={{whiteSpace:"nowrap"}}>Email</span>,
      key: 'email',
      sorter: () => onOrderByChange(getOrderBy('email', orderBy)),
      ...GetColumnSearchProps('email', onEmailChange),
      render: (text, record) => <>{record && displayDataCheck(record.email)}</>
    },
    {
      title: <span style={{whiteSpace:"nowrap"}}>Contact Type</span>,
      key: 'contactType',
      sorter: () => onOrderByChange(getOrderBy('contactType', orderBy)),
      // ...GetColumnSearchProps('contactType', onMessageChange),
      render: (text, record) => <>{record && displayDataCheck(record.contactType)}</>
    },
    {
      title: <span style={{whiteSpace:"nowrap"}}>Team Size</span>,
      key: 'teamSize',
      sorter: () => onOrderByChange(getOrderBy('teamSize', orderBy)),
      // ...GetColumnSearchProps('contactType', onMessageChange),
      render: (text, record) => <>{record && displayDataCheck(record.teamSize)}</>
    },
    {
      title: <span style={{whiteSpace:"nowrap"}}>Message</span>,
      key: 'message',
      sorter: () => onOrderByChange(getOrderBy('message', orderBy)),
      ...GetColumnSearchProps('message', onMessageChange),
      render: (text, record) => <>{record && displayDataCheck(record.message)}</>
    },

    {
      title: <span style={{whiteSpace:"nowrap"}}>Request Date</span>,
      key: 'requestDate',
      sorter: () => onOrderByChange(getOrderBy('requestDate', orderBy)),
      render: (text, record) => (
        <>{`${displayUtcToUserTimezone(record.requestDate, "DD MMM YYYY")}`}</>
      )
    },
    {
      title: <span style={{whiteSpace:"nowrap"}}>Complete Date</span>,
      key: 'completeDate',
      sorter: () => onOrderByChange(getOrderBy('completeDate', orderBy)),
      render: (text, record) => (
        <>{`${displayUtcToUserTimezone(record.completeDate, "DD MMM YYYY")}`}</>
      )
    },

  ];

  return (
    <AdminLayout
      table={true}
      title="Contact"
      loading={loading || submitLoading}
      tableData={allContactRequests}
      columns={columns}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
    />
  );
};

export default compose(withPageState, withToggleContactStatus, withPageFilterUpdating, withApollo)(Contact);

/* eslint-disable array-callback-return */

import { useMutation } from "@apollo/react-hooks";
import { Dropdown, Menu, message, Modal, Row, Table } from "antd";
import gql from "graphql-tag";
import { getIntFromString } from "modules/look";
import React from "react";
import { Bars_Home_Icons } from "../Icon";
import BarsDeleteConfirmation from "./BarDeleteConfirmation";
import { BarsDropdownButton } from "./BarsHomeView";
import bars_route from "../route";





const BarsExpandedComponent = (props) => {
    const { record, renderStatus, renderIncompleteBarsAction,   allEmployeeLevels, 
 allRatingScales, subCompetencyBarToDelete, setSubcompetencyBarToDelete, data ,navigateRoute} = props

   const [competencySet,setCompetencySet] = React.useState(undefined);
   const [empLevel, setEmpLevel] = React.useState(undefined);
   

   



  const DELETE_DEFINITION_SUB_COMPETENCY = gql`
  mutation deleteBehaviourallyAnchoredRating {
    ${subCompetencyBarToDelete?.length && subCompetencyBarToDelete
      ?.map(
        (item) =>
          `delete${item.intId}: deleteBehaviourallyAnchoredRating(behaviourallyAnchoredRatingId: "${item.intId}") {
          behaviourallyAnchoredRating{
            id
          }
         }
       `
      )
      .join('')}
  }
  `;
  

  const [deleteDefinitionSubCompetency] = useMutation(DELETE_DEFINITION_SUB_COMPETENCY);
  

   const handleDeleteSubCompetencyBar = async () => {
    
  
    const response = await deleteDefinitionSubCompetency()
    if (response?.data) {
      
      setSubcompetencyBarToDelete(undefined)
      message.success('Submitted successfully');
    }
   
  }

   const getStatusOfBar = (data) =>{

    if(data?.length === (allEmployeeLevels?.length * allRatingScales?.length))
       {
        return 'completed'
       }else{
        return 'Pending'
       }

  }


  const getLevelsWithstatus = (data) => {
  
    let countOfLevels = getCountOfLevel(data);
    

    let levelsWithStatus = allEmployeeLevels?.map((level,index)=>
    
    { 
     
      if(countOfLevels[level?.id] === allRatingScales?.length ){
        return {...level,status:'completed',selectedLevel:false }
      }else{
        return {...level,status:'Pending',selectedLevel:false }
      }
    }

    )
  

    return levelsWithStatus;
  
  }


  const getCountOfLevel = (data) => {
    
    const count = {};

    data.forEach(element => {
      if (count[element?.employeeLevel?.id]) {
        count[element?.employeeLevel?.id] += 1;
      } else {
        count[element?.employeeLevel?.id] = 1;
      }
    });

    return count

  }



  const getBarsRecordGenerated = (data) => {
    
    let subCompetencies = data?.map(( node ) => node)?.filter((value) => value !== null);
   
    const key = 'id';
    
    let allBars = subCompetencies?.map((node) =>
       
      data?.map((record) => {
       
        if (record?.id === node?.id) {
          
          return {
            id: node?.id,
            title: node?.title,
            barsSet: record?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => { return {...node,intId: getIntFromString(node?.id) }} ),
            status:getStatusOfBar(record?.behaviourallyanchoredratingSet?.edges),
            employeeLevel: getLevelsWithstatus(record?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node)),
            selectedLevel:getLevelsWithstatus(record?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node))[0],
            
          }
        }
      }
      )

    )?.flat()?.filter((value)=> value !== undefined);
   
    const uniqueBars = [...new Map(allBars.map(item => [item[key], item])).values()];
    
    return uniqueBars;
  }
   

  const handleDeleteRecords = (data) =>{
    setSubcompetencyBarToDelete(data?.barsSet)
  }
   
   
   
   React.useEffect(()=>{
        if(record){
              let list = getBarsRecordGenerated(record)
              setCompetencySet(list);
            }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[record])

    const handleEditSubCompetencyBars = (record) => {

      localStorage.setItem('mainCompetencyData',JSON.stringify(data?.barsSet))
      if(empLevel){
        navigateRoute(bars_route?.edit_bars_sub_competency?.replace(":bar_id",getIntFromString(record?.id))?.replace("/:level_id",''))
      }else{
        navigateRoute(bars_route?.edit_bars_sub_competency?.replace(":bar_id",getIntFromString(record?.id))?.replace("/:level_id",''))
      }
       
    
      }



  const handleLevelSelectionSubCompetency = (record, bar,index) => {

    let list = [].concat(competencySet);
    let doc = list.find((value) => value?.id === bar?.id);
    setEmpLevel(index)
    if (doc) {
      doc.selectedLevel = record; 
      doc.employeeLevel.forEach((level) => {
        if (level?.id === record?.id) {
          return level.selectedLevel = true
        } else {
          return level.selectedLevel = false
        }
      }
      )
 
      setCompetencySet(list)
     
    }
   


}


const getClassesOfElementSubComp = (node) => {

  let string;
  if (node.selectedLevel) {

  }
  switch (node.selectedLevel) {
    case true:
      if (node?.status === "completed") {
        string = 'bars-filter-element poppins bars-selected-level'
      } else {
        string = 'bars-filter-element-incomplete poppins bars-selected-level'
      }
      break;
    case false:
      if (node?.status === "completed") {
        string = 'bars-filter-element poppins'
      } else {
        string = 'bars-filter-element-incomplete poppins'
      }
      break;
    default:
      break;
  }

  return string;

}


  // Employee level dropdown
  const empLevelDropDownOverlaySubComp = (record) => {

    let allEmployeeLevels = [].concat(record?.employeeLevel);



    return (

      <Menu className="filter-overlay-container">
        {
          allEmployeeLevels?.map((node,index) =>
            <Menu.Item className={`text-ellipsis ${getClassesOfElementSubComp(node)}`}  key={node?.node?.id} onClick={(e) => handleLevelSelectionSubCompetency(node, record,index)}>
              {node?.name}
            </Menu.Item>
          )
        }
      </Menu>
    )
  }

    


        const columns = [

            {
                title: <div>Competency</div>,
                key: 'Competency',
                align: "left",
                width: 150,
                render: (text, record) =>
                <div className="bars-table-attribute">{record?.title}</div>
            }
            ,
            {
                title: <div >Level</div>,
                key: 'Level',
                align: "center",
                width: 30,
                render: (text, record) => record &&
                    <div className="bars-expanded-levels-container">
                        <Dropdown overlay={empLevelDropDownOverlaySubComp(record)} overlayStyle={{maxWidth:'25%'}}>
                            <BarsDropdownButton >
                                <Row justify="center" onClick={(e) => handleLevelSelectionSubCompetency(record)} align="middle" >{record?.selectedLevel ? record?.selectedLevel?.name : 'Level'}</Row>
                            </BarsDropdownButton>
                        </Dropdown>
                    </div>
    
            },
            {
                title: 'Status',
                key: 'Action',
                width: 50,
                render: (text, record) => record &&
                <div>{renderStatus(record)}</div>
            },
            {
                title: 'Action',
                key: 'Action',
                width: 170,
                render: (text, record) => record &&
                record?.status === "completed" ?
                <Row wrap={false} style={{ gap: '10px' }} justify='center'>
                  <img className="pointer bars-action-icon" onClick={()  => navigateRoute(bars_route?.bars_sub_competency_detail?.replace(":id",getIntFromString(record?.id)))} src={Bars_Home_Icons.detail_page_view} alt=""/>
                  <img className="pointer bars-action-icon" onClick={(e) => handleEditSubCompetencyBars(record)} src={Bars_Home_Icons.edit_role_definition} alt=""/>
                  <img className="pointer bars-action-icon" onClick={(e) => handleDeleteRecords(record)} src={Bars_Home_Icons.delete_icon} alt=""/>
                </Row>
                :
                renderIncompleteBarsAction(record,false,data)
            }
        ];
    

        

    return (

        <>
             <Table
                className="bars-expanded-table"
                size={'middle'}
                showHeader={true}
                pagination = {false}
                dataSource={
                    competencySet
                        ? competencySet
                        : []
                }

                columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
            /> 


         <Modal visible={subCompetencyBarToDelete?.length}
          footer={null}
          centered
          destroyOnClose={true}
          closable={false}>
          <BarsDeleteConfirmation
            message={'Are you sure you want to delete this BARS ?'}
            icon={Bars_Home_Icons.warning_bar}
            onConfirm={() => setSubcompetencyBarToDelete(undefined)}
            onCancel={() => handleDeleteSubCompetencyBar()}
            confirm_action_label='Cancel'
            cancel_action_label='Delete'
            confirm_class="bars-cancel-action"
            cancel_class="bars-confirm-action-delete"
          />
        </Modal>
        </>

    );
};

export default BarsExpandedComponent;
import React from 'react';
import { compose } from '../../core';
import CreateNotificationView from '../components/CreateNotificationView';
import { withApollo } from '@apollo/client/react/hoc';
import { EMPLOYEE_DETAIL } from '../graphql/EmployeDetail.gql';
import { withCreateNotification } from './NotificationOperation';
const CreateNotificationContainer = props => {
    const {client,me,history}=props
    const [employees,setEmployees] = React.useState([]) 
    const getEmployeeDetail = async()=>{
      const { data } = await client.query({
            query: EMPLOYEE_DETAIL,
        });
        if(data?.me){
          let Detail =data?.me?.employee
          if(Detail?.teamManagerEmployee?.totalCount || Detail?.orgCeoEmployee?.totalCount || Detail?.orgPocEmployee?.totalCount || Detail?.verticalHeadEmployee?.totalCount){
            let employees =[]
            if(Detail?.orgCeoEmployee?.totalCount){
             
              employees=employees.concat(Detail?.orgCeoEmployee?.edges?.map(({node})=>node?.employees?.edges))
            }
            if(Detail?.teamManagerEmployee?.totalCount){
              employees=employees.concat(Detail?.teamManagerEmployee?.edges?.map(({node})=>node?.employees?.edges))
            }
            if(Detail?.orgPocEmployee?.totalCount){
          
              employees=employees.concat(Detail?.orgPocEmployee?.edges?.map(({node})=>node?.employees?.edges))
            }
            if(Detail?.verticalHeadEmployee?.totalCount){
              employees=employees.concat(Detail?.verticalHeadEmployee?.edges?.map(({node})=>node?.employees?.edges))
            }
        
            
            employees = [].concat.apply([],employees) 
            var setObj = new Set(); // create key value pair from array of array

              var result = employees.reduce((acc,item)=>{
                if(!setObj.has(item.node?.user?.id)){
                  setObj.add(item.node?.user?.id)
                  acc.push(item)
                }
                return acc;
              },[])

            setEmployees(result)
          }
          else{
            history.push('/home')
          }
         
        }
    }

    
  React.useEffect(()=>{
    if(me){
        getEmployeeDetail()
    }
    else if (me===null){
      history.push('/user/login')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[me])

    return (
        <>
        <CreateNotificationView {...props} employees={employees}/>
        </>
      );
}

export default compose(withApollo,withCreateNotification)(CreateNotificationContainer);
import React from "react";
import { Col, Dropdown, Row, Button, Menu, Comment, Avatar, Spin, Input} from "antd";
import { MoreOutlined, UserOutlined } from "@ant-design/icons";

import { Capitalize, getIntFromString } from "../../look";

const { TextArea } = Input;

const CommentSectionView = props => {
  const [value, setValue] = React.useState(undefined);
  const { me, loading, comments, onSubmit, commentRef, onDelete, admin } = props;
  const [invalidInput, setInvalidInput] = React.useState(false)
  const menu = id => (
    <Menu>
      <Menu.Item key="1" onClick={() => onDelete(getIntFromString(id))} danger={true}>
        Delete
      </Menu.Item>
      {/* <Menu.Item key="2" >Select</Menu.Item> */}
    </Menu>
  );

  const handleSubmit = () => {
    
    if (value?.trim() === "") {
      setInvalidInput(true)
    } else {
      onSubmit(value);
      setValue("");
    }

  }

  React.useEffect(() => {

    if (value?.trim() !== "" && invalidInput) {
      setInvalidInput(false);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])


  return (
    <Spin spinning={loading} size="small">
      <Row>
        {!admin && <Col lg={2} xs={0} />}
        <Col lg={admin ? 24 : 20} xs={24}>
          {!admin && (
            <>
              <br />
              <TextArea ref={commentRef} rows={4} onChange={e => setValue(e.target.value)} value={value} />
              {
                invalidInput ?
                  <div>
                    <p className="poppins" style={{ fontSize: '13px', color: 'red', marginTop: '10px' }}>Please enter a valid input</p>
                  </div>
                  :
                  <div>
                    <br />
                    
                  </div>
              }

              <Row justify="start">
                <Button
                  htmlType="submit"
                  /* loading={submitting} */ onClick={() => {

                    handleSubmit()

                  }}
                  type="primary"
                  ghost
                >
                  Add Comment
                </Button>
              </Row>
              <br />
            </>
          )}
          <Row>
            {comments?.edges.length > 0 &&
              comments?.edges?.map(({ node: item }) => (
                <Col span={admin ? 12 : 24}>
                  {item && item.user && me && (
                    <Comment
                      datetime={
                        getIntFromString(item.user.id) === getIntFromString(me.id) && (
                          <Dropdown overlay={() => menu(item.id)} placement="bottomLeft">
                            <MoreOutlined
                              style={{
                                fontSize: "large"
                              }}
                            />
                          </Dropdown>
                        )
                      }
                      author={
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a>
                          {item.user.firstName && item.user.lastName
                            ? `${Capitalize(item.user.firstName)} ${Capitalize(item.user.lastName)}`
                            : item.user.username}
                        </a>
                      }
                      avatar={<Avatar icon={<UserOutlined />} alt="Han Solo" />}
                      content={<p align="left">{item.commentText}</p>}
                    // datetime={
                    //   <Tooltip title={moment().format('YYYY-MM-DD HH:mm:ss')}>
                    //     <span>{moment().fromNow()}</span>
                    //   </Tooltip>
                    // }
                    />
                  )}
                </Col>
              ))}
          </Row>
        </Col>
        {!admin && <Col lg={2} xs={0} />}
      </Row>
    </Spin>
  );
};

export default CommentSectionView;

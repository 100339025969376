import React from 'react';
import gql from 'graphql-tag';
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc'
import SelectField from './SelectField';
import { Capitalize, getIntFromString } from '../functions';

export const LIST_ALL_USERS_QUERY = gql`
  query getNonOrgEmployees($fnameIcontains: String) {
    getNonOrgEmployees(fnameIcontains:$fnameIcontains) {
      edges {
        cursor
        node {
          id
          user{
            id
            firstName
            lastName
            username
            email
          }
        }
      }
    }
  }
`;
const UsersField = props => {
  const { id = true, client, me } = props;
  const [ choice_list, setChoiceList ] = React.useState()
  const [ choice_loading, setChoiceLoading ] = React.useState( false )
  React.useEffect( () => {
    if(me){
    getUserList()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me] )

  const getUserList = async ( e ) => {
    setChoiceLoading( true )
    try {
      const { data } = await client.query( {
        query: LIST_ALL_USERS_QUERY,
        fetchPolicy: 'network-only',
        variables: { fnameIcontains: e }
      } );
      if ( data?.getNonOrgEmployees.edges.length ) {
        let choices =data?.getNonOrgEmployees?.edges?.map( ( { node } ) => ( {
            label: node.user.firstName && node.user.lastName ? `${Capitalize(node.user.firstName)} ${Capitalize(node.user.lastName)}` : node.user.username,
            value: id ? getIntFromString( node.user.id ) : `${node.user.email},${node.user.firstName},${node.user.lastName},${node.user.username}`
          } ) );
        setChoiceList( choices );
        setChoiceLoading( false )
      }
    }
    catch ( e ) {
      setChoiceLoading( false )
    }
  }

  return (
      <SelectField {...props} loading={choice_loading} choices={choice_list} showSearch={true} onSearch={( e ) => getUserList( e )} />
  );
};

export default compose( withApollo )( UsersField );

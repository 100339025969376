// @ts-nocheck
import React from 'react'
import { Dropdown} from 'antd';
import dropdown_Arrow from '../../assets/icons/drop_down_arrow.svg'

const MultiSelectFormDropdown = (props) => {

  const { onSelect, selected_data, placeholder, onSearch, choice_list,add_btn,on_add,remove_add } = props
  const container_ref = React.useRef(null);
  // to handle the event propogation to stop the dropdown from closing after each clicks and set the data
  const changeSelection=(event,data)=>{
    onSelect(data)
    event.stopPropagation()
  }

  // content to be rendered inside the dropdown
  const menuOverlay = () => (
    <div className='custom-multiselectdropdown-menu' style={{width:`${container_ref.current?.offsetWidth}px`||"100%"}}>
      <div style={{width:"100%",padding:"1em"}} className='custom-multiselectdropdown-scroll'>
      {
        choice_list?.map(item => (
          <div key={item?.id} className='custom-multiselectdropdown-choice' onClick={(e)=>{changeSelection(e,item)}}>
           <h4 className='multiselectdropdown-choice-label'>{item?.label}</h4>
            {!remove_add&&<button className={`multiselectdropdown-choice-selection ${selected_data?.includes(item?.id)?'active-choice-selection':''}`}>+</button>}
          </div>
        ))
      }
      {
        !choice_list?.length?
        <div align="center"> <h4 className='rd-nodata-label'>No data found</h4> </div>
        :''
      }
      </div>
      {
        (add_btn && on_add)&& (
          <div className='custom-multiselectdropdown-add'>
             <span style={{cursor:'pointer',textAlign:"center"}} onClick={()=>{on_add()}}>+ {add_btn}</span>
          </div>
        )
      }
    </div>
  );

  return (
    <Dropdown overlay={menuOverlay} overlayClassName='content-dropdown' trigger='click'>
      <div className='custom-multiselectdropdown' style={{borderRadius:"8px"}} ref={container_ref}>
        <input onChange={(e) => { onSearch(e?.target?.value) }} className='custom-multiselectdropdown-search' placeholder={placeholder} ></input>
        <div className='custom-multiselectdropdown-icon'>
          <img src={dropdown_Arrow} alt="" style={{width:"1.2em"}}/>
        </div>
      </div>
    </Dropdown>
  )

}

export default MultiSelectFormDropdown
import React from "react";

// import { withMe } from './UserOperations';
import { compose } from "../../core";
import PersonalDashboardView from "../components/PersonalDashboardView";

import { withMe } from "../../user/containers/UserOperations";
import { globalPermissionValidator } from "modules/look";
import { story_permission } from "Permissions/story.permission";

const PersonalDashboard = (props) => {
  const  {userPermission} = props
  const [storypermission,setStorypermission]=React.useState()
  React.useEffect(()=>{
    if(userPermission?.length){
      let permission_list=globalPermissionValidator(story_permission,userPermission)
      setStorypermission(permission_list)
    }
    else{
      setStorypermission({})
    }
  },[userPermission])
  return <PersonalDashboardView {...props} storypermission={storypermission}/>;
};

export default compose(withMe,)(PersonalDashboard);

// modificatins on line
// 14
// 6

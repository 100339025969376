import React from "react";
import close from '../../assets/icons/popup_close.svg'
import { Select, Button, Input,Spin,message } from "antd";
import { Capitalize } from "modules/look/components/functions";
const ManagerSelectionView = (props) => {
    const { onClose, manager_list,list_loading,getManagerList,onSubmit } = props
    const [manager_employee_id, SetManagerId] = React.useState()
    const [manager_employee_email, SetManagerEmail] = React.useState()
    const Option = Select.Option;

    React.useEffect(() => {
        if (manager_employee_id !== "other") {
            SetManagerEmail(null)
        }
    }, [manager_employee_id])

    const SubmitManager=()=>{
        if(!manager_employee_id){
            message.error("Please select your manager")
            return
        }

        if(manager_employee_id==="other" && !manager_employee_email){
            message.error("Please enter your managers email")
            return
        }

        if(manager_employee_id==="other" && !manager_employee_email?.includes('@')){
            message.error("Invalid email")
            return
        }
        let data
        // Do not change the submission format if you want you can add new fields but do not change or remove existing feild
        if(manager_employee_id!=="other"){
            let manager = manager_list?.find(i=> String(i?.user_id) === String(manager_employee_id))
            if(manager){
                data = {
                    email_user:null,
                    employee_id:manager?.employee_id,
                    user_id:manager?.user_id,
                    label:manager?.name
                }
            }
        }else{
            data = {
                email_user:manager_employee_email,
                label:manager_employee_email
            }
        }
        onSubmit(data)
    }

    return (
        <>
            <div className="row-space-between">
                <h4 className="idp-form-label" style={{ fontWeight: "500" }}>Select Mentoring Manager</h4>
                <img src={close} alt="close" onClick={() => { onClose() }} style={{ cursor: "pointer" }} />
            </div>
            <p className='idp-note' style={{ width: "75%" }}>
                If you're looking for guidance and mentorship from another manager, select another mentoring manager for this IDP.
            </p>
            <Spin spinning={list_loading}>
            <div className="idp-select-container">
                <Select
                    className="idp-select"
                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                    placeholder="Search or Select your Manager name"
                    showSearch={true}
                    filterOption={false}
                    onSelect={(e) => { SetManagerId(e) }}
                    onSearch={(e)=>getManagerList({user_FirstName:e})}
                >
                    
                    {
                        manager_list?.map(i => (
                            <Option
                                key={i?.user_id}
                                value={i?.user_id}
                            >
                                {Capitalize(i?.name)}
                            </Option>
                        ))
                    }
                    <Option
                        key={"other"}
                        value={"other"}
                    >
                        Other
                    </Option>
                </Select>
            </div>
            </Spin>
            {
                manager_employee_id === "other" && (
                    <Input className='idp-input' style={{ marginTop: "2.5em" }} onChange={(e) => { SetManagerEmail(e?.target?.value) }} placeholder='Enter your managers email id'></Input>
                )
            }

            <div style={{ display: 'flex', justifyContent: "end", marginTop: "2.5em" }}>
                <Button className='idp-cancel idp-btn' onClick={() => { onClose() }} >Cancel</Button>
                <Button className='idp-submit-outline idp-btn' onClick={(e)=>SubmitManager()}>Save</Button>
            </div>
        </>
    )
}

export default ManagerSelectionView;

// @ts-nocheck
import { Button, Form, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { competency_icons } from '../icon';
import { getIntFromString } from 'modules/look';
import _ from 'lodash';

const CompetencyAddEditView = ( props ) => {
    const { onSubmit, competency_category_list, competency_detail, current_comptency_tab, onCancel, getCompetencyList, competency_list, getCompetencyCategoryList,
        invalidInput ,setInvalidInput} = props
    const { Option } = Select;
    const [ show_competecny_list, setShowCompetecnyList ] = React.useState( false );
    const [ selected_competency, setSelectedCompetency ] = React.useState()
    const [ form ] = Form.useForm();
    const [category_select,SetCategorySelect] = React.useState()
    const [refresh,setRefresh] = React.useState(false)
    const search_api_debounce = React.useRef()
    React.useEffect(()=>{
        if(category_select){
            let category = competency_category_list?.find(i=> String(i?.id) === String(category_select))
            if(category){
                changetype(category)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[category_select])

    React.useEffect(()=>{
        if(current_comptency_tab?.main_competency){
            changetype(current_comptency_tab?.main_competency)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[current_comptency_tab?.main_competency])

    const changetype = (category)=>{
        if(category?.competencyType === "GENERIC"){
            form.setFieldsValue(_.set(form.getFieldsValue(true), 'type', 'Generic'));
        }else{
            form.setFieldsValue(_.set(form.getFieldsValue(true), 'type', 'Role Specific'));
        }
        setRefresh(!refresh)
    }

    React.useEffect( () => {
        if ( competency_list?.length > 0 ) {
            setShowCompetecnyList( true )
        }
        else {
            setShowCompetecnyList( false )
        }
    }, [ competency_list ] )

    React.useEffect( () => {
        
        if ( selected_competency ) {
            form.setFieldsValue( {
                title: selected_competency?.title,
                competency_category: selected_competency?.competencyCategory?.id,
                type:
                    selected_competency?.competencyType === 'ROLE_SPECIFIC'
                        ? 'Role Specific'
                        : selected_competency?.competencyType === 'GENERIC'
                            ? 'Generic'
                            : '',
                description: selected_competency?.description,
            } );
        }
    }, [ selected_competency, form ] )

    React.useEffect( () => {
        if ( current_comptency_tab?.competency_id ) {
            if ( competency_detail ) {
                form.setFieldsValue( {
                    title: competency_detail?.title,
                    competency_category: competency_detail?.competencyCategory?.id,
                    type: competency_detail?.competencyType === 'ROLE_SPECIFIC'
                        ? 'Role Specific'
                        : competency_detail?.competencyType === 'GENERIC' ?
                            'Generic'
                            : '',
                    description: competency_detail?.description
                }
                )
            }
        }
        else {
        }
    }, [ competency_detail, current_comptency_tab, form ] );

    const renderCompetency = () => {
        return (
            <div className='rd_competency_drop_render' style={{ marginTop: ".5em" }}>
                {competency_list?.map( item => (
                    <h4 className={getIntFromString( selected_competency?.id ) === getIntFromString( item?.id ) ? 'rd-selected-option' : 'rd-competency-option'} style={{ cursor: "pointer", margin: "0px" }} onClick={() => { setSelectedCompetency( item ); setShowCompetecnyList( false ) }}>{item?.title}</h4>
                ) )}
            </div>
        )
    }

    const searchCompetency = ( text ) => {
        if ( text ) {
            setShowCompetecnyList( true )
            if (search_api_debounce.current) {
                clearTimeout(search_api_debounce.current)
            }
            search_api_debounce.current = setTimeout(() => {
            setSelectedCompetency()
            getCompetencyList( { title_Icontains: text } )
            }, 700);
        }
        else {
            setShowCompetecnyList( false )
            setSelectedCompetency()
        }
    }

    const handleTitleInput = (e) => {

        if (e.target.value?.length > 200) {
            setInvalidInput(true)
        } else {
            setInvalidInput(false);
        }

    }

    return (
        <>
            <Form
                form={form}
                onFinish={values => onSubmit({ data: values, type: selected_competency ? "update_exist" : "create_new" })}
                scrollToFirstError={true}
                className='rd-align-center rd-add-edit-competency-container'
                style={{ gap: '1em' }}
            >

                <div className='rd-input-container'>
                    <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Title</h4>
                    {current_comptency_tab?.main_competency ?
                        <div style={{ width: "100%", position: "relative" }}>
                            <Form.Item
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input title',
                                    },
                                ]}>
                                <Input
                                    className="rd-text-input"
                                    placeholder='Enter competency title'
                                    value={selected_competency?.title}
                                    onChange={e => (searchCompetency(e.target.value))} />
                            </Form.Item>
                            <div style={{ width: "100%", position: 'absolute', zIndex: "1100", marginTop: "-2em" }}>
                                {show_competecny_list && renderCompetency()}
                            </div>
                        </div> :
                        <Form.Item
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input title',
                                },
                            ]}
                            style={{ width: '100%' }}
                        >
                            <Input onChange={(e) => handleTitleInput(e)} className="rd-text-input" placeholder='Enter competency title' />
                        </Form.Item>}
                    {
                        invalidInput &&
                        <p className='poppins' style={{ color: 'red', fontSize: '1em', marginTop: '-2.3em',marginLeft:'0.8em' }}>Whoops, looks like the title is bit too long! </p>
                    }
                </div>

                <div className='rd-input-container' style={{width:"100%"}}>
                    <h4 className='rd-input-title'> <span className='rd-input-star'>*</span> Competency</h4>
                    {current_comptency_tab?.main_competency ?
                        <Input className="rd-text-input" value={current_comptency_tab?.main_competency?.title} readOnly style={{ maxWidth: "99%" }} />
                        : <Form.Item
                            name="competency_category"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select category',
                                },
                            ]}
                            style={{ width: '100%' }}

                        >
                            <Select
                                className="rd-select-filed rd-type-select"
                                style={{ textOverflow: "ellipsis", whiteSpace: "nowrap",maxWidth:'100%' }}
                                disabled={current_comptency_tab?.competency_id ? true : false}
                                suffixIcon={<img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                                showSearch
                                onSearch={(e) => getCompetencyCategoryList({ title_Icontains: e })}
                                filterOption={false}
                                placeholder='select competency category'
                                onChange={(e)=>{SetCategorySelect(e)}}
                                {...props}
                            >
                                {competency_category_list?.map(value => (
                                    <Option
                                        className='rd-select-option'
                                        key={value?.id}
                                        value={value?.id}
                                        
                                    >
                                        {value?.title}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }
                </div>

                <div className='rd-input-container'>
                    <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Type</h4>
                    <Form.Item
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: 'Please select type',
                            },
                        ]}
                        style={{ width: '100%' }}
                    >
                        <Input readOnly={true} className="rd-text-input" placeholder='Competency type' />
                    </Form.Item>
                </div>

                <div className='rd-input-container'>
                    <h4 className='rd-input-title'>Description</h4>
                    <Form.Item
                        name="description"
                        style={{ width: '100%' }}
                    >
                        <TextArea name='description' className="rd-text-area" placeholder='Enter competency description' />
                    </Form.Item>
                </div>

                <div className='rd-confirmation-action-container'>
                    <Button disabled={invalidInput} className='global-submit-btn' htmlType="submit">Save</Button>
                    <Button className='global-cancel-btn' onClick={() =>(current_comptency_tab?.main_competency && !current_comptency_tab?.competency_id)? onCancel('competencymain_home_view'):onCancel('competency_home_view')}>Cancel</Button>
                </div>

            </Form>
        </>
    )

}

export default CompetencyAddEditView
import { Col, Divider, Row, Spin, Switch } from 'antd';
import React from 'react';

import { IMG } from '../../../config';
import { CardLayout, getIntFromString, ReactMarkdown } from '../../look';

import ToolAssign from '../containers/ToolAssign';
import ROUTE from '../route';

import CommitmentSection from './CommitmentSection';
import TextFormSectionComponent from './TextFormSectionComponent';

const ToolDetailView = props => {
  const {
    loading,
    getToolByIdAdmin,
    editTool,
    onCommitmentDelete,
    actionLoading,
    setActionLoading,
    onTextSectionDelete,
    onFormSectionDelete,
    subscribeToMore,
    me,
    commitmentSet,
    setCommitmentSet
  } = props;
  const [assign, setAssign] = React.useState(false);
  const [toolDetails, setToolDetails] = React.useState(undefined);

  React.useEffect(() => {
    if (getToolByIdAdmin) {
      setCommitmentSet(getToolByIdAdmin?.commitment);
      setToolDetails(getToolByIdAdmin)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToolByIdAdmin])


  const handleEditTool = async (e, field) => {
    setActionLoading(true);
    let data = {};
    if (field === 'public') data = { public: e };
    if (field === 'status') data = { status: e };
    if (field === 'commitment')
      data = {
        commitment: [e, ...(commitmentSet?.edges?.map(({ node }) => getIntFromString(node?.id)) || [])]
      };
    if (field === 'textsection')
      data = {
        textsection: [e, ...(toolDetails.textsection?.edges?.map(({ node }) => getIntFromString(node?.id)) || [])]
      };
    if (field === 'formsection')
      data = {
        formsection: e
      };
    try {
      const response = await editTool({
        id: Number(getIntFromString(toolDetails.id)),
        name: toolDetails.name,
        ...data
      });
      setActionLoading(false);
      return response;
    } catch (e) {
      setActionLoading(false);
      throw new Error(e.message);
    }
  };

  
  return (
    <CardLayout
      active={ROUTE.survey}
      title={[
        {
          name: toolDetails?.title || ''
          // link: `${ROUTE.surveyDetailLink}${getToolByIdAdmin && getIntFromString(getToolByIdAdmin.id)}`
        }
      ]}
      showTitle={false}
      editLink={ROUTE.editToolLink}
      addLink={ROUTE.addTool}
      backLink={ROUTE.tool}
      // onFiltersRemove={onFiltersRemove}
      showBack={false}
      table={false}
      rowKey={toolDetails && toolDetails.id}
    >
      <Row>
        <Col span={24}>
          <Spin spinning={loading || actionLoading} size="large">
            <div style={{ minHeight: '70vh' }}>
              {toolDetails && (
                <>
                  <Row gutter={[24, 24]}>
                    <Col lg={8} md={6} xs={24}>
                      <Row gutter={[24, 24]} justify="center">
                        <Col align="center">
                          <img alt={toolDetails.title} src={toolDetails.thumbnailImage || IMG} width="100%" />
                        </Col>
                      </Row>
                      <br />

                      <Row justify="space-between" align="bottom" gutter={[24, 24]}>
                        <Col>
                          Public
                          <br />
                          <Switch
                            checked={toolDetails.public}
                            size="small"
                            onClick={e => handleEditTool(e, 'public')}
                          />
                        </Col>

                        <Col>
                          Published
                          <br />
                          <Switch
                            checked={toolDetails.status}
                            size="small"
                            onClick={e => handleEditTool(e, 'status')}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col span={1}>
                      <Divider type="vertical" style={{ height: '100%' }} />
                    </Col>

                    <Col lg={15} md={17} xs={24}>
                      <Row>
                        <ToolAssign getToolByIdAdmin={toolDetails} assign={assign} setToolDetails={setToolDetails} setAssign={setAssign} {...props} />
                      </Row>
                      <br />

                      <Row
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'baseline'
                        }}
                      >
                        <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>Description</h3>
                      </Row>
                      <div
                        style={{
                          border: '1px solid rgba(202, 202, 202, 0.25)',
                          margin: '7px',
                          padding: '15px',
                          height: '80%',
                          borderRadius: '2px'
                        }}
                      >
                        <ReactMarkdown>{toolDetails.description}</ReactMarkdown>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />

                  <CommitmentSection
                    toolDetails={toolDetails}
                    setToolDetails={setToolDetails}
                    commitment={commitmentSet}
                    handleEditTool={handleEditTool}
                    setActionLoading={setActionLoading}
                    onCommitmentDelete={onCommitmentDelete}
                    subscribeToMore={subscribeToMore}
                    me={me}
                  />

                  <TextFormSectionComponent
                    formsection={toolDetails?.formsection}
                    textsection={toolDetails?.textsection}
                    onTextSectionDelete={onTextSectionDelete}
                    onFormSectionDelete={onFormSectionDelete}
                    setActionLoading={setActionLoading}
                    subscribeToMore={subscribeToMore}
                    handleEditTool={handleEditTool}
                    me={me}
                  />
                </>
              )}
            </div>
          </Spin>
        </Col>
      </Row>
    </CardLayout>
  );
};

export default ToolDetailView;

// @ts-nocheck
import React from "react";
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { message } from "antd";
import { compose } from "../../core";
import CreateAssignEmployeeView from "../components/CreateAssignEmployeeView";
import { ALL_EMPLOYEE_LIST, ROLES_LIST_FOR_ASSIGN_EMPLOYEE } from "../graphql/AssignEmployee.gql";

const CreateAssignEmployeeContainer = (props) => {

    const { onCancel,permission,client } = props

    const [mutationData, setmutationData] = React.useState();
    const [assign_loading, SetAssignLoading] = React.useState(false);
    const [role_list,SetRoleList] = React.useState([]);
    const [org_employee_list,SetEmployeeList] = React.useState([]);

    const ASSIGN_EMPLOYEE_DYNAMIC = gql`
        mutation createMember {
            ${mutationData?.filter(val=>val?.is_member)?.map(
        (item, index) =>
            `update${index}: updateMember(id:"${item.employeeId}",reportingLine:"${item.reportingLine}",roleId:"${item.roleId}") {
                member{
                        id
                    }
                }
                `
        ).join('')}
        ${mutationData?.filter(val=>!val?.is_member)?.map(
            (item, index) =>
              `create${index}: createMember(memberData:{employeeId:"${item.employeeId}",memberFrom:{organizationId:"${item.memberFrom.organizationId}"},reportingLine:"${item.reportingLine}",roleId:"${item.roleId}"}) {
                member{
                    id
                }
            }
           `
          ).join('')}
        }
    `

    // eslint-disable-next-line no-unused-vars
    const [assignEmployeeDefinition, { data, isloading, error }] = useMutation(ASSIGN_EMPLOYEE_DYNAMIC);

    React.useEffect(()=>{
        if(permission.required_permission){
            getRoleLevel()
            getOrgEmployee()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[permission])

    React.useEffect(() => {
        if (mutationData && mutationData?.length) {
            assignEmployeeMutation()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutationData])

    const getRoleLevel = async ( filter ) => {
        const { data } = await client.query( {
            query: ROLES_LIST_FOR_ASSIGN_EMPLOYEE,
            variables: filter,
            fetchPolicy: 'network-only'
        } );
        if ( data?.allRoles ) {
           SetRoleList(data?.allRoles?.edges?.map(({node})=>node))
        }
    }

    const getOrgEmployee = async ( filter ) => {
        const { data } = await client.query( {
            query: ALL_EMPLOYEE_LIST,
            variables: {first:25,...filter},
            fetchPolicy: 'network-only'
        } );
        if ( data?.allEmployees ) {
            SetEmployeeList(data?.allEmployees?.edges?.map(({node})=>{
                return {
                    id:node?.id,
                    member_id:node?.memberSet?.edges[0]?.node?.id,
                    user:node?.user
                }
            }))
        }
    }

    const assignEmployeeMutation = async () => {
      try {
        SetAssignLoading(true)
        const response = await assignEmployeeDefinition()
        // new to do send back the response as props
        if (response) {
            SetAssignLoading(false)
            message.success("Submitted successfully")
            onCancel()
        } else {
            SetAssignLoading(false)
        }
      } catch (error) {
        message.error("Already Assigned a role to the selected employee")
        SetAssignLoading(false)
      }
    }


    return (
        <>
            <CreateAssignEmployeeView
                {...props}
                getRoleLevel={(filter)=>{getRoleLevel(filter)}}
                role_list={role_list}
                org_employee_list={org_employee_list}
                getOrgEmployee={(filter)=>{getOrgEmployee(filter)}}
                assign_loading={assign_loading}
                setmutationData={(e)=>{setmutationData(e)}}
            />
        </>
    )
}

export default compose()(CreateAssignEmployeeContainer)
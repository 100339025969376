// @ts-nocheck
import { compose } from 'modules/core';
import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { ALL_EMPLOYEES_SURVEY_TOKEN } from '../graphql/AllEmployees.gql';
import { withCreateTokenBySurveyOptimized } from './SurveyOperations';
import { SURVEY_RESPONSES } from '../graphql/SurveyReponses.gql';
import SurveyTokenView from '../components/SurveyTokenView';
import { GET_USER_BY_USERNAME_OR_EMAIL_QUERY } from 'modules/user/graphql/GetUserByUsernameOrEmailQuery.gql';

const Survey360Container = props => {
    
    const { client, me } = props
    const [allEmployees, setAllemployees] = React.useState([]);
    const [responses, setSurveyResponses] = React.useState([]);
    const [responsesForToken,setResponsesForToken] = React.useState([]);
    const [loading,setLoading] = React.useState(false)
    const [user_detail,setUserDetail] = React.useState()
    React.useEffect(() => {
        if (me) {
            getAllEmployees()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me])
    
    const getAllEmployees = async (filter) => {
        setLoading(true)
        const { data } = await client.query({
            query: ALL_EMPLOYEES_SURVEY_TOKEN,
            fetchPolicy: 'network-only',
            variables: { ...filter }
        });
        if (data?.allEmployees) {
            let employees = data?.allEmployees?.edges?.map(({ node }) => node)
            setAllemployees(employees)
            setLoading(false)
        }
        
    }
    const getSurveyResponsesByUser = async (filter) => {
        setLoading(true)
        const { data } = await client.query({
            query: SURVEY_RESPONSES,
            fetchPolicy: 'network-only',
            variables: { ...filter }
        });
        if (data?.surveyResponses) {
            let responses = data?.surveyResponses?.edges?.map(({ node }) => node?.survey)?.map(item => item)
            .filter((value, index, self) => self.indexOf(value) === index)
            if(filter?.survey){
           
                setResponsesForToken(data?.surveyResponses?.edges)
            }else if(filter?.survey_Name_Icontains){
                setSurveyResponses(responses)
            }else{
                setSurveyResponses(responses)
            }
            setLoading(false)
        }
    }

    const getUserProfile = async (email) => {

        const { data } = await client.query({
            query: GET_USER_BY_USERNAME_OR_EMAIL_QUERY,
            fetchPolicy: 'network-only',
            variables: { email }
        });
        if(data?.users?.edges?.length){
            setUserDetail(data?.users?.edges[0]?.node)
        }else{
            setUserDetail(null)
        }
    }

    return (
      
        <SurveyTokenView allEmployees={allEmployees}
            responses={responses}
            responsesForToken = {responsesForToken}
            loading = {loading} setLoading = {setLoading}
            getAllEmployees = {getAllEmployees}
            user_detail={user_detail}
            getUserProfile={getUserProfile}
            setUserDetail={setUserDetail}
            getSurveyResponsesByUser={getSurveyResponsesByUser} {...props} />
    )
};

export default compose(withCreateTokenBySurveyOptimized, withApollo)(Survey360Container);


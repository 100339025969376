import { ThemeProvider } from '../../look/themeProvider/ThemeProvider';
import React, { useState } from 'react';
import { compose } from '../../core';
import themeConfig from '../theme/themeConfig';
import {
  withCreateResponseOptimized,
  withEditResponseOptimized,
} from './SurveyOperations';
import { withApollo } from '@apollo/client/react/hoc';
import { getSurveyBase64StringFromInt, globalPermissionValidator } from '../../look';
import { SURVEY_BY_ID_VALUES_QUERY } from '../graphql/SurveyByIdQuery.gql';
import { soul_permission } from 'Permissions/soul.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { SOUL_SURVEY_RESPONSE_TOOLS,PERSONALITY_PART_1,VALUE_SUERVEY_PART_1,STRENGTH_PART_1,KNOWLEDGE_PART_1 } from '../../../config';
import { COMPLETE_SOUL_SURVEY } from 'modules/page/graphql/CompletedSurvey.gql';
const EditSurveyResponseContainer = props => {
  const {
    me,
    client, 
    userPermission, 
    permissionLoader
  } = props;

  const [ surveyChoices, setSurveyChoices ] = React.useState()
  const [ surveyAnswers, setSurveyAnswers ] = React.useState()
  const [soulPermitted, setSoulPermitted] = React.useState(false)
  const [uiLoading,setUiLoading] = React.useState(true)
  const [isSoulTool,setIsSoulTool] = React.useState(true);
  const [soul_survey_completion,SetSoulSurveyCompletion] = React.useState()
  
  // question and current answer 
  const [ qa, setQa ] = useState( [] )
  const [ allOpts, setAllOpts ] = useState( [] )
  //surveyId
  const [ sId, setSId ] = useState( "" )
  const [ responseId, setResponseId ] = useState( "" )
  // const [ ui, setUi ] = useState( "SURVEY_EDIT_SELECT" )
  const [ ui, setUi ] = useState( "SURVEY_EDIT_SELECT_NEW" )
  //survey name
  const [ sname, setSName ] = useState( "" )

  React.useEffect(() => {
    setSName(`Enter your top 5 ${props.match.params.surveyType}`)
  },[props])



  React.useEffect(()=>{
    if(me && soulPermitted){
      let id = getSurveyBase64StringFromInt( props.match.params.id );
      let responseUser = me?.id
      getSurveyDetail( { id, responseUser } )
    }else if(!isSoulTool){
     let id = getSurveyBase64StringFromInt( props.match.params.id );
      let responseUser = me?.id
      getSurveyDetail( { id, responseUser } )
  }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ,[me,soulPermitted])

  React.useEffect(()=>{
       
    if(props.match.params.id){
     
     let soulTool = SOUL_SURVEY_RESPONSE_TOOLS?.includes(parseInt(props.match.params.id))
     setIsSoulTool(soulTool)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.match.params?.id])

  React.useEffect(() => {
    
    if (userPermission && !permissionLoader && isSoulTool ) {
      let soulPermission = globalPermissionValidator(soul_permission, userPermission);
      setSoulPermitted(soulPermission?.required_permission)
    }
  }, [userPermission,permissionLoader,isSoulTool ])

  const  getSurveyDetail= async (value)=>{

    const { data } = await client.query( {
      query: SURVEY_BY_ID_VALUES_QUERY,
      variables: value,
      fetchPolicy: 'network-only'
  } );
  if ( data?.surveyChoices && data?.surveyAnswers) {
    setSurveyChoices( data?.surveyChoices )
    setSurveyAnswers(data?.surveyAnswers)
  }
  setUiLoading(false)
  }
  React.useEffect( () => {
    if(surveyAnswers && surveyChoices){
    let responseData = []
    let choiceSet = []
    let resId = ""
    /* eslint-disable no-unused-expressions */
  
    setUi( surveyChoices?.uiFlow || "SURVEY_EDIT_SELECT_NEW" )
    setSId( surveyChoices?.id )
    // setSName( surveyChoices?.name )

    let choices = surveyChoices;
    let ans = surveyAnswers;
    choices?.groupSet?.edges?.forEach( ( gs ) => {

      gs?.node?.questionSet?.edges?.forEach( ( qs ) => {
        qs?.node?.choiceSet?.edges?.forEach( ( cs ) => {
          choiceSet.push( { 'label': cs?.node?.label, 'value': cs?.node?.value } )
        } )
      } )
    } )
    
    ans?.groupSet?.edges?.forEach( ( gs ) => {
    
      gs?.node?.questionSet?.edges?.slice( 0, 10 ).forEach( ( qs ) => {
        let q = qs?.node?.questionText
        let id = qs?.node?.id
        let d = {}
        d[ 'q' ] = q
        d[ 'id' ] = id
        d[ 'required' ] = qs?.node?.required
        qs?.node?.answerSet?.edges?.slice( 0, 1 )?.forEach( ( as ) => {
          d[ 'a' ] = as?.node?.answer
          d[ 'sequence' ] = as?.node?.sequence
          resId = as?.node?.response?.id
        } )
        responseData.push( d )
      } )
    } )

    setResponseId( resId )
    choiceSet=choiceSet?.map(i=>{
      let ans = responseData?.find(x=>x.a===i?.value)
      return{
        ...i,
        sequence:ans?.sequence
      }
    })
    setQa( responseData?.slice( 0, 5 ) )
    // if(responseData?.length>=5){
    //   let ans=responseData?.map(i=>i?.a)
    //   choiceSet = choiceSet?.filter(i=>ans?.includes(i?.value))
    // }
    setAllOpts( choiceSet )
  }
  }, [ surveyAnswers,surveyChoices ] )

  React.useEffect(()=>{
    if(me){
        GetSoulSurveysCompletedCount(me?.employee?.user?.id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[me])

const GetSoulSurveysCompletedCount = async (userid) => {
    try {
      const { data } = await client.query({
        query: COMPLETE_SOUL_SURVEY,
        variables: {
          user: userid,
          value: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
          strength: getSurveyBase64StringFromInt(STRENGTH_PART_1),
          personality: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
          knowledge: getSurveyBase64StringFromInt(KNOWLEDGE_PART_1)
        },
        fetchPolicy: 'network-only'
      });
  
      if (data) {
        SetSoulSurveyCompletion(data)
      }
    } catch (error) {
      
    }
  }

  return (
    ((!soulPermitted && !permissionLoader && isSoulTool && !uiLoading)||(isSoulTool && !uiLoading && surveyChoices==null && surveyAnswers==null)) ?
    <NoPermissionView content={"You Don't Have Permission To View This Survey."}/>
    :
    (soulPermitted && isSoulTool&& !uiLoading) ?
      <ThemeProvider
      {...props}
      soul_survey_completion={soul_survey_completion}
      config={themeConfig}
      ui={ui}
      sname={sname}
      qa={qa}
      allOpts={allOpts}
      sId={sId}
      responseId={responseId}
      surveyChoices={surveyChoices}
      surveyAnswers={surveyAnswers}
      uiLoading={uiLoading}
    />
    
    :
    (!isSoulTool) ?
    <ThemeProvider
    {...props}
    soul_survey_completion={soul_survey_completion}
    config={themeConfig}
    ui={ui}
    sname={sname}
    qa={qa}
    allOpts={allOpts}
    sId={sId}
    responseId={responseId}
    surveyChoices={surveyChoices}
    surveyAnswers={surveyAnswers}
    uiLoading={uiLoading}
  />
    : null
  );
};


export default compose(withApollo,withEditResponseOptimized, withCreateResponseOptimized )( EditSurveyResponseContainer );
import React from 'react';
import { compose } from '../../core';
import { getIntFromString, getSurveyBase64StringFromInt } from "../../look";
import { withApollo } from '@apollo/client/react/hoc';
import { Spin } from 'antd';
import UserReport from '../components/UserReport';
import { PERSONALITY_PART_1, STRENGTH_PART_1, VALUE_SUERVEY_PART_1, KNOWLEDGE_PART_1 } from '../../../config';
import { EMPLOYEE_REPORT_SURVEY_DETAIL } from '../graphql/user-response.gql';

const ReportTool = props => {

  const { client, user } = props
  const [loading, setloading] = React.useState(false)
  const [user_report, setUserReport] = React.useState()
  React.useEffect(() => {
    if (user) {
      getEmployeeSurveyDetail()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const getEmployeeSurveyDetail = async () => {
    try {
      setloading(true)
      let filter = {
        user: user?.id,
        personality_survey: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
        strength_survey: getSurveyBase64StringFromInt(STRENGTH_PART_1),
        value_survey: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
        personality_questions: [624, 625, 626, 627, 628],
        knowledge_survey: getSurveyBase64StringFromInt(KNOWLEDGE_PART_1)
      }
      const { data } = await client.query({
        query: EMPLOYEE_REPORT_SURVEY_DETAIL,
        fetchPolicy: 'network-only',
        variables: filter,
      });
      if (data) {
        let details = {
          strength:  data?.strength?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node),
          value: data?.value?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node),
          knowledge: data?.knowledge?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node),
          impact_narrative: data?.impact_narrative?.edges?.map(({ node }) => node),
          personality: data?.personality?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => {
            return {
              ...node,
              question: getIntFromString(node?.question?.id)
            }
          })
        }
        let valid_data = Object.values(details)?.find(i => i?.length)
        if (valid_data) {
          setUserReport(details)
        }
        setloading(false)
      }
    } catch (error) {
    }
  }

  return (
    <>
      {
        loading ?
          <div style={{ width: '100%' }} align="center">
            <Spin spinning={true} style={{ marginTop: '150px' }} />
          </div>
          :
          user_report ?
            <UserReport {...props} loading={loading} user_report={user_report} /> :
            <h4 style={{ fontSize: '18px', marginTop: '40px', textAlign: 'center' }}>No Report Found</h4>

      }
    </>

  );
};

export default compose(withApollo)(ReportTool);


import React from 'react';
import { compose } from '../../core';

import { withApollo } from '@apollo/client/react/hoc'
import UpcomingMeetingView from '../components/UpcomingMeetingView';
import { UPCOMING_MEETING } from '../graphql/UpcomingMeeting.gql';
import moment from 'moment-timezone'; 
const UpcomingMeetingContainer = props => {
  const { client,me,history,selectedDrop,filteritem}=props
  const [upcomingMeeting,setUpcomingMeeting]=React.useState([])
  const [loading, setloading] = React.useState(false);
  const [onFilterList,setOnFilterlist]=React.useState(false)
  const UpcomingMeeting = async (filterData) => {
    setloading(true)
    const { data} = await client.query({
        query: UPCOMING_MEETING,
        variables: {...filterData,orderBy:['when'],endedAt_Isnull:true},
        fetchPolicy: 'network-only'
    });
    if(data?.upcomingMeeting){
        if(filterData?.pagination_value){
          setOnFilterlist(true)
        }
        else{
          setOnFilterlist(false)
        }
        setUpcomingMeeting(data?.upcomingMeeting)
        setloading(false)
    }
}


function firstDayOfWeek(dateObject, firstDayOfWeekIndex) {

  const dayOfWeek = dateObject.getDay(),
    firstDayOfWeek = new Date(dateObject),
    diff = dayOfWeek >= firstDayOfWeekIndex ?
      dayOfWeek - firstDayOfWeekIndex :
      6 - dayOfWeek

  firstDayOfWeek.setDate(dateObject.getDate() - diff)
  firstDayOfWeek.setHours(0, 0, 0, 0)

  return firstDayOfWeek
}

React.useEffect(()=>{
  if(filteritem){
    if(selectedDrop ==='This Week'){
      let currentDate = moment.utc(firstDayOfWeek(new Date(moment().format()), 0))
    let weekendDate = new Date(moment().format())
    let dif = 6-weekendDate.getDay()
    weekendDate.setDate(weekendDate.getDate()+dif)
    let IsoWeekendDate = moment.utc(moment(weekendDate).endOf('day'))
      UpcomingMeeting({ when_Range: [currentDate,IsoWeekendDate],...filteritem,offset:0,first:20})
    }
    else{
      UpcomingMeeting({ when_Range: [moment().startOf('day'),moment().endOf('day')],...filteritem,offset:0,first:20})
    }
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[filteritem])
React.useEffect(()=>{
  if(me){
    let selected_Data =JSON.parse(localStorage.getItem("one_on_one_filter"))
      if(selected_Data==null){
    let currentDate = moment.utc(firstDayOfWeek(new Date(moment().format()), 0))
    let weekendDate = new Date(moment().format())
    let dif = 6-weekendDate.getDay()
    weekendDate.setDate(weekendDate.getDate()+dif)
    let IsoWeekendDate = moment.utc(moment(weekendDate).endOf('day'))
    if(selectedDrop ==='This Week'){
      UpcomingMeeting({ when_Range: [currentDate,IsoWeekendDate],offset:0,first:20})
    }
    else{
      UpcomingMeeting({ when_Range: [moment().startOf('day'),moment().endOf('day')],offset:0,first:20})
    }
  }
  else if (me===null){
    history.push('/user/login')
  }
}
// eslint-disable-next-line react-hooks/exhaustive-deps
},[me,selectedDrop ])

const PaginantionCall =(value)=>{
  let currentDate = moment.utc(firstDayOfWeek(new Date(moment().format()), 0))
  let weekendDate = new Date(moment().format())
  let dif = 6-weekendDate.getDay()
  weekendDate.setDate(weekendDate.getDate()+dif)
  let IsoWeekendDate = moment.utc(moment(weekendDate).endOf('day'))
  if(value){
    if(selectedDrop ==='This Week'){
    UpcomingMeeting({ when_Range: [currentDate,IsoWeekendDate],...filteritem,...value,first:20})
    }
    else{
      UpcomingMeeting({ when_Range: [moment().startOf('day'),moment().endOf('day')],...filteritem,...value,first:20})
    }
  }
  else{
    if(selectedDrop ==='This Week'){
      UpcomingMeeting({ when_Range: [currentDate,IsoWeekendDate],offset:0,first:20})
      }
      else{
        UpcomingMeeting({ when_Range: [moment().startOf('day'),moment().endOf('day')],offset:0,first:20})
      }
  }
}
  return (
    <>
    <UpcomingMeetingView {...props} upcomingMeeting={upcomingMeeting} loading={loading} setloading={(e)=>setloading(e)} onPaginantion={e=>PaginantionCall(e)} filteritem={filteritem} onFilterList={onFilterList}></UpcomingMeetingView>
    </>
  );
};

export default compose(withApollo)(UpcomingMeetingContainer);
// @ts-nocheck
import { Button, Form, Input, Modal,  Select, Spin, message } from "antd";
import { getIntFromString } from "modules/look";
import React, { useRef } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// import modal_close from '../../assets/badge-modal-close.svg';
// import drop_black from '../../assets/drop-arrow-black.svg';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg';
import left_arrow from '../../assets/left-active-carosul.svg';
import right_arrow from '../../assets/right-active-carosul.svg';
import search_icon from '../../assets/search-icon.svg';
import "../../kudos/kudos.css";
import '../user-kudos.css';
import close_icon from '../../assets/dashboard-kudos-close-icon.svg';
const AwardBadgeView = (props) => {
    const { visible, onClose, onSearch, badgeList, loading, createBadgeAward,  selectedItem, me,  getbadgeFailyList, onFilter, getBageFamilyById, badge_family_list, from_detail } = props

    const [selectedBadge, setSelectedBadge] = React.useState()
    const [detail, setdetail] = React.useState();
    const [Employees, setEmployees] = React.useState([]);
    const [selectedEmployee, setSelectedEmployee] = React.useState()
    const [selectedNotify, setSelectedNotify] = React.useState("employee")
    // eslint-disable-next-line no-unused-vars
    const [isOpen, setIsOpen] = React.useState(false);
    // const [isOpenNotify, setIsOpenNotify] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [cat_list, setCatList] = React.useState([
        { value: "employee", label: "Employee" },
        { value: "team", label: "Team" },
        { value: "vertical", label: "Vertical" },
        { value: "organization", label: "Organization" },
        { value: "none", label: "None" },
    ])
    // const [search_toggle, setSearchToggle] = React.useState(false)
    const [filter_value, setFilterValue] = React.useState()
    // eslint-disable-next-line no-unused-vars
    const [inflinite_slider, setInfiniteSlider] = React.useState(false)
    const Option = Select.Option;
    const [form] = Form.useForm();
    // eslint-disable-next-line no-unused-vars
    const selectRef = useRef(null);
    // const settings = {
    //     nextArrow: <img src={right_arrow} />,
    //     prevArrow: <img src={left_arrow} />
    // }


    React.useEffect(() => {

        if (selectedItem) {
            let emp = from_detail ? selectedItem?.contributors.filter((item) => item?.id !== me?.employee?.user?.id) :
                selectedItem?.contributors.filter((item) => item?.user?.id !== me?.employee?.user?.id)
            var setObj = new Set();
            let empList = emp.reduce((acc, item) => {
                if (!setObj.has(item?.id)) {
                    setObj.add(item?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            setEmployees(empList)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem])
    // eslint-disable-next-line no-unused-vars
    const handleSearch = (event) => {
        if (event?.target?.value) {
            onSearch({ title_Icontains: event?.target?.value, behaviouralDescription_Icontains: event?.target?.value })
        }
        else {
            onSearch({ title_Icontains: "", behaviouralDescription_Icontains: "" })
        }
    }
    const badgeSelectHandler = (node) => {
        setSelectedBadge(node)
    }

    // eslint-disable-next-line no-unused-vars
    const category_option = cat_list &&
        cat_list.map((choice, key) => (
            <Option className="kudos-user-home-select-option"
                style={{ border: "1px solid #000000", borderRadius: "8px" }}
                key={key}
                value={choice?.value}
            >
                {choice?.label}
            </Option>
        ));

    // eslint-disable-next-line no-unused-vars
    const choices = Employees &&
        Employees?.map((item) => (
            <Option
                className="kudos-user-home-select-option"
                style={{ border: "1px solid #000000", borderRadius: "8px" }}
                key={item?.id}
                value={item?.id}
            >
                <span className="text-transform-capitalize">{item?.user?.firstName}</span> <span className="text-transform-capitalize">{item?.user?.lastName}</span>
            </Option>
        ))

    const handleSubmit = async (value) => {
        if (selectedEmployee && selectedNotify) {
            try {
                let badgeAwardData = {
                    recipientId: getIntFromString(selectedEmployee),
                    badgeId: getIntFromString(selectedBadge?.id),
                    relatedToId: selectedItem?.id,
                    notify: selectedNotify,
                    evidence: detail
                }
                //   setloading(true)
                const res = await createBadgeAward({ badgeAwardData })

                if (res) {
                    message.success("Kudos given successfully !!")
                    onClose(false)
                }
                else {
                    message.error("Failed to give kudos !!")
                }
                //   setloading(false)
            } catch (error) {

                //   setloading(false)
            }


        }
    }
    const handleSelectEmployee = (e) => {
        setSelectedEmployee(e)
    }
    // eslint-disable-next-line no-unused-vars
    const handleSelectNotify = (e) => {
        setSelectedNotify(e)
    }
        // eslint-disable-next-line no-unused-vars
    const handleBeforeChange = (oldIndex, newIndex) => {
        if (oldIndex === 0 || newIndex === 0) {
            setInfiniteSlider(false)
        }
        else {
            setInfiniteSlider(true)
        }
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        arrows: true,
        nextArrow: <img src={right_arrow} alt='' />,
        prevArrow: <img alt="" src={left_arrow} />,
        slidesToShow: 4,
        // slidesToScroll: 4,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 310,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // eslint-disable-next-line no-unused-vars
    const handleMouseEnter = () => {
        setIsOpen(true);
    };
        // eslint-disable-next-line no-unused-vars
    const handleMouseLeave = () => {
        setIsOpen(false)
    };

    const filter_list = badge_family_list &&
        badge_family_list?.map(({ node }, key) => (
            <Option
                key={key}
                value={node?.id}
            >
                {node?.title}
            </Option>
        ))
    const filterSearch = (value) => {
        if (value) {
            setSelectedBadge(null)
            getbadgeFailyList({ title_Icontains: value })
        }
        else {
            getbadgeFailyList({ title_Icontains: "" })
        }

    }
    const handleFamilyFilter = (e) => {
        setFilterValue(e)
        setSelectedBadge(null)
        onFilter({ badgeFamily: e })
        getBageFamilyById({ id: e })
        filterSearch(null)
    }
    const handleBadgeSearch = (event) => {
        if (event?.target?.value) {
            onSearch({ title_Icontains: event?.target?.value, behaviouralDescription_Icontains: event?.target?.value, badgeFamily: filter_value })
        }
        else {
            onSearch({ title_Icontains: "", behaviouralDescription_Icontains: "", badgeFamily: filter_value })
        }
    }
        // eslint-disable-next-line no-unused-vars
    const TooltipData = (node) => {
        return (
            <div style={{ width: "100%", maxWidth: "400px", minWidth: "180px" }}>
                <h4 style={{ fontSize: '1.2em', fontWeight: "500", color: "rgba(0, 0, 0, 0.71)", margin: "0" }}>{node?.title}</h4>
                <p style={{ color: "#8C8CA2", fontSize: "1em", margin: "0", textAlign: 'justify' }}>{node?.behaviouralDescription}</p>
            </div>
        )
    }
    return (
        <>
            <Modal
                closable={false}
                visible={visible}
                destroyOnClose={true}
                footer={false}
                centered={true}
                width={"90%"}
                style={{ maxWidth: "45em" }}
            >
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: "1em", cursor: "pointer", padding: '.5em 1em', marginBottom: "1em" }}>
                    <h4 className="user-board-section-heading">Give Kudos</h4>
                    <img src={close_icon} alt="" style={{ cursor: "pointer" }} onClick={() => onClose()} />
                </div>
                <div style={{ height: "", overflow: "auto" }}>
                    <Form
                        form={form}
                        name={"kudos"}
                        style={{ width: "100%", marginTop: '1em' }}
                        onFinish={() => { handleSubmit() }}
                        className="formLabel"
                    >
                        <div className="give-kudos-popup-container-scroll" style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1em" }}>
                            <div className="kudos-form-group">
                                <h4 className="kudos-card-user-name"><span style={{ color: " rgba(249, 95, 83, 1)" }}>*</span>Employee Name</h4>
                                <Select
                                    className="user-kudos-badge-select"
                                    dropdownClassName="kuos-dropdown-popup"
                                    showSearch
                                    name={'employee'}
                                    id={'employee'}
                                    placeholder="Select Employee"
                                    suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                                    style={{ width: '100%', maxWidth: "100%", backgroundColor: "#fff", marginTop: "1em" }}
                                    {...props}
                                    onSelect={handleSelectEmployee}
                                    filterOption={(input, option) => option}
                                    disabled={false}
                                >
                                    {
                                        from_detail ? Employees?.map((item) => (
                                            <Option
                                                className="kudos-user-home-select-option"
                                                key={item?.employee_id}
                                                value={item?.employee_id}
                                            >
                                                <span className="text-transform-capitalize">{item?.firstName}</span> <span className="text-transform-capitalize">{item?.lastName}</span>
                                            </Option>
                                        )) : Employees?.map((item) => (
                                            <Option
                                                className='kudos-card-filter-text'
                                                key={item?.id}
                                                value={item?.id}
                                            >
                                                <span className="text-transform-capitalize">{item?.user?.firstName}</span> <span className="text-transform-capitalize">{item?.user?.lastName}</span>
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <div className="kudos-form-group" style={{ marginTop: "1em" }}>
                                <h4 className="kudos-card-user-name"><span style={{ color: " rgba(249, 95, 83, 1)", marginTop: "1em" }}>*</span>Kudos Family</h4>
                                <div style={{ display: "flex", flexDirection: "column", border: "1px solid rgba(170, 186, 198, 1)", borderRadius: '8px', width: "100%", padding: "2px 0px", marginTop: "1em" }}>
                                    <div className="dashboard-user-badge-award-popup-input-div" style={{ display: 'flex', flexDirection: "row", width: "100%" }}>
                                        <Select
                                            showSearch
                                            className="dasboard-filter-select-user-select"
                                            name={'filter'}
                                            id={'filter'}
                                            placeholder={`Select`}
                                            suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                                            style={{ width: '100%', maxWidth: "15em", textOverflow: 'ellipsis', whiteSpace: "nowrap" }}
                                            {...props}
                                            onSelect={handleFamilyFilter}
                                            onSearch={filterSearch}
                                            allowClear
                                            onClear={() => { onFilter() }}
                                            filterOption={(input, option) => option}
                                            disabled={false}
                                        >
                                            {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}

                                            {filter_list}

                                        </Select>
                                        <div className="dashboard-search-input-user-kudos" style={{ display: 'flex', flexDirection: 'row', width: "100%", border: "none", paddingRight: "10px", borderLeft: "1px solid rgba(170, 186, 198, 1)", marginLeft: "10px" }}>
                                            <img src={search_icon} alt="" style={{ cursor: 'pointer', transform: " rotate(-270deg)", height: '20px', marginTop: "10px", marginRight: '7px', marginLeft: '7px' }} />
                                            <Input className="kudos-search-imput-popup" placeholder="Search kudos name here" autoComplete='off' style={{ width: "100%", border: "none !important" }} onChange={(e) => handleBadgeSearch(e)}></Input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slider-container new-kudos-badge-award-carosul-container">
                                <Spin spinning={loading}>
                                    {loading && <div className="dashboard-user-award-badge-image-selected-container" style={{ width: "100%", minWidth: "42em",background:"transparent" }}></div>}
                                    {badgeList?.length > 0 && !selectedBadge && !loading && <Slider {...settings} style={{ width: "100%", maxWidth: "42em" }}>
                                        {badgeList?.length && badgeList?.map(({ node }, index) => (
                                            <div key={index} className={`dashboard-user-award-badge-image-selected-container ${node?.id === selectedBadge?.id ? "dashboard-user-award-badge-image-selected-container-selected" : ""}`}
                                                onClick={() => badgeSelectHandler(node)} >
                                                <div style={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: "center", flexDirection: "column" }}>
                                                    <img className="dashboard-user-award-badge-image-selected" src={node?.image} alt={`Slide ${index}`} />
                                                </div>
                                                <h4 className="dashboard-user-award-badge-image-selected-title">{node?.title}</h4>
                                                <div key={index} className="dashboard-user-award-badge-image-selected-detail dashboard-show-kudos-detail-card" style={{ textOverflow: "ellipsis", textAlign: "center" }}>
                                                    <h4 style={{ margin: "0px", textAlign: "left" }}>{node?.title}</h4>
                                                    <p style={{ margin: "0px" }}>{node?.behaviouralDescription}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>}
                                    {selectedBadge && <div className="dashboard-selected-kudos-card" style={{ position: "relative", width: "100%", border: "none" }}>
                                        <img src={close_icon} alt="" style={{ cursor: "pointer", position: "absolute", right: "10px", top: '10px' }} onClick={() => badgeSelectHandler()} />
                                        <div>
                                            <img style={{ height: "em", width: "6em" }} src={selectedBadge?.image} alt="" />
                                        </div>
                                        <div style={{ borderleft: "1px soild #AABAC633", marginLeft: ".5em", paddingLeft: "1em" }}>
                                            <h4 style={{ fontFamily: "Poppins", fontSize: "1em", fontWeight: "600", color: "#4CCB1F" }}>{selectedBadge?.title}</h4>
                                            <p className="dashboard-details-elipsis" style={{ fontFamily: "Poppins", fontSize: ".85em", fontWeight: "400", color: "#303030" }}>{selectedBadge?.behaviouralDescription}</p>
                                        </div>
                                    </div>}
                                    {badgeList?.length === 0 && loading === false &&
                                        <div style={{ minWidth: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <h4 className="recived-award-image-title" style={{ margin: 'auto', justifyContent: 'center', textAlign: "center" }}>No Kudos Found</h4>
                                        </div>
                                    }
                                </Spin>
                            </div>

                            <div className="kudos-form-group" style={{ marginTop: "1em", position: "relative" }}>
                                <h4 className="kudos-card-user-name"><span style={{ color: " rgba(249, 95, 83, 1)" }}>*</span>Rational</h4>
                                <textarea style={{ width: "100%", height: "150px", backgroundColor: "#fff", border: "1px solid rgba(170, 186, 198, 1)", marginTop: '1em' }} placeholder="Rationale" name="description" id="" className="kudos-description"
                                    value={detail?.length ? detail : ''} maxLength={300} onChange={(e) => { setdetail(e?.target?.value) }}></textarea>
                                {detail?.length > 0 && <h4 className="kudos-card-user-name" style={{ fontSize: ".75em", fontWeight: "400", position: "absolute", bottom: "30px", right: "5px" }}>{detail?.length}/300</h4>}
                                <h4 className="kudos-card-user-name" style={{ color: "rgba(137, 152, 162, 1)", textAlign: "right", fontWeight: "400" }}>300 characters</h4>
                            </div>
                            <div style={{ width: "100%", marginBottom: "15px", display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: "center", gap: '1em', marginTop: "2em" }}>
                                <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', width: "100px" }}
                                    disabled={!selectedEmployee || !selectedNotify || !detail} htmlType="submit">
                                    Save
                                </Button>
                                <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', color: "#8998A2", border: '1px solid #8998A2', width: "100px", background: "#fff" }} onClick={() => onClose(false)}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default AwardBadgeView


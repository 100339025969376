import React, { useState } from "react";
import { Button, Modal} from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import CustomCommitFormComponent from "../containers/CustomCommitFormComponent";
import CommitmentsList from "../containers/CommitmentsList";

const PersonalEditCommitmentView = (props) => {
  const {
    defaultCustomUserCommitments,
    loading = false,
    customUserCommitments,
    allDefaultCommitmentsProgress,
    onDelete,
    dashboard,
    commitmentKey = "commitment",
    forUser,
  } = props;
  const [visible, setVisible] = useState(false);
  const [customCommitment, setCustomCommitment] = useState(false);

  function getMode() {
    const { innerWidth: width } = window;
    if (width < 650){
      return '80%'
    }
    return '60%'
  }
  const [tabMode, setTabMode] = React.useState(getMode());

  React.useEffect(() => {
    let listenerTab = undefined
    function handleResize() {
      const { innerWidth: width } = window;
      if (width < 650) {
        setTabMode('80%')
      } else{
        setTabMode('60%')
      }
    }
    listenerTab = window.addEventListener('resize', handleResize)
    return () => {
      if (listenerTab !== undefined) {
        window.removeEventListener('resize', listenerTab)
      }

    }
  })

  return (
    <>
      <Button type="link" onClick={() => setVisible(true)}>
        Edit
        <ArrowRightOutlined />
      </Button>
      <Modal
        footer={null}
        centered
        visible={visible}
        width={tabMode}
        destroyOnClose={true}
        onCancel={() => {
          setVisible(false);
          setCustomCommitment(false);
        }}
      >
        {!customCommitment && (
          <CommitmentsList
            defaultCustomUserCommitments={defaultCustomUserCommitments}
            allDefaultCommitmentsProgress={allDefaultCommitmentsProgress}
            loading={loading}
            customUserCommitments={customUserCommitments}
            onDelete={onDelete}
            dashboard={dashboard}
            commitmentKey={commitmentKey}
            forUser={forUser}
          />
        )}

        {dashboard !== 'manager-personal' && (
          <CustomCommitFormComponent
            visible={customCommitment}
            setVisible={setCustomCommitment}
          />
        )}
      </Modal>
    </>
  );
};

export default PersonalEditCommitmentView;

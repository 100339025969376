import React from "react";
import NotificationSettingsView from "../components/notification-settings-view";
import '../css/notification-settings.css'
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { DEFAULT_NOTIFICATION_SETTINGS, USER_NOTIFICATION_SETTINGS } from "../graphql/profile.gql";
import { message } from "antd";
import { gql, useMutation } from "@apollo/react-hooks";
import { withCreateNotificationSetting, withUpdateDnd } from "./profile-opertations";
import { globalPermissionValidator } from "modules/look";
import { notification_settings_permissions } from "Permissions/notification_setting_permission";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";

const NotificationSettingsContainer = (props) => {
    const { client, me, createNotificationSettings, userPermission,updateDnd } = props
    const [notification_settings, SetNotificationSettings] = React.useState({})
    const [permission, SetPermission] = React.useState()
    const [notification_loading, setNotificationLoading] = React.useState(false)
    const [change_notification_setting, SetChangeNotificationSetting] = React.useState()
    
    // eslint-disable-next-line no-unused-vars
    const [donot_disturb_update,setDonotDisturbUpdate] = React.useState(false)
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(
                notification_settings_permissions,
                userPermission);
            SetPermission(permission)  
        }
    }, [userPermission])

    const notification_list = [
        {
            module: "OKR",
            label: "OKRs",
            info: "Choose your preferred notification settings for the following changes.",
            note: "This change will only affect newly created OKRs or KRs in the system; it does not apply to existing OKRs.",
            notification_list: [
                {
                    notification_module: "okr_summary",
                    default_key: "okrSummary",
                    module: "OKR",
                    label: "Set the frequency for the OKR Summary report updates",
                    isNotification:true,
                    values: [
                        {
                            key: "okr_summary_1_weeks",
                            title: 'Weekly',
                            duration: 1,
                            durationSpecifier: "weeks",
                            default_value: "Weekly"
                        },
                        {
                            key: "okr_summary_1_month",
                            title: 'Monthly',
                            duration: 1,
                            durationSpecifier: "month",
                            default_value: "Monthly"
                        }
                    ]
                },
                {
                    notification_module: "okr_kr",
                    default_key: "okrKr",
                    module: "OKR",
                    label: "Get notified when key results meet their timelines",
                    isNotification:true,
                    values: [
                        {
                            key: "okr_kr_10_percentage",
                            title: '10%',
                            duration: 10,
                            durationSpecifier: "percentage",
                            default_value: "10 %"
                        },
                        {
                            key: "okr_kr_20_percentage",
                            title: '20%',
                            duration: 20,
                            durationSpecifier: "percentage",
                            default_value: "20 %"
                        },
                        {
                            key: "okr_kr_30_percentage",
                            title: '30%',
                            duration: 30,
                            durationSpecifier: "percentage",
                            default_value: "30 %"
                        }
                    ]
                },
                {
                    notification_module: "okr_milestone",
                    default_key: "okrMilestone",
                    module: "OKR",
                    label: "Activate notifications for achieving timelines on chosen milestone",
                    isNotification:true,
                    values: [
                        {
                            key: "okr_milestone_10_percentage",
                            title: '10%',
                            duration: 10,
                            durationSpecifier: "percentage",
                            default_value: "10 %"
                        },
                        {
                            key: "okr_milestone_20_percentage",
                            title: '20%',
                            duration: 20,
                            durationSpecifier: "percentage",
                            default_value: "20 %"
                        },
                        {
                            key: "okr_milestone_30_percentage",
                            title: '30%',
                            duration: 30,
                            durationSpecifier: "percentage",
                            default_value: "30 %"
                        }
                    ]
                },
                {
                    notification_module: "okr_objective",
                    default_key: "okrObjective",
                    module: "OKR",
                    label: "Arrange reminders for achieving timelines on chosen Objective",
                    isNotification:true,
                    values: [
                        {
                            key: "okr_objective_10_percentage",
                            title: '10%',
                            duration: 10,
                            durationSpecifier: "percentage",
                            default_value: "10 %"
                        },
                        {
                            key: "okr_objective_20_percentage",
                            title: '20%',
                            duration: 20,
                            durationSpecifier: "percentage",
                            default_value: "20 %"
                        },
                        {
                            key: "okr_objective_30_percentage",
                            title: '30%',
                            duration: 30,
                            durationSpecifier: "percentage",
                            default_value: "30 %"
                        }
                    ]
                },
                {
                    notification_module: "okr_due_date",
                    default_key: "okrDueDate",
                    module: "OKR",
                    label: "Arrange a reminder ahead of your OKR due date.",
                    isNotification:true,
                    values: [
                        {
                            key: "okr_due_date_1_days",
                            title: '1 Day',
                            duration: 1,
                            durationSpecifier: "days",
                            default_value: "1 Day"
                        },
                        {
                            key: "okr_due_date_2_days",
                            title: '2 Days',
                            duration: 2,
                            durationSpecifier: "days",
                            default_value: "2 Days"
                        },
                        {
                            key: "okr_due_date_3_days",
                            title: '3 Days',
                            duration: 3,
                            durationSpecifier: "days",
                            default_value: "3 Days"
                        }
                    ]
                }
            ],
            custom_style: {}

        },
        {
            module: "FEEDBACK",
            label: "Feedback",
            info: "Select when you'll be notified when the following changes occur.",
            notification_list: [
                {
                    notification_module: "feedback_reminder",
                    default_key: "feedbackReminder",
                    module: "FEEDBACK",
                    label: 'Set a reminder for giving feedback to someone',
                    isNotification:true,
                    values: [
                        {
                            key: "feedback_reminder_5_days",
                            title: "5 Days",
                            duration: 5,
                            durationSpecifier: "days",
                            default_value: "5 Days"
                        },
                        {
                            key: "feedback_reminder_10_days",
                            title: "10 Days",
                            duration: 10,
                            durationSpecifier: "days",
                            default_value: "10 Days"
                        },
                        {
                            key: "feedback_reminder_15_days",
                            title: "15 Days",
                            duration: 15,
                            durationSpecifier: "days",
                            default_value: "15 Days"
                        }
                    ]
                }
            ],
            custom_style: { alignItems: "center" }
        },
        {
            module: "MEETING",
            label: "1:1 meeting",
            info: "Select when you'll be notified when the following changes occur.",
            notification_list: [
                {
                    notification_module: "meeting_reminder_1on1",
                    default_key: "meetingReminder1on1",
                    module: "MEETING",
                    label: 'Receive a reminder before your 1:1 meeting',
                    isNotification:true,
                    values: [
                        {
                            key: "meeting_reminder_1on1_1_hours",
                            title: "1 Hour",
                            duration: 1,
                            durationSpecifier: "hours",
                            default_value: "1 Hour"
                        },
                        {
                            key: "meeting_reminder_1on1_24_hours",
                            title: "24 Hours",
                            duration: 24,
                            durationSpecifier: "hours",
                            default_value: "24 Hours"
                        },
                        {
                            key: "meeting_reminder_1on1_48_hours",
                            title: "48 Hours",
                            duration: 48,
                            durationSpecifier: "hours",
                            default_value: "48 Hours"
                        }
                    ]
                }
            ],
            custom_style: { alignItems: "center" }
        },
        {
            module: "IDP",
            label: "IDP",
            info: "Select when you'll be notified when the following changes occur.",
            notification_list: [
                {
                    notification_module: "idp_due_date",
                    default_key: "idpDueDate",
                    module: "IDP",
                    label: 'Arrange a reminder ahead of your IDP due date.',
                    isNotification:true,
                    values: [
                        {
                            key: "idp_due_date_3_days",
                            title: "3 Days",
                            duration: 3,
                            durationSpecifier: "days",
                            default_value: "3 Days"
                        },
                        {
                            key: "idp_due_date_5_days",
                            title: "5 Days",
                            duration: 5,
                            durationSpecifier: "days",
                            default_value: "5 Days"
                        },
                        {
                            key: "idp_due_date_10_days",
                            title: "10 Days",
                            duration: 10,
                            durationSpecifier: "days",
                            default_value: "10 Days"
                        },
                        {
                            key: "idp_due_date_15_days",
                            title: "15 Days",
                            duration: 15,
                            durationSpecifier: "days",
                            default_value: "15 Days"
                        }
                    ]
                }
            ],
            custom_style: { alignItems: "center" }
        },
        {
            module: "KUDOS",
            label: "Kudos",
            info: "Select when you'll be notified when the following changes occur.",
            notification_list: [
                {
                    notification_module: "kudos_reminder",
                    default_key: "kudosReminder",
                    module: "KUDOS",
                    label: 'Set a reminder for giving kudos to someone',
                    isNotification:true,
                    values: [
                        {
                            key: "kudos_reminder_3_days",
                            title: "3 Days",
                            duration: 3,
                            durationSpecifier: "days",
                            default_value: "3 Days"
                        },
                        {
                            key: "kudos_reminder_5_days",
                            title: "5 Days",
                            duration: 5,
                            durationSpecifier: "days",
                            default_value: "5 Days"
                        },
                        {
                            key: "kudos_reminder_10_days",
                            title: "10 Days",
                            duration: 10,
                            durationSpecifier: "days",
                            default_value: "10 Days"
                        },
                        {
                            key: "kudos_reminder_15_days",
                            title: "15 Days",
                            duration: 15,
                            durationSpecifier: "days",
                            default_value: "15 Days"
                        }
                    ]
                }
            ],
            custom_style: { alignItems: "center" }
        }
    ]

    

    const NOTIFICATION_MUTATION = gql`
    mutation notificationSetting {
        ${change_notification_setting ?
            `
             ${change_notification_setting?.filter(i => String(i.action_type) === "update")?.map((i, index) =>
                ` 
                update_${index + 1} : updateNotificationSettings(id:"${i?.id}",durationSpecifier:"${i?.durationSpecifier}",duration:${i?.duration}){
                    notificationSetting{
                        id
                    }
                } 
                `
            ).join('')
            }
            `
            : null
        }
    }
    `;

    const [notificationSetting] = useMutation(NOTIFICATION_MUTATION);

    React.useEffect(() => {
        if (change_notification_setting?.length) {
            TriggerNotificationUpdateApi()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change_notification_setting])

    React.useEffect(() => {
        if (permission?.required_permission) {
            getUserNotification()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission])

    const TriggerNotificationUpdateApi = async () => {
        try {
            setNotificationLoading(true)
            // @ts-ignore
            // eslint-disable-next-line no-unused-vars
            const response = await notificationSetting()
            message.success("Notification settings updated")
            getUserNotification()
        } catch (error) {
            setNotificationLoading(false)
            message.error("Failed to update Notification settings")
            getUserNotification()
        }
    }

    const getUserNotification = async () => {
        try {
            setNotificationLoading(true)
            const { data } = await client.query({
                query: USER_NOTIFICATION_SETTINGS,
                fetchPolicy: 'network-only'
            });
            if (data) {
                let list = [].concat.apply([], notification_list?.map(i => i?.notification_list))
                let notification_data = {}
                Object.entries(data).forEach(i => {
                    if (i[1]?.edges?.length) {
                        let node = i[1]?.edges[0]?.node
                        let data_set = {
                            ...node,
                            durationSpecifier: node?.durationSpecifier?.toLowerCase(),
                            notification_module: node?.module?.toLowerCase(),
                            module: node?.module?.split("_")[0]?.toUpperCase(),
                            updated: false,
                            
                        } 
                        let title = getTitle(list, data_set)
                        data_set["title"] = title
                        data_set["isNotification"] = node?.isNotification!==undefined?node?.isNotification:true

                        notification_data[i[0]?.toLowerCase()] = data_set
                    }
                })
                let module_list = list?.map(i => i?.notification_module)
                let available_module = Object.keys(notification_data)
                if (module_list?.every(i => available_module?.includes(i))) {
                    SetNotificationSettings(notification_data)
                    setNotificationLoading(false)
                } else {
                    getNotificationSettings(notification_data, list)
                }

            }
        } catch (error) {

        }
    }

    const getNotificationSettings = async (current_setting, notification_list) => {
        try {
            const { data } = await client.query({
                query: DEFAULT_NOTIFICATION_SETTINGS,
                fetchPolicy: 'network-only'
            });
            if (data?.notificationsSettingsDefault) {
                let default_values = {}
                Object.entries(data?.notificationsSettingsDefault).forEach(i => {
                    default_values[i[0]?.split("_")?.join("")?.toLowerCase()] = i[1]
                })
                let delete_key = ["__typename"]
                Object.keys(current_setting).forEach(i => {
                    delete_key.push(i?.split("_")?.join("")?.toLowerCase())
                })

                delete_key.forEach(i => {
                    delete default_values[i]
                })

                Object.entries(default_values).forEach(i => {
                    let module_data = notification_list?.find(m => String(m?.default_key?.toLowerCase()) === String(i[0]))
                    if (module_data) {
                        let selected_value = module_data?.values?.find(v =>String( v?.default_value )=== String(i[1]))
                        let updated_data = {
                            duration: selected_value?.duration,
                            durationSpecifier: selected_value?.durationSpecifier?.toLowerCase(),
                            id: null,
                            module: module_data?.module,
                            notification_module: module_data?.notification_module,
                            title: selected_value?.title,
                            updated: false,
                            isNotification:selected_value?.isNotification?selected_value?.isNotification:true
                        }

                        current_setting[module_data?.notification_module] = updated_data
                    }
                }) 
                
                SetNotificationSettings(current_setting)
                setNotificationLoading(false)
            } else {
                SetNotificationSettings(current_setting)
                setNotificationLoading(false)
            }
        } catch (error) {
            SetNotificationSettings(current_setting)
            setNotificationLoading(false)
        }
    }

    const getTitle = (notification_list, notification_obj) => {
        let data = notification_list?.find(i => String(i?.notification_module) === String(notification_obj?.notification_module))
        let options = data?.values
        let key = `${notification_obj?.notification_module}_${notification_obj?.duration}_${notification_obj?.durationSpecifier}`?.toLowerCase()
        let title = options?.find(i => (String(i?.key) === String(key)))?.title
        return title || `${notification_obj?.duration} ${notification_obj?.durationSpecifier}`?.toLowerCase()
    }
    
    const UpdateChanges = (data) => {
        const { notification_data, selected_item } = data
        let updated_notification = { ...notification_settings }
        if (updated_notification[notification_data?.notification_module]) {
            let module_data = updated_notification[notification_data?.notification_module]
            module_data.duration = selected_item?.duration
            module_data.durationSpecifier = selected_item?.durationSpecifier
            module_data.title = selected_item?.title
            module_data.updated = true
            SetNotificationSettings(updated_notification)
        }
    }

    const onSave = async () => {
        let updated_notification = Object.values(notification_settings)?.filter(i => i?.updated)
        if (!updated_notification?.length) {
            message.error("Please update the settings")
            return
        }
        let new_creation = updated_notification?.filter(i => !i?.id)
        let updated = updated_notification?.filter(i => i?.id)

        if (new_creation?.length) {
            setNotificationLoading(true)
            let notificationSettingsData = new_creation?.map(i => {
                return {
                    userId: me?.id,
                    module: i?.notification_module,
                    duration: i?.duration,
                    durationSpecifier: i?.durationSpecifier,
                    // isNotification: true,
                }
            })
            let response = await createNotificationSettings({ notificationSettingsData }) 
            if(response?.length){
                if(!response[0]?.id){
                    setNotificationLoading(false)
                    message.error("Failed to update Notification settings")
                    getUserNotification()
                    return
                }
            }
            if (!updated?.length ) {
                setNotificationLoading(false)
                message.success("Notification settings updated")
                getUserNotification()
            } 

        }

        if (updated?.length) {
            let action_data = updated?.map(i => {
                return {
                    action_type: "update",
                    id: i?.id,
                    duration: i?.duration,
                    durationSpecifier:  i?.durationSpecifier,
                    isNotification:i?.isNotification,
                }
            })
        SetChangeNotificationSetting(action_data)
        }

    }
    const DonotDisturb =async(checked)=>{
        // let updated_notification = Object.values(notification_settings)?.filter(item => item?.id)
        // let notificationSettingsData =Object.values(notification_settings)?.filter(item=>!item?.id)?.map(i => {
        //     return {
        //         userId: me?.id,
        //         module: i?.notification_module,
        //         duration: i?.duration,
        //         durationSpecifier: i?.durationSpecifier,
        //         isNotification:!checked,
        //     }
        // })
        // if(notificationSettingsData?.length){
        //     let response = await createNotificationSettings({ notificationSettingsData }) 

        //     if(response?.id){
        //         getUserNotification()
        //         message.success(checked?"Successfully Disabled Notifications":"Successfully Enabled Notifications")
               
        //     }
        // } 
        // if (updated_notification) {
        //     let action_data = updated_notification?.map(i => {
        //         return {
        //             action_type: "update",
        //             id: i?.id,
        //             duration: i?.duration,
        //             durationSpecifier:  i?.durationSpecifier,
        //             isNotification:!checked
        //         }
        //     })
        //     SetChangeNotificationSetting(action_data)
        //     setDonotDisturbUpdate(true)
        // } 
        let response = await updateDnd({isNotification:!checked})
        if(response){
            message.success(checked?"Successfully Disabled Notifications":"Successfully Enabled Notifications")
        }
    }
   
    return (
        <>
            {
               (permission && permission?.required_permission) && (
                    <NotificationSettingsView {...props} onSave={onSave} notification_loading={notification_loading} notification_list={notification_list} notification_settings={notification_settings} UpdateChanges={UpdateChanges} getUserNotification={getUserNotification} DonotDisturb={(e)=>DonotDisturb(e)}/>
                )
            }

            {
               (permission && !permission?.required_permission) && (
                     <NoPermissionView/>
                )
            }
        </>
    )
}

export default compose(withApollo, withCreateNotificationSetting,withUpdateDnd)(NotificationSettingsContainer);
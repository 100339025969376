import React from 'react';
import _ from 'lodash';
import { Row, Col, Form } from 'antd';

import {
  TextField,
  TagAutoComplete,
  getIntFromString,
  ButtonsComponent,
  CheckBoxField,
  UserAutoComplete,
  CKEditorField,
  RenderUploadWithCrop
} from '../../look';

import ROUTES from '../route';
import { STORY_BANNER_HEIGHT } from '../../../config';
export const CKEditorFieldName = ['storyData', 'content'];

const StoryFormComponent = props => {
  const { storyById, onSubmit, setPreviewData } = props;
  const [load, setLoad] = React.useState(false);
  const [button, setButton] = React.useState('');
  const [form] = Form.useForm();
  const initialValue = {
    storyData: {
      title: storyById && storyById.title,
      content: storyById && storyById.content,
      published: storyById && storyById.published,
      thumbnailImage: storyById && storyById.thumbnailImage,
      banner: storyById && storyById.banner,
      author: storyById && storyById.author
    },
    storyTags:
      storyById &&
      storyById.storyTags &&
      storyById.storyTags.edges[0] &&
      storyById.storyTags.edges.map(({ node }) => getIntFromString(node.id))
  };

  const handleCkeditorChange = e => {
    setPreviewData(_.set(form.getFieldsValue(true), CKEditorFieldName, e.editor.getData()));
    form.setFieldsValue(_.set(form.getFieldsValue(true), CKEditorFieldName, e));
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValue}
          onFinish={values => onSubmit(values, button)}
          required={false}
          scrollToFirstError={true}
        >
          <Row>
            <Col lg={20}>
              <Row gutter={48}>
                <Col lg={11} md={20}>
                  <TextField
                    name={['storyData', 'title']}
                    label="Title"
                    rules={[{ required: true, message: 'Title is required!' }]}
                  />
                </Col>

                <Col lg={{ span: 12, offset: 1 }} md={20}>
                  <UserAutoComplete
                    name={['storyData', 'author']}
                    label="Author"
                    id={false}
                    placeholder={`Search author`}
                    rules={[{ required: true, message: 'User is required!' }]}
                    offset={1}
                    otherOption={true}
                  />
                </Col>

                <Col lg={12} md={20}>
                  <Row>
                    <Col span={24}>
                      <TagAutoComplete
                        margin={true}
                        newTab={true}
                        onAdd={ROUTES.addTag + '?args=closeonsave'}
                        name="storyTags"
                        label="Tags" /* rules={[{ required: true, message: 'Manager is required!' }]} */
                      />
                    </Col>
                    <Col span={24}>
                      <CheckBoxField name={['storyData', 'published']} label="Published" />
                    </Col>
                  </Row>
                </Col>

                <Col lg={12} md={20}>
                  <Row>
                    <Col lg={12} md={20} xs={24}>
                      <RenderUploadWithCrop
                        width={300}
                        height={250}
                        setUrl={e =>
                          form.setFieldsValue(_.set(form.getFieldsValue(true), ['storyData', 'thumbnailImage'], e))
                        }
                        setLoad={setLoad}
                        name={['storyData', 'thumbnailImage']}
                        label="Image" /* rules={[{ required: true, message: 'Image is required!' }]} */
                        value={initialValue?.storyData?.thumbnailImage}
                        offset={2}
                      />
                    </Col>

                    <Col lg={12} md={20} xs={24}>
                      <RenderUploadWithCrop
                        width={500}
                        height={STORY_BANNER_HEIGHT}
                        setUrl={e => form.setFieldsValue(_.set(form.getFieldsValue(true), ['storyData', 'banner'], e))}
                        setLoad={setLoad}
                        name={['storyData', 'banner']}
                        label="Banner" /* rules={[{ required: true, message: 'Image is required!' }]} */
                        value={initialValue?.storyData?.banner}
                        offset={2}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} md={20}></Col>
                <Col span={3} />

                <Col span={22}>
                  <CKEditorField
                    name={CKEditorFieldName}
                    label="Content"
                    initialValue={storyById && storyById.content}
                    rules={[{ required: true, message: 'Content is required!' }]}
                    changeHandler={handleCkeditorChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          {/* <MarkdownField
            name={CKEditorFieldName}
            label="Content"
            initialValue={storyById && storyById.content}
            rules={[{ required: true, message: 'content is required!' }]}
          /> */}
          <ButtonsComponent disabled={load} setButton={setButton} />
        </Form>
      </Col>
    </Row>
  );
};

export default StoryFormComponent;

import { Col, Modal, Radio, Row, Spin } from "antd";
import { getEmployeeBase64StringFromInt } from "modules/look";
import DoughntChartCascaded from "modules/look-v2/Components/DoughnutchartCascaded";
import DoughntChartNonCascaded from "modules/look-v2/Components/DoughnutChartNonCascaded";
import PaginationDropdown from "modules/look-v2/Components/PaginantionDropdowm";
import PerformanceBar from "modules/look-v2/Components/PerformanceBar";
import React from "react";
import styled from "styled-components";
import modal_close from '../../assets/modal-close-icon.svg'

const OKRVerticalPopupView =(props)=>{
    const {visible,getAllCascadedObjectives,getAllNonCascadedObjectives,onClose,verticalDetailModal,okrPerformanceForVertical,getOkrPerformanceForvertical,okrPerformanceForTeam, getOkrPerformanceForTeam,team_manager_pop,barloader,donutloader,cascadedOkrModal,nonCascadedOkrModal}=props
    const [donutTab, setdonutTab] = React.useState('Cascaded');
    const [PerformanceTab, setPerformanceTab] = React.useState('vertical');
    const changeTab=(event)=>{
        setPerformanceTab(event?.target?.value)
        if(event?.target?.value==='team' && !okrPerformanceForTeam?.edges){
            getOkrPerformanceForTeam(5,0,false)
          }
        
    }

    const changedonutTab=(event)=>{
        setdonutTab(event?.target?.value)
        if(event?.target?.value==='Non_Cascaded' && !nonCascadedOkrModal){
            getAllNonCascadedObjectives(5)
          }
        
    }

    let current_result_count = React.useRef({
        team:5,
        vertical:5
      })


    const filterData=(first)=>{
        if(PerformanceTab==='vertical'){
          getOkrPerformanceForvertical(first,0,false)
          current_result_count['current'].vertical=first
        }else{
          getOkrPerformanceForTeam(first,0,false)
          current_result_count['current'].team=first
        }
      }
    
    return (
        <>
        <Modal
             footer={null}
             visible={visible}
             closable={false}
             destroyOnClose={true}
             centered
             className="custom-performance-modal"
             >
              
              <CustomModalBody>
                <Row justify="space-between">
                    <h4 className="chart-title" style={{textAlign:"left",textOverflow:'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',paddingRight: '40px'}}>{verticalDetailModal?.name}’s OKR Performance</h4>
                    <img src={modal_close} alt=""style={{position:"absolute",right:"10px",top:"10px",cursor:"pointer"}} onClick={()=>onClose()}/>
                    </Row>
                    <Spin spinning={barloader}>
                        <Row align="center" style={{gap:"30px",width:'100%'}}>
                            <Col lg={13}>
                            
                                <Row justify="space-between" style={{margin:"20px 0px",width:"100%"}}>
                                {team_manager_pop?.length?
                                <Col>
                                    <Radio.Group className="ModalRadioGroup" value={PerformanceTab} onChange={(e) => changeTab(e)}>
                                        <RadioButton style={{borderRight:"none",width:"110px"}} className={`section-cascade ${PerformanceTab==='vertical'?'active-cascade':''}`} value="vertical">Vertical</RadioButton>
                                        <RadioButton style={{width:"80px"}}  className={`section-non-cascade ${PerformanceTab ==='team'?'active-non-cascade':''}`} value="team">Teams</RadioButton>
                                    </Radio.Group>
                                </Col>
                                :<div style={{width:"10px",height:"10px"}}></div>}
                                {(PerformanceTab==='vertical' &&okrPerformanceForVertical?.totalCount && okrPerformanceForVertical?.edgeCount<100)?<PaginationDropdown current={current_result_count['current'].vertical} onselect={(e)=>{filterData(e)}} totalCount={okrPerformanceForVertical?.totalCount}/>:''}
                                {(PerformanceTab==='team' && okrPerformanceForTeam?.totalCount && okrPerformanceForTeam?.edgeCount<100)?<PaginationDropdown current={current_result_count['current'].team} onselect={(e)=>{filterData(e)}} totalCount={okrPerformanceForTeam?.totalCount} hideResult={true} showTooltip={{show:true,label:null,customType:"OKR's"}}/>:''}
                            </Row>
                            
                                <div  style={{background: "#FFFFFF",boxShadow:" -1px 3px 66px -25px rgba(0, 0, 0, 0.17)",maxHeight:"500px",width:"100%",padding:"10px 20px",minHeight:"350px"}}>
                                    {
                                        okrPerformanceForVertical?.edges &&PerformanceTab==='vertical' && (
                                            
                                        okrPerformanceForVertical?.edges?.length?
                                           <>
                                           
                                           <PerformanceBar  {...props} performanceData={okrPerformanceForVertical?.edges||[]}/>:
                                            {(okrPerformanceForVertical?.totalCount>100 && okrPerformanceForVertical?.edgeCount>=100)
                                                &&(
                                                 <div style={{width:'100%'}} align="center">
                                                 <h4 className="pagination-label" >{okrPerformanceForVertical?.edgeCount<okrPerformanceForVertical?.totalCount&&(<span style={{cursor:"pointer",color:"#B0CB1F"}} onClick={()=>{getOkrPerformanceForvertical(5,okrPerformanceForVertical?.edgeCount,true)}}><u>View more Okrs</u></span>)} <span>{`${okrPerformanceForVertical?.edgeCount===okrPerformanceForVertical?.totalCount?'Viewing':''}(${okrPerformanceForVertical?.edgeCount}/${okrPerformanceForVertical?.totalCount})`}</span> </h4>
                                                 </div>
                                                )
                                              }
                                              </>
                                              :
                                            <h4 style={{textAlign:'center',fontSize:"17px",marginTop:"150px"}}>No Records found</h4>
                                            
                                        )
                                    }
                                    {
                                    okrPerformanceForTeam?.edges &&PerformanceTab==='team' && (
                                        
                                        okrPerformanceForTeam?.edges?.length?
                                        <>
                                          <PerformanceBar  {...props} performanceData={okrPerformanceForTeam?.edges||[]}/>
                                        {(okrPerformanceForTeam?.totalCount>100 && okrPerformanceForTeam?.edgeCount>=100)
                                            &&(
                                             <div style={{width:'100%'}} align="center">
                                             <h4 className="pagination-label" >{okrPerformanceForTeam?.edgeCount<okrPerformanceForTeam?.totalCount&&(<span style={{cursor:"pointer",color:"#B0CB1F"}} onClick={()=>{getOkrPerformanceForTeam(10,okrPerformanceForTeam?.edgeCount,true)}}><u>View more Okrs</u></span>)} <span>{`${okrPerformanceForTeam?.edgeCount===okrPerformanceForTeam?.totalCount?'Viewing':''}(${okrPerformanceForTeam?.edgeCount}/${okrPerformanceForTeam?.totalCount})`}</span> </h4>
                                             </div>
                                            )
                                          }
                                        </>
                                        :
                                        <h4 style={{textAlign:'center',fontSize:"17px",marginTop:"150px"}}>No Records found</h4>
                                        
                                    )
                                    }
                                </div>
                            </Col>
                                <Col lg={10} style={{maxHeight:"410px",width:"100%",padding:"10px"}}>
                                    <Spin spinning={donutloader}>
                                        <div style={{minHeight:"360px"}}>
                                            <Row align="middle" style={{marginBottom:"20px"}}>
                                                <CustomCol>
                                                    <Radio.Group className="radio-doughnutChart" value={donutTab} onChange={(e) => changedonutTab(e)}>
                                                        <RadioButtonCascaded style={{borderRight:"none"}} className={`section-cascade-modal ${donutTab==='Cascaded'?'active-cascade-modal':''}`} value="Cascaded">Cascaded</RadioButtonCascaded>
                                                        <RadioButtonNonCascaded  className={`section-non-cascade-modal ${donutTab ==='Non_Cascaded'?'active-non-cascade-modal':''}`} value="Non_Cascaded">Non Cascaded</RadioButtonNonCascaded>
                                                    </Radio.Group>
                                                </CustomCol>
                                            </Row>
                                            <Row align="middle" style={{width:"100%",flexDirection:"column"}}>
                                                {donutTab==='Cascaded'?
                                            
                                                <DoughntChartCascaded
                                                {...props}
                                                getNextSetOfCascadedOkrs={(e,offset)=>getAllCascadedObjectives(e,offset,true)}
                                                label={'Cascaded Objectives'}
                                                data={cascadedOkrModal}
                                                height='200px'
                                                topValue = '137px'
                                                rightValue = '90px'
                                                fontSize = '14px'
                                                responsiveFontSize = '10px'
                                                responsiveTopValue = '87px'
                                                responsiverightValue = '52px'
                                                managerids={verticalDetailModal?.managerId?.map(id=> getEmployeeBase64StringFromInt(id) ) || []}
                                                loading={donutloader}
                                            />
                                                :
                                            
                                                <DoughntChartNonCascaded
                                                {...props}
                                                getNextSetOfNonCascadedOkrs = {(e,offset)=> getAllNonCascadedObjectives(e,offset,true)}
                                                label={'Non cascaded Objectives'}
                                                data={nonCascadedOkrModal}
                                                height='200px'
                                                topValue = '137px'
                                                rightValue = '90px'
                                                fontSize = '14px'
                                                responsiveFontSize = '10px'
                                                responsiveTopValue = '87px'
                                                responsiverightValue = '52px'
                                                managerids={verticalDetailModal?.managerId?.map(id=> getEmployeeBase64StringFromInt(id) ) || []}
                                                loading={donutloader}
                                            />
                                                }
                                            </Row>
                                        </div>
                                    </Spin>
                                </Col>
                        </Row>
                    </Spin>
                    </CustomModalBody>
            </Modal>
        </>
    )
}

const RadioButton = styled(Radio.Button)`

`;

const RadioButtonCascaded = styled(Radio.Button)`
width:110px;
@media(max-width:380px){
    width:95px;
  font-size:10px;
}

`;

const RadioButtonNonCascaded = styled(Radio.Button)` 
  width:130px;
  @media(max-width:380px){
    width:110px;
  font-size:10px;
}
`;



export default OKRVerticalPopupView
const CustomCol = styled(Col)`
padding-left:90px;
@media(max-width:350px){
    padding-left:0px;
}

@media  (min-width:351px) and (max-width:420px){
    padding-left:10px;
}

@media  (min-width:421px) and (max-width:600px){
    padding-left:85px;
}

@media  (min-width:601px) and (max-width:1200px){
    padding-left:35px;
}

@media  (min-width:1201px) and (max-width:1400px){
    padding-left:85px;
}

@media  (min-width:1800px){
    padding-left:190px;
}

`;


const CustomModalBody = styled.div`
padding:0px 40px;
width:100%;
position:relative;
@media(max-width:1024px){
    padding:0px;
}
`;
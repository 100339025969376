import React from 'react';
import _ from 'lodash';
import { Row, Col, Form, Divider, Switch, Button } from 'antd';

import { TextField, getIntFromString, SimpleFieldAutoComplte, ReferenceFieldAutoComplete } from '../../look';

import AddSimpleField from '../containers/AddSimpleField';
import AddReferenceField from '../containers/AddReferenceField';

const FormSectionFormComponent = props => {
  const { formSection, onSubmit, btnText } = props;
  const [form] = Form.useForm();
  const [simpleField, setSimpleField] = React.useState(true);
  const [referenceField, setReferenceField] = React.useState(true);

  const initialValue = {
    title: formSection?.title,
    editable: String(formSection?.editable) === 'false' ? false : true,
    simplefieldId:
      formSection &&
      formSection?.simpleField?.edges[0] &&
      formSection?.simpleField?.edges?.map(({ node }) => getIntFromString(node.id)),
    referencefieldId:
      formSection &&
      formSection?.referencefield?.edges[0] &&
      formSection?.referencefield?.edges?.map(({ node }) => getIntFromString(node.id))
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValue}
          onFinish={values =>
            onSubmit({ ...values /* , referencefieldId: referenceField.map(({ node }) => getIntFromString(node.id)) */ })
          }
          scrollToFirstError={true}
        >
          <Row gutter={24}>
            <Col lg={12} md={20}>
              <TextField name={'title'} label="Title" rules={[{ required: true, message: 'Title is required!' }]} />
            </Col>

            <Col lg={12} md={20}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24, offset: 1 }}
                name={'editable'}
                label="Editable"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>

            <Col lg={12} md={20}>
              {simpleField && (
                <SimpleFieldAutoComplte name="simplefieldId" offset={1} label="Simple Field" mode="multiple" />
              )}
              {/* <br />
              <div>
                <h4 style={{ fontWeight: 'unset' }}>Reference Field</h4>
                {referenceField.map(({ node }) => (
                  <Tag closable onClose={() => setReferenceField(referenceField.filter(({ node: r }) => r.id !== node.id))}>
                    {node?.question?.questionText}
                  </Tag>
                ))}
              </div> */}
            </Col>

            <Col lg={12} md={20}>
              {referenceField && (
                <ReferenceFieldAutoComplete name="referencefieldId" offset={1} label="Reference Field" mode="multiple" />
              )}
            </Col>
          </Row>

          {/* condition select field */}
          <Divider />
          {/* <ButtonsComponent setButton={setButton} /> */}
          <Row justify="space-between">
            <Col>
              <Button type="primary" htmlType="submit">
                {btnText}
              </Button>
            </Col>

            <Col>
              <Row gutter={24}>
                <Col>
                  <AddSimpleField
                    btn="add"
                    btnText={'Add'}
                    onSubmit={e => {
                      setSimpleField(false);
                      form.setFieldsValue(
                        _.set(form.getFieldsValue(true), 'simplefieldId', [
                          ...(form.getFieldValue('simplefieldId') || []),
                          e
                        ])
                      );
                      setSimpleField(true);
                    }}
                  />
                </Col>

                <Col>
                  <AddReferenceField
                    btn="add"
                    btnText={'Add'}
                    onSubmit={e => {
                      setReferenceField(false);
                      form.setFieldsValue(
                        _.set(form.getFieldsValue(true), 'referencefieldId', [
                          ...(form.getFieldValue('referencefieldId') || []),
                          e
                        ])
                      );
                      setReferenceField(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default FormSectionFormComponent;

import React from 'react';
// import { ReactDOM } from 'react-dom';

const ProgressCounterBtn = props => {
  // eslint-disable-next-line no-unused-vars
  const { idIndex, timeLimit = 5, btn: Btn, reset, setReset } = props;
  const [timesup, setTimesup] = React.useState(false);
  const progressBarId = `progressBtn${idIndex}`;
  const baseTimerPathRemainingId = `base-timer-path-remaining${idIndex}`;
  const baseTimerLabelId = `base-timer-label${idIndex}`;

  // eslint-disable-next-line no-unused-vars
  function formatTime(time) {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }

    return `${minutes}:${seconds}`;
  }

  const handleFilterRemove = React.useRef(() => {});
  React.useEffect(() => {
    document.getElementById(progressBarId).innerHTML = `
     <div class="base-timer" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="base-timer__circle" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        
          <path
            id=${baseTimerPathRemainingId}
            stroke-dasharray="283"
            class="base-timer__path-remaining ${remainingPathColor}"
            d="
              M 50, 50
              m -45, 0
              a 45,45 0 0,0 90,0
              a 45,45 0 0,0 -90,0
            "
            ></path>
        </g>
        </svg>
      <span id=${baseTimerLabelId} class="base-timer__label">${/* formatTime */ timeLeft}</span>
      </div>
      `;

    handleFilterRemove.current = () => {
      startTimer();
    };

    handleFilterRemove.current();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FULL_DASH_ARRAY = 283;
  const WARNING_THRESHOLD = 10;
  const ALERT_THRESHOLD = 5;

  const COLOR_CODES = {
    info: {
      color: 'green'
    },
    warning: {
      color: 'orange',
      threshold: WARNING_THRESHOLD
    },
    alert: {
      color: 'red',
      threshold: ALERT_THRESHOLD
    }
  };

  const TIME_LIMIT = timeLimit;
  let timePassed = 0;
  let timeLeft = TIME_LIMIT;
  let timerInterval = null;
  let remainingPathColor = COLOR_CODES.info.color;

  function onTimesUp() {
    clearInterval(timerInterval);
    setTimesup(true);
    timeLeft = TIME_LIMIT;
  }
  function calculateTimeFraction() {
    const rawTimeFraction = timeLeft / TIME_LIMIT;
    return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
  }
  function setRemainingPathColor(timeLeft) {
    const { alert, warning, info } = COLOR_CODES;
    if (timeLeft <= alert.threshold) {
      document.getElementById(`${baseTimerPathRemainingId}`).classList.remove(warning.color);
      document.getElementById(`${baseTimerPathRemainingId}`).classList.add(alert.color);
    } else if (timeLeft <= warning.threshold) {
      document.getElementById(`${baseTimerPathRemainingId}`).classList.remove(info.color);
      document.getElementById(`${baseTimerPathRemainingId}`).classList.add(warning.color);
    }
  }

  function setCircleDasharray() {
    const circleDasharray = `${(calculateTimeFraction() * FULL_DASH_ARRAY).toFixed(0)} 283`;
    document.getElementById(`${baseTimerPathRemainingId}`).setAttribute('stroke-dasharray', circleDasharray);
  }

  function startTimer() {
    timerInterval = setInterval(() => {
      timePassed = timePassed += 1;
      timeLeft = TIME_LIMIT - timePassed;
      document.getElementById(`${baseTimerLabelId}`).innerHTML = /* formatTime */ timeLeft;
      setCircleDasharray();
      setRemainingPathColor(timeLeft);

      if (timeLeft === 0) {
        setTimesup(true);
        onTimesUp();
      }
    }, 1000);
  }

  // React.useEffect(() => {
  //   if (reset === true) {
  //     setTimesup(true);
  //     onTimesUp();
  //     setReset(false);
  //   }
  // }, [reset, setReset]);

  React.useEffect(() => {
    return () => onTimesUp();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div style={{ position: 'relative', display: timesup && 'none' }} id={progressBarId} />
      <Btn style={{ display: !timesup ? 'none' : 'block' }} />
    </>
  );
};
//<circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
export default ProgressCounterBtn;

import { message } from "antd";
import { CREATE_TICKET_MUTATION, CREATE_TICKET_COMMNET } from "../graphql/ticket-mutation.gql";
import { graphql } from "@apollo/client/react/hoc";

export const withCreateTicket = (Component) =>
  graphql(CREATE_TICKET_MUTATION, {
    props: ({ mutate, history }) => ({
      createTicket: async (values) => {
        try {
          const {
            data: { createTicket },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          return createTicket.ticket;
        } catch (e) {
          message.destroy();
          message.error("Failed to create Ticket");
          console.error(e);
        }
      },
    }),
  })(Component);

  export const withCreateComment = (Component) =>
    graphql(CREATE_TICKET_COMMNET, {
      props: ({ mutate, history }) => ({
        createTicketComment: async (values) => {
          try {
            const {
              data: { createTicketComment },
            } = await mutate({
              variables: {
                ...values,
              },
            });
  
            message.destroy();
            return createTicketComment.ticketComment;
          } catch (e) {
            message.destroy();
            message.error("Failed to add Comment");
            console.error(e);
          }
        },
      }),
    })(Component);
  
// @ts-nocheck
import React from "react";
import { Col, Row, Form,Button, Tooltip, Radio,Space,message,Select } from "antd";
import "../oneONone.css"
import close from '../../assets/close.svg'
import moment from 'moment-timezone';
import { DatePickerField, displayUtcToUserTimezone, ReactMarkdown, SelectField, TextField } from "../../look";
const OccuranceView = props => {
    const {type,onClose,changeRule,when,selected_end_date}=props
    const [occurance_type,set_occurance_type]=React.useState()
    const [start_date, setstart_date] = React.useState();
    const [end_date, setend_date] = React.useState();
    const [weekdays,setweekdays] = React.useState([]);
    const [monthlyvalue,setmonthlyvalue] = React.useState(1);
    const [form] = Form.useForm();
    const [monthlyForm] = Form.useForm();
    const [info,setinfo]=React.useState()
    const { Option } = Select;
    React.useEffect(()=>{
        if(type){
            set_occurance_type(type?.type)
            if(type?.days){
                setweekdays(type?.days)
            }else{
                let list = {0:'SU',1:'MO',2:'TU',3:'WE',4:'TH',5:'FR',6:'SA'}
                let day = new Date().getDay()
                setweekdays([list[day]])
            }
        }
    },[type])

    React.useEffect(()=>{
        generateInfo(start_date,{type:occurance_type,days:weekdays},end_date)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[monthlyvalue])

    React.useEffect(()=>{
        if(when){
            setstart_date(when)
        }
    },[when])

    React.useEffect(()=>{
        if(when && type){
            let list = {0:'SU',1:'MO',2:'TU',3:'WE',4:'TH',5:'FR',6:'SA'}
            let day = new Date().getDay()
            generateInfo(when,{...type,days:type?.days || [list[day]]})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[when,type])

    const generateInfo=(start_date,oc_type,end_date)=>{
        let values = form.getFieldsValue()
        let monthform = monthlyForm.getFieldsValue()
        if(oc_type?.type==='Day'){
            let info = `occurs every ${values?.repeat>1?`${values?.repeat} days`:'day'} starting ${displayUtcToUserTimezone(start_date,"YYYY/MM/DD")} ${end_date?`until ${displayUtcToUserTimezone(end_date,"YYYY/MM/DD")}`:''}`
            setinfo(info)
        }else if(oc_type?.type==='Week'){
            let days_list
            if(oc_type?.days){
                let x = weekday_list?.filter(item=>oc_type?.days?.includes(item?.value)).sort((a,b)=>a?.order-b?.order)
                days_list=x?.map(value=>value?.label)?.join(',')
            }
           
            let info = `occurs every ${days_list} starting ${displayUtcToUserTimezone(start_date,"YYYY/MM/DD")} ${end_date?`until ${displayUtcToUserTimezone(end_date,"YYYY/MM/DD")}`:''}`
            setinfo(info)
        }
        else if(oc_type?.type==='Month'){
            let info 
            if(monthlyvalue===1){
                info = monthform?.day?`occurs every month on day ${monthform?.day} starting ${displayUtcToUserTimezone(start_date,"YYYY/MM/DD")} ${end_date?`until ${displayUtcToUserTimezone(end_date,"YYYY/MM/DD")}`:''}`:''
            }else{
                let data
                let a = day_count?.find(item=>item?.value===monthform?.day_count)
                let b = weekday_list?.find(item=>item?.value===monthform?.week_date)
                if(a && b){
                    data = `${a?.label} ${b?.label}`
                }
                info = (monthform?.day_count && monthform?.week_date)?`occurs every month on ${data} starting ${displayUtcToUserTimezone(start_date,"YYYY/MM/DD")} ${end_date?`until ${displayUtcToUserTimezone(end_date,"YYYY/MM/DD")}`:''}`:''
            }
            setinfo(info)
        }
    }

    const ref = React.useRef(null);

    const save=()=>{
        let values = form.getFieldsValue()
        let monthform = monthlyForm.getFieldsValue()
        if((!end_date && !selected_end_date )|| !start_date){
            message.error('select dates')
            return
        }
        if(!values?.repeat){
            message.error('set proper intervel')
            return
        }

        let rrule
        let until =end_date?moment(end_date).endOf('day').utc().toISOString().replaceAll(':','').replaceAll('-','').split('.')[0]+'Z':moment(selected_end_date).endOf('day').utc().toISOString().replaceAll(':','').replaceAll('-','').split('.')[0]+'Z'
        if(values?.type==='Week'){
            rrule=`RRULE:FREQ=WEEKLY;INTERVAL={intervel};BYDAY=${weekdays?.join(',')};UNTIL={until}`
        }
        else if(values?.type==='Month'){
            if(monthlyvalue===1){
                if(!monthform?.day){
                    message.error('set proper date')
                    return
                }
                rrule=`RRULE:FREQ=MONTHLY;INTERVAL={intervel};BYMONTHDAY=${monthform?.day};UNTIL={until}`
            } 
            else{
                if(!monthform?.day_count || !monthform?.week_date){
                    message.error('set proper date')
                    return
                }
                rrule=`RRULE:FREQ=MONTHLY;INTERVAL={intervel};BYDAY=${monthform?.day_count}${monthform?.week_date};UNTIL={until}`
            }  
        }
        else if(values?.type==='Day'){
            rrule=`RRULE:FREQ=DAILY;INTERVAL={intervel};UNTIL={until}`
        }
        rrule=rrule?.replace('{intervel}',values?.repeat)
        rrule=rrule?.replace('{until}',until)
        changeRule({rrule,info,start_date,end_date})
        // onClose()
    }
   
    const weekday_list=[
        {value:'SU',name:'S',label:"Sunday",order:0},
        {value:'MO',name:'M',label:"Monday",order:1},
        {value:'TU',name:'T',label:"Tuesday",order:2},
        {value:'WE',name:'W',label:"Wednesday",order:3},
        {value:'TH',name:'T',label:"Thursday",order:4},
        {value:'FR',name:'F',label:"Friday",order:5},
        {value:'SA',name:'S',label:"Saturday",order:6},]

    const day_count = [{value:"1",label:"First"},{value:"2",label:"Second"},{value:"3",label:"Third"},{value:"4",label:"Fourth"},{value:"-1",label:"Last"}]

    const changeDay=(e)=>{
      let list = [].concat(weekdays)  
      if(list.find(item=>item===e)){
        if(list?.length>1){
            list = list?.filter(i=>i!==e)
        }
      }else{
        list.push(e)
      }
      generateInfo(start_date,{type:occurance_type,days:list},end_date)
      setweekdays(list)
    }

    

    return (
        <>
        {occurance_type &&(
            <>
            <div className="row space-between aligin-center">
                <div style={{width:"10px",height:"5px"}}></div>
                <h4 className="occarance-modal-title">Set recurrence</h4>
                <img src={close} alt="" className="close-icon" onClick={()=>{onClose()}} />
            </div>
            {/* border: !dateInvalid ? '' : '1px solid #ff4d4f' */}
            <Row style={{marginTop:"20px"}}>
            <Col span={12}>
            <p style={{fontSize:'15px'}}><span style={{color:'#ff4d4f'}}>*</span> Start date</p>
                  <DatePickerField
                   ref={ref}
                      style={{ width: '100%', height: '36px', }}
                      format="YYYY-MM-DD"
                      dropdownClassName="modal-datepicker-index"
                      onChange={e =>{(e?setstart_date(e):setstart_date(null));generateInfo(e,{type:occurance_type,days:weekdays},end_date)}}
                      disabledDate={(current) => current<moment().subtract(1,'day') ||  current > moment(end_date)}
                      allowClear={false}
                      defaultValue={moment(when)}
                    //   disabled={!end_date}
                    //   showTime={{ 
                    //     format: 'HH:mm',
                    //  }}
                      // defaultValue={moment(when)}
                    //   disabledTime={e=>disabledRangeTime(e)}
                  />
            </Col>
            <Col span={12}>
            <p style={{fontSize:'15px'}}><span style={{color:'#ff4d4f'}}>*</span> End date</p>
                  <DatePickerField
                   ref={ref}
                      style={{ width: '100%', height: '36px', }}
                      format="YYYY-MM-DD"
                      dropdownClassName="modal-datepicker-index"
                      disabled={!start_date}
                      onChange={e =>{(e?setend_date(e):setend_date(null));generateInfo(start_date,{type:occurance_type,days:weekdays},e)}}
                      disabledDate={(current) => current < moment(start_date) ||  current > moment(start_date).add(1, "year")}
                      allowClear={false}
                      defaultValue={selected_end_date&&moment(selected_end_date)}
                    //   showTime={{ 
                    //     format: 'HH:mm',
                    //  }}
                      // defaultValue={moment(when)}
                    //   disabledTime={e=>disabledRangeTime(e)}
                  />
            </Col>
            </Row>
            <Col span={12}>
                <p style={{fontSize:'15px'}}>Repeat Every</p>
                <Form
                initialValues={{repeat:1,type:occurance_type}}
                form={form}
                key={Math.random()}
                >
                <Row wrap={false}>
                    <TextField type='number' margin="0px" onChange={()=>{generateInfo(start_date,{type:occurance_type,days:weekdays},end_date)}} style={{width:'70px'}} widthscale="80px" minValue={1} maxValue={365} name="repeat"/>
                    <SelectField
                    name="type"
                    onChange={(e)=>{set_occurance_type(e);generateInfo(start_date,{type:e,days:weekdays},end_date)}}
                    style={{width:"100px"}}
                    choices={[{value:"Day",label:"Day"},{value:"Week",label:"Week"},{value:"Month",label:"Month"}]}
                    />
                </Row>
              </Form>
            </Col>
            {
                occurance_type==='Week'?
                <div className="occurance-row" >
                    {
                        weekday_list?.map(item=>(
                            <Tooltip title={item?.label}>
                                <Button onClick={()=>{changeDay(item?.value)}} className={`day-btn ${weekdays?.includes(item?.value)?'active-day-btn':''}`}>{item?.name}</Button>
                            </Tooltip>
                        ))
                    }
                </div>:''
            }

{
                occurance_type==='Month'?
                <Form
                // initialValues={getinitialMonthValue()}
                form={monthlyForm}
                key={Math.random()}
                >
                <Radio.Group onChange={(e)=>setmonthlyvalue(e?.target?.value)} name="selection" value={monthlyvalue}>
                    <Space direction="vertical">
                        <Radio value={1}>
                            <div style={{display:'flex',flexDirection:"row",gap:"10px",justifyContent:'start',alignItems:"center"}}>
                                <p style={{margin:'0px',marginTop:"-30px"}}>On day</p>
                                <TextField type='number' disabled={monthlyvalue!==1} placeholder=' ' onChange={(e)=>generateInfo(start_date,{type:occurance_type,days:weekdays},end_date)} margin="0px" style={{width:'70px'}} widthscale="80px" minValue={1} maxValue={31} name="day"/>
                            </div>
                        </Radio>
                        <Radio value={2}>
                            <div style={{display:'flex',flexDirection:"row",gap:"10px",justifyContent:'start',alignItems:"center"}}>
                                <p style={{margin:'0px',marginTop:"0px"}}>On the</p>
                                <Select
                                    disabled={monthlyvalue!==2}
                                    name="day_count"
                                    className="occurance_dropdown"
                                    onChange={(e)=>generateInfo(start_date,{type:occurance_type,days:weekdays},end_date)}
                                    style={{width:"120px"}}>
                                    {day_count&& day_count?.map((choice, key) => (
                                         <Option
                                         style ={{
                                         color: "#A3A3A3",
                                         fontSize: "1em",
                                         fontWeight: "500",
                                         }}
                                         key={key}
                                         value={String(choice.value) === 'false' ? false : choice.value === '' ? '' : choice.value || choice.label}
                                     >
                                         {typeof choice.label === 'string' ? <ReactMarkdown>{choice.label}</ReactMarkdown> : choice.label}
                                     </Option>
                                    ))}
                                </Select>

                                <Select
                                    disabled={monthlyvalue!==2}
                                    name="week_date"
                                    className="occurance_dropdown"
                                    onChange={(e)=>generateInfo(start_date,{type:occurance_type,days:weekdays},end_date)}
                                    style={{width:"120px",marginLeft:"10px"}}>
                                    {weekday_list&& weekday_list?.map((choice, key) => (
                                         <Option
                                         style ={{
                                         color: "#A3A3A3",
                                         fontSize: "1em",
                                         fontWeight: "500",
                                         }}
                                         key={key}
                                         value={String(choice.value) === 'false' ? false : choice.value === '' ? '' : choice.value || choice.label}
                                     >
                                         {typeof choice.label === 'string' ? <ReactMarkdown>{choice.label}</ReactMarkdown> : choice.label}
                                     </Option>
                                    ))}
                                </Select>
                            </div>
                        </Radio>
                    </Space>
                </Radio.Group>
                </Form>:''
            }
            <div align="center">
                <p style={{marginTop:"20px"}}>{info}</p>
            </div>
            <div align="end">
                <Button className="occurance-save" onClick={()=>{save()}}>Save</Button>
            </div>
            </>
           
        )}
            
        </>
    )
}
export default OccuranceView
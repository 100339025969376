
import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import BadgeFamilyCreateView from "../components/badge_family_create_view";
import { withCreateBadgeFamily } from "./kudos_operation";

const BadgeFamilyCreateContainer = (props) => {
  return (
    <>
       <BadgeFamilyCreateView {...props} />
    </>
  );
};

export default compose(withApollo,withCreateBadgeFamily)(BadgeFamilyCreateContainer);

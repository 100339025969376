import React from 'react';
import CoachingConversationView from '../components/coachingConversationView';
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc';
import { COACHING_ENGAGEMENT_DASHBOARD_LISTING_QUERY } from '../graphql/userDashboardQueries.gql';
import { GROW_GOAL_QUESTION_ADDRESS } from '../../../config'

const CoachingConversationContainer =(props)=>{
    const {client,user_permission,me}=props
    const [engaement_list,setEngagementList]=React.useState()
    const [engagement_loading,setEngagementLoading]=React.useState(false)
    
    React.useEffect(()=>{
        if(user_permission?.coaching_engagement_permission && me){
            getEngagementList()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user_permission,me])

    const getEngagementList =async()=>{
        setEngagementLoading(true)
        const { data } = await client.query({
            query: COACHING_ENGAGEMENT_DASHBOARD_LISTING_QUERY,
            fetchPolicy: 'network-only',
            variables: {question_QuestionAddress:GROW_GOAL_QUESTION_ADDRESS,nextCoachingConversation_Isnull:true,employee: me?.employee?.id}
          });
          if (data ) {
            let list = data?.coach?.edges?.map(({node})=>node)?.map(i=>{

                let ActionCoach = i?.actionItems?.edges?.filter(item =>
                    item?.node?.owner?.id !== i?.coachee?.id
                  )
            
                  let ActionCoachee = i?.actionItems?.edges?.filter(item =>
                    item?.node?.owner?.id === i?.coachee?.id
                  )
                return {
                    ...i,
                    next_step:ActionCoach[0]?.node,
                    assistance_needed:ActionCoachee[0]?.node
                }
            }).concat(
                data?.coachee?.edges?.map(({node})=>node)?.map(i=>{

                    let ActionCoach = i?.actionItems?.edges?.filter(item =>
                        item?.node?.owner?.id !== i?.coachee?.id
                      )
                
                      let ActionCoachee = i?.actionItems?.edges?.filter(item =>
                        item?.node?.owner?.id === i?.coachee?.id
                      )
                    return {
                        ...i,
                        next_step:ActionCoach[0]?.node,
                        assistance_needed:ActionCoachee[0]?.node
                    }
                })
            )
            setEngagementList(list)
          }
          setEngagementLoading(false)
    }

    return (
        <>
        {user_permission?.coaching_engagement_permission&&<CoachingConversationView engagement_lists={engaement_list} engagement_loading={engagement_loading} {...props}/>}
        </>
    )
}
export default compose(withApollo)(CoachingConversationContainer)
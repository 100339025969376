// @ts-nocheck
import { message } from 'antd';
import _ from 'lodash';
import { graphql } from '@apollo/client/react/hoc';

import surveyResolvers from '../resolvers';

// Query
// import { EMAIL_PERCEPTION_REPORT_QUERY } from '../graphql/EmailPerceptionReport.gql';
import { ALL_SURVEY_QUERY } from '../graphql/AllSurveysQuery.gql';
import { ALL_ORG_SURVEY_BY_ORG_ID_QUERY } from '../graphql/AllOrgSurveysByOrgIdQuery.gql';
import { ORG_SURVEY_BY_VERTICAL_ID_QUERY } from '../graphql/OrgSurveysByVerticalIdQuery.gql';
import { ALL_ORG_SURVEY_BY_VERTICAL_QUERY } from '../graphql/AllOrgSurveysByVerticalQuery.gql';
import { ORG_SURVEY_BY_TEAM_ID_QUERY } from '../graphql/OrgSurveysByTeamIdQuery.gql';
import { ALL_ORG_SURVEY_BY_TEAM_QUERY } from '../graphql/AllOrgSurveysByTeamQuery.gql';
import { ALL_ORG_SURVEY_BY_IMPLICIT_ORG_ID_QUERY } from '../graphql/AllOrgSurveysByImplicitOrgId.gql';
import { ALL_PUBLIC_SURVEY_QUERY } from '../graphql/AllPublicSurveysQuery.gql';
import { ALL_GROUPS_FOR_SURVEY_BY_SURVEY_ID_QUERY } from '../graphql/AllGroupsForSurveyBySurveyIdQuery.gql';
import { ALL_QUESTIONS_FOR_A_GROUP_BY_GROUP_ID_QUERY } from '../graphql/AllQuestionsForAGroupByGroupIdQuery.gql';
import { GET_ALL_TOKENS_LIST_QUERY } from '../graphql/GetAllTokensListQuery.gql';
import { ALL_RESPONSES_BY_USER_ID_QUERY } from '../graphql/AllResponsesByUserId.gql';
import { SURVEY_BY_ID_GROUP_SET_QUERY, SURVEY_BY_ID_QUERY, SURVEY_BY_ID_VALUES_QUERY } from '../graphql/SurveyByIdQuery.gql';
import { PUBLIC_SURVEY_BY_ID_QUERY } from '../graphql/PublicSurveyByIdQuery.gql';
import { QUESTION_BY_ID_QUERY } from '../graphql/QuestionByIdQuery.gql';
import { GROUP_BY_ID_QUERY } from '../graphql/GroupByIdQuery.gql';
import { RESPONSE_BY_RESPONSE_ID_QUERY } from '../graphql/ResponseByResponseId.gql';
import { DELETE_TOKEN_UNDER_SURVEY } from '../graphql/DeleteTokenUnderSurvey.gql'
// Client
import { SURVEY_STATE_QUERY } from '../graphql/SurveyStateQuery.client.gql';
import { UPDATE_SURVEY_FILTER, UPDATE_SURVEY_RESPONSE_FILTER } from '../graphql/UpdateSurveyFilter.client.gql';

// Mutation
import { ADD_GROUP_MUTATION } from '../graphql/AddGroupMutation.gql';
import { ADD_QUESTION_MUTATION } from '../graphql/AddQuestionMutation.gql';
import { ADD_CHOICE_MUTATION } from '../graphql/AddChoiceMutation.gql';
import { ADD_SURVEY_MUTATION } from '../graphql/AddSurveyMutation.gql';
import { CREATE_TOKEN_MUTATION, CREATE_TOKEN_OPTIMIZED } from '../graphql/CreateTokenMutation.gql';
import { CREATE_RESPONSE_MUTATION } from '../graphql/CreateResponseMutations.gql';
import { EDIT_RESPONSE_MUTATION, EDIT_RESPONSE_MUTATION_GROUPSET, EDIT_RESPONSE_MUTATION_OPTIMIZED } from '../graphql/EditResponseMutations.gql';
import { EDIT_GROUP } from '../graphql/EditGroup.gql';
import { EDIT_SURVEY, EDIT_SURVEY_ADMIN_PANEL } from '../graphql/EditSurvey.gql';
import { EDIT_QUESTION } from '../graphql/EditQuestion.gql';
import { EDIT_CHOICE } from '../graphql/EditChoice.gql';
import { DELETE_TOKEN_OPTIMIZED } from '../graphql/DeleteToken.gql';
import { DELETE_SURVEY } from '../graphql/DeleteSurvey.gql';
import { DELETE_GROUP } from '../graphql/DeleteGroup.gql';
import { DELETE_QUESTION } from '../graphql/DeleteQuestion.gql';
import { DELETE_CHOICE } from '../graphql/DeleteChoice.gql';
import { DELETE_RESPONSE, DELETE_RESPONSE_OPTIMIZED } from '../graphql/DeleteResponse.gql';

import { PAGINATION_LIMIT } from '../../../config';
import { removeTypename } from '../../core/clientStorage';
import { getBase64StringFromInt, getGroupStringFromInt, getQuestionBase64StringFromInt, getResponsesBase64StringFromInt, getSurveyBase64StringFromInt } from '../../look';

import { ALL_RESPONSES_BY_IMPLICIT_USER_TOP_RESPONSES } from '../graphql/AllResponsesByImplicitUserTopResponses';
import { ALL_RESPONSES_BY_IMPLICIT_USER_TOP_RESPONSES_QUESTION_BASED_CHOICE } from '../graphql/AllResponsesByImplicitUserTopResponsesQuestionBasedChoice';
import { CREATE_TOKEN_UNDER_SURVEY, CREATE_TOKEN_UNDER_SURVEY_OPTIMIZED } from '../graphql/CreateTokenUnderSurvey.gql'
import { SURVEY_BY_ID_FOR_360 } from '../graphql/SurveyByIdfor360.gql'
import { UNAUTH_SUBMIT_TOKEN_RESPONSE_MUTATION } from '../graphql/UnAuthSubtmitToken.gql'
import { ORG_SURVEY_BY_ID_OPTIMIZED } from '../graphql/OrgSurveyOptimized.gql'
import { CREATE_RESPONSE_OPTIMIZED_MUTATION } from '../graphql/CreateResponseOptimized.gql'
import { RESPONSE_BY_RESPONSE_ID_OPTIMIZED } from '../graphql/RESPONSE_BY_RESPONSE_ID_OPTIMIZED.gql'
import { PUBLIC_SURVEY_BY_ID_OPTIMIZED } from '../graphql/PublicSurveyOptimized.gql'
import { SURVEY_BY_ID_NEW_OPTIMIZED } from '../graphql/surveyByIdnewQueryOptimized.gql';
import { SURVEY_RESPONSE_STATE_QUERY } from '../graphql/SurveyResponseStateQuery.client.gql';
// Query
export const withAllResponsesByUserId = Component =>
  graphql( ALL_RESPONSES_BY_USER_ID_QUERY, {
    options: ( { userId, filter, orderBy, pagination } ) => {
      return {
        variables: {
          userId: userId,
          ...pagination,
          ...filter,
          orderBy
        }
      };
    },
    props( { data } ) {
      const { loading, error, allResponsesByUserId, subscribeToMore, updateQuery } = data;
      return { loading, error, allResponsesByUserId, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withAllSurveysQuery = Component =>
  graphql( ALL_SURVEY_QUERY, {
    options: ( { filter, orderBy, pagination } ) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props( { data } ) {
      const { loading, error, allSurveysForTokens, subscribeToMore, updateQuery } = data;
      return { loading, error, allSurveysForTokens, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withAllOrgSurveysByOrgIdQuery = Component =>
  graphql( ALL_ORG_SURVEY_BY_ORG_ID_QUERY, {
    options: ( { filter, orderBy, pagination, match, navigation } ) => {
      let id = '';
      if ( match ) {
        id = match.params.id;
      } else if ( navigation ) {
        id = navigation.state.params.id;
      }

      return {
        variables: {
          orgId: Number( id ),
          orderBy,
          ...filter,
          ...pagination
        }
      };
    },
    props( { data } ) {
      const { loading, error, allOrgSurveysByOrgId, subscribeToMore, updateQuery } = data;
      return { loading, error, allOrgSurveysByOrgId, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withOrgSurveysByVerticalIdQuery = Component =>
  graphql( ORG_SURVEY_BY_VERTICAL_ID_QUERY, {
    options: ( { filter, orderBy, pagination, match, navigation } ) => {
      let id = '';
      if ( match ) {
        id = match.params.id;
      } else if ( navigation ) {
        id = navigation.state.params.id;
      }
      return {
        variables: {
          verticalId: Number( id ),
          orderBy,
          ...filter,
          ...pagination
        }
      };
    },
    props( { data }, history ) {
      const { loading, error, orgSurveyByVerticalId, subscribeToMore, updateQuery } = data;
      return { loading, error, orgSurveyByVerticalId, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withAllOrgSurveysByVerticalQuery = Component =>
  graphql( ALL_ORG_SURVEY_BY_VERTICAL_QUERY, {
    options: props => {
      let id = '';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: {
          id: Number( id ),
          first: PAGINATION_LIMIT
        }
      };
    },
    props( { data }, history ) {
      const { loading, error, orgSurveyByVertical, subscribeToMore, updateQuery } = data;
      return { loading, error, orgSurveyByVertical, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withOrgSurveysByTeamIdQuery = Component =>
  graphql( ORG_SURVEY_BY_TEAM_ID_QUERY, {
    options: ( { filter, orderBy, pagination, match, navigation } ) => {
      let id = '';
      if ( match ) {
        id = match.params.id;
      } else if ( navigation ) {
        id = navigation.state.params.id;
      }
      return {
        variables: {
          teamId: Number( id ),
          orderBy,
          ...filter,
          ...pagination
        }
      };
    },
    props( { data }, history ) {
      const { loading, error, orgSurveyByTeamId, subscribeToMore, updateQuery } = data;
      return { loading, error, orgSurveyByTeamId, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withAllOrgSurveysByTeamQuery = Component =>
  graphql( ALL_ORG_SURVEY_BY_TEAM_QUERY, {
    options: props => {
      let id = '';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: {
          id: Number( id ),
          first: PAGINATION_LIMIT
        }
      };
    },
    props( { data }, history ) {
      const { loading, error, orgSurveyByTeam, subscribeToMore, updateQuery } = data;
      return { loading, error, orgSurveyByTeam, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withAllOrgSurveysByImplicitOrgIdQuery = Component =>
  graphql( ALL_ORG_SURVEY_BY_IMPLICIT_ORG_ID_QUERY, {
    options: ( { filter, orderBy, pagination } ) => {
      return {
        variables: {
          ...filter,
          ...pagination,
          orderBy
        }
      };
    },
    props( { data } ) {
      const { loading, error, allOrgSurveysByImplicitOrgId, subscribeToMore, updateQuery } = data;
      return {
        allOrgSurveysByImplicitOrgIdLoading: loading,
        error,
        allOrgSurveysByImplicitOrgId,
        allOrgSurveysByImplicitOrgIdSubscribeToMore: subscribeToMore,
        updateQuery
      };
    }
  } )( Component );

export const withAllPublicSurveysQuery = Component =>
  graphql( ALL_PUBLIC_SURVEY_QUERY, {
    options: props => {
      return {
        variables: {
          first: PAGINATION_LIMIT
        }
      };
    },
    props( { data }, history ) {
      const { loading, error, allPublicSurveys, subscribeToMore, updateQuery } = data;
      return { loading, error, allPublicSurveys, allPublicSurveysSubscribeToMore: subscribeToMore, updateQuery };
    }
  } )( Component );

export const withAllGroupsForSurveyBySurveyIdQuery = Component =>
  graphql( ALL_GROUPS_FOR_SURVEY_BY_SURVEY_ID_QUERY, {
    options: ( { filter, match, navigation, orderBy, pagination } ) => {
      let id = '';
      if ( match ) {
        id = match.params.id;
      } else if ( navigation ) {
        id = navigation.state.params.id;
      }

      if ( filter.sequence_Icontains === '' ) delete filter.sequence_Icontains;
      else filter.sequence_Icontains = Number( filter.sequence_Icontains );
      return {
        variables: {
          ...pagination,
          ...filter,
          orderBy,
          id: Number( id )
        }
      };
    },
    props( { data }, history ) {
      const { loading, error, fetchMore, allGroupsForSurveyBySurveyId, subscribeToMore, updateQuery } = data;
      const loadData = ( startCursor, dataDelivery, isEdge, endCursor, pageSize ) => {
        // isEdge ->  last, next, prev
        const variables =
          dataDelivery === 'add'
            ? { after: startCursor }
            : isEdge === 'custom-cursor'
              ? { first: pageSize, after: startCursor, before: endCursor }
              : isEdge === 'last'
                ? { first: undefined, last: PAGINATION_LIMIT }
                : isEdge === 'first'
                  ? { first: PAGINATION_LIMIT }
                  : isEdge === 'next'
                    ? { after: startCursor }
                    : { first: undefined, last: PAGINATION_LIMIT, before: startCursor };

        return fetchMore( {
          variables,
          updateQuery: ( previousResult, { fetchMoreResult } ) => {
            const totalCount = fetchMoreResult.allGroupsForSurveyBySurveyId.totalCount;
            const newEdges = fetchMoreResult.allGroupsForSurveyBySurveyId.edges;
            const pageInfo = fetchMoreResult.allGroupsForSurveyBySurveyId.pageInfo;
            const displayedEdges =
              dataDelivery === 'add' ? [ ...previousResult.allGroupsForSurveyBySurveyId.edges, ...newEdges ] : newEdges;

            return {
              // By returning `cursor` here, we update the `fetchMore` function
              // to the new cursor.
              allGroupsForSurveyBySurveyId: {
                totalCount,
                edges: displayedEdges,
                pageInfo,
                __typename: 'AllPublicSurveys'
              }
            };
          }
        } );
      };
      return { loading, error, allGroupsForSurveyBySurveyId, subscribeToMore, updateQuery, loadData };
    }
  } )( Component );

export const withAllQuestionsForAGroupByGroupIdQuery = Component =>
  graphql( ALL_QUESTIONS_FOR_A_GROUP_BY_GROUP_ID_QUERY, {
    options: ( { filter, match, navigation, orderBy, pagination } ) => {
      let id = '';
      if ( match ) {
        id = match.params.id;
      } else if ( navigation ) {
        id = navigation.state.params.id;
      }

      if ( filter.sequence_Icontains === '' ) delete filter.sequence_Icontains;
      else filter.sequence_Icontains = Number( filter.sequence_Icontains );

      return {
        variables: {
          ...pagination,
          ...filter,
          orderBy,
          id: Number( id )
        }
      };
    },
    props( { data }, history ) {
      const { loading, error, allQuestionsForAGroupByGroupId, subscribeToMore, updateQuery } = data;
      return { loading, error, allQuestionsForAGroupByGroupId, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withGetAllTokensListQuery = Component =>
  graphql( GET_ALL_TOKENS_LIST_QUERY, {
    options: ( { filter, orderBy, pagination } ) => {
      let newFilter = filter;
      if ( filter.invited === '' ) newFilter = _.omit( newFilter, 'invited' );
      if ( filter.valid === '' ) newFilter = _.omit( newFilter, 'valid' );
      return { variables: { ...pagination, ...newFilter, orderBy } };
    },
    props( { data } ) {
      const { loading, error, getAllTokensList, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllTokensList, subscribeToMore, updateQuery };
    }
  } )( Component );


export const withSurveyById = Component =>
  graphql( SURVEY_BY_ID_QUERY, {
    options: props => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
        return {
          variables: { id: getSurveyBase64StringFromInt( Number( id ) ), responseUser:props?.me?.id },
          fetchPolicy: 'network-only'
        };
    },
    props( { data: { loading, error, SurveyById, subscribeToMore, updateQuery } } ) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, SurveyById, subscribeToMore, updateQuery };
    }
  } )( Component );


export const withSurveyByIdGroupSet = Component =>
  graphql( SURVEY_BY_ID_GROUP_SET_QUERY, {
    options: props => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      if(props?.me?.id){
      return {
        variables: { id: getSurveyBase64StringFromInt( Number( id ) ), responseUser: props?.me?.id},
        fetchPolicy: 'network-only'
      };
    }
    },
    props( { data: { loading, error, SurveyById, subscribeToMore, updateQuery } } ) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, SurveyById, subscribeToMore, updateQuery };
    }
  } )( Component );


export const withSurveyByIdValueSet = Component =>
  graphql( SURVEY_BY_ID_VALUES_QUERY, {
    
    options: props => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      if(props?.me?.id){
        return {
          variables: { id: getSurveyBase64StringFromInt( Number( id ) ), responseUser: props?.me?.id },
          fetchPolicy: 'network-only'
        };
      }
    },
    props( { data: { loading, error, surveyChoices, surveyAnswers, subscribeToMore, updateQuery } } ) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, surveyChoices, surveyAnswers, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withOrgSurveyById = Component =>
  graphql( ORG_SURVEY_BY_ID_OPTIMIZED, {
    options: props => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: getSurveyBase64StringFromInt(Number(id) ) },
        fetchPolicy: 'network-only'
      };
    },
    props( { data: { loading, error, orgSurveyById, subscribeToMore, updateQuery } } ) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, orgSurveyById, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withGroupById = Component =>
  graphql( GROUP_BY_ID_QUERY, {
    options: props => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: getGroupStringFromInt(id ) }
      };
    },
    props( { data: { loading, error, groupById, subscribeToMore, updateQuery } } ) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, groupById, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withQuestionById = Component =>
  graphql( QUESTION_BY_ID_QUERY, {
    options: props => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: getQuestionBase64StringFromInt( id ) }
      };
    },
    props( { data: { loading, error, questionById, subscribeToMore, updateQuery } } ) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, questionById, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withPublicSurveyById = Component =>
  graphql( PUBLIC_SURVEY_BY_ID_QUERY, {
    options: props => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: Number( id )},
        fetchPolicy: 'network-only'
      };
    },
    props( { data: { loading, error, publicSurveyById, subscribeToMore, updateQuery } } ) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, publicSurveyById, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withResponseByResponseId = Component =>
  graphql( RESPONSE_BY_RESPONSE_ID_QUERY, {
    options: props => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: getResponsesBase64StringFromInt( id ) }
      };
    },
    props( { data: { loading, error, responseByResponseId, subscribeToMore, updateQuery } } ) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, responseByResponseId, subscribeToMore, updateQuery };
    }
  } )( Component );

// Mutation
export const withCreateResponse = Component =>
  graphql( CREATE_RESPONSE_MUTATION, {
    props: ( { mutate, history } ) => ( {
      createResponse: async values => {
        try {
          const {
            data: { createResponse }
          } = await mutate( {
            variables: {
              ...values
            }
          } );

          message.destroy();
          message.success( 'Submitted response!!' );
          return createResponse.response;
        } catch ( e ) {
          message.destroy();
          message.error( "Couldn't perform the action" );
        }
      }
    } )
  } )( Component );

  export const withAddGroup = Component =>
  graphql( ADD_GROUP_MUTATION, {
    props: ( { mutate, history } ) => ( {
      createGroup: async values => {
        try {
          const {
            data: { createGroup }
          } = await mutate( {
            variables: {
              ...values
            }
          } );

          message.destroy();
          message.success( 'Submitted response!!' );
          return createGroup.group;
        } catch ( e ) {
          message.destroy();
          message.error( "Couldn't perform the action" );
        }
      }
    } )
  } )( Component );


  export const withAddQuestion = Component =>
  graphql( ADD_QUESTION_MUTATION, {
    props: ( { mutate, history } ) => ( {
      createQuestion: async values => {
        try {
          const {
            data: { createQuestion }
          } = await mutate( {
            variables: {
              ...values
            }
          } );

          message.destroy();
          message.success( 'Submitted response!!' );
          return createQuestion.question;
        } catch ( e ) {
          message.destroy();
          message.error( "Couldn't perform the action" );
        }
      }
    } )
  } )( Component );

export const withAddChoice = Component =>
  graphql( ADD_CHOICE_MUTATION, {
    props: ( { mutate, history } ) => ( {
      addChoice: async choiceData => {
        try {
          const {
            data: { createChoice }
          } = await mutate( {
            variables: {
              choiceData
            }
          } );

          message.destroy();
          return createChoice.choice;
        } catch ( e ) {
          message.destroy();
          message.error( "Couldn't perform the action" );
        }
      }
    } )
  } )( Component );

export const withAddSurvey = Component =>
graphql( ADD_SURVEY_MUTATION, {
  props: ( { mutate, history } ) => ( {
    createSurvey: async values => {
      try {
        const {
          data: { createSurvey }
        } = await mutate( {
          variables: {
            ...values
          }
        } );

        message.destroy();
        return createSurvey.survey;
      } catch ( e ) {
        message.destroy();
        message.error( "Couldn't perform the action" );
      }
    }
  } )
} )( Component );

export const withSubmitTokenResponse = Component =>
  graphql( UNAUTH_SUBMIT_TOKEN_RESPONSE_MUTATION, {
    props: ( { mutate, history } ) => ( {
      submitTokenResponse: async values => {
        try {
          const {
            data: { submitTokenResponse }
          } = await mutate( {
            variables: {
              ...values
            }
          } );

          message.destroy();
          message.success( 'Submitted response!!' );
          return submitTokenResponse.response;
        } catch ( e ) {
          message.destroy();
          message.error( "Couldn't perform the action" );
        }
      }
    } )
  } )( Component );

export const withMultipleCreateToken = Component =>
  graphql( CREATE_TOKEN_MUTATION, {
    props: ( { mutate } ) => ( {
      createToken: async values => {
        const {
          data: { createToken },
          errors
        } = await mutate( {
          variables: {
            ...values
          }
        } );
        return { token: createToken.token, errors };
      }
    } )
  } )( Component );


  export const withCreateToken = Component =>
  graphql( CREATE_TOKEN_MUTATION, {
    props: ( { mutate } ) => ( {
      createToken: async values => {
        const {
          data: { createToken },
          errors
        } = await mutate( {
          variables: {
            ...values
          }
        } );
        return { token: createToken.token, errors };
      }
    } )
  } )( Component );

  export const withCreateTokenOprimized = Component =>
  graphql( CREATE_TOKEN_OPTIMIZED, {
    props: ( { mutate } ) => ( {
      createToken: async values => {
        const {
          data: { createToken },
          errors
        } = await mutate( {
          variables: {
            ...values
          }
        } );
        return { token: createToken.token, errors };
      }
    } )
  } )( Component );

export const withEditResponse = Component =>
  graphql( EDIT_RESPONSE_MUTATION, {
    props: ( { mutate } ) => ( {
      editResponse: async values => {
        try {
          const {
            data: { updateResponse }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          return updateResponse.response;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );


export const withEditResponseGroupSet = Component =>
  graphql( EDIT_RESPONSE_MUTATION_GROUPSET, {
    props: ( { mutate } ) => ( {
      editResponse: async values => {
        try {
          const {
            data: { updateResponse }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          return updateResponse.response;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );

export const withEditGroup = Component =>
  graphql( EDIT_GROUP, {
    props: ( { mutate } ) => ( {
      editGroup: async values => {
        try {
          const {
            data: { updateGroup }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          return updateGroup.group;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );


  export const withAdminPanelEditSurvey = Component =>
  graphql( EDIT_SURVEY_ADMIN_PANEL, {
    props: ( { mutate } ) => ( {
      editSurvey: async values => {
        try {
          const {
            data: { updateSurvey }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          return updateSurvey.survey;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );


export const withEditSurvey = Component =>
  graphql( EDIT_SURVEY, {
    props: ( { mutate } ) => ( {
      editSurvey: async values => {
        try {
          const {
            data: { updateSurvey }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          return updateSurvey.survey;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );

export const withEditQuestion = Component =>
  graphql( EDIT_QUESTION, {
    props: ( { mutate } ) => ( {
      editQuestion: async values => {
        try {
          const {
            data: { updateQuestion }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          return updateQuestion.question;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );

export const withEditChoice = Component =>
  graphql( EDIT_CHOICE, {
    props: ( { mutate } ) => ( {
      editChoice: async values => {
        try {
          const {
            data: { updateChoice }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          return updateChoice.choice;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );

  export const withDeleteToken = Component =>
  graphql(DELETE_TOKEN_OPTIMIZED, {
    props: ({ mutate, history }) => ({
      deleteToken: async values => {
        try {
          const {
            data: { deleteToken }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Successfully deleted');
          return deleteToken.token;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDeleteGroup = Component =>
  graphql(DELETE_GROUP, {
    props: ({ mutate, history }) => ({
      deleteGroup: async values => {
        try {
          const {
            data: { deleteGroup }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Successfully deleted');
          return deleteGroup.group;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withDeleteSurvey = Component =>
  graphql(DELETE_SURVEY, {
    props: ({ mutate, history }) => ({
      deleteSurvey: async values => {
        try {
          const {
            data: { deleteSurvey }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Successfully deleted');
          return deleteSurvey.survey;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDeleteQuestion = Component =>
  graphql(DELETE_QUESTION, {
    props: ({ mutate, history }) => ({
      deleteQuestion: async values => {
        try {
          const {
            data: { deleteQuestion }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Successfully deleted');
          return deleteQuestion.question;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDeleteChoice = Component =>
  graphql(DELETE_CHOICE, {
    props: ({ mutate, history }) => ({
      deleteChoice: async values => {
        try {
          const {
            data: { deleteChoice }
          } = await mutate({
            variables: {
              ...values
            }
          });
  
          message.destroy();
          message.success('Successfully deleted');
          return deleteChoice.choice;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

export const withDeleteResponse = Component =>
  graphql( DELETE_RESPONSE, {
    props: ( { mutate } ) => ( {
      deleteResponse: async id => {
        try {
          const {
            data: { deleteResponse }
          } = await mutate( { variables: { id } } );
          return deleteResponse.response;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );


  export const withDeleteResponseOptimized = Component =>
  graphql( DELETE_RESPONSE_OPTIMIZED, {
    props: ( { mutate } ) => ( {
      deleteResponse: async id => {
        try {
          const {
            data: { deleteResponse }
          } = await mutate( { variables: { id } } );
          return deleteResponse.response;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );

export const withTopResponses = Component =>
  graphql( ALL_RESPONSES_BY_IMPLICIT_USER_TOP_RESPONSES, {
    options: ( props ) => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      let survey = getBase64StringFromInt( 'SurveyType', id )
      return {
        variables: { survey },
        fetchPolicy: 'network-only'
      };
    },
    props( { data } ) {
      const { loading, error, allResponsesByImplicitUserId, subscribeToMore, updateQuery } = data;
      return { loading, error, allResponsesByImplicitUserId, subscribeToMore, updateQuery };
    }
  } )( Component );


export const withTopResponsesQuestionBased = Component =>
  graphql( ALL_RESPONSES_BY_IMPLICIT_USER_TOP_RESPONSES_QUESTION_BASED_CHOICE, {
    options: ( props ) => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      let survey = getBase64StringFromInt( 'SurveyType', id )
      return { variables: { survey } };
    },
    props( { data } ) {
      const { loading, error, allResponsesByImplicitUserId, subscribeToMore, updateQuery } = data;
      return { loading, error, allResponsesByImplicitUserId, subscribeToMore, updateQuery };
    }
  } )( Component );

// Client
export const withSurveyState = Component =>
  graphql( SURVEY_STATE_QUERY, {
    props( { data } ) {
      const { orderBy, ...rest } = data.surveyState;
      const surveyState = { ...removeTypename( rest ), orderBy };
      return { ...surveyState, stateLoading: data.loading };
    }
  } )( Component );

  export const withSurveyResponseState = Component =>
  graphql( SURVEY_RESPONSE_STATE_QUERY, {
    props( { data } ) {
        
      const { orderBy, ...rest } = data.surveyResponseState;
      const surveyResponseState = { ...removeTypename( rest ), orderBy };
      return { ...surveyResponseState, stateLoading: data.loading };
    }
  } )( Component );

export const withSurveyFilterUpdating = Component =>
  graphql( UPDATE_SURVEY_FILTER, {
    props: ( { mutate } ) => ( {
      onOrderByChange( orderBy ) {
        mutate( { variables: { orderBy } } );
      },
      onPaginationChange( pagination ) {
        mutate( { variables: { pagination } } );
      },
      onFiltersRemove() {
        mutate( { variables: { ...surveyResolvers.defaults.surveyState } } );
      },
      onNameChange( name_Icontains ) {
        mutate( { variables: { filter: { name_Icontains } } } );
      },
      onDescriptionChange( description_Icontains ) {
        mutate( { variables: { filter: { description_Icontains } } } );
      },
      onLabelChange( label_Icontains ) {
        mutate( { variables: { filter: { label_Icontains } } } );
      },
      onHelpTextChange( helpText_Icontains ) {
        mutate( { variables: { filter: { helpText_Icontains } } } );
      },
      onSequenceChange( sequence_Icontains ) {
        mutate( { variables: { filter: { sequence_Icontains } } } );
      },
      onQuestionTextChange( questionText_Icontains ) {
        mutate( { variables: { filter: { questionText_Icontains } } } );
      },
      onQuestionAddressChange( questionAddress_Icontains ) {
        mutate( { variables: { filter: { questionAddress_Icontains } } } );
      },
      onUserChange( user ) {
        mutate( { variables: { filter: { user } } } );
      },
      onSurveyChange( survey ) {
        mutate( { variables: { filter: { survey } } } );
      },
      onCreatedByChange( createdBy ) {
        mutate( { variables: { filter: { createdBy } } } );
      },
      onInvitedChange( invited ) {
        mutate( { variables: { filter: { invited } } } );
      },
      onValidChange( valid ) {
        mutate( { variables: { filter: { valid } } } );
      },
      onSurveyNameChange( survey_Name_Icontains ) {
        mutate( { variables: { filter: { survey_Name_Icontains } } } );
      }
    } )
  } )( Component );

  export const withSurveyResponseFilterUpdating = Component =>
  graphql( UPDATE_SURVEY_RESPONSE_FILTER, {
    props: ( { mutate } ) => ( {
      onOrderByChange( orderBy ) {
        mutate( { variables: { orderBy } } );
      },
      onPaginationChange( pagination ) {
        mutate( { variables: { pagination } } );
      },
      onFiltersRemove() {
        mutate( { variables: { ...surveyResolvers.defaults.surveyState } } );
      },
      onNameChange( name_Icontains ) {
        mutate( { variables: { filter: { name_Icontains } } } );
      },
      onSurveyNameChange( survey_Name_Icontains ) {
        mutate( { variables: { filter: { survey_Name_Icontains } } } );
      }
    } )
  } )( Component );

export const withTokenDelete = Component =>
  graphql( DELETE_TOKEN_UNDER_SURVEY, {
    props: ( { mutate, history } ) => ( {
      deleteToken: async values => {
        try {
          const {
            data: { deleteToken }
          } = await mutate( {
            variables: {
              ...values
            }
          } );

          message.destroy();
          message.success( 'Successfully deleted' );
          return deleteToken?.token;
        } catch ( e ) {
          message.destroy();
          message.error( "Couldn't perform the action" );
          console.error( e );
        }
      }
    } )
  } )( Component );

export const withCreateTokenBySurvey = Component =>
  graphql( CREATE_TOKEN_UNDER_SURVEY, {
    props: ( { mutate, history } ) => ( {
      createSourceResponse: async values => {
        try {
          const {
            data: { createSourceResponse }
          } = await mutate( {
            variables: {
              ...values
            }
          } );

          message.destroy();
          // message.success( 'Submitted response!!' );
          return createSourceResponse?.token;
        } catch ( e ) {
          message.destroy();
          if(e?.graphQLErrors?.length){
            message.error( e?.graphQLErrors[0]?.message );
          }else{
            message.error( "Failed to generate 360 survey invite" );
          }
        }
      }
    } )
  } )( Component );

  export const withCreateTokenBySurveyOptimized = Component =>
  graphql( CREATE_TOKEN_UNDER_SURVEY_OPTIMIZED, {
    props: ( { mutate, history } ) => ( {
      createSourceResponse: async values => {
        try {
          const {
            data: { createSourceResponse }
          } = await mutate( {
            variables: {
              ...values
            }
          } );

          message.destroy();
          message.success( 'Submitted response!!' );
          return createSourceResponse.token;
        } catch ( e ) {
          message.destroy();
          message.error( "Couldn't perform the action" );
          console.error( e );
        }
      }
    } )
  } )( Component );


export const with360SurveyByid = Component =>
  graphql( SURVEY_BY_ID_FOR_360, {
    options: props => {
      return {
        variables: { id: props?.match?.params?.surveyid }
      };
    },
    props( { data: { loading, error, SurveyById, subscribeToMore, updateQuery, refetch } } ) {
      if ( error ) {
        throw new Error( error.message );
      }
      return { loading, error, SurveyById, subscribeToMore, updateQuery, refetch };
    }
  } )( Component );

export const withCreateResponseOptimized = Component =>
  graphql( CREATE_RESPONSE_OPTIMIZED_MUTATION, {
    props: ( { mutate, history } ) => ( {
      createResponse: async values => {
        try {
          const {
            data: { createResponse }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          // message.destroy();
          // message.success( 'Submitted response!!' );
          return createResponse.response;
        } catch ( e ) {
          message.destroy();
          message.error( "Failed to submit the survey response" );
          console.error( e );
        }
      }
    } )
  } )( Component );

export const withResponseByResponseIdOptimized = Component =>
  graphql( RESPONSE_BY_RESPONSE_ID_OPTIMIZED, {
    options: props => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: getResponsesBase64StringFromInt( id ) }
      };
    },
    props( { data: { loading, error, responseByResponseId, subscribeToMore, updateQuery } } ) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, responseByResponseId, subscribeToMore, updateQuery };
    }
  } )( Component );


export const withPublicSurveyByIdOptimized = Component =>
  graphql( PUBLIC_SURVEY_BY_ID_OPTIMIZED, {
    options: props => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: Number( id ) }
      };
    },
    props( { data: { loading, error, publicSurveyById, subscribeToMore, updateQuery } } ) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, publicSurveyById, subscribeToMore, updateQuery };
    }
  } )( Component );

  export const withSurveyByIdNewOptimized = Component =>
  graphql( SURVEY_BY_ID_NEW_OPTIMIZED, {
    options: props => {
      let id = '0';
      if ( props.match ) {
        id = props.match.params.id;
      } else if ( props.navigation ) {
        id = props.navigation.state.params.id;
      }
      return {
        variables: { id: getSurveyBase64StringFromInt( id ) }
      };
    },
    props( { data: { loading, error, SurveyById, subscribeToMore, updateQuery } } ) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, SurveyById, subscribeToMore, updateQuery };
    }
  } )( Component );

export const withEditResponseOptimized = Component =>
  graphql( EDIT_RESPONSE_MUTATION_OPTIMIZED, {
    props: ( { mutate } ) => ( {
      editResponse: async values => {
        try {
          const {
            data: { updateResponse }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.success( 'updated' )
          return updateResponse.response;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );

  
// @ts-nocheck
import React, { useRef, useState } from 'react';
import { Input, Spin, Tooltip } from "antd";
import search_icon from '../../assets/search-inverted.svg'
import '../css/transcriptionSummary.css'
import { displayUtcToUserTimezone } from 'modules/look';
import AudioPlayer from '../../look-v2/Components/AudioPlayer';
import download_icon from '../../assets/direct-download.png'
import { LoadingOutlined } from '@ant-design/icons';
import wave_signal from '../../assets/sound_wave.gif'
import { audio_auto_play_timer } from '../../../config';
const TranscriptionSummaryView = props => {
    const { setSearch, transcription_loading, transcription_list, me, search, pagination, hasNextPage, cached_audio, download_list,played_audio_ref } = props
    const [current_player, setCurrentPlayer] = useState()
    const [read_audio, setReadAudio] = useState(played_audio_ref)
    const auto_play_timer = useRef(null)
    const latest_audio_played = useRef(null)
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    React.useEffect(()=>{
        if(transcription_list?.length && latest_audio_played.current && auto_play_timer.current){
            const next_player = document.getElementById(`audio_${latest_audio_played.current?.index + 1}`)
            if(next_player){
                if(auto_play_timer.current){
                    clearTimeout(auto_play_timer.current)
                    auto_play_timer.current=null
                }
                playNextAudio(latest_audio_played.current)
            }
        }
    },[transcription_list])

    const audioEnd = (data) => {
        setCurrentPlayer(null)
        latest_audio_played.current = data
        const next_player = document.getElementById(`audio_${data?.index + 1}`)
        if (next_player) {
            playNextAudio(data)
        }else{
            if(auto_play_timer.current){
                clearTimeout(auto_play_timer.current)
                auto_play_timer.current=null
            }
            auto_play_timer.current = setTimeout(() => {
                playNextAudio(data)
            }, audio_auto_play_timer || 5000);
        }
    }

    const playNextAudio=(data)=>{
        const next_player = document.getElementById(`audio_${data?.index + 1}`)
        if (next_player) {
            next_player.play()
            next_player.scrollIntoView({ behavior: 'smooth', block: 'center'});
        }
    }

    const audioPlay = (data) => {
        if(auto_play_timer.current){
            clearTimeout(auto_play_timer.current)
            auto_play_timer.current=null
        }
        played_audio_ref.push(data?.chunk_id)
        setReadAudio([].concat(played_audio_ref))
        setCurrentPlayer(data?.index)
        pauseOtherAudio(data)
        let next_sequence = transcription_list.find(item => item?.index === data.index + 1)
        if (next_sequence && !next_sequence?.audio && !download_list?.includes(next_sequence?.chunk_id)) {
            cached_audio(next_sequence?.chunk_id)
        }
    }

    const audioPause = (data) => {
        if (String(current_player) === String(data.index)) {
            setCurrentPlayer(null)
        }
    }

    const pauseOtherAudio = (data) => {
        let list = transcription_list?.filter(item => item.index !== data.index)
        if (list?.length) {
            list.forEach(element => {
                const audio_player = document.getElementById(`audio_${element?.index}`)
                if (audio_player) {
                    audio_player.pause()
                }
            });
        }
    }

    const antIcon = (
        <LoadingOutlined
            style={{
                color: "black",
                fontSize: 18,
                fontWeight: 500
            }}
            spin
        />
    );

    const downloadAudio = (data) => {
        if (!data?.audio && !download_list?.includes(data?.chunk_id)) {
            cached_audio(data?.chunk_id)
        }
    }

    const jumbToAudio = (index) => {
        if (index) {
            const current_player = document.getElementById(`audio_${index}`)
            if (current_player) {
                current_player.scrollIntoView({ behavior: 'smooth', block: 'center'});
                // let class_name = current_player.classList
                // let default_class = 'audio-player transcript-audio-player'
                // current_player.classList = `${class_name} highlight-audio-player`
                // setTimeout(() => {
                //     current_player.classList = class_name
                // }, 1200);
            }
            
        }
    }

    const renderMessage = (data) => {
        if (String(data?.user_id) === String(me?.employee?.user?.id)) {
            return (
                <div className='tright-aligned-container'>
                    <div className='tmsg-container'>
                        <div className='tmsg-info'>
                            <div className='tuser-info align-end' >
                                <h4 className='tuser-info-label'>{displayUtcToUserTimezone(data?.requsted_date, 'hh:mm a')}</h4>
                                <h4 className='tuser-info-label text-transform-capitalize'>{data?.firstName}</h4>
                            </div>
                            <h4 className='tmsg'>{data?.message}</h4>
                            {
                                !isSafari && (
                                    data?.audio ?
                                        <AudioPlayer data={data} showCustomAudio={true} {...props} audioEnd={audioEnd} audioPlay={audioPlay} audioPause={audioPause} className="transcript-audio-player" controlsList="nodownload noplaybackrate" read_audio={read_audio}/> :
                                        <div className='audio-border'>
                                            {
                                                download_list?.includes(data?.chunk_id) ?
                                                    <Spin indicator={antIcon} /> :
                                                    <img src={download_icon} onClick={() => { downloadAudio(data) }} className='audio-download-icon' alt="" srcset="" />
                                            }
                                            <div className='audio-progress'></div>
                                        </div>
                                )
                            }
                        </div>
                        <div>
                            {
                                data?.profile_image ?
                                    <img src={data?.profile_image} className='timg-container' alt="" /> :
                                    <div className='tchara-container'>
                                        <h5 className='tpro-char text-transform-capitalize'>{data?.firstName.charAt(0)}</h5>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='tleft-aligned-container'>
                    <div className='tmsg-container'>
                        <div>
                            {
                                data?.profile_image ?
                                    <img src={data?.profile_image} className='timg-container' alt="" /> :
                                    <div className='tchara-container'>
                                        <h5 className='tpro-char text-transform-capitalize'>{data?.firstName.charAt(0)}</h5>
                                    </div>
                            }
                        </div>
                        <div className='tmsg-info'>
                            <div className='tuser-info align-start' >
                                <h4 className='tuser-info-label text-transform-capitalize'>{data?.firstName}</h4>
                                <h4 className='tuser-info-label'>{displayUtcToUserTimezone(data?.requsted_date, 'hh:mm a')}</h4>
                            </div>
                            <h4 className='tmsg'>{data?.message}</h4>
                            {
                                !isSafari && (
                                    data?.audio ?
                                        <AudioPlayer data={data} showCustomAudio={true} {...props} audioEnd={audioEnd} audioPlay={audioPlay} audioPause={audioPause} className="transcript-audio-player" controlsList="nodownload noplaybackrate" read_audio={read_audio} /> :
                                        <div className='audio-border'>
                                            {
                                                download_list?.includes(data?.chunk_id) ?
                                                    <Spin indicator={antIcon} /> :
                                                    <img src={download_icon} onClick={() => { downloadAudio(data) }} className='audio-download-icon' alt="" srcset="" />
                                            }
                                            <div className='audio-progress'></div>
                                        </div>
                                )
                            }

                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            <div className='transcription-summary-root'>
                <div align="center" style={{ padding: "0.25em" }}>
                    <div className='tsearch-container'>
                        <Input className='tsummary-search'
                        autoComplete='off'
                         // eslint-disable-next-line no-lone-blocks
                         value={search} placeholder='Search' onChange={(e) => { { pauseOtherAudio({ index: "none" }); setSearch(e?.target.value) } }}></Input>
                        <img src={search_icon} alt="" className='tsearch-icon' />
                    </div>
                </div>
                {
                    current_player ?
                        <div style={{ width: "100%", position: "relative" }}>
                            <Tooltip title="jumb to currently playing audio" >
                                <img src={wave_signal} onClick={() => { jumbToAudio(current_player) }} className='auido-sound-signal' alt="" srcset="" />
                            </Tooltip>
                        </div>
                        : ""
                }
                <Spin spinning={transcription_loading}>
                    <div className="past-meeting-data-overflow" style={{ marginRight: '5px', minHeight: "150px" }}  >
                        {
                            transcription_list?.length ?
                                transcription_list.map(item => (renderMessage(item)))
                                :
                                !transcription_loading ?
                                    <h4 className="no-data pt-33" style={{ color: "rgb(191, 191, 191)" }}>No Transcription Data Found</h4>
                                    : ''
                        }
                        {
                            (hasNextPage && transcription_list?.length) ?
                                <div align="center">
                                    <h4 onClick={() => { pagination() }} className='transcript-view-more'>view more</h4>
                                </div>
                                : ''
                        }
                    </div>
                </Spin>
            </div>
        </>
    )
}
export default TranscriptionSummaryView;



import React from 'react';
import { CloseCircleFilled } from "@ant-design/icons";
import { Row,Button,Dropdown,Menu,Input} from "antd";
import styled from "styled-components";
// import { DatePickerField } from '../../look';
import search_icon from '../../assets/search-inverted.svg'
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
// import tick_icon from '../../assets/active_tick.svg'
import dropdown_arrow from '../../assets/one-on-one-dropdown-arrow.svg';

const FilterOneOnOne = props => {
    const {
        filterData,
        onfilterChange    } = props; 
    
    const [selectedFilter,setFilter]=React.useState('Title')
    const [filterList,setfilterList]=React.useState([])
    const [search_value,setSearchValue]=React.useState()
    const [show_close,setShowClose]=React.useState(false)
    const timeout_ref =React.useRef()
    React.useEffect(()=>{
        let selected_Data =JSON.parse(localStorage.getItem("one_on_one_filter"))
        if(selected_Data!=null){
            setShowClose(true)
            setFilter(selected_Data?.filter)
            filterValueChange(selected_Data?.search_value)
            setSearchValue(selected_Data?.search_value)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    React.useEffect(()=>{
        if(filterData){
            setfilterList(filterData)
        }
    },[filterData])
    const filterValueChange=(value)=>{
        switch (selectedFilter){
            case 'Title':{
              
                onfilterChange({title_Icontains:value})
                break
            }
            case 'Action item':{
              
                onfilterChange({actionitem_Title_Icontains:value})
                break
            }
            case 'Agenda':{
              
                onfilterChange({actionitem_Title_Icontains:value})
                break
            }
            case 'Owner':{
              
                onfilterChange({owner_User_FirstName_Icontains:value})
                break
            }
            case 'Member':{
              
                onfilterChange({member_User_FirstName_Icontains:value})
                break
            }
            default : break
        }
        localStorage.setItem("one_on_one_filter",JSON.stringify({search_value:value,filter:selectedFilter}))
        if(!value){
            setShowClose(false)
        }
        else{
            setShowClose(true)
        }
    }
    const HandleChange=(value)=>{
        setSearchValue(value)
        if(!value){
            localStorage.removeItem("one_on_one_filter")
            setShowClose(false)
        }
        clearTimeout(timeout_ref.current)

        timeout_ref.current = setTimeout(()=>{
            filterValueChange(value)
        },600)
    }
    const onClearFilter = ()=>{
        localStorage.removeItem("one_on_one_filter")
        setShowClose(false)
        setFilter('Title')
        filterValueChange('')
        setSearchValue('')
    }
    const FilterType =(
        <div>
            {(selectedFilter==='Title' || selectedFilter==='Action item'||selectedFilter==='Agenda'||selectedFilter==='Owner'||selectedFilter==='Member' ) && (
                <div style={{position:"relative"}}>
                    <Input
                    className='search-input-filter search-input-bars'
                    style={{border: "1px solid #D9D9D9",paddingRight:"35px"}}
                    value={search_value}
                    onChange={e => (HandleChange(e.target.value))}
                    autoComplete='off'
                    />
                    <img src={search_icon} alt="" style={{position:'absolute',left:"10px",width:"20px",top:"13px"}} />
                    {show_close&&<CloseCircleFilled style={{position:'absolute',right:"10px",fontSize:"20px",top:"13px",cursor:"pointer",color:"#D3D3D3"}}
                     onClick={()=>onClearFilter()}/>}
                </div>  
            )}
        </div>
    )

    const Filtermenu =()=> (
        <Menu className='one-one-filter-menu' style={{maxHeight:'200px',overflow:'auto'}} suffixIcon={<img src={dropdown_Arrow} alt="" style={{width:"10px",height:"10px"}}/>}>
          {
            filterList && (
                filterList?.map((data)=>
                    <Menu.Item style={{color:selectedFilter === data?.type ? '#141414':'#BBBBBB'}} key={data?.key} onClick={()=>{setFilter(data?.key);}}>
                        {data?.type}
                    </Menu.Item>
                )
            )
          }
        </Menu>
      );

      
    return (
        <>
            <Row style={{flexWrap:"nowrap"}}>
                <div>
                {FilterType}
                </div>
                <Dropdown overlay={Filtermenu} overlayStyle={{zIndex:"1050"}} className="input-filter-one-on-one" >
                    <DropdownButton className='one-on-one-drop-dtn'>
                        <div className='one-on-one-selected-filter' style={{width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left'}}>{selectedFilter?selectedFilter:'Select'}</div>
                        <img src={dropdown_arrow} alt="" />
                    </DropdownButton>
                </Dropdown>
            </Row>
        </>
    )

}

export default  FilterOneOnOne;

const DropdownButton = styled(Button)`
width:100px;
display:flex;
height:36px;
border-left:none;
justify-content:space-between;
align-items:center;
gap:5px
`
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Spin, Empty } from 'antd';
import { compose } from '../../../core';

import SelectField from './SelectField';
import { withApollo } from '@apollo/client/react/hoc';

const EMPLOYEE_KEYRESULT_QUERY = gql`
 query keyresults($owner:ID!,$title_Icontains:String) {
    keyresults(owner:$owner, title_Icontains: $title_Icontains) {
        totalCount
        edges {
          node {
            id
            title
          }
        }
    }
}
`;

const KeyResultField = props => {
    const { loading, margin = [], owner, client } = props;
    const handleFilterRemove = React.useRef(() => { });

    const [keyresults, setKeyResults] = useState()
    const [chars, setChars] = useState("")

    

    React.useEffect(() => {
            const getKeyResult = async () => {
                const { data } = await client.query({
                    query: EMPLOYEE_KEYRESULT_QUERY,
                    variables: { owner: owner, title_Icontains: chars },
                });
                setKeyResults(data?.keyresults?.edges?.map(({ node }) => ({
                    label: node.title,
                    value: node.id
                })));
            }
        if (owner !== undefined) {
            getKeyResult()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chars, props.owner])

    const onSelectFeildSearch = (s) => {
        setChars(s)
    }
       

    return (
        <SelectField
            margin={margin}
            showSearch
            onSearch={(e) => { onSelectFeildSearch(e) }}
            choices={keyresults}
            style={{ width: !margin && 300, marginBottom: !margin && 0 }}
            label={"Select Key result"}
            // labelInValue
            filterOption={false}
            onBlur={handleFilterRemove.current}
            notFoundContent={
                loading ? (
                    <div align="center">
                        <Spin size="small" />
                    </div>
                ) : (
                    <Empty />
                )
            }
            {...props}
        />
    );
};

export default compose(withApollo) (KeyResultField);

import React, { useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CKEditor from 'ckeditor4-react';
import { Spin, Form} from 'antd';
import { withHTMLFieldValue } from '../functions';
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_PRESET } from '../../../../config';

const editorVersion = '4.15.1';
const editorUrl = `https://cdn.ckeditor.com/${editorVersion}/full-all/`;

CKEditor.editorUrl = `${editorUrl}ckeditor.js`;

const FormItem = styled(Form.Item)`
.ant-col-offset-1{
  margin-left:0px !important
}
`;

const CKEditorFieldOneOnOne = props => {
  const [loaded, setLoaded] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [disable, setDisable] = useState(false)
  

  const { label,  name, rules, initialValue, changeHandler, style, offset = 1, hideToolBar, height, removeMargin, width, editorPlaceholder,links } = props;

  const cloudName = `${CLOUDINARY_CLOUDNAME}/image`;
  const unsignedUploadPreset = `${CLOUDINARY_PRESET}`;

  if (typeof window !== 'undefined') {
    // To load the script -> <script src="https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image"></script>
    const scr = document.createElement('script');
    const head = document.head || document.getElementsByTagName('head')[0];
    scr.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
    scr.async = false;
    head.insertBefore(scr, head.firstChild);
  }
  return (
    <div style={style}>
      <FormItem
        style={{ margin: removeMargin ? '0px' : '', paddingLeft: "0px", paddingRight: "0px",marginTop:"4.5px" }}
        name={name}
        rules={rules}
        label={label} /* {...labels} */
        /* validateStatus={validateStatus} */
        // initialValue={initialValue}
        /* help={touched && error} */
        labelCol={{ span: 24 }}
        // disabled={disabled}
        wrapperCol={{ span: 24, offset }}
      >
        {/* <Column span={24}  > */}
        <CKEditor
          className="one-on-one-custom-ck"
          name={Array.isArray(name) ? name.join('.') : name}
          mode="wysiwyg"
          data={withHTMLFieldValue(initialValue)}
          onChange={(e) => changeHandler(e)}
          readOnly={disable}
          config={{
            extraPlugins: ['image2'],
            editorplaceholder: editorPlaceholder,
            font_names: 'Poppins;Arial;Times New Roman;Verdana;Comic Sans MS;Courier New;Georgia;Lucida Sans Unicode;Tahoma;Trebuchet Ms',
            contentsCss: 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap',
            removePlugins: ['forms', 'bidi', 'div', 'flash', 'iframe', 'about', 'source'],

            // Enable what you want.
            toolbar: [
              {
                name: 'basicstyles', groups: ['basicstyles', 'cleanup'],
                items:hideToolBar?[]:['Bold', 'Italic', 'Underline', 'NumberedList', 'BulletedList']
              },
              { name: 'links', items: links || [] }
            ],

            allowedContent: true,
            height: height ? height : 80,
            width: width ? width : null,
            language: 'en',
            // startupFocus: 'end',
            filebrowserImageUploadUrl: '/uploader/upload.php', // this is only for the upload tab to appear on dialog
            // Change request to upload to Cloudinary server
            on: {
              loaded: function () {
                setLoaded(true);
              },
              fileUploadRequest: function (evt) {
                // Prepare request to Cloudinary
                var url = 'https://api.cloudinary.com/v1_1/' + cloudName + '/upload';
                var xhr = evt.data.fileLoader.xhr;
                xhr.open('POST', url, true);
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

                // Fill all necessary fields for Cloudinary
                // eslint-disable-next-line no-undef
                var fd = new FormData();
                fd.append('upload_preset', unsignedUploadPreset);
                // fd.append('folder', 'content/articles'); // Optional - place image at specific folder admin in Cloudinary
                // fd.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
                fd.append('file', evt.data.fileLoader.file);
                // Send the request to Cloudinary server
                xhr.send(fd);

                // Prevented the default behavior.
                evt.stop();
              },
              // Change response to handle Cloudinary response
              fileUploadResponse: function (evt) {
                // Prevent the default response handler.
                evt.stop();

                // Get XHR and response.
                var data = evt.data,
                  xhr = data.fileLoader.xhr,
                  response = JSON.parse(xhr.responseText);

                // Transfer the response to Ckeditor format
                data.uploaded = 1;
                data.fileName = response.public_id + '.' + response.format;
                data.url = response.secure_url;
              }
            }
          }}
          // onSelectionChange={changeHandler}
          onBeforeLoad={CKEDITOR => {
            // CKEDITOR.plugins.addExternal('ckeditor_wiris', 'https://www.wiris.net/demo/plugins/ckeditor/', 'plugin.js');
            CKEDITOR.plugins.addExternal(
              'image2',
              `https://ckeditor.com/docs/vendors/${editorVersion}/ckeditor/plugins/image2/`,
              'plugin.js'
            );
          }}
        />
        {/* </Column> */}
      </FormItem>
      {!loaded && (
        <div style={{ height: '40vh', display: 'grid' }} align="center">
          <Spin style={{ margin: 'auto' }} />
        </div>
      )}
    </div>
  );
};

CKEditorFieldOneOnOne.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  inFilter: PropTypes.bool,
  meta: PropTypes.object,
  formik: PropTypes.object
};

export default CKEditorFieldOneOnOne;

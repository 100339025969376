import React from 'react';
import _ from 'lodash';
import { Col, Space, Tooltip, Modal } from 'antd';

import { getMarks, ReactMarkdown,RenderField } from '../../../look';
import { InfoCircleOutlined } from '@ant-design/icons';

const GenericUiFlowRenderSimpleReferenceField = ({
  type,
  inputType,
  label,
  helpText,
  id,
  value,
  question,
  simplefieldchoiceSet,
  responseDates,
  form,
  ...rest
}) => {
  const [helptext, setHelptext] = React.useState(null);
  return (
    <>
      {type === 'simplefield' ? (
        <Col span={24}>
          <RenderField
           margin="0vh"
          rules={question?.required?[{ required: true, message: 'This field is required!' }]:null}
            inputType={inputType}
            id={id}
            label={
              <Col>
              <Space align="start">
              
                <ReactMarkdown>{label}</ReactMarkdown>
                {helpText && (
                  <Tooltip title={'Help Text'}>
                    <InfoCircleOutlined onClick={() => setHelptext(helpText)} />
                  </Tooltip>
                )}
              
              </Space>
            
               
             
              </Col>
            }
            choices={simplefieldchoiceSet.edges
              .map(({ node }) => node)
              .map(c => ({
                label: (
                  <Space align="baseline">
                    {c?.label}
                    <ReactMarkdown>{c?.graphic}</ReactMarkdown>
                  </Space>
                ),
                value: c?.value
              }))}
            value={value}
            marks={getMarks([
              Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[0]),
              Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[1])
            ])}
            min={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[0])}
            max={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[1])}
            step={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[2])}
            defaultValue={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[3])}
            onChange={e => form.setFieldsValue(_.set(form.getFieldsValue(true), [id], e))}
          />
          {value && value !== '' && Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[3]) !== '' && (
              <div align="end" style={{width:'100%',marginBottom:'15px',marginTop:'-20px'}}>
                <span style={{ color: '#999',fontSize:'13px' }}>{new Date(responseDates()[id])?.toDateString() || ''}</span>
              </div>
            )}
        </Col>
      ) : (
        <Col span={24}>
          <RenderField
          rules={question?.required?[{ required: true, message: 'This field is required!' }]:null}
            margin="0vh"
            inputType={question?.choiceSet?.edges[0]?.node?.inputType}
            id={id}
            label={
             <Col>
              <Space align="start">
            
            <ReactMarkdown>{question?.questionText}</ReactMarkdown>
            {helpText && (
              <Tooltip title={'Help Text'}>
                <InfoCircleOutlined onClick={() => setHelptext(helpText)} />
              </Tooltip>
            )}
           
          </Space>
           
        
          
             </Col>
            }
            value={value}
            choices={question?.choiceSet.edges
              .map(({ node }) => node)
              .map(c => ({
                label: (
                  <Space align="baseline">
                    <ReactMarkdown>{c?.label}</ReactMarkdown>
                    <ReactMarkdown>{c?.graphic}</ReactMarkdown>
                  </Space>
                ),
                value: c?.value
              }))}
            marks={getMarks([
              Number(question?.choiceSet?.edges[0]?.node?.value?.split(':')[0]),
              Number(question?.choiceSet?.edges[0]?.node?.value?.split(':')[1])
            ])}
            min={Number(question?.choiceSet?.edges[0]?.node?.value?.split(':')[0])}
            max={Number(question?.choiceSet?.edges[0]?.node?.value?.split(':')[1])}
            step={Number(question?.choiceSet?.edges[0]?.node?.value?.split(':')[2]) || 1}
            defaultValue={Number(question?.choiceSet?.edges[0]?.node?.value?.split(':')[3])}
            onChange={e => form.setFieldsValue(_.set(form.getFieldsValue(true), [id], e))}
          />
             {value && value !== '' && Number(question?.choiceSet?.edges[0]?.node?.value?.split(':')[3]) !== '' && (
            <div align="end" style={{width:'100%',marginBottom:'15px',marginTop:'-20px'}}>
            <span style={{ color: '#999',fontSize:'13px' }}>{new Date(responseDates()[id])?.toDateString() || ''}</span>
            </div>
          )}
        </Col>
      )}
      <Modal centered visible={helptext} footer={null} onCancel={() => setHelptext(null)}>
        <ReactMarkdown>{helptext}</ReactMarkdown>
      </Modal>
    </>
  );
};

export default GenericUiFlowRenderSimpleReferenceField;

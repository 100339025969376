import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import BarsHomeView from "../Components/BarsHomeView";
import { getIntFromString, globalPermissionValidator } from "modules/look";
import { bars_permission } from "Permissions/bars.permission";
import { ALL_BEHAVIOUR_LIST_SUBSCRIPTION, ALL_EMPLOYEE_LEVELS, ALL_RATING_SCALES, BARS_LIST } from "../graphql/BarsQueries.gql";
import { withDeleteBar } from "./BarsOperations";
import { Row, Spin } from "antd";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";


const BarsHomeContainer = (props) => {

  const { client, me, userPermission, permissionLoader } = props;

  const [barsLoading, setBarsLoading] = React.useState(undefined);
  const [bars, setBars] = React.useState(undefined);
  const [barsPermission, setBarsPermission] = React.useState(undefined);
  const [allRatingScales, setAllRatingScales] = React.useState(undefined);
  const [allEmployeeLevels, setAllEmployeeLevels] = React.useState(undefined);


  const bars_list_ref = React.useRef([])
  const bars_subscription = React.useRef(null)
  let barsSub = undefined

  React.useEffect(() => {
    return () => {
      if (barsSub) {
        barsSub.unsubscribe();
      }
    }
  })

  React.useEffect(() => {

    // Checking for permission of user
    if (userPermission?.length) {
      let permission = globalPermissionValidator(bars_permission, userPermission);

      setBarsPermission(permission)
    }

  }, [userPermission])


  React.useEffect(() => {

    // based on permission of user bars will be di
    if (barsPermission?.required_permission) {
      getAllRatingScales();
      getAllEmployeeLevels()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barsPermission])

  React.useEffect(() => {

    if (allEmployeeLevels && allRatingScales) {
      getBarsList();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEmployeeLevels, allRatingScales])


  React.useEffect(() => {
    bars_list_ref.current = bars?.uniqueBars
  }, [bars])


  const getCountOfLevel = (data) => {

    const count = {};

    data.forEach(element => {
      if (count[element?.employeeLevel?.id]) {
        count[element?.employeeLevel?.id] += 1;
      } else {
        count[element?.employeeLevel?.id] = 1;
      }
    });



    return count

  }




  const getLevelsWithstatus = (data) => {

    let countOfLevels = getCountOfLevel(data);


    let levelsWithStatus = allEmployeeLevels?.map((level) => {

      if (countOfLevels[level?.id] === allRatingScales?.length) {
        return { ...level, status: 'completed', selectedLevel: false }
      } else {
        return { ...level, status: 'Pending', selectedLevel: false }
      }
    }

    )


    return levelsWithStatus;

  }


  const getStatusOfBar = (data) => {

    if (data?.length === (allEmployeeLevels?.length * allRatingScales?.length)) {
      return 'completed'
    } else {
      return 'Pending'
    }

  }


  const getBarsRecordGenerated = (data, filter) => {

    let mainCompetencies = data?.edges?.map(({ node }) => node?.mainCompetency)?.filter((value) => value !== null);

    const key = 'id';
    const uniqueMainCompetncies = [...new Map(mainCompetencies.map(item => [item[key], item])).values()];
    let allBars = uniqueMainCompetncies?.map((node) =>

      // eslint-disable-next-line array-callback-return
      data?.edges?.map((record) => {
        if (record?.node?.mainCompetency?.id === node?.id) {
          return {
            id: node?.id,
            title: node?.title,
            barsSet: record?.node?.mainCompetency?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => { return { ...node, intId: getIntFromString(node?.id) } }),
            competencySet: record?.node?.mainCompetency?.competencySet?.edges?.map(({ node }) => node),
            status: getStatusOfBar(record?.node?.mainCompetency?.behaviourallyanchoredratingSet?.edges),
            employeeLevel: getLevelsWithstatus(record?.node?.mainCompetency?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node)),
            selectedLevel: getLevelsWithstatus(record?.node?.mainCompetency?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => node))[0]
          }
        }
      }
      )

    )?.flat()?.filter((value) => value !== undefined);



    if (bars?.uniqueBars?.length && !(filter?.title_Icontains || filter?.mainCompetency_Title_Icontains)) {

      const uniqueBarsNew = [...new Map(allBars.map(item => [item[key], item])).values()];
      let combinedBars = [...bars?.uniqueBars, ...uniqueBarsNew]
      const uniqueBars = [...new Map(combinedBars.map(item => [item[key], item])).values()];


      return {
        edgeCount: data?.edgeCount + bars?.edgeCount,
        totalCount: data?.totalCount, uniqueBars
      };
    } else {
      const uniqueBars = [...new Map(allBars.map(item => [item[key], item])).values()];

      return {
        edgeCount: data?.edgeCount,
        totalCount: data?.totalCount, uniqueBars
      };
    }


  }


  // Fetching all the bars
  const getBarsList = async (filter) => {
    setBarsLoading(true)
    const { data } = await client.query({
      query: BARS_LIST,
      fetchPolicy: 'network-only',
      variables: { ...filter }
    });
    if (data?.allBehaviourallyAnchoredRatings) {

      let dataForRecordGeneration;
      if (filter?.title_Icontains) {
        dataForRecordGeneration = data?.allBehaviourallyAnchoredRatings?.edges?.filter((value) =>
          value?.node?.mainCompetency?.competencySet?.edges?.length
        );
        dataForRecordGeneration = { edges: dataForRecordGeneration }
      } else {
        dataForRecordGeneration = data?.allBehaviourallyAnchoredRatings
      }


      let list = getBarsRecordGenerated(dataForRecordGeneration, filter)

      setBars(list)
      setBarsLoading(false)
      barsSubscription();
    }
  }

  // Fetching all the bars
  const getAllRatingScales = async () => {

    const { data } = await client.query({
      query: ALL_RATING_SCALES,
      fetchPolicy: 'network-only'
    });
    if (data?.ratingScales) {
      let scales = data?.ratingScales?.edges?.map(({ node }) => node)
      setAllRatingScales(scales)
    }

  }

  // Fetching all the bars
  const getAllEmployeeLevels = async () => {
    setBarsLoading(true)
    const { data } = await client.query({
      query: ALL_EMPLOYEE_LEVELS,
      variables: { organization: me?.employee?.organizationSet?.edges[0]?.node?.id },
      fetchPolicy: 'network-only'
    });
    if (data?.allEmployeeLevels) {
      let allLevels = data?.allEmployeeLevels?.edges?.map(({ node }) => node);
      setAllEmployeeLevels(allLevels)
    }
  }


  const barsSubscription = async () => {
    barsSub = bars_subscription.current = client.subscribe({
      query: ALL_BEHAVIOUR_LIST_SUBSCRIPTION,
      fetchPolicy: 'no-cache'
    }).subscribe({
      next(result) {
        let bars_cache_data = [].concat(bars_list_ref.current)
        let subscription_data = result.data.behaviourallyAnchoredRatingSubscription
        switch (subscription_data.mutation) {
          case 'CREATE':
            bars_cache_data.push(subscription_data?.behaviourallyAnchoredRating)
            let newRecords = bars_cache_data?.filter((rec) => rec?.index !== bars_cache_data[bars_cache_data?.length - 1].sequence)
            setBars(newRecords)

            break
          case 'UPDATE':
            let doc = bars_cache_data?.find(item => item?.id === subscription_data?.behaviourallyAnchoredRating?.id)
            if (doc) {
              Object.entries(subscription_data?.behaviourallyAnchoredRating).forEach(item => {
                doc[item[0]] = item[1]
              })
              setBars(bars_cache_data)
            }
            break
          case 'DELETE':

            if (subscription_data?.behaviourallyAnchoredRating?.mainCompetency && bars_cache_data?.length) {
              bars_cache_data = bars_cache_data?.filter(item => item?.id !== subscription_data?.behaviourallyAnchoredRating?.mainCompetency?.id)?.filter((value) => value !== undefined)
              setBars({ uniqueBars: bars_cache_data })
            }

            else if (!subscription_data?.behaviourallyAnchoredRating?.mainCompetency && bars_cache_data?.length) {

              let subCompetencyToDelete = {};

              bars_cache_data.forEach((data, mainCompetencyIndex) =>
                data?.competencySet.forEach((competency, index) =>
                  competency?.behaviourallyanchoredratingSet?.edges.forEach((bar) =>
                    bar?.node?.id === subscription_data?.behaviourallyAnchoredRating?.id ?
                      subCompetencyToDelete = { mainCompetencyIndex: mainCompetencyIndex, subCompetencyIndex: index }
                      :
                      null
                  )
                )
              )

              if (subCompetencyToDelete?.subCompetencyIndex >= 0) {

                let temp = [];

                if (bars_cache_data[subCompetencyToDelete?.mainCompetencyIndex]?.competencySet?.length) {
                  temp = [...bars_cache_data[subCompetencyToDelete?.mainCompetencyIndex]?.competencySet]
                }

                temp = temp.filter((element, index) =>
                  index !== subCompetencyToDelete?.subCompetencyIndex)


                if (bars_cache_data[subCompetencyToDelete?.mainCompetencyIndex]?.competencySet?.length) {
                  bars_cache_data[subCompetencyToDelete?.mainCompetencyIndex].competencySet = temp
                }
                subCompetencyToDelete = {};
                setBars({ uniqueBars: bars_cache_data })
              }


            }

            break
          default:
            break
        }
      }
    });
  }

  


  return (
    <>

      <Row justify='center' align='middle' >
        {
          <Spin spinning={permissionLoader} style={{ width: '100%' }} wrapperClassName='permission-check-bars'>
            <div>
              {
                barsPermission !== undefined &&
                <div>
                  {
                    barsPermission?.required_permission ?
                      <BarsHomeView
                        bars={bars}
                        barsLoading={barsLoading}
                        setBarsLoading={setBarsLoading}
                        getBarsList={getBarsList}
                        setBars={setBars}
                        allEmployeeLevels={allEmployeeLevels}
                        allRatingScales={allRatingScales}
                        {...props}
                      />
                      :
                      <NoPermissionView />
                  }

                </div>
              }
            </div>
          </Spin>
        }
      </Row>



    </>
  );
};

export default compose(withApollo, withDeleteBar)(BarsHomeContainer);

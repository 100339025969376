import React from 'react';
// import _ from 'lodash';
import { Button, Modal, Row, Col, Form } from 'antd';
// import { ArrowRightOutlined } from '@ant-design/icons';
import { CKEditorField, TextField } from '../../look';

export const CKEditorFieldName = ['coachingCommitmentData', 'description'];

const ManagerAddCommitmentView = props => {
  const {  onSubmit, modal2, setModal2 } = props;

  // const [isNew, setIsNew] = useState(false);
  const [form] = Form.useForm();

  // const choices =
  //   getMyCustomizedCoachingCommitments &&
  //   getMyCustomizedCoachingCommitments.edges.length > 0 &&
  //   getMyCustomizedCoachingCommitments.edges.map(({ node }) => ({
  //     label: node.title || '',
  //     value: Number(getIntFromString(node.id))
  //   }));
  return (
    <>
      <Modal
        footer={null}
        centered
        visible={modal2.visible}
        width={'50%'}
        destroyOnClose={true}
        onCancel={() => {
          setModal2({ visible: false, id: 0, default: false });
        }}
      >
        <h3>Add custom coaching commitment</h3>
        <Form form={form} layout="vertical" onFinish={values => onSubmit(values)} required={false} scrollToFirstError={true}>
          {/* <Row gutter={24} justify="space-between">
            <Col span={16}>
              <SelectField
                choices={choices}
                name={['coachingCommitmentData', 'customusercommitmentId']}
                label="Select from your custom coaching commitments"
                disabled={isNew}
                onChangeSelect={id => {
                  const coachingCommitment =
                    getMyCustomizedCoachingCommitments.edges.length > 0 &&
                    getMyCustomizedCoachingCommitments.edges.filter(({ node }) => getIntFromString(node.id) === id)[0];
                  form.setFieldsValue(
                    _.set(
                      form.getFieldsValue(true),
                      ['coachingCommitmentData', 'title'],
                      coachingCommitment.node.title || ''
                    )
                  );
                  form.setFieldsValue(
                    _.set(
                      form.getFieldsValue(true),
                      ['coachingCommitmentData', 'commitmentId'],
                      getIntFromString(coachingCommitment.node.commitment.id) || ''
                    )
                  );
                }}
              />
              <Col span={0}>
                <TextField
                  name={['coachingCommitmentData', 'commitmentId']}
                  label="Title for your commitment"
                  disabled={!isNew}
                />
              </Col>
            </Col>
            <Col span={4} align="right" style={{ alignItems: 'center' }}>
              {'Add New '}
              <Switch
                checked={isNew}
                onChange={v => {
                  setIsNew(v);
                  form.resetFields();
                }}
              />
            </Col>
          </Row>
          <Row>
            <Divider style={{ width: '100%', alignItems: 'flex-start' }}>OR</Divider>
          </Row> */}
          <Row>
            <Col span={24}>
              <Row>
                <Col lg={16} md={16} xs={24}>
                  <TextField
                    name={['coachingCommitmentData', 'title']}
                    label="Title for your commitment"
                    // disabled={!isNew}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <CKEditorField
                name={CKEditorFieldName}
                label="Description for your commitment"
                // initialValue={SurveyById && SurveyById.termsConditions}
                offset={0}
                // disabled={!isNew}
                // rules={[{ required: true, message: 'All questions are required!' }]}
              />
            </Col>
            <Col span={24}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ManagerAddCommitmentView;

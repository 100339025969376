import React from 'react';
import gql from 'graphql-tag';
import { Spin } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

import * as ROUTE from '../../../organization/route';

import SelectField from './SelectField';
import { Capitalize, getIntFromString, getVerticalBase64StringFromInt } from '../functions';

export const VERTICAL_BY_VERTICAL_Id_QUERY = gql`
  query vertical($id: ID!) {
    vertical(id: $id) {
      id
      name
      description
      employees{
        edges {
          cursor
          node {
            id
            user {
              id
              firstName
              lastName
              username
            }
          }
        }
      }
    }
  }
`;

const TeamManagerField = props => {
  const { vertical, loading} = props;
  // eslint-disable-next-line no-unused-vars
  const [ deleteLoading, setDeleteLoading ] = React.useState( false );

  // const handleDelete = async id => {
  //   setDeleteLoading( true );
  //   try {
  //     const response = await deleteEmployee( {id:id} );
  //     // response ? message.success( 'Action Successfull!' ) : message.error( 'Cannot delete this employee!' );
  //   } catch ( e ) {
  //     message.error( 'Action Failed!' );
  //     throw new Error( e.message );
  //   }
  //   setDeleteLoading( false );
  // };
  const choices =
    vertical &&
    vertical.employees &&
    vertical.employees.edges.length > 0 &&
    vertical.employees.edges.map( ( { node } ) => ( {
      label: node.user.firstName && node.user.lastName ? `${Capitalize(node.user.firstName)} ${Capitalize(node.user.lastName)}` : node.user.username,
      value: getIntFromString( node.id )
    } ) );
  return (
    <Spin spinning={loading || deleteLoading} size="small">
      {vertical && (
        <SelectField
          onAdd={props.onAdd ? props.onAdd : ROUTE.addEmployee}
          onEdit={ROUTE.editEmployeeLink}
          // onDelete={handleDelete}
          {...props}
          choices={choices}
        />
      )}
    </Spin>
  );
};

export default graphql( VERTICAL_BY_VERTICAL_Id_QUERY, {
  options: props => {
    return {
      variables: {
        id: getVerticalBase64StringFromInt(props.verId)
      }
    };
  },
  props( { data: { loading, error, vertical, subscribeToMore, updateQuery } } ) {
    return { loading, error, vertical, subscribeToMore, updateQuery };
  }
} )( TeamManagerField );

import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { Spin, Col, Row, Button} from 'antd';
import { Tree, TreeNode } from 'react-organizational-chart';
import { IMG } from '../../../config';
import { getIntFromString, getOrganizationBase64StringFromInt, getVerticalBase64StringFromInt } from '../../look';
import { ORGANIZATION_BY_ID_QUERY_ASSIGN } from '../../organization/graphql/OrganizationByIdQuery.gql';
import { TEAMS_BY_VERTICAL_ID_QUERY } from '../../organization/graphql/TeamsByVerticalIdQuery.gql';

import { compose } from '../../core';

const OrganizationTree = props => {
  const {
    loading,
    organizationById,
    allVerticals,
    allTeams,
    editTool,
    toolId,
    toolName,
    allOrganizations
  } = props;
  const [team, setTeam] = React.useState(false);
  const [organizations, setOrganizations] = React.useState(allOrganizations);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleAddOrganization = async verticalId => {
    setSubmitLoading(true);
    let organization = organizations;
    if (organization.indexOf(verticalId) !== -1) {
      organization = organization.filter(id => id !== verticalId);
      setOrganizations(organization);
    } else {
      organization.push(verticalId);
      setOrganizations(organization);
    }
    try {
      await editTool({ id: getIntFromString(toolId), name: toolName, organization });
      setSubmitLoading(false);
    } catch (e) {
      setSubmitLoading(false);
      throw new Error(e.message);
    }
  };

  return (
    <Spin spinning={loading || submitLoading} size="medium">
      {organizationById && (
        <Tree
          label={
            <Row>
              <Col span={24}>
                <img
                  src={organizationById?.logo || IMG}
                  alt=""
                  style={{
                    width: 70,
                    height: 70,
                    borderRadius: 50,
                    marginBottom: 10,
                    backgroundColor: '#f3f3f3',
                    border:
                      organizations.indexOf(getIntFromString(organizationById.id)) !== -1 ? '1px solid #B0CB1F' : '0px',
                    boxShadow:
                      organizations.indexOf(getIntFromString(organizationById.id)) !== -1
                        ? '0px 0px 2px 2px rgba(0, 0, 0, 0.1)'
                        : '0px'
                  }}
                />
              </Col>
              <Col span={24}>
                <Button
                  type={organizations.indexOf(getIntFromString(organizationById.id)) !== -1 ? 'primary' : 'link'}
                  style={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)', margin: 5 }}
                  onClick={() => handleAddOrganization(getIntFromString(organizationById.id))}
                >
                  {organizationById && organizationById.name}
                </Button>
              </Col>
            </Row>
          }
          lineColor={'#CACACA'}
          lineBorderRadius={'5px'}
        >
          {organizationById?.verticalSet &&
            organizationById?.verticalSet.edges.map(({ node }, idx) => (
              <SubTreeNode
                node={node}
                team={team}
                setSubmitLoading={setSubmitLoading}
                allTeams={allTeams}
                toolName={toolName}
                toolId={toolId}
                editTool={editTool}
                setTeam={setTeam}
                allVerticals={allVerticals}
              />
            ))}
        </Tree>
      )}
    </Spin>
  );
};

const SubTreeNode = props => {
  const { allVerticals, node, team, setTeam, editTool, toolId, setSubmitLoading, toolName, allTeams } = props;
  const [verticals, setVerticals] = React.useState(allVerticals);
  const handleAddVertical = async verticalId => {
    setSubmitLoading(true);
    let vertical = verticals;
    if (vertical.indexOf(verticalId) !== -1) {
      vertical = vertical.filter(id => id !== verticalId);
      setVerticals(vertical);
    } else {
      vertical.push(verticalId);
      setVerticals(vertical);
    }
    try {
      await editTool({ id: getIntFromString(toolId), name: toolName, vertical });
      setSubmitLoading(false);
    } catch (e) {
      setSubmitLoading(false);
      throw new Error(e.message);
    }
  };

  return (
    <TreeNode
      label={
        <Button
          type={verticals.indexOf(getIntFromString(node.id)) !== -1 ? 'primary' : 'link'}
          style={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)', margin: 5 }}
          onClick={() => handleAddVertical(getIntFromString(node.id))}
        >
          {node.name}
        </Button>
      }
    >
      {node.subvertical.totalCount > 0 || team ? (
        <>
          {node?.subvertical?.totalCount > 0
            ? node?.subvertical?.edges?.map(({ node: sv }) => (
                <TreeNode
                  label={
                    <Button
                      type={verticals.indexOf(getIntFromString(sv.id)) !== -1 ? 'primary' : 'link'}
                      style={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)', margin: 5 }}
                      onClick={() => handleAddVertical(getIntFromString(sv.id))}
                    >
                      {sv.name}
                    </Button>
                  }
                >
                  {null}
                </TreeNode>
              ))
            : null}
          {team ? null : (
            <TeamNode
              setTeam={setTeam}
              verticalId={getIntFromString(node.id)}
              allTeams={allTeams}
              setSubmitLoading={setSubmitLoading}
              toolName={toolName}
              toolId={toolId}
              editTool={editTool}
            />
          )}
        </>
      ) : null}
    </TreeNode>
  );
};

export default compose(
  graphql(ORGANIZATION_BY_ID_QUERY_ASSIGN, {
    options: props => {
      let id = '';
      if (props.orgId) {
        id = getOrganizationBase64StringFromInt(props.orgId);
      }
      return {
        variables: { id:id }
      };
    },
    props({ data: { loading, error, organizationById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loadingVerifyToken: loading, error, organizationById, subscribeToMore, updateQuery };
    }
  })
)(OrganizationTree);

const VerticalTeamNode = props => {
  const { loading, Teams, setTeam, allTeams, editTool, setSubmitLoading, toolId, toolName } = props;
  const [teams, setTeams] = React.useState(allTeams);

  const handleAddTeam = async teamId => {
    setSubmitLoading(true);
    let team = teams;
    if (team.indexOf(teamId) !== -1) {
      team = team.filter(id => id !== teamId);
      setTeams(team);
    } else {
      team.push(teamId);
      setTeams(team);
    }
    try {
      await editTool({ id: getIntFromString(toolId), name: toolName, team });
      setSubmitLoading(false);
    } catch (e) {
      setSubmitLoading(false);
      throw new Error(e.message);
    }
  };

  if (Teams?.edges?.length > 0) {
    setTeam(false);
    return Teams.edges.map(({ node }) => (
      <TreeNode
        label={
          // <Link to={`${ROUTE.verticalDetailLink}${getIntFromString(node.subvertical.id)}`}>
          <Button
            type={teams?.indexOf(getIntFromString(node.id)) !== -1 ? 'primary' : 'link'}
            style={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)', margin: 5 }}
            onClick={() => handleAddTeam(getIntFromString(node.id))}
          >
            {node.name}
          </Button>
          // </Link>
        }
      >
        {null}
      </TreeNode>
    ));
  } else {
    // !loading && setTeam(true);
    return loading ? <Spin /> : null;
  }
};

export const TeamNode = compose(
  graphql(TEAMS_BY_VERTICAL_ID_QUERY, {
    options: props => {
      let id = '';
      if (props.verticalId) {
        id = getVerticalBase64StringFromInt(props.verticalId);
      }
      return { variables: { verticalId: id } };
    },
    props({ data }) {
      const { loading, error, Teams, subscribeToMore, updateQuery } = data;
      return { loading, error, Teams, subscribeToMore, updateQuery };
    }
  })
)(VerticalTeamNode);

import React from "react";
import IssueNewBadgeView from "../component/issueNewBadgeView";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { ALL_BADGES_LIST } from "../graphql/allBadgesList.gql";
import { withCreateBadgeAward } from "./badgeAwardOperationFeedback";
import { ALL_EMPLOYEE_LIST } from "../graphql/allEmployeesList.gql";
import { ALL_FEEDBACK_LIST, ALL_MEETING_LIST, ALL_OKR_LIST } from "../graphql/allModuleListQuery.gql";
import { globalPermissionValidator } from "modules/look";
import { BADGE_FAMILY_DETAIL, BADGE_FAMILY_FILTER_LIST } from "../graphql/badgeFamilyFilterList.gql";
import { kudos_permission } from "Permissions/kudos.permission";
import NoPermissionView from "../component/NoPermissionComponent";
import { Spin } from "antd";
import IssueNewBadgePopup from "../component/IssueNewBadgePopup";
const IssueNewBadgeContainer = ( props ) => {
    const { client, userPermission, me, show_popup } = props
    const [ loading, setLoading ] = React.useState( false )
    const [ badgeList, setBadgeList ] = React.useState( [] )
    const [ employeeList, setEmployeeList ] = React.useState( [] )
    const [ employeeLoading, setEmployeeLoading ] = React.useState( false )
    const [ moduleList, setModuleList ] = React.useState()
    const [ okrList, setOkrList ] = React.useState()
    const [ permission, setpermission ] = React.useState( null )
    const [ badge_family_list, setBadgeFamilyList ] = React.useState()
    const [ badge_Family_by_id, setBadgeFamilyById ] = React.useState()
    React.useEffect( () => {
        if ( userPermission?.length ) {
            let permission = globalPermissionValidator( kudos_permission, userPermission )
            if ( permission?.can_award_badge ) {
                getBadgeFamilyList()
                BadgeFamilyList()
            }
            setpermission( permission )
            
        }
        else {
            setpermission( null )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ userPermission ] )

    const BadgeFamilyList = async ( filterData ) => {
        const { data } = await client.query( {
            query: BADGE_FAMILY_FILTER_LIST,
            variables: { ...filterData },
            fetchPolicy: "network-only"
        } );
        if ( data ) {
            setBadgeFamilyList( data?.BadgeFamily?.edges )
        }
    }

    const BadgeFamilyDetail = async ( filterData ) => {
        const { data } = await client.query( {
            query: BADGE_FAMILY_DETAIL,
            variables: { ...filterData },
            fetchPolicy: "network-only"
        } );
        if ( data ) {
            setBadgeFamilyById( data?.badgeFamilyById )
        }
    }

    const getBadgeFamilyList = async ( filterValue ) => {
        setLoading( true )
        const { data } = await client.query( {
            query: ALL_BADGES_LIST,
            variables: { ...filterValue },
            fetchPolicy: "network-only"
        } );
        if ( data ) {
            let values = data?.badgeByTitle?.edges.concat( data?.badgeByDescription?.edges )
            var setObj = new Set();
            var result = values.reduce( ( acc, item ) => {
                if ( !setObj.has( item?.node?.id ) ) {
                    setObj.add( item?.node?.id )
                    acc.push( item )
                }
                return acc;
            }, [] )
            
            setLoading( false )
            setBadgeList( result )
        }
    }

    const AllEmployeeList = async ( filterData ) => {
        const { data } = await client.query( {
            query: ALL_EMPLOYEE_LIST,
            variables: { ...filterData },
            fetchPolicy: "network-only"
        } );
        if ( data ) {
            let mergeData = data?.firstName?.employee?.organizationSet?.edges?.map( ( { node } ) => node?.employees?.edges ).concat( data?.lastName?.employee?.organizationSet?.edges?.map( ( { node } ) => node?.employees?.edges ) )
            var setObj = new Set();
            var result = mergeData.reduce( ( acc, item ) => {
                if ( !setObj.has( item?.node?.id ) ) {
                    setObj.add( item?.node?.id )
                    acc.push( item )
                }
                return acc;
            }, [] )
            setEmployeeList( result[ 0 ] )
        }
    }

    const AllOkrs = async ( filterData ) => {
        setEmployeeLoading( true )
        const { data } = await client.query( {
            query: ALL_OKR_LIST,
            variables: { ...filterData },
            fetchPolicy: "network-only"
        } );
        if ( data ) {
            let dataList = data?.Owner?.edges?.map( ( { node } ) => node?.okr ).concat( data?.Assigned?.edges?.map( ( { node } ) => node?.okr ) )
            let mergeData = dataList.concat( data?.Collaborator?.edges?.map( ( { node } ) => node?.okr ) )
            var setObj = new Set();
            var result = mergeData.reduce( ( acc, item ) => {
                if ( !setObj.has( item?.id ) ) {
                    setObj.add( item?.id )
                    acc.push( item )
                }
                return acc;
            }, [] )
            setOkrList( result )
            setEmployeeLoading( false )
        }
    }

    const AllMeetings = async ( filterData ) => {
        setEmployeeLoading( true )
        const { data } = await client.query( {
            query: ALL_MEETING_LIST,
            variables: { ...filterData },
            fetchPolicy: "network-only"
        } );
        if ( data ) {
            let mergeData = data?.allMeetingsForOwner?.edges?.map( ( { node } ) => node ).concat( data?.allMeetingsForMember?.edges?.map( ( { node } ) => node ) )
            var setObj = new Set();
            var result = mergeData.reduce( ( acc, item ) => {
                if ( !setObj.has( item?.id ) ) {
                    setObj.add( item?.id )
                    acc.push( item )
                }
                return acc;
            }, [] )
            setModuleList( result )
            setEmployeeLoading( false )
        }
    }

    const AllFeedbacks = async ( filterData ) => {
        setEmployeeLoading( true )
        const { data } = await client.query( {
            query: ALL_FEEDBACK_LIST,
            variables: { ...filterData },
            fetchPolicy: "network-only"
        } );
        if ( data ) {
            let mergeData = data?.FeedbackToFirstName?.edges?.map( ( { node } ) => node ).concat( data?.FeedbackFromFirstName?.edges?.map( ( { node } ) => node ) )

            var setObj = new Set();
            var result = mergeData.reduce( ( acc, item ) => {
                if ( !setObj.has( item?.id ) ) {
                    setObj.add( item?.id )
                    acc.push( item )
                }
                return acc;
            }, [] )
            let filter_list = result.filter( item => item?.feedbackType === "MOTIVATIONAL_RECOGNITION" )
            setModuleList( filter_list )
            setEmployeeLoading( false )
        }
    }
    React.useEffect( () => {
        if ( me ) {
            AllEmployeeList()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ me ] )
    return (
        <>
            {
                permission && ( permission?.can_award_badge && ( permission?.one_on_one_permission || permission?.okr_permission || permission?.feedback_permission ) ) && (
                    !show_popup ? <IssueNewBadgeView {...props}
                        onSearch={( e ) => getBadgeFamilyList( e )}
                        badgeList={badgeList}
                        loading={loading}
                        employeeList={employeeList}
                        employeeSearch={( e ) => AllEmployeeList( e )}
                        employeeLoading={employeeLoading}
                        getOkrList={( e ) => AllOkrs( e )}
                        getMeetingList={( e ) => AllMeetings( e )}
                        getFeedbackList={( e ) => AllFeedbacks( e )}
                        selectedModuleList={moduleList}
                        permission={permission}
                        okrList={okrList}
                        badge_family_list={badge_family_list}
                        onFilter={( e ) => getBadgeFamilyList( e )}
                        getbadgeFailyList={( e ) => BadgeFamilyList( e )}
                        getBageFamilyById={( e ) => BadgeFamilyDetail( e )}
                        badge_Family_by_id={badge_Family_by_id} /> :
                        <IssueNewBadgePopup {...props}
                            onSearch={( e ) => getBadgeFamilyList( e )}
                            badgeList={badgeList}
                            loading={loading}
                            employeeList={employeeList}
                            employeeSearch={( e ) => AllEmployeeList( e )}
                            employeeLoading={employeeLoading}
                            getOkrList={( e ) => AllOkrs( e )}
                            getMeetingList={( e ) => AllMeetings( e )}
                            getFeedbackList={( e ) => AllFeedbacks( e )}
                            selectedModuleList={moduleList}
                            permission={permission}
                            okrList={okrList}
                            badge_family_list={badge_family_list}
                            onFilter={( e ) => getBadgeFamilyList( e )}
                            getbadgeFailyList={( e ) => BadgeFamilyList( e )}
                            getBageFamilyById={( e ) => BadgeFamilyDetail( e )}
                            badge_Family_by_id={badge_Family_by_id} />
                )
            }

            {
                permission && ( !permission?.can_award_badge || ( !permission?.one_on_one_permission && !permission?.okr_permission && !permission?.feedback_permission ) ) && (
                    <NoPermissionView />
                )
            }

            {
                !permission && (
                    <div style={{ width: "100%", padding: "200px 50px" }} align="center" >
                        <Spin spinning={true} size="large" />
                    </div>
                )
            }
        </>
    )
}

export default compose( withApollo, withCreateBadgeAward )( IssueNewBadgeContainer )
// @ts-nocheck
import React from 'react'
import { Col, Steps } from 'antd';
import DviversitySurveySelectionnewSkinComponent from './diversity-new-skin'
import {
  ReactMarkdown,
  getIntFromString,
} from '../../look';

import step_icon from '../../assets/stepper-complete-icon.svg'
import stepper_pending from '../../assets/stepper-pending.svg'
const DiversityGroupView = props => {
  const {
    questionList,
    form,
    srNo,
    timeoutFunc,
    closetimer,
    nextStep,
    currentSection,
    selectedChoiceList,
    questionData,
    survey,
    choiceList,
    selectedChoicevalues,
    form_values
  } = props;


  const [showOkButton, setShowOkButton] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [survayLading, setSurvayLoading] = React.useState(false)
  const [dependencyQuestion, setdependencyQuestion] = React.useState([])

  const [currentIdx, setCurrentIdx] = React.useState(0)
  const Step = Steps.Step;

  if (!questionList) {
    setSurvayLoading(true)
  }

  const customDot = (icon, { status }) => {
    switch (status) {
      case 'process':
        return <span className="custom-dot custom-dot-process"><img src={step_icon} alt="" style={{ width: "25px", marginLeft: "-5px" }} /></span>;
      case 'wait':
        return <span className="custom-dot custom-dot-finish"><img src={stepper_pending} alt="" style={{ width: "18px", marginLeft: "-2px" }} /></span>;
      default:
        return <span className={`custom-dot custom-dot-${status}`}><img src={stepper_pending} alt="" style={{ width: "18px", marginLeft: "-2px" }} /></span>;
    }
  };

  const jumbTonext = (index) => {
    if (index) {
      const current_player = document.getElementById(`${index}`)
      if (current_player) {
        current_player.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    }
  }
  function arraysEqual(a1, a2) {
    return JSON.stringify(a1) === JSON.stringify(a2);
  }
  React.useEffect(() => {
    if (choiceList.length) {
      let afterDuplicate = choiceList.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.choiseid === value.choiseid
        ))
      )
      setdependencyQuestion(afterDuplicate)
    }
  }, [choiceList])
  const checkConditions = (eqa) => {

    let ands = eqa.split("&&")
    let ors = eqa.split("||")
    let submit_section = questionData[currentSection - 1]?.node?.questionSet?.edges?.map(({ node }) => node)
    let values = form?.getFieldsValue(true)
    let values_question_id = Object.keys(values)
    let choices_q = submit_section?.filter(i => values_question_id?.includes(i?.id) && i?.choiceSet?.edges?.length) || []

    let depend_q_address = []
    choices_q.forEach(i => {
      let q = getIntFromString(i?.id)?.toString()
      let choice = i?.choiceSet?.edges?.map(({ node }) => node)
      let type = values[i?.id]
      if (type) {
        let t_of = typeof (type)
        choice.forEach(c => {
          if (t_of === 'object' && type?.includes(c?.value)) {
            let c_id = getIntFromString(c?.id)?.toString()
            depend_q_address.push(`${q}:${c_id}`)
          } else if (type === c?.value) {
            let c_id = getIntFromString(c?.id)?.toString()
            depend_q_address.push(`${q}:${c_id}`)
          }


        })
      }

    })
    if (arraysEqual(ands, ors)) {


      return depend_q_address?.includes(eqa)
      // no ands or or conditions
      // let x = eqa.split( ":" )
      // let f = dependencyQuestion.find( item => item?.questionId === Number( x[ 0 ] ) && item?.choiseid === Number( x[ 1 ] ) )
      // if ( f === undefined ) {
      //   return false
      // } else {
      //   return true
      // }

    }
    if (ands.length > 1) {
      // and condition is present 
      let valid = true
      ands.forEach(i => {
        // let x = i.split(":")
        // let f = dependencyQuestion.find(item => item?.questionId === Number(x[0]) && item?.choiseid === Number(x[1]))
        if (!depend_q_address?.includes(eqa)) {
          valid = false
        }
      })
      return valid
    }
    if (ors.length > 1) {
      // ors condition is present
      let valid = false
      ors.forEach(i => {
        let x = i.split(":")
        // eslint-disable-next-line no-unused-vars
        let f = dependencyQuestion.find(item => item?.questionId === Number(x[0]) && item?.choiseid === Number(x[1]))
        if (depend_q_address?.includes(eqa)) {
          valid = true
        }
      })
      return valid
    }
  }

  const RenderQuestion = (question, index) => {
    let qList = question?.node?.questionSet?.edges.map(({ node }) => node)
    // eslint-disable-next-line no-unused-vars
    let filterarray = qList.filter((item) => item?.questionAddress === "")
    const submitValue = values => {
      nextStep(srNo)
    };
    const handleQuestions = (element, idx,) => {
      let dependend = checkConditions(element.questionAddress, element)
      if (dependend && element?.questionAddress) {
        return (
          <DviversitySurveySelectionnewSkinComponent
            group={question}
            survey={survey}
            key={idx}
            srNo={idx + 1}
            form={form}
            currentIdx={currentIdx}
            sectionLabel={element?.node?.label}
            sectionSequence={element.node?.sequence}
            timeoutFunc={timeoutFunc}
            closetimer={closetimer}
            nextStep={(e) => nextStep(form.getFieldsValue(true), e)}
            questionList={[element]}
            showOkbutton={showOkButton}
            doNothing={e => { jumbTonext(e); setCurrentIdx(e) }}
            goNext={e => { setCurrentIdx(currentIdx + 1); jumbTonext(idx + 1); setShowOkButton(false) }}
            setShowOkbutton={e => e ? (setCurrentIdx(currentIdx + 1), jumbTonext(idx)) : setShowOkButton(true)}
            submitted={e => { submitValue(e); selectedChoiceList(dependencyQuestion) }
            }
            selectedvalues={(e, id, type) => { dependency(e, id, type) }}
            form_values={form_values}
          />
        )
      }
      else if (!element.questionAddress) {
        return (
          <DviversitySurveySelectionnewSkinComponent
            group={question}
            survey={survey}
            key={idx}
            srNo={idx + 1}
            form={form}
            currentIdx={currentIdx}
            sectionLabel={element?.node?.label}
            sectionSequence={element.node?.sequence}
            timeoutFunc={timeoutFunc}
            closetimer={closetimer}
            nextStep={(e) => nextStep(form.getFieldsValue(true), e)}
            questionList={[element]}
            showOkbutton={showOkButton}
            doNothing={e => { jumbTonext(e); setCurrentIdx(e) }}
            goNext={e => { setCurrentIdx(currentIdx + 1); setShowOkButton(false); jumbTonext(idx + 1) }}
            setShowOkbutton={e => e ? (setCurrentIdx(currentIdx + 1), jumbTonext(idx + 1)) : (setShowOkButton(true))}
            submitted={e => { submitValue(e); selectedChoiceList(dependencyQuestion) }
            }
            selectedvalues={(e, id, type) => { dependency(e, id, type) }}
            form_values={form_values}
          />
        )
      }
    }
    const dependency = (choiseid, sequence, questionId, type) => {
      let idSet = {
        choiseid: getIntFromString(choiseid),
        questionId: getIntFromString(questionId),
        sequence: sequence ? sequence : 0
      }
      let idArray = [].concat(dependencyQuestion)
      let selected = idArray.find(item => item.questionId === getIntFromString(questionId))
      let selectedCoiceid = idArray.find(item => item.choiseid === choiseid)
      if (selectedCoiceid !== undefined) {
        let filterarray = idArray.filter(item => item?.choiseid !== selectedCoiceid?.choiseid)
        idArray = filterarray;
        setdependencyQuestion(idArray)
      }
      else if (selected && type !== 'CHECKBOX') {
        selected.choiseid = getIntFromString(choiseid)
        setdependencyQuestion(idArray)
      }
      else {
        idArray.push(idSet)
        setdependencyQuestion(idArray)
      }
      setdependencyQuestion(idArray)
      selectedChoicevalues(idArray)
    }
    return (
      <>
        {currentSection === srNo ?
          <Col>
            {/* <h3 className='survey-group-title'><ReactMarkdown>{question?.node?.label}</ReactMarkdown></h3> */}
            <div className='generic-survey-skil-stepper'>
              <Steps direction="vertical" current={currentIdx} progressDot={customDot}>
                {
                  qList.map((element, idx) => (checkConditions(element.questionAddress, element) ? <Step key={idx} id={idx} title={idx === 0 ? <h3 className='survey-group-title'><ReactMarkdown>{question?.node?.label}</ReactMarkdown></h3> : ""} description={handleQuestions(element, idx)} /> : element?.questionAddress === "" ? <Step key={idx} id={idx} title={idx === 0 ? <h3 className='survey-group-title'><ReactMarkdown>{question?.node?.label}</ReactMarkdown></h3> : ""} description={handleQuestions(element, idx)} /> : null))}
                <Step />
              </Steps>
            </div>
          </Col > : null
        }
      </>
    )
  };
  return questionList?.map(RenderQuestion)
}

export default DiversityGroupView


import { Button, Select, message } from "antd"
import { getIntFromString } from "modules/look"
import React from "react"

const AddUserToOrgView = (props) => {
    const { org_confirmation, org_list, getOrgList, goBack, SetMemberData, getVerticalList, getTeamList, setVerticalList, setTeamList, vertical_list, team_list,add_to_org,addEmployee,setloading } = props
    const [selected_org, SetOrg] = React.useState()
    const [show_org_selection, SetShowOrgSelection] = React.useState(add_to_org)
    const [selected_vertical, SetVertical] = React.useState()
    const [selected_team, SetTeam] = React.useState()

    React.useEffect(() => {
        if (selected_org) {
            setVerticalList([])
            setTeamList([])
            getVerticalList({ organization: selected_org })
        } else {
            setVerticalList([])
            setTeamList([])
        }
        SetVertical(null)
        SetTeam(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected_org])

    React.useEffect(() => {
        if (selected_vertical) {
            setTeamList([])
            getTeamList({ vertical: selected_vertical })
        } else {
            setTeamList([])
        }
        SetTeam(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected_vertical])

    const addUserToOrg = async() => {
        if (!selected_org) {
            message.error('please select an organization')
        }
        let employee_id = org_confirmation?.employee?.id
        if(!employee_id){
            setloading(true)
            const response = await addEmployee({ employeeData: { userId: getIntFromString(org_confirmation?.id) } });
            if(response?.id){
                employee_id = response?.id
            }else{
                setloading(false)
                message.error("Failed to add user to organization")
            }
        }
        let list = [{
            type:"organizationId",
            memberFrom: selected_org,
            employeeId: employee_id,
            reportingLine: "dotted"
        }]

        if(selected_vertical){

            list.push({
                type:"verticalId",
                memberFrom: selected_vertical,
                employeeId: employee_id,
                reportingLine: "dotted"
            })
        }
        if(selected_team){
            list.push({
                type:"teamId",
                memberFrom: selected_team,
                employeeId: employee_id,
                reportingLine: "dotted"
            })
        }
        SetMemberData(list)
    }
    return (
        <>
            {
                !show_org_selection ?
                    <>
                        <h3 style={{ fontWeight: 'bold', textAlign: 'center' }}>User has been successfully created.</h3>
                        <h3 style={{ fontWeight: 'bold', textAlign: 'center' }}>Do you want to add this user to an organization?</h3>
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1em", width: "100%" }}>
                            <Button className='sa-create-btn-large' onClick={() => { SetShowOrgSelection(true) }} style={{ width: '75px', fontSize: "1.2em" }}>YES</Button>
                            <Button className='sa-create-btn-large' onClick={() => { goBack() }} style={{ width: '75px', fontSize: "1.2em" }}>NO</Button>
                        </div>
                    </>
                    :
                    <>
                        {/* org */}
                        <div>
                            <h3 >Select Organization</h3>
                            <Select
                                showSearch={true}
                                onSearch={(e) => { getOrgList({ name_Icontains: e }) }}
                                style={{
                                    width: '100%',
                                }}
                                className={"normal-bordered"}
                                onChange={(value) => { SetOrg(value) }}
                                filterOption={false}

                            >

                                {
                                    org_list?.map(item => {
                                        return <Select.Option className="poppins" key={item?.id} value={item?.id} >{item?.name} </Select.Option>
                                    }
                                    )
                                }

                            </Select>
                        </div>

                        {/* vertical */}
                        {
                            selected_org && (
                                <div style={{marginTop:"16px"}}>
                                <h3 >Select Vertical</h3>
                                <Select
                                    id={selected_org}
                                    showSearch={true}
                                    onSearch={(e) => { getVerticalList({ name_Icontains: e, organization: selected_org }) }}
                                    style={{
                                        width: '100%',
                                    }}
                                    className={"normal-bordered"}
                                    onChange={(value) => { SetVertical(value) }}
                                    filterOption={false}
                                    allowClear
                                    onClear={()=>SetVertical(null)}
                                >
    
                                    {
                                        vertical_list?.map(item => {
                                            return <Select.Option className="poppins" key={item?.id} value={item?.id} >{item?.name} </Select.Option>
                                        }
                                        )
                                    }
    
                                </Select>
                            </div>
                            )
                        }

                          {/* team */}
                          {
                            (selected_org && selected_vertical) && (
                                <div style={{marginTop:"16px"}}>
                                <h3 >Select Team</h3>
                                <Select
                                    id={selected_vertical}
                                    showSearch={true}
                                    onSearch={(e) => { getTeamList({ name_Icontains: e, vertical: selected_vertical }) }}
                                    style={{
                                        width: '100%',
                                    }}
                                    className={"normal-bordered"}
                                    onChange={(value) => { SetTeam(value) }}
                                    filterOption={false}
                                    allowClear
                                    onClear={()=>SetTeam(null)}
                                >
    
                                    {
                                        team_list?.map(item => {
                                            return <Select.Option className="poppins" key={item?.id} value={item?.id} >{item?.name} </Select.Option>
                                        }
                                        )
                                    }
    
                                </Select>
                            </div>
                            )
                        }
                       
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1em", width: "100%" }}>
                            <Button className='sa-create-btn-large' onClick={() => { addUserToOrg() }} style={{ fontSize: "1.2em" }}>Submit</Button>
                            <Button className='sa-create-btn-large' onClick={() => { goBack() }} style={{ fontSize: "1.2em" }}>Cancel</Button>
                        </div>
                    </>
            }

        </>
    )
}

export default AddUserToOrgView
import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
import { Spin } from 'antd';

import ConditionOperator from '../../../text-section/helpers/helpers';
import { compose } from '../../../core';
import { withLookConditionFilterUpdating, withLookConditionState } from '../../containers/LookOperations';
import QuestionAutoComplete from './QuestionAutoComplete';
import { Row } from 'antd';
import { Col } from 'antd';
import AddIcon from '../AddIcon';

export const ALL_CONDITIONS_QUERY = gql`
  query getAllConditions($first: Int, $orderBy: [String], $leftOperand: ID) {
    getAllConditions(first: $first, orderBy: $orderBy, leftOperand: $leftOperand) {
      edges {
        node {
          id
          leftOperand {
            id
            questionText
          }
          operator
          rightOperand
        }
      }
    }
  }
`;

const ConditionAutoComplete = props => {
  const { loading, getAllConditions, onAdd, onLeftOperandChange, onFiltersRemove } = props;
  const handleFilterRemove = React.useRef(() => {});

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const choices =
    !loading &&
    getAllConditions &&
    getAllConditions?.edges.length > 0 &&
    getAllConditions?.edges?.map(({ node }) => ({
      label: (
        <Row justify="space-between" align="middle">
          <Col span={7} align="left">
            <span>{node?.leftOperand?.questionText}</span>
          </Col>
          <Col span={6} align="left">
            {ConditionOperator?.filter(c => c.fetchValue === node?.operator)[0]?.label}
          </Col>
          <Col span={7} align="left">
            <span>{node?.rightOperand}</span>
          </Col>
          <Col span={4} align="right">
            <AddIcon tooltipSuffix="Condition" color={'primary'} size="sm" ghost onClick={() => onAdd({ node })} />
          </Col>
        </Row>
      ),
      value: { node }
    }));

  return (
    <>
      <QuestionAutoComplete margin={false} label="Question" onChangeSelect={onLeftOperandChange} />
      <br />
      <Spin spinning={loading}>
        <Row gutter={[24, 24]}>{choices && choices?.map(c => <Col span={24}>{c?.label}</Col>)}</Row>
      </Spin>
    </>
  );
};

export default compose(
  withLookConditionState,
  graphql(ALL_CONDITIONS_QUERY, {
    options: ({ filter, orderBy }) => {
      return {
        variables: { ...filter, first: 3, orderBy }
      };
    },
    props({ data }) {
      const { loading, error, getAllConditions, subscribeToMore, updateQuery } = data;
      return { loading, error, getAllConditions, subscribeToMore, updateQuery };
    }
  }),
  withLookConditionFilterUpdating
)(ConditionAutoComplete);

// @ts-nocheck
import React from 'react';
import { Row,Input } from "antd";
// import styled from "styled-components";
import Search_icon from '../../assets/search-icon.svg'
import moment from 'moment-timezone';
const AsssigmentFilter = props => {
 
    const {
        me,
        filterData,
        defaultFilter,
        onfilterChange
    } = props; 
    
    const [selectedFilter,setFilter]=React.useState('Objective')
    // eslint-disable-next-line no-unused-vars
    const [filterList,setfilterList]=React.useState([])
    const [filterValue,setfilterValue]=React.useState()
    React.useEffect(()=>{
        if(filterData){
            setfilterList(filterData)
        }
    },[filterData])

    React.useEffect(()=>{
        if(defaultFilter){
            setFilter(defaultFilter?.key)
        }
    },[defaultFilter])
    const filterValueChange=(value)=>{
        setfilterValue(value)
        switch (selectedFilter){
            case 'Objective':{
                onfilterChange({employee:me?.employee?.id,role: "ASSIGNED", requestStatus_In: ["PENDING", "NEEDS_DISCUSSION"],requestStatus_Iexact:"ACCEPTED",okr_Goalstatement_Icontains:value,offset:0,first:10,tz:moment.tz.guess()})
                break
            }
            default : break
        }
    }

    const FilterType =(
        <div>
            {(selectedFilter==='Objective'||selectedFilter==='Incomming'||selectedFilter==='Outgoing') && (
                <div style={{position:'relative'}}>
                <Input
                placeholder=""
                className='filter-input'
                style={{paddingLeft:"30px"}}
                value={filterValue}
                onChange={e => (filterValueChange(e.target.value))}   
                autoComplete='off'
                />
                  <img style={{position:"absolute",left:"7px",top:"10px",width:"20px"}} src={Search_icon } alt="" />
                </div>
            )}
        </div>
    )

    return (
        <>
        <Row>
            <div>
              {FilterType}
            </div>
        </Row>
        </>
    )

}

export default  AsssigmentFilter;
import { Button, Modal, Popconfirm } from "antd";
import React from "react";
import active_star from '../../assets/mapp-review-active-star.svg'
import inactive_star from '../../assets/mapp-review-disabled-star.svg'
import { review_smiles } from "../configs/review-config";
import close_icon from "../../assets/readiness-close-icon.svg"
const MappReviewView = (props) => {
    const { reviw_visible,closeReview,onSubmitReview} = props
    const stars = [1, 2, 3, 4, 5]
    const [give_star_count, setGivenStarCount] = React.useState(0)
    const [comment,setComment]=React.useState("")
    const [warning_visible,setWarningVisible] = React.useState(false)
    const selectReviewStar = (value) => {
        setGivenStarCount(value)
    }

    const onSubmit =()=>{
        let data_set ={
            comment:comment,
            rating:give_star_count,
            module:reviw_visible
        }
        onSubmitReview(data_set)
    }
    const onSkip =()=>{
        if(give_star_count > 0 ||comment){
            setWarningVisible(true)
        }
        else{
            setComment(null)
            setGivenStarCount(0)
            closeReview()
        }
    }
    const confirmWarning =()=>{
        setComment(null)
        setGivenStarCount(0)
        closeReview()
    }
    return (
        <>
            <Modal
                visible={reviw_visible}
                destroyOnClose={true}
                centered
                closable={false}
                footer={false}
                width={"90%"}
                style={{maxWidth:"40em"}}
                className="mapp-feedback-review-popup"
            >
                <div className="mapp-review-container" style={{position:'relative',width:"100%"}}>
                    <div style={{position:"absolute",right:"0px",top:"0px"}}>
                        <Popconfirm
                                overlayStyle={{zIndex:1700}}
                                placement="left"
                                overlayClassName="review-popcon"
                                title={
                                    <h4 className="mapp-review-title" style={{ fontSize: '1em',fontWeight:"500",color:'#000',textAlign:"center" }}>
                                    You have not saved the rating/comments.<br/> Do you want to proceed?
                                    </h4>
                                }
                                visible={warning_visible}
                                onConfirm={()=>confirmWarning()}
                                onCancel={()=>setWarningVisible(false)}
                                okText="Yes"
                                cancelText="No"
                                okButtonProps={{
                                    className: 'review-popcon-yes-btn',
                                  }}
                                  cancelButtonProps={{
                                    className: 'review-popcon-no-btn',
                                  }}
                            >
                                <img src={close_icon} alt="close_icon" style={{cursor:"pointer"}} onClick={()=>onSkip()}/>
                        </Popconfirm>
                        
                    </div>
                    <div className="mapp-review-star-container">
                        <div>
                            <h4 className="mapp-review-title">{reviw_visible} Rating</h4>
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "1em",paddingTop:'1em' }}>
                                {
                                    stars?.map((star, index) =>
                                        <span key={star}><img src={active_star} alt="" onClick={() => selectReviewStar(star)} style={{ cursor: "pointer" }} className={star >= give_star_count+1 ? "inactive-rating-star-count" : "inactive-rating-start"} /><img src={inactive_star} className={star >= give_star_count+1 ? "active-rating-star-count" : "active-rating-star"} alt="" style={{ cursor: "pointer" }} onClick={() => selectReviewStar(star)} /></span>
                                    )
                                }
                            </div>
                        </div>
                        {give_star_count>0&&<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img src={review_smiles[(give_star_count - 1)]?.icon} alt="emoji" style={{height:"4em",width:"4em"}}/>
                            <h4 className="mapp-review-title" style={{ fontSize: '1em' }}>{review_smiles[(give_star_count - 1)]?.title}</h4>
                        </div>}
                    </div>

                    <div style={{ width: "100%" }}>
                        <h4 className="mapp-review-title" style={{ fontSize: '1em' }}>{<span style={{visibility:give_star_count>0&&give_star_count<=3?"visible":"hidden",color: " rgba(249, 95, 83, 1)" }}>*</span>}Comments</h4>
                        <textarea className="mapp-review-comment-input" placeholder={give_star_count<=3?"Please provide your commets to help us to improve...":"Please add your comments..."} rows={6} onChange={(e)=>setComment(e?.target?.value)}></textarea>
                        {/* <p className="mapp-review-title" style={{fontSize:'1em',fontWeight:"500",color:"#ED4337",visibility:(give_star_count<3&&change_rating)?'visible':'hidden'}}>* Review comment is required</p> */}
                    </div>
                    <div className="mapp-review-button-container">
                        <Button className="mapp-review-submit-btn" disabled={(give_star_count<=3 && comment.trim()==="")||(give_star_count===0)} onClick={()=>onSubmit()}>Submit</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default MappReviewView
import { withApollo } from '@apollo/client/react/hoc';
import { Row} from 'antd';
import { compose } from "modules/core";
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { getRoleTypeBase64StringFromInt, globalPermissionValidator } from "modules/look";
import { role_permission } from "Permissions/role.permission";
import React from "react";
import RoleDetailView from "../components/RoleDetailView";
import { ROLE_BY_ID_QUERY } from "../graphql/RoleQueries.gql";
import { withDeleterole } from './RoleOperations';


const RoleDetailContainer = (props) => {

    const { client, userPermission} = props

    const [roleDetailsLoading, setRoleDetailsLoading] = React.useState(false);

    const [role, setRole] = React.useState(undefined);
    const [rolePermission, setRolePermission] = React.useState(undefined);



    // For checking the permission of role
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(role_permission, userPermission)
            if (permission?.view_role) {
                // Call the api to get details of role with id sent from url
                if (props.match.params?.role_id) {
                    getRoleDetails(props.match.params?.role_id)
                }

            }
            setRolePermission(permission)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPermission, props.match.params?.role_id])


    // method to fetch the details of particular role
    const getRoleDetails = async (id) => {
        setRoleDetailsLoading(true)
        let RoleId = getRoleTypeBase64StringFromInt(id)
        const { data } = await client.query({
            query: ROLE_BY_ID_QUERY,
            fetchPolicy: 'network-only',
            variables: { id: RoleId }
        });
        if (data?.roleById) {
            setRole(data?.roleById)
            setRoleDetailsLoading(false)
        }


    }




    return (

        <Row justify='center' align='middle' style={{ width: "100%" }}>
            {
                rolePermission !== undefined &&
                <div style={{width:"100%"}}>
                    {
                        rolePermission?.view_role ?
                            <RoleDetailView
                                role={role}
                                rolePermission={rolePermission}
                                roleDetailsLoading={roleDetailsLoading}
                                {...props}
                            /> :
                            <NoPermissionView />
                    }

                </div>
            }
        </Row>

    )

}

export default compose(withApollo, withDeleterole)(RoleDetailContainer);


import { useMutation } from "@apollo/react-hooks";
import { Button, Modal, Row, Spin } from "antd";
import gql from "graphql-tag";
import React from "react";
import { EDIT_BARS_ICON } from "../Icon";
import bars_route from "../route";
import BarsDeleteConfirmation from "./BarDeleteConfirmation";
import BarsEditDetailsTabContainer from "./BarsEditDetails";




const BarsEditSubCompetencyView = (props) => {
  const { barsDetails, barsDetailsLoading, setBarsDetails, setBarsDetailsLoading, empLevelRef, allRatingScales,
     createBulkBehaviourallyAnchoredRatings,navigateRoute
  } = props



  const [newlyAddedBars, setNewAddedBars] = React.useState([]);
  const [barsMutation, setBarsMutation] = React.useState(undefined);
  const barsDetailsRef = React.useRef(null);
  const [activeLevel, setActiveLevel] = React.useState(undefined);
  const [empLevels, setEmpLevels] = React.useState([]);
  const [importModalVisible, setImportModalVisible] = React.useState(false);
  const [importedData, setImportedData] = React.useState(undefined);
  const [invalidBehaviourExamples, setInvalidBehaviourExamples] = React.useState(false);


  React.useEffect(() => {

    window.scrollTo(0, 0);

  }, [])


  React.useEffect(() => {

    if (empLevelRef.current) {

      setEmpLevels(empLevelRef.current);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empLevelRef.current])


  React.useEffect(() => {

    if (props.match.params.level_id) {
      setActiveLevel(props.match.params.level_id)
    }

  }, [props.match.params.level_id])




  const handleImportCompetency = async () => {

    if (activeLevel > 0) {
    
     
      let levelToEdit = barsDetails?.empLevels?.filter((value) => value?.id === empLevels[activeLevel]?.id)
      let filtererData = barsDetails?.empLevels?.filter((value) => value?.id === empLevels[activeLevel-1]?.id)
      
      // eslint-disable-next-line no-unused-vars
      let records = levelToEdit?.forEach((value,index)=>
      
      handleExampleChange( filtererData[index]?.behaviourExample, value, {id:value?.id,name:value?.name})

      )

  
    } else {

      let importedData = JSON.parse(localStorage.getItem('mainCompetencyData'));
      
      let filtererData = importedData?.filter((value) => value?.employeeLevel?.id === empLevels[0]?.id)
      
      setImportedData(filtererData)
     
      // eslint-disable-next-line no-unused-vars
      let recordsNew = filtererData?.forEach((row) =>
      handleExampleChange(row?.behaviourExample, row, row?.employeeLevel))
    
    

    }

    setInvalidBehaviourExamples(true);
    setImportModalVisible(false)

  }


  



  

  const handleExampleChange = async (e, barsNode, level) => {
    
    let newBars;
    
    let indexOfUpdateBar = barsDetails?.empLevels?.findIndex((value) =>
      value?.ratingScale?.id === barsNode?.ratingScale?.id && value?.id === level?.id
    );
    
    let updatedBars = barsDetails;

   
    updatedBars.empLevels[indexOfUpdateBar].behaviourExample = e;
    
    


   
    
      let dataToCheck = importedData?.map((value)=>value?.behaviourExample?.trim())?.filter((value)=>value !== undefined);
      let dataFromCompetency = updatedBars?.empLevels?.map((value)=>value?.behaviourExample?.trim())?.filter((value)=>value !== undefined);
      let comparison  =  dataToCheck?.every((value, index) => value === dataFromCompetency[index]);
      
      setInvalidBehaviourExamples(comparison);
      
      
      newBars = updatedBars?.empLevels?.filter((value)=> !value?.bar && value?.behaviourExample).slice(0)
      
      setNewAddedBars(newBars);
    

    setBarsDetails({ ...updatedBars });
  }



  React.useEffect(() => {
    let newRecords = [];
    if (newlyAddedBars?.length && barsDetails?.empLevels?.length) {
      newRecords = newlyAddedBars?.map((value) => { return { id: value?.employeeLevelId, behaviourExample: value?.behaviourExample } })
      let combindBars = { empLevels: barsDetails?.empLevels.concat(newRecords) }

      let arr = [];

      empLevelRef.current
        .forEach(level => (
          arr.push({ ...level, status: combindBars?.empLevels?.filter(item => item?.id === level?.id && item?.behaviourExample)?.length !== allRatingScales?.length ? "pending" : 'completed' })
        ));


     
      empLevelRef.current = arr;
      setEmpLevels([...arr])


    }



  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newlyAddedBars, barsDetails])


  

  React.useEffect(() => {

    barsDetailsRef.current = barsDetails

    let list = barsDetails?.empLevels?.filter((value)=> value?.bar && value?.behaviourExample).slice(0)
    if (list?.length) {
      setBarsMutation(list)
    }
  }, [barsDetails])


  const UPDATE_DEFINITION = gql`
  mutation updateBehaviourallyAnchoredRating {
    ${barsMutation?.length &&  barsMutation?.map(
    (item, index) => {
      return `update${index}: updateBehaviourallyAnchoredRating(id: "${(item.bar)}",behaviourExample:${JSON.stringify(item.behaviourExample)}) {
              behaviourallyAnchoredRating{
                id
              }
             }
           `
    }
  )
      .join('')}
  }
  `;

  const [updateDefinition] = useMutation(UPDATE_DEFINITION);



  const handleEditBars = async () => {

    setBarsDetailsLoading(true)
    let addResponse
    if (newlyAddedBars?.length) {

      let bars = [];

      // eslint-disable-next-line no-unused-vars
      let records =  newlyAddedBars?.forEach((record)=>
      bars.push({
        competency: { behaviourId: barsDetails?.behaviourId },
              ratingScaleId: record?.ratingScale?.id,
              employeeLevelId: record?.id,
              behaviourExample: record?.behaviourExample
         }) 
      )
      

      addResponse = await createBulkBehaviourallyAnchoredRatings({ behaviourallyAnchoredRatingDataList: bars })
    }

  
    if (barsMutation?.length) {
      const response = await updateDefinition();
      if (response) {
        setBarsDetailsLoading(false)
        navigateRoute(bars_route.bars_home)
      }
    } else {
      if (addResponse) {
        setBarsDetailsLoading(false)
        navigateRoute(bars_route.bars_home)
      }
    }
   
    localStorage.removeItem('mainCompetencyData')

  }


  const handlePreviousRoute = () =>{

    localStorage.removeItem('mainCompetencyData');
    navigateRoute(bars_route.bars_home)

  }


  return (
    <>
      <Spin spinning={barsDetailsLoading}>
        <div className="create-bars-root poppins">

          <img src={EDIT_BARS_ICON.new_back_arrow} onClick={(e) => handlePreviousRoute()} className="create-bar-back-arrow pointer" alt="new_back_arrow"/>

          <Row align="middle" justify="center">
            <div className="create-bars-header">Edit <span>BARS</span></div>
          </Row>

          <div className="create-bars-body">
            <Row justify="space-between" align="middle">
              <div className="create-bar-sub-heading create-bar-margin-seperator">Competency</div>
              <img className="pointer" style={{ width: '1.2em' }} src={EDIT_BARS_ICON.import_info} onClick={(e) => setImportModalVisible(true)} alt="import_info"/>
            </Row>

            <div className="bars-create-competency-title create-bar-margin-seperator">{barsDetails?.title}</div>
            {/* Component for adding data into rating scales with respective employee levels */}
            {
              barsDetails ?
              <BarsEditDetailsTabContainer
              barsDetails={barsDetails}
              allEmployeeLevels={empLevels}
              allRatingScales={allRatingScales}
              handleExampleChange={handleExampleChange}
              indexOfElements={1}
              activeLevel={activeLevel ? activeLevel : 0}
              setActiveLevel={setActiveLevel}
              invalidBehaviourExamples={invalidBehaviourExamples}
              setInvalidBehaviourExamples={setInvalidBehaviourExamples}
            ></BarsEditDetailsTabContainer>
            :null
            }
           
          </div>
          {
            (empLevels?.length && allRatingScales?.length) ?
            <Row justify="center">
            <Button className="bars-submit-action" onClick={(e) => handleEditBars()}>Submit</Button>
            </Row>
            : null
          }
         


        </div>

        <Modal
          visible={importModalVisible}
          footer={null}
          centered
          destroyOnClose={true}
          closable={false}
        >
          <BarsDeleteConfirmation
            message={"Would you like to import the rating scale from the previously defined competency? You will be able to proceed only after editing the rating scale for the sub-competency."}
            confirm_action_label={"Yes"}
            cancel_action_label={"No"}
            onConfirm={(e) => handleImportCompetency()}
            onCancel={(e) => setImportModalVisible(false)}
            confirm_class="bars-confimation-action"
            cancel_class="bars-cancel-action"
          ></BarsDeleteConfirmation>

        </Modal>
      </Spin>

    </>
  );
};

export default BarsEditSubCompetencyView;


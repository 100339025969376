import React from 'react';
import { Dropdown, Menu,Tooltip } from 'antd';
import three_dots from '../../assets/three_dots.svg'
import edit_disable from '../../assets/edit-disabled.svg'
import cascade_disable from '../../assets/cascade_disable.svg'
import delete_disable from '../../assets/delete_disable.svg'
import discard_disble from '../../assets/discard_disable.svg'
import view_disable from '../../assets/View_disable.svg'
import kudos_disable from '../../assets/kudos_disable.svg'
const TableRowMenu = (props) => {
    const { onClick, options } = props
    
    // donot change the keys of already declared items in menu
    const disable_icon= React.useRef({
        view:view_disable,
        edit:edit_disable,
        kodos:kudos_disable,
        discard:discard_disble,
        delete:delete_disable,
        tree:cascade_disable
    })

    const MenuDropDown = () => (
        

     
        <Menu className='three-dot-menu'>
          
              {  options.map((val) => 
                   (<Menu.Item  key={val.key} disabled={val?.disabled} onClick={(e)=>onClick(val.key)} style={{padding:'10px'}}>
                        <div className='row'   style={{width:'90%',margin:'auto', cursor: "pointer",gap:"4px",alignItems:"center"}}>
                         {
                            !val?.disabled?
                                <img src={val.icon} alt="" style={{ height:'18px',width:'18px',color:'black',margin:'0px 5px'}} />
                                :
                                <img src={disable_icon.current[val.key] || view_disable} alt="" style={{ height:'18px',width:'18px',color:'black',margin:'0px 5px'}} />
                         }                       
                        
                        <div style={{fontSize:'16px',padding:"0px",margin:"0px"}}>{val.label} </div>

                        {
                            val?.post_icon && (
                                <Tooltip title={val?.tooltip}>
                                    <img src={val.post_icon} alt="" style={{ height:'15.75px',width:'15.75pxpx',color:'black',margin:'0px 5px'}} />
                                </Tooltip>
                            )
                        }
                        
                        
                        
                        </div>
                    </Menu.Item>)
                  
                )}
          
        </Menu>
        
    )
    
    return (
        <>
            <Dropdown overlay={MenuDropDown} placement="bottomLeft"
            overlayStyle={{ width: '160px' }}  >
                <img src={three_dots} alt="" style={{ cursor: "pointer",height:"25px" }}  />
            </Dropdown>
            
        </>
    )
}
export default TableRowMenu
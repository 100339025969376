// @ts-nocheck
import React from 'react'
import { VERTICAL_AND_TEAN_LIST } from '../graphql/verticalTeamquery.gql';
import { compose } from '../../core';
import VerticalTeamView from '../components/VerticalAndTeamsView';
import { getIntFromString } from 'modules/look';
const VerticalAndTeamsContainer =(props)=>{
    const {me,client,dateRangeTofilter,organizationDetail}=props
    const [dataSource, setdataSource] = React.useState();
    const [dataSourceLoader, setdataSourceLoader] = React.useState(true);
    React.useEffect(()=>{
        if(me && organizationDetail){
            getVerticalAndTeams(me?.employee?.id,true)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[me,organizationDetail])


      const calculateVerticalProgress = (heads)=>{
        let list = heads?.map(({node})=>{
          return node?.okrContributorSet?.edges?.map(({node})=>node?.progress)
          // ?.filter(item=>item?.node?.employee?.id === node?.id && item?.node?.createdBy?.id !==node?.id)
        })
        
        list=[].concat.apply([],list)
        let progress =list.reduce((a, b) => a+ b, 0)
        return (!progress || isNaN(progress)|| isNaN(progress/list?.length)||(progress/list?.length)===Infinity)?'0%':`${(progress/list?.length)?.toString()?.includes('.')?(progress/list?.length).toFixed(2):(progress/list?.length)}%`
  
      }
     

    const getVerticalAndTeams = async(id,addteam=false)=>{
        setdataSourceLoader(true)
        const { data } = await client.query({
          query: VERTICAL_AND_TEAN_LIST,
          variables: {
            // getIntFromString(verticalid),
            duedate_Range: dateRangeTofilter,
            // employee_In:[getIntFromString(id)],
            // createdBy_In:[getIntFromString(id)]
            // managerId: [],
          },
        //   fetchPolicy: 'network-only'
        });
        if(data?.verticalTeamList){
           
            const vertical = null
            let org = data?.verticalTeamList?.employee?.organizationSet?.edges?.find(({node})=>node?.id===organizationDetail?.id)
            
            let vertical_list = org?.node?.verticalSet?.edges.map(({node})=>{  
                return {
                    type:'parent-vertical',
                    id:node?.id,
                    name:node?.name,
                    parent_vertical:node?.parentvertical,
                    subvertical:node?.subvertical?.totalCount,
                    employeesList:node?.heads,
                    teamSet:node?.teamSet?.totalCount,
                    progress:calculateVerticalProgress(node?.heads?.edges),
                    managerId:node?.heads?.edges?.map(({node})=>getIntFromString(node?.id)),
                }
            })
           
            let edges=vertical_list
            setdataSource({edges,vertical})
            setdataSourceLoader(false)
          
        }
      }
    
    return (
        <>
        <VerticalTeamView 
         setdataSourceLoader={(e)=>setdataSourceLoader(e)} 
         dataSourceLoader={dataSourceLoader} 
         dataSource={dataSource}
        //  getSubverticalAndTeams={(e)=>{GetsubVerticalList(e)}}
         getVerticalAndTeams={(e)=>getVerticalAndTeams(me?.employee?.id,true)}

        {...props}/>
        </>
    )
}

export default  compose()(VerticalAndTeamsContainer)
import React from 'react';
import '../css/SalesToolStyles.css'
import BookDemoView from './BookDemoView';
import SalesPageFooter from './SalesPageFooter';
import SalesPageNavbar from './SalesPageNavbar';
import '../css/SalesPageStyles.css'
import Value from '../../assets/Value.png'
import Impact_narrative from '../../assets/Impact_narrative.png'
import knowledge from '../../assets/knowledge.png'
import Personalities from '../../assets/Personalities.png'
import strengths from '../../assets/strengths.png'
import personalities_feature from '../../assets/personalities_feature.png'
import knowledge_feature from '../../assets/knowledge_feature.png'
import impact_narrative_feature from '../../assets/impact_narrative_feature.png'
import { Button, Col, Row } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';



const SoulIdentityView = props => {



    const history = useHistory();

    const soulFeatureSet = [{ id: 'value', title: 'Value', text: "Values are our personal guiding principles. Our personal values decide what we value and don't. Therefore, they also influence what we think is right or wrong. Our sense of integrity is based on them. Aligning with values will allow us a space where we feel we are doing the right things the right way. Where values are being compromised, we won't be at peace. We may feel disrespected at one end and compromised at the other. Therefore, it's important for us to know our values and be purposeful about them.", image: Value },
    { id: 'strengths', title: 'Strengths', text: "Strengths are natural gifts we have that help us do some activities better than others without a lot of effort and, at the same time, be energized by the experience. Given these are natural gifts, if we are able to discover and hone them, we can create outsized impact. There's deep research in positive psychology on strengths and how to discover and grow them so we can be more successful.", image: strengths },
    { id: 'personalities', title: 'Personalities', text: "Where do we get our energy from? How do we take in information? How do we make decisions? How do we get things done? Dr Carl Jung's pioneering research into personality demonstrates that we have our preferred ways, and being aware of these preferences helps us know how we choose to behave and why. Awareness of our personality preferences helps us understand our unique style of showing up and determine whether it's serving us well or not. We can then step out of our comfort zone to achieve better results. Personality awareness helps us build trust, better relationships, be more effective and collaborate smoothly.", image: personalities_feature },
    { id: 'knowledge', title: 'Knowledge & Skills', text: "Our education, the school we went to, the training we received, who our teachers and peers were, all of these contribute to shaping our sense of identity. We also gather knowledge through informal channels and from our experience that we are able to use to create visible impact at work and otherwise. This sense of identity also evolves as we become increasingly aware of the skills we have and our levels of expertise.", image: knowledge_feature },
    { id: 'impact_narrative', title: 'Impact Narrative', text: "It’s the unique workstyle of each person created through the input of the psychometric assessments and helps people be intentional about how they perform. ", image: impact_narrative_feature }]


    const scrollToElement = (elementId) => {
        let id = document.getElementById(elementId)
        if (id) {
            id.scrollIntoView({ inline: 'end', behavior: 'smooth' })
        }
    }

    React.useEffect(()=>{

        let element = document.getElementById('soul-tool-container-sales')
        if(element){
            element.scrollIntoView({block:'start',inline:'start',behavior:'smooth'})
        }
    },[])


    return (
        <div id='soul-tool-container-sales' className='poppins'>
            {/*  Navigation bar */}
            <SalesPageNavbar />



            {/* Body section */}
            <Row justify='center' align='middle' className='soul-tool-spacer soul-tool-intro-section' style={{ marginTop: '20px' }} >
                <Col xs={22} sm={22} md={22} lg={11}>
                    <Row justify='start' style={{ flexDirection: 'column', width: '90%' }}>
                        <div className='soul-tool-identity-header'>Soul identity</div>
                        <div style={{ fontSize: '17px', fontWeight: '500', marginTop: '30px', marginBottom: '20px' }}>
                            It helps managers guide their team members on self-awareness journeys and express their unique workstyles, known as impact narratives. This allows individuals to be more deliberate in their performance and enables managers to personalize their leadership approach without the need to remember everyone's preferences. It also fosters better understanding and collaboration across teams and promotes inclusivity within the organization through the Diversity Profile, which captures employee diversity and can be customized based on individual preferences.
                        </div>

                        
                    </Row>
                <Row justify='start'>
                <Button className='button-link-sales-page' onClick={(e) => history.push('/intro/getintouch')}>Get in Touch</Button>
                </Row>
                </Col>
                {/* Column for  */}
                <Col xs={22} sm={22} md={22} lg={10}>
                    <video  loop autoPlay>
                        <source src="https://res.cloudinary.com/mapp-brainayan-app/video/upload/v1687267215/MApp/UI/gif/Soul_marleting_mui9wt.mp4" type="video/mp4" />
                    </video>
                </Col>
            </Row>

            {/* Features panel */}

            <div className='soul-tool-feature-header'>Soul identity core feature  </div>

            <Row justify='center' align='middle' className='soul-tool-feature-card-navigation' >
                <Col xs={22} sm={22} md={11} lg={11}>
                    <Row className='feature-row survey-type-soul-tool'  style={{ gap: '15px' }}>
                        <Col xs={22} sm={22} md={12} lg={12} className='pointer feature-col-soul-sales-page' onClick={(e) => scrollToElement('value')} >
                            <div className='feature-label'>Value</div>
                            <img style={{ width: '100%', height: '100%' }} src={Value} alt=""/>
                        </Col>
                        <Col xs={22} sm={22} md={12} lg={12} className='pointer feature-col-soul-sales-page' onClick={(e) => scrollToElement('strengths')} >
                            <div className='feature-label'>Strengths</div>
                            <img style={{ width: '100%' }} src={strengths} alt=""/>
                        </Col>
                    </Row>
                    <Row className='horizontal-card-container-soul-tool'>
                        <Col xs={23} sm={23} md={24} lg={24} className='pointer feature-col-soul-sales-page' onClick={(e) => scrollToElement('personalities')}>
                            <div className='feature-label'>Personalities</div>
                            <img style={{ width: '100%' }} src={Personalities} alt=""/>
                        </Col>
                    </Row>

                </Col>

                <Col xs={22} sm={22} md={10} lg={10}>
                    <Row style={{ gap: '15px' }} className='survey-type-soul-tool'>
                        <Col xs={22} sm={22} md={12} lg={12} className='pointer feature-col-soul-sales-page' onClick={(e) => scrollToElement('knowledge')}>
                            <div style={{ top: '32px' }} className='feature-label'>Knowledge & Skills</div>
                            <img style={{ width: '100%', height: '98%', paddingTop: '12px' }} src={knowledge} alt=""/>
                        </Col>
                        <Col xs={22} sm={22} md={12} lg={12} className='pointer feature-col-soul-sales-page' onClick={(e) => scrollToElement('impact_narrative')}>
                            <div style={{ top: '32px' }} className='feature-label'>Impact Narrative</div>
                            <img style={{ width: '100%', height: '98%', paddingTop: '12px' }} src={Impact_narrative} alt=""/>
                        </Col>
                    </Row>
                </Col>
            </Row>


            {/* Feature details section */}
            <div className='soul-tool-spacer' style={{ marginBottom: '25px' }}>
                {
                    soulFeatureSet.map((feature) =>
                        <Row id={feature.id} className="feature-card-soul-tool" justify='center' align='middle' >
                            <Col xs={24} sm={24} md={11} lg={11} className="soul-tool-feature-row" >
                                <img className='feature-description-image-soul-tool' src={feature.image} alt=''/>
                            </Col>
                            <Col xs={24} sm={24} md={10} lg={10} className="soul-tool-feature-row" >
                                <Row style={{ flexDirection: 'column', rowGap: '20px' }}>

                                    <div className='feature-description-soul-tool-title'>{feature.title}</div>
                                    <div className='feature-description-soul-tool-text'>{feature.text}</div>
                                </Row>

                            </Col>
                        </Row>
                    )
                }
            </div>



            {/* Book a demo section */}
            <BookDemoView paddingTop='57px' />



            {/* Footer section */}
            <SalesPageFooter />

        </div>


    );
};

export default SoulIdentityView;

import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../look';

import EditToolView from '../components/EditToolView';
import ROUTE from '../route';
import { CKEditorFieldName } from '../components/ToolFormComponent';

import { withEditTool, withToolByIdAdmin } from './ToolOperations';

const EditTool = props => {
  const { history, editTool, getToolByIdAdmin } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);

    try {
      const response = await editTool(
        withMarkdownFieldValue(CKEditorFieldName, {
          id: Number(getIntFromString(getToolByIdAdmin.id)),
          ...values
        })
      );
      response &&
        ButtonsCatch(
          item,
          `${ROUTE.toolDetailLink}${getIntFromString(response.id)}`,
          history,
          item === undefined ? window.location.pathname : `${ROUTE.addToolLink}${getIntFromString(response.id)}`
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditToolView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withToolByIdAdmin, withEditTool)(EditTool);

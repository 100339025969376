import React from "react";
import SoulUserReportContainer from "../containers/soul-user-report-container";
import { Button, Input } from "antd";
import SoulTeamReportContainer from "../containers/soul-team-report-container";
import '../css/soul-report.css'
// import comparison_icon from "../../assets/comparison-icon.svg"
import ComparisonSelectionPopupContainer from "../containers/comparison-selection-popup-container";
import CompareResponseContainer from "../containers/compare-resoponse-container";
import { dashboard_home_icons } from "modules/poc-dashboard/icons";
// import CustomSoulReportFilterContainer from "../containers/custom-soul-report-filter-container";
const SoulReportHomeView = (props) => {
    const {me}=props
    const [activeTab, setActiveTab] = React.useState('mySoulIdentity');
    const [selected_employee_list, setSelectedEmployeeList] = React.useState()
    const [show_comparison_report,setShowComparisionReport]=React.useState(false)
    const filter_response = {}
    const [search,setSearch] = React.useState()
    let search_ref = React.useRef("")
    React.useEffect(() => {
        if(selected_employee_list){
            setShowComparisionReport(true)
        }
    },[selected_employee_list])

    const tabs = [
        {
            key: "mySoulIdentity",
            title: "My Soul Identity"
        },
        {
            key: "teamSoulIdentity",
            title: "Team's Soul Identity"
        }
    ]

    const module = {
        mySoulIdentity: SoulUserReportContainer,
        teamSoulIdentity: SoulTeamReportContainer,
    }
    let search_time = React.useRef()

    const searchReport =(value)=>{
        if (search_time.current) {
            clearTimeout(search_time?.current)
          }
          search_time.current = setTimeout(() => {
            search_ref.current = value
            setSearch(value)
          }, 500);
    }
    const CurrentView = module[activeTab]

    return (
        <div>
           {(me?.employee?.verticalHeadEmployee?.totalCount > 0 ||
             me?.employee?.teamManagerEmployee?.totalCount > 0 ||
             me?.employee?.orgCeoEmployee?.totalCount > 0 ||
             me?.employee?.orgPocEmployee?.totalCount > 0) && 
             <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "1em" }}>
                    <div className="soul-report-tab-switcher">
                        {tabs.map(item => (
                            <Button
                                onClick={() => setActiveTab(item?.key)}
                                className={activeTab === item?.key ? 'selected-soul-active-tab' : 'soul-report-switch-button'}
                            >
                                {item?.title}
                            </Button>
                        ))}
                    </div>
                    {activeTab==='teamSoulIdentity'&&
                    // <Tooltip title="Compare Reports" placement="bottom">
                    //     <img src={comparison_icon} alt="comparison_icon" style={{cursor:"pointer",width:"2.5em",height:"2.5em"}} onClick={()=>setShowComparision(true)}/>
                    // </Tooltip>
                    <ComparisonSelectionPopupContainer {...props} compare_request={(e)=>setSelectedEmployeeList(e)}/>
                    }
                </div>
                {activeTab==='teamSoulIdentity'&&<div style={{display:'flex',gap:"1em",alignItems:'center'}}>
                    <div style={{ position: "relative", width: "100%", maxWidth: "12.5em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".25em" }}>
                        <img src={dashboard_home_icons?.search_icon} alt="search_icon" style={{ height: "20px" }} />
                        <Input style={{ width: "100%", border: "none", height: "3em", fontSize: "1em", fontFamily: "Poppins",backgroundColor:"transparent"}} placeholder="Search" onChange={(e)=>searchReport(e?.target?.value)}/>
                    </div>
                    {/* <CustomSoulReportFilterContainer filter={(filter)=>setFilterResponse(filter)} {...props}/> */}
                </div>}
            </div>}

            <div style={{ marginTop: "2em" }}>
                <CurrentView  {...props} filter_response={filter_response} search={search}/>
                <CompareResponseContainer {...props} show_comparison_report={show_comparison_report} selected_employee_list={selected_employee_list} onClose={()=>{setShowComparisionReport(false);setSelectedEmployeeList(null)}}/>
            </div>

        </div>
    )
}
export default SoulReportHomeView
export const module_config = {
    soul: 'SOUL',
    shoal: 'SHOAL',
    whole: 'WHOLE',
    poll: 'POLL',
    diversity: 'DIVERSITY',
    whoposhoro: 'WHOPOSHORO',
    kudos: 'KUDOS',
    role: 'ROLE',
    hr: 'HR',
    career: 'CAREER',
    okr:"OKR",
    readiness:"READINESS",
    feedback:"FEEDBACK",
    one_on_one:"ONE_ON_ONE",
    coaching_engagement:"COACHING_ENGAGEMENT",
    managing_performance:"MANAGING_PERFORMANCE",
    bars:"BARS",
    role_definition:"ROLE_DEFINITION",
    role_relation:"ROLE_RELATION",
    hiring:"HIRING",
    values:"VALUES",
    strengths:"STRENGTHS",
    personalities:"PERSONALITIES",
    knowledge_skills:"KNOWLEDGE_SKILLS",
    impact_narrative:"IMPACT_NARRATIVE",
    three_cs:"3CS",
    user_manual:"USER_MANUAL",
    scape_report:"SCAPE_REPORT",
    visioning:"VISIONING",
    career_planning:"CAREER_PLANING",
    career_conversation:"CAREER_CONVERSATION",
    report:"REPORT",
    home:"HOME",
    survey:"SURVEY",
    dashboard:"DASHBOARD",
    my_team:"MY_TEAM",
    survey_list:"SURVEY_LIST",
    idp:"IDP",
    poc_admin:"POC_ADMIN",
    poc_dashboard:"POC_DASHBOARD",
    poc_organization:"POC_ORGANIZATION",
    poc_token:"POC_TOKEN",
    poc_report:"POC_REPORT",
    soul_user_report:"SOUL_USER_REPORT",
    learning_path:"LEARNING_PATH",
    mapp_support:"MAPP_SUPPORT"
}
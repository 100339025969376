import React from 'react';
import { Spin } from 'antd';

import AddResponseView from '../components/AddResponseView';

// import { withAddResponse } from './OrganizationOperation';

const AddResponse = props => {
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async values => {
    setSubmitLoading(true);

    try {
      // const response = await addResponse({ userId: values.userId });
      // response && history.push(ROUTE.group);
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddResponseView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default /* withAddResponse */ AddResponse;

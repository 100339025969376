import React from "react";
import ThankyouPage from "../components/ThankyouPage";

const ThankyouTicketContainer = (props)=>{
    return (
        <>
            <ThankyouPage {...props}/>
        </>
    )
}
export default ThankyouTicketContainer
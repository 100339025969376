import React from 'react';
import {  Dropdown, Menu, Row, } from 'antd';
// import three_dots from '../../assets/three_dots.svg'
import AwardDetailPopup from 'modules/kudos-user/container/awardDetaailPopup';
import ShareModal from 'modules/kudos-user/component/shareModalView';
const ReceivedBadgeMenu = ( props ) => {
    const {  optionData, menuIcon,margin } = props
    
    const [ showDetail, setShowDetail ] = React.useState( false )
    const [ badgeDetail, setBadgeDetail ] = React.useState()
    const [shared,setShared] =React.useState(false)
    const showBadgeDetail = ( item ) => {
        if ( item ) {
            setBadgeDetail( item )
            setShowDetail( true )

        }
    }

    const MenuDropDown = () => (
        <Menu className='user-badge-root-class' style={{marginTop:"-50px",borderRadius:"13px",boxShadow: "-1px 6px 27px -9px rgba(0, 0, 0, 0.31)"}}>
            <Row style={{height: "100%", minHeight: "100px", maxWidth: "200px",width:"100%",padding:"10px"}}>
                <h4 className='recieved-badge-head' style={{width:"100%"}}>Received Kudos</h4>
                <Row justify='start'style={{width:"100%",gap:"10px",marginTop:'10px'}}>
                    {
                        optionData?.map( ( { node } ) => (
                            <div style={{ width: "50px", height: "50px", borderRadius: "50%", border: "1px solid #19E37E", display: "flex", justifyContent: "center", alignItems: 'center', cursor: "pointer",marginTop:"5px"}}
                                onClick={() => showBadgeDetail( node )}>
                                <img style={{ width: "45px", height: "45px", borderRadius: "50%"}} src={node?.badge?.image} alt="" />
                            </div>
                        ) )
                    }
                </Row>
            </Row>

        </Menu>

    )

    return (
        <>
            <Dropdown overlay={MenuDropDown} placement="bottomLeft">
                <div style={{ width: "40px", height: "40px", borderRadius: "50%",border: "1px solid #19E37E", display: "flex", justifyContent: "center", alignItems: 'center', cursor: "pointer", marginLeft:margin,position:"absolute" }}>
                    <img style={{ width: "35px", height: "35px", borderRadius: "50%" }} src={menuIcon} alt="" />
                </div>
            </Dropdown>
                {
                    showDetail&&<AwardDetailPopup visible={showDetail} onClose={()=>{setShowDetail(false);setBadgeDetail()}}selectedAward={badgeDetail} shareAward={(e)=>setShared(e)}/>
                }
                 {
                    shared&&<ShareModal SharedVisible={shared} sharedClose={( e ) => { setShared( e );setBadgeDetail();setShowDetail(e) }} sharedValue={badgeDetail} />
                }
        </>
    )
}
export default ReceivedBadgeMenu
import { Button, Input, message, Modal, Select, Spin } from "antd";
import React from "react";
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg';
import search_icon from '../../assets/search-icon.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Radio_disabled from '../../assets/readiness-radio-inactive.svg'
import Radio_active from '../../assets/readiness-radio-active.svg'
import close_icon from '../../assets/dashboard-kudos-close-icon.svg';
import left_arrow from '../../assets/kudos-left-arrow-icon.svg';
import right_arrow from '../../assets/kudos-right-arrow-icon.svg';
import { getIntFromString } from "modules/look";
const GiveKudosNewPopupView = (props) => {
    const { me, visible, onSearch, loading, onCancel, employeeList, badgeList, badge_family_list, onFilter, getbadgeFailyList, getBageFamilyById, createBadgeAward } = props
    const [selectedEmployee, setSelectedEmployee] = React.useState()
    const [selectedBadge, setSelectedBadge] = React.useState()
    const [selected_notify, setSelectedNotify] = React.useState()
    const [filter_value, setFilterValue] = React.useState()
    const [detail, setdetail] = React.useState();
    const [allEmployeeList, setAllEmployeeList] = React.useState([])
    const Option = Select.Option;
    const notify_types = [
        {
            value: "team",
            label: "Team"
        },
        {
            value: "vertical",
            label: "Vertical"
        },
        {
            value: "organization",
            label: "Organization"
        }
    ]
    React.useEffect(() => {
        if (employeeList?.length) {
            let list = employeeList?.filter(({ node }) => node?.user?.id !== me?.employee?.user?.id)
            setAllEmployeeList(list)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeList])

    const handleSelectEmployee = (e) => {
        setSelectedEmployee(e)
    }
    const handleFamilyFilter = (e) => {
        setFilterValue(e)
        setSelectedBadge(null)
        onFilter({ badgeFamily: e })
        getBageFamilyById({ id: e })
        filterSearch(null)
    }

    const handleBadgeSearch = (event) => {
        if (event?.target?.value) {
            onSearch({ title_Icontains: event?.target?.value, behaviouralDescription_Icontains: event?.target?.value, badgeFamily: filter_value })
        }
        else {
            onSearch({ title_Icontains: "", behaviouralDescription_Icontains: "", badgeFamily: filter_value })
        }
    }

    const badgeSelectHandler = (node) => {
        setSelectedBadge(node)
    }

    const searchEmployes = (value) => {
        if (value) {
            let valueList = allEmployeeList?.filter(({ node }) => node?.user?.firstName.toLowerCase()?.includes(value?.toLowerCase()))
            setAllEmployeeList(valueList)
        }
        else {
            setAllEmployeeList(employeeList)
        }
    }

    const handleSubmit = async () => {
        if (detail && selectedEmployee && selected_notify) {
            try {
                let badgeAwardData = {
                    recipientId: getIntFromString(selectedEmployee),
                    badgeId: getIntFromString(selectedBadge?.id),
                    notify: selected_notify,
                    evidence: detail
                }
                const res = await createBadgeAward({ badgeAwardData })
                if (res) {
                    message.success("Kudos Award Created Successfully")
                    onCancel()
                    setSelectedEmployee(null)
                    setSelectedBadge(null)
                    setdetail(null)
                    setSelectedNotify(null)
                    setFilterValue(null)
                }
                else {
                    message.error("Faild to create Kudos")
                }
            } catch (error) {
            }


        }
    }

    const filter_list = badge_family_list &&
        badge_family_list?.map(({ node }, key) => (
            <Option
                key={key}
                value={node?.id}
            >
                {node?.title}
            </Option>
        ))

    const filterSearch = (value) => {
        if (value) {
            setSelectedBadge(null)
            getbadgeFailyList({ title_Icontains: value })
        }
        else {
            getbadgeFailyList({ title_Icontains: "" })
        }

    }

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        arrows: true,
        nextArrow: <img src={right_arrow} alt='' />,
        // eslint-disable-next-line jsx-a11y/alt-text
        prevArrow: <img src={left_arrow} />,
        slidesToShow: 4,
        // slidesToScroll: 4,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 310,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <Modal
            visible={visible}
            destroyOnClose={true}
            footer={false}
            centered={true}
            closable={false}
            width={"90%"}
            style={{ maxWidth: "45em" }}>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: "1em", cursor: "pointer", padding: '.5em 1em', marginBottom: "1em" }}>
                <h4 className="user-board-section-heading">Give Kudos</h4>
                <img src={close_icon} alt="" style={{ cursor: "pointer" }} onClick={() => onCancel()} />
            </div>
            <div className="give-kudos-popup-container-scroll" style={{ width: "100%", display: "flex", flexDirection: "column", gap: "1em" }}>
                <div className="kudos-form-group">
                    <h4 className="kudos-card-user-name"><span style={{ color: " rgba(249, 95, 83, 1)" }}>*</span>Employee Name</h4>
                    <Select
                        className="user-kudos-badge-select"
                        dropdownClassName="kuos-dropdown-popup"
                        showSearch
                        name={'employee'}
                        id={'employee'}
                        placeholder="Select Employee"
                        suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                        style={{ width: '100%', maxWidth: "100%", backgroundColor: "#fff", marginTop: "1em" }}
                        {...props}
                        onSelect={handleSelectEmployee}
                        onSearch={searchEmployes}
                        filterOption={(input, option) => option}
                        disabled={false}
                    >
                        {
                            allEmployeeList?.length > 0 && allEmployeeList?.map(({ node }) => (
                                <Option
                                    className='kudos-card-filter-text'
                                    key={node?.id}
                                    value={node?.id}
                                >
                                    <span className="text-transform-capitalize">{node?.user?.firstName}</span> <span className="text-transform-capitalize">{node?.user?.lastName}</span>
                                </Option>
                            ))
                        }
                    </Select>
                </div>
                <div className="kudos-form-group" style={{ marginTop: "1em" }}>
                    <h4 className="kudos-card-user-name"><span style={{ color: " rgba(249, 95, 83, 1)", marginTop: "1em" }}>*</span>Kudos Family</h4>
                    <div style={{ display: "flex", flexDirection: "column", border: "1px solid rgba(170, 186, 198, 1)", borderRadius: '8px', width: "100%", padding: "2px 0px", marginTop: "1em" }}>
                        <div className="dashboard-user-badge-award-popup-input-div" style={{ display: 'flex', flexDirection: "row", width: "100%" }}>
                            <Select
                                showSearch
                                className="dasboard-filter-select-user-select"
                                name={'filter'}
                                id={'filter'}
                                placeholder={`Select`}
                                suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                                style={{ width: '100%', maxWidth: "15em", textOverflow: 'ellipsis', whiteSpace: "nowrap" }}
                                {...props}
                                onSelect={handleFamilyFilter}
                                onSearch={filterSearch}
                                allowClear
                                onClear={() => { onFilter() }}
                                filterOption={(input, option) => option}
                                disabled={false}
                            >
                                {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}

                                {filter_list}

                            </Select>
                            <div className="dashboard-search-input-user-kudos" style={{ display: 'flex', flexDirection: 'row', width: "100%", border: "none", paddingRight: "10px", borderLeft: "1px solid rgba(170, 186, 198, 1)", marginLeft: "10px" }}>
                                <img src={search_icon} alt="" style={{ cursor: 'pointer', transform: " rotate(-270deg)", height: '20px', marginTop: "10px", marginRight: '7px', marginLeft: '7px' }} />
                                <Input className="kudos-search-imput-popup" placeholder="Search kudos name here" autoComplete='off' style={{ width: "100%", border: "none !important" }} onChange={(e) => handleBadgeSearch(e)}></Input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slider-container new-kudos-badge-award-carosul-container">
                    <Spin spinning={loading}>
                        {loading && <div className="dashboard-user-award-badge-image-selected-container" style={{ width: "100%", minWidth: "42em",background:"transparent" }}></div>}
                        {badgeList?.length > 0 && !selectedBadge && !loading && <Slider {...settings} style={{ width: "100%", maxWidth: "42em" }}>
                            {badgeList?.length && badgeList?.map(({ node }, index) => (
                                <div key={index} className={`dashboard-user-award-badge-image-selected-container ${node?.id === selectedBadge?.id ? "dashboard-user-award-badge-image-selected-container-selected" : ""}`}
                                    onClick={() => badgeSelectHandler(node)} >
                                    <div style={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: "center", flexDirection: "column" }}>
                                        <img className="dashboard-user-award-badge-image-selected" src={node?.image} alt={`Slide ${index}`} />
                                    </div>
                                    <h4 className="dashboard-user-award-badge-image-selected-title">{node?.title}</h4>
                                    <div key={index} className="dashboard-user-award-badge-image-selected-detail dashboard-show-kudos-detail-card" style={{ textOverflow: "ellipsis", textAlign: "center" }}>
                                        <h4 style={{margin:"0px",textAlign:"left"}}>{node?.title}</h4>
                                        <p style={{margin:"0px"}}>{node?.behaviouralDescription}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>}
                        {selectedBadge && <div className="dashboard-selected-kudos-card" style={{ position: "relative", width: "100%", border: "none" }}>
                            <img src={close_icon} alt="" style={{ cursor: "pointer", position: "absolute", right: "10px", top: '10px' }} onClick={() => badgeSelectHandler()} />
                            <div>
                                <img style={{ height: "em", width: "6em" }} src={selectedBadge?.image} alt="" />
                            </div>
                            <div style={{ borderleft: "1px soild #AABAC633", marginLeft: ".5em", paddingLeft: "1em" }}>
                                <h4 style={{ fontFamily: "Poppins", fontSize: "1em", fontWeight: "600", color: "#4CCB1F" }}>{selectedBadge?.title}</h4>
                                <p className="dashboard-details-elipsis" style={{ fontFamily: "Poppins", fontSize: ".85em", fontWeight: "400", color: "#303030" }}>{selectedBadge?.behaviouralDescription}</p>
                            </div>
                        </div>}
                        {badgeList?.length === 0 && loading === false &&
                            <div style={{ minWidth: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <h4 className="recived-award-image-title" style={{ margin: 'auto', justifyContent: 'center', textAlign: "center" }}>No Kudos Found</h4>
                            </div>
                        }
                    </Spin>
                </div>
                <div className="kudos-form-group" style={{ marginTop: "2em" }}>
                    <h4 className="kudos-card-user-name"><span style={{ color: " rgba(249, 95, 83, 1)" }}>*</span>Choose Who you want to notify </h4>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: 'center', gap: "3em", marginTop: "1em", width: "100%", flexWrap: "wrap" }}>
                        {notify_types?.map(item => (
                            <div style={{ display: 'flex', flexDirection: "row", gap: "1em", justifyContent: "start", alignItems: "center", cursor: "pointer" }} onClick={() => { setSelectedNotify(item?.value) }}>
                                {selected_notify === item?.value ? <img src={Radio_active} alt="" /> : <img src={Radio_disabled} alt="" />}
                                <h4 className="kudos-card-user-name">{item?.label}</h4>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="kudos-form-group" style={{ marginTop: "1em", position: "relative" }}>
                    <h4 className="kudos-card-user-name"><span style={{ color: " rgba(249, 95, 83, 1)" }}>*</span>Rational</h4>
                    <textarea style={{ width: "100%", height: "150px", backgroundColor: "#fff", border: "1px solid rgba(170, 186, 198, 1)", marginTop: '1em' }} placeholder="Rationale" name="description" id="" className="kudos-description"
                        value={detail?.length ? detail : ''} maxLength={300} onChange={(e) => { setdetail(e?.target?.value) }}></textarea>
                    {detail?.length > 0 && <h4 className="kudos-card-user-name" style={{ fontSize: ".75em", fontWeight: "400", position: "absolute", bottom: "30px", right: "5px" }}>{detail?.length}/300</h4>}
                    <h4 className="kudos-card-user-name" style={{ color: "rgba(137, 152, 162, 1)", textAlign: "right", fontWeight: "400" }}>300 characters</h4>
                </div>
                <div style={{ width: "100%", marginBottom: "15px", display: 'flex', flexDirection: "row", justifyContent: 'flex-start', alignItems: "center", gap: '1em', marginTop: "2em" }}>
                    <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', width: "100px" }}
                        disabled={!selectedBadge || !selectedEmployee || !selected_notify || !detail} onClick={() => handleSubmit()}>
                        Save
                    </Button>
                    <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', color: "#8998A2", border: '1px solid #8998A2', width: "100px", background: "#fff" }} onClick={() => onCancel(false)}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default GiveKudosNewPopupView;
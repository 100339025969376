import React, { useState, useEffect } from 'react';
import { Row, Col, Spin,Button,Modal  } from 'antd';

import { getIntFromString,ReactMarkdown } from '../../look';
import { RenderFormSection } from '../../tool/components/generic-tool';
import left_arrow from '../../assets/bulb-icon.svg'
import close_arrow from '../../assets/close-arrow.svg'

const SideToolFeedbackView = props => {
  const { loading, getToolById, admin = false, refetch} = props;
  
  const [items, setItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [widthOfContainer, setWidthOfContainer] = useState(undefined)
  // eslint-disable-next-line no-unused-vars
  const [containerBorder, setContainerBorder] = useState(undefined)
  const [sideAction,setSideAction]=React.useState(false)
  useEffect(() => {
    if (getToolById)
      setItems([
        ...(getToolById?.textsection?.edges?.map(({ node }) => ({ ...node, type: 'textsection' })) || []),
        ...(getToolById?.formsection?.edges?.map(({ node }) => ({ ...node, type: 'formsection' })) || [])
      ]);
  }, [getToolById]);

  useEffect(() => {
    setWidthOfContainer(props.widthOfContainer || 20)
    setContainerBorder(props.containerBorder === undefined ? '1px solid #EBEBEB' : props.containerBorder)
  }, [props]);

  const RenderTextSection = ({ textSection, idx }) => {
    return (
      <Row justify='center' align='middle' style={{flexDirection:'column',gap:'10px'}}>
            <Col span={24}>
            {/* Dont't show divider in back to back textsections */}
            {/* {props?.hideTopDivider && idx===0?'':
            <div>{items[idx - 1]?.type !== 'textsection' && <Divider />}</div>
            } */}
            {/* <ReactMarkdown>{textSection.text}</ReactMarkdown> */}
            <ReactMarkdown>{textSection.renderedText || '<p></p>'}</ReactMarkdown>

            {/* Dont't show divider in back to back textsections */}
            {/* {items[idx + 1]?.type !== 'textsection' && <Divider />} */}
            </Col>
        </Row>
    );
  };

  return (
    <div >
    <Button className='side_tool_views' style={{background: "#009AF1",position:"fixed",right:"0",top:'94px',border:"none",height:"50px",width:"50px",borderTopLeftRadius:"6px",borderBottomLeftRadius:"6px",cursor:"pointer",zIndex:1000}} onClick={()=>setSideAction(true)}><img src={left_arrow} className="bulb-icon" alt="" height='25px'/></Button>
    {sideAction&&(
       <Modal 
       className='side-modal custom-side-modal'
       visible={sideAction}
       closable={false}
       footer={false}
        style={{position:"fixed",right:"-20px",top:'130px',maxWidth:"500px",zIndex:1200}}>
           <Button className='custom-close-btn' style={{background: "#009AF1",position:"fixed",right:"460px",top:'170px',border:"none",height:"40px",width:"40px",borderRadius:"6px"}} onClick={()=>setSideAction(false)}><img src={close_arrow} alt="" height='20px'/></Button>
           <Spin spinning={loading} size="large">
               <div>
               {!loading && (
                   <Row style={{ marginTop:'10px' }} justify="center">
                       {items
                       .sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
                       .map((section, idx) =>
                           section.type === 'textsection' ? (
                           <Col span={23} align="left">
                               <RenderTextSection customClass={'custom-text-render'} textSection={section} idx={idx} />
                           </Col>
                           ) : (
                           <Col span={24} style={{ backgroundColor: (2 * idx + 1) % 2 !== 0 && '#8080801c', paddingTop: '10px' }}>
                               <Row justify="center">
                               <Col span={23} align="left">
                                   <RenderFormSection
                                   admin={admin}
                                   formSectionId={getIntFromString(section.id)}
                                   formSection={section}
                                   refetch={refetch}
                                   />
                               </Col>
                               </Row>
                           </Col>
                           )
                       )}
                   </Row>
               )}
           </div>
           </Spin>
       </Modal>
    )

    }
   </div>
  );
};

export default SideToolFeedbackView;

import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import GraphAdvanceFilter from "../components/graph-filter";
import { ALL_EMPLOYEE_LEVEL, ALL_MEMBER_LIST, ALL_ROLE_QUERY, ALL_VERTICAL_AND_TEAM } from "../graphql/ca-graph-filter-queries.gql";


const GraphAdvanceFilterContainer = ( props ) => {
    const { permission,client } = props
   const [filter_options,SetFilterOptions] = React.useState()
   const [role,SetRole] = React.useState()
   const [employe_level,SetEmployeeLevel] = React.useState()
   const [members,SetMembers] = React.useState()
   const [vertical,SetVerticals] = React.useState()
   const [teams,SetTeams] = React.useState()

   React.useEffect(()=>{
    if(permission?.required_permission){
        getVerticalAndTeam()
        getEmployeeLevel()
        getMember()
        getRole()
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[permission])

   React.useEffect(()=>{
    let filter_options={}
    if(role?.length){
        filter_options['role']=role
    }
    if(employe_level?.length){
        filter_options['employe_level']=employe_level
    }
    if(members?.length){
        filter_options['members']=members
    }
    if(vertical?.length){
        filter_options['vertical']=vertical
    }
    if(teams?.length){
        filter_options['teams']=teams
    }
    if(Object.keys(filter_options)?.length){
        SetFilterOptions(filter_options)
    }else{
        SetFilterOptions(null)
    }
   },[role,employe_level,members,vertical,teams])

   const getVerticalAndTeam = async()=>{
    const { data } = await client.query( {
        query: ALL_VERTICAL_AND_TEAM,
        fetchPolicy: 'network-only'
    } );
    if(data?.me){
        let org = data?.me?.employee?.organizationSet?.edges[0]?.node
        if(org){
            let verticals = org?.verticalSet?.edges?.map(({node})=>node)
            let teams = verticals?.map(vertical=>vertical?.teamSet?.edges?.map(({node})=>node))
            teams = [].concat.apply([],teams)
            SetVerticals(verticals)
            SetTeams(teams)
        }
    }
   }

   const getEmployeeLevel = async()=>{
    const { data } = await client.query( {
        query: ALL_EMPLOYEE_LEVEL,
        fetchPolicy: 'network-only'
    } );
    if(data?.allEmployeeLevels){
        SetEmployeeLevel(data?.allEmployeeLevels?.edges?.map(({node})=>node))
    }
   }

   const getMember = async()=>{
    const { data } = await client.query( {
        query: ALL_MEMBER_LIST,
        fetchPolicy: 'network-only'
    } );

    if(data?.members){
        SetMembers(data?.members?.edges?.map(({node})=>node))
    }
   }

   const getRole = async()=>{
    const { data } = await client.query( {
        query: ALL_ROLE_QUERY,
        fetchPolicy: 'network-only'
    } );
    if(data?.allRoles){
        SetRole(data?.allRoles?.edges?.map(({node})=>node)) 
    }
   }

    return (
        <>
            <GraphAdvanceFilter
                {...props}
                filter_options={filter_options}
            />
        </>
    )

}

export default compose( withApollo )( GraphAdvanceFilterContainer )
import React from 'react';
import back_arrow from '../../assets/soul-back.svg'
import {  useHistory } from "react-router-dom";	
// import detail_arrow from '../../assets/detail-back-button.svg'
const BackButton =(props)=>{
const history = useHistory()
const {path,detailArrow}=props

const back=()=>{
    if(path){
        history.push(path)
    }
    else{
        if(history?.length > 2){
            history.goBack()
        }else{
            window.close();
        }
    }
}
return (
    <>
    {!detailArrow&&<img className='back-btn' src={back_arrow} style={{cursor:"pointer"}} onClick={()=>{back()}} alt="" />}
    {detailArrow&&<img className='back-btn' src={back_arrow} style={{cursor:"pointer"}} onClick={()=>{back()}} alt="" />}
    </>
)
}
export default BackButton
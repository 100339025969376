import React from 'react';
import '../css/profile.css'
import UserOKRStatusContainer from '../containers/userOKRStatusContainer';
import UserSurveyStatusContainer from '../containers/userSurveyStatus.container';
import UserImpactNarrativeContainer from '../containers/UserImpactNarrativeContainer';
import UserKudosContainer from '../containers/UserKudosContainer';
import UserCompetencyAssessmentContainer from '../containers/UserCompetencyAssessmentContainer';
import { Modal, Spin, Tooltip } from 'antd';
import { profile_icon } from '../dashboard-icons';
import RoleDefinitionConfirmation from 'modules/look-v2/Components/ConfirmationDialog';
import { MetaTags } from 'modules/look';

const ProfileDetail = (props) => {
    const { profile_detail, team_detail, manageEmployee, navigateRoute,permission,location } = props
    const [remove_user_popup, SetRemoveUserPopup] = React.useState(false)
    const tab = [
        {
            key: 'okr_status',
            label: 'OKR Status',
            permission:permission?.okr_permission
        },
        {
            key: 'survey_status',
            label: 'Survey status',
            permission:permission?.survey_permission
        },
        {
            key: 'impact_narrative',
            label: 'Impact Narrative ',
            permission:permission?.impact_narrative_permission
        },
        {
            key: 'competency_report',
            label: 'Competency Report',
            permission:permission?.competency_assessment_permission
        },
        {
            key: 'kudos',
            label: 'Kudos',
            permission:permission?.award_badge_permission
        }
    ]

    const [current_tab, SetCurrentTab] = React.useState()

    React.useEffect(()=>{
        if(tab?.length){
            let current_tab = tab?.find(i=>i?.permission)
            SetCurrentTab(current_tab)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useEffect(()=>{
        if(location){
            let search = location.search.replace('?','')
            if(search==="kudos"){
                SetCurrentTab(
                    {
                        key: 'kudos',
                        label: 'Kudos',
                        permission:permission?.award_badge_permission
                    }
                )
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location])

    const content = {
        okr_status: UserOKRStatusContainer,
        survey_status: UserSurveyStatusContainer,
        impact_narrative: UserImpactNarrativeContainer,
        kudos: UserKudosContainer,
        competency_report: UserCompetencyAssessmentContainer
    }



    const CurrentView = content[current_tab?.key]
    return (
        <>
            <MetaTags title="Employee profile" description="Employee profile" />    
            {profile_detail ?
                <div className='global-root-em profile-detail-layout'>
                    <div className='page-title-wrapper'>
                        <img src={profile_icon?.back_arrow} onClick={() => { navigateRoute("-1") }} alt="back" />
                        <h4>Employee Detail</h4>
                    </div>
                    <div className='profile-card row-wrapper media-col-wrapper' style={{ padding: "2em 2.5em" }}>

                        <div className='user-profile-detail'>
                            <div className='pro-data-wrapper'>
                                <img src={profile_detail?.user?.profile?.profileImage || profile_icon.profile} alt="profile" />
                                <div>
                                    <h4 className='profile-name text-transform-capitalize'>{profile_detail?.user?.firstName}</h4>
                                    <p className='role-name'>{profile_detail?.role?.title || 'No role assigned'}</p>
                                </div>
                            </div>
                            {
                                (team_detail && team_detail?.employees?.includes(profile_detail?.employee_id)) && (
                                    <div className='ipad-view'>
                                        <Tooltip title="Remove Employee">
                                        <img src={profile_icon?.user_remove} alt="icon" onClick={() => { SetRemoveUserPopup(true) }} style={{ width: "1.5em",height:"1.5em", cursor: "pointer" }} />
                                        </Tooltip>
                                    </div>
                                    
                                )
                            }
                        </div>
                        <div className='user-surevey-detail'>

                            <div className='col-wrapper'>
                                <div>
                                    <p className='info-label-title'>Personality Type</p>
                                    <h4 className='info-label-content'>{profile_detail?.personality || '-'}</h4>
                                </div>
                                <div>
                                    <p className='info-label-title'>Job Family</p>
                                    <h4 className='info-label-content'>{profile_detail?.job_family?.title || '-'}</h4>
                                </div>
                            </div>

                            <div className='col-wrapper'>
                                <div>
                                    <p className='info-label-title'>Work Experience</p>
                                    <h4 className='info-label-content'>{profile_detail?.experinece>=0?`${profile_detail?.experinece} ${profile_detail?.experinece>1?'years':'year'}`:'-'} </h4>
                                </div>
                                <div>
                                    <p className='info-label-title'>Employee Level</p>
                                    <h4 className='info-label-content'>{profile_detail?.role?.employeeLevel?.name || '-'}</h4>
                                </div>
                            </div>

                            <div className='col-wrapper'>
                                <div>
                                    <p className='info-label-title'>Top Values</p>
                                    <div className='row-wrapper content-wrap' style={{ gap: "1em" }}>
                                        {
                                            profile_detail?.value?.map((i, index) => (
                                                <h4 className='info-label-content' style={{ color: "#3B8855" }}>{index + 1} {i?.answer}</h4>
                                            ))
                                        }
                                        {
                                            !profile_detail?.value?.length ?
                                                <h4 className='info-label-content' style={{ color: "#3B8855" }}>Survey not taken</h4> : ''
                                        }
                                    </div>
                                </div>
                                <div>
                                    <p className='info-label-title'>Top Strengths </p>
                                    <div className='row-wrapper content-wrap' style={{ gap: "1em" }}>
                                        {
                                            profile_detail?.strength?.map((i, index) => (
                                                <h4 className='info-label-content' style={{ color: "#3B8855" }}>{index + 1} {i?.answer}</h4>
                                            ))
                                        }
                                        {
                                            !profile_detail?.strength?.length ?
                                                <h4 className='info-label-content' style={{ color: "#3B8855" }}>Survey not taken</h4> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            (team_detail && team_detail?.employees?.includes(profile_detail?.employee_id)) && (
                                <div className='web-view'>
                                     <Tooltip title="Remove Employee">
                                    <img src={profile_icon?.user_remove} alt="icon" onClick={() => { SetRemoveUserPopup(true) }} style={{ width: "1.5em", cursor: "pointer" }} />
                                    </Tooltip>
                                </div>
                            )
                        }
                    </div>

                    <div className='profile-card' style={{ marginTop: "1.5em" }}>
                        <div className='profile-tab-view'>
                            {
                                tab?.filter(i=>i?.permission)?.map(tab => (

                                    <div className={`profile-tab-normal ${(current_tab?.key === tab?.key || (current_tab?.key?.includes(tab?.key))) ? 'profile-tab-active' : ''}`} onClick={() => SetCurrentTab(tab)}>
                                        <h4 className={`profile-tab-label ${(current_tab?.key === tab?.key || (current_tab?.key?.includes(tab?.key))) ? 'profile-tab-active-label' : ''}`}>{tab?.label}</h4>
                                    </div>
                                ))
                            }
                            <div className='border-line'>

                            </div>
                        </div>
                        <div className='user-content-container'>

                            {
                                content[current_tab?.key] && (

                                    <CurrentView current_tab={current_tab} profile_detail={profile_detail} {...props} />
                                )
                            }
                        </div>
                    </div>
                </div> :
                <div align="center" style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin spinning={true} size='large'></Spin>
                </div>}

            <Modal
                visible={(remove_user_popup && team_detail)}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal'
            >
                <RoleDefinitionConfirmation
                    main_warning="Are you sure ?"
                    cancel_action_label="No"
                    confirm_action_label="Yes"
                    message={`Do you want to remove this employee from your team ?`}
                    onCancel={() => { SetRemoveUserPopup(false) }}
                    onConfirm={() => { manageEmployee(); SetRemoveUserPopup(false) }}
                />
            </Modal>
        </>
    )
}

export default ProfileDetail
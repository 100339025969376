/* eslint-disable array-callback-return */
import React, { useState} from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { Space, Col, Row, Empty, Button } from 'antd';
import { CaretRightOutlined, CheckOutlined } from '@ant-design/icons';
import {
  ReactMarkdown,
  RadioField,
  MLCheckBoxField,
  TextField,
  MLSelectField,
  RangeField,
  SelectField,
  displayDataCheck,
  ColorField,
  InputValuesObject,
  DatePickerField,
  RenderUpload,
  interpolate,
  getIntFromString
} from '../../look';
import { withApollo } from '@apollo/client/react/hoc';
import ProgressCounterBtn from './ProgressCounterBtn';
import ProgressCounterBtnNative from './ProgressCounterBtnNative';
import { compose } from 'modules/core';
import { FILTER_SURVEY_CHOICE } from '../../diversity-survey/graphql/filterSurveyChoice.gql'
export const TIME_LIMIT = 5;

const emailRules = { type: 'email', message: 'Not a vaild email' };

export function getTypeFromQuestion(question) {
  return question?.choiceSet?.edges[0]?.node?.inputType;
}

const SectionComponent = props => {
  const {
    questionList,
    matrix,
    sectionLabel,
    form,
    nextStep,
    srNo,
    timeoutFunc,
    closetimer,
    currentIsMatrix,
    currentSection,
    showOk,
    setShowOk,
    currentMatrixQuestion,
    survey,
    client
  } = props;

  const values = form.getFieldsValue(true);
  const getRangeValues = (choiceSet, type) => {
    let defaultValue = 0,
      marks = {},
      step = 1;
    const value = choiceSet[0]?.value?.split(':') || [];
    // min:max:jump:default
    if (type === 'RANGE') {
      marks[value[0]] = value[0];
      marks[value[1]] = value[1];
      step = value[2] === '0' ? '1' : value[2];
      defaultValue = value[3];
    }
    return { step, marks, defaultValue, min: Number(value[0]), max: Number(value[1]) };
  };

  const RenderQuestion = (question, index) => {
    const [colorLoading, setColorLoading] = React.useState(false);
    const [load, setLoad] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const { id, sequence, questionText, required, helpText } = question;
    const [ choiceSet, setchoiceSet ] = React.useState(question?.choiceSet)
    const type = getTypeFromQuestion(question);
    const [reset, setReset] = useState(false);
    const obj = {};
    const keyArray = Object.keys(values);
    keyArray?.length > 0 &&
      keyArray.map(key => {
        obj[getIntFromString(key)] = values[key];
      });

      const onSelectFeildSearch=async (search,que)=>{
        let id = survey?.id;
        const { data } = await client.query({
            query: FILTER_SURVEY_CHOICE,
            variables: { id: id,label_Icontains: search},
            // fetchPolicy: 'network-only'
        });
        if(data?.Options){
          let qa = data?.Options.groupSet?.edges.map(item=>item?.node?.questionSet?.edges)
          qa = [].concat.apply([],qa) 
          let current =qa.find(item=>item?.node?.id===que?.id)
          if(current){
            setchoiceSet(current?.node?.choiceSet)
          }
        }
    }

    const label = (
      <Space align="baseline" >
        <SequenceNo>{srNo}</SequenceNo>
        <ReactMarkdown style={{ marginLeft: '4px' }}>{interpolate(questionText?.replace('{', '${'), obj)}</ReactMarkdown>
      </Space>
    );

    const renderLabel = (
      <div>
        {label}
        <ReactMarkdown style={{ marginLeft: '3vw', marginRight: '3vw' }}>{helpText}</ReactMarkdown>
      </div>
    );

    const rules = [{ required: true, message: 'An answer is required.' }];

    const { step, marks, defaultValue, min, max } = getRangeValues(
      choiceSet?.edges?.map(({ node }) => node),
      type
    );

    const handleChange = doNext => {
      if (doNext) {
        if (showOk) {
          setShowOk(false);
          setShowOk(true);
          setReset(true);
          closetimer();
        } else {
          setShowOk(true);
          timeoutFunc(srNo);
        }
      } else {
        setShowOk(true);
      }
    };

    return true ? (
      <Layout key={index} visible={currentSection === srNo}>
        {currentSection === srNo && (
          <ChildLayout>
            <div style={{ margin: 'auto 0' }}>
              {type === InputValuesObject.checkbox && (
                <MLCheckBoxField
                  name={id}
                  label={renderLabel}
                  rules={required ? rules : []}
                  choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                  onChange={e => e && handleChange(false)}
                />
              )}
              {type === InputValuesObject.color && !colorLoading && (
                <ColorField
                  name={id}
                  label={renderLabel}
                  rules={required ? rules : []}
                  choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                  onChange={e => {
                    setColorLoading(true);
                    e && form.setFieldsValue(_.set(form.getFieldsValue(true), [id], e)) && setShowOk(true);
                    setColorLoading(false);
                  }}
                  value={form.getFieldValue(id)}
                />
              )}
              {type === InputValuesObject.date && (
                <DatePickerField
                  margin={false}
                  name={id}
                  label={label}
                  offset={3}
                  format="YYYY-MM-DD"
                  rules={[{ required, message: 'Please select a date' }]}
                  enabledisabledDate={false}
                  onChange={e => e && handleChange(true)}
                />
              )}
              {type === InputValuesObject.datetime && (
                <DatePickerField
                  margin={false}
                  name={id}
                  label={label}
                  offset={3}
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                  rules={[{ required, message: 'Please select a date and time' }]}
                  enabledisabledDate={false}
                  onChange={e => e && handleChange(true)}
                // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                />
              )}
              {type === InputValuesObject.month && (
                <DatePickerField
                  margin={false}
                  name={id}
                  label={label}
                  offset={3}
                  rules={[{ required, message: 'Please select a month' }]}
                  enabledisabledDate={false}
                  picker="month"
                  onChange={e => e && handleChange(true)}
                // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                />
              )}
              {type === InputValuesObject.time && (
                <DatePickerField
                  margin={false}
                  name={id}
                  label={label}
                  offset={3}
                  rules={[{ required, message: 'Please select a month' }]}
                  enabledisabledDate={false}
                  type="time"
                  onChange={e => e && handleChange(true)}
                // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                />
              )}
              {type === InputValuesObject.week && (
                <DatePickerField
                  margin={false}
                  name={id}
                  label={label}
                  offset={3}
                  rules={[{ required, message: 'Please select a month' }]}
                  enabledisabledDate={false}
                  picker="week"
                  onChange={e => e && handleChange(true)}
                // defaultValue={moment('2015-06-06', 'YYY-MM-DD')}
                />
              )}
              {type === InputValuesObject.radio && (
                <RadioField
                  name={id}
                  label={renderLabel}
                  choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                  rules={required ? rules : []}
                  onChange={e => e?.target?.value && handleChange(true)}
                />
              )}
              {type === InputValuesObject.range && (
                <RangeField
                  name={id}
                  label={
                    <div style={{ minWidth: '25vw', maxWidth: 'calc(100% - 30px)' }}>
                      {label}
                      <ReactMarkdown style={{ marginLeft: '3vw' }}>{helpText}</ReactMarkdown>
                    </div>
                  }
                  rules={required ? rules : []}
                  marks={marks}
                  step={step}
                  defaultValue={defaultValue}
                  min={min}
                  max={max}
                  trackStyle={{ backgroundColor: '#B0CB1F' }}
                  handleStyle={{ backgroundColor: '#B0CB1F' }}
                  onAfterChange={e => e && handleChange(true)}
                />
              )}
              {type === InputValuesObject.email && (
                <TextField
                  name={id}
                  label={renderLabel}
                  rules={required ? rules.map(r => ({ ...r, ...emailRules })) : [{ ...emailRules }]}
                  // rules={[{ type: 'email' }]}
                  offset={2}
                  // onChange={e => e.target.value && handleChange(false)}
                  onBlur={e => e?.target?.value && handleChange(false)}
                />
              )}
              {type === InputValuesObject.file && (
                <RenderUpload
                  setUrl={e => form.setFieldsValue(_.set(form.getFieldsValue(true), id, e))}
                  setLoad={setLoad}
                  offset={2}
                  name={id}
                  label={renderLabel}
                />
              )}
              <Row>
                <Col lg={24} xs={20}>
                  {type === InputValuesObject.text && (
                    <TextField
                      name={id}
                      label={renderLabel}
                      rules={required ? rules : []}
                      offset={2}
                      onBlur={e => e?.target?.value && handleChange(false)}
                    />
                  )}
                </Col>
              </Row>
              {type === InputValuesObject.number && (
                <TextField
                  name={id}
                  label={renderLabel}
                  rules={required ? rules : []}
                  type="number"
                  offset={2}
                  // onChange={e => e && handleChange(false)}
                  onBlur={e => e?.target?.value && handleChange(false)}
                />
              )}
              {type === InputValuesObject.select && (
                <Row>
                  <Col lg={24} xs={20}>
                    <SelectField
                      showSearch
                      onSearch={(e)=>{onSelectFeildSearch(e,question)}}
                      otherOption={true}
                      name={id}
                      offset={2}
                      label={renderLabel}
                      rules={required ? rules : []}
                      choices={choiceSet.edges.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                      onSelectChange={e => e && handleChange(false)}
                      onBlur={e => e?.target?.value && handleChange(false)}
                    />
                  </Col>
                </Row>
              )}
              {showOk && (
                <>
                  <Col lg={24} md={0} xs={0}>
                    <ProgressCounterBtn
                      idIndex={srNo}
                      timeLimit={TIME_LIMIT - 2}
                      timer={
                        type !== InputValuesObject.checkbox ||
                        type !== InputValuesObject.text ||
                        type !== InputValuesObject.range
                      }
                      reset={reset}
                      setReset={setReset}
                      btn={props => (
                        <Button type="primary" disabled={load} onClick={e => nextStep(srNo)} {...props}>
                          OK
                          <CheckOutlined />
                        </Button>
                      )}
                    />
                  </Col>
                  <Col lg={0} md={24} xs={24}>
                    <ProgressCounterBtnNative
                      idIndex={srNo}
                      timeLimit={TIME_LIMIT - 2}
                      timer={
                        type !== InputValuesObject.checkbox ||
                        type !== InputValuesObject.text ||
                        type !== InputValuesObject.range
                      }
                      reset={reset}
                      setReset={setReset}
                      btn={props => (
                        <Button type="primary" disabled={load} onClick={e => nextStep(srNo)} {...props}>
                          OK
                          <CheckOutlined />
                        </Button>
                      )}
                    />
                  </Col>
                </>
              )}
            </div>
          </ChildLayout>
        )}
      </Layout>
    ) : null;
  };

  return matrix && matrix ? (
    <MatrixDiv currentIsMatrix={currentSection === srNo && currentIsMatrix}>
      <Space align="baseline">
        <SequenceNo>{srNo}</SequenceNo>
        <h3 style={{ fontWeight: 'bold' }}>{displayDataCheck(sectionLabel)}</h3>
      </Space>
      <Row style={{ paddingBottom: '2vh', paddingTop: '2vh' }}>
        <Col lg={8} md={8} xs={0}></Col>
        <Col lg={16} md={16} xs={24}>
          <Row>
            {questionList[1]?.choiceSet?.edges?.map(({ node: choice }, key) => (
              <Col span={6} align="center">
                {choice?.label}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row style={{ backgroundColor: 'rgba(202, 202, 202, 0.05)', marginBottom: '2vh' }}>
        {currentIsMatrix && questionList?.length > 0 ? (
          questionList
            ?.sort(function (a, b) {
              return parseInt(a.id) - parseInt(b.id);
            })
            .map((question, index) => {
              const { id, questionText, choiceSet, required } = question;
              const rules = [{ required: true, message: 'An answer is required.' }];
              return (
                <Col lg={{ span: 23, offset: 1 }} md={{ span: 23, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                  {currentSection === srNo && (
                    <MLSelectField
                      otherOption={true}
                      name={id}
                      label={
                        <Space>
                          {currentMatrixQuestion === index ? (
                            <CaretRightOutlined />
                          ) : (
                            <CaretRightOutlined style={{ opacity: 0 }} />
                          )}
                          {questionText}
                        </Space>
                      }
                      choices={choiceSet?.edges?.map(({ node }) => node).map(c => ({ label: c.label, value: c.value }))}
                      rules={required ? rules : []}
                    />
                  )}
                </Col>
              );
            })
        ) : (
          <br />
        )}
      </Row>
    </MatrixDiv>
  ) : questionList?.length > 0 ? (
    questionList?.map(RenderQuestion)
  ) : (
    <Empty />
  );
};

export default compose(withApollo) (SectionComponent);

const SequenceNo = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  background: rgba(98, 98, 98, 1);
  color: white;
  display: inline-flex;
  margin-top:10px
`;

const MatrixDiv = styled.div`
  height: 75vh; /* ${props => (props.currentIsMatrix ? '75vh' : '60vh')}; */
  padding: 3vh 0;
  overflow-y: auto;
`;

const Layout = styled.div`
  min-height: 75vh;
  padding: 3vh 0;
`;

const ChildLayout = styled.div`
min-height: 40vh;
align-items: center;
display: grid;
`;

import React from 'react';
import { compose } from '../../core';

import { withApollo } from '@apollo/client/react/hoc'
import PastMeetingView from '../components/PastMeetingView';
import { ALL_MEETING } from '../graphql/AllMeeting.gql';

const PastMeetingContainer = props => {
  const { client,me,history,filteritem}=props
  const [meetingList,setMeeting]=React.useState([])
  const [loading, setloading] = React.useState(false);
  const [onFilterList,setOnFilterlist]=React.useState(false)
  const allMeetingList = async (filterData) => {
    if(filterData?.pagination_value){
      setOnFilterlist(true)
    }
    else{
      setOnFilterlist(false)
    }
    setloading(true)
   
    const { data } = await client.query({
        query: ALL_MEETING,
        variables: {...filterData,orderBy:['-endedAt']},
        fetchPolicy: 'network-only'
    });
    if(data?.allMeetings){
      setMeeting(data?.allMeetings)
      setloading(false)
    }
}



React.useEffect(()=>{
  if(me){
    let selected_Data =JSON.parse(localStorage.getItem("one_on_one_filter"))
      if(selected_Data==null){
      allMeetingList({nextMeeting_Isnull:true,endedAt_Isnull:false,offset:0,first:20})
      }
  }
  else if (me===null){
    history.push('/user/login')
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[me])

React.useEffect(()=>{
  if(filteritem){
    if(filteritem?.title_Icontains !== ""){
      allMeetingList({nextMeeting_Isnull:true,endedAt_Isnull:false,...filteritem})
    }
    else{
      allMeetingList({nextMeeting_Isnull:true,endedAt_Isnull:false,...filteritem,offset:0,first:20})
    }
    
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[filteritem])


const PaginantionCall =(value)=>{
  if(value){
    allMeetingList({...filteritem,...value,nextMeeting_Isnull:true,endedAt_Isnull:false,first:20})
  }
  else{
    allMeetingList({nextMeeting_Isnull:true,endedAt_Isnull:false,offset:0,first:20})
  }
}

  return (
    <>
    <PastMeetingView {...props} allMeetings={meetingList} onfilter={(e) => allMeetingList({...e,nextMeeting_Isnull:true,endedAt_Isnull:false})} loading={loading} setloading={(e)=>setloading(e)} onPaginantion={e=>PaginantionCall(e)} onSearchFilter={(filteritem)}onFilterList={onFilterList}></PastMeetingView>
    </>
  );
};

export default compose(withApollo)(PastMeetingContainer);
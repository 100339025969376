/* eslint-disable no-useless-concat */
const baseName = '/story';
const tagBaseName = '/tag';
const commentBaseName = '/comment';

const detailBaseName = '/detail';
const newBaseName = '/new';
const editBaseName = '/edit';

module.exports = {
  story: baseName + baseName + '/admin-panel',
  tag: baseName + tagBaseName + '/admin-panel',
  comment: baseName + commentBaseName + '/admin-panel' + baseName + '/:id',
  commentLink: baseName + commentBaseName + '/admin-panel' + baseName + '/',

  allStories: baseName + '/all-stories',
  storyDetailPreview: baseName + '/preview',
  storyDetail: baseName + detailBaseName + '/:id',
  storyDetailLink: baseName + detailBaseName + '/',
  adminStoryDetail: baseName + detailBaseName + '/admin-panel/' + ':id',
  adminStoryDetailLink: baseName + detailBaseName + '/admin-panel/',

  addStory: baseName + baseName + newBaseName,
  addTag: baseName + tagBaseName + newBaseName,
  // addComment: baseName + commentBaseName + newBaseName + baseName + '/:id',
  // addCommentLink: baseName + commentBaseName + newBaseName + baseName + '/',
  editStory: baseName + baseName + editBaseName + '/:id',
  editStoryLink: baseName + baseName + editBaseName + '/',
  editTag: baseName + tagBaseName + editBaseName + '/:id',
  editTagLink: baseName + tagBaseName + editBaseName + '/'
  // editComment: baseName + commentBaseName + editBaseName + baseName + '/:id',
  // editCommentLink: baseName + commentBaseName + editBaseName + baseName + '/'
};

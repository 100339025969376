import React from 'react';
import { Button } from 'antd'

const SoulSurveySuccessPopup = (props)=>{

    const { message,onCancel,onConfirm,confirm_action_label,cancel_action_label,main_warning } = props

    return (
        <div className='rd-confirmation-dialog'>
     
            {
                main_warning && (
                    <h4 className='rd-confirmation-message' style={{fontSize:"1.5em"}}>{main_warning}</h4>
                )
            }
            <h4 className='rd-confirmation-message'>{message}</h4>
            <div className='rd-confirmation-action-container'>
                <Button className='global-cancel-btn' onClick={()=>{onCancel()}}>{cancel_action_label || 'No'}</Button>
                <Button className='global-submit-btn'  onClick={()=>{onConfirm()}}>{confirm_action_label || 'Yes'}</Button>
            </div>
        </div>
    )

}

export default SoulSurveySuccessPopup
import { Button, Col } from "antd";
import React from "react";
import existing_no_data from '../../assets/existing-no-data.svg'
import { useHistory } from "react-router-dom";
import { compose } from "modules/core";
import { withMe } from "modules/user/containers/UserOperations";
import { withApollo } from '@apollo/client/react/hoc';
import { SEND_LOG_TO_BACKEND } from "modules/look/graphql/LogError.gql";
// import error_banner from '../../assets/error-page-banner.svg'
// import error_ticket_icon from '../../assets/error-page-ticket-icon.svg'
// import support_route from 'modules/ticketing-system/route';

const NoPermissionView = (props) => {
    const { content, client } = props
    const history = useHistory()
    const sendLogToBackend = async (msg) => {
        await client.query({
            query: SEND_LOG_TO_BACKEND,
            variables: { msg: msg },
            fetchPolicy: 'network-only',
        });
    }
    React.useEffect(() => {
        try {
            if (localStorage.hasOwnProperty("routeHistory")) {
                const routeArray = localStorage.routeHistory.split(",")
                const msg = { TYPE: "UI_PERMISSION_ERROR", CURRENT_ROUTE: routeArray[routeArray.length - 1], ROUTE_HISTORY: localStorage.routeHistory }
                sendLogToBackend(JSON.stringify(msg))
                localStorage.removeItem("routeHistory")
            }
        } catch (error) {
            console.error("Error sending log", error)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className="kudos-root-class kudos-existing-no-data">
                <img src={existing_no_data} className="kudos-no-data-img" alt="" />
                <Col align="center" className="kudos-root-class">
                    <h4 className="kuods-not-found">{content ? content : "You don't have permission to view this page"}</h4>
                    <Button onClick={() => { history.goBack() }} className="kudos-go-back-btn">Go Back</Button>
                </Col>
            </div>
             {/* <div className="kudos-root-class kudos-existing-no-data error-boundry-container" style={{background:"#fff",borderRadius:"1em"}}>
                <img className="error-boundry-image" src={error_banner} alt="error_banner"/>
                <h1 className='error-bountry-title-head'>Opps! Something Went Wrong</h1>
                <h4 className="error-permission-warning-text">{content ? content : "You don't have permission to view this page"}</h4>
                <div style={{display:'flex',flexDirection:"row",justifyContent:'center',alignItems:'center',gap:"1em",flexWrap:'wrap'}}>
                    <Button className='error-boundry-btn' onClick={() => { history.goBack() }}><span style={{paddingTop:"5px"}}>Go Back</span></Button>
                    <Button className='error-boundry-btn' style={{position:"relative"}} onClick={()=>{history.push(support_route.create_ticket)}}> <img style={{ position: "absolute", left: '0px', top: "-12px", height: "1.5em", width: '1.5em' }} src={error_ticket_icon} alt="error_ticket_icon" /> <span style={{paddingTop:"5px"}}>Raise Ticket</span></Button>
                </div>
            </div> */}
        </>
    )

}

export default compose(withMe, withApollo)(NoPermissionView)
import React from 'react';
import { Row, Col, Form, Divider } from 'antd';

import { TextField, ButtonsComponent, CheckBoxField, CKEditorField } from '../../look';
import ChoiceFormComponent from './ChoiceFormComponent';

export const CKEditorFieldName = 'helpText';
export const CKEditorFieldName1 = 'questionText';

const QuestionFormComponent = props => {
  const { onSubmit, questionById } = props;
  const [choices, setChoices] = React.useState([]);
  const [button, setButton] = React.useState('');
  React.useEffect(() => {
    if (questionById && questionById.choiceSet) {
      const choice = [];
      questionById.choiceSet.edges.forEach(({ node }) => {
        choice.push({
          id: node.id,
          label: node.label,
          value: node.value,
          sequence: node.sequence,
          inputType: node.inputType.toLowerCase(),
          graphic: node.graphic,
          answerKey: node?.answerKey === null ? false : node?.answerKey,
        });
      });
      setChoices(choice);
    }
  }, [questionById]);

  const initialValue = {
    questionText: questionById && questionById.questionText,
    helpText: questionById && questionById.helpText,
    sequence: questionById && questionById.sequence,
    required: questionById && questionById.required
  };

  const handleSubmit = values => {
    const data = {
      questionText: values.questionText,
      helpText: values.helpText,
      sequence: Number(values.sequence),
      required: Boolean(values.required)
    };
    onSubmit(data, choices, button);
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={initialValue}
          required={false}
          scrollToFirstError={true}
        >
          <Row align="middle">
            <Col span={22} offset={1}>
              <CKEditorField
                name={CKEditorFieldName1}
                label="Name"
                initialValue={questionById && questionById.questionText}
                rules={[{ required: true, message: 'Name is required!' }]}
              />
            </Col>
            <Col span={10} offset={1}>
              <Row>
                <Col lg={14} md={20}>
                  <TextField
                    name="sequence"
                    label="Sequence"
                    type="number"
                    rules={[{ required: true, message: 'Sequence is required!' }]}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={10} md={13} xs={24}>
              <Row align="middle">
                <Col lg={14} md={20}>
                  <CheckBoxField name="required" label="Required" />
                </Col>
              </Row>
            </Col>
            <Col lg={3} md={0} xs={0} />
            <Col span={22} offset={1}>
              <CKEditorField
                name={CKEditorFieldName}
                label="Help Text"
                initialValue={questionById && questionById.helpText}
              />
            </Col>
          </Row>
          {/* <MarkdownField name="helpText" label="Help Text" initialValue={questionById && questionById.helpText} /> */}
          <Divider />
          <ChoiceFormComponent choices={choices} setChoices={setChoices} />
          <ButtonsComponent setButton={setButton} />
        </Form>
      </Col>
    </Row>
  );
};

export default QuestionFormComponent;

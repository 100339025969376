// @ts-nocheck
import React from 'react';
import { Button, Spin, Table, Modal, Tooltip } from "antd";
import styled from 'styled-components';
import readiness_view from '../../assets/rediness-view-icon.svg'
import ReadinessDetailComponent from '../containers/ReadinessDetailComponent'; 

const SelfReadinessAssessmentView = props => {
    const {
        dataSource,
        self_assessment_loader,
        page_info_assessment,
        onFilter,
    } = props;
    const [viewReadiness, setViewReadiness] = React.useState()

    const getStatus = (zone) => {
        return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
            <Tooltip title={zone?.zone}>
                <h4 className={`readiness-zone-btn  ${zone?.color}-readiness-zone`} style={{textTransform:"capitalize"}}>{zone?.color}</h4>
            </Tooltip>
        </div>
    }

    const onViewMore =()=>{
        let filter = {after: page_info_assessment?.after}
        onFilter(filter)
    }

    return (
        <>
   
            <div style={{ marginTop: '20px' }}>
                <Spin spinning={self_assessment_loader}>
                    <div className='rd-table-container'>
                        <table className='rd-table'>
                            <thead>
                                <tr className='rd-row-header'>
                                    <th className='rd-table-row-padding-left rd-left-radious' style={{ width: "33.33%" }}>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Skills</h4>
                                    </th>
                                    <th className='rd-table-header-label' style={{ textAlign: "center", width: "33.33%" }}>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em" }}>Readiness Zone</h4>
                                    </th>
                                    <th className='rd-table-row-padding-rigth rd-content-center rd-table-header-label rd-right-radious' style={{ textAlign: "center", width: "33.33%" }}>
                                        <h4 className='rd-table-header-label' style={{ fontSize: "1.35em", textAlign: "right" }}>Actions</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataSource?.map(item => (
                                        <tr className='rd-row-body'>

                                            <td style={{ width: "33.33%" }} className='rd-table-row-padding-left rd-left-radious table-border-left'>
                                                <h4 className='rd-table-body-label' style={{ fontSize: "1.25em" }}>{item?.zone_detail && item?.zone_detail[0]?.skill?.title}</h4>
                                            </td>
                                            <td style={{ textAlign: "center", width: "33.33%" }} className='table-border-up-down'>
                                                {getStatus(item?.zone_detail?.length ? item?.zone_detail[0]?.zone : { color: "green" })}
                                            </td>
                                            <td className='rd-table-row-padding-rigth rd-content-center rd-right-radious table-border-right' style={{ width: "33.33%" }}>
                                                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "end", alignItems: "center" }}>
                                                    <Button className='readiness-view-btn' style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', gap: ".25em" }}
                                                        onClick={() => setViewReadiness(item)}>
                                                        <img src={readiness_view} alt="readiness_view" style={{ width: "15px" }} /> <span style={{ margin: "0px" }}>View</span>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        {
                            (page_info_assessment && page_info_assessment?.hasNextPage && dataSource?.length && !self_assessment_loader)?
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center",marginTop:"2em" }}>
                                    <h4 onClick={() => onViewMore()} className='pagination-label' style={{ cursor: "pointer" }}>View More</h4>
                                </div>
                            :''
                        }
                        {
                            (!dataSource?.length && !self_assessment_loader) ?
                                <div className='rd-nodata'>
                                    <h4 className='rd-nodata-label'>No data found</h4>
                                </div> : ''
                        }
                    </div>
                </Spin>
            </div>
            <Modal
                visible={viewReadiness}
                footer={false}
                closable={false}
                width={"90%"}
                centered={true}
                destroyOnClose={true}
                className='readiness-modal'>
                <ReadinessDetailComponent {...props} tab="self_assessments" readiness_detail={viewReadiness} onClose={() => setViewReadiness()} />
            </Modal>
            
        </>
    );
};
export default SelfReadinessAssessmentView;

export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
import { Button, Modal, Row } from "antd";
import { getIntFromString, InputValuesObject, interpolateFor3cs, parseMarkDownText, ReactMarkdown } from "modules/look";
import React from "react";
import warning_icon from '../../assets/warning-icon.svg'
import { question_id_for_3cs, SURVEY_ID_3CS } from "../../../config"; 

const SurveyPreview = (props) => {
    const { preview_detail, survey_question_list, onSubmit, RetakeSurvey, survey,action_btn_label } = props
    const [preview_list, SetPreviewList] = React.useState([])
    const [retake_survey_warning, SetRetakeSurveyWarning] = React.useState(false)


    React.useEffect(() => {
        if (preview_detail?.length && survey_question_list?.length) {
            let preview_list = survey_question_list?.map(q => {
                let answer_detail = preview_detail?.find(ans => ans?.questionId === getIntFromString(q?.id))
                let question_type = q?.choiceSet?.edges[0]?.node?.inputType
                let choices = q?.choiceSet?.edges?.map(({ node }) => node)
                let selected_answer_type = [InputValuesObject.checkbox, InputValuesObject.radio, InputValuesObject.select]
                let answer_data = answer_detail?.answer || null
                let respose_list = []
                if (selected_answer_type?.includes(question_type)) {
                    let selected_answer = choices?.find(v => v?.value === answer_data) 
                    if (!selected_answer) {
                        choices.forEach(item=>{
                            if(answer_data?.toLowerCase()?.includes(item?.value?.toLowerCase())){
                                respose_list.push({
                                    value:item?.value 
                                })  
                            }
                        }) 
                    }else{
                        if(selected_answer){
                            answer_data = parseMarkDownText(selected_answer?.label)
                        } 
                        respose_list = [{ value: answer_data }]
                    }
                    
                } else {
                    respose_list = [{ value: answer_data }]
                }

                return {
                    ...q,
                    respose_list
                }
            })
            SetPreviewList(preview_list)
        }
    }, [preview_detail, survey_question_list])

    const renderQuestionText = (question) => {
        let obj = {}
        preview_detail.forEach(i => {
            obj[i.questionId] = i.answer
        }) 

        return (
            <>
                {
                    getIntFromString(survey?.id) !== SURVEY_ID_3CS ?
                        <>
                            <ReactMarkdown >{question}</ReactMarkdown>
                        </> :
                        <>
                            {Object.keys(obj).includes(question_id_for_3cs?.toString()) ? <ReactMarkdown >{interpolateFor3cs(question?.replace('{', '${'), obj)}</ReactMarkdown> :
                                <ReactMarkdown >{interpolateFor3cs(question.replace(/\{\d+}/g, ''), obj)}</ReactMarkdown>}
                        </>
                }
            </>

        )
    }

    return (
        <>{
            preview_list?.length ?

                <div className="survey-preview-main-alignment-container">
                    <div className="survey-preview-main-section">
                        {
                            preview_list?.map((i, index) => (
                                <div className="generic-survey-question-preview-container"  >
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px" }}  >
                                        <div className={`um-question-section`}>
                                            <p style={{ color: "rgba(13, 214, 113, 1)", fontWeight: "700" }}>Q{index + 1}.</p>
                                            {renderQuestionText(i?.questionText)}
                                        </div>
                                    </div>
                                    <div className="um-response-section">
                                        <p style={{ color: "rgba(13, 214, 113, 1)", fontWeight: "700", whiteSpace: "nowrap", margin: "0px" }}>Answer :</p>
                                        <div className="um-response-list">
                                            {
                                                i?.respose_list?.map(r => (
                                                    <div className={`um-response-card `}>
                                                        <p className={`um-response-label`}><ReactMarkdown>{r?.value}</ReactMarkdown></p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Button className='generic-survey-next-btn' style={{ margin: "0.5em" }} onClick={() => { SetRetakeSurveyWarning(true) }}>
                            RETAKE
                        </Button>
                        <Button className='generic-survey-next-btn' style={{ margin: "0.5em" }} onClick={() => onSubmit(preview_detail)}>
                            {action_btn_label || "SUBMIT"}
                        </Button>

                    </div>
                </div>

                : ""
        }
            {/* retake warning */}
            <Modal
                footer={null}
                style={{ padding: 10, borderRadius: "20px", maxWidth: "600px" }}
                visible={retake_survey_warning}
                closable={false}
                width='100%'
                centered={true}
                className="custom-modal"
            >
                <Row justify='center' align='center' style={{ padding: "1rem", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '10px', width: "100%" }}>
                    <img src={warning_icon} alt="" />
                    <h3 className="generic-survey-quit-title" style={{ marginTop: "1rem", textAlign: "center" }}>Are you sure you want to retake the survey ? <br></br> Current data will be removed.</h3>
                    <Row justify="center" align="center" >
                        <button className="generic-survey-remove-btn" style={{ margin: "5px" }} onClick={() => SetRetakeSurveyWarning(false)}>Cancel</button>
                        <button className="generic-survey-submit-btn" style={{ margin: "5px" }} onClick={() => { SetRetakeSurveyWarning(false); RetakeSurvey() }}>Retake</button>
                    </Row>
                </Row>
            </Modal>
        </>
    )

}

export default SurveyPreview
import { Button, Col, Form, message, Modal, Row, Spin, Tabs } from "antd";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FEEDBACK_COMPRESSED_TOOL_ID, GOAL_HOME_TOOL_ID, ROLEGOAL_HOME_TOOL_ID } from '../../../config';
import '../components/css/FeedBackPopupStyles.css';
import GiveKudosPopupContainer from '../containers/GiveKudosPopupContainer';
import PostFeedbackContainer from '../containers/PostFeedbackContainer';
import ReceivedFeedbackContainer from '../containers/ReceivedFeedbackContainer';
import SubmittedFeedbackContainer from '../containers/SubmittedFeedbackContainer';
import { replaceAnswer1, replaceAnswer2, replaceAnswer3, replaceAnswer4, replaceAnswer5Feedback1, replaceAnswer5Feedback2 } from '../containers/textReplace';
import '../css/newFeedbackViewPage.css';
import FilterFeedbacks from './FilterFeedbacks';
import FeedbackDetailView from './ViewFeedbackPopUp';


// imports for permissions 
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { CUSTOM_EMAIL_ROUTE, getFeedbackStringFromInt, getIntFromString, getSelectedFeedbackType, globalPermissionValidator, MetaTags } from 'modules/look';
import { feedback_permission } from 'Permissions/feedback.permission';
import SideToolFedback from '../containers/feedbackSideTool';
import FeedbackAiTipContainer from "modules/ai-tips/container/feedback-ai-tip-container";
// import { ai_trigger_content } from "modules/ai-tips/ai-trigger-content";
import FeedbackActionRequiredContainer from "../containers/feedbackActionRequiredContainer";
import AIGlobalButtonContainer from "modules/ai-global-buttons/container/ai-global-button-container";
import CasperContainer from "modules/casper/container/casper-container";
const FeedbackView = props => {
  const {
    history,
    me,
    type,
    userPermission,
    permissionLoader, createFeedback, createBadgeAward,
    Recipient, setRecipient,
    recipientName, setrecipientName, getEmployeeById,
    feedbackDetails, setFeedbackDetails, feedbackDetailsLoading, setFeedbackDetailsLoading,
    getFeedbackDetails,
    // SetAITrigger,
    // ai_trigger,
    feedback_action_permission
  } = props;

  const [action_required_user, SetActionRequiredUser] = React.useState()
  const [selectedTab, setTab] = React.useState();
  const [searchBarData, setSearchBardata] = useState({ input: null, dropDown: 'Feedback' })
  const [feedbackOperationsModalVisible, setFeedbackOperationsModalVisible] = React.useState(false)
  const [giveKudosPopupVisible, setGiveKudosPopUpVisible] = React.useState(false)
  const [givenKudos, setGivenKudos] = React.useState(undefined);
  const [badgeAwardId, setBadgeAwardId] = React.useState();
  const [feedbackById, setFeedBackById] = React.useState();
  const [feedbackModalVisible, setFeedbackModalVisible] = React.useState(false)
  const [feedbackEditModalVisible, setFeedbackEditModalVisible] = React.useState(false);

  const [form] = Form.useForm();
  const [okrList, setokrList] = React.useState([])
  const [okrid, setokrid] = React.useState();
  const [feedBackType, setFeedBackType] = React.useState("1");
  const [fillInOption, setfillInOption] = React.useState(false);
  const [questionList, setquestionList] = React.useState([]);
  const [answersList, setAnswers] = React.useState([]);
  const [question1Field1, setquestion1Field1] = React.useState("");
  const [question1Field2, setquestion1Field2] = React.useState("");
  const [question2Field1, setquestion2Field1] = React.useState("");
  const [question2Field2, setquestion2Field2] = React.useState("");
  const [question2Field3, setquestion2Field3] = React.useState("");
  const [question3Field1, setquestion3Field1] = React.useState("");
  const [question3Field2, setquestion3Field2] = React.useState("");
  const [question4Field1, setquestion4Field1] = React.useState("");
  const [question5Field2, setquestion5Field2] = React.useState("")
  const [question5Field1, setquestion5Field1] = React.useState("")
  const [navigationFromOkr, setnavigationFromOkr] = React.useState(false);
  const [hideselection, sethideselection] = React.useState(false);
  const [employeeList, setemployeeList] = React.useState([]);
  const [invalidFeedbackType, setInvalidFeedbackType] = React.useState(false);
  const [selectedTabValue, setSelectedTabValue] = React.useState(0)
  const [selectedOkr, setSelectedOkr] = React.useState(undefined);
  const [activeTab, setActiveTab] = React.useState("received");
  const [checkBoxSelection, setCheckBoxSelection] = React.useState(undefined);
  const [newKudosAdded, setNewKudosAdded] = React.useState(false)
  const [submittedFeedback, setSubmittedFeedback] = React.useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [loaderTrigger, setLoaderTrigger] = useState(false);
  const [isInputFreeze, setIsInputFreeze] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [receivedHasLength, setReceivedHasLength] = useState();
  const [receipientNameInvalid, setReceipientNameInvalid] = React.useState();
  const [submittedHasLength, setSubmittedHasLength] = useState();
  const [feedbackDetailSide, setFeedbackDetailSide] = React.useState("");
  const [loading, setloading] = React.useState(false)
  const [feedbackPreviewDisplay, setFeedbackPreviewDisplay] = React.useState(false);
  const [tabArray, setTabArray] = React.useState([
    { id: 0, value: 'C', active: true },
    { id: 1, value: 'L', active: true },
    { id: 2, value: 'A', active: true },
    { id: 3, value: 'P', active: feedBackType === '1' ? false : true },
    { id: feedBackType === '1' ? 4 : 4, value: 'S', active: true }])

  const [tabArrayDetailed, setTabArrayDetailed] = React.useState([
    { id: 0, value: 'C', active: true },
    { id: 1, value: 'L', active: true },
    { id: 2, value: 'A', active: true },
    { id: feedBackType === '1' ? null : 3, value: 'P', active: true },
    { id: feedBackType === '1' ? 4 : 4, value: 'S', active: true }])

  const [questionSet, setQuestionSet] = React.useState({
    0: [question1Field1, question1Field2],
    1: [question2Field1, question2Field2, question2Field3],
    2: [question3Field1, question3Field2],
    3: [question4Field1],
    4: [question5Field1, question5Field2]
  })
  const feedBackTypeEnum = {
    1: 'MOTIVATIONAL_RECOGNITION',
    2: 'DEVELOPMENTAL_CONSTRUCTIVE'
  }
  const [selected_user, setSelectedUser] = React.useState()

  const [selected_user_list, setSelectedUserList] = React.useState([])
  // set default selected employee

  React.useEffect(() => {
    let defaultSelectedEmployee;
    let defaultFeedback;
    if (CUSTOM_EMAIL_ROUTE?.employee) {
      defaultSelectedEmployee = CUSTOM_EMAIL_ROUTE?.employee
    } else if (window.location?.search) {
      let params = new URLSearchParams(window.location.search)
      defaultSelectedEmployee = params?.get('employee')

    }
    if (defaultSelectedEmployee) {
      setFeedbackOperationsModalVisible(true)
      getEmployeeById(parseInt(defaultSelectedEmployee))
      localStorage.removeItem('emailRoute')
    }


    if (CUSTOM_EMAIL_ROUTE?.feedbackId) {
      defaultFeedback = CUSTOM_EMAIL_ROUTE?.feedbackId
    } else if (window.location?.search) {
      let params = new URLSearchParams(window.location.search)
      defaultFeedback = params?.get('feedback')

    }
    if (defaultFeedback) {
      setFeedbackModalVisible(true)
      defaultFeedback = getFeedbackStringFromInt(defaultFeedback)
      getFeedbackDetails(defaultFeedback)
      localStorage.removeItem('emailRoute')
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CUSTOM_EMAIL_ROUTE])


  React.useEffect(() => {
    if (action_required_user) {
      setFeedbackOperationsModalVisible(true)
    }
  }, [action_required_user])

  // state to store permissions data
  const [feedbackPermission, setFeedbackPermission] = useState()
  // code to setup the permissions validator
  useEffect(() => {
    if (me && userPermission) {
      let permission = globalPermissionValidator(feedback_permission, userPermission);
      // @ts-ignore
      setFeedbackPermission(permission)
    }
  }, [me, userPermission])

  const { TabPane } = Tabs;
  React.useEffect(() => {
    if (type) {
      setTab(type)
    }
  }, [type])
  // eslint-disable-next-line no-unused-vars
  const back = () => {
    history.push(`/page/goal/home/${GOAL_HOME_TOOL_ID}`)
  };

  // eslint-disable-next-line no-unused-vars
  const goToGoalHome = () => {
    history.push(`/page/goal/home/${GOAL_HOME_TOOL_ID}`)
  }

  // eslint-disable-next-line no-unused-vars
  const [filteritem, setFilterData] = React.useState()

  const filterData = [
    { type: 'Feedback', key: 'Feedback' },
    { type: 'User', key: 'User' },
    { type: 'OKR', key: 'OKR' }
  ]

  const SubmitFilter = (data) => {
    setFilterData(data)
  }

  // eslint-disable-next-line no-unused-vars
  const goHome = () => {
    history.push('/home')
  }

  function grabDropdownBtn(data) {
    setSearchBardata({ ...searchBarData, dropDown: data })
    setLoaderTrigger(false)
  }

  function grabInputValue(value) {
    if (value === "") {
      const inputDelay = setTimeout(() => {
        setSearchBardata({ ...searchBarData, input: value })
      }, 1000)
      return () => clearTimeout(inputDelay)
    }

    else {
      const inputDelay = setTimeout(() => {
        setSearchBardata({ ...searchBarData, input: value })
      }, 600)
      return () => clearTimeout(inputDelay)
    }
  }

  useEffect(() => {
    if (searchBarData?.input === "" && isLoading) {
      setIsInputFreeze(true)
    } else {
      setIsInputFreeze(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, searchBarData?.input])

  const loaderState = (loaderState) => {
    setIsLoading(loaderState)
  }

  // eslint-disable-next-line no-unused-vars
  const backToRoleGoal = () => {
    history.push(`/page/role_goal/home/${ROLEGOAL_HOME_TOOL_ID}`)
  };

  let feedbackModalOpener = (e) => {
    e.preventDefault();
    setFeedbackOperationsModalVisible(true)
  }

  const handleKudosSubmit = async (createdFeedbackId) => {
    if (givenKudos) {
      try {
        let badgeAwardData = {
          badgeId: givenKudos?.badgeId, evidence: givenKudos?.evidence, relatedToId: createdFeedbackId,
          recipientId: getIntFromString(Recipient?.id),
          notify: 'employee'
        }
        const res = await createBadgeAward({ badgeAwardData })
        if (res) {
          setFeedbackOperationsModalVisible(false)
        }
      } catch (error) {
        console.error(error);

      }
    }

  }

  const resetFeedbackForm = () => {
    setquestion1Field1("");
    setquestion1Field2("");
    setquestion2Field1("");
    setquestion2Field2("");
    setquestion2Field3("");
    setquestion3Field1("");
    setquestion3Field2("");
    setquestion4Field1("");

    setTabArray([
      { id: 0, value: 'C', active: true },
      { id: 1, value: 'L', active: true },
      { id: 2, value: 'A', active: true },
      { id: 3, value: 'P', active: true },
      { id: feedBackType === '1' ? 4 : 4, value: 'S', active: true }]);
    setTabArrayDetailed([
      { id: 0, value: 'C', active: true },
      { id: 1, value: 'L', active: true },
      { id: 2, value: 'A', active: true },
      { id: feedBackType === '1' ? null : 3, value: 'P', active: true },
      { id: feedBackType === '1' ? 4 : 4, value: 'S', active: true }])
    setAnswers([]);
    setGivenKudos()
    setSelectedTabValue(0)
  }

  const resetStateOfElemets = () => {
    setRecipient()
    setrecipientName()
    setokrList([])
    setFeedBackType();
    setfillInOption(false);
    setnavigationFromOkr(false);
    setInvalidFeedbackType(false);
    setSelectedOkr();
    setCheckBoxSelection();
    setSubmittedFeedback()
    resetFeedbackForm()
    setCheckBoxSelection()
    setNewKudosAdded(false)

  }

  const [remove_user_from_pending,SetRemoveUserFromPending] = React.useState()
  async function handleSubmit(value) {
    setloading(true)

    let selectedFeedbackType = getSelectedFeedbackType(feedBackTypeEnum, feedBackType)

    let feedbackData = {
      feedbackToId: getIntFromString(Recipient?.id),
      okrId: getIntFromString(selectedOkr?.id),
      feedbackType: selectedFeedbackType,
      detailFillIn: fillInOption
    }

    const response = await createFeedback({ answers: value, feedbackData: feedbackData, surveyId: 15 });
    if (response && response?.id) {
      localStorage.setItem('review_module','Feedback')
      message.destroy()
      if (givenKudos) {
        message.success('Kudos given and Feedback Submitted Successfully')

      } else {
        message.success('Feedback Submitted Successfully')

      }
      SetRemoveUserFromPending(action_required_user)
      if(SetActionRequiredUser){
        SetActionRequiredUser(null)
      }
     
      setSubmittedFeedback(response)
      handleKudosSubmit(response?.id)
      setFeedbackOperationsModalVisible(false)
      resetStateOfElemets()
      setloading(false)
      setFeedbackDetails(undefined)
      setFeedbackPreviewDisplay(false)
      setFeedbackModalVisible(false)
    } else {
      setloading(false)
    }
  }


  const handleValidationwithFillin = (submitData) => {
    if (Recipient && feedBackType) {
      let answerdata = []
      if (feedBackType === "1") {
        if (!question1Field1 || !question1Field2 || !question2Field1 || !question2Field2 || !question2Field3 || !question3Field1 || !question3Field2) {
          message.error('please fill in the required field')
          return
        }
      }
      else if (feedBackType === '2') {
        if (!question1Field1 || !question1Field2 || !question2Field1 || !question2Field2 || !question2Field3 || !question3Field1 || !question3Field2 || !question4Field1) {
          message.error('please fill in the required field')
          return
        }
      }

      questionList.forEach(question => {
        let typeValidations = question?.questionAddress?.split(':')
        if (!question?.questionAddress || typeValidations?.includes(feedBackType)) {
          let response = {
            questionId: getIntFromString(question?.id),
            answer: ''
          }
          if (getIntFromString(question?.id) === 683) {
            response.answer = replaceAnswer1(question1Field1, question1Field2)
          }
          else if (getIntFromString(question?.id) === 684) {
            response.answer = replaceAnswer2(question2Field1, question2Field2, question2Field3)
          }
          else if (getIntFromString(question?.id) === 685) {
            response.answer = replaceAnswer3(question3Field1, question3Field2)
          }
          else if (getIntFromString(question?.id) === 686) {
            response.answer = replaceAnswer4(question4Field1)
          }
          else if (getIntFromString(question?.id) === 687) {
            response.answer = feedBackType === "1" ? replaceAnswer5Feedback1(question5Field1) : feedBackType === "2" ? replaceAnswer5Feedback2(question5Field2) : ''
          }
          answerdata.push(response)
        }

      });

      if (submitData) {

        handleSubmit(answerdata)
      }

      return answerdata;

    }
    else {
      message.error('please fill in the required field')
    }
  }


  const handleValidation = () => {
    if (Recipient && feedBackType) {
      let answerdata = []


      questionList.forEach(data => {
        if (!data?.questionAddress) {
          let answer = answersList.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
          answerdata.push(answer)
        }
        else {
          let typeValidations = data?.questionAddress?.split(':')
          if (typeValidations?.includes(feedBackType)) {
            let answer = answersList.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
            answerdata.push(answer)
          }
        }
      });


      let valid = true
      questionList.forEach(data => {
        if (!data?.questionAddress) {
          if (data?.required) {
            let answer = answerdata.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id) && item?.answer?.trim() !== '')
            if (!answer) {
              valid = false
            }
          }

        }
        else {
          let typeValidations = data?.questionAddress?.split(':')
          if (typeValidations?.includes(feedBackType) && data?.required) {
            let answer = answerdata.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id) && item?.answer?.trim() !== '')
            if (!answer) {
              valid = false
            }
          }
        }
      });
      if (valid) {
        handleSubmit(answerdata)
      }
      else {
        message.error('please fill in the required field')
      }
    }
    else {
      setReceipientNameInvalid(true)
      message.error('please fill in the required field')
    }
  }

  const [visible, setVisible] = useState(false); 
  const showDemo = () => { setVisible(true); }; 
  const handleCancel = () => { setVisible(false); };
  


  return (
    <>
      <MetaTags title="Feedback" description="Feedback Home" />
      {
        !permissionLoader ? <div>
          {
            (feedbackPermission?.requried_permission) ?
              <>


                {/* Page Title and Back Arrow */}
                <div className="main-title-container back-arrow-global" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'relative', margin: '0em 1em', paddingBottom: '1.5em' }}>

                  <div>
                    <p className="goalheader poppins" style={{ margin: 'auto', textAlign: 'center', color: 'black', fontWeight: '700', fontSize: '1.8em' }}>Feedback</p>
                    <p className='poppins' >Build Trust Through Feedback!</p>
                  </div>

                </div>
                <SideToolFedback {...props} tool_id={FEEDBACK_COMPRESSED_TOOL_ID}></SideToolFedback>
                <div style={{ padding: '0px 52px' }}>
                  <div className='feedback-actions-container'>
                    <div className='dummy-feedback-div'></div>
                    <button className='give_feedback_btna add-new-feed-btn' style={{ cursor: 'pointer', zIndex: '5' }} onClick={(e) => feedbackModalOpener(e)} >GIVE FEEDBACK</button>
                    <div style={{ alignSelf: 'center' }}>
                    <span onClick={showDemo} style={{ cursor: 'pointer', color: 'blue' ,fontSize:'16px',fontWeight:'500'}}>
                                Demo
                            </span>

      <Modal
        title="Give Feedback Demo"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={800}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Motivational Feedback" key="1">
            <img src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1737544556/MApp/UI/Videos/Learning%20Paths/Feedback/motivational_feedback_gpq6c4.gif" alt="vedio"  style={{width:"100%"}}/>
          </TabPane>
          <TabPane tab="Developmental Feedback" key="2">
            <img src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1737544552/MApp/UI/Videos/Learning%20Paths/Feedback/Developmental_feedback_1_mvt4mr.gif" alt="vedio"  style={{width:"100%"}}/>
          </TabPane>
        </Tabs>
      </Modal>
    </div>

                    <div className='feedback-search-bar feedback-search-container' >

                      <FilterFeedbacks
                        isInputFreeze={isInputFreeze}
                        setReceivedHasLength={setReceivedHasLength}
                        setSubmittedHasLength={setSubmittedHasLength}
                        onfilterChange={(e) => SubmitFilter(e)}
                        filterData={filterData}
                        defaultFilter={filterData[0]}
                        grabDropdownBtn={grabDropdownBtn}
                        grabInputValue={grabInputValue}
                        activeTab={activeTab}
                        setSearchBardata={setSearchBardata}
                        searchBarData={searchBarData}
                      />
                    </div>
                  </div>

                  {
                    selectedTab && (
                      <div className='feedback-tabpane'>
                        <Tabs defaultActiveKey={selectedTab} onChange={(e) => setActiveTab(e)} className='feedback-tab-bar' tabBarStyle={{ marginBottom: '0px' }}>
                          <TabPane tab="RECEIVED" key="received" style={{ background: '#F9F9F9', width: '100%' }}>
                            <div className='feedback-tab-section'>
                              <ReceivedFeedbackContainer feedbackPermission={feedbackPermission} receivedHasLength={receivedHasLength} loaderState={loaderState} activeTab={activeTab} setFeedbackModalVisible={setFeedbackModalVisible}
                                feedbackDetails={feedbackDetails} setFeedbackDetails={setFeedbackDetails} feedbackDetailsLoading={feedbackDetailsLoading}
                                setFeedbackDetailsLoading={setFeedbackDetailsLoading} searchBarData={searchBarData} setFeedbackDetailSide={setFeedbackDetailSide} {...props} />
                            </div>
                          </TabPane >
                          <TabPane tab="SUBMITTED" key="submitted" style={{ background: '#F9F9F9', width: '100%' }}>
                            <div className='feedback-tab-section'>
                              <SubmittedFeedbackContainer feedbackPermission={feedbackPermission} submittedHasLength={submittedHasLength} loaderState={loaderState} activeTab={activeTab} setFeedBackById={setFeedBackById}
                                feedbackDetails={feedbackDetails} setFeedbackDetails={setFeedbackDetails} feedbackDetailsLoading={feedbackDetailsLoading}
                                setFeedbackDetailsLoading={setFeedbackDetailsLoading} searchBarData={searchBarData}
                                setNewKudosAdded={setNewKudosAdded} newKudosAdded={newKudosAdded} givenKudos={givenKudos}
                                setGivenKudos={setGivenKudos} feedbackById={feedbackById} feedbackEditModalVisible={feedbackEditModalVisible}
                                feedbackPreviewDisplay={feedbackPreviewDisplay} setFeedbackPreviewDisplay={setFeedbackPreviewDisplay}
                                setFeedbackModalVisible={setFeedbackModalVisible} feedBackTypeEnum={feedBackTypeEnum}
                                setFeedbackDetailSide={setFeedbackDetailSide} feedbackDetailSide={feedbackDetailSide}
                                setFeedbackEditModalVisible={setFeedbackEditModalVisible} setGiveKudosPopUpVisible={setGiveKudosPopUpVisible} {...props} />
                            </div>
                          </TabPane>

                          {/* Action required section */}
                          {
                            feedback_action_permission && feedback_action_permission?.can_view && (
                              <TabPane tab="PENDING FEEDBACK" key="action_required" style={{ background: '#F9F9F9', width: '100%' }}>
                                <div className='feedback-tab-section'>
                                  <FeedbackActionRequiredContainer {...props} SetActionRequiredUser={SetActionRequiredUser} activeTab={activeTab} remove_user_from_pending={remove_user_from_pending} searchBarData={searchBarData}/>
                                </div>
                              </TabPane>
                            )
                          }

                        </Tabs>
                      </div>
                    )
                  }

                  <Modal visible={feedbackOperationsModalVisible}
                    footer={null}
                    centered
                    destroyOnClose={true}
                    closable={false}
                    style={{ minWidth: "40em" }}
                  >
                    <PostFeedbackContainer
                      action_required_user={action_required_user}
                      SetActionRequiredUser={SetActionRequiredUser}
                      feedbackPermission={feedbackPermission}
                      setGivenKudos={setGivenKudos}
                      getEmployeeById={getEmployeeById}
                      Recipient={Recipient}
                      newKudosAdded={newKudosAdded}
                      setRecipient={setRecipient}
                      recipientName={recipientName}
                      setrecipientName={setrecipientName}
                      okrList={okrList}
                      setokrList={setokrList}
                      okrid={okrid}
                      badgeAwardId={badgeAwardId}
                      setBadgeAwardId={setBadgeAwardId}
                      form={form}
                      setokrid={setokrid}
                      feedBackType={feedBackType}
                      setFeedBackType={setFeedBackType}
                      fillInOption={fillInOption}
                      setfillInOption={setfillInOption}
                      questionList={questionList}
                      setquestionList={setquestionList}
                      answersList={answersList}
                      setAnswers={setAnswers}
                      setFeedbackDetails={setFeedbackDetails}
                      setFeedbackDetailsLoading={setFeedbackDetailsLoading}
                      setFeedbackModalVisible={setFeedbackModalVisible}
                      question1Field1={question1Field1}
                      setquestion1Field1={setquestion1Field1}
                      loading={loading} setloading={setloading}
                      receipientNameInvalid={receipientNameInvalid}
                      setReceipientNameInvalid={setReceipientNameInvalid}
                      question1Field2={question1Field2} setquestion1Field2={setquestion1Field2}
                      question2Field1={question2Field1} setquestion2Field1={setquestion2Field1}
                      question2Field2={question2Field2} setquestion2Field2={setquestion2Field2}
                      question2Field3={question2Field3} setquestion2Field3={setquestion2Field3}
                      question3Field1={question3Field1} setquestion3Field1={setquestion3Field1}
                      question3Field2={question3Field2} setquestion3Field2={setquestion3Field2}
                      question4Field1={question4Field1} setquestion4Field1={setquestion4Field1}
                      question5Field2={question5Field2} setquestion5Field2={setquestion5Field2}
                      question5Field1={question5Field1} setquestion5Field1={setquestion5Field1}
                      navigationFromOkr={navigationFromOkr} setnavigationFromOkr={setnavigationFromOkr}
                      hideselection={hideselection} sethideselection={sethideselection}
                      employeeList={employeeList} setemployeeList={setemployeeList}
                      invalidFeedbackType={invalidFeedbackType} setInvalidFeedbackType={setInvalidFeedbackType}
                      selectedTabValue={selectedTabValue} setSelectedTabValue={setSelectedTabValue}
                      selectedOkr={selectedOkr} setSelectedOkr={setSelectedOkr}
                      checkBoxSelection={checkBoxSelection} setCheckBoxSelection={setCheckBoxSelection}
                      submittedFeedback={submittedFeedback} setSubmittedFeedback={setSubmittedFeedback}
                      tabArray={tabArray} setTabArray={setTabArray}
                      questionSet={questionSet} setQuestionSet={setQuestionSet}
                      resetStateOfElemets={resetStateOfElemets}
                      setFeedbackPreviewDisplay={setFeedbackPreviewDisplay}
                      resetFeedbackForm={resetFeedbackForm}
                      feedBackTypeEnum={feedBackTypeEnum}
                      setFeedbackDetailSide={setFeedbackDetailSide}
                      handleValidationwithFillin={handleValidationwithFillin}
                      tabArrayDetailed={tabArrayDetailed} setTabArrayDetailed={setTabArrayDetailed}
                      givenKudos={givenKudos} setGiveKudosPopUpVisible={setGiveKudosPopUpVisible} setFeedbackOperationsModalVisible={setFeedbackOperationsModalVisible} {...props}
                      feedbackPreviewDisplay={feedbackPreviewDisplay}
                      setSelectedUser={setSelectedUser}
                      selected_user={selected_user}
                      setSelectedUserList={setSelectedUserList}
                      selected_user_list={selected_user_list}
                    >
                    </PostFeedbackContainer>
                  </Modal>

                  <Modal visible={giveKudosPopupVisible}
                    footer={null}
                    centered
                    closable={false}
                    width={"fit-content"}>
                    <GiveKudosPopupContainer setNewKudosAdded={setNewKudosAdded} feedbackById={feedbackById} setBadgeAwardId={setBadgeAwardId}
                      badgeAwardId={badgeAwardId} setGivenKudos={setGivenKudos} feedbackEditModalVisible={feedbackEditModalVisible}
                      setFeedbackEditModalVisible={setFeedbackEditModalVisible} setFeedbackOperationsModalVisible={setFeedbackOperationsModalVisible}
                      setGiveKudosPopUpVisible={setGiveKudosPopUpVisible} {...props} ></GiveKudosPopupContainer>
                  </Modal>

                <Modal visible={feedbackModalVisible} 
                  footer={null}
                  centered
                  destroyOnClose={true}
                  closable={false}
                >
                  <Spin spinning={feedbackDetailsLoading}>
                    <FeedbackDetailView feedbackPermission={feedbackPermission} feedbackPreviewDisplay = {feedbackPreviewDisplay}
                     feedbackDetails={feedbackDetails} setFeedbackModalVisible={setFeedbackModalVisible}
                     setFeedbackPreviewDisplay = {setFeedbackPreviewDisplay} me={me} 
                     fillInOption = {fillInOption}
                     setFeedbackOperationsModalVisible = {setFeedbackOperationsModalVisible}
                     handleValidation = {handleValidation}
                     setFeedbackDetails = {setFeedbackDetails}
                     feedbackDetailSide = {feedbackDetailSide}
                     handleValidationwithFillin = {handleValidationwithFillin}
                     editFeedbackPreview = {false}
                     {...props}
                     />
                  </Spin>
                </Modal>
              </div>
                {
                  feedbackPermission && 
                  (feedbackPermission?.runpod_permission || feedbackPermission?.casper_permissions) && 
                  <AIGlobalButtonContainer
                    module = "feedback"
                    feedbackPermission={feedbackPermission} 
                    Casper={feedbackPermission && feedbackPermission?.casper_permissions && CasperContainer} 
                    AiTips={feedbackPermission && feedbackPermission?.runpod_permission && FeedbackAiTipContainer} 
                    {...props}
                  />
                }
            </> 
            :
            <NoPermissionView />
          }
        </div> : null
      }
    </>
  );
};

export default React.memo(FeedbackView);

// eslint-disable-next-line no-unused-vars
const RowDiv = styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`
// eslint-disable-next-line no-unused-vars
const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#B0CB1F;
}
`// eslint-disable-next-line no-unused-vars
const CreateButton = styled(Button)`
background-color: #4CCB1F;
width:170px;
height:40px;
border-radius:5px;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;
font-color: white;
`
// eslint-disable-next-line no-unused-vars
const CreateButtonNew = styled(Button)`
width:170px;
height:40px;
border-radius:5px;
color: #4CCB1F;
font-family: 'Poppins';
text-shadow: 0px 8px #e7e7e9;
drop-shadow: 0px 8px #e7e7e9 inset;
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 24px;

`

import React from 'react';
import {Button } from 'antd';
import HOME_ROUTE from '../../home/route';
import { SEND_LOG_TO_BACKEND } from '../graphql/LogError.gql';
import { withApollo } from '@apollo/client/react/hoc';
import { compose } from 'modules/core';
import error_banner from '../../assets/error-page-banner.svg'
import error_ticket_icon from '../../assets/error-page-ticket-icon.svg'
import support_route from 'modules/ticketing-system/route';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  async sendLogToBackend(msg) {
    await this.props.client.query({
      query: SEND_LOG_TO_BACKEND,
      variables: { msg: msg },
      fetchPolicy: 'network-only',
    });
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    try {
      if (JSON.stringify(errorInfo).includes("CreateInvitationMultippleEmployeeView")) {
        localStorage.setItem("err_at", "multi_invite");
        const formData = localStorage.hasOwnProperty("formData") ? JSON.parse(localStorage.formData) : null;
        const msg = JSON.stringify({ TYPE: "UI_ERROR", ERROR: error?.message, DATA: formData, ERROR_STACK: errorInfo });
        this.sendLogToBackend(msg)
      } else {
        localStorage.setItem("err_at", "multi_invite");
        const msg = JSON.stringify({ TYPE: "UI_ERROR", ERROR: error?.message, ERROR_STACK: errorInfo });
        this.sendLogToBackend(msg)
      }
    } catch (error) {
      console.error("ErrorBoundry", error)
    }
  }
  render() {
    if (!this.state.error) {
      return this.props.children;
    } else {
      return (
        // <div className={'HVCenter'} style={{margin:'1em'}}>
        //   <Result
        //     status="500"
        //     title="500"
        //     subTitle="Sorry, something went wrong."
        //     extra={
        //       <Button href={HOME_ROUTE.home} type="primary">
        //         Back Home
        //       </Button>
        //     }
        //   />

        // </div>
          <div className='error-boundry-container' style={{ display: "flex",flexDirection:"column", justifyContent: "center", alignItems: "center", gap: "1em", background: "#fff",padding: '1em'}}>
            <img className='error-boundry-image' src={error_banner} alt="error_banner"/>
            <h1 className='error-bountry-title-head'>Opps! Something Went Wrong</h1>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "2em",flex:"wrap" }}>
              <Button className='error-boundry-btn' href={HOME_ROUTE.userdashboard}><span style={{paddingTop:".75em"}}>Back To Home</span></Button>
              <Button className='error-boundry-btn' style={{position:"relative"}}href={support_route.create_ticket}> <img style={{ position: "absolute", left: '0px', top: "-12px", height: "1.5em", width: '1.5em' }} src={error_ticket_icon} alt="error_ticket_icon" /> <span style={{paddingTop:".75em"}}>Raise Ticket</span></Button>
            </div>
          </div>
      );
    }
  }
}

export default compose(withApollo)(ErrorBoundary);

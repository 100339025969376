import React from 'react';
import { Form, Input, Col, Select, Button } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
// import DeleteIcon from '../DeleteIcon';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
import { ReactMarkdown } from 'modules/look';

const FormItem = Form.Item;
const Option = Select.Option;

const GrowSelectField = props => {
  const {
    choices,
    onAdd,
    onEdit,
    onDelete,
    margin,
    otherOption = false,
    onChangeSelect,
    newTab = false,
    offset,
    onSelectChange,
    onBlur,
    onSearch,
    disabled = false,
    selectedValue,
    placeholder,
    conditon,
    employee,
    ...rest
  } = props;
  const [value, setValue] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref && ref.current && ref.current.props && ref.current.props.value !== '') {
      setValue(ref.current.props.value);
    }
  }, []);

  const choice =
    choices &&
    choices.map((choice, key) => (
      <Option
        key={key}
        className='selected-survey'
        style={{textOverflow:"ellipsis",whiteSpace:"nowrap"}}
        value={String(choice.value) === 'false' ? false : choice.value === '' ? '' : choice.value || choice.label}
      >
        {typeof choice.label === 'string'&&conditon!=="ignore" ? <ReactMarkdown>{choice.label}</ReactMarkdown> : choice.label}
      </Option>
    ));
  const otherField = (
    <Option key="other" value="" className='selected-survey'>
      Other...
    </Option>
  );
  const handleChange = params => {
    if (params === '') {
      setVisible(true);
    } else {
      setVisible(false);
      onSelectChange && onSelectChange(params);
    }
  };
  const handleSelect = params => { 
    setValue(params);
    onChangeSelect && onChangeSelect(params);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onBlur(event);
      setVisible(false)
    }
  };

  const extraLinkProps = newTab
    ? {
        target: '_blank'
      }
    : {};

  return (
    <div className='grow-surevey-select-row'>
      <div className='grow-surevey-select-row'>
        <div style={{width:'100%',display:"flex",flexDirection:"column",gap:'.5em'}}>
          <FormItem
            onChange={onSelectChange}
            name={props.name}
            label={props.label}
            rules={props.rules}
            labelCol={{ span: 24 }} wrapperCol={{ span: 24}}
            required={props?.required}
            style={{ paddingLeft: "0px",marginBottom:margin?margin:"24px" }}
            {...rest}
          >
            <Select placeholder={placeholder} className="grow-surevey-select-filed" defaultValue={selectedValue} value={selectedValue}  onChange={handleChange} style={{textOverflow:"ellipsis",whiteSpace:"nowrap"}} suffixIcon={<img src={dropdown_Arrow} alt="" style={{width:"10px",height:"10px"}}/>} {...props} ref={ref} onSelect={handleSelect} onSearch={onSearch} disabled={disabled}
             filterOption={(input,option)=> option} 
             >
              {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice}
              {/* {choice} */}
            </Select>
          </FormItem>
        </div>
        {onEdit && (
          <Col lg={2} md={3} style={{ paddingTop: '30px' }}>
            <Button shape="circle" disabled={!value} /* style={{ marginTop: '10px' }} */>
              <Link to={`${onEdit}${value}`}>
                <EditOutlined />
              </Link>
            </Button>
          </Col>
        )}
        {onAdd && (
          <Col lg={2} md={3} style={{ paddingTop: '30px' }}>
            <Button shape="circle" type="primary" ghost /* style={{ marginTop: '10px' }} */>
              <Link to={`${onAdd}`} {...extraLinkProps}>
                <PlusOutlined />
              </Link>
            </Button>
          </Col>
        )}
        {/* {onDelete && (
          <Col span={3} style={{ paddingTop: '3vh' }}>
            <DeleteIcon onClick={() => onDelete(value)} style={{ marginTop: '10px' }} />
          </Col>
        )} */}
      </div>
      {visible && (
        <FormItem onChange={onSelectChange} {...rest} label={null} style={{marginTop:"1em"}}>
          <Input className="grow-survey-input-filed" onBlur={onBlur} onKeyDown={handleKeyDown} />
        </FormItem>
      )}
    </div>
  );
};

export default GrowSelectField;
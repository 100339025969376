import React, { useState } from "react";
import { Select, Button, Spin } from "antd";

import '../css/yourKudosView.css'
import styled from "styled-components";
import { Capitalize } from "modules/look";
import { kudosBadgeColors } from "../container/configs/kudosColorConfig";

const YourKuodsView = (props) => {
  const { me, fetchKudos, awards, loading, quarters,your_badge_page_info } = props;
  const [tab, setTab] = useState("Receive")

  const SwitchTab = (key) => {
    setTab(key)
    if (key === "Receive") {
      fetchKudos({ recipient: me?.employee?.id })
    }
    else if (key === "Given") {
      fetchKudos({ issuer: me?.employee?.id })
    }
  }


  const onfilter = (value) => {
    let selected_quarter = quarters?.find(item => item?.key === value)
    if (tab === "Receive") {
      fetchKudos({ recipient: me?.employee?.id, createdAt_Range: [selected_quarter?.value?.startDate, selected_quarter?.value?.endDate] })
    }
    else if (tab === "Given") {
      fetchKudos({ issuer: me?.employee?.id, createdAt_Range: [selected_quarter?.value?.startDate, selected_quarter?.value?.endDate] })
    }
  }
  const onPagination =(cursor)=>{
    let selected_tab = {}
    if(tab ==='Receive'){
      selected_tab ={recipient: me?.employee?.id}
    }
    else if (tab === "Given"){
      selected_tab ={issuer: me?.employee?.id}
    }
    fetchKudos({...cursor,...selected_tab})
  }
  return (
    <div className="kudos-card-root">
      <div className="kudos-new-card" style={{ minHeight: "30em", padding: '1em' }}>
        <div className="kudos-main-title" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em" }}>
          <h3 className="your-kudos-barde-label" style={{ fontSize: "1.25em" }}> YOUR KUDOS</h3>
          {quarters.length > 0 && <CustomSelect dropdownClassName="content-dropdown" placeholder="Sort By" onChange={(e, item) => { onfilter(e) }} >
            {
              quarters.map((item) => (
                <Select.Option
                  className="kudos-card-filter-text"
                  key={item.value}
                  value={item.key}
                >
                  {item.label}
                </Select.Option>
              ))}
          </CustomSelect>}
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", marginTop: '1em' }}>
          {me?.profile?.profileImage ? <img style={{ width: "100px", height: "100px", borderRadius: "50%" }} src={me?.profile?.profileImage} alt="" /> :
            <div style={{ width: "100px", height: "100px", borderRadius: "50%", background: "#AABAC6", display: "flex", justifyContent: "center", alignItems: 'center' }}>
              <h4 style={{ fontSize: "3em", textAlign: "center", fontWeight: "600", fontFamily: "Poppins", color: "#fff", margin: 0 }}>{Capitalize(me?.firstName?.charAt(0))}</h4>
            </div>}
          <h4 style={{ margin:' 10px 0 0 0', padding:0,fontWeight:'600' }}>{me?.firstName} {me?.lastName}</h4>
          <p className="kudos-card-user-role">{me?.employee?.memberSet?.edges?.[0]?.node?.role?.title}</p>
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: 'center', gap: "1em", width: "100%",marginTop:"1em" }}>
          <Button className={tab === "Receive" ? "your-kudos-button-select" : "your-kudos-button"} onClick={() => SwitchTab("Receive")}>
            RECEIVE
          </Button>
          <Button className={tab === "Given" ? "your-kudos-button-select" : "your-kudos-button"} onClick={() => SwitchTab("Given")}>
            GIVEN
          </Button>
        </div>
        <Spin spinning={loading}>
          {tab === "Receive" && awards.length>0 && !loading && <div className="your-kudos-recent" style={{background:kudosBadgeColors[awards[0]?.badge?.badgeFamily?.title?.replace(/\s+/g, '').toLowerCase()]?kudosBadgeColors[awards[0]?.badge?.badgeFamily?.title?.replace(/\s+/g, '').toLowerCase()]?.backgroundColor:'rgba(46, 85, 107, 1)'}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"start",gap:"1em",alignItems:"center"}}>
              {me?.profile?.profileImage ? <img className="kudos-recent-image" style={{ height: "3em", width: "3em", borderRadius: "50%" }} src={me?.profile?.profileImage} alt="" /> :
                <div className="kudos-recent-image" style={{ height: "3em", width: "3em", borderRadius: "50%", background: "#AABAC6", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                  <h4 style={{ fontSize: "2em", textAlign: "center", fontWeight: "600", fontFamily: "Poppins", color: "#fff", margin: 0 }}>{Capitalize(awards[0]?.issuer?.user?.firstName?.charAt(0))}</h4>
                </div>}
              <div>
                <p className="your-kudos-barde-label" style={{color:"#fff" , fontWeight: "400", fontSize:"13px"}}>{awards[0]?.badge?.title}</p>
                <p className="your-kudos-barde-label" style={{color:"#fff", fontWeight: "400", fontSize:"13px"}}>Awarded by - {Capitalize(awards[0]?.issuer?.user?.firstName)}</p>
              </div>
            </div>
            <img
              style={{ height: "3em", width: "3em", borderRadius: "50%" }}
              alt="user"
              src={awards[0]?.badge?.image}
              className="kudos-recent-image"
            />
          </div>}
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', padding: '25px 0 0 0' }}>
              <div className="your-kudos-barde-label" style={{ fontWeight: "500", padding: '0 0 0 50px' }}>KUDOS</div>
              <div className="your-kudos-barde-label" style={{ fontWeight: "500" }}>{tab === "Given" ? 'RECEIVED BY' : 'AWARDED BY'}</div>
            </div>
          
              <div className="your-kudos-popup-container-scroll" style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                {awards?.length > 0 && awards?.map(item => (
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em" }}>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "center", gap: "1em" }}>
                      <img style={{ width: "3em", height: '3em' }} src={item?.badge?.image} alt="badge_image" />
                      <h4 className="your-kudos-barde-label">{item?.badge?.title}</h4>
                    </div>
                    {tab === "Given" ? <h4 className="your-kudos-barde-label">{Capitalize(item?.recipient?.user?.firstName)}</h4> : <h4 className="your-kudos-barde-label">{Capitalize(item?.issuer?.user?.firstName)}</h4>}
                  </div>
                ))}
                {!loading && your_badge_page_info?.hasNextPage?
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                        <h4 onClick={() => { onPagination({cursor:your_badge_page_info?.endCursor})}}>View More</h4>
                    </div> : ''}
                {
                    (!awards?.length && !loading) ?
                        <div className='rd-nodata' style={{background:"transparent"}}>
                            <h4 className='rd-nodata-label'>No Kudos Found</h4>
                        </div> : ''
                }
              </div>
          
          </div>
        </Spin>
      </div>
    </div>
  )
}

export default YourKuodsView;
const CustomSelect = styled(Select)`
.ant-select-selector{
    border: 1px solid #AABAC6 !impoortant;
    color:  #000;
    font-weight: 500;
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: left;
    border-radius:.5em !important;
    width:10em !important;
    height: 40px !important;
    padding-top: 4px !important;
}
`;


import { useMutation } from "@apollo/react-hooks";
import { Button, Modal, Row, Spin } from "antd";
import gql from "graphql-tag";
import { getIntFromString } from "modules/look";
import React from "react";
import { EDIT_BARS_ICON } from "../Icon";
import bars_route from "../route";
import BarsDeleteConfirmation from "./BarDeleteConfirmation";
import BarsEditDetailsTabContainer from "./BarsEditDetails";





const BarsEditView = (props) => {
  const { barsDetails, barsDetailsLoading, setBarsDetails, setBarsDetailsLoading, allRatingScales, createBulkBehaviourallyAnchoredRatings,
     empLevelRef,navigateRoute
  } = props

  const [activeLevel, setActiveLevel] = React.useState();
  const [updateMutationData,setUpdateMutationData] = React.useState();
  const [newBars,setNewBars] = React.useState();
  
  



  React.useEffect(() => {

    window.scrollTo(0, 0);

  }, [])

  React.useEffect(() => {

    if (props.match.params.level_id) {
      setActiveLevel(props.match.params.level_id)
    }

  }, [props.match.params.level_id])

  const [newlyAddedBars, setNewAddedBars] = React.useState([]);
  const barsDetailsRef = React.useRef(null);
  const [subCompetencyModalVisible, setSubCompetencyModalVisible] = React.useState(false)



  const handleExampleChange = async (e, barsNode, level) => {
    
    let newBars = newlyAddedBars;
    let indexOfUpdateBar = barsDetails?.empLevels?.findIndex((value) =>
      value?.ratingScale?.id === barsNode?.ratingScale?.id && value?.id === level?.id
    );
    
    let updatedBars = barsDetails;
    
    if (updatedBars.empLevels[indexOfUpdateBar]?.bar) {
      updatedBars.empLevels[indexOfUpdateBar].behaviourExample = e;
    } else {
      let indexOfNewlyAddedBar = newlyAddedBars?.findIndex((value) =>

        value?.ratingScaleId === barsNode?.ratingScale?.id && value?.employeeLevelId === level?.id
      );
      
      if (indexOfNewlyAddedBar >= 0) {
        newlyAddedBars[indexOfNewlyAddedBar].behaviourExample = e;
      } else {
      
        let record = {
          competency: { mainCompetencyId: barsDetails?.mainCompetencyId },
          ratingScaleId: barsNode?.ratingScale?.id,
          employeeLevelId: level?.id,
          behaviourExample: e
        }
        newBars = newBars.concat([record])
        
        setNewAddedBars([...newBars]);
      }

    }

    

    let newRecords = newBars?.map((record) => { return { id: record?.employeeLevelId, behaviourExample: record?.behaviourExample } })


    let combindBars = { empLevels: updatedBars?.empLevels.concat(newRecords) }

    let arr = [];
    empLevelRef.current
      .forEach(level => (
        arr.push({ ...level, status: combindBars?.empLevels?.filter(item => item.id === level?.id && item?.behaviourExample)?.length !== allRatingScales?.length ? "pending" : 'completed' })
      ));

    
    empLevelRef.current = arr;
    setBarsDetails({ ...updatedBars });

  }



  




  const UPDATE_DEFINITION = 

    
      gql`
      mutation updateBehaviourallyAnchoredRating {
        ${updateMutationData &&  updateMutationData?.empLevels?.filter((i)=>i?.bar)
          ?.map(
            // eslint-disable-next-line array-callback-return
            (item, index) => {
              if(item?.bar){
                return `update${index}: updateBehaviourallyAnchoredRating(id: "${(item.bar)}",behaviourExample:${JSON.stringify(item.behaviourExample)}) {
                  behaviourallyAnchoredRating{
                    id
                    employeeLevel {
                      id 
                    }
                    ratingScale {
                      id
                      sequence
                    }
                    behaviourExample
                  }
                 }
               `
                }
            }
          )
          .join('')}
      }
      `;
    

  
  
 
  






  React.useEffect(()=>{

    let updateBarsAvailable = updateMutationData?.empLevels?.filter((value)=>value?.bar);
    
    if(updateBarsAvailable?.length){
      triggerUpdateMutation()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updateMutationData])





  // eslint-disable-next-line no-unused-vars
  const [updateDefinition, { data, error }] = useMutation(UPDATE_DEFINITION);

  const triggerUpdateMutation = async() =>{
    setBarsDetailsLoading(true)
   const editResponse = await updateDefinition();

  
    let editedBarsToStore = Object.entries(editResponse?.data)?.map((value, index) =>

      editResponse.data[Object.keys(editResponse.data)[index]]

    )
    let alreadyAddedbars = barsDetailsRef.current?.empLevels?.map((node) => node?.bar)?.filter((value) => value !== undefined);
    let allBars = [].concat(newlyAddedBars).concat(alreadyAddedbars);
    let allExpectedBarsQty = empLevelRef.current?.length * allRatingScales?.length


  

   

    if (allExpectedBarsQty === allBars?.length && barsDetails?.subCompetenciesLength && newBars) {

      let barsToStore = [];
      barsToStore = barsToStore.concat(editedBarsToStore).concat(newBars)
      let filteredData = barsToStore?.filter((value) => value?.behaviourallyAnchoredRating?.employeeLevel?.id === empLevelRef.current[0]?.id)
      let scales = filteredData?.map((value) => { return { ...value?.behaviourallyAnchoredRating?.ratingScale, behaviourExample: value?.behaviourallyAnchoredRating?.behaviourExample } }).sort((a, b) => a.sequence - b.sequence)
      let objToStore = { ...empLevelRef.current[0], ratingScales: scales }

      localStorage.setItem('mainCompetencyData', JSON.stringify(objToStore))
      setSubCompetencyModalVisible(true);
    } else {
      navigateRoute(bars_route.bars_home)
    }


  }


  const handleEditBars = async () => {

    let addResponse;
    
    if (newlyAddedBars?.length) {
      addResponse = await createBulkBehaviourallyAnchoredRatings({ behaviourallyAnchoredRatingDataList: newlyAddedBars })
    }


    setNewBars(addResponse?.behaviourallyAnchoredRating);

    if(barsDetails?.empLevels?.length){
       setUpdateMutationData(barsDetails)
    }
         
  }

  const handleCancelSubCompetency = () => {
    setSubCompetencyModalVisible(false);
    navigateRoute(bars_route.bars_home)
  }

  const handleSubCompetencyConfirm = () => {
    setSubCompetencyModalVisible(false)
    navigateRoute(bars_route.bars_create_sub_comp?.replace(":competency_id",getIntFromString(barsDetails?.mainCompetencyId)))
  }

  return (
    <>

      <Spin spinning={barsDetailsLoading}>

        <div className="create-bars-root poppins" style={{ borderRadius: '0.8em' }}>

          <img alt='' src={EDIT_BARS_ICON.new_back_arrow} onClick={(e) =>  navigateRoute(bars_route.bars_home)} className="create-bar-back-arrow pointer" />

          <Row align="middle" justify="center">
            <div className="create-bars-header">Edit <span>BARS</span></div>
          </Row>

          <div className="create-bars-body">
            <div className="create-bar-sub-heading create-bar-margin-seperator">Competency</div>

            <div className="bars-create-competency-title create-bar-margin-seperator">{barsDetails?.title}</div>
            {/* Component for adding data into rating scales with respective employee levels */}
            {
              barsDetails ?
              <BarsEditDetailsTabContainer
              barsDetails={barsDetails}
              allEmployeeLevels={empLevelRef.current}
              allRatingScales={allRatingScales}
              handleExampleChange={handleExampleChange}
              indexOfElements={0}
              activeLevel={activeLevel ? activeLevel : 0}
              setActiveLevel={setActiveLevel}
            ></BarsEditDetailsTabContainer>
            : null
            }
           
          </div>
          {
            (empLevelRef.current?.length && allRatingScales?.length) ?
            <Row justify="center">
            <Button className="bars-submit-action" onClick={(e) => handleEditBars()}>Submit</Button>
            </Row>
            :null
          }
         


          <Modal
            visible={subCompetencyModalVisible}
            footer={null}
            centered
            destroyOnClose={true}
            closable={false}
          >
            <BarsDeleteConfirmation
              message={"Yay! You've defined the competencies! Would you like to continue defining the sub-competencies?"}
              confirm_action_label={"Yes"}
              cancel_action_label={"No"}
              onConfirm={(e) => handleSubCompetencyConfirm()}
              onCancel={(e) => handleCancelSubCompetency()}
              confirm_class="bars-confimation-action"
              cancel_class="bars-cancel-action"
            ></BarsDeleteConfirmation>

          </Modal>


        </div>
      </Spin>
    </>
  );
};

export default BarsEditView;


import React from 'react';
import ScapePreferneceTabView from '../components/scape-preference-tab-view';


const ScapePreferneceTabContiner =(props)=>{
    return (
        <div>
            <ScapePreferneceTabView {...props}/>
        </div>
    )
}

export default  ScapePreferneceTabContiner
import React from 'react'
import USER_ROUTE from '../../user/route';
import { compose, removeItem } from '../../core';
// import { withCreateResponse } from '../../survey/containers/SurveyOperations';
import { withSurveyByIdNewOptimized } from './diversityOperations'
import DiversityQuizView from '../components/DiversityQuizView';
import { getIntFromString, removeSurveyCache } from '../../look';
import { withCreateResponseOptimized} from '../../survey/containers/SurveyOperations'
import SurveyTermsAndCondition from '../../survey/components/SurveyTermsAndCondition'
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import mapp_learning_path_routes from 'modules/learning-path/routes';
import { withEditProfile } from 'modules/user/containers/UserOperations';
const DiversityHome = (props) => {
    const { error, history, createResponse, orgSurveyById, SurveyById, location, navigateRoute } = props;
    const startTime = new Date().getTime();
    const [prevTotalDuration, setPrevTotalDuration] = React.useState(0);
    // eslint-disable-next-line no-unused-vars
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [showTermsAndCondition, setshowTermsAndCondition] = React.useState();
    React.useEffect(()=>{
        if(location){
            let  query =location.search?.replace('?','')
            // eslint-disable-next-line no-unused-vars
            let data = query.split('=')
        }
    },[location])
    if (error) {
        if (error.message === 'GraphQL error: Permission Denied.') {
            // setRedirect(true);
            setTimeout(3000, history.push(USER_ROUTE.login));
        }
    }

    // const editUserProfile = async (data) => {
    //     const questionIds = {
    //         gender: 36,
    //         education: 38,
    //         work_exp: 662,
    //         nationality: 665,
    //     }

    //     const gender_list ={
    //         "Male": "Male",
    //         "Female": "Female",
    //         "Transgender": "Transgender",
    //         "Gender Fluid": "Gender Fluid",
    //         "Gender Neutral": "Gender Neutral",
    //         "Other": "Other"
    //     }
        
    //     try {
    //         let permission = globalPermissionValidator(user_profile_permissions, userPermission)
    //         let profile_details = {
    //             gender: gender_list[data.find(ele => ele.questionId === questionIds.gender).answer],
    //             education: data.find(ele => ele.questionId === questionIds.education).answer,
    //             workExperience: data.find(ele => ele.questionId === questionIds.work_exp).answer,
    //             nationality: data.find(ele => ele.questionId === questionIds.nationality).answer,
    //         }
    //         permission?.profile_permissions && await editProfile({ id: getIntFromString(me?.profile?.id), ...profile_details })
    //     }
    //     catch (error) {
    //         console.error('Error updating profile:', error)
    //     }
    // }

    const handleSubmit = async answers => {
        setSubmitLoading(true);
        const endTime = new Date().getTime();
        try {
            const response = await createResponse({
                answers,
                surveyId:
                    (orgSurveyById && getIntFromString(orgSurveyById.id)) ||
                    (SurveyById && getIntFromString(SurveyById.id)),
                totalDuration: prevTotalDuration + (endTime - startTime) / 1000 // returns time in seconds
            });
            response && setSubmitted(true);
            response && removeItem(orgSurveyById ? orgSurveyById.id : SurveyById.id);
           
            if (response){
                // editUserProfile(answers)
                let status =localStorage.getItem('to_leraning')
                const surveyId = getIntFromString(SurveyById.id);
                removeSurveyCache(surveyId)
                if(status ==='diversity_profile'){
                    localStorage.setItem('to_leraning','diversity_profile_completed')
                    navigateRoute(mapp_learning_path_routes?.soul_learning_path)
                }
                else{
                    navigateRoute(`/survey/response/${getIntFromString(response.id)}`);
                }
                
            } 
        } catch (e) {
            throw new Error(e.message);
        }
        setSubmitLoading(false);
    };

    return (
        // <Spin spinning={submitLoading} size="large">
        <>
        {SurveyById&&SurveyById==null?<NoPermissionView content={"You Don't Have Permission To View This Survey."}/>:
        <>
        {
            showTermsAndCondition && SurveyById?.termsConditions && (
              <SurveyTermsAndCondition
                termsAndConditon={SurveyById?.termsConditions}
                accept={()=>{setshowTermsAndCondition(false)}}
                back={()=>{history.goBack()}}
              />
            )
        }
        <DiversityQuizView
            {...props}
            submitted={submitted}
            onSubmit={handleSubmit}
            setPrevTotalDuration={setPrevTotalDuration}
            setshowTermsAndCondition={setshowTermsAndCondition}
            quizStartTime={startTime}
        />
        </>}
        </>
        // </Spin>
    )
}

export default compose(withCreateResponseOptimized, withSurveyByIdNewOptimized, withEditProfile)(DiversityHome)

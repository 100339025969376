import React from "react";
import UserOneOneView from "../components/userOneOnOneView";
import { withApollo } from '@apollo/client/react/hoc';
import { compose } from 'modules/core';
import { ACTION_ITEMS_LIST, UPCOMMING_LATEST_METTING } from "../graphql/userDashboardQueries.gql";
import moment from "moment-timezone";
const UserOneOnOneContainer =(props)=>{
    const {me,client,user_permission}=props
    const [action_item_loading,setAction_item_loading] =React.useState(false)
    const [action_item_list,setActionItemList]=React.useState([])
    const [upcoming_meeting,setUpcommingMeeting]=React.useState()
    const [upcoming_meeting_loading,setUpcomingMeetingLoading]=React.useState(false)
    React.useEffect(()=>{
        if(user_permission?.oneonone_permission){
            getActionItemList()
            getUpcommingMeeting()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[user_permission])

    const getActionItemList =async()=>{
        setAction_item_loading(true)
        const { data } = await client.query({
            query: ACTION_ITEMS_LIST,
            fetchPolicy: 'network-only',
            variables: {member:me?.employee?.id}
          });
          if (data?.allMeetings?.edges?.length) {
            let list =data?.allMeetings?.edges?.map(({node})=>node)
            let action_item_list =list?.filter(item=>item?.actionitemSet?.edges?.length>0)
                setActionItemList(action_item_list)
          }
          setAction_item_loading(false)
          
    }

    const getUpcommingMeeting =async(filter)=>{
        setUpcomingMeetingLoading(true)
        const { data } = await client.query({
            query: UPCOMMING_LATEST_METTING,
            fetchPolicy: 'network-only',
            variables:{when_Range: [moment(),moment().add(7,'day')],orderBy:["when"],endedAt_Isnull:true}
          });
          if (data?.allMeetings?.edges?.length) {
            setUpcommingMeeting(data?.allMeetings?.edges[0]?.node)
          }
          setUpcomingMeetingLoading(false)
    }

    return (
        <>
           {user_permission?.oneonone_permission&&<UserOneOneView 
            action_item_loading={action_item_loading}
            action_item_list={action_item_list}
            upcoming_meeting={upcoming_meeting}
            upcoming_meeting_loading={upcoming_meeting_loading}
            {...props}/>}
        </>
    )
}

export default compose(withApollo)(UserOneOnOneContainer)
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-mixed-operators */
// @ts-nocheck
import React from "react";
import { Row, Col, Button, Input,Dropdown, message, Menu, Spin, Checkbox, Popover, Tooltip } from "antd";
import styled from "styled-components";
import {  DownOutlined} from "@ant-design/icons";
import { scrolltoview, withMarkdownFieldValue } from "../../look";
import CKEditorFieldOneOnOne from "modules/look/components/form-components/CKEditor-one-on-one";
import agenda_cancel from '../../assets/agenda-cancel.svg'
import save_disabled from '../../assets/icons/save_disabled.png'
import save_enabled from '../../assets/icons/save_enabled.png'
import Meeting_title from '../../assets/meeting-title-icon.svg'
import okr_icon from '../../assets/okr-disabled-icon.svg'
const AddAgenda = props => {
  const { header, onSubmit, relatedData, selectType, memberlist, agenda, isAgenda, close, customCss,meetingStarted,permission,AiTip } = props
  const [title, setTitle] = React.useState();
  const [titleInvalid, settitleInvalid] = React.useState(false);
  const [selectedType, setSelectedType] = React.useState();
  const [OkrId, setOKrId] = React.useState();
  const [commitmentId, setcommitmentId] = React.useState();
  const [coachingCommitmentId, setcoachingCommitmentId] = React.useState();
  const [relatedtoName, setrelatedtoName] = React.useState();
  const [FieldName, setFieldName] = React.useState();
  const [member, setMember] = React.useState(null);
  const [relatedloading, setrelatedloading] = React.useState(false);
  const [relatedList, setrelatedList] = React.useState([]);
  const [alreadyChanged, setalreadyChanged] = React.useState();
  const TypeInvalid = false;
  const [visible, setVisible] = React.useState(false)
  const [enable, setEnable] = React.useState(false)
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (header !== "Add Action Item") {
      setMember(memberlist[0])
      selectType({ type: "OKR", user: memberlist[0] })
    }
    else {
      setMember()
      selectType()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberlist, header])

  React.useEffect(() => {
    if (agenda && !alreadyChanged) {
      setTitle(agenda?.title)
      if (agenda?.okrId) {
        setOKrId(agenda?.okrId)
        setcoachingCommitmentId(null)
        setcommitmentId(null)
        setMember(agenda?.okrId?.owner?.user)
        setSelectedType('OKR')
        selectType({ type: 'OKR', user: agenda?.okrId?.owner?.user })
        setrelatedtoName(agenda?.okrId?.title)
      }
      else if (agenda?.coachingCommitmentId) {
        setOKrId(null)
        setcoachingCommitmentId(agenda?.coachingCommitmentId)
        setcommitmentId(null)
        setMember(agenda?.coachingCommitment?.user)
        setSelectedType('Coaching Commitment')
        selectType({ type: 'Coaching Commitment', user: agenda?.coachingCommitment?.user })
        setrelatedtoName(agenda?.coachingCommitmentId?.title)
      }
      else if (agenda?.commitmentId) {
        setOKrId(null)
        setcoachingCommitmentId(null)
        setcommitmentId(agenda?.commitmentId)
        setMember(agenda?.commitment?.user)
        setSelectedType('Commitment')
        selectType({ type: 'Commitment', user: agenda?.commitment?.user })
        setrelatedtoName(agenda?.commitmentId?.title)
      }
      setalreadyChanged(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agenda])
  const CKEditorFieldName = "notes"

  React.useEffect(() => {
    if (relatedData) {
      setrelatedList(relatedData)
    }
    setrelatedloading(false)
  }, [relatedData])

  const handleMemberSelection = (data) => {
    setSelectedType('OKR')
    setMember(data)
    selectType({ type: 'OKR', user: data })
    setOKrId(null)
    setcommitmentId(null)
    setcoachingCommitmentId(null)
    setrelatedtoName(null)
    if (selectedType) {
      setrelatedList([])
      selectType({ type: selectedType, user: data })
      setrelatedloading(true)
    }
  }

  const handleRelatedSelection = (data) => {
    if (selectedType === 'OKR' && data?.__typename === 'okrType') {
      setOKrId(data)
      setcommitmentId(null)
      setcoachingCommitmentId(null)
    }
    else if (selectedType === 'Commitment' && data?.__typename === 'CommitmentType') {
      setOKrId(null)
      setcommitmentId(data)
      setcoachingCommitmentId(null)
    }
    else if (selectedType === 'Coaching Commitment' && data?.__typename === 'CoachingCommitmentType') {
      setOKrId(null)
      setcommitmentId(null)
      setcoachingCommitmentId(data)
    }
    setrelatedtoName(data?.title)
  }

  const menu = () => (
    <Menu style={{ maxHeight: '200px', overflow: 'auto' }}>
      {/* <Menu.Item className="one-on-one-okr-selection-drop" onClick={()=>RemoveMemberSelection()}>
          </Menu.Item> */}
      {
        memberlist && memberlist?.map((data) =>
          <Menu.Item className="one-on-one-okr-selection-drop text-transform-capitalize" onClick={() => handleMemberSelection(data)}>
            {data?.firstName}
          </Menu.Item>
        )
      }
    </Menu>
  );
  

  const Relatedmenu = () => (

    <Menu style={{ maxHeight: '200px', overflowY: 'auto', width: "100%" }}>
      {
        relatedList?.length ?
          relatedList.map((data) =>
            <Menu.Item className="one-on-one-okr-selection-drop" onClick={() => handleRelatedSelection(data)} >
              <div style={{display:'flex',flexDirection:"row",gap:"5px"}}>
                <div>
                  {
                data?.okrType === "OBJECTIVE" &&
                <div style={{border:'1px solid black',opacity:'0.4',paddingLeft:'5px',paddingRight:'5px',marginRight:'5px',fontSize:'10px',borderColor:'#4CCB1F',color:'#4CCB1F'}}>O</div>
                  ||
                  data?.okrType === "KEY_RESULT" &&
                  <div style={{border:'1px solid black',opacity:'0.4',paddingLeft:'3px',borderColor:'#009AF1',paddingRight:'3px',marginRight:'5px',fontSize:'10px',color:'#009AF1'}}>KR</div>
                  ||                
                  data?.okrType === "MILESTONE" &&
                  <div style={{border:'1px solid black',opacity:'0.4',paddingLeft:'5px',borderColor:'rgb(250, 197, 60)',paddingRight:'5px',marginRight:'5px',fontSize:'10px',color:'rgb(250, 197, 60)'}}>M</div>
                  }
                </div>
                <div style={{ textOverflow: 'ellipsis', overflow: "hidden", maxWidth: meetingStarted ?  "340px" :'460px'}}>
                  {data?.title} 
                </div>
              </div>
            </Menu.Item>
          )
          :
          relatedloading ?
            <Menu.Item  >
              <Spin spinning={true}></Spin>
            </Menu.Item> :
            <Menu.Item  >
              no data found
            </Menu.Item>


      }
    </Menu>
  );


  const Save = () => {
    if (title&&title?.trim()!=="") {
    if (member) {
     
        let data = withMarkdownFieldValue(CKEditorFieldName, { notes: FieldName })
        let agendaDetail = {
          ...data,
          title: title,
          member:member? member : null,
          okrId: OkrId,
          commitmentId: commitmentId,
          coachingCommitmentId: coachingCommitmentId,
          isAgenda: isAgenda ? true : false,
          id: agenda ? agenda?.id : null
        }
        onSubmit(agendaDetail)
      }
    else{
      message.error("Please select member")
      ref.current.focus();
    }
  }
    else {
      settitleInvalid(true)
      ref.current.focus();
      // message.error('please add title')
    }
  }
  const cancel = () => {
    setTitle(null)
    setSelectedType(null)
    setOKrId(null)
    setcommitmentId(null)
    setcoachingCommitmentId(null)
    setrelatedtoName(null)
    setFieldName(null)
    setMember(null)
    close()
  }

  const onChange = (e) => {
    if (e.target.checked === true) {
      setSelectedType("OKR")
      setOKrId(null)
      setcommitmentId(null)
      setcoachingCommitmentId(null)
      setrelatedList([])
      setrelatedtoName(null)
      selectType({ type: "OKR", user: member })
      setrelatedloading(true)
    }
    else {
      setSelectedType()
      setOKrId(null)
      setcommitmentId(null)
      setcoachingCommitmentId(null)
      setrelatedList([])
      setrelatedtoName(null)
      selectType(null)
      setrelatedloading(true)
    }

  };
  const handleTitleSubmit = (e) => {
    let title = e?.target?.value?.trim()
    if (title) {
      setEnable(true)
    }
    else {
      setEnable(false)
    }
    if (title.length >= 200) {
      setVisible(true)
      settitleInvalid(true)
    }
    else {
      setVisible(false)
      settitleInvalid(false)
    }
    setTitle(e.target.value)
  }
  const hide = () => {
    setVisible(false)
  }
  return (
    <>
      <Col className="add-agenda-container-section" style={{ border: agenda ? "1px solid #D9D9D9" : "1px solid #4CCB1F", padding: "10px" }} >
        {/* <p style={{fontSize:'15px'}}>{header}</p> */}
        <Row gutter={10} justify="center" align="center" style={{ marginTop: '15px' }}>
          <Col style={{ marginTop: header === "Update Action Item" || header === "Add Action Item" ? "10px" : "0px", width: header === "Update Action Item" || header === "Add Action Item" ? "90%" : "100%", padding: header === "Update Action Item" || header === "Add Action Item" ? "0px 18px" : "0px 0px" }} lg={{ span: header === "Update Action Item" || header === "Add Action Item" ? 15 : 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
            {/* <p style={{fontSize:'15px'}}><span style={{color:'#ff4d4f'}}>*</span> Title</p> */}

            <Popover
              content={<a onClick={hide}>Close</a>}
              title="Character Limit Exceeded"
              trigger="click"
              visible={visible}
              placement="topRight"
            >
              <div style={{ position: "relative" }}>
                <Input
                  style={{
                    border: !titleInvalid ? '1px solid #D9D9D9' : '1px solid #ff4d4f',
                    borderRadius: "8px",
                    height: "40px",
                    paddingLeft: "43px",
                  }}
                  className="one-on-one-input-field-style"
                  id="1_1_title"
                  value={title}
                  maxLength={200}
                  placeholder={isAgenda ? "Title of Agenda" : "Title of Action Item"}
                  ref={ref}
                  // onChange={e => (setTitle(e.target.value))}   
                  onChange={(e) => handleTitleSubmit(e)}
                  onClick={(e) => { scrolltoview('1_1_title') }}
                  onBlur={(e)=>AiTip?
                    AiTip({value:e.target.value,key:isAgenda?'one_on_one_create_agenda':'one_on_one_create_action_item'}):null}
                  autoComplete='off'
                />
                <img className='instant-form-icon' src={Meeting_title} alt="" style={{ position: "absolute", left: "10px" }} />
              </div>
            </Popover>
            {
              titleInvalid && (
                <p style={{ color: 'red', marginTop: '5px' }}>This field is required.</p>
              )
            }
          </Col>
          {(header === "Update Action Item" || header === "Add Action Item" ) ? <Col lg={{ span: 9 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ marginTop: header === "Update Action Item" || header === "Add Action Item" ? "10px" : "0px" }}>
            {/* <p style={{fontSize:'15px'}}>Member</p> */}
            <Dropdown overlay={menu}>
              <DropdownButton
                style={{
                  border: !TypeInvalid ? '1px solid #D9D9D9' : '1px solid #ff4d4f',
                  borderRadius: "5px",
                  height: "40px"
                }}
                ref={ref}
                >
                <p className="one-on-one-okr-selection-drop text-transform-capitalize" style={{ marginTop: '10px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>{member ? member?.firstName : <span style={{ color: "#D9D9D9" }}>{`Select member`}</span>}</p>
                <DownOutlined style={{ color: "#BFBFBF" }} />

              </DropdownButton>
            </Dropdown>
            {/* // <h4>{memberlist[0]?.firstName}</h4>             */}
          </Col> : null
          }
          {
           ( member && permission?.okr_permission) && (
              <Col style={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: "center", marginTop: "15px" }}>
                {header !== "Update Action Item" && header !== "Add Action Item" ?
                  <Checkbox className="one-on-one-check one-on-one-check-title" onChange={onChange} id="milestone-check" onClick={(e) => { scrolltoview('1_1_title') }} checked={selectedType === "OKR"}>Related to <span className="text-transform-capitalize">{memberlist[0]?.firstName}</span> OKRS?</Checkbox> : null}
              </Col>
            )}
        </Row>
        {
        ( member && permission?.okr_permission) && (
        <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ marginTop: "15px" }}>
          {selectedType && (
            <div style={{ position: "relative" }}>
              <Dropdown overlay={Relatedmenu} ref={ref} style={{ width: "100%" }}>
                <DropdownButton
                  style={{
                    border: !TypeInvalid ? '1px solid #D9D9D9' : '1px solid #ff4d4f',
                    borderRadius: "5px",
                    height: "35px",
                    width: "100%"
                  }}>
                  <p className="one-on-one-okr-selection-drop" style={{ marginTop: '13px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', paddingLeft: customCss ? "20px" : "22px" }}>{relatedtoName ? relatedtoName : <span style={{ color: "#D9D9D9" }}>{`Select ${selectedType}`}</span>}</p>
                  <DownOutlined style={{ color: "#BFBFBF" }} />

                </DropdownButton>
              </Dropdown>
              <img className='instant-form-icon' src={okr_icon} alt="" style={{ position: "absolute", left: customCss ? "10px" : "10px", marginTop: "0px" }} />
            </div>
          )}
     
          {
            selectedType && TypeInvalid && (
              <p style={{ color: 'red', marginTop: '15px' }}>This field is required.</p>
            )
          }
        </Col>
           )}
        <div style={{ width: "100%", border: "1px solid #D9D9D9", borderRadius: "8px", paddingBottom: "5px", paddingRight: "5px", marginTop: selectedType ? "10px" : "10px" }}>
          <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ width: "100%" }}>
            <div className="one-on-one-ck-editor" >
              <CKEditorFieldOneOnOne
                initialValue={agenda?.notes || ""}
                changeHandler={(e) => { setFieldName(e) }}
              />
            </div>
          </Col>
          <Row align="end">
            <Tooltip title={"Cancel"}><img src={agenda_cancel} alt="" onClick={() => { cancel() }} style={{ marginLeft: '10px', cursor: "pointer" }} /></Tooltip>
            {enable || FieldName ? <Tooltip title={"Save"}><img src={save_enabled} alt="" style={{ marginLeft: '10px', cursor: "pointer",width:"15px" }} onClick={() => { Save() }} /></Tooltip> : <img src={save_disabled} alt="" style={{ marginLeft: '10px', cursor: "pointer",width:"15px" }} />}
          </Row>
        </div>
      </Col>
    </>
  )

}
export default AddAgenda;

const DropdownButton = styled(Button)`
width:95%;
display:flex;
justify-content:space-between;
align-items:center;
gap:5px
`
// @ts-nocheck
import { message } from 'antd';
import { graphql } from '@apollo/client/react/hoc';
import { CREATE_RESPONSE_COMPARE } from '../graphql/createUserManualRequest.gql';
import { ACCEPT_COMPARE_REQUEST } from '../graphql/AcceptCompareRequest.gql';
import { COMPARE_REQUEST_DELETE_MUTATION } from '../graphql/DeleteCompareRequest.gql';

export const createResponseCompareRequest = Component =>
graphql(CREATE_RESPONSE_COMPARE, {
  props: ({ mutate, history }) => ({
    createResponseCompareRequest: async values => {
      try {
        const {
          data: { createResponseCompareRequest }
        } = await mutate({
          variables: {
            ...values
          }
        });
        message.destroy();
        message.success('created successfully');
        return createResponseCompareRequest.compareRequest;
      } catch (e) {
        message.destroy();
        message.error("Failed to create");
        console.error(e);
      }
    }
  })
})(Component);

export const acceptCompareRequest = Component =>
graphql(ACCEPT_COMPARE_REQUEST, {
  props: ({ mutate, history }) => ({
    respondToResponseCompareRequest: async values => {
      try {
        const {
          data: { respondToResponseCompareRequest }
        } = await mutate({
          variables: {
            ...values
          }
        });
        message.destroy();
        message.success('accepted successfully');
        return respondToResponseCompareRequest.compareRequest;
      } catch (e) {
        message.destroy();
        console.error(e);
      }
    }
  })
})(Component);

export const withDeleteRequest= Component =>
    graphql(COMPARE_REQUEST_DELETE_MUTATION, {
      props: ({ mutate}) => ({
        deleteResponseCompareRequest: async values => {
          try {
            const {
              // eslint-disable-next-line no-unused-vars
              data: { deleteResponseCompareRequest }
            } = await mutate({
              variables: {
                ...values
              }
            });
            message.destroy();
            message.success('Successfully deleted');
            return true;
          } catch (e) {
            message.destroy();
            message.error("Couldn't perform the action");
            console.error(e);
          }
        }
      })
    })(Component);
import { Button, Form, Input, Modal, Select } from "antd";
import React from "react";
import "../css/profile-update.css"
import close_icon from "../../assets/readiness-close-icon.svg"
import { education_list, gender_list, Nationalities } from "../profile-config";
const UserProfileEditView = (props) => {
    const { profile_update_viible, onSubmit, onClose, me } = props;
    const [form] = Form.useForm();
    const onFinish = async (value) => {
        await onSubmit({ ...value })
    }

    React.useEffect(() => {
        if (me) {
            form.setFieldsValue({
                firstName: me?.employee?.user?.firstName,
                lastName: me?.employee?.user?.lastName,
                gender: me?.profile?.gender === "TRANSGENDER" ?
                    'Transgender' : me?.profile?.gender === "GENDER_FLUID" ?
                        'Gender Fluid' : me?.profile?.gender === "GENDER_NEUTRAL" ?
                            "Gender Neutral" : me?.profile?.gender === "MALE" ?
                                "Male" : me?.profile?.gender === "FEMALE" ?
                                    "Female" : me?.profile?.gender === "OTHER" ?
                                        "Other" : me?.profile?.gender,
                education: me?.profile?.education,
                experience: me?.profile?.workExperience,
                nationality: me?.profile?.nationality,
            });
        }
    }, [form, me])

    return (
        <>
            <Modal
                visible={profile_update_viible}
                centered={true}
                closable={false}
                destroyOnClose={true}
                footer={false}>
                <div style={{ display: 'flex', flexDirection: "column", gap: "1em" }}>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: '1em' }}>
                        <h4 className="profile-update-popup-main-head">Profile Update</h4>
                        <img src={close_icon} alt="close_icon" style={{ cursor: "pointer" }} onClick={() => onClose()} />
                    </div>

                    <Form
                        form={form}
                        name="basic"
                        layout={'vertical'}
                        onFinish={onFinish}
                        autoComplete="off"
                        className="update-profile-form">
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "center", gap: '1em', width: '100%' }}>
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                style={{ width: '100%', }}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input type="text" className="" style={{ borderRadius: ".5em", border: "1px solid rgba(170, 186, 198, 1) ", height: "3em", width: '100%' }}
                                    autoComplete='off' />
                            </Form.Item>
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                style={{ width: '100%', }}
                                rules={[
                                    {
                                        required: false,
                                    },
                                ]}
                            >
                                <Input type="text" className="" style={{ borderRadius: ".5em", border: "1px solid rgba(170, 186, 198, 1) ", height: "3em", width: '100%' }}
                                    autoComplete='off' />
                            </Form.Item>
                        </div>
                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select your gender',
                                },
                            ]}
                        >
                            <Select
                                name="gender"
                                className="profile-edit-select"
                                showSearch={true}
                            >
                                {gender_list?.map((gender) => (

                                    <Select.Option key={gender.value} value={gender.value}>{gender.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Education"
                            name="education"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your education',
                                },
                            ]}
                        >
                            <Select
                                name="education"
                                className="profile-edit-select"
                                showSearch={true}
                            >
                                {education_list?.map((education) => (
                                    <Select.Option key={education.value} value={education.value}>{education.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Work Experience(In Years)"
                            name="experience"

                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your work experience',
                                },
                            ]}
                        >
                            <Input type="number" className="" style={{ borderRadius: ".5em", border: "1px solid rgba(170, 186, 198, 1) ", height: "3em", width: "100%" }}
                                autoComplete='off' />
                        </Form.Item>
                        <Form.Item
                            label="Nationality"
                            name="nationality"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select your nationality',
                                },
                            ]}
                        >
                            <Select
                                name="nationality"
                                className="profile-edit-select"
                                showSearch={true}
                            >
                                {Nationalities?.map((value) => (
                                    <Select.Option key={value.name} value={value.name}>{value.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1em", flexWrap: "wrap", paddingTop: "1em" }}>
                            <Button type="primary" htmlType="submit" className="save-btn-profile-update">
                                Submit
                            </Button>
                            <Button type="primary" className="cancel-btn-profile-update" onClick={() => onClose()}>
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    )
}
export default UserProfileEditView;
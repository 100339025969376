

import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { globalPermissionValidator } from 'modules/look';
import { okr_permission } from 'Permissions/okr.permission';
import React from 'react'
import DashboardHomePage from '../components/DashboardHomePage'


const DashboardHomePageContainer = (props) => {


    const { userPermission, permissionLoader } = props;
    const [okrPermitted, setOkrPermitted] = React.useState({});

    React.useEffect(() => {
        if (userPermission && !permissionLoader) {
            let okrPermission = globalPermissionValidator(okr_permission, userPermission)
            setOkrPermitted(okrPermission);
        }
    }, [userPermission, permissionLoader])


    return (

        <div>
            {
                !permissionLoader ?
                    <div>
                        {
                            (okrPermitted?.requried_permission) ?
                                <DashboardHomePage {...props} />
                                :
                                <NoPermissionView />
                        }
                    </div>
                    :
                    null
            }
        </div>
    )

}



export default DashboardHomePageContainer
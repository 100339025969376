import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../look';

import AddToolView from '../components/AddToolView';
import { CKEditorFieldName } from '../components/ToolFormComponent';

import { withAddTool, withToolState } from './ToolOperations';
import ROUTE from '../route';

const AddTool = props => {
  const { history, createTool } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    try {
      const response = await createTool({ toolData: withMarkdownFieldValue(CKEditorFieldName, values) });
      response &&
        ButtonsCatch(
          item,
          false,
          history,
          item === undefined ? `${ROUTE.editToolLink}${getIntFromString(response.id)}` : window.location.pathname
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddToolView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withToolState, withAddTool)(AddTool);

import { Button, Col, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import left_arrow from '../../assets/bulb-icon.svg';
import close_arrow from '../../assets/close-arrow.svg';
import { getIntFromString, ReactMarkdown } from '../../look';
import { RenderFormSection } from '../../tool/components/generic-tool';

const BarsSideToolView = props => {
  const { loading, getToolById, admin = false, refetch } = props;
  const [items, setItems] = useState([]);
  const [sideAction, setSideAction] = React.useState(false)

  useEffect(() => {
    if (getToolById)
      setItems([
        ...(getToolById?.textsection?.edges?.map(({ node }) => ({ ...node, type: 'textsection' })) || []),
        ...(getToolById?.formsection?.edges?.map(({ node }) => ({ ...node, type: 'formsection' })) || [])
      ]);
  }, [getToolById]);

  const RenderTextSection = ({ textSection, idx }) => {
    return (
      <Row justify='center' align='center' style={{ flexDirection: 'column', gap: '10px' }}>
        <Col span={24}>
          <ReactMarkdown>{textSection.renderedText || '<p></p>'}</ReactMarkdown>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Button className='side_tool_views' style={{ background: "#009AF1", position: "fixed", right: '0px', border: "none", height: "50px", width: "50px", borderTopLeftRadius: "6px", borderBottomLeftRadius: "6px", cursor: "pointer", zIndex: 1000 }} onClick={() => setSideAction(true)}><img src={left_arrow} className="bulb-icon" alt="" height='25px' /></Button>

      {sideAction && (
        <Modal
          className='side-modal custom-side-modal'
          visible={sideAction}
          closable={false}
          footer={false}
          style={{ position: "fixed", right: "-20px", top: '130px', maxWidth: "500px" }}>
          <SideButton className='tool-close-btn-icon' onClick={() => setSideAction(false)}><img src={close_arrow} alt="" height='20px' /></SideButton>
          {/* <Button className='custom-close-btn' style={{background: "#009AF1",position:"fixed",right:"460px",top:'170px',border:"none",height:"40px",width:"40px",borderRadius:"6px"}} onClick={()=>setSideAction(false)}><img src={close_arrow} alt="" height='20px'/></Button> */}
          <Spin spinning={loading} size="large">
            <div>
              {!loading && (
                <Row style={{ marginTop: '10px' }} justify="center">
                  {items
                    .sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
                    .map((section, idx) =>
                      section.type === 'textsection' ? (
                        <Col span={23} align="left">
                          <RenderTextSection customClass={'custom-text-render'} textSection={section} idx={idx} />
                        </Col>
                      ) : (
                        <Col span={24} style={{ backgroundColor: (2 * idx + 1) % 2 !== 0 && '#8080801c', paddingTop: '10px' }}>
                          <Row justify="center">
                            <Col span={23} align="left">
                              <RenderFormSection
                                admin={admin}
                                formSectionId={getIntFromString(section.id)}
                                formSection={section}
                                refetch={refetch}
                              />
                            </Col>
                          </Row>
                        </Col>
                      )
                    )}
                </Row>
              )}
            </div>
          </Spin>
        </Modal>
      )
      }
    </>
  );
};

export default BarsSideToolView;
const SideButton = styled(Button)`

background: #009AF1;
position:fixed;
right:460px;
border:none;
height:40px;
width:40px;
border-radius:6px;
`
/* eslint-disable no-useless-concat */
export const okr = "objective-keyresult"
const baseName = `/role/${okr}`
const create = '/add'
const view = '/view'
const edit = '/edit'
const home = '/home'
const keyresult = "/keyresult"
const milestone = '/milestone'

const okr_route = {
    createObjective: baseName + create,
    viewObjective: baseName + view + '/:okrId', 
    editObjective: baseName + edit + '/:okrId',
    mineOkr: baseName + '/mine',
    pastOkr: baseName + '/past',
    discardRequest: baseName + '/discard',
    okrDashboard: baseName + '/okrdashboard',
    okrDashboardTeam: baseName + '/okrdashboard'+'/team',
    okrTree: baseName + '/okrtree',
    discardDetail: baseName + '/discard' +'/detail'+ '/:id',
    requestDetail: baseName + '/:type' +'/detail'+ '/:id',
    home: baseName + home ,
    keyResultDetail:baseName + keyresult + view + '/:okrId',
    milestoneDetail:baseName + milestone + view + '/:okrId'
}   

export default okr_route
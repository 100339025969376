import { message } from "antd";
import {
  CREATE_EMAIL_NOTIFICATION_SETTINGS,
  CREATE_TIP_SETTINGS,
  UPDATE_DND,
  UPDATE_TIP_SETTINGS,
} from "../graphql/profile.gql";
import { graphql } from "@apollo/client/react/hoc";

export const withCreateSettingsTip = (Component) =>
  graphql(CREATE_TIP_SETTINGS, {
    props: ({ mutate, history }) => ({
      createTipsettings: async (values) => {
        try {
          const {
            data: { createTipsettings },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          return createTipsettings.tipsettings;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);
export const withUpdateSettingsTip = (Component) =>
  graphql(UPDATE_TIP_SETTINGS, {
    props: ({ mutate, history }) => ({
      updateTipsettings: async (values) => {
        try {
          const {
            data: { updateTipsettings },
          } = await mutate({
            variables: {
              ...values,
            },
          });

          message.destroy();
          return updateTipsettings.tipsettings;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      },
    }),
  })(Component);

  export const withCreateNotificationSetting = (Component) =>
    graphql(CREATE_EMAIL_NOTIFICATION_SETTINGS, {
      props: ({ mutate, history }) => ({
        createNotificationSettings: async (values) => {
          try {
            const {
              data: { createNotificationSettings },
            } = await mutate({
              variables: {
                ...values,
              },
            });
  
            message.destroy();
            return createNotificationSettings.notifications;
          } catch (e) {
            message.destroy(); 
            console.error(e);
          }
        },
      }),
    })(Component);
    export const withUpdateDnd = (Component) =>
      graphql(UPDATE_DND, {
        props: ({ mutate, history }) => ({
          updateDnd: async (values) => {
            try {
              const {
                data: { updateDnd },
              } = await mutate({
                variables: {
                  ...values,
                },
              });
    
              message.destroy();
              return updateDnd.notificationSetting;
            } catch (e) {
              message.destroy();
              message.error("Couldn't perform the action");
              console.error(e);
            }
          },
        }),
      })(Component);
// @ts-nocheck
import React from "react";
import { Row, Col, Button,Tooltip,Menu,Spin,Input,Form ,Table, Dropdown,message,Popover,} from "antd";
import styled from "styled-components";
import { MetaTags, getIntFromString, scrolltoview,DatePickerField, TextField } from "../../look";
import { EditOutlined,DeleteOutlined } from "@ant-design/icons";
import AddAgendaContainer from '../containers/AddAgendaContainer'
import MultiSelectEmployee from './MultiSelectEmployee'
import moment from 'moment-timezone';
import { useHistory } from "react-router-dom";

import Meeting_title from '../../assets/meeting-title-icon.svg'
import okr from '../../assets/okr-redirection.svg'
import edit_icon from '../../assets/edit-agenda.svg'
import delete_icon from '../../assets/delete-agenda.svg'
import bookmark_link from '../../assets/bookmark-link.svg'
import three_dot from '../../assets/three-dot.svg'
import Back_icon from '../../assets/soul-back.svg'
import calender_icon from '../../assets/meeting-calender-icon.svg'
import time_icon from '../../assets/time-icon.svg'
import delete_outline_24px from '../../assets/delete_outline_24px.svg'
import OneOnOneCongirmationModal from "./one-on-one-confirmation-modal";
import CustomTimePicker from "modules/look-v2/Components/custom-time-picker";
const EditMeetingView = props => {
  const {me,meetingById,createActionItem,deleteActionItem,updateActionItem,
  updateMeeting,upcomingMeeting,remove,allEmpoloyee,allEmpoloyeeResult,searchKey}=props
  const history=useHistory()
  const [loading, setloading] = React.useState(false); 
  const [form] = Form.useForm();
  const [memberlist,setmemberlist]= React.useState([]);
  const [title, setTitle] = React.useState();
  const [when, setwhen] = React.useState();
  const [addAgenda, setaddAgenda] = React.useState(false);
  const [titleInvalid, settitleInvalid] = React.useState(false);
  const [memberListInvalid, setmemberListInvalid] = React.useState(false);
  const [dateInvalid, setdateInvalid] = React.useState(false);
  const [employeeList,setemployeeList]= React.useState([]);
  const [agendaList,setagendaList]= React.useState([]);
  const [Agenda, setAgenda] = React.useState();
  // eslint-disable-next-line no-unused-vars
  const [confirmationModalVisible, setdConfirmationModalVisible] = React.useState(false);
  const [confirmationModalDeleteVisible,setdConfirmationModalDeleteVisible]=React.useState(false)
  const [visible,setVisible]=React.useState(false)
  const [durationInvalid, setdurationInvalid] = React.useState(false);
  const [selectedItem,setSelectedItem]=React.useState()
  const [selected_time,setSelectedTime]=React.useState()
  const [selectedCustomTime, setSelectedCustomTime] = React.useState(null);
  const ref = React.useRef(null);

  const back = () => {
    history.goBack();
  };

  
  // React.useEffect(()=>{

  //   if(allOrganizationEmployees && me){
     
  //     let list =  allOrganizationEmployees?.edges?.map(({node})=>node.user)
  //     let filtered = list.filter(item=>item?.employee?.id!==me?.employee?.id)
  //     setemployeeList(filtered)
  //   }

  // },[allOrganizationEmployees,me])
  React.useEffect(()=>{
    if(allEmpoloyee && me){
      let list
      let teamMembersEmployee = [].concat.apply(
        [],allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({node})=>node?.managers?.edges.map(({node})=>node?.user)))
      let teamManager= [].concat.apply(
        [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.vertical?.heads?.edges.map(({node})=>node?.user)))
      let teamManagerEmployee= [].concat.apply(
          [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.employees?.edges.map(({node})=>node?.user)))
      let orgPocEmployee = [].concat.apply(
          [],allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
      let orgCeoEmployee=[].concat.apply(
        [],allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))

      list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee)
      let data =list.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.firstName === value.firstName && t.id === value.id
      )))
      let filtered = data.filter(item=>item?.employee?.id!==me?.employee?.id)
      setemployeeList(filtered)
    }
  },[allEmpoloyee,me])

  React.useEffect(()=>{
    let lastFilter
    let firstFilter
    if(allEmpoloyeeResult?.first){
      let allEmpoloyee =allEmpoloyeeResult?.first
      if(allEmpoloyee){
        let list
        let teamMembersEmployee = [].concat.apply(
          [],allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({node})=>node?.managers?.edges.map(({node})=>node?.user)))
        let teamManager= [].concat.apply(
          [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.vertical?.heads?.edges.map(({node})=>node?.user)))
        let teamManagerEmployee= [].concat.apply(
            [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.employees?.edges.map(({node})=>node?.user)))
        let orgPocEmployee = [].concat.apply(
            [],allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
        let orgCeoEmployee=[].concat.apply(
          [],allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))

        list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee)
        let data =list.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.firstName === value.firstName && t.id === value.id
        )))
        firstFilter = data.filter(item=>item?.employee?.id!==me?.employee?.id)
      }
    }
    if(allEmpoloyeeResult?.last){
      let allEmpoloyee=allEmpoloyeeResult?.last
      if(allEmpoloyee){
        let list
        let teamMembersEmployee = [].concat.apply(
          [],allEmpoloyee?.employee?.teamMembersEmployee?.edges.map(({node})=>node?.managers?.edges.map(({node})=>node?.user)))
        let teamManager= [].concat.apply(
          [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.vertical?.heads?.edges.map(({node})=>node?.user)))
        let teamManagerEmployee= [].concat.apply(
            [],allEmpoloyee?.employee?.teamManagerEmployee?.edges.map(({node})=>node?.employees?.edges.map(({node})=>node?.user)))
        let orgPocEmployee = [].concat.apply(
            [],allEmpoloyee?.employee?.orgPocEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))
        let orgCeoEmployee=[].concat.apply(
          [],allEmpoloyee?.employee?.orgCeoEmployee?.edges.map(({node})=>node?.employees.edges.map(({node})=>node.user)))

        list=[].concat(teamMembersEmployee,teamManager,teamManagerEmployee,orgPocEmployee,orgCeoEmployee)
        let data =list.filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.firstName === value.firstName && t.id === value.id
        )))
        lastFilter = data.filter(item=>item?.employee?.id!==me?.employee?.id)
      }
      let user =firstFilter.concat(lastFilter)
      var setObj = new Set(); // create key value pair from array of array
      var result = user.reduce((acc,item)=>{
        if(!setObj.has(item?.employee?.id)){
          setObj.add(item?.employee?.id)
          acc.push(item)
        }
        return acc;
      },[])
      setemployeeList(result)
    }   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allEmpoloyeeResult])


  React.useEffect(()=>{

    if(meetingById){
      setTitle(meetingById?.title)
      setwhen(meetingById?.when)
      let memberlist =  meetingById?.member?.edges?.map(({node})=>node.user)
      memberlist=memberlist?.filter(i=>i?.employee?.id!==meetingById?.owner?.user?.employee?.id)
      setmemberlist(memberlist)
      
      let list =  meetingById?.actionitemSet?.edges?.map(({node})=>node)
      let agendaList = list.filter(item=>item?.isAgenda)
      let agendaFiltered =  agendaList?.map((item)=>
      {
        return {
          id:item?.id,
          title:item?.title,
          // content:null,
          navigateTo:null,
          notes:item?.notes,
          okrId:item?.okr,
          commitmentId:item?.commitment?.commitment,
          coachingCommitmentId:item?.coachingCommitment?.coachingcommitment,
          isAgenda:true,
          member:null,
          edited:false
        }
      }
     )
     setagendaList(agendaFiltered)
    }

  },[meetingById])

  const anchorStyle={
    whiteSpace:'nowrap',
    overflow:'hidden',
    textOverflow:'ellipsis',
    maxWidth:'150px',
    marginTop:'5px'
  }

  // eslint-disable-next-line no-unused-vars
  const columns = [
    {
      title: "Title",
      key: 'Title',
      render: (text, record) => record && 
      <Tooltip title={record?.title?.length>16?record?.title:''} placement="right">
       <p style={anchorStyle}>{record?.title}</p>
      </Tooltip>
    },
    {
      title:"relatedTo",
      key: 'relatedTo',
      render: (text, record) => record &&     
      <p >{relate(record)}</p>
    },
    {
      title: '',
      key: '',
      render: (text, record) => record && 
        <div align="end" >
          {
            record?.id && (
              <Button shape="circle" type="primary" disabled={Agenda} onClick={()=>{editAgenda(record)}} > 
              <EditOutlined />
             </Button>
            )
          }

          <Button shape="circle" type="danger" disabled={Agenda} style={{marginLeft:'10px'}} onClick={()=>DeleteWarning(record)}> 
           <DeleteOutlined />
          </Button>
        </div>
  
    }
  ];

  const editAgenda =(record)=>{
    setAgenda(record)
    setaddAgenda(true)
  } 

  const DeleteWarning =(record)=>{
    setAgenda(record)
    setdConfirmationModalVisible(true)
  } 

  const RemoveAgenda = async(value)=>{
    setdConfirmationModalVisible(false)
    if(value){
    let list =[].concat(agendaList)
    let index = list.indexOf(Agenda)
  
    if(Agenda?.id){
      setloading(true)
      try{
        const response = await deleteActionItem({id:getIntFromString(Agenda?.id)});
        if(response){
          list.splice(index,1)
          setagendaList(list)
        }
        setloading(false)
        setAgenda(null)
      }
      catch{
        setloading(false)
        setAgenda(null)
      }
      list.splice(index,1)
      setagendaList(list)
    }else{
      list.splice(index,1)
      setagendaList(list)
      message.success('Successfully deleted');
      setAgenda(null)
    }
  }
  else{
    setAgenda(null)
  }
    
  }

  const relate=(data)=>{
    
    if(data?.okrId){
      return data?.okrId?.title
    }
    else if(data?.commitmentId){
      return data?.commitmentId?.title
    }
    else if(data?.coachingCommitmentId){
      return data?.coachingCommitmentId?.title
    }
  }

  const handleMenuClick=(members)=>{
    setmemberlist(members)
    if(members?.length){
      setTitle(`${me?.firstName} and ${members[0]?.firstName}'s 1:1`)
      settitleInvalid(false)
      setmemberListInvalid(false)
    }
  }
  
  
  const SubmitAgenda=async(data)=>{
    setloading(true)
    
    let list =[].concat(agendaList)
    if(data?.id){ 
      try{
       const response = await updateActionItem(
         {
          id:getIntFromString(data?.id),
          title:data?.title,
          notes:data?.notes,
          coachingCommitmentId:data?.coachingCommitmentId?.id?getIntFromString(data?.coachingCommitmentId?.id):-1,
          commitmentId: data?.commitmentId?.id?getIntFromString(data?.commitmentId?.id):-1,
          isAgenda:data?.isAgenda,
          okrId: data?.okrId?.id?getIntFromString(data?.okrId?.id):-1,
        });
        if(response && response?.id){
          let item = list.find(item=>item?.id===response?.id)  
          let index = list.indexOf(item)
          let update = {
            title:response?.title,
            okrId:response?.okr,
            commitmentId:response?.commitment?.commitment,
            coachingCommitmentId:response?.coachingCommitment?.coachingcommitment,
            commitment:response?.commitment,
            coachingCommitment:response?.coachingCommitment,
            isAgenda:true,
            id:response?.id,
            member:null,
            notes:response?.notes,
            edited:false
          }
          list[index]=update
          setagendaList(list)
        }
     
      }
      catch{
        setloading(false)
        setAgenda(null)
        setaddAgenda(false)
      }
      setloading(false)
      setAgenda(null)
      setaddAgenda(false)
    }
    else{
      try{
      let meetingid = getIntFromString(meetingById?.id)
      let actionItemData={
        title: data?.title,
        meetingId: meetingid,
        okrId: data?.OkrId?.id?getIntFromString(data?.OkrId?.id):null,
        commitmentId: data?.commitmentId?.id?getIntFromString(data?.commitmentId?.id):null,
        coachingCommitmentId: data?.coachingCommitmentId?.id?getIntFromString(data?.coachingCommitmentId?.id):null,
        notes: data?.notes,
        isAgenda: data?.isAgenda,
        employeeId: data?.member?.employee?.id?getIntFromString(data?.member?.employee?.id):getIntFromString(me?.employee?.id)
      }
      const agendaCreate = await createActionItem({actionItemData});
    
      if (agendaCreate && agendaCreate?.id){
        let add = {
          title:agendaCreate?.title,
          okrId:agendaCreate?.okr,
          commitmentId:agendaCreate?.commitment?.commitment,
          coachingCommitmentId:agendaCreate?.coachingCommitment?.coachingcommitment,
          commitment:agendaCreate?.commitment,
          coachingCommitment:agendaCreate?.coachingCommitment,
          isAgenda:true,
          id:agendaCreate?.id,
          member:null,
          notes:agendaCreate?.notes,
          edited:false
        }

        list.push(add)
        setagendaList(list)
        setaddAgenda(false)
        setAgenda(null)
      }
  
     }
     catch{
      setloading(false)
      setAgenda(null)
      setaddAgenda(false)
     }
     setloading(false)
      setAgenda(null)
      setaddAgenda(false)
    }
  
  } 
  
  const handleSubmit=async()=>{
    let values = form.getFieldsValue()
    if(title && memberlist?.length && when && values?.duration>0){
      let selected_date_time
      if(selected_time){
        let date =moment(when).format("YYYY-MM-DD")
        const roundedTime = moment({
          hour: selected_time.hour(),
          minute: Math.ceil( selected_time.minute() / 15) * 15,
        }).format("HH:mm");
        selected_date_time =`${date} ${roundedTime}`
      }
      // else if (!selected_time){
      //   if(moment(when._d).minute()<45){
      //     let date =moment(when).format("YYYY-MM-DD")
      //     let roundedTime = moment({
      //       hour:  moment(when._d).hour(),
      //       minute: Math.ceil( moment(when._d).minute() / 15) * 15,
      //     }).format("HH:mm");
      //     selected_date_time =`${date} ${roundedTime}`
      //   }
      //   else{
      //     let date =moment(when).format("YYYY-MM-DD")
      //     let time_diff = 60-Math.ceil(moment(when._d).minute())
      //     let new_time =moment(when._d).add(time_diff, 'minutes')
      //     let roundedTime = moment({
      //       hour:moment(new_time).hour(),
      //       minute: Math.ceil(moment(new_time).minute() / 15) * 15,
      //     }).format("HH:mm"); 
      //     selected_date_time =`${date} ${roundedTime}`
      //   }
      // }
      else{
        selected_date_time=when
      }
      setloading(true)
      settitleInvalid(false)
      setmemberListInvalid(false)
      if(title.length>200){
        settitleInvalid(true)
        setloading(false)
        message.error("please add title less than 200 words")
      }
      else{
        let members = memberlist.map(user=>getIntFromString(user?.employee?.id))
        members.push(getIntFromString(meetingById?.owner?.user?.employee?.id))
        
        try{
      
          const response = await updateMeeting({id:getIntFromString(meetingById?.id),title:title,when:moment(selected_date_time).utc().toISOString(),members,scheduleDuration:values?.duration*60})
          if(response && response?.id){
            setloading(false)
              // eslint-disable-next-line no-unused-vars
              let isUpcoimgMeeting =  upcomingMeeting?.edges.some(element => {
                  if (element?.node?.id === meetingById?.id) {
                    return true;
                  }else{  
                    return false;
                  }
                
                });

                history.goBack()
          
          }
          else{
            setloading(false)
          }
        }
        catch{
          setloading(false)
        }
      }
      
    }
    else{
      if(!title){
        settitleInvalid(true)
        ref.current.focus();
      }
      else{
        settitleInvalid(false)
      }
      if(!memberlist?.length){
        setmemberListInvalid(true)
        ref.current.focus();
      }
      else{
        setmemberListInvalid(false)
      }
      if(!when){
        setdateInvalid(true)
        ref.current.focus();
      }
      else{
        setdateInvalid(false)
      }

      if(values?.duration<=0){
        setdurationInvalid(true)
      }
      else{
        setdurationInvalid(false)
      }
      
    }
  }
  async function RemoveMeeting(value) {
    setdConfirmationModalDeleteVisible(false)
    if(value){
        remove(meetingById?.id)
    }
  }
  const disabledHours = () => {
    const hours = [];
    if(when && moment(when).isAfter(moment())){
      return hours;
    }
    else{
      for (let i = 0; i < moment().hour(); i += 1) hours.push(i);
      return hours;
    } 
  };
  
  const disabledMinutes = (selectedHour) => {
    const minutes = [];
    if(when && moment(when).isAfter(moment())){
      return minutes;
    }else{
      if (selectedHour === moment().hour()) {
        for (let i = 0; i < moment().minute(); i += 1) minutes.push(i);
      }
      return minutes;
    }
  
  };
  function disabledRangeTime(value) {
    if(value && moment(value).isAfter(moment())){
  
    }
    else{
      return {
        disabledHours: () => disabledHours(),
        disabledMinutes: (selectedHour) => disabledMinutes(selectedHour),
      };
    }
  }
  const handleTitleSubmit=(e)=>{
    let title =e.target.value
    if(title.length>=200){
      setVisible(true)
      settitleInvalid(true)
    }
    else{
      setVisible(false)
      settitleInvalid(false)
    }
    setTitle(e.target.value)
  }
  const hide=()=>{
    setVisible(false)
  }

  const handleChange =(value)=>{
    
    if(value){
      setwhen(value)
      setdateInvalid(false)
    }
    else{
      setwhen(null)
    }
    
  }
  const searchInput=(value)=>{
    searchKey(value)
}

const redirectToOkr=(okr)=>{
  if(okr?.okrType === 'OBJECTIVE'){
      history.push(`/role/objective-keyresult/view/${getIntFromString(okr?.id)}`);
  }
  else if(okr?.okrType === 'KEY_RESULT'){
      history.push(`/role/objective-keyresult/keyresult/view/${getIntFromString(okr?.id)}`);
  }
  else if (okr?.okrType === 'MILESTONE'){
      history.push(`/role/objective-keyresult/milestone/view/${getIntFromString(okr?.id)}`);
  }
}

const EditActionItem=(item)=>{
  setSelectedItem(item)
  setaddAgenda(true)
}

const handleTimeChange = (time) => {
  let date =moment(when).format("YYYY-MM-DD")
  let selected_date_time =`${date} ${moment(time, ['h:mm A']).format('HH:mm')}`
  setSelectedTime(moment(selected_date_time))
  setSelectedCustomTime(time);
};

  return(
    <>
      <MetaTags
          title="Edit 1:1"
          description="This is Edit 1:1 page."
      />
      <Spin spinning={loading}>
        {meetingById && (
      <div style={{padding:'0px 20px'}}>
  {
    confirmationModalDeleteVisible&& (

       <OneOnOneCongirmationModal
          heading={'Warning'}
          content={'You are about to delete this Meeting. Are you sure?'}
          onSubmit={(e)=>RemoveMeeting(e)}
          visible={confirmationModalDeleteVisible}
          onCancel={()=>setdConfirmationModalDeleteVisible(false)}
          actionButton={"Delete"}
          actionButtonColor={"#E86161"}
       />
    )
   }
   {/* <RowDiv >
     <h4 style={{fontSize:'20px'}}>Edit 1:1</h4>
       <div>
         <Button shape="circle" onClick={()=>setdConfirmationModalDeleteVisible(true)} type="danger" style={{marginRight:'20px'}} >
           <DeleteOutlined />
         </Button>
         <Button shape="circle" onClick={back}>
             <LeftOutlined />
         </Button>
       </div>
   </RowDiv> */}
    <RowDiv className="one-on-one-heading-seaction">
        <img style={{cursor:"pointer"}} src={Back_icon} alt="" onClick={()=>back()}/>
        {/* <div style={{display:"flex",flexDirection:"row",gap:"10px",justifyContent:'center',alignItems:'center'}}> */}
        <h2 className="one-on-one-head">EDIT 1 : 1</h2>
        {/* </div> */}
        <div className="logo-wrapper center item-center">
          <img  className="details-page-logos" src={delete_outline_24px} onClick={()=>setdConfirmationModalDeleteVisible(true)} alt=""  />
        </div>
    </RowDiv>
   <Form
     form={form}
     name={"1:1"}
     onFinish={() =>{handleSubmit()} }
     initialValues={{duration:meetingById?.scheduleDuration/3600}}
     >

    <Row gutter={5} justify="center" align="center" style={{marginBottom:"80px"}}>

    <Col className="schedule-one-on-one-container" lg={{ span:13 }}  md={{ span: 16 }} xs={{ span: 24 }} sm={{ span: 24 }}  style={{background:" #F5F5F5",
              border:" 1px solid #D9D9D9",borderRadius: "8px"}}>

       <Row gutter={10}>
          <Col lg={{ span: 24 }}  md={{ span: 24}} xs={{ span: 24 }} sm={{ span: 24 }} style={{marginTop:'15px'}}>
              <p className="one-on-one-form-title">Participant</p>
             <MultiSelectEmployee
               ref={ref}
               dataList = { employeeList }
               onChange = { (e)=>{handleMenuClick(e)} }
               initialData = { memberlist?.map(data=>data?.firstName) }
               memberListInvalid={memberListInvalid}
               searchKey={e=>searchInput(e)}
             />
              {
               memberListInvalid&& (
                   <p style={{color:'red',marginTop:'10px'}}>This field is required.</p>
               )
             }
           </Col>
           <Col lg={{ span: 24 }}  md={{ span: 24}} xs={{ span: 24 }} sm={{ span: 24 }} style={{marginTop:'15px'}}>
               <p className="one-on-one-form-title"> Title</p>
               <Popover
                 // eslint-disable-next-line jsx-a11y/anchor-is-valid
                 content={<a onClick={hide}>Close</a>}
                 title="Character Limit Exceeded"
                 trigger="click"
                 visible={visible}
                 placement="topRight"
               >
                <div style={{position:"relative"}}>
                  <Input
                    // disabled={memberlist?.length?false:true}
                    style={{
                      border: !titleInvalid?'1px solid #D9D9D9':'1px solid #ff4d4f', 
                      borderRadius:"8px",
                      height:"40px",
                      paddingLeft:"42px"           
                    }}
                    id="1_1_title_name"
                    value={title}
                    maxLength={200}
                    className="one-on-one-input-field-style"
                    // onChange={(e) => (setTitle(e.target.value))}
                    onChange={(e) =>handleTitleSubmit(e)}    
                    onClick={(e)=>{scrolltoview('1_1_title_name')}}
                    ref={ref}
                    autoComplete='off'
                  />
                  <img className='instant-form-icon' src={Meeting_title} alt="" style={{position:"absolute",left:"10px"}} />
                </div>

               </Popover>
               {
                 titleInvalid && (
                   <p style={{color:'red',marginTop:'10px'}}>This field is required.</p>
                 )
               }
           </Col>
       </Row>
       {meetingById && meetingById.when && (
        <Row justify="start" align="start" style={{gap:"30px"}} className="meeting-create-selction">
          <Col style={{marginTop:'15px'}}>
          <p className="one-on-one-form-title"> When</p>
          <Row style={{gap:'10px'}}>
            <div>
           <DatePickerField
             ref={ref}
             suffixIcon={<img src={calender_icon} alt=""/>}
             style={{ width: '100%',
             borderRadius:"8px",
             height:"40px",
             border: !dateInvalid ? '' : '1px solid #ff4d4f' }}
             format="YYYY-MM-DD"
             // onChange={e =>(e?setwhen(e):setwhen(null))}
             onChange={e =>handleChange(e)}
             id="1_1_when"
             onClick={(e)=>{scrolltoview('1_1_when')}}
             disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
             defaultValue={moment(meetingById.when)}
             // onSelect={e=>(e?setwhen(e):setwhen(null))}
             disabledTime={e=>disabledRangeTime(e)}
             allowClear={false}
           />
           {
             dateInvalid&& (
               <p style={{color:'red',marginTop:'-10px'}}>This field is required.</p>
             )
           }
           </div>
           <div>
            {/* <TimePicker  
              footer={false}
              showOk={false}
              disabled={when?false:true}
              showNow={false}
              format="hh:mm"
              style={{ width: '100%',
              maxWidth:"150px",
              borderRadius:"8px",
              height:"40px",
              border: !dateInvalid ? '' : '1px solid #ff4d4f' }}
              suffixIcon={<img src={time_icon} alt=""/>}
              disabledHours={()=>disabledHours()}
              disabledMinutes={(e)=>disabledMinutes(e)}
              defaultValue={moment(meetingById.when)}
              onChange={(e)=>{e?setSelectedTime(e):setSelectedTime(null)}}
              // value={selected_time?moment(selected_time):when?moment(when?._d):""}
              minuteStep={15}
              /> */}
               <CustomTimePicker onedit={true} selectedTime={selectedCustomTime} when={meetingById.when} onChange={handleTimeChange}/>
          </div>
          </Row>
         </Col>
          <Col className="oc-duration" style={{marginTop:'15px'}}>
          <p className="one-on-one-form-title">Duration</p>
       
          <div className={durationInvalid?'invalid-duration':''} style={{position:"relative"}}>
          <TextField type='number' className="one-on-one-input-field-style"  placeholder=' ' margin="0px" widthscale="100px" name="duration" style={{paddingRight:"20px"}}/>
            <img src={time_icon} alt="" style={{position:"absolute",right:"10px",top:"10px"}}/>
          </div>
         </Col>
        </Row>
       )}
        <Col span={24} style={{marginTop:'20px',background:"#FFF",padding:"10px"}}>
                  {/* <Tooltip title={memberlist?.length?'':'select members to enable the button'}>
                  <KeyButton  type="primary" id="1_1_agenda" disabled={memberlist?.length?false:true} onClick={()=>{setaddAgenda(true);scrolltoview('1_1_agenda')}}>Add Agenda</KeyButton>
                  </Tooltip> */}
                    <div className="agenda-create-overflow" style={{background:"#FFF",width:"100%",padding:"20px 10px",minHeight:"300px",maxHeight:"400px",overflow:"auto"}}>
                    {!addAgenda&&<div className="one-on-one-meeting-items-add-btn" onClick={()=>setaddAgenda(true)}>
                  + New Agenda
                  </div>}
                  {addAgenda&&
                  <Spin spinning={loading}>
                  <AddAgendaContainer 
                      {...props}
                      memberlist={memberlist}
                      header={"Add Agenda"}
                      onSubmit={(e)=>{SubmitAgenda(e)}}
                      me={me}
                      close={()=>{setaddAgenda(false);setSelectedItem()}}
                      agenda={selectedItem}
                      isAgenda={true}
                      customCss={true}
                  />
                  </Spin>}
                  {!addAgenda&&
                  <>
                      {agendaList?.length?agendaList?.map(item=>(
                          <div className="agend-list-div" style={{display:"flex",flexDirection:"row",gap:"20px",justifyContent:"space-between",alignItems:"center",marginTop:"10px"}}>
                              <h4 className="one-on-one-agenda-title" style={{textAlign:"left"}}>{item?.title}</h4>
                              <div style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",flexWrap:"nowrap",gap:"10px"}}>
                                  {item?.okrId?.id&&<Popover placement="bottomRight"content={<div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",width:"100%",padding:"10px 20px",maxWidth:"300px"}}>
                                      <h4 className="current-one-on-one-meeting-head" >{`Related to ${item?.okrId?.owner?.user?.firstName}'s`}</h4>
                                      <div style={{display:"flex",flexDirection:"row", justifyContent:"space-between",alignItems:"center",gap:"10px",marginTop:"-10px"}}>
                                          <div></div>
                                          <h4 className="current-one-on-one-meeting-head" style={{textTransform:"uppercase",color:"#009AF1",margin:"0"}}>{item?.okrId?.okrType==='KEY_RESULT'?'KEY RESULT':item?.okrId?.okrType==='MILE_STONE'?'MILESTONE':item?.okrId?.okrType}</h4>
                                          <img src={bookmark_link} alt="" style={{cursor:"pointer"}} onClick={()=>redirectToOkr(item?.okrId)} />
                                      </div>
                                      <p className="one-on-one-agenda-title" style={{paddingTop:"10px"}}>{item?.okrId?.title}</p>
                                      </div>} trigger="click">
                                      <img src={okr} alt="" style={{cursor:"pointer"}}/>
                                      </Popover>}
                                  {/* <img src={bookmark_disabel} alt="" style={{cursor:"pointer"}} /> */}
                                  <Dropdown overlay={
                                      <Menu style={{borderRadius:" 6.5px",boxShadow:" -0.5px 3px 13.5px -4.5px rgba(0, 0, 0, 0.31)",width:"80px",display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                                      <Menu.Item key="edit" style={{display:"flex",flexDirection:"row",justifyContent:'left',alignItems:"center",gap:"5px",fontFamily:"Poppins",fontWeight: 500,fontSize:"12px",cursor:"pointer",width:"100%"}}  onClick={()=>EditActionItem(item)}><img src={edit_icon} alt="" /> Edit</Menu.Item>
                                      <Menu.Item key="delete" style={{display:"flex",flexDirection:"row",justifyContent:'left',alignItems:"center",gap:"5px",fontFamily:"Poppins",fontWeight: 500,fontSize:"12px",cursor:"pointer",width:"100%"}} onClick={()=>RemoveAgenda(item)}><img src={delete_icon} alt="" />Delete</Menu.Item>
                                      </Menu>}placement="bottomCenter"><img src={three_dot} alt="" style={{cursor:"pointer"}}/>
                                  </Dropdown>
                              
                              </div>
                          </div>
                      )):null}
                  </>
                }
                </div>
        </Col>
      </Col>
     </Row>
     <div style={{position:'fixed',left:0,right:0,bottom:0,width:"100%",display:"flex",flexDirection:"row",gap:"20px",justifyContent:"center",alignItems:"center",padding:"20px 10px ",background:"#FFF",boxShadow:"-6px 2px 72px -11px rgba(0, 0, 0, 0.09)",zIndex:'2000'}}>
        <Button className="one-on-one-bo-back-btn"   onClick={()=>history.goBack()}>Go Back</Button>
        <Button className="one-on-one-save-btn" type="primary" htmlType="submit" disabled={addAgenda}>Update</Button>
      </div>
     {/* <div align="center">   
         <CreateButton type="primary" htmlType="submit" disabled={addAgenda}  style={{marginTop:'30px'}}>Save</CreateButton>
     </div> */}
   </Form>

 </div>
        )}
       
      </Spin>
    </>
  )
};

export default EditMeetingView;

const RowDiv =styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`
export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
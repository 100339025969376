import { Button, Modal,  Spin } from "antd";
import React from "react";
import { competency_assessment_home_icon } from "../icon";
import { useHistory } from "react-router-dom";
import '../css/competency-assessment.css'
import CompetencySearchView from "./competency-filter-view";
import { getIntFromString } from "modules/look";
import CompetecnyModalContainer from '../containers/ca-competency-modal-container'
import moment from "moment-timezone";
import ca_route from '../route/index'

const CompetencyAssessmentHomeView = ( props ) => {

    const { pageLoading, competency_assessment_list, onFilter, total_page_count, Pagination,me } = props
    const [ visible, setVisible ] = React.useState( false )
    const [ selected_page, setSelectedPage ] = React.useState( 1 )
    const history = useHistory()

    //Filter values
    const filter_data = [
        {
            key: "employee_User_FirstName_Icontains",
            label: "Employee Name",
            ui_flow: "input_box",
            default: true
        },
        {
            key: "role_Title_Icontains",
            label: "Role",
            ui_flow: "input_box",
        },
        {
            key: "employeeLevel_Icontains",
            label: "Employee Level",
            ui_flow: "input_box",
        }
    ]

    const onPagination = ( selected ) => {
        Pagination( { offset: ( selected - 1 ) * 9 } )
        setSelectedPage( selected )
    }

    const editCheck = ( deadline,assessedBy ) => {
        if ( moment().isBefore( moment( deadline ))&& assessedBy?.id===me?.employee?.id ) {
            return true
        }
        else {
            return false
        }
    }

    const remainingDays=(deadline)=>{
        let remainingDays = moment(deadline).diff(moment(), 'hours')
        if(remainingDays) {
        return Math.ceil(remainingDays/24)
        }
    }

    const Navigate =(item)=>{
        if(item?.user_details?.id){
            
            history.push( `${ca_route?.user_competency_assessment_detail?.replace(":id",getIntFromString( item?.user_details?.id ))}` )
        }
        else if(item?.email){
            
            history.push( `${ca_route?.user_email_competency_assessment_detail?.replace(":email",item?.email)}` )
        }
    }
    return (
        <>
            <Modal
                visible={visible}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='ca-modal'>
                <CompetecnyModalContainer {...props} onCancel={() => setVisible( false )} />
            </Modal>

            <div className="ca-root">
                   
                    <h4 className="ca-main-title" style={{textAlign:"center"}}>Competency Assessment Dashboard</h4>
  
                <div className="ca-section-container">
                    {me?.employee?.orgCeoEmployee?.totalCount>0||me?.employee?.orgPocEmployee?.totalCount>0||
                    me?.employee?.teamManagerEmployee?.totalCount>0||
                    me?.employee?.verticalHeadEmployee?.totalCount>0?<button className='ca-add-button' style={{ width: "8em", marginRight: "1.5em" }} onClick={() => history.push( ca_route?.competency_assessment_create )}>+ Add</button>:null}
                    <div style={{ display: 'flex', flexDirection: "row", gap: "1em", alignItems: 'center' }}>
                        <CompetencySearchView filterData={filter_data} onfilterChange={( e ) => onFilter( e )} />
                        <h4 className="ca-view-competecny-title" onClick={() => setVisible( true )}>View by Competency</h4>
                    </div>

                </div>

                <Spin spinning={pageLoading} style={{ marginTop: '2em' }}>
                    <div className="ca-employee-card-container">
                        {competency_assessment_list?.length > 0 && competency_assessment_list?.map( item => (
                            <div className="ca-employee-card-seaction">
                                <div className="ca-employee-card">
                                    <div style={{display:'flex',flexDirection:"column",width:"100%"}}>
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: "1em", padding: "1.5em" }}>
                                            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center', gap: '.75em' }}>
                                                {item?.user_details?.user?.profile?.profileImage ? <img className="cs-user-profile" src={item?.user_details?.user?.profile?.profileImage} alt="" /> :
                                                    <div className="cs-user-profile" ><h4 className="ca-profile-icon text-transform-capitalize">{item?.user_details?.user?.firstName?.charAt( 0 )?.toUpperCase() || item?.email.charAt( 0 )?.toUpperCase()}</h4></div>}
                                                <div>
                                                    <h4 className={`cs-user-name ${item?.user_details?.user?.firstName ? 'text-transform-capitalize' : ''}`}>{item?.user_details?.user?.firstName || item?.email}</h4>
                                                    <p className="cs-label">{item?.employee_role?.title}</p>
                                                </div>
                                            </div>
                                            {editCheck( item?.correctionDeadline,item?.assessedBy ) &&<div className="ca-edit-icon-container" style={{display:'flex',flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                                                <h4 className="ca_remaining_day" style={{background:remainingDays(item?.correctionDeadline)<=2?"#E13F3F":"#0098F0"}}>{remainingDays(item?.correctionDeadline)} Days left</h4>       
                                                <img className="ca-edit-icon" src={remainingDays(item?.correctionDeadline)<=2?competency_assessment_home_icon?.edit_read:competency_assessment_home_icon?.competency_edit} onClick={() => history.push( ca_route?.competency_assessment_edit?.replace(":assessment_id",getIntFromString( item?.id )) )} alt="" style={{ cursor: "pointer" }} />
                                                </div>}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: 'center', gap: '1em', flexWrap: "nowrap", padding: "0em 1.5em 1.5em 1.5em" }}>
                                            <p className="cs-label">Job Family :  {item?.employee_role?.jobfamilySet?.edges[ 0 ]?.node?.title}</p>
                                            <p className="cs-label">Employee Level : {item?.emplpyee_level}</p>
                                        </div>
                                        <div className="cs-employee-progress-container">
                                            {item?.functional_assessment &&
                                                ( <div className="cs-employe-progress-view" style={{ border: `1px solid ${item?.functional_assessment?.border}`, background: item?.functional_assessment?.background }}>
                                                    <p className="cs-label">Functional competency</p>
                                                    <h4 className="cs-employee-progress-status" style={{ color: item?.functional_assessment?.color }}>{item?.functional_assessment?.qualifyingLevelName}</h4>
                                                </div> )
                                            }
                                            {item?.generic_assessment && (
                                                <div className="cs-employe-progress-view" style={{ border: `1px solid ${item?.generic_assessment?.border}`, background: item?.generic_assessment?.background }}>
                                                    <p className="cs-label">Generic Competencies</p>
                                                    <h4 className="cs-employee-progress-status" style={{ color: item?.generic_assessment?.color }}>{item?.generic_assessment?.qualifyingLevelName}</h4>
                                                </div>
                                            )
                                            }
                                            {item?.other_assessment && (
                                                <div className="cs-employe-progress-view" style={{ border: `1px solid ${item?.other_assessment?.border}`, background: item?.other_assessment?.background }}>
                                                    <p className="cs-label">Other Competencies</p>
                                                    <h4 className="cs-employee-progress-status" style={{ color: item?.other_assessment?.color }}>{item?.other_assessment?.qualifyingLevelName}</h4>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <button className="cs-see-detail-btn" onClick={() =>Navigate(item)}>See Detail</button>
                                </div>
                            </div>
                        ) )}
                        {competency_assessment_list?.length === 0 && !pageLoading && <div className="ca-no-data">
                            <h4 className="ca-no-data-text">No Competency Assessment Found</h4>
                        </div>}
                    </div>
                    <div className="ca-employee-card-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                        <div></div>
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", gap: "1em" }}>
                            {selected_page > 1 && <Button className="ca-next-page-icon" onClick={() => onPagination( selected_page - 1 )}><img style={{ marginRight: ".5em" }} src={competency_assessment_home_icon?.prev_icon} alt="" /> Previous </Button>}
                            {selected_page < total_page_count && <Button className="ca-next-page-icon" onClick={() => onPagination( selected_page + 1 )}>Next Page <img style={{ marginLeft: ".5em" }} src={competency_assessment_home_icon?.next_icon} alt="" /></Button>}
                        </div>
                       {competency_assessment_list?.length > 0&&<h4 className="ca-page-count">Page <span>{selected_page}</span> of {total_page_count}</h4>}
                    </div>
                </Spin>

            </div>
        </>
    )

}

export default CompetencyAssessmentHomeView
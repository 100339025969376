import { all_survey_icon } from "modules/survey-module/icon";
import React from "react";
import { Row, Input, Button, Select, Spin } from "antd";
import styled from "styled-components";
import survey_module_route from "modules/survey-module/routes";
import CreateInvitationForMultippleEmployeeContainer from "../container/CreateIvitationForMultippleEmployees";
const CreateInvitationView = (props) => {
    const { survey_detial, sendInvitation, navigateRoute, loader } = props;
    const [selected_tab, setSelectedTab] = React.useState("invite_individual");
    const [email, setEmail] = React.useState();
    const [firstName, setfirstName] = React.useState();
    const [lastName, setlastName] = React.useState();
    const [respondent, setrespondent] = React.useState();
    const [messageAttachment, setmessage] = React.useState()
    const back = () => {
        navigateRoute("-1")
    };

    const handleInvitation = () => {
        sendInvitation({
            firstName: firstName,
            lastName: lastName,
            email: email,
            respondent: respondent,
            message: messageAttachment
        })
    }
    const cancel = () => {
        let url = survey_module_route.survey_360?.replace(":surveyid", props?.match?.params?.surveyid)?.replace(":responseid", props?.match?.params?.responseid)
        navigateRoute(url)
    }
    return (
        <Spin spinning={loader}>
            <div className="invite-container-padding">
                <RowDiv>
                    <img
                        src={all_survey_icon?.back_arrow}
                        alt="back_arrow"
                        onClick={back}
                        style={{ cursor: "pointer", width: "20px" }}
                    />
                    <h4 className="survey-invite-title" style={{ marginLeft: "1em" }}>
                        {" "}
                        Invite People To {survey_detial?.name} 360 Survey
                    </h4>
                </RowDiv>
                <div
                    className="invite-main-content-container"
                    style={{
                        background: "#fff",
                        boxShadow: " 0px 10px 15px 0px rgba(170, 186, 198, 0.2)",
                        marginTop: "1em",
                        padding: "1.5em 3em",
                        borderRadius: ".75em"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                        }}
                    >
                        <div className="invite-switch-tab">
                            <div
                                className={`invite-switch-tab-text ${selected_tab === "invite_individual"
                                    ? "invite-switch-tab-selected"
                                    : ""
                                    }`}
                                onClick={() => setSelectedTab("invite_individual")}
                            >
                                Invite Individual
                            </div>
                            <div
                                className={`invite-switch-tab-text ${selected_tab === "invite_multiple_people"
                                    ? "invite-switch-tab-selected"
                                    : ""
                                    }`}
                                onClick={() => setSelectedTab("invite_multiple_people")}
                            >
                                Invite Multiple people
                            </div>
                        </div>
                    </div>
                    {selected_tab === "invite_individual" && <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1em",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: ".5em",
                                width: "100%",
                            }}
                        >
                            <h4 className="survey-invite-form-title">
                                <span className="rd-input-star">*</span>Email
                            </h4>
                            <Input
                                className="survey-invite-form-title-input"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e?.target?.value?.trim())}
                                autoComplete="off"
                            />
                        </div>
                        <div
                            className="invite-wrap-container"
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: ".5em",
                                    width: "100%",
                                }}
                            >
                                <h4 className="survey-invite-form-title">
                                    <span className="rd-input-star">*</span>First name
                                </h4>
                                <Input
                                    className="survey-invite-form-title-input"
                                    placeholder="Enter first name"
                                    value={firstName}
                                    onChange={(e) => setfirstName(e?.target?.value?.trim()===""?"":e?.target?.value)}
                                    autoComplete="off"
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: ".5em",
                                    width: "100%",
                                }}
                            >
                                <h4 className="survey-invite-form-title">
                                    <span className="rd-input-star">*</span>Last name
                                </h4>
                                <Input
                                    className="survey-invite-form-title-input"
                                    placeholder="Enter last name"
                                    value={lastName}
                                    onChange={(e) => setlastName(e.target.value?.trim()===""?"":e?.target?.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: ".5em",
                                width: "100%",
                            }}
                        >
                            <h4 className="survey-invite-form-title">
                                <span className="rd-input-star">*</span>Respondent is  my
                            </h4>
                            <CustomSelect onChange={(e) => setrespondent(e)} placeholder='Select one'>
                                <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontSize: '1em', fontWeight: "500" }} key='manager' value='manager' >Manager</Select.Option>
                                <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontSize: '1em', fontWeight: "500" }} key='manager Of manager' value='manager of manager' >Manager of Manager</Select.Option>
                                <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontSize: '1em', fontWeight: "500" }} key='report' value='report' >Report</Select.Option>
                            </CustomSelect>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: ".5em",
                                width: "100%",
                            }}
                        >
                            <h4 className="survey-invite-form-title">
                                Attach message
                            </h4>
                            <textarea
                                className="survey-invite-form-title-input"
                                placeholder="Message"
                                style={{ height: "7em", padding: ".5em", resize: "none" }}
                                value={messageAttachment}
                                onChange={e => (setmessage(e?.target?.value?.trim()===""?"":e?.target?.value))}
                                autoComplete="off"
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: '1em', width: "100%" }}>
                            <Button className="survey-invite-cancel-btn" onClick={() => cancel()}>CANCEL</Button>
                            <Button className="survey-invite-send-btn" disabled={!firstName || !lastName || !email || !respondent} onClick={() => handleInvitation()}>SEND INVITE</Button>
                        </div>
                    </div>}
                    {selected_tab === "invite_multiple_people" && <CreateInvitationForMultippleEmployeeContainer {...props} />}
                </div>

            </div>
        </Spin>
    );
};

export default CreateInvitationView;
const RowDiv = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
`;
const CustomSelect = styled(Select)`
.ant-select-selector{
    border: 1px solid rgba(170, 186, 198, 1) !impoortant;
    color:  #000;
    font-weight: 400;
    font-family: Poppins;
    font-size: 1em;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align:left;
    border-radius:.5em !important;
    width:100% !important;
    height:2.7em !important;
}
`;
import React from 'react';
import {Col, Row} from 'antd';
import { displayUtcToUserTimezone, getIntFromString } from 'modules/look';
import green_arrow from '../../assets/green_arrow.svg'
import styled from 'styled-components';
import IndDashboardDropDownContainer from 'modules/look-v2/Container/IndDashboardDropDownContainer';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const UpcomingMilestones = (props) => {
  const { upcomingMilestones } = props

  return (
    <> 
     
     <MilestoneContainer>
     
     <ColScroll  style={{width:"100%",maxHeight:"400px",overflow:"auto"}} >
          {upcomingMilestones?.edges?.length ?
            upcomingMilestones?.edges.map((val, ind) =>
                <Row wrap={false} gutter={15} style={{ borderBottom: '1px solid #E8E8E8',width:'90%',margin:'auto' }}>
                  <Col span={17}
                    style={{ marginTop: '20px', marginBottom: '10px' }} >
                    <Row wrap={false}>
                      <div className='Manrope' style={{
                        fontSize: '14px', color: '#000000',marginBottom:'10px',
                        textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontWeight: '600',marginRight:'10px'
                      }}>
                        {val?.node?.okr?.title}
                      </div>
                      <div>
                      <Link to={`/role/objective-keyresult/milestone/view/${getIntFromString(val?.node?.okr?.id)}`} target="_blank">
                            <img style={{ height: '10px', width: '10px',cursor:"pointer",marginLeft:"5px" }}src={green_arrow} alt='green_arrow'/>
                          </Link>

                      </div>
                    </Row>
                    <Row align='middle'  wrap={false}>
                      <div className='Manrope' style={{ marginBottom: '10px', fontStyle: 'normal', paddingRight: '5px', color: '#8D8D8D',textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        {`${<span className='text-transform-capitalize'>{val?.node?.okr?.owner?.user?.firstName}</span>} ${<span className='text-transform-capitalize'>{val?.node?.okr?.owner?.user?.lastName}</span>}`}
                         </div>
                      <div>
                      <IndDashboardDropDownContainer okrId={val?.node?.okr?.id}  record={val?.node?.okr} {...props}/> 
                      </div>
                    </Row>
                  </Col>
                  <Col span={7}
                    style={{ marginTop: '20px', marginBottom: '10px' }} >
                    <div className='Manrope' style={{ overflowWrap: 'break-word', color: '#E86161', }}>{displayUtcToUserTimezone(val?.node?.okr?.duedate, "DD MMM YYYY")}
                    </div>
                  </Col>
                </Row>
              
            )
            :
            <div style={{textAlign: 'center',
              fontSize: '1.2em',
              fontWeight: '600',
              marginTop: '9em'
              }}>No Milestone Found</div>
          }
      </ColScroll>
      </MilestoneContainer>
    </>
  )
}
export default UpcomingMilestones


const MilestoneContainer = styled.div`
border-radius:15px;
width:100%;
background: #FFFFFF !important;
.ant-spin-container {
  background: #FFFFFF !important;
  margin-right: 10px !important;
  transition: opacity 0.3s;
}

.input-filter-dynamic{
  height:20px;
  width:20px;
  margin-bottom:10px;
  border:none;
}


`;

const ColScroll = styled(Col)`	
::-webkit-scrollbar {	
  height: 0px;	
  width: 8px;
  padding-left:10px;
}	
::-webkit-scrollbar-track {	

  display:none;
}	
::-webkit-scrollbar-thumb {	
  border-radius: 10px;	
  background-color:#F0F0F0;	
}`

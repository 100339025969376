import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import ActionListView from '../components/ActionItemListView';
const ActionListContainer =(props)=>{

    return(
        <>
        <ActionListView {...props}/>
        </>
    )

}

export default compose(withApollo)(ActionListContainer)
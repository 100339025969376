// @ts-nocheck
import React from 'react';
import { Row, Dropdown, Menu, Input, Button } from "antd";
import search_icon from '../../assets/search-gray-icon.svg'
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
import styled from 'styled-components';
import { competency_icons } from 'modules/role_definition/icon';
import { CloseCircleFilled } from '@ant-design/icons'
const IDPSearchView = ( props ) => {

  const {
    filterData,
    onfilterChange
  } = props;

  const [ selectedFilter, setFilter ] = React.useState()
  const [ type, setType ] = React.useState()
  const [ select_default, setSelectDefault ] = React.useState( false )
  React.useEffect( () => {
    if ( filterData && !selectedFilter ) {
      let find_value = filterData?.find( Item => Item.default === true )
      if ( find_value ) {
        setFilter( find_value )
      }
      else {
        setSelectDefault( true )
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ filterData ] )

  const filterValueChange = ( value ) => {
    let filter = {}
    filter[ selectedFilter?.key ] = value
    onfilterChange( filter )
  }

  //Filter choices render
  const Filtertype = ( choice ) => (
    <Menu style={{ maxHeight: '200px', overflow: 'auto' }} suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}>
      {
        choice && (
          choice?.map( ( data ) =>
            <Menu.Item className='rd-search-menu-title' style={{ color: selectedFilter?.label === data?.label ? '#4CCB1F' : '#BBBBBB' }} key={data?.key} onClick={() => { filterValueChange( data?.key ); setType( data?.label ) }}>
              {data?.label}
            </Menu.Item>
          )
        )
      }
    </Menu>
  );

  // Filter type category
  const FilterType = (
    <div>
      {( selectedFilter?.ui_flow === 'input_box' || select_default ) && (
        <div style={{ position: "relative" }}>
          <Input
            className='search-input-filter'
            // value={filterValue}
            onChange={e => ( filterValueChange( e.target.value ) )}
            autoComplete='off'
            placeholder="Search"
            style={{ width: "16em", borderRight: "1px solid #A9A9A9" }}
          />
          <img src={search_icon} alt="" style={{ position: 'absolute', left: "10px", width: "20px", top: "13px" }} />
        </div>
      )}
      {
        ( selectedFilter?.ui_flow === 'dropdown' ) && (
          <Dropdown overlay={Filtertype( selectedFilter?.choice )} overlayStyle={{zIndex:"1050"}} className="rd-input-filter-new">
            <DropdownButton style={{ borderLeft: '1px solid #D9D9D9', display: "flex", justifyContent: "center", alignItems: "center", width: "10em" }}>
              <p className='rd-search-menu-title' style={{ marginTop: '13px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>{type ? type : <span style={{ color: "#D9D9D9" }}>Select</span>}</p>
              {type ?
                <CloseCircleFilled style={{color:"rgba(14, 14, 44, 0.2)"}} onClick={() => { filterValueChange(); setType() }} /> :
                <img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
            </DropdownButton>
          </Dropdown> )
      }
    </div>
  )

  //Filter menu
  const Filtermenu = () => (
    <Menu style={{ maxHeight: '200px', overflow: 'auto' }} suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}>
      {
        filterData && (
          filterData?.map( ( data ) =>
            <Menu.Item className='rd-search-menu-title' style={{ color: selectedFilter?.label === data?.label ? '#4CCB1F' : '#BBBBBB' }} key={data?.key} onClick={() => { setFilter( data ) }}>
              {data?.label}
            </Menu.Item>
          )
        )
      }
    </Menu>
  );


  return (
    <>
      <Row style={{ flexWrap: "nowrap" }}>
        <div>
          {FilterType}
        </div>
        <Dropdown overlay={Filtermenu} overlayStyle={{zIndex:"1050"}} className="rd-input-filter" >
          <DropdownButton style={{ borderLeft: '1px solid #D9D9D9', display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p className="rd-search-menu-title" style={{ margin: "0px", width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>{selectedFilter?.label ? selectedFilter?.label : <p className='rd-search-drop-placeholder'>Filter</p>}</p>

            <img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />
          </DropdownButton>
        </Dropdown>
      </Row>
    </>
  )

}

export default IDPSearchView;
const DropdownButton = styled( Button )`
width:fit-content;
min-width:100px;
display:flex;
height:36px;
border-left:none;
justify-content:space-between;
align-items:center;
gap:5px
`
import React from 'react'
import { compose } from '../../core';
import { Row, Col, Button, Modal  } from "antd";
import { withUpdateContributorRequest } from '../container/objective-keyresult-operations';
import { getIntFromString } from 'modules/layout/Layout';
import { displayUtcToUserTimezone } from 'modules/look';
import QuickActonItem from '../formComponents/CreateNewActionItem'

const DiscussRequest =(props)=>{

    const {visible,data,onClose,updateContributorRequestStatus,Allmeeting, me,createActionItem,modalLoading,location }=props
    const [currentMeetingData,setCurrentMeetingData]=React.useState()
    const [quickMeeting,setquickMeeting]=React.useState(false)
    React.useEffect(()=>{
        if(Allmeeting){
            let currentMeeting=Allmeeting[0]?.node
            setCurrentMeetingData(currentMeeting)
        }
    },[Allmeeting])

    const cancel=()=>{
        onClose(onClose({response:false}))
    }
    const closeQuick= async(value)=>{
        if(value?.meeting){
            setquickMeeting(false)
            onClose({response:true})
        }
        else{
            setquickMeeting(false)
        }
    }
    const UpdateAgenda = async()=>{
        modalLoading(true)
        let actionItems={
            title: `Discuss ${data?.okr?.goalStatement}`,
            meetingId: getIntFromString(currentMeetingData?.id),
            okrId: getIntFromString(data?.okr?.id),
            employeeId: getIntFromString(me?.employee?.id),
            isAgenda: true,
            notes: `<a href=${window?.origin}${location?.pathname} target="_blank">Go to ${data?.okr?.goalStatement}</a>`
        }
        let response = await createActionItem({actionItemData:actionItems})
        if(response?.id){
            let response = await updateContributorRequestStatus({id:getIntFromString(data?.id),requestStatus:"NEEDS_DISCUSSION"})
            if(response){
                modalLoading(false)
                onClose({response:true})
            }
        }
        else{
            modalLoading(false)
        }
    }

    const FaceToFace =async()=>{
        modalLoading(true)
        let response = await updateContributorRequestStatus({id:getIntFromString(data?.id),requestStatus:"NEEDS_DISCUSSION"})
        if(response){
            onClose({response:true})
            modalLoading(false)
        }
    }

    return (
        <>
            <Modal
            footer={null}
            visible={visible}
            centered
            closable={false}
            destroyOnClose={true}
            className='custom-new-modal'>
                <Col style={{position:'relative'}}>
                    <div>
                        <p className="modal-sub-head">How would you like to discuss this <br/> with {data?.okr?.title} ?</p>
                    </div>
                    <div style={{width:"100%",padding:"0px 40px 20px 40px"}}>
                    {Allmeeting?.length>0&&(<>
                        <Button className='discuss-btn' onClick={()=>UpdateAgenda()}>Add to Upcoming 1:1 Agenda </Button>
                        <p className='meet-sub'>with {currentMeetingData?.owner?.user?.id!==me?.employee?.user?.id?<span className='text-transform-capitalize'>{currentMeetingData?.owner?.user?.firstName}</span>:currentMeetingData?.member?.edges[0]?.node?.user?.id!==me?.employee?.user?.id?<span className='text-transform-capitalize'>{currentMeetingData?.member?.edges[0]?.node?.user?.firstName}</span>:''} on {displayUtcToUserTimezone(currentMeetingData?.when,'DD MMM YYYY hh:mm a' )}</p>
                    </>)}
                    <Button className='discuss-btn' onClick={()=>setquickMeeting(true)}>Schedule a 1 on 1 </Button>
                    <Button className='discuss-btn' onClick={()=>FaceToFace()}>Discuss Face to Face</Button>
                    </div>
                    
                    <Row style={{width:"100%",gap:"20px",paddingTop:"20px"}} justify="center">
                        <Button className="modal-cancel-btn" onClick={cancel}>Cancel</Button>
                    </Row>
                </Col>
            </Modal>
            {
        quickMeeting?
         <Modal
         footer={null}
         visible={quickMeeting}
         centered
         closable={false}
         destroyOnClose={true}
         className='custom-new-modal'>
         <QuickActonItem me={me} onCloseQuick={(e)=>{closeQuick(e)}} {...props}/>

       </Modal>
       :null
      }
        </>
    )
}

export default compose(withUpdateContributorRequest)(DiscussRequest)
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import _ from "lodash";
import styled from "styled-components";
import {
  Col,
  Card,
  Row,
  Space,
  Spin,
  Progress,
  Form,
  Breadcrumb,
  Empty,
  Switch,
  Tooltip,
} from "antd";

import { getStrokeColor } from "../../look";
import { CloseOutlined } from "@ant-design/icons";

const FormItem = Form.Item;
const BreadcrumbItem = Breadcrumb.Item;

const MAppAnchorsView = (props) => {
  const { loading, MAppAnchors, filter, onIncludingManagerChange } = props;

  const [visible, setVisible] = useState(null);

  const getFactor = (factor, employee = visible?.employee) =>
    MAppAnchors[visible?.team].Employees[employee].socres[factor];

  function setFactor(factor) {
    if (visible?.factor === factor)
      setVisible({
        ..._.omit(visible, ["factor"]),
        anchorData: MAppAnchors[visible?.team].Overall,
      });
    else
      setVisible({
        ...visible,
        factor,
        anchorData: getFactor(factor, visible.employee),
      });
  }

  function setEmployee(employee) {
    const factor = Object.keys(
      MAppAnchors[visible?.team].Employees[employee].socres
    )[0];
    if (visible?.employee === employee) setVisible(null);
    else
      setVisible({
        ...visible,
        employee,
        factor,
        anchorData: getFactor(factor, employee),
      });
  }

  function setTeam(team) {
    if (visible?.team === team) setVisible(null);
    else setVisible({ team, anchorData: MAppAnchors[team].Overall });
  }

  return (
    <Col lg={visible ? 24 : 12} md={24} xs={24}>
      <Card
        style={{height: "100%" }}
        bodyStyle={{
          boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
          borderRadius: "2%",
          // paddingBottom: 0
        }}
        bordered={false}
      >
        <Spin spinning={loading}>
          <Row gutter={[24,24]} /* align="middle" */>
            <Col span={visible ? 10 : 24}>
              <div align="center">
                <Row align="middle" gutter={[24,34]}>
                  <Col>
                    <h2 style={{ fontWeight: "normal" }}>
                      My Teams
                      <br />
                      MApp Anchors
                    </h2>
                  </Col>
                  <Col>
                    <Tooltip title={"Include Manager"}>
                      <Switch
                        checked={filter.includingManager}
                        onChange={(value) => onIncludingManagerChange(value)}
                      />
                    </Tooltip>
                  </Col>
                </Row>

                <Col span={24}>
                  {MAppAnchors && Object.keys(MAppAnchors)?.length ? (
                    Object.keys(MAppAnchors)?.map((team, index) =>
                      team !== "Error" &&
                      Object.keys(MAppAnchors)?.length === 1 ? (
                        // list of teams
                        <Row>
                          <Col span={15} align="left">
                            <Row justify="center">
                              {!visible?.team ? (
                                <HoverSpace
                                  size="middle"
                                  align="center"
                                  onClick={() => setTeam(team)}
                                >
                                  <SequenceNo active={visible?.team === team}>
                                    {index + 1}
                                  </SequenceNo>

                                  <Colordiv active={visible?.team === team}>
                                    {team}
                                  </Colordiv>
                                </HoverSpace>
                              ) : visible?.factor ? (
                                Object.keys(
                                  MAppAnchors[visible?.team]?.Employees[
                                    visible?.employee
                                  ]?.socres
                                ).map(
                                  // list of  factors
                                  (factor, index) => (
                                    <Col span={24}>
                                      <HoverSpace
                                        size="middle"
                                        align="center"
                                        onClick={() => setFactor(factor)}
                                      >
                                        <SequenceNo
                                          active={visible?.factor === factor}
                                        >
                                          {index + 1}
                                        </SequenceNo>

                                        <Colordiv
                                          active={visible?.factor === factor}
                                        >
                                          {factor}
                                        </Colordiv>
                                      </HoverSpace>
                                    </Col>
                                  )
                                )
                              ) : (
                                Object.keys(
                                  MAppAnchors[visible?.team]?.Employees
                                ).map((employee, index) => (
                                  // list of employees
                                  <Col span={24}>
                                    <HoverSpace
                                      size="middle"
                                      align="center"
                                      onClick={() => setEmployee(employee)}
                                    >
                                      <SequenceNo
                                        active={visible?.employee === employee}
                                      >
                                        {index + 1}
                                      </SequenceNo>

                                      <Colordiv
                                        active={visible?.employee === employee}
                                      >
                                        {employee}
                                      </Colordiv>
                                    </HoverSpace>
                                  </Col>
                                ))
                              )}
                            </Row>
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <br />
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        </>
                      )
                    )
                  ) : (
                    <>
                      <br />
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    </>
                  )}
                </Col>
              </div>
            </Col>

            <Col span={visible ? 14 : 0}>
              {visible &&
                (!visible?.factor ? (
                  <Row justify={"space-between"}>
                    <Col span={20}>
                      <Breadcrumb separator=">">
                        <BreadcrumbItem>{visible?.team}</BreadcrumbItem>
                      </Breadcrumb>
                      <br />
                    </Col>
                    <Col span={4} align="right">
                      <CloseOutlined onClick={() => setVisible(null)} />
                    </Col>

                    <Col span={24} style={{ minHeight: "25vh" }}>
                      <Row align={"middle"} style={{ minHeight: "40vh" }}>
                        <Col span={20}>
                          {Object.keys(visible?.anchorData)?.map((factor) => (
                            <FormItem
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24, offset: 1 }}
                              label={factor}
                            >
                              <Progress
                                percent={(
                                  visible?.anchorData[factor] * 10
                                )?.toFixed(0)}
                                strokeColor={getStrokeColor(
                                  visible?.anchorData[factor]?.toFixed(2) || 0
                                )}
                              />
                            </FormItem>
                          ))}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : (
                  <Row justify={"space-between"}>
                    <Col span={20}>
                      <Breadcrumb separator=">">
                        <BreadcrumbItem
                          onClick={() => setFactor(visible?.factor)}
                        >
                          <a>{visible?.team}</a>
                        </BreadcrumbItem>
                        <BreadcrumbItem>{visible?.employee}</BreadcrumbItem>
                        <BreadcrumbItem>{visible?.factor}</BreadcrumbItem>
                      </Breadcrumb>
                      <br />
                    </Col>
                    <Col span={4} align="right">
                      <CloseOutlined onClick={() => setVisible(null)} />
                    </Col>

                    <Col span={24} style={{ minHeight: "25vh" }}>
                      <Row align={"middle"} style={{ minHeight: "40vh" }}>
                        <Col span={8}>
                          <Progress
                            type="circle"
                            percent={(
                              visible?.anchorData?.overall * 10
                            ).toFixed(0)}
                            strokeColor="#B0CB1F"
                            strokeWidth="10"
                          />
                        </Col>

                        <Col span={14}>
                          {visible &&
                            Object.keys(visible?.anchorData?.factors)?.map(
                              (factor) => (
                                <FormItem
                                  labelCol={{ span: 24 }}
                                  wrapperCol={{ span: 24, offset: 1 }}
                                  label={factor}
                                >
                                  <Progress
                                    percent={(
                                      visible?.anchorData?.factors[factor] * 10
                                    ).toFixed(0)}
                                    strokeColor={getStrokeColor(
                                      (visible?.anchorData?.factors[
                                        factor
                                      ]).toFixed(2) || 0
                                    )}
                                  />
                                </FormItem>
                              )
                            )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
            </Col>
          </Row>
        </Spin>
      </Card>
    </Col>
  );
};

export default MAppAnchorsView;

const SequenceNo = styled.div`
  border-radius: 50%;
  width: 28px;
  color: ${(props) => (props.active ? "#B0CB1F" : "#222")};
  height: 28px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  border: 1px solid #cacaca;
`;

const HoverSpace = styled(Space)`
  margin-bottom: 10px;
  &:hover {
    color: PRIMARY_CLR;
    cursor: pointer;
  }
`;
const Colordiv = styled.div`
  color: ${(props) => (props.active ? "#B0CB1F" : "#222")};
`;

import CreateTicketContainer from "./containers/createTicketContainer";
import MyTicketContainer from "./containers/myTicketContainer";
import ThankyouTicketContainer from "./containers/thankyouTicketContainer";
import TicketDetailsContainer from "./containers/TicketDetailsContainer";
import ROUTE from "./route";

export default [
    {
      name:"Create Ticket",
      path:ROUTE.create_ticket,
      component:CreateTicketContainer,
      protect:true,
      exact:true
    },
    {
        name:"my ticket",
        path:ROUTE.my_ticket,
        component:MyTicketContainer,
        protect:true,
        exact:true
    },
    {
      name:"Ticket Details",
      path:ROUTE.details,
      component:TicketDetailsContainer,
      protect:true,
      exact:true
    },
    {
      name:"Thank you Ticket",
      path:ROUTE.thank_you,
      component:ThankyouTicketContainer,
      protect:true,
      exact:true
    },
]
// @ts-nocheck
import React from 'react';
import gql from 'graphql-tag';
import { Spin, Empty } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

import { compose } from '../../../core';
import { Capitalize} from 'modules/look';
import { withLookUserFilterUpdating, withLookUserState } from 'modules/look/containers/LookOperations';
import SelectField from './selectField';



const ALL_EMPLOYEES_QUERY = gql`
  query allEmployees($nameIcontains: String) {
    allEmployees(nameIcontains: $nameIcontains) {
      edges {
        node {
          id
          user {
            id
            username
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const EmployeeFieldSelector = props => {
  const { allEmployees, loading, onNameChange, onFiltersRemove,onChangeSelect, skipIds = [] } = props;
  const handleFilterRemove = React.useRef(() => { });

  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);


  const choices =
    allEmployees &&
    allEmployees.edges
    .sort((a,b)=>a.node.user.firstName.toLowerCase().localeCompare(b.node.user.firstName.toLowerCase()))
      .filter(({ node }) => {
        return !skipIds.includes(node?.user?.id)
      })
      .map(({ node }) => ({
        label: node.user.firstName ? `${Capitalize(node.user.firstName)} ${Capitalize(node.user.lastName)||''}` : node.user.username,
        value: node?.user?.id
      }));


  const onChange=(id)=>{
   
    let emp= allEmployees.edges?.find(({node})=>node?.user?.id===id)
    onChangeSelect({empid:id,...emp?.node?.user})
  }

  return (
    <SelectField
      className="um-employee-selector"
      showSearch
      choices={choices}
      filterOption={false}
      onSearch={onNameChange}
      onBlur={handleFilterRemove.current}
      changeSelect={(e)=>{onChange(e)}}
      notFoundContent={
        loading ? (
          <div align="center">
            <Spin size="small" />
          </div>
        ) : (
          <Empty />
        )
      }
      {...props}
    />
  );
};

export default compose(
  withLookUserState,
  graphql(ALL_EMPLOYEES_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data: { loading, error, allEmployees, subscribeToMore, updateQuery } }) {
      return { loading, error, allEmployees, subscribeToMore, updateQuery };
    }
  }),
  withLookUserFilterUpdating
)(EmployeeFieldSelector);

/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useEffect } from 'react';
import { Row,Input} from "antd";
import search_icon from '../../assets/search-inverted.svg'
import '../css/FilterFeedbacks.css';

const FilterFeedbacks = props => {
    const {
        setReceivedHasLength,
        setSubmittedHasLength,
        filterData,
        searchBarData,
        onfilterChange,
        setSearchBardata,
        grabInputValue,
        isInputFreeze,
        activeTab
    } = props; 
    
    const [selectedFilter,setFilter]=React.useState('Feedback');
    const [filterList,setfilterList]=React.useState([]);
    const [filterValue,setfilterValue]=React.useState();

    React.useEffect(()=>{
        if(filterData){
            setfilterList(filterData)
        }
    },[filterData])

    useEffect(()=>{
        if (searchBarData?.input?.length) {
            if(activeTab === 'received') {
                setSubmittedHasLength(true);
            } else {
                setReceivedHasLength(true);
            }
        setSearchBardata({ ...searchBarData, input: null})
        setfilterValue(null)
        } else {
            if(activeTab === 'received') {
                setSubmittedHasLength(false);
            } else {
                setReceivedHasLength(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeTab])
       
    const filterValueChange=(value)=>{
        setfilterValue(value)
        
        switch (selectedFilter){
            case 'Feedback':{
              
                onfilterChange({title_Icontains:value})
                break
            }
            case 'User':{
              
                onfilterChange({actionitem_Title_Icontains:value})
                break
            }
            case 'OKR':{
              
                onfilterChange({actionitem_Title_Icontains:value})
                break
            }
            case '1:1':{
              
                onfilterChange({actionitem_Title_Icontains:value})
                break
            }
            default : break
        }
    }

    const FilterType =(
        <div>
            {(selectedFilter==='Feedback' || selectedFilter==='User'||selectedFilter==='OKR'||selectedFilter==='1:1') && (
                <div style={{position:"relative"}}>
                    <Input
                    style={{ padding: '0px 50px'}}
                    disabled={isInputFreeze}
                    className='feedback-search-input-filter search-bar-feedback'
                    // style={{border: "1px solid #D9D9D9"}}
                    value={filterValue}
                    onChange={e => (filterValueChange(e.target.value))}
                    autoComplete='off'
                    />
                    <img src={search_icon} alt="" style={{position:'absolute',left:"20px",width:"20px",top:"13px"}} />
                </div>  
            )}
        </div>
    )

      useEffect(()=>{
        grabInputValue(filterValue)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[filterValue])
      
    return (
        <>
            <Row style={{flexWrap:"nowrap"}}>
         
                <div>
                {FilterType}
                </div>
            </Row>
        </>
    )
}

export default  FilterFeedbacks;


import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import { USER_MANUAL_ALL_EMPLOYEE_LIST, USER_MANUAL_COMPARE_RESPONSE } from "modules/look-v2/graphql/survey-request-employee-list.gql";
import { getIntFromString, getSurveyBase64StringFromInt } from "modules/look";
import TeamUserManualView from "../components/TeamUserManualView";
import { GET_RESPONSE_FROM_REQUESTED_USER } from "../graphql/userReportData.gql";
import { message } from "antd";
import { USER_MANUAL_SURVEY_ID } from "../../../config";

const TeamUserManualContainer = (props) => {
    const { client, selected_tab, allowComparison } = props
    const [data_list, SetDataList] = React.useState([])
    const [data_loading, SetDataLoading] = React.useState(false)
    const [user_one_data, SetUserOneData] = React.useState()
    const [user_two_data, SetUserTwoData] = React.useState()
    const [question_list, SetQuestionList] = React.useState([])
    React.useEffect(() => {
        if (selected_tab?.key === "team_user_manual") {
            HandleComparison({})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected_tab])

    const HandleComparison = (filter) => {
        if (allowComparison) {
            getEmployeeList(filter)
        } else {
            getUserManualAcceptedRequest(filter)
        }
    }

    const getEmployeeList = async (filter) => {
        try { 
            const { data } = await client.query({
                query: USER_MANUAL_ALL_EMPLOYEE_LIST,
                fetchPolicy: 'network-only',
                variables: { ...filter, first: 25 }
            });
            if (data?.allEmployees) { 
                let list = data?.allEmployees?.edges?.map(({ node }) => {
                    return {
                        data_id: getIntFromString(node?.user?.id),
                        user: {
                            ...node?.user,
                            employee_id: node?.id
                        }
                    }
                }).sort((a,b)=>a?.user?.firstName?.toLowerCase().localeCompare(b?.user?.firstName?.toLowerCase()))
                SetDataList(list)
            }
        } catch (error) {

        }

    }

    const getUserManualAcceptedRequest = async (filter) => {
        try { 
            const { data } = await client.query({
                query: USER_MANUAL_COMPARE_RESPONSE,
                fetchPolicy: 'network-only',
                variables: { ...filter, first: 25,accepted:true,survey:getSurveyBase64StringFromInt(USER_MANUAL_SURVEY_ID) }
            });
            if (data?.allComparisonRequestFormUser) {
           
                let list = data?.allComparisonRequestFormUser?.edges?.map(({ node }) => {
                    return {
                        data_id: getIntFromString(node?.id),
                        user: {
                            ...node?.receiver,
                            employee_id: node?.receiver?.employee?.id
                        }
                    }
                }).sort((a,b)=>a?.user?.firstName?.toLowerCase().localeCompare(b?.user?.firstName?.toLowerCase()))
                // if(!filter?.receiver_FirstName_Icontains || me?.firstName?.toLowerCase()?.includes(filter?.receiver_FirstName_Icontains?.toLowerCase())){
                //     list.push({
                //         data_id: getIntFromString(me?.id),
                //         user: {
                //             id:me?.id,
                //             firstName:me?.firstName,
                //             lastname:me?.lastName,
                //             employee_id: me?.employee?.id
                //         }
                //     })
                // }
                SetDataList(list)
            }
        } catch (error) {

        }
    }

    const getRequestResponse = async (filter) => {
        SetDataLoading(true)
        try {
            const { data } = await client.query({
                query: GET_RESPONSE_FROM_REQUESTED_USER,
                variables: { ...filter },
                fetchPolicy: "network-only"
            });
            if (data?.getReportDataForRequestedUsersUserManualSurvey) {

                let user_manual_data = data?.getReportDataForRequestedUsersUserManualSurvey?.answerSet?.edges
                if (!user_manual_data?.length) {
                    if (filter?.type === "employee_1") {
                        SetUserOneData(null)
                    } else {
                        SetUserTwoData(null)
                    }
                    message.error("User has not taken user manual survey")
                    SetDataLoading(false)
                    return
                }
                let detail = {
                    user: filter?.user,
                    user_manual_data,
                    data_id:filter?.data_id
                }
                let question_list = user_manual_data?.map(({ node }) => {
                    return {
                        id: node?.question?.id,
                        questionText: node?.question?.questionText
                    }
                })
                SetQuestionList(question_list)
                if (filter?.type === "employee_1") {
                    SetUserOneData(detail)
                } else {
                    SetUserTwoData(detail)
                }
                SetDataLoading(false)
            } else {
                SetDataLoading(false)
                message.error("User has not taken user manual survey")
                if (filter?.type === "employee_1") {
                    SetUserOneData(null)
                } else {
                    SetUserTwoData(null)
                }
            }
        }
        catch (e) {
            SetDataLoading(false)
            message.error("User has not taken user manual survey")
            if (filter?.type === "employee_1") {
                SetUserOneData(null)
            } else {
                SetUserTwoData(null)
            }
        }
    }

    return (
        <TeamUserManualView
            {...props}
            data_loading={data_loading}
            data_list={data_list}
            SetUserTwoData={SetUserTwoData}
            SetUserOneData={SetUserOneData}
            getRequestResponse={getRequestResponse}
            user_one_data={user_one_data}
            user_two_data={user_two_data}
            question_list={question_list} 
            HandleComparison={HandleComparison}/>
    )
}

export default compose(withApollo)(TeamUserManualContainer);

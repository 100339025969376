import { dasboardIcons } from 'modules/home/dashboard-icons';
import React from 'react';
import AddNewEmployeeContainer from '../containers/addNewEmployeeContainer';
import home_route from 'modules/home/route';
import { getIntFromString } from 'modules/look';
import { Spin,Tooltip,Button } from 'antd'
const TeamsMembersView = ( props ) => {
    const { team_employees, navigateRoute, team_employee_loading,team_id } = props
    const [ add_new_member, setAddNewMember ] = React.useState( false )
    return (
        <>

            <div className='small-team-members-container' style={{ height: "32em", maxHeight: "32em" }}>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em" }}>
                    <h4 className="user-dashboard-small-card-title">Team Members</h4>
                   {team_employees?.length>0&&<Tooltip title={"Add new member"}>
                    <img src={dasboardIcons?.add_new_member} alt="add_new_member" style={{ cursor: "pointer" }} onClick={() => setAddNewMember( true )} />
                    </Tooltip>}
                </div>
                <Spin spinning={team_employee_loading}>
                  {team_employees?.length>0?<><div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: '.5em', alignItems: "center" }}>
                        <h4 style={{ fontFamily: "Poppins", fontSize: '2em', fontWeight: "500", color: "#000", margin: "0" }}> {team_employees?.length}</h4>
                        <p style={{ fontFamily: "Poppins", fontSize: '.85em', fontWeight: "400", color: "#535353", margin: "0" }}>Total</p>
                    </div>
                    <div style={{ overflow: "auto", display: "flex", flexDirection: "column", gap: ".5em", maxHeight: "21em", marginTop: "1em" }}>
                        {team_employees?.map( item => (
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
                                <h4 style={{ fontFamily: "Poppins", fontSize: '1em', fontWeight: "500", color: "#000", margin: "0", cursor: "pointer" }} onClick={() => navigateRoute( `${home_route?.employee_profile.replace( ':id', getIntFromString( item?.id ) )}?team=${getIntFromString(team_id)}` )}><span className='text-transform-capitalize'>{item?.user?.firstName}</span> <span className='text-transform-capitalize'>{item?.user?.lastName}</span></h4>
                                <p style={{ fontFamily: "Poppins", fontSize: '.85em', fontWeight: "400", color: "#8998A2", margin: "0" }}>{item?.memberSet?.edges[ 0 ]?.node?.role?.title ? item?.memberSet?.edges[ 0 ]?.node?.role?.title : "No role assigned"}</p>
                            </div>
                        ) )}
                    </div></>:
                    <div className='no-performance-container' style={{ border: "none" }}>
                    <img src={dasboardIcons?.no_team} alt="diversity_no_data" />
                    <h4 className='baner-tip-title' style={{textAlign:'center'}}>here you will see the list of your team members</h4>
                    <Button className="user-board-welcome-banner-button" style={{ height: "2.5em", fontSize: "1.1em", marginTop: '.75em' }} onClick={() => setAddNewMember( true )}>ADD MEMBERS</Button>
                    </div>
                    }
                </Spin>
            </div>
            {add_new_member && <AddNewEmployeeContainer add_employee={add_new_member} onCancel={() => setAddNewMember( false )} {...props} />}

        </>
    )
}

export default TeamsMembersView
// @ts-nocheck
import React from 'react'
import { Row, Col,message,Spin,Dropdown,Menu, Radio, Form,Tooltip } from "antd";
import styled from "styled-components";
// import { LeftOutlined,DownOutlined,UpOutlined,PlusOutlined } from "@ant-design/icons";
import InfoCircle from '../../assets/InfoCircle.svg'
import NumberInputField from '../../Objective-keyresult/formComponents/Number-input-field'
import Supervisor from '../../assets/supervisor.svg'
import upArrow from '../../assets/up-arrow.svg'
import gql from 'graphql-tag';
import {useMutation } from '@apollo/client';
const IndDashboardExpDropDown =(props)=>{
    const {me, defaultSelectedAssigne,defaultSelectedCollaborator,response,allokrweights, loadingcollab,setloadingcollab,getOKRContributorList }=props
    const [OpenMenu,setOpenMenu]=React.useState(false)
    const [eligibilityType, seteligibilityType] = React.useState('assign');
    const [selectedCollaborate,setSelectedCollaborate]=React.useState([])
    const [selectedAssigne,setSelectedAssigne]=React.useState(null)
    const [form] = Form.useForm();
    const [contributor,setContributor]=React.useState()
    const normalemployee = false
    let previous_assigne=React.useRef()

    React.useEffect(()=>{
        if(OpenMenu){
            getOKRContributorList()
            // employeeSearch('')
        }else{
          
            setContributor(null) 
            setSelectedCollaborate([])
            form.setFieldsValue({})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[OpenMenu])

    React.useEffect(()=>{
        if(allokrweights){
            let collab = allokrweights?.edges?.filter(({node})=> node?.role === 'COLLABORATOR')
            
            if(!collab?.find(({node})=>node?.employee?.id===me?.employee?.id)){
                collab.push({
                    node:{
                        employee:me?.employee,
                        id:null,
                        weight:100,
                        role:"COLLABORATOR"
                    }
                })  
            }
           
            let assigne = allokrweights?.edges?.filter(({node})=> node?.role === 'ASSIGNED')
            let collablist = collab?.map(({node})=>{
                let value = {
                    ...node?.employee,
                    contributorId:node?.id,
                    weight:node?.weight,
                    role:node?.role
                }
                return JSON.stringify(value)
            })
            setSelectedCollaborate(collablist)
       
            if(assigne?.length){
                let emp = assigne[0]?.node?.employee
                previous_assigne['current']=assigne[0]?.node?.employee
                setSelectedAssigne(`${emp?.id}_${emp?.user?.firstName}_${emp?.user?.lastName||''}`)
            }else{
                previous_assigne['current']=null
                setSelectedAssigne(null) 
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allokrweights,loadingcollab])

    React.useEffect(()=>{
        if(defaultSelectedAssigne){
            setSelectedAssigne(defaultSelectedAssigne)
        }
    },[defaultSelectedAssigne])

    React.useEffect(()=>{
        if(defaultSelectedCollaborator){
            setSelectedCollaborate(defaultSelectedCollaborator)
        }
    },[defaultSelectedCollaborator])
    
    const CREATE_CONTRIBUTOR_DYNAMIC =gql`
    mutation createContributor {
      ${contributor?.filter(item=>!item?.isUpdate)
        ?.map(
          (item,index) =>
           `create${index}: createContributor(contributorData:{okrId:${item?.okrId},employeeId:${item?.employeeId},role:"${item?.role}"${item?.weight?`,weight:"${item?.weight}"`:""}}) {
            contributor{
              id
              employee{
                id
                user{
                  id
                  firstName
                  lastName
                }
              }
            }
           }
         `
        )
      .join('')}
      ${contributor?.filter(item=>item?.isUpdate)
        ?.map(
          (item,index) =>
           `update${index}: updateContributor(id:${item?.contributorId},weight:"${item?.weight}") {
            contributor{
              id
              employee{
                id
                user{
                  id
                  firstName
                  lastName
                }
              }
            }
           }
         `
        )
      .join('')}
     }
    `;
  
    // eslint-disable-next-line no-unused-vars
    const [contributorDefinition ,{ data, isloading, error }] = useMutation(CREATE_CONTRIBUTOR_DYNAMIC);

    React.useEffect(()=>{
        if(contributor && contributor?.length){
            createContributorMutation()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[contributor])

const createContributorMutation=async()=>{
        setloadingcollab(true)
        const response_data = await contributorDefinition()
        setloadingcollab(false)
        // new to do send back the response as props
        if(response_data){
            message.success(`${eligibilityType==='assign'?'Assigne':'Collaborators'} Updated`)
            response(response_data)
            setOpenMenu(false)
        }
        
    }

    const changeTab=(event)=>{
        seteligibilityType(event?.target?.value)
    }
    
    const getInitialCollab =()=>{
        let initial ={}
        selectedCollaborate.forEach(item=>{
            let data = JSON.parse(item)
            if(data?.contributorId){
                initial[data?.id]=data?.weight??0
            }else if(data?.id===me?.employee?.id){
                initial[data?.id]=data?.weight??0
            }
        })
        return initial
    } 

    const dynamicIsland =()=> (

        <Menu className='custom-menu-dashboard' >
        <Row align='middle'   style={{flexDirection:"column",padding:"20px",position:'relative'}}>
            <h4 className='menu-head'>Contributors <Tooltip overlayStyle={{zIndex:'1160'}} title={'Choose Assign if you want to delegate this task to somebody else. Choose collaborate if it a combined task.'}><img src={InfoCircle} alt="" /></Tooltip></h4>
            <img src={upArrow} alt="" style={{position:"absolute",top:'10px',right:"10px",cursor:"pointer"}} onClick={()=>setOpenMenu(false)} />
           
           {
            !normalemployee && (
                <Col style={{marginBottom:'20px'}}>
                <Radio.Group value={eligibilityType} onChange={(e) => changeTab(e)}>
                    <RadioButton disabled={normalemployee} style={{borderRight:"none",width:"120px"}} className={`section-assign ${eligibilityType==='assign'?'active-assign':''}`} value="assign">Assign</RadioButton>
                    <RadioButton style={{width:"120px"}}  className={`section-collab ${eligibilityType ==='collaborate'?'active-collab':''}`} value="collaborate">Collaborate</RadioButton>
                </Radio.Group>
            </Col>
            )
           }

          
            
                    <Spin spinning={loadingcollab}>
                    <div className='select-content-div'>
                {
                    selectedCollaborate?.length?
                    <Form
                    scrollToFirstError={true}
                    className="formLabel"
                    initialValues={getInitialCollab()}
                    form={form}>
                    {(eligibilityType==='collaborate'&&selectedCollaborate?.length)? <h4 className='weight-head'>Weight <Tooltip><img src={InfoCircle} alt="" style={{paddingLeft:"10px",cursor:'pointer'}}/></Tooltip></h4>:''}
                    {(eligibilityType==='collaborate'&& selectedCollaborate?.length)? selectedCollaborate?.map((data,index)=>{
                        let value = JSON.parse(data)
                        return (
                            <div className='item-div'>
                            <div className='selected-item row'>
                                <p style={{width:"100%",overflow:"hidden",textOverflow:"ellipsis"}}><span className='text-transform-capitalize'>{value?.user?.firstName}</span> <span className='text-transform-capitalize'>{value?.user?.lastName}</span></p>
                                {/* {
                                    value?.id!==me?.employee?.id?
                                    // <img src={CloseIcon} alt="" onClick={(e)=>{deleteCollabrator(value)}} style={{cursor:'pointer'}} />
                                    :""
                                } */}
                            </div>
                            <NumberInputField className="progress-input" style={{width:"55px",height:"40px"}} disabled={true} type='number' maxValue={100} name={value?.id}></NumberInputField>
                        </div>
                        )
                    }):''}
                </Form>
                :''
                }
                
                {
                    eligibilityType==='assign'&& selectedAssigne&&(
                        <div className='selected-item'>
                        <p style={{width:"100%",overflow:"hidden",textOverflow:"ellipsis"}}>{selectedAssigne?.split('_')[1]} {selectedAssigne?.split('_')[2]||''}</p>
                        {/* <img src={CloseIcon} alt="" style={{cursor:'pointer'}} onClick={()=> setSelectedAssigne('')}/> */}
                    </div>
                    )
                }
            </div>
                    </Spin>
        </Row>
       
         </Menu>
    );

    return (
        <>
         <Dropdown overlay={dynamicIsland}  visible={OpenMenu} trigger="click" placement="bottomLeft"  onClick={()=>setOpenMenu(true)} className="input-filter-dynamic" >
                <img  src={Supervisor} alt="" style={{cursor:"pointer"}}/>
            </Dropdown>
         {OpenMenu ? (<div className='overlay-dynamic-island' onClick={() => setOpenMenu(false)} />) : null}
         
        </>
    )
}

const RadioButton = styled(Radio.Button)`
`;

export default IndDashboardExpDropDown
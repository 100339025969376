import React from "react";
import '../../css/poc-dashboard-style.css'
import okrModuleUsageContainer from "./usage_module/okrModuleUsageContainer";
import { globalPermissionValidator } from "modules/look";
import { poc_admin_permission } from "Permissions/poc_admin.permission";
import { Input, Select, Spin } from "antd";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import soulModuleUsageContainer from "./usage_module/soulModuleUsageContainer";
import idpModuleUsageContainer from "./usage_module/idpModuleUsageContainer";
import meetingModuleUsageContainer from "./usage_module/meetingModuleUsageContainer";
import readinessModuleUsageContainer from "./usage_module/readinessModuleUsageContainer";
import feedbackModuleUsageContainer from "./usage_module/feedbackModuleUsageContainer";
import KudosModuleUsageContainer from "./usage_module/kudosModuleUsgaeContainer";
import GrowModuleUsageContainer from "./usage_module/coachingConvModuleUsageContainer";
import MappReviewContainer from "./usage_module/MappReviewContainer";
import { ca_create_icon } from "modules/competency-assessment/icon";
import moment from "moment-timezone";
import search_icon from '../../../assets/search-gray-icon.svg'
const ModuleUsageReportContainer = (props) => {
    const { match, me, userPermission, location } = props
    const [permission, setPermission] = React.useState()
    const [selected_filter, setSelectedFilter] = React.useState("all")
    const [date_range_filter, setDateRangeFilter] = React.useState()
    const [search,setSearch] =  React.useState()
    let tab_list = [
        {
            key: "mapp_review",
            label: "Mapp Overview"
        },
        {
            key: "module_usage",
            label: "Module Usage"
        }
    ]

    const [current_tab, SetCurrentTab] = React.useState(tab_list?.find(i => String(i?.key) === String(match?.params?.tab)) || {
        key: "mapp_review",
        label: "Mapp Review"
    })
    const [selected_module, SetModule] = React.useState("soul")
    const module = {
        okr: okrModuleUsageContainer,
        soul: soulModuleUsageContainer,
        idp: idpModuleUsageContainer,
        meeting: meetingModuleUsageContainer,
        readiness: readinessModuleUsageContainer,
        feedback: feedbackModuleUsageContainer,
        kudos: KudosModuleUsageContainer,
        coaching: GrowModuleUsageContainer
    }
    React.useEffect(() => {
        if (location?.search) {
            let params = new URLSearchParams(location.search)
            let module_tab = params?.get('module')
            if (module[module_tab]) {
                SetModule(module_tab)
            } else {
                SetModule("okr")
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length && me) {
            let permission = globalPermissionValidator(poc_admin_permission, userPermission)

            if (permission.required_permission && !me?.employee?.orgPocEmployee?.totalCount) {
                permission.required_permission = false
            }
            if (permission.required_permission && !me?.employee?.organizationSet?.edges?.length) {
                permission.required_permission = false
            }

            setPermission(permission)
        }
    }, [userPermission, me])

    React.useEffect(() => {
        let filter = {};
        const endDate = moment();

        switch (selected_filter) {
            case '24_hours':
                filter = {
                    start_date: moment().subtract(24, 'hours'),
                    end_date: endDate
                };
                break;
            case '7_days':
                filter = {
                    start_date: moment().subtract(7, 'days'),
                    end_date: endDate
                };
                break;
            case '30_days':
                filter = {
                    start_date: moment().subtract(30, 'days'),
                    end_date: endDate
                };
                break;
            case '6_months':
                filter = {
                    start_date: moment().subtract(6, 'months'),
                    end_date: endDate
                };
                break;
            case '1_year':
                filter = {
                    start_date: moment().subtract(1, 'years'),
                    end_date: endDate
                };
                break;
            default:
                filter = {};
        }
        setDateRangeFilter(filter);
    }, [selected_filter]);

    const CurrentView = module[selected_module]

    let module_list = [
        {
            label: "SOUL",
            key: "soul"
        },
        {
            label: "IDP",
            key: "idp"
        },
        {
            label: "OKR",
            key: "okr"
        },
        {
            label: "Readiness Zone",
            key: "readiness"
        },
        {
            label: "Feedback",
            key: "feedback"
        },
        {
            label: "Coaching Conversation",
            key: "coaching"
        },
        {
            label: "Kudos",
            key: "kudos"
        },
        {
            label: "1:1 Meeting",
            key: "meeting"
        }
    ]
    const date_filter = [
        {
            key: 'all',
            label: 'All',
        },
        {
            key: '1_year',
            label: '1 Year',
        },
        {
            key: '6_months',
            label: '6 Months',
        },
        {
            key: '30_days',
            label: '30 Days',
        },
        {
            key: '7_days',
            label: '7 Days',
        },
        {
            key:'24_hours',
            label:'24 Hours',
        }
    ]
    return (
        <>
            {(permission && !permission?.required_permission) && (<NoPermissionView />)}

            {
                (permission && permission?.required_permission) && (
                    <div className="poc-org-root module-usage-root-style"  >

                        <div className='poc-org-nav-view'>
                            {
                                tab_list?.map(sidebar => (

                                    <div className={`rd-sidebar-normal ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar' : ''}`} onClick={() => SetCurrentTab(sidebar)}>
                                        <h4 className={`rd-sidebar-label ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar-label' : ''}`}>{sidebar?.label}</h4>
                                    </div>
                                ))
                            }
                            <div className='border-line'>

                            </div>
                        </div>
                        <div className="module-usage-outlet" style={{width:"100%"}}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: 'center', gap: '1em', marginRight: "1.5em",width:'98%',flexWrap:"wrap" }}>
                                {
                                    String(current_tab?.key )=== "module_usage" ?
                                        <div style={{ width: "100%", marginTop: "1em",maxWidth:"20%" }}>
                                            <div style={{ width: "220px" }}>
                                                <Select
                                                    className="rd-select-filed rd-type-select"
                                                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                                                    suffixIcon={<img src={ca_create_icon?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                                                    showSearch={false}
                                                    defaultValue={selected_module}
                                                    onSelect={(e) => { SetModule(e) }}
                                                    filterOption={false}
                                                    dropdownClassName="content-dropdown"
                                                    {...props}
                                                >
                                                    {module_list?.map(value => (
                                                        <Select.Option
                                                            className='rd-select-option'
                                                            key={value?.key}
                                                            value={value?.key}
                                                        >
                                                            {value?.label}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        </div> : <div></div>
                                }
                                {String(current_tab?.key )=== "module_usage" &&
                                    <div style={{ display: 'flex', flexDirection: "row", gap: '1em', justifyContent: 'start', alignItems: 'center',marginTop: "1em",flexWrap:"wrap"}}>
                                        <div style={{ position: "relative" }}>
                                            <Input
                                                className='search-input-filter'
                                                onChange={(e) => { setSearch(e?.target?.value) }} 
                                                value={search}
                                                autoComplete='off'
                                                placeholder="Search"
                                                style={{ maxWidth: "16em", borderRight: "1px solid #A9A9A9", borderRadius: ".5em",height:"2.5em" }}
                                            />
                                            <img src={search_icon} alt="" style={{ position: 'absolute', left: "10px", width: "20px", top: "10px" }} />
                                        </div>
                                        <div className="usage-date-filter-container" style={{flexWrap:"wrap"}}>
                                            {date_filter?.map(filter => (
                                                <div className={String(filter?.key) === String(selected_filter )? "usage-date-filter-label-selected" : "usage-date-filter-label"} onClick={() => setSelectedFilter(filter?.key)}>{filter?.label}</div>
                                            ))}
                                        </div>
                                    </div>}
                            </div>
                            {
                                String(current_tab?.key) === "mapp_review" ?
                                    <MappReviewContainer {...props} permission={permission} /> : CurrentView ? <CurrentView  {...props} permission={permission} date_range_filter={date_range_filter} search={search} /> : ""
                            }

                        </div>

                    </div>
                )
            }
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }

        </>
    )
}

export default ModuleUsageReportContainer;
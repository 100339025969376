import React from "react";
import { Progress, Form } from "antd";

import { getStrokeColor } from "../../../look";

import { withMyOrganizationMAppPulseCheck } from "../../containers/DashboardOperations";

const FormItem = Form.Item;

const RenderMyOrganizationMAppPulseCheck = ({
  loading,
  myOrganizationMAppPulseCheck,
}) => (
  <FormItem
    labelCol={{ span: 24 }}
    wrapperCol={{ span: 24, offset: 1 }}
    label={"Organization’s MApp Pulse Check​"}
  >
    <Progress
      format={(percent) => percent / 10 + "%"}
      percent={
        (myOrganizationMAppPulseCheck &&
          JSON.parse(myOrganizationMAppPulseCheck)?.overall?.toFixed(2) * 10) ||
        0
      }
      strokeColor={getStrokeColor(
        (myOrganizationMAppPulseCheck &&
          JSON.parse(myOrganizationMAppPulseCheck)?.overall?.toFixed(2)) ||
          0
      )}
    />
  </FormItem>
);

export default withMyOrganizationMAppPulseCheck(
  RenderMyOrganizationMAppPulseCheck
);

// modifications on line
// 6
// 11(2)
// 12
// 16(2)
// 19(2)
// 25(2)

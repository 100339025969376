import gql from 'graphql-tag';

export const OBJECTIVE_TYPE = gql`
  fragment ObjectiveType on ObjectiveType {
    id
  }
`;

export const KEYRESULT_TYPE = gql`
  fragment KeyResultType on KeyResultType {
    id
    title
  }
`;

export const MILESTONE_TYPE = gql`
  fragment MilestoneType on MilestoneType {
    id
    title
  }
`;

export const MESSAGE_TYPE = gql`
  fragment OKRMessageType on OKRMessageType {
    id
  }
`;

export const FEEDBACK_TYPE = gql`
  fragment FeedbackType on FeedbackType {
    id
  }
`;

export const READINESS_TYPE = gql`
  fragment ReadinessType on ReadinessType {
    id
  }
`;

export const READINESS_LEVEL_TYPE = gql`
  fragment ReadinessLevelType on ReadinessLevelType {
    id
  }
`;

export const MEETING_TYPE = gql`
  fragment MeetingType on MeetingType {
    id
  }
`;

export const MEETING_NOTES_TYPE = gql`
  fragment MeetingNotesType on MeetingNotesType {
    id
  }
`;

export const ACTION_ITEM_TYPE = gql`
  fragment ActionItemType on ActionItemType {
    id
  }
`;

export const CONTACT_REQUEST_TYPE = gql`
  fragment ContactRequestType on ContactRequestType {
    id
  }
`;

export const EMPLOYEE_TYPE = gql`
  fragment EmployeeType on EmployeeType {
    id
  }
`;

export const ORGANIZATION_TYPE = gql`
  fragment OrganizationType on OrganizationType {
    id
  }
`;

export const VERTICAL_TYPE = gql`
  fragment VerticalType on VerticalType
  {
    id
  }
`;

export const TEAM_TYPE = gql`
  fragment TeamType on TeamType
  {
    id
  }
`;

export const COMMENT_TYPE = gql`
  fragment CommentType on CommentType
  {
    id
  }
`;

export const RESPONSE_TYPE = gql`
  fragment ResponseType on ResponseType
  {
    id
  }
`;

export const TOKEN_TYPE = gql`
  fragment TokenType on TokenType
  {
    id
  }
`;

export const RESPONSE_COMPARE_REQUEST_TYPE = gql`
  fragment ResponseCompareRequestType on ResponseCompareRequestType
  {
    id
  }
`;

export const DEFAULT_COMMITMENT_PROGRESS_TYPE = gql`
  fragment DefaultCommitmentProgressType on DefaultCommitmentProgressType
  {
    id
  }
`;

export const CUSTOM_USERCOMMITMENT_TYPE = gql`
  fragment CustomUserCommitmentType on CustomUserCommitmentType
  {
    id
  }
`;

export const COACHING_COMMITMENT_PROGRESS_TYPE = gql`
  fragment CoachingCommitmentProgressType on CoachingCommitmentProgressType
  {
    id
  }
`;

export const COMPETENCY_TYPE = gql`
  fragment CompetencyType on CompetencyType {
    id
    title
  }
`;

export const OKR_TYPE = gql`
  fragment okrType on okrType {
    id
    title
    okrType
  }
`;

export const CONTRIBUTOR_TYPE = gql`
  fragment ContributorType on ContributorType {
    id
    okr{
      id
      okrType
    }
  }
`;
export const TRACKING_TYPE = gql`
  fragment TrackingType on TrackingType {
    id
    okr{
      id
      okrType
    }
  }
`;
export const DISCARD_REQUEST_TYPE = gql`
  fragment DiscardRequestType on DiscardRequestType {
    id
    okr{
      id
      okrType
    }
    
  }
`;

export const BADGE_AWARD_TYPE = gql`
fragment BadgeAwardType on BadgeAwardType{
  id
  badge{
    id
    title
    image
    badgeFamily{
      id
      title
    }
    behaviouralDescription
  }
    evidence
    expirationDate
    issuer{
      id
      user{
        id
        firstName
        lastName
      }
    }
}
`;

export const IDP_TYPE = gql`
  fragment IdpType on IdpType {
    id
  }
`;

export const SKILL_TYPE = gql`
  fragment skillType on skillType {
    id
  }
`;



export const BADGE_AWARD_COMMENT_TYPE = gql`
  fragment BadgeAwardCommentType on BadgeAwardCommentType {
    id
  }
`;

export const BADGE_AWARD_REACTION_TYPE = gql`
  fragment BadgeAwardReactionType on BadgeAwardReactionType {
    id
  }
`;


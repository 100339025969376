// @ts-nocheck
import React from 'react';
import {Table } from "antd";
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import MeetingCard from './OneOnOneMeetingCard';

const UpcomingMeetingView = props => {
  const {
    upcomingMeeting,loading,selectedTab,onPaginantion,onFilterList
  } = props;
  // eslint-disable-next-line no-unused-vars
  const antIcon = <LoadingOutlined style={{ fontSize: "34px",color:"#4CCB1F" }} spin />;
  const [dataSource, setDataSource] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pageloading, setpageloading] = React.useState(false)
  const [nextPage,setNextPage]=React.useState(false)
  const [currentPage, setcurrentPage] = React.useState(1)
  let currentFilter = React.useRef({})

  React.useEffect(()=>{
    if(upcomingMeeting){
      setNextPage(upcomingMeeting?.pageInfo?.hasNextPage)
      let list =  upcomingMeeting?.edges?.map(({node})=>node)
      if(dataSource?.length&&onFilterList){
        let finalMetingList =dataSource?.concat( list)
        var setObj = new Set();
        let meetList= finalMetingList.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])
        setDataSource(meetList)
      }
      else{
        setDataSource(list)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[upcomingMeeting,onFilterList])

  const pagination=()=>{
    let current_page=currentPage
    currentFilter.current['offset']=current_page*20
    setcurrentPage(current_page+1)
    setpageloading(true)
    onPaginantion({...currentFilter?.current,pagination_value:true})
  }

  return (
    <>
       <div className='agenda-create-overflow' style={{width:"100%",maxHeight:'440px',overflow:'auto',height:"100%",minHeight:"440px"}}>
          <MeetingCard dataSource={dataSource} selectedTab={selectedTab} loading={loading} {...props} paginationLoad={()=>pagination()} nextPage={nextPage} tab="upComming"/>
       </div>
      
    </>
  );
};

export default UpcomingMeetingView;

export const MyTable = styled(Table)`
  table {
    border-spacing: 0 1em;
    tbody {
      tr {
        td {
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom-right-radius: 5px !important;
          border-top-right-radius: 5px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;
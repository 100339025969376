import React from 'react';
import { Row, Col, Card,Spin, Empty } from 'antd';
import styled from 'styled-components';

import { getIntFromString } from '../../look';
import { USER_IMG } from '../../../config';
// import CompareYourScore from '../containers/CompareYourScore';

const ManagerTeamComponent = props => {
  const { me, activeEmployee, setActiveEmployee, setActiveUser } = props;

  return (
    <>
      <Col lg={{ span: 8 }} xs={{ span: 24 }}>
        <Card
          bodyStyle={{
            boxShadow: '5px 0px 25px 3px rgba(32, 32, 32, 0.1)',
            borderRadius: '2%'
          }}
          bordered={false}
        >
          <Row>
            <h2 style={{ fontWeight: 'normal' }}>Team</h2>
          </Row>
          <Spin spinning={false}>
            <Row gutter={[12, 12]}
              style={{
                overflow: 'hidden',
                overflowY: 'auto',
                maxHeight: '380px'
              }}>
              {me?.employee?.teamManagerEmployee?.edges?.length ? (
                me?.employee?.teamManagerEmployee?.edges?.map(
                  ({ node }, index) =>
                    node.employees &&
                    node.employees.edges.length > 0 &&
                    node.employees.edges.map(({ node: emp }) => (
                      <Col span={24}>
                        <CutomCard
                          hoverable
                          bordered={false}
                          bodyStyle={{ padding: 10 }}
                          onClick={() => {
                            setActiveEmployee(getIntFromString(emp.id));
                            setActiveUser(getIntFromString(emp.user.id));
                          }}
                          active={activeEmployee === getIntFromString(emp.id)}
                        >
                          <Row gutter={[12, 12]} align="middle">
                            <Col style={{width:"60px"}}>
                              <img
                                src={USER_IMG}
                                alt=""
                                style={{
                                  width: '45px',
                                  height: '45px',
                                  borderRadius: '50%'
                                }}
                              />
                            </Col>
                            <Col style={{whiteSpace:"nowrap",textOverflow:'ellipsis',overflow:'hidden'}}>{emp?.user?.username}</Col>
                          </Row>
                        </CutomCard>
                      </Col>
                    ))
                )
              ) : (
                <Empty />
              )}
            </Row>
            <br />
          </Spin>
        </Card>
      </Col>

      <Col lg={0} xs={1}></Col>
    </>
  );
};

export default ManagerTeamComponent;

const CutomCard = styled(Card)`
  border-color: ${props => props.active && 'transparent'};
  box-shadow: ${props =>
    props.active && '0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%)'};
  word-wrap: break-word;
`;

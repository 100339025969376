import React from "react";
import { Row, Col, Button,Tooltip,Switch,Spin  } from "antd";
import styled from "styled-components";
import { displayBoolean,getIntFromString,ReactMarkdown } from "../../look";
import { displayUtcToUserTimezone } from '../../look/components/functions';

const ActionDetailView = props => {
    const { me,editable,actionDetail,onClose,updateActionItem,owner }=props
    const [loading,setloading]= React.useState(false)
    const [status,setStatus]=React.useState()
    const [canEdit,setcanEdit]=React.useState(false)
    React.useEffect(()=>{
        if(actionDetail){
            setStatus(actionDetail?.completed)
            if(me){
                if(actionDetail?.okr){
                    if(actionDetail?.okr?.owner?.user?.employee?.id===me?.employee?.id){
                        setcanEdit(true) 
                    }
                }
                else if(actionDetail?.commitment){
                    if(actionDetail?.commitment?.user?.employee?.id===me?.employee?.id){
                        setcanEdit(true) 
                    }
                }
                else if(actionDetail?.coachingCommitment){
                    if(actionDetail?.coachingCommitment?.user?.employee?.id===me?.employee?.id){
                        setcanEdit(true) 
                    }
                }
                else if(me?.employee?.id === owner?.user?.employee?.id){
                    setcanEdit(true)
                }
            }
           
        }
    },[actionDetail,me,owner])

    const update=async(value)=>{
        setloading(true)
        try{
            const response = await updateActionItem(
                {
                 id:getIntFromString(actionDetail?.id),
                 completed:value
               });
            if(response && response?.id){
                setloading(false)
                setStatus(response?.completed)  
            }
            else{
                setloading(false)
            }
        }
        catch{
            setloading(false)
        }
     
        
    }
    return (
        <>
         <Spin spinning={loading} >
         <Col>
                <RowDiv>
                   <Col>
                   <Row>
                    <h4 style={{fontSize:'18px',marginRight:'10px'}}>{actionDetail?.title}</h4>
                    {
                        editable && canEdit?
                        <Tooltip title={status?'switch to incomplete':'switch to complete'}>
                        <Switch checked={status} onChange={()=>update(!status)} />
                        </Tooltip>
                        :
                        <div style={{paddingTop:'5px'}}>
                            <Tooltip title={status?'Completed':'Incomplete'}>
                            {displayBoolean(status)}
                            </Tooltip>
                        </div>
                    }
                    </Row>
                   </Col>
                   <Col >
                    <Row style={{display:"flex",flexDirection:"row",gap:"20px"}}>
                        <h4 style={{textAlign:'left'}}>
                             Submitted On<br/>
                            {displayUtcToUserTimezone(actionDetail?.createdAt, "DD MMM")}
                        </h4>
                        <h4 style={{textAlign:'left'}}>
                             Updated On<br/>
                            {displayUtcToUserTimezone(actionDetail?.updatedAt, "DD MMM")}
                        </h4>
                    </Row>
                   </Col>
               </RowDiv>
                {/* <h4>{ relate(actionDetail)}</h4> */}
                { actionDetail?.okr && ( <p>Okr: {actionDetail?.okr?.title}</p>)}
                {
                    actionDetail?.notes && (
                        <ReactMarkdown>{actionDetail?.notes}</ReactMarkdown>
                    )
                }
                
            <div align="center">
                <Button style={{marginTop:'20px'}} onClick={()=>onClose({id:actionDetail?.id,completed:status})}>Close</Button>
            </div>
          </Col>
         </Spin>
        
        
        </>
    )

}
export default ActionDetailView;

const RowDiv =styled(Row)`
display:flex;
justify-content:space-between;
margin-bottom:20px
`
// @ts-nocheck
import React from 'react';

import { compose } from '../../../core';

import { withApollo } from '@apollo/client/react/hoc'
import { getSurveyBase64StringFromInt } from 'modules/look/components/functions';
import { RESPONSE_SET_FOR_EMPLOYEE } from '../../../survey/graphql/ResponseSetForUser.gql';
import Survey360ManagerReportView from '../component/Survey360ManagerReportView';
import { SURVEY_BY_ID_FOR_360 } from '../../../survey/graphql/SurveyByIdfor360.gql';
import { MANAGER_EMPLOYEE_DETAIL } from '../../../survey/graphql/EmployeeDetail.gql';
import { TEAM_SURVEY_360_REPORTS } from '../../../survey/graphql/Team360SurveyReport.gql';

const Survey360ManagerReportContainer = props => {
    const { client,me,history } = props
    const [SurveyReport,setSurveyReport]=React.useState()
    const [ surveyDetail,setSurveyDetail ]=React.useState()
    const [employees,setEmployees] = React.useState([]) 
    const [responseSet,setresponseSet] = React.useState() 

    const getSurveyReport = async (filterData) => {
        const { data } = await client.query({
            query: TEAM_SURVEY_360_REPORTS,
            variables: filterData
        });
        if(data?.teamMemberReport){
           let report =data?.teamMemberReport?.employee?.organizationSet?.edges[0]?.node?.employees?.edges[0]?.node?.user?.responseSet?.edges[0]?.node?.report360
            if(report){
              let report360 = JSON.parse(report)
              setSurveyReport({report360})
            }else{
              setSurveyReport(null)
            }
    
        }else{
          setSurveyReport(null)
        }
        
    }

    const getResponseSet = async (filterData) => {
      let filter = {...filterData,survey:getSurveyBase64StringFromInt(props?.match?.params?.surveyid)} 
      const { data } = await client.query({
          query: RESPONSE_SET_FOR_EMPLOYEE,
          variables: filter
      });
      if(data?.responseSetList){
          setresponseSet(data?.responseSetList)
  
      }
      
  }

   
    
  
    const getEmployeeDetail = async()=>{
      const { data } = await client.query({
            query: MANAGER_EMPLOYEE_DETAIL,
        });
        if(data?.me){
          let Detail =data?.me?.employee
          if(Detail?.teamManagerEmployee?.totalCount || Detail?.orgCeoEmployee?.totalCount || Detail?.orgPocEmployee?.totalCount || Detail?.verticalHeadEmployee?.totalCount){
            let employees =[]
            if(Detail?.orgCeoEmployee?.totalCount){
             
              employees=employees.concat(Detail?.orgCeoEmployee?.edges?.map(({node})=>node?.employees?.edges))
            }
            if(Detail?.teamManagerEmployee?.totalCount){
              employees=employees.concat(Detail?.teamManagerEmployee?.edges?.map(({node})=>node?.employees?.edges))
            }
            if(Detail?.orgPocEmployee?.totalCount){
          
              employees=employees.concat(Detail?.orgPocEmployee?.edges?.map(({node})=>node?.employees?.edges))
            }
            if(Detail?.verticalHeadEmployee?.totalCount){
              employees=employees.concat(Detail?.verticalHeadEmployee?.edges?.map(({node})=>node?.employees?.edges))
            }
        
            
            employees = [].concat.apply([],employees) 
            var setObj = new Set(); // create key value pair from array of array

              var result = employees.reduce((acc,item)=>{
                if(!setObj.has(item.node?.user?.id)){
                  setObj.add(item.node?.user?.id)
                  acc.push(item)
                }
                return acc;
              },[])
            result = result.map(({node})=>node?.user) 
            setEmployees(result)
          }
          else{
            history.push('/home')
          }
         
        }
    }

    const getSurveyDetail = async(filter)=>{
        const { data } = await client.query({
            query: SURVEY_BY_ID_FOR_360,
            variables: filter
        });

        if(data?.SurveyById){
            setSurveyDetail(data?.SurveyById)
        }
    }
  
    React.useEffect(()=>{
        if(me){
            if(props?.match?.params?.surveyid){
                let id = getSurveyBase64StringFromInt(props?.match?.params?.surveyid)
                getSurveyDetail({id:id})
                getEmployeeDetail()
            }
            
          }
          else if (me===null){
            history.push('/user/login')
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[me])

    return (
        <Survey360ManagerReportView
         {...props} 
         SurveyReport={SurveyReport} 
         surveyDetail={surveyDetail} 
         employees={employees} 
         changeUser={(e)=>{getResponseSet(e)}} 
         responseSet={responseSet} 
         get360Report={(e)=>{getSurveyReport(e)}}
         ></Survey360ManagerReportView>
    )
 
};

export default compose(withApollo)(Survey360ManagerReportContainer);

import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import {ASSESSMENT_BY_ID, RATING_SCALES} from "../graphql/ca-create-queries.gql";
import { Spin, message } from "antd";
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import CompetencyAssessmentEditView from "../components/ca-edit-view";
import { getAssessmentBase64StringFromInt, globalPermissionValidator } from "modules/look";
import { competency_assessment_permission } from "Permissions/competency-assessment";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import ca_route from '../route/index'
const CompetencyAssessmentEditContainer = (props) => {
    const { client, history, userPermission } = props
    const [permission, SetPermission] = React.useState()
    const [threshold_list, SetThresholdList] = React.useState([])
    const [is_loading, SetLoader] = React.useState(false)
    const [selected_member, SetSelectedMember] = React.useState()
    const [selected_role, SetSelectedRole] = React.useState()
    const [assessment_detail, SetAssessmentDetail] = React.useState()
    const [assessment_list, SetAssessmentList] = React.useState()
    const [data_mutation, SetDataMutation] = React.useState()

    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(competency_assessment_permission, userPermission)
            SetPermission(permission)
        }
    }, [userPermission])

    React.useEffect(() => {
        if (permission?.required_permission && props.match.params?.assessment_id) {
            getThresholdList()
            getAssessmentDetail({ id: getAssessmentBase64StringFromInt(props.match.params?.assessment_id) })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission, props.match.params?.assessment_id])

    React.useEffect(() => {
        if (data_mutation?.length) {
            updateMutation()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data_mutation])

    const ASSESSMENT_UPDATE_DYNAMIC = gql`
    mutation updateAssessment {
        ${data_mutation?.map(
        (item, index) =>
            `update${index}: updateAssessment(selectionId:"${item?.id}") {
                assessment{
                    id
                }
            }
            `
    ).join('')}
    }
`

    const [assessmentUpdateDefinition] = useMutation(ASSESSMENT_UPDATE_DYNAMIC);

    const updateMutation = async () => {
        try {
            SetLoader(true)
            const response = await assessmentUpdateDefinition()
            // new to do send back the response as props
            if (response) {
                SetLoader(false)
                message.success("Assessment updated successfully")
                history.push(ca_route?.competency_assessment_home)
            } else {
                SetLoader(false)
            }
        }
        catch (error) {
            SetLoader(false)
        }
    }

    //Get assessment List
    const getAssessmentDetail = async (filter) => {
        SetLoader(true)
        const { data } = await client.query({
            query: ASSESSMENT_BY_ID,
            fetchPolicy: 'network-only',
            variables: { ...filter }
        });
        if (data?.assessmentById) {
            let assessment = data?.assessmentById
            let user = assessment?.employee ? assessment?.employee.user : { email: assessment?.employeeEmail }
            SetSelectedMember({ user })
            SetSelectedRole({ ...assessment?.role, employeeLevel: { name: assessment?.employeeLevel } })
            SetAssessmentDetail(assessment)
            let assessment_list = assessment?.competencyassessmentSet?.edges?.map(({ node }) => node)
            SetAssessmentList(assessment_list?.map(i => {
                return {
                    ...i,
                    assessmentbarsSet: i?.assessmentbarsSet?.edges?.map(({ node }) => node)?.sort((a, b) => a.qualifyingLevelSequence - b.qualifyingLevelSequence)
                }
            }
            ))
            SetLoader(false)
        }
    }

    //Get Threshold List
    const getThresholdList = async (filter) => {
        const { data } = await client.query({
            query: RATING_SCALES,
            fetchPolicy: 'network-only',
            variables: { ...filter }
        });
        if (data?.ratingScales) {
            let list = data?.ratingScales?.edges?.map(({ node }, index) => { return { ...node, sequence: index + 1 } })
            SetThresholdList(list)
        }
    }

    // data handling for assessment mutation
    const OnSubmitAssessment = async (data) => {
        let updated_list = data?.filter(i => i.updated)?.map(i => i?.assessmentbarsSet)
        updated_list = [].concat.apply([], updated_list)?.filter(i => i?.selected)
        if (updated_list?.length) {
            SetDataMutation(updated_list)
        }
    }

    return (
        <>

            {(permission && permission?.required_permission) && (
                <CompetencyAssessmentEditView
                    {...props}
                    permission={permission}
                    is_loading={is_loading}
                    threshold_list={threshold_list}
                    OnSubmitAssessment={OnSubmitAssessment}
                    selected_member={selected_member}
                    selected_role={selected_role}
                    assessment_list={assessment_list}
                    SetAssessmentList={SetAssessmentList}
                    assessment_detail={assessment_detail}
                />
            )}

            {(permission && !permission?.required_permission) && (<NoPermissionView />)}
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    )

}

export default compose(withApollo)(CompetencyAssessmentEditContainer)
// @ts-nocheck
import React, { useState} from "react";
import {Button, Tooltip } from "antd";
import { Capitalize} from "../../look";
import './uiFlowTool.css';
import User_profile from '../../assets/user-profile.svg'
import EmployeeFieldSelector from './generic-tool/employeeSelector'
import compare_inactive from '../../assets/compare_inactive.svg'
import ImpactNarrativeMobileTab from "./ImpactNarrativeMobileTab";
import active_compare from '../../assets/active_compare.svg'
const TeamNarrativeUI = (props) => {
  const { items, fistEmployeeId, setFistEmployeeId, secondEmployeeId, setSecondEmployeeId, first_user_narrative, second_user_narrative, selectedTab, key_values } = props;
  // eslint-disable-next-line no-unused-vars
  const [headerItems, setHeaderItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [sub_content_items, setSubContentItems] = useState([]);
  const [compare, setcompare] = React.useState(false)
  const [selected_user_tab, setSelecteduserTab] = useState('first')
  React.useEffect(() => {
    if (items?.length) {
      setSubContentItems(items?.filter(data => data?.sequence !== 0))
      setHeaderItems(items?.filter(data => data?.sequence === 0))
    }
  }, [items])

  React.useEffect(() => {
    
    if(first_user_narrative && second_user_narrative){
      // if(SetAITrigger){
      //   SetAITrigger({
      //     trigger: ai_trigger_content.impact_narrative_compare,
      //     dynamic_data: [fistEmployeeId?.firstName,first_user_narrative?.overview,secondEmployeeId?.firstName,second_user_narrative?.overview],
      //     custom_instruction:"compare impact narrative with the provided data and give comparison report"
      //   })
      // }
    }
  }, [first_user_narrative,second_user_narrative])



  const renderHeaders = (section, emp) => (
    <>
      <div className="toolcontent impact-header-card">
        {
          compare ?
            <div className="header-border-div compare-border-div">
              <div className="border-inner-content">
                <img className="profile-img" src={User_profile} alt="" />
                <h4 className="header-border-label compare-border-label" style={{ textAlign: "center" }}>{emp}’s <br /> Impact Narrative</h4>
              </div>
            </div>
            :
            <div className="header-border-div">
              <div className="border-inner-content">
                <img className="profile-img" src={User_profile} alt="" />
                <h4 className="header-border-label" style={{ textAlign: "center" }}>{emp}’s Impact Narrative</h4>
              </div>
            </div>
        }

        {
          compare ?
            <div className="overflow-card" style={{ padding: "0px" }}>
              <h4>{section?.text}</h4>
            </div>
            :
            <h4>{section?.text}</h4>
        }

      </div>
    </>
  )

  const RenderCards = (user_narrative) => {
    let unwanted_fields = ['id', 'overview', '__typename','createdAt']
    let sub_list = []
    Object.entries(user_narrative).forEach(i => {
      if (!unwanted_fields?.includes(i[0])) {
        let key = i[0]?.split("_")?.join("")?.toLowerCase()
        sub_list.push({
          text: i[1],
          key: i[0],
          title: key_values[key]?.label || i[0]?.replaceAll("_", " ")
        })
      }
    })
    return (<>
      {
        sub_list?.map(section => (
          <div className={`toolcontent card-gap impact-card ${compare ? "compare-full-width" : ""}`}>
            <div className="sub-border-div">
              <h4 className="sub-border-label">{section?.title}</h4>
            </div>
            <div className="overflow-card">
              <h4>{section?.text}</h4>
            </div>
          </div>
        ))
      }


    </>)
  }
  const onEmployeeChange = (val, type) => {
    if (type === 'first') {
      setFistEmployeeId(val)
    } else {
      setSecondEmployeeId(val)
    }
  }
  const [firstuserSublist, SetFirstUserSublist] = React.useState([])
  const [seconduserSublist, SetSecondUserSublist] = React.useState([])
  React.useEffect(() => {
    if (first_user_narrative) {
      let unwanted_fields = ['id', 'overview', '__typename','createdAt']
      let sub_list = []
      // setSubContentItems(items?.filter((data, idx) => idx !== 0))
      Object.entries(first_user_narrative).forEach(i => {
        if (!unwanted_fields?.includes(i[0])) {
          sub_list.push({
            text: i[1],
            key: i[0],
            title: key_values[i[0]]?.label || i[0]?.replaceAll("_", " ")
          })
        }
      })
      SetFirstUserSublist(sub_list)
    } else {
      SetFirstUserSublist([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first_user_narrative])

  React.useEffect(() => {
    if (second_user_narrative) {
      let unwanted_fields = ['id', 'overview', '__typename','createdAt']
      let sub_list = []
      // setSubContentItems(items?.filter((data, idx) => idx !== 0))
      Object.entries(second_user_narrative).forEach(i => {
        if (!unwanted_fields?.includes(i[0])) {
          sub_list.push({
            text: i[1],
            key: i[0],
            title: key_values[i[0]]?.label || i[0]?.replaceAll("_", " ")
          })
        }
      })
      SetSecondUserSublist(sub_list)
    } else {
      SetSecondUserSublist([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [second_user_narrative])

  const renderHeadersMobile = (section, emp) => (
    <>
      <div className="toolcontent impact-header-card">
        <div className="header-border-div compare-border-div">
          <div className="border-inner-content">
            <img className="profile-img" src={User_profile} alt="" />
            <h4 className="header-border-label compare-border-label" style={{ textAlign: "center" }}>{emp}’s <br /> Impact Narrative</h4>
          </div>
        </div>

        <h4>{section?.text}</h4>

      </div>
    </>
  )
  React.useEffect(() => {
    if (!compare) {
      setSecondEmployeeId(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compare])

  React.useEffect(() => {
    if (selectedTab === "COMPARE") {
      setcompare(true)
    }
  }, [selectedTab])

  const deselect = (id) => {
    let doc = document.getElementById(id)
    if (doc) {
      doc.blur()
    }
  }

  return (
    <>
      <div className="web-screen" style={{ width: "100%" }}>
        <div style={{ margin: "40px 12.5px" }} >
          <div className="tool-content selector-container" >

            <div className="drop-field">
              <EmployeeFieldSelector
                name="firstEmployeeId"
                label=""
                rules={[]}
                disabled={false}
                onAdd={false}
                onEdit={false}
                placeholder="Select an employee"
                defaultId={fistEmployeeId?.empid}
                skipIds={[secondEmployeeId?.empid]}
                onChangeSelect={(value) => { onEmployeeChange(value, 'first'); deselect("firstEmployeeId") }}
              />
            </div>
            {
              fistEmployeeId && (
                <Button disabled={!fistEmployeeId} className="compare-div" onClick={() => { setcompare(!compare) }}>
                  <Tooltip title="Compare two Impact Narratives">
                    <img src={!compare ? compare_inactive : active_compare} alt="" />
                  </Tooltip>
                </Button>
              )
            }



            {compare ?
              <div className="drop-field">
                <EmployeeFieldSelector
                  name="secondEmployeeId"
                  label=""
                  rules={[]}
                  disabled={false}
                  onAdd={false}
                  onEdit={false}
                  placeholder="Select an employee"
                  defaultId={secondEmployeeId?.empid}
                  skipIds={[fistEmployeeId?.empid]}
                  onChangeSelect={(value) => { onEmployeeChange(value, 'second'); deselect("secondEmployeeId") }}
                />
              </div>
              :
              <div className="drop-field"></div>}
          </div>
        </div>


        <div className="impact-row ">
          {
            (fistEmployeeId && first_user_narrative) ?
              <div className="team-section-gap">
                <div style={{ margin: "12.5px" }}>
                  {renderHeaders(
                    {
                      text: first_user_narrative?.overview,
                      key: "overview",
                      title: 'Impact Narrative'
                    }, Capitalize(fistEmployeeId?.firstName))}
                </div>
                <div className="card-wrapper">
                  {
                    RenderCards(first_user_narrative)
                  }
                </div>
              </div>
              : <div className="team-section-gap"></div>
          }
          {
            compare && (

              (secondEmployeeId && second_user_narrative) ?
                <div className="team-section-gap">
                  <div style={{ margin: "12.5px" }}>
                    {renderHeaders({
                      text: second_user_narrative?.overview,
                      key: "overview",
                      title: 'Impact Narrative'
                    }, Capitalize(secondEmployeeId?.firstName))}
                  </div>
                  <div className="card-wrapper" >
                    {
                      RenderCards(second_user_narrative)
                    }
                  </div>
                </div>
                : <div className="team-section-gap"></div>

            )
          }

        </div>
      </div>

      <div className="mobile-screen">
        {
          selectedTab === "TEAM_NARRATIVE" ?
            <>
              <div style={{ margin: "23px 0px" }} >
                <div className="tool-content selector-container" >

                  <div className="drop-field">
                    <EmployeeFieldSelector
                      name="firstEmployeeId"
                      label=""
                      rules={[]}
                      disabled={false}
                      onAdd={false}
                      onEdit={false}
                      placeholder="Select an employee"
                      defaultId={fistEmployeeId?.empid}
                      skipIds={[secondEmployeeId?.empid]}
                      onChangeSelect={(value) => { onEmployeeChange(value, 'first'); deselect("firstEmployeeId") }}
                    />
                  </div>
                </div>
              </div>
              {
                (fistEmployeeId && first_user_narrative) ?
                  <>
                    <div style={{ margin: "40px 0px" }}>
                      {renderHeadersMobile({
                        text: first_user_narrative?.overview,
                        key: "overview",
                        title: 'Impact Narrative'
                      }, Capitalize(fistEmployeeId?.firstName))}
                    </div>
                    <div style={{ width: "100%" }} >
                      <ImpactNarrativeMobileTab content={firstuserSublist} {...props} />
                    </div>
                  </>
                  : null
              }
            </>
            :
            <>
              <div style={{ margin: "23px 0px" }} >
                <div className="tool-content selector-container" >

                  <div className="row" style={{ justifyContent: 'center', alignItems: "center", flexWrap: "nowrap" }}>
                    <div style={{ width: "100%" }}>
                      <div className="drop-field">
                        <EmployeeFieldSelector
                          name="firstEmployeeId"
                          label=""
                          rules={[]}
                          disabled={false}
                          onAdd={false}
                          onEdit={false}
                          placeholder="Select an employee"
                          defaultId={fistEmployeeId?.empid}
                          skipIds={[secondEmployeeId?.empid]}
                          onChangeSelect={(value) => { onEmployeeChange(value, 'first'); deselect("firstEmployeeId") }}
                        />
                      </div>
                      <div className="drop-field" style={{ marginTop: "11px" }}>
                        <EmployeeFieldSelector
                          name="secondEmployeeId"
                          label=""
                          rules={[]}
                          disabled={!fistEmployeeId}
                          onAdd={false}
                          onEdit={false}
                          placeholder="Select an employee"
                          defaultId={secondEmployeeId?.empid}
                          skipIds={[fistEmployeeId?.empid]}
                          onChangeSelect={(value) => { onEmployeeChange(value, 'second'); deselect("secondEmployeeId") }}
                        />
                      </div>
                    </div>
                    <Button className="compare-div">
                      <img src={active_compare} alt="" />
                    </Button>
                  </div>
                </div>

                <div className="user-compare-tab row" >
                  {
                    fistEmployeeId && (<div style={{ width: "100%" }} align="center">
                      <h4 onClick={() => { setSelecteduserTab('first') }} className={`user-tab-label ${selected_user_tab === 'first' ? 'act-user-tab-label' : ''}`}>{Capitalize(fistEmployeeId?.firstName)}</h4>
                    </div>)
                  }

                  {secondEmployeeId && (<div style={{ width: "100%" }} align="center">
                    <h4 onClick={() => { setSelecteduserTab('second') }} className={`user-tab-label ${selected_user_tab !== 'first' ? 'act-user-tab-label' : ''}`}>{Capitalize(secondEmployeeId?.firstName)}</h4>
                  </div>)}


                </div>

                {
                  selected_user_tab === 'first' ?
                    <div style={{ width: "100%" }}>
                      {
                        (fistEmployeeId && first_user_narrative) ?
                          <>
                            <div style={{ margin: "40px 0px" }}>
                              {renderHeadersMobile({
                                text: first_user_narrative?.overview,
                                key: "overview",
                                title: 'Impact Narrative'
                              }, Capitalize(fistEmployeeId?.firstName))}
                            </div>
                            <div style={{ width: "100%" }} >
                              <ImpactNarrativeMobileTab content={firstuserSublist} {...props} />
                            </div>
                          </>
                          : null
                      }
                    </div> :
                    <div style={{ width: "100%" }}>
                      {
                        (secondEmployeeId && second_user_narrative) ?
                          <>
                            <div style={{ margin: "40px 0px" }}>
                              {renderHeadersMobile({
                                text: second_user_narrative?.overview,
                                key: "overview",
                                title: 'Impact Narrative'
                              }, Capitalize(secondEmployeeId?.firstName))}
                            </div>
                            <div style={{ width: "100%" }} >
                              <ImpactNarrativeMobileTab content={seconduserSublist} {...props} />
                            </div>
                          </>
                          : null
                      }
                    </div>
                }


              </div>
            </>
        }
      </div>
    </>
  );
};

export default TeamNarrativeUI;

import React from "react";
import TicketForm from "../components/TicketsForm";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { withCreateTicket } from "./ticketOperators";
import { getIntFromString } from "modules/look";
import { message } from "antd";
import support_route from "../route";

const CreateTicketContainer =(props)=>{
    const {me , createTicket,navigateRoute} =props
    const onSubmit = async(values) => {
        
        try{
            let CreateTicketInput ={
                title: values?.title,
                description: values?.description,
                raisedBy:getIntFromString(me?.id),
                attachments:values?.file?.length?values?.file?.join(","):""
            }
           let response = await createTicket({...CreateTicketInput})
           if(response?.id){
            navigateRoute(`${support_route?.thank_you}?tickt_id=${getIntFromString(response.id)}`)
            message.success("Ticket Created Successfully")
           }
        }
        catch(error){
            console.error(error)
        }
    }
    return (
        <>
        <TicketForm 
        onSubmit={(values)=>onSubmit(values)}
        {...props}/>
        </>
    )
}

export default compose(withApollo,withCreateTicket)(CreateTicketContainer);
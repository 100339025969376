// @ts-nocheck
import React from 'react';
import {DownOutlined} from "@ant-design/icons";
import { Row,Button,Dropdown,Menu,Input } from "antd";
import styled from "styled-components";
import Search_icon from '../../assets/search-icon.svg'
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
import moment from 'moment-timezone';
const DiscardFilter = props => {
 
    const {
        me,
        filterData,
        defaultFilter,
        onfilterChange
    } = props; 
    
    const [selectedFilter,setFilter]=React.useState('All')
    const [filterList,setfilterList]=React.useState([])
    const [filterValue,setfilterValue]=React.useState()
    React.useEffect(()=>{
        if(filterData){
            setfilterList(filterData)
        }
    },[filterData])

    React.useEffect(()=>{
        if(defaultFilter){
            setFilter(defaultFilter?.key)
        }
    },[defaultFilter])
    const typeFilter =(value)=>{
        switch (value){
            case 'All':{
                onfilterChange({type:value})
                break
            }
            case 'Incoming':{
                onfilterChange({type:value,offset:0,first:10,tz:moment.tz.guess()})
                break
            }
            case 'Outgoing':{
                onfilterChange({type:value,offset:0,first:10,tz:moment.tz.guess()})
                break
            }
            default : break
        }
    }
    const filterValueChange=(value)=>{
        setfilterValue(value)
        switch (selectedFilter){
            case 'All':{
                onfilterChange({type:selectedFilter,okr_Goalstatement_Icontains:value,offset:0,first:10,tz:moment.tz.guess()})
                break
            }
            case 'Incoming':{
                onfilterChange({actor:me?.employee?.user?.id,type:selectedFilter,okr_Goalstatement_Icontains:value,offset:0,first:10,tz:moment.tz.guess()})
                break
            }
            case 'Outgoing':{
                onfilterChange({initiator:me?.employee?.user?.id,type:selectedFilter,okr_Goalstatement_Icontains:value,offset:0,first:10,tz:moment.tz.guess()})
                break
            }
            default : break
        }
    }

    const FilterType =(
        <div>
            {(selectedFilter==='All'||selectedFilter==='Incoming'||selectedFilter==='Outgoing') && (
                <div style={{position:'relative'}}>
                <Input
                placeholder=""
                className='filter-input'
                style={{paddingLeft:"30px"}}
                value={filterValue}
                onChange={e => (filterValueChange(e.target.value))}   
                autoComplete='off'
                />
                  <img style={{position:"absolute",left:"7px",top:"10px",width:"20px"}} src={Search_icon } alt="" />
                </div>
            )}
        </div>
    )

    const Filtermenu =()=> (
        <Menu style={{maxHeight:'200px',overflow:'auto'}}>
          {
            filterList && (
                filterList?.map((data)=>
              <Menu.Item key={data?.key} onClick={()=>{typeFilter(data?.key);setfilterValue(null);setFilter(data?.key)}}>
                {data?.type}
              </Menu.Item>
              )
            )
          }
        </Menu>
      );

    return (
        <>
        <Row>
            <div>
              {FilterType}
            </div>
            <Dropdown overlay={Filtermenu}  className="filter-drop" trigger={['click']}>
                <DropdownButton >
                    <p className='selected-filter' style={{marginTop:'10px',width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left',paddingTop:"2px"}}>{selectedFilter}</p>
                    <img src={dropdown_Arrow} alt="" style={{width:"13px",height:"10px"}}/>
                    <DownOutlined />
            
                </DropdownButton>
            </Dropdown>
        </Row>
        </>
    )

}

export default DiscardFilter;

const DropdownButton = styled(Button)`
width:100px;
display:flex;
height:36px;
justify-content:space-between;
align-items:center;
gap:5px
`
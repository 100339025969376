import { withApollo } from '@apollo/client/react/hoc';
import { Row, Spin } from "antd";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { getCompetencyTypeBase64StringFromInt, getIntFromString, globalPermissionValidator } from "modules/look";
import { bars_permission } from "Permissions/bars.permission";
import React from "react";
import { compose } from "../../core";
import BarsEditSubCompetencyView from '../Components/BarsEditSubCompetency';
import { SUB_COMPETENCY_BY_ID } from "../graphql/BarsEditQueries.gql";
import { ALL_EMPLOYEE_LEVELS, ALL_RATING_SCALES } from "../graphql/BarsQueries.gql";
import { withCreateBarsInBulk } from "./BarsOperations";




const BarsEditSubCompetencyContainer = (props) => {

    const { client, permissionLoader, userPermission, me } = props;

    const [barsDetailsLoading, setBarsDetailsLoading] = React.useState(undefined);
    const [barsDetails, setBarsDetails] = React.useState(undefined);
    const [barsPermission, setBarsPermission] = React.useState(undefined);
    const [ratingScales, setRatingScales] = React.useState(undefined);
    const [allEmployeeLevels, setAllEmployeeLevels] = React.useState(undefined);
    const empLevelRef = React.useRef([]);





    React.useEffect(() => {

        // Checking for permission of user
        if (userPermission?.length) {
            let permission = globalPermissionValidator(bars_permission, userPermission);
            setBarsPermission(permission)
        }

    }, [userPermission])



    React.useEffect(() => {

        if (allEmployeeLevels && ratingScales) {
            let id = props.match.params.bar_id;
            if (id) {
                getSubCompetencyById(id);
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allEmployeeLevels, ratingScales])


    React.useEffect(() => {

        // based on permission of user bars will be di
        if (barsPermission?.required_permission) {
            getAllRatingScales();
            getAllEmployeeLevels();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [barsPermission, props.match])


    // For fetching all rating scales
    const getAllRatingScales = async () => {


        const { data } = await client.query({
            query: ALL_RATING_SCALES,
            fetchPolicy: 'network-only',
        });
        if (data?.ratingScales) {
            let scales = data?.ratingScales?.edges?.map(({ node }) => node);
            scales.current = scales
            setRatingScales(scales);

        }
    }

    // Fetching all the bars
    const getAllEmployeeLevels = async (filter) => {

        const { data } = await client.query({
            query: ALL_EMPLOYEE_LEVELS,
            variables:{organization:me?.employee?.organizationSet?.edges[0]?.node?.id},
            fetchPolicy: 'network-only',
        });
        if (data?.allEmployeeLevels) {
            let allLevels = data?.allEmployeeLevels?.edges?.map(({ node }) => node);

            setAllEmployeeLevels(allLevels)
        }
    }


    const recordExist = (scale, level, records) => {
        let verifyScale
        verifyScale = records?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => {
            if (node?.employeeLevel?.id === level?.id && node?.ratingScale?.id === scale?.id) {
                return { bar: getIntFromString(node?.id), ...node?.employeeLevel, ratingScale: node?.ratingScale, behaviourExample: node?.behaviourExample }
            } else {
                return undefined
            }
        }

        )?.filter((value) => value !== undefined)

        return verifyScale;

    }


    const getEmployeeLevels = (data) => {

        let levels = allEmployeeLevels?.map((level) =>

            ratingScales?.map((scale) => {
                let record = recordExist(scale, level, data)

                if (record?.length) {
                    return { ...record[0] }
                } else {
                    return { ...level, ratingScale: scale }
                }
            })
        )?.flat();

        return levels;

    }


    const getRecordGenarated = (node) => {
        let record = {
            id: node?.id,
            title: node?.title,
            behaviourId: node?.id,
            empLevels: getEmployeeLevels(node),
            competency: node?.behaviourallyanchoredratingSet?.edges?.map(({ node }) => { return { ...node, intId: getIntFromString(node?.id) } }),
        }
        return record;
    }


    const getSubCompetencyById = async (id) => {
        setBarsDetailsLoading(true)
        let record = getCompetencyTypeBase64StringFromInt(id)
        const { data } = await client.query({
            query: SUB_COMPETENCY_BY_ID,
            fetchPolicy: 'network-only',
            variables: { id: record }
        });
        if (data?.competencyById) {
            let record = getRecordGenarated(data?.competencyById)

      if(record && allEmployeeLevels){
        let arr = [];
        allEmployeeLevels
         .forEach(level => (
           arr.push({...level,status: record?.empLevels?.filter(item => item.id === level?.id && item?.behaviourExample)?.length !== ratingScales?.length ? "pending" : 'completed'})
         ));
    
         empLevelRef.current = arr;
      
      }
            setBarsDetails(record)
            setBarsDetailsLoading(false)
        }
    }




    return (
        <>

            <Row justify='center' align='middle'>
                {
                    <Spin spinning={permissionLoader} wrapperClassName='permission-check-bars'>
                        <div>
                            {
                                barsPermission !== undefined &&
                                <div>
                                    {
                                        barsPermission?.required_permission ?
                                            <BarsEditSubCompetencyView
                                                barsDetails={barsDetails}
                                                barsDetailsLoading={barsDetailsLoading}
                                                setBarsDetailsLoading={setBarsDetailsLoading}
                                                empLevelRef={empLevelRef}
                                                allRatingScales={ratingScales}
                                                setBarsDetails={setBarsDetails}
                                                allEmployeeLevels = {allEmployeeLevels}
                                                {...props}
                                            />
                                            :
                                            <NoPermissionView />
                                    }

                                </div>
                            }
                        </div>
                    </Spin>
                }
            </Row>


        </>
    );
};

export default compose(withApollo, withCreateBarsInBulk)(BarsEditSubCompetencyContainer);



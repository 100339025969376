import { Progress, Spin } from "antd";
import React from "react";

const CommonTableView = (props) => {
    const { page_info, data_list_loading, list, onPagination, header_list,custom_height_class } = props

    return (
        <>
            <Spin spinning={data_list_loading}>
                <div className={`org-table-container poc_table_scroll ${custom_height_class?custom_height_class:"module-usage-table-height"}`} >
                    <table className='org-table'>
                        <thead>
                            <tr className='org-row-header'>
                                {
                                    header_list?.map((i, index) => (
                                        <th className={`org-table-header-label ${index === 0 ? "org-left-radious" : ""} ${index === (header_list?.length - 1) ? "org-right-radious" : ""}`} style={{ textAlign: index === 0 ? "left" : "center",...i?.custom_style }}>
                                            <h4 className='org-table-header-label'>{i?.label}</h4>
                                        </th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {list?.map(item => (
                                <tr className='org-row-body'>
                                    {
                                        item?.map((i, index) => (
                                            <td className={`${(index !== 0 && index !== (header_list?.length - 1)) ? "table-border-up-down" : ""} ${index === 0 ? "org-left-radious table-border-left" : ""} ${index === (header_list?.length - 1) ? "org-right-radious table-border-right" : ""}`} style={{ textAlign: index === 0 ? "left" : "center" }}  >
                                                {
                                                    i?.type === "label" && (
                                                        <h4 className='org-table-body-label'>{i?.value}</h4>
                                                    )
                                                }
                                                {
                                                    i?.type === "icon" && (
                                                        <img src={i?.value} alt="icon" />
                                                    )
                                                }
                                                {
                                                    i?.type === "progress-bar" && (
                                                        <div style={{ padding: "0.25em 1em", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Progress 
                                                                percent={i?.value}
                                                                strokeColor={"rgba(84, 217, 159, 1)"}
                                                            
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </td>
                                        ))
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {(!data_list_loading && list?.length && page_info?.hasNextPage) ? <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1em" }}>
                        <h4 onClick={() => { onPagination(page_info) }} className='pagination-label' style={{ cursor: "pointer", margin: "0px", padding: "0px" }}>View More</h4>
                    </div> : ''}
                    {
                        (!list?.length && !data_list_loading) ?
                            <div style={{ width: "100%", height: "5em" }}>
                                <h4 className='rd-nodata-label' style={{ textAlign: "center" }}>No data found</h4>
                            </div> : ''
                    }
                </div>
            </Spin>
        </>
    )
}

export default CommonTableView
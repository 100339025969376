import React, { useState, useEffect } from 'react';
import { compose } from "modules/core";
import PocReprtListView from "modules/poc-dashboard/components/poc_report/pocReportListView";
import { withApollo } from '@apollo/client/react/hoc';
import { ORG_POC_SURVEYS, ORG_POC_SURVEY_EMPLOYEE_RESPONSE_SET, ORG_POC_SURVEY_EMPLOYEE_RESPONSE_SET_NON_360,QUERY_FOR_EXPORT_360_SURVEY_STATUS_REPORT } from "modules/poc-dashboard/graphql/pocDashboardQuery.gql";
import { VALUE_SUERVEY_PART_1, PERSONALITY_PART_1, STRENGTH_PART_1, KNOWLEDGE_PART_1, grow_survey_id, SURVEY_READINESS_LEVEL, feedback_survey_id, feedback_1_1_survey_id, USER_MANUAL_SURVEY_ID, effectiveness_indicator_one_on_one, SURVEY_DIVERSITY_PROFILE, SURVEY_ID_3CS, VALUE_SUERVEY_PART_2, PERSONALITY_PART_2, STRENGTH_PART_2, KNOWLEDGE_PART_2, cc_graduation_test } from '../../../../config';
import { Capitalize, displayUtcToUserTimezone, exportToCsv, getIntFromString, globalPermissionValidator } from 'modules/look';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { poc_admin_permission } from 'Permissions/poc_admin.permission';
import { message, Spin } from 'antd';
import moment from 'moment';

const PocReportListContainer = (props) => {
    const { me, client, userPermission } = props;
    const [org_survey_lists, setSurveyList] = useState([]);
    const [selected_survey_response, setSurveyResponseSet] = useState();
    const [table_data_loading, setTableDataLoading] = useState(true);
    const [total_score, setTotalScore] = useState(0)
    const [response_page_info, setReasponsePageInfo] = useState()
    const response_data_ref = React.useRef([])
    const [permission, setPermission] = React.useState()
    const [survey_list_loading, setSurveyListLoading] = React.useState(false)
    const [export_loader, SetExportLoader] = React.useState(false)
    const [download_progress, SetDownloadProgress] = React.useState(0)
    let download_progress_ref = React.useRef(0)

    React.useEffect(() => {
        response_data_ref.current = selected_survey_response
    }, [selected_survey_response])

    React.useEffect(() => {
        if (userPermission?.length && me) {
            let permission = globalPermissionValidator(poc_admin_permission, userPermission)

            if (permission.required_permission && !me?.employee?.orgPocEmployee?.totalCount) {
                permission.required_permission = false
            }
            if (permission.required_permission && !me?.employee?.organizationSet?.edges?.length) {
                permission.required_permission = false
            }
            setPermission(permission)
        }
    }, [userPermission, me])

    useEffect(() => {
        if (permission?.required_permission) {
            getOrgSurveyList();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission]);

    const getOrgSurveyList = async () => {
        setSurveyListLoading(true)
        const { data } = await client.query({
            query: ORG_POC_SURVEYS,
            fetchPolicy: 'network-only'
        });
        if (data?.allOrgSurveysByImplicitOrgId) {
            let skip_survey = [VALUE_SUERVEY_PART_1, VALUE_SUERVEY_PART_2, PERSONALITY_PART_1, PERSONALITY_PART_2, STRENGTH_PART_1, STRENGTH_PART_2, KNOWLEDGE_PART_1, KNOWLEDGE_PART_2, grow_survey_id, SURVEY_READINESS_LEVEL, feedback_survey_id, feedback_1_1_survey_id, USER_MANUAL_SURVEY_ID, effectiveness_indicator_one_on_one, SURVEY_DIVERSITY_PROFILE, SURVEY_ID_3CS]
            let list_after_skip = data?.allOrgSurveysByImplicitOrgId?.edges?.filter(({ node }) => !skip_survey.includes(getIntFromString(node?.id)))
            setSurveyList(list_after_skip?.map(({ node }) => {
                return {
                    ...node,
                    max_score: calculateMaxScore(node?.groupSet?.edges)
                }
            }));
            setSurveyListLoading(false)
        }
    };

    const calculateMaxScore = (data) => {

        let questions_in_group = data?.map(({ node }) => node?.questionSet?.totalCount || 0)

        return questions_in_group?.reduce((a, b) => a + b, 0)
    }

    const checkTotalScore = (value) => {
        try {
            let answerSet = value?.edges?.map(({ node }) => node)
            let question_ids = [...new Set(answerSet?.map(i => i?.question?.id))]
            let total_score = 0
            question_ids.forEach(q => {
                let list_answer = answerSet?.filter(a => a?.question?.id === q)
                let choice_set = list_answer[0]?.question?.choiceSet?.edges?.map(({ node }) => node)
                let user_answer = list_answer?.map(ans => ans?.answer)?.flatMap(item => item.split(','))
                let correct_answers = choice_set?.filter(cw => cw?.answerKey)?.map(i => i?.value)
                let user_input_is_correct = !correct_answers?.length ? false : correct_answers?.some(i => user_answer?.includes(i))
                if (user_input_is_correct) {
                    total_score = total_score + 1
                }
            })
            setTotalScore(total_score)
            return total_score
        } catch (error) {
            return 0
        }
    }

    const allEmployee360SurveyResponse = async (filter) => {
        try {
            setTableDataLoading(true)
            const { data } = await client.query({
                query: ORG_POC_SURVEY_EMPLOYEE_RESPONSE_SET,
                variables: { ...filter, first: 10,orderBy:["user__firstName"] },
                fetchPolicy: 'network-only'
            });
            if (data?.allEmployees) {

                let list = data?.allEmployees?.edges?.flatMap(({ node }) => handleEmployeeData(node))
                setReasponsePageInfo({
                    hasNextPage: data?.allEmployees?.pageInfo?.hasNextPage,
                    after: data?.allEmployees?.pageInfo?.endCursor
                })
                if (filter?.after) {
                    list = response_data_ref?.current.concat(list)
                }
                setSurveyResponseSet(list)
                setTableDataLoading(false)
            }

        } catch (error) {

        }
    }  

    const allEmployeeNon360SurveyResponse = async (filter) => {
        try {
            setTableDataLoading(true)
            const { data } = await client.query({
                query: ORG_POC_SURVEY_EMPLOYEE_RESPONSE_SET_NON_360,
                variables: { ...filter, first: 10,orderBy:["user__firstName"] },
                fetchPolicy: 'network-only'
            });
            if (data?.allEmployees) {
                setReasponsePageInfo({
                    hasNextPage: data?.allEmployees?.pageInfo?.hasNextPage,
                    after: data?.allEmployees?.pageInfo?.endCursor
                })
                let list = data?.allEmployees?.edges?.flatMap(({ node }) => handleEmployeeData(node))


                let data_list = filter?.after ? response_data_ref?.current.concat(list) : list
                setSurveyResponseSet(data_list)
                setTableDataLoading(false)
            }
        } catch (error) {

        }
    }

    const handleEmployeeData = (node) => {
        let user_responses = node?.user?.responseSet?.edges?.map(r => r?.node)

        if (user_responses?.length) {
            return user_responses?.map(res => {

                return {
                    user_id: node?.user?.id,
                    firstName: Capitalize(node?.user?.firstName),
                    lastName: Capitalize(node?.user?.lastName),
                    email: node?.user?.email,
                    response_id: res?.id,
                    responseDate: res?.responseDate,
                    total_invitation: res?.total_invitation?.totalCount,
                    completed_invitation: res?.completed_invitation?.totalCount,
                    totalScore: res?.answerSet?.edges?.length ? checkTotalScore(res?.answerSet) : 0
                }
            })
        } else {
            return [
                {
                    user_id: node?.user?.id,
                    firstName: Capitalize(node?.user?.firstName),
                    lastName: Capitalize(node?.user?.lastName),
                    email: node?.user?.email,
                    response_id: null,
                    responseDate: null,
                    total_invitation: 0,
                    completed_invitation: 0,
                    totalScore: 0
                }
            ]
        }
    }

    const sortScore = (type) => {
        setTableDataLoading(true)
        let result = [...selected_survey_response]
        if (type === 'ascending') {
            result = result.sort((a, b) => a?.totalScore - b?.totalScore);
        } else if (type === 'descending') {
            result = result.sort((a, b) => b?.totalScore - a?.totalScore);
        }
        setTableDataLoading(false)
        setSurveyResponseSet(result)
    }

    let ref_360DownloadData = React.useRef({
        employee_list: [],
        invitation_list: []
    })

    React.useEffect(() => {
        download_progress_ref.current = download_progress
    }, [download_progress])

    const GetDataForcsvOf360Survey = async (filter) => {
        try {
            SetExportLoader(true)
            let doc_per_page = 100
            let current_page = filter?.page || 1
            let offset = (current_page - 1) * doc_per_page
            const { data } = await client.query({
                query: QUERY_FOR_EXPORT_360_SURVEY_STATUS_REPORT,
                variables: { survey: filter?.survey, first: doc_per_page, offset,orderBy:["user__firstName"] },
                fetchPolicy: 'network-only'
            });
            if (data) {
                let max_doc = Math.max(data?.employee_list?.totalCount, data?.invitation_list?.totalCount)
                let total_page = Math.ceil(max_doc / doc_per_page)
                let progress = download_progress_ref.current + (100 / total_page)
                SetDownloadProgress(Math.round(progress))
                let has_next_page = data?.employee_list?.pageInfo?.hasNextPage || data?.invitation_list?.pageInfo?.hasNextPage
                ref_360DownloadData.current.employee_list = ref_360DownloadData.current.employee_list.concat(data?.employee_list?.edges?.map(({ node }) => node))
                ref_360DownloadData.current.invitation_list = ref_360DownloadData.current.invitation_list.concat(data?.invitation_list?.edges?.map(({ node }) => node))
                if (has_next_page) {
                    GetDataForcsvOf360Survey({
                        ...filter,
                        page: current_page + 1
                    })
                } else {
                    GenerateCSVFor360(ref_360DownloadData.current, filter?.survey_name)
                }
            }

        } catch (error) {
            resetDownload()
            message.error("Failed to download")
        }
    }

    const GenerateCSVFor360 = (data, survey_name) => {
        try {
            let employee_list_response = data?.employee_list?.flatMap(node => handleEmployeeData(node))

            let invite_list = employee_list_response.flatMap(i => {
                let invitation_list = i?.response_id ? data?.invitation_list?.filter(inv => getIntFromString(inv?.responseSourceFor?.id) === getIntFromString(i?.response_id)) : []
                if (!invitation_list?.length) {
                    return [
                        {
                            invitor: i,
                            respondentType: "SELF",
                            status: i?.response_id ? "Completed" : "Pending"
                        }
                    ]
                } else {
                    let invite_data = invitation_list?.map(inv => {
                        return {
                            invitor: i,
                            invitee: inv?.user,
                            respondentType: inv?.respondentType?.split("_")?.join(" "),
                            status: !inv?.valid ? "Completed" : "Pending"
                        }
                    })

                    return [
                        {
                            invitor: i,
                            respondentType: "SELF",
                            status: i?.response_id ? "Completed" : "Pending"
                        }
                    ].concat(invite_data)
                }
            })

            let csv_data = invite_list?.map(csv => {
                return {
                    "Originator": `${csv?.invitor?.firstName} ${csv?.invitor?.lastName || ""}`,
                    "Invitee Name": csv?.respondentType !== "SELF" ? `${csv?.invitee?.firstName} ${csv?.invitee?.lastName || ""}` : " ",
                    "Invitee Email": csv?.respondentType !== "SELF" ? csv?.invitee?.email : " ",
                    "Respondent Type": csv?.respondentType,
                    "Status": csv?.status
                }
            })
            if (csv_data?.length) {
                exportToCsv(`MApp_${survey_name}_360_report_${moment()}`, csv_data)
                resetDownload()
                message.success("Report has been exported")
            } else {
                resetDownload()
                message.error("No data available")
            }

        } catch (error) {
            resetDownload()
            message.error("Failed to download")
        }
    }

    let ref_Non360DownloadData = React.useRef({
        employee_list: [],
    })

    const GetDataForcsvOfNon360Survey = async (filter) => {
        try {
            SetExportLoader(true)
            let doc_per_page = 100
            let current_page = filter?.page || 1
            let offset = (current_page - 1) * doc_per_page
            const { data } = await client.query({
                query: ORG_POC_SURVEY_EMPLOYEE_RESPONSE_SET_NON_360,
                variables: { survey: filter?.survey, first: doc_per_page, offset,orderBy:["user__firstName"] },
                fetchPolicy: 'network-only'
            });
            if (data) {
                let max_doc = data?.allEmployees?.totalCount
                let total_page = Math.ceil(max_doc / doc_per_page)
                let progress = download_progress_ref.current + (100 / total_page)
                SetDownloadProgress(Math.round(progress))
                let has_next_page = data?.allEmployees?.pageInfo?.hasNextPage
                ref_Non360DownloadData.current.employee_list = ref_Non360DownloadData.current.employee_list.concat(data?.allEmployees?.edges?.map(({ node }) => node))
                if (has_next_page) {
                    GetDataForcsvOfNon360Survey({
                        ...filter,
                        page: current_page + 1
                    })
                } else {
                    GenerateCSVForNon360(ref_Non360DownloadData.current, filter?.survey_name, filter?.survey)
                }
            }

        } catch (error) {
            resetDownload()
            message.error("Failed to download")
        }
    }

    const GenerateCSVForNon360 = (data, survey_name, survey_id) => {
        try {
            let employee_list_response = data?.employee_list?.flatMap(node => handleEmployeeData(node))


            let csv_data = []

            if (cc_graduation_test.includes(getIntFromString(survey_id))) {
                let survey_detail = org_survey_lists?.find(i => getIntFromString(i?.id) === getIntFromString(survey_id))

                csv_data = employee_list_response?.map((i, index) => {
                    let data = {
                        "Sr.No": index + 1,
                        "Name": `${i?.firstName} ${i?.lastName || ""}`,
                        "Email": i?.email,
                        "Response Date": i?.responseDate ? displayUtcToUserTimezone(i?.responseDate, 'MMM DD YYYY, hh:mm') : " ",
                        "Status": i?.response_id ? "Completed" : "Pending"
                    }
                    if (survey_detail) {
                        data[`Score (Maximum Score: ${survey_detail?.max_score})`] = i?.response_id ? i?.totalScore : " "
                    } else {
                        data[`Score`] = i?.response_id ? i?.totalScore : " "
                    }
                    return data
                })

            } else {
                csv_data = employee_list_response?.map((i, index) => {
                    return {
                        "Sr.No": index + 1,
                        "Name": `${i?.firstName} ${i?.lastName || ""}`,
                        "Email": i?.email,
                        "Response Date": i?.responseDate ? displayUtcToUserTimezone(i?.responseDate, 'MMM DD YYYY, hh:mm') : " ",
                        "Status": i?.response_id ? "Completed" : "Pending"
                    }
                })
            }
            if (csv_data?.length) {
                exportToCsv(`MApp_${survey_name}_report_${moment()}`, csv_data)
                resetDownload()
                message.success("Report has been exported")
            } else {
                resetDownload()
                message.error("No data available")
            }

        } catch (error) {
            resetDownload()
            message.error("Failed to download")
        }
    }

    const resetDownload = () => {
        ref_360DownloadData.current = {
            employee_list: [],
            invitation_list: []
        }
        SetDownloadProgress(0)
        SetExportLoader(false)
    }

    return (
        <>
            {(permission && !permission?.required_permission) && (<NoPermissionView />)}
            {
                (permission && permission?.required_permission) && (
                    <PocReprtListView org_survey_lists={org_survey_lists}
                        selected_survey_response={selected_survey_response}
                        allEmployeeNon360SurveyResponse={allEmployeeNon360SurveyResponse}
                        table_data_loading={table_data_loading}
                        sortScore={(type) => sortScore(type)}
                        total_score={total_score}
                        response_page_info={response_page_info}
                        survey_list_loading={survey_list_loading}
                        allEmployee360SurveyResponse={allEmployee360SurveyResponse}
                        GetDataForcsvOf360Survey={GetDataForcsvOf360Survey}
                        export_loader={export_loader}
                        download_progress={download_progress}
                        setSurveyResponseSet={setSurveyResponseSet}
                        GetDataForcsvOfNon360Survey={GetDataForcsvOfNon360Survey}
                        {...props} />
                )
            }
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }

        </>
    );
};

export default compose(withApollo)(PocReportListContainer);

import gql from 'graphql-tag';

export const ME_SURVEY_FOR_CEO_OR_POC_QUERY = gql`
  query me($id: ID!) {
    surveys: me{
      id
    emp: employee{
      id
      orgPocEmployee(id: $id){
        edges{
          node{
            surveySet {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
      orgCeoEmployee(id: $id){
        edges {
          node {
            id
            surveySet{
              edges{
                node{
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
  }
`;

export const ORG_SURVEY_LIST_FOR_REPORT = gql`
  query allOrgSurveysByImplicitOrgId($first: Int,$listing: Boolean) {
    allOrgSurveysByImplicitOrgId(first:$first,listing:$listing){
      pageInfo{
        hasNextPage
        endCursor
      }
      totalCount
        edges{
          node{
            id
            name 
          }
        }
      }
  }
`;

import React from 'react';
import { Row, Col, Form, Divider } from 'antd';
import { useLocation } from 'react-router-dom';

import {UserTranferField, ButtonsComponent, getIntFromString } from '../../look';
import ROUTE from '../../user/route';
import UserFieldAddEmployee from 'modules/look/components/form-components/UserFieldAddEmployee';

const EmployeeFormComponent = props => {
  const { onSubmit, employeeById } = props;
  const [button, setButton] = React.useState('');

  const { state } = useLocation();
  const initialValue = {
    userId: employeeById && employeeById.user && getIntFromString(employeeById.user.id)
  };
  return (
    <Row>
      <Col span={24}>
        <Form
          layout="vertical"
          onFinish={values => onSubmit(values, button)}
          initialValues={initialValue}
          required={false}
          scrollToFirstError={true}
        >
          {state && state.type === 'multiple' ? (
            <>
              <Row>
                <Col span={24}>
                  <UserTranferField
                    name="userId"
                    label="Users"
                    rules={[
                      { required: true, message: 'User is required!' }
                    ]} /* initialValues={initialValue && initialValue.employees} */
                  />
                </Col>
              </Row>

              <Divider />
              <ButtonsComponent setButton={setButton} saveedit={false} />
            </>
          ) : (
            <>
              <Row>
                <Col span={10} offset={1}>
                  <Row>
                    <Col span={24}>
                      <UserFieldAddEmployee
                        name="userId"
                        label="User"
                        rules={[{ required: true, message: 'User is required!' }]}
                        onAdd={'add'}
                        onEdit={ROUTE.editUserLink}
                        {...props}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={10}></Col>
                <Col span={3} />
              </Row>

              <Divider />
              <ButtonsComponent setButton={setButton} />
            </>
          )}
        </Form>
      </Col>
    </Row>
  );
};

export default EmployeeFormComponent;

import React from 'react';
import { SnippetsOutlined } from '@ant-design/icons';

import Tool from './containers/Tool';
import ToolDetail from './containers/ToolDetail';
import AddTool from './containers/AddTool';
// import AddCommitment from './containers/AddCommitment';
import EditTool from './containers/EditTool';
import GenericTool from './containers/GenericTool';
import GenericToolAdmin from './containers/GenericToolAdmin';
import AddEditToolView from './containers/AddEditTool/AddEditToolContainer';

import ROUTE from './route';
import impactNarrativeContainer from './containers/impactNarrativeContainer';

export default [
  {
    name: 'EditTool',
    path: ROUTE.editTool,
    component: EditTool,
    admin: true
  },
  {
    name: 'Tool',
    path: ROUTE.tool,
    component: Tool,
    admin: true,
    icon: <SnippetsOutlined />,
    adminDropdown: true,
    exact: true
  },
  {
    name: 'ToolDetail',
    path: ROUTE.toolDetail,
    component: ToolDetail,
    admin: true
    // adminDropdown: true,
    // icon: <MacCommandOutlined />
  },
  {
    name: 'GenericTool',
    path: ROUTE.genericTool,
    component: GenericTool,
    protect: true
  },
  {
    name: 'GenericToolAdmin',
    path: ROUTE.genericToolAdmin,
    component: GenericToolAdmin,
    admin: true
  },
  {
    name: 'AddTool',
    path: ROUTE.addTool,
    component: AddTool,
    admin: true
  },
  {
    name: 'EditNewTool',
    path: ROUTE.uiFlowEditTool,
    component: AddEditToolView,
    admin: false
  },
  {
    name: 'impact_narrative',
    path: ROUTE.impact_narrative,
    component: impactNarrativeContainer,
    admin: false,
    protect: true
  },
  {
    name: 'GenericTool',
    path: ROUTE.wholeTool,
    component: GenericTool,
    protect: true
  }
];

// @ts-nocheck
import React from "react";
import { compose } from 'modules/core';
import TeamsDashboardView from "../components/OkrTeamsDashboardView.jsx";
import { CASCARDING_OBJECTIVE_LIST, NON_CASCARDING_OBJECTIVE_LIST } from "../graphql/TeamsCascadingObjective.gql.js";
import { getIntFromString } from 'modules/look';
import { withApollo } from '@apollo/client/react/hoc';
import { PERFORMANCE_CHART_DATA } from "../graphql/PerformanceChartData.gql.js";
import { TEAM_MEMBER_LIST } from "../graphql/TeammemberLists.gql.js";
const TeamsDashboardComponent =(props)=>{
    const {me,client,teamDetail,dateRangeTofilter}=props
    const [cascadedOkr, setCascadedOkr] = React.useState();
    const [NoncascadedOkr, setNonCascadedOkr] = React.useState();
    const [PerformanceData, setPerformanceData] = React.useState();
    const [teamData, setteamData] = React.useState();
    const [cascadLoding,setCascadeLoding]=React.useState(false)
    const [NoncascadLoding,setNonCascadeLoding]=React.useState(false)
    const [progressLoding,setProgressLoding]=React.useState(false)
    const [teamLoding,setTeamLoding]=React.useState(false)
    React.useEffect(()=>{
        if(me&&teamDetail&&dateRangeTofilter){
            let manager =teamDetail?.managers?.edges?.map(({node})=>getIntFromString(node?.id))
        
            if(manager?.length){
              getCascardRecord(0)
              getNonCascadeRecord(0)
              getPerformanceData(5,0 )
              getTeamMembersList(0)
            }
        }
        else{
          setCascadedOkr(null)
          setNonCascadedOkr(null)
          setPerformanceData(null)
          setteamData(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[teamDetail,me,dateRangeTofilter])

    // let dateRangeTofilter;
    // if (dateRange) {
    //     dateRangeTofilter = dateRange
    // } else {
    //     dateRangeTofilter = [startOfQuarter, endOfQuarter]
    // }

    const getCascardRecord = async(id,offset,pagination=false)=>{
      setCascadeLoding(true)
      let manager =teamDetail?.managers?.edges?.map(({node})=>getIntFromString(node?.id))
        const { data } = await client.query({
            query: CASCARDING_OBJECTIVE_LIST,
            variables: {
                okr_Duedate_Range: dateRangeTofilter,
                role_In: ["COLLABORATOR", "ASSIGNED"],
                employee_In: manager,
                offset: offset*100, first: 100
            },
            // fetchPolicy: 'network-only'
          });
          if(data?.AllCascadingObjectives){
            if (pagination) {
                let obj = {
                  edgeCount: cascadedOkr?.edgeCount + data?.AllCascadingObjectives?.edgeCount,
                  edges: [...cascadedOkr?.edges,...data?.AllCascadingObjectives?.edges],
                  totalCount: data?.AllCascadingObjectives?.totalCount
                }
                setCascadedOkr(obj)
            } else {
              setCascadedOkr(data?.AllCascadingObjectives)
            }
            setCascadeLoding(false)
          }
      
    }
    const getNonCascadeRecord =async(offset,pagination=false)=>{
      let manager =teamDetail?.managers?.edges?.map(({node})=>getIntFromString(node?.id))
      setNonCascadeLoding(true)
        const { data } = await client.query({
            query: NON_CASCARDING_OBJECTIVE_LIST,
            variables: {
              id:teamDetail?.id, 
              okr_Duedate_Range: dateRangeTofilter,
              createdBy_In:manager, offset: offset*100, first: 100
            },
            // fetchPolicy: 'network-only'
          });
          if(data?.AllNonCascadingObjectives){
            let emp_edges= data.AllNonCascadingObjectives.employees.edges.map(({node})=>{
              return node?.okrContributorSet?.edges?.map((item=>item))
            })
            let head_edges= data.AllNonCascadingObjectives.managers.edges.map(({node})=>{
              return node?.okrContributorSet?.edges?.map((item=>item))
            })
            let edges=[...emp_edges,...head_edges]
            edges = [].concat.apply([],edges) 
            var setObj = new Set(); 
          edges = edges?.reduce((acc,item)=>{
            if(!setObj.has(item?.node?.okr?.id)){
              setObj.add(item?.node?.okr?.id)
              acc.push(item)
            }
            return acc;
          },[])
          let fetchedData ={edges:edges,edgeCount:edges?.length,totalCount:edges?.length}
          if (pagination) {
            let obj = {
              edgeCount: NoncascadedOkr?.edgeCount + fetchedData?.edgeCount,
              edges: [...NoncascadedOkr?.edges, ...fetchedData?.edges],
              totalCount: fetchedData?.totalCount
            }
            setNonCascadedOkr(obj)
          } else {
            setNonCascadedOkr(fetchedData)
          }
          }
          setNonCascadeLoding(false)
    }

    const getPerformanceData = async(first,offset,pagination=false)=>{
      setProgressLoding(true)
      let manager =teamDetail?.managers?.edges?.map(({node})=>getIntFromString(node?.id))
      const { data } = await client.query({
        query: PERFORMANCE_CHART_DATA,
        variables: {
          savedOkrType: "OBJECTIVE",okr_Duedate_Range: dateRangeTofilter,
            employee_In: manager,
            offset: offset, first: first
        },
        // fetchPolicy: 'network-only'
      });
      if(data?.PerformanceGraphData){
        if(pagination){
        let  fetchData ={
            totalCount:data?.PerformanceGraphData?.totalCount,
            edgeCount:data?.PerformanceGraphData?.edgeCount+PerformanceData?.edgeCount,
            edges:[...PerformanceData?.edges,...data?.PerformanceGraphData?.edges]
          }
          setPerformanceData(fetchData)
        }
        else{
          setPerformanceData(data?.PerformanceGraphData)
        }
        
        setProgressLoding(false)
      }
    }

    const getTeamMembersList =async(offset)=>{
      let manager =teamDetail?.managers?.edges?.map(({node})=>getIntFromString(node?.id))
      setTeamLoding(true)
      const { data } = await client.query({
        query: TEAM_MEMBER_LIST,
        variables: {
          id:teamDetail?.id,okr_Duedate_Range: dateRangeTofilter,employee_In: manager, createdBy_In:manager, offset: offset*100, first: 100
        },
        // fetchPolicy: 'network-only'
      });
      if(data?.teamData){
        setteamData(data?.teamData)
      }
      setTeamLoding(false)
    }
    return (
        <>
          <TeamsDashboardView {...props} 
          cascadedOkr={cascadedOkr} 
          NoncascadedOkr={NoncascadedOkr} 
          PerformanceData={PerformanceData} 
          teamData={teamData} 
          teamDetail={teamDetail}
          cascadLoding={cascadLoding}
          NoncascadLoding={NoncascadLoding}
          progressLoding={progressLoding}
          teamLoding={teamLoding}
          getOkrPerformanceData={(first,offset,pagination)=>{getPerformanceData(first,offset,pagination)}}
          getAllNonCascadedObjectives={(id,offset)=>{getNonCascadeRecord(offset,true)}}
          getAllCascadedObjectives={(id,offset)=>{getCascardRecord(id,offset,true)}}
         />
        </>
    )
}

export default  compose(withApollo)(TeamsDashboardComponent)
import gql from "graphql-tag";




export const GET_ENTITY_HEAD_ROLE = gql`
query entityHeadRoles($user:ID){
      entityHeadRoles: me {
        id
        employee {
          id
        organizationSet{
        edges{
          node{
            id
            employees(user:$user){
              edges{
                node{
                  id
                  teamManagerEmployee{
                    totalCount
                  }
                  verticalHeadEmployee{
                    totalCount
                  }
                  orgPocEmployee{
                    totalCount
                  }
                  orgCeoEmployee{
                    totalCount
                  }
                }
              }
            }
          }
        }
      }
        }
      }
    }
`

export const UPDATE_CORRECTION_DEADLINE = gql`
mutation extendOkrCorrectionDeadline($id: ID!,$correctionDeadline: DateTime!){
    extendOkrCorrectionDeadline(id:$id,correctionDeadline:$correctionDeadline){
        OKR{
            id
            correctionDeadline
          }
    }
}
`
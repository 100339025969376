import React from 'react';
import _ from 'lodash';
import { Button, Modal, Spin } from 'antd';

import { compose } from '../../core';
import { EditIcon, getIntFromString } from '../../look';

import FormFormComponent from '../components/FormSectionFormComponent';

import { withEditFormSection, withAddFormSection } from './FormSectionOperation';

const AddForm = props => {
  const { btn, onSubmit, createFormSection, editFormSection, btnText, formSection, sequence } = props;
  const [visible, setVisible] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);

  const handleSubmit = async values => {
    setActionLoading(true);
    try {
      if (formSection?.id) {
        const response = await editFormSection({ id: getIntFromString(formSection.id), ...values });
        setActionLoading(false);
        response && setVisible(false);
      } else {
        const response = await createFormSection({
          formSectionData: _.omit({ ...values, sequence }, ['simplefieldId', 'referencefieldId']),
          simplefieldId: values.simplefieldId,
          referencefieldId: values.referencefieldId
        });
        setActionLoading(false);
        if(response){
          setVisible(false)
          onSubmit && onSubmit({ formsection: [getIntFromString(response.id)] });}
        }
    } catch (e) {
      setActionLoading(false);
      throw Error(e.message);
    }
  };

  return (
    <>
      {btn === 'add' ? (
        <Button block type={'primary'} ghost onClick={() => setVisible(true)}>
          {btnText}
        </Button>
      ) : (
        <EditIcon type="link" size="sm" tooltipSuffix="Form Section" onClick={() => setVisible(true)} />
      )}

      <Modal
        destroyOnClose
        centered
        visible={/* true || */ visible || window.location.href.includes('openmodal')}
        onCancel={() => setVisible(false)}
        // footer={null}
        width="50%"
        title={btn === 'add' ? 'Add Form' : 'Edit Form'}
        footer={null}
      >
        <Spin spinning={actionLoading} size="medium">
          <FormFormComponent
            btnText={btn === 'add' ? 'Add' : 'Save'}
            formSection={formSection}
            setVisible={setVisible}
            onSubmit={handleSubmit}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default compose(withEditFormSection, withAddFormSection)(AddForm);

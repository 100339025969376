import { Button, Col, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import '../css/SalesToolStyles.css'
import BookDemoView from './BookDemoView';
import SalesPageFooter from './SalesPageFooter';
import SalesPageNavbar from './SalesPageNavbar';


const FeedbackToolView = props => {

    const { tool } = props

    const history = useHistory();

    React.useEffect(()=>{
        let element = document.getElementById('sales-tool')
        if(element){
            element.scrollIntoView({block:'start',inline:'start',behavior:'smooth'})
        }
    },[tool])

    return (
        <>
        <div  id='sales-tool'>
            
            <SalesPageNavbar />
            {
                tool?.rowSet?.map((row) =>
                    <Col span={23} className="poppins" style={{ margin: 'auto', marginTop: '100px', marginBottom: '100px' }}>
                        {
                            row?.rowType === 'textFirst' ?
                                <div>


                                    <Row align='middle' className='mapp-tool-sales-page'  justify={row.justify} wrap={false}>


                                        <Col xs={23} sm={23} md={row.textSpan} lg={row.textSpan}>
                                            <Row justify='start' className='mapp-tool-text-col-sales' style={{ flexDirection: 'column', marginLeft: row.marginLeft, maxWidth: '80%' }}>
                                                <div style={{width:row.textWidth}} className='sales-tool-heading'>{row?.heading}</div>
                                                {
                                                    row?.subHeading &&
                                                    <div className='poppins tool-subheading-sales-tool'>{row?.subHeading}</div>
                                                }

                                                {
                                                    row?.text &&
                                                    <div style={{width:row?.textWidth}} className='sales-tool-description'>{row?.text}</div>
                                                }

                                                {
                                                    row?.orderedList &&
                                                    <ul className='tool-ordered-list'>
                                                        {
                                                            row?.orderedList?.map((listItem) =>
                                                                <li sales-tool-description>{listItem.text}</li>
                                                            )
                                                        }
                                                    </ul>
                                                }


                                            </Row>
                                            <Row justify='start' style={{marginLeft: row.marginLeft}}>
                                        {
                                            row?.showbutton &&
                                            <Button className='button-link-sales-page sales-tool-button' onClick={(e) => history.push('/intro/getintouch')}>Get in Touch</Button>
                                        }

                                    </Row>

                                        </Col>

                                        <Col xs={23} sm={23} md={row?.imageSpan} lg={row?.imageSpan}>
                                            <img style={{ width: '100%' }} src={row?.image} alt=''/>
                                        </Col>
                                    </Row>
                                    



                                </div>
                                :
                                <Row align='middle' justify={row.justify}>
                                    <Col xs={23} sm={23} md={8} lg={8}>
                                        <img style={{ width: '100%' }} src={row?.image} alt=''/>
                                    </Col>
                                    <Col xs={23} sm={23} md={12} lg={12}>
                                        <Row justify='start' className='mapp-tool-text-col-sales' style={{ flexDirection: 'column', marginLeft: row.marginLeft, maxWidth: '80%' }}>
                                            <div style={{width:row.textWidth}} className='sales-tool-heading'>{row?.heading}</div>
                                            {
                                                row?.text &&
                                                <div className='sales-tool-description'>{row?.text}</div>
                                            }

                                            {
                                                row?.orderedList &&
                                                <ul className='tool-ordered-list'>
                                                    {
                                                        row?.orderedList?.map((listItem) =>
                                                            <li sales-tool-description>{listItem.text}</li>
                                                        )
                                                    }
                                                </ul>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                        }
                    </Col>
                )
            }


            <BookDemoView paddingTop='57px' />

            <SalesPageFooter />
            </div>
        </>
    );
};

export default FeedbackToolView;


// @ts-nocheck
import { message } from 'antd';
import { graphql } from '@apollo/client/react/hoc';
import { USER_ME_QUERY } from '../graphql/allorganizationEmployees.gql'
import { KEYRESULT_LIST } from '../graphql/KeyresultList.gql'
import { FEEDBACK_SURVEY_BY_ID } from '../graphql/FeedBackSurveyByid.gql'
import { CREATE_FEEDBACK_MUTATION } from '../graphql/createfeedbackmutation.gql'
import { SUBMITTED_FEEDBACK } from '../graphql/SubmittedFeedback.gql'
import { RECEIVED_FEEDBACK } from '../graphql/ReceivedFeedback.gql'
import { FEEDBACK_BY_ID } from '../graphql/FeedbackById.gql'
import { UPDATE_FEEDBACK_MUTATION } from '../graphql/UpdateFeedbackMutation.gql'
import { DELETE_FEEDBACK_MUTATION } from '../graphql/DeleteFeedbackMutation.gql'
import { FEEDBACK_SURVEY_ID } from '../../../config'
import { USER_EMPLOYEE_QUERY } from '../graphql/employees.gql'
// Mutation
export const withCreateFeedback = Component =>
  graphql(CREATE_FEEDBACK_MUTATION, {
    props: ({ mutate, history }) => ({
      createFeedback: async values => {
        try {
          const {
            data: { createFeedback }
          } = await mutate({
            variables: {
              ...values
            }
          });

          return createFeedback.feedback;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);


  export const employeeList = Component =>
  graphql(USER_ME_QUERY, {
    props({ data: { loading, error, allOrganizationEmployees} }) {
      return { loading, error, allOrganizationEmployees };
    }
   
  })(Component);

  export const withKeyResults = Component =>
  graphql(KEYRESULT_LIST, {
    options: props => {
      let id = '';
      if ( props?.me) {
        id = props?.me?.employee?.id;
      } 
      return {
        variables: { owner: id}
      };
    },
    props({ data: { loading, error, keyresults, subscribeToMore, updateQuery, refetch } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, keyresults, subscribeToMore, updateQuery, refetch };
    }
  })(Component);

  // do not change this survey id
  export const withFeedBackSurveyByid = Component =>
  graphql(FEEDBACK_SURVEY_BY_ID, {
    options: props => {
      return {
        variables: { id: FEEDBACK_SURVEY_ID},
        fetchPolicy: 'network-only'
      };
    },
    props({ data: { loading, error, SurveyById, subscribeToMore, updateQuery, refetch } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, SurveyById, subscribeToMore, updateQuery, refetch };
    }
  })(Component);

  export const withSubmittedFeedback = Component =>
  graphql(SUBMITTED_FEEDBACK, {
    options: props => {
      let id = '';
      if ( props?.me) {
        id = props?.me?.employee?.id;
      } 
      return {
        variables: { feedbackFrom: id}
      };
    },
    props({ data: { loading, error, submittedFeedback, subscribeToMore, updateQuery, refetch} }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, submittedFeedback, subscribeToMore, updateQuery, refetch };
    }
  })(Component);

  export const withReceivedFeedback = Component =>
  graphql(RECEIVED_FEEDBACK, {
    options: props => {
      let id = '';
      if ( props?.me) {
        id = props?.me?.employee?.id;
      } 
      return {
        variables: { feedbackTo: id}
      };
    },
    props({ data: { loading, error, receivedFeedback, subscribeToMore, updateQuery, refetch} }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, receivedFeedback, subscribeToMore, updateQuery, refetch };
    }
  })(Component);

  export const withFeedBackByid = Component =>
  graphql(FEEDBACK_BY_ID, {
    options: props => {
      let id = '';
      if (props?.match?.params) {
        id = props?.match?.params?.feedid;
      } 
      return {
        variables: { id: id},
        fetchPolicy: 'network-only'
      };
    },
    props({ data: { loading, error, feedbackById, subscribeToMore, updateQuery, refetch } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, feedbackById, subscribeToMore, updateQuery, refetch };
    }
  })(Component);

  export const WithUpdateFeedback = Component =>
  graphql(UPDATE_FEEDBACK_MUTATION, {
    props: ({ mutate, history }) => ({
      updateFeedback: async values => {
        try {
          const {
            data: { updateFeedback }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          // message.success('Feedback Updated Successfully!!');
          return updateFeedback.feedback;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);
  
  export const withDeleteFeedback = Component =>
  graphql(DELETE_FEEDBACK_MUTATION, {
    props: ({ mutate, history }) => ({
      deleteFeedback: async values => {
        try {
          const {
            // eslint-disable-next-line no-unused-vars
            data: { deleteFeedback }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Successfully deleted');
          return true;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);


  // export const UseremployeeList = Component =>
  // graphql(USER_EMPLOYEE_QUERY, {
  //   props({ data: { loading, error, allEmployee} }) {
  //     return { loading, error, allEmployee };
  //   }
   
  // })(Component);

  export const UseremployeeList = Component =>
  graphql(USER_EMPLOYEE_QUERY, {
    options: props => {
      return {
        fetchPolicy: 'network-only'
      };
    },
    props({ data: { loading, error, allEmployee, subscribeToMore, updateQuery, refetch } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, allEmployee, subscribeToMore, updateQuery, refetch };
    }
  })(Component);
// @ts-nocheck
import React, { useState, useRef } from 'react';
import { Slider } from "antd";
import '../Css/audio.css'
import pause from '../../assets/pause.png'
import play_icon from '../../assets/play-button.png'
import audio_play_active from '../../assets/audio_play_active.png'

const AudioPlayer = props => {
    const { data, audioEnd, audioPlay, className, controlsList, audioPause, showCustomAudio, read_audio } = props
    const [duration, setAudioDuration] = useState(0)
    const [max_duration, setMaxAudioDuration] = useState(data?.duration||8)
    const [audio_status, SetAudioStatus] = useState('pause')
    const audioRef = useRef(null);
    const audioPlayEnd = () => {
        audioOnLoad()
        SetAudioStatus('pause')
        setAudioDuration(0)
        if (audioEnd) {
            audioEnd(data)
        }
    }

    const audioPlayStart = () => {
        SetAudioStatus('play')
        if (audioPlay) {
            audioPlay(data)
        }
    }

    const audioPlayPause = () => {
        SetAudioStatus('pause')
        if (audioPause) {
            audioPause(data)
        }
    }

    const audioProgressUpdate = (event) => {
        const durationInSeconds = audioRef?.current?.currentTime;
        setAudioDuration(durationInSeconds)
    }

    const audioOnLoad = () => {
        const durationInSeconds = audioRef?.current.duration;
        if(!isNaN(durationInSeconds) && isFinite(durationInSeconds)){
            setMaxAudioDuration(durationInSeconds)
        }
    }

    const customAudioPlay = () => {
        const current_player = document.getElementById(`audio_${data?.index}`)
        if (current_player) {
            current_player.play()
        }
    }

    const customAudioPause = () => {
        const current_player = document.getElementById(`audio_${data?.index}`)
        if (current_player) {
            current_player.pause()
        }
    }
   
    const audioSeeking = (duration) => {
        if(audioRef.current){
            audioRef.current.currentTime = duration
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <audio controls id={`audio_${data?.index}`} ref={audioRef} className={`audio-player ${showCustomAudio ? 'hide-audio-player' : ''} ${className ? className : ''}`} onPlay={(e) => { audioPlayStart() }} onEnded={(e) => { audioPlayEnd() }} onPause={(e) => { audioPlayPause() }} onLoadedMetadata={()=>{audioOnLoad()}} onTimeUpdate={(e)=>{audioProgressUpdate(e)}} controlsList={controlsList}>
                {
                    data?.audio?.map(item=>
                        <source src={item?.url} type={item?.type} />
                    )
                }
                
            </audio>
            {
                showCustomAudio ?
                    <div className='audio-container'>
                        <div className={`audio-border-custom custom-audio-player ${audio_status === 'play'?'active-audio-player':''} ${read_audio?.includes(data?.chunk_id)?'active-audio-player':''} `} >
                            {
                                audio_status === 'pause' ?
                                    <img src={read_audio?.includes(data?.chunk_id)?audio_play_active:play_icon} onClick={() => { customAudioPlay() }} className='audio-download-icon' alt="" srcset="" />
                                    : audio_status === 'play' ? <img src={pause} onClick={() => { customAudioPause() }} className='audio-download-icon' alt="" srcset="" /> : ''
                            }
                            <div style={{width:"100%"}}>
                            <Slider value={duration} max={max_duration} onChange={(e)=>setAudioDuration(e)} onAfterChange={e => {audioSeeking(e)}} step={.1}/>
                            </div>
                            {/* <input type="range" onChange={} /> */}
                        </div>
                    </div> : ''
            }

        </div>
    )
}
export default AudioPlayer;


import { withApollo } from '@apollo/client/react/hoc';
import { Button, Col, Modal, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { compose } from '../../core';
import {
  displayDataCheck, displayUtcToUserTimezone, GetColumnSearchProps, getOrderBy, readableTime, RenderTable
} from '../../look';
import { ALL_RESPONSES_BY_USER_ID_QUERY } from '../graphql/AllResponsesByUserId.gql';

import { SURVEY_RESPONSE_SUBSCRIPTION_OPTIMIZED } from '../graphql/SurveyResponseSubscription.gql';
import { withSurveyFilterUpdating, withSurveyState } from './SurveyOperations';

const ResponsesByUserIdTable = props => {
  const {
    userId,
    username,
    onOrderByChange,
    orderBy,
    loading,
    allResponsesByUserId,
    selectedRowKeys,
    handleChange,
    onSurveyChange,
    client,
    filter
  } = props;
  const [visible, setVisible] = React.useState(false);
  const [surveyResponses, setSurveyRepsonses] = React.useState(undefined);
  const [paginationFilter,setPaginationFilter] = React.useState({first: 10,offset: 0});
  // eslint-disable-next-line no-unused-vars
  const [searchFilter,setSearchFilter] = React.useState({"survey":""})
  let surveyResponseSubscription = undefined;
  const m = React.useRef(true)

  const surveyResponseDataRef = React.useRef(null);


  React.useEffect(()=>{
    let updatedFilters;
     if(filter?.survey ){
       
       updatedFilters = {survey:filter?.survey}
      
     }else{
       updatedFilters = {...paginationFilter,...searchFilter}
     }
      
     getAllResponses(updatedFilters)
 
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[filter])




  const getAllResponses = async (filters) => {
     
    if(filters?.first){
      setPaginationFilter({...filters})
    }
    
    const { data } = await client.query({
      query: ALL_RESPONSES_BY_USER_ID_QUERY,
      variables: {...filters, userId:userId ,orderBy},
      fetchPolicy: 'network-only',
    });
    
    if (data?.allResponsesByUserId) {
      setSurveyRepsonses(data?.allResponsesByUserId);
      SubscribeToSurveyResponses();
      surveyResponseDataRef.current = data?.allResponsesByUserId;
      
    }
  }



  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])

  React.useEffect(() => {
    return () => {
      if (surveyResponseSubscription) {
        surveyResponseSubscription.unsubscribe();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  React.useEffect(() => {

    if (allResponsesByUserId) {
      if (!surveyResponses) {
        surveyResponseDataRef.current = allResponsesByUserId;
        setSurveyRepsonses(allResponsesByUserId)
      }
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allResponsesByUserId])


  const SubscribeToSurveyResponses = async () => {

    surveyResponseSubscription = client.subscribe({
      query: SURVEY_RESPONSE_SUBSCRIPTION_OPTIMIZED,
    }).subscribe({
      next(result) {

        switch (result.data.surveyResponseSubscription.mutation) {

          case 'DELETE':

            if (m.current) {
              let responses = surveyResponseDataRef.current?.edges

              let surveyResponsesWithDeletedRecord = responses?.filter((response) =>
                response?.node?.id !== result.data.surveyResponseSubscription?.surveyResponse?.id)

              let responseSet = { edgeCount: (surveyResponseDataRef.current?.edgeCount - 1), totalCount: (surveyResponseDataRef.current?.totalCount - 1), edges: surveyResponsesWithDeletedRecord }
              setSurveyRepsonses(responseSet);

              surveyResponseDataRef.current = responseSet;
            }

            break;
          default:
            break
        }

      }
    })

  }





  const columns = [
    {
      title: 'Survey Name',
      key: 'name',
      sorter: () => onOrderByChange(getOrderBy('name', orderBy)),
    
      ...GetColumnSearchProps('name', onSurveyChange, 'survey'),
      render: (text, record) => <>{record && displayDataCheck(record.survey.name)}</>
    },
    {
      title: 'Respone Date',
      key: 'responseDate',
      sorter: () => onOrderByChange(getOrderBy('responseDate', orderBy)),
      render: (text, record) => `${displayUtcToUserTimezone(record.responseDate, 'YYYY-M-DD HH:mm')}`
    },
    {
      title: 'Response Duration',
      key: 'responseDuration',
      sorter: () => onOrderByChange(getOrderBy('responseDuration', orderBy)),
      render: (text, record) => record?.responseDuration && <>{`${readableTime(record?.responseDuration)}`}</>
    },
    {
      title: 'Action',
      key: 'action',
      // sorter: () => onOrderByChange(getOrderBy('action', orderBy)),
      render: (text, record) => (
        <>
          {record && (
            <Button
              type="primary"
              onClick={() => {
                setVisible(true);
              }}
            >
              Get Report
            </Button>
          )}
        </>
      )
    }
  ];
  const EmailSent = () => (
    <>
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        {/* <MailSent>
          <CheckCircleFilled style={{ color: '#009c4a', fontSize: '25px' }} /> &nbsp; Email Sent! <br />
        </MailSent> */}
        <h3>
          This could take a minute.
          <br />
          Generated report will be sent to your registered email.
        </h3>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                setVisible(false);
              }}
              size="middle"
            >
              Close
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Button type="primary" ghost onClick={() => setVisible(false)} size="middle">
              Close
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  return (
    <>
      <h2>Responses for {username}</h2>
      <RenderTable
        tableData={surveyResponses}
        loading={loading}
        onPaginationChange={getAllResponses}
        columns={columns}
        selectedRowKeys={selectedRowKeys}
        handleChange={handleChange}
      />
      <Modal
        destroyOnClose
        footer={null}
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        bodyStyle={{ padding: '0px' }}
      >
        <ModalBody>
          <EmailSent />
        </ModalBody>
      </Modal>
    </>
  );
};

export default compose(
  withApollo,
  withSurveyState,
  withSurveyFilterUpdating
)(ResponsesByUserIdTable);

const ModalBody = styled.div`
  padding: 24px 48px;
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;

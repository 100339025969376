import { Button, Form, Input,Select } from 'antd';
import React from 'react';
import { competency_icons } from '../icon';

const MainCompetencyEditView = (props) => {

    const { cancel, onSubmit, edit_competency_value } = props;
    const [form] = Form.useForm();
    React.useEffect(() => {
        if (edit_competency_value) {
            let type = edit_competency_value?.competencyType ==='ROLE_SPECIFIC'?'Role_Specific':'Generic'
            
            form.setFieldsValue({
                title: edit_competency_value?.title,
                competencyType:type,
            })
        }
    }, [edit_competency_value, form])

    return (
        <>
            <div className='rd-create-main-competency-container'>
                <img className='cp-modal-close-icon' style={{ width: '2em' }} src={competency_icons?.jf_modal_close} alt="" onClick={() => cancel()} />
                <Form
                    form={form}
                    onFinish={values => onSubmit(values)}
                    scrollToFirstError={true}
                    className='rd-align-center rd-add-edit-competency-container'
                    style={{ gap: '.5em', marginTop: '1em' }}
                >
                    {/* <div style={{ display: "flex", flexDirection: "row", gap: "1em", width: "100%", justifyContent: "space-between", alignItems: 'center' }}> */}
                    <div className='rd-input-container'>
                        <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Title</h4>
                        <Form.Item
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input title',
                                },
                            ]}
                            style={{ width: '100%' }}
                        >
                            <Input className="rd-text-input" style={{ width: "100%" }} placeholder='Enter competency title' />
                        </Form.Item>
                    </div>
                    <div className='rd-input-container'>
                        <h4 className='rd-input-title'><span className='rd-input-star'>*</span> Type</h4>
                        <Form.Item
                            name="competencyType"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select type',
                                },
                            ]}
                            style={{ width: '100%' }}
                        >
                        <Select
                            className="rd-select-filed rd-type-select"
                            style={{ width: '100%', marginTop: "10px" }}
                            suffixIcon={<img src={competency_icons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                            placeholder='select competency type'
                            onChange={(e) => {}}
                            >
                            <Select.Option
                                className='rd-select-option'
                                key={'Generic'}
                                value={'Generic'}
                            >
                                Generic Competency
                            </Select.Option>
                            <Select.Option
                                className='rd-select-option'
                                key={'Role_Specific'}
                                value={'Role_Specific'}
                            >
                                Functional competency(Role specific)
                            </Select.Option>
                        </Select>
                        </Form.Item>
                    </div>
                    {/* </div> */}
                    <div className='rd-confirmation-action-container' style={{ marginTop: "1em" }}>
                        <Button className='global-submit-btn' htmlType="submit">Save</Button>
                        <Button className='global-cancel-btn' onClick={() => cancel()}>Cancel</Button>
                    </div>
                </Form>


            </div>
        </>
    )
}

export default MainCompetencyEditView
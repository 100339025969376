import ROUTE from './routes';
import ReadinessHome from './containers/ReadinessHomeContainer'
import CreateReadinessContainer from './containers/AddReadinessContainer'
import EditRedinessComponent from './containers/EditRedinessComponent';
export default [
    {
        name: 'ReadinessHome',
        path: ROUTE.readinessHome,
        component: ReadinessHome,
        exact: true,
        protect:true
    },
    {
        name: 'AddReadininess',
        path: ROUTE.addReadiness,
        component: CreateReadinessContainer,
        exact: true,
        protect:true
    },
    {
        name: 'EditReadiness',
        path: ROUTE.editReadiness,
        component: EditRedinessComponent,
        expect: true,
        protect:true
    }
]
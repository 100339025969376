import React from 'react';
import { Form, Row, Col, Button, InputNumber } from 'antd';

import { QuestionAutoComplete } from '../../look';

export const CKEditorFieldName = 'note';
const FormItem = Form.Item;

const OperandFormComponent = props => {
  const { btnText, weightedQuestion, onSubmit, setVisible } = props;

  const initialValue = {
    weight: (weightedQuestion && weightedQuestion.weight) || 1.0,
    questionId: (weightedQuestion && weightedQuestion.questionId) || null
  };

  return (
    <Form
      onFinish={
        onSubmit
      }
      layout="vertical"
      initialValues={initialValue}
      required={false}
      scrollToFirstError={true}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <QuestionAutoComplete
            name="questionId"
            offset={1}
            label="Question"
            rules={[{ required: true, message: 'Question is required!' }]}
          />
        </Col>

        <Col span={11}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, offset: 1 }}
            name="weight"
            label="Weight"
            placeholder="Weight"
          >
            <InputNumber
            autoComplete='off'
              style={{
                width: '100%'
              }}
              defaultValue="1.0"
              min="0"
              max="10"
              step="0.1" /* stringMode */
            />
          </FormItem>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col>
          <Button htmlType="submit" type="primary">
            {btnText}
          </Button>
        </Col>
        <Col>
          <Button ghost type="primary" onClick={() => setVisible(false)}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default OperandFormComponent;

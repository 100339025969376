import React, { useEffect, useState } from 'react';
import { Pagination, Select, Button, Row, Col} from 'antd';
import leftIcon from '../../assets/let-pagination.svg';
import rightIcon from '../../assets/right-pagination.svg';
import doubleRightIcon from '../../assets/double-right-pagination.svg';
import doubleLeftIcon from '../../assets/double-left-pagination.svg';
import rightArrow from '../../assets/right-arrow-pagination.png';

import PropTypes from 'prop-types';
import '../Css/ReUsablePagination.css';

const ReUsablePagination = ({ queryFunc, className, style, paginationType = 'prevNext', paginationData, paginationData: { totalCount }, size, resultsCountText = 'Results per page' }) => {

  ReUsablePagination.propTypes = {
    queryFunc: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    paginationType: PropTypes.oneOf(['numbered', 'prevNext', 'viewMore']),
  };

  const recordsPerPageOptions = [10, 20, 50, 100];
  const [recordsPerPage, setRecordsPerPage] = useState(recordsPerPageOptions[0]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <img style={{ width: '16px', height: 'auto' }} src={leftIcon} alt='left icon'/>;
    }
    if (type === 'next') {
      return <img style={{ width: '16px', height: 'auto' }} src={rightIcon} alt='right icon'/>;
    }
    return originalElement;
  };

  useEffect(() => {
    if (totalCount && totalCount > recordsPerPage) {
      let noOfPages = Math.ceil(totalCount / recordsPerPage);
      setPageCount(noOfPages);
    }
  }, [totalCount, recordsPerPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    queryFunc({ first: recordsPerPage, offset: (page - 1) * recordsPerPage });
  };

  useEffect(() => {
    if (recordsPerPage) {
      queryFunc({ first: recordsPerPage, offset: 0 });
      setCurrentPage(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsPerPage]);

  const handleRecordsPerPageChange = (value) => {
    setRecordsPerPage(value);
  };

  const handleNextPage = () => {
    queryFunc({ after: paginationData?.pageInfo?.endCursor, first: recordsPerPage });
  }

  const handleFirstPage = () => {
    queryFunc({ first: recordsPerPage, offset: 0 });
    setCurrentPage(1)
  }
  const handleLastPage = () => {
    const totalPages = Math.ceil(totalCount / recordsPerPage);
    queryFunc({ first: recordsPerPage, offset: (totalPages - 1) * recordsPerPage });
    setCurrentPage(totalPages);

  }

  const renderPagination = () => {
    switch (paginationType) {
      case 'prevNext':
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {currentPage > 1 && pageCount !== currentPage && <Button style={{ margin: '0 0.5em' }} className='pn_pagination' size={size} onClick={() => handlePageChange(currentPage - 1)}><img style={{ width: '1.6em', height: 'auto', fill: 'white', transform: 'scaleX(-1)' }} src={rightArrow} alt='right arrow'/></Button>}
            {currentPage === pageCount ? <Button style={{ margin: '0 0.5em' }} className='pn_pagination' size={size} onClick={() => handlePageChange(currentPage - 1)}><img style={{ width: '1.6em', height: 'auto', fill: 'white', marginLeft: '-0.5em', marginRight: '0.9em', transform: 'scaleX(-1)' }} src={rightArrow} alt='right arrow'/>PREVIOUS PAGE</Button> : <Button style={{ margin: '0 0.5em' }} className='pn_pagination' size={size} onClick={() => handlePageChange(currentPage + 1)}>NEXT PAGE<img style={{ width: '1.6em', height: 'auto', fill: 'white', marginLeft: '0.5em', marginRight: '-0.9em' }} src={rightArrow} alt='right arrow'/></Button>}
          </div>
        );
      case 'numbered':
        return (
          <Row align='middle' >
            <img onClick={handleFirstPage} style={{ width: '16px', height: 'auto', cursor: `${currentPage === 1 ? 'not-allowed' : 'pointer'}` }} src={doubleLeftIcon} alt='doubleLeftIcon'/>
            <Pagination

              itemRender={itemRender}
              showTotal={(total, range) => ''}
              hideOnSinglePage
              locale={{ next_page: "Next Page", prev_page: "Previous Page" }}
              size={size}
              className='pagination_parent'
              current={currentPage}
              total={totalCount}
              pageSize={recordsPerPage}
              showSizeChanger={false}
              onChange={handlePageChange}
              style={{ position: 'relative', top: '-1px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 14px 0 9px' }}
            />
            {/* <button onClick={handleLastPage} style={{backgroundColor:'transparent', border:'unset'}}><DoubleRightOutlined style={{ backgroundColor: 'transparent' }} /></button> */}
            <img onClick={handleLastPage} style={{ width: '16px', height: 'auto', cursor: `${currentPage === pageCount ? 'not-allowed' : 'pointer'}` }} src={doubleRightIcon} alt='doubleRightIcon'/>


          </Row>
        );
      case 'viewMore':
        return (
          <>
            {paginationData?.pageInfo?.hasNextPage &&
              <Button
                style={{ margin: "0 0.5em" }}
                className="pn_pagination"
                size={size}
                onClick={handleNextPage}
              >
                VIEW MORE
              </Button>}
          </>
        );
      default:
        return null;
    }
  };

  let internalStyle = {
    display: 'flex',
    justifyContent: 'center'
  }
  if (paginationType !== 'numbered') {
    return (
      <div style={{ ...style }} className={className}>
        <Row style={{ width: '100%' }} align='middle'>
          <Col span={8}>
            <Row justify='start'>
            {paginationType === 'prevNext' && pageCount === currentPage &&  <p onClick={handleFirstPage} style={{color:'#4CCB1F', cursor:'pointer'}}>{`< Go to page 1`}</p>}
            </Row>
          </Col>
          <Col span={8}>
            <Row justify='center' >
              {renderPagination()}
            </Row>
          </Col>
          <Col span={8}>
            <Row style={{ marginLeft: `${paginationType === 'numbered' ? '-38px' : ''}` }} justify={`${paginationType === 'numbered' ? 'start' : 'end'}`} align='middle'>
              {paginationType !== 'numbered' && <label style={{ margin: '0.2em', fontWeight: '500' }}>{resultsCountText}</label>}
              <Select size={size} style={{ margin: '1em' }} className='select_parent' value={recordsPerPage} onChange={handleRecordsPerPageChange}>
                {recordsPerPageOptions.map((option) => (
                  <Select.Option key={option} value={option}>
                    {paginationType === 'numbered' ? `${option} / Page` : option}

                  </Select.Option>
                ))}
              </Select>
            </Row>
          </Col>
        </Row>




      </div>
    );
  } else {
    return (
      <div style={{ ...style, ...internalStyle }} className={className}>
        <Row style={{ backgroundColor: '#F4F9FD', borderRadius: '11px', padding: "1.3em 2em" }} justify='center' align='middle'>
          {renderPagination()}
          <Select size={size} style={{ marginLeft: '2em' }} className='select_parent' value={recordsPerPage} onChange={handleRecordsPerPageChange}>
            {recordsPerPageOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {paginationType === 'numbered' ? `${option} / Page` : option}
              </Select.Option>
            ))}
          </Select>
        </Row>
      </div>
    );
  }

};

export default ReUsablePagination;

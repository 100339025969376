import React from 'react'
import { Row, Dropdown} from "antd";
import Supervisor from '../../assets/supervisor.svg'

const ShowContributors =(props)=>{
    const {dataList}=props

    const FilterItem =()=> (
        <Row align='middle' style={{flexDirection:"column",padding:"20px",position:'relative'}}>
          <h4 className='menu-head'>Contributors</h4>
            {/* <img src={upArrow} alt="" style={{position:"absolute",top:'10px',right:"10px",cursor:"pointer"}} onClick={()=>setOpenMenu(false)} /> */}
            <div className='select-content-div' style={{width:"170px"}}>
                {dataList?.map(({node})=>(
                // <div className='item-div'>
                //     <p className='selected-item'style={{background: "#F0F0F0",textAlign:"center",color: "#595959",justifyContent:'center'}}>
                //         {node?.user?.firstName}
                //     </p>
                // </div>
                <div className='item-div' align="center" style={{padding:"5px"}}>
                <div className='selected-item' style={{background: "#F0F0F0"}}>
                    <p style={{width:"100%",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",color: "#595959"}}><span className='text-transform-capitalize'>{node?.user?.firstName}</span> <span className='text-transform-capitalize'>{node?.user?.lastName}</span></p>
                </div>
            </div>
                ))}
            </div>
        </Row>
    )
    return(
        <>
          <Dropdown overlay={FilterItem} placement="topCenter"className="input-filter-dynamic" >
                <img  src={Supervisor} alt="" style={{cursor:"pointer",border:"none"}}/>
            </Dropdown>
        </>
    )
}

export default ShowContributors
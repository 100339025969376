import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Spin, message, Row, Col, Button, Modal, Result } from 'antd';

import { compose } from '../../core';
import { getIntFromString, Loading, removeSurveyCache } from '../../look';
import { withVerifyToken } from '../../user/containers/UserOperations';

import HOME_ROUTE from '../../home/route';
import USER_ROUTE from '../../user/route';

import { withSubmitTokenResponse } from './SurveyOperations';
import { withApollo } from '@apollo/client/react/hoc';
import SurveyTermsAndCondition from '../components/SurveyTermsAndCondition';
import GenericQuizView from 'modules/survey-look/containers/generic-quiz-view';

const UnSignedQuiz = props => {
  const { loadingVerifyToken, verifyToken, submitTokenResponse, client, ...rest } = props;
  const reportProgress = 0;
  const [visible, setVisible] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const { token } = useParams();
  const { history } = rest;
  const [showTermsAndCondition, setshowTermsAndCondition] = React.useState();
  React.useEffect(() => {
    if (verifyToken && verifyToken?.user && verifyToken?.valid) {
      const head = document.head || document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.innerHTML = `
       var for_firstName360 = "${verifyToken?.createdBy?.firstName}"
       var for_lastName360 = "${verifyToken?.createdBy?.lastName}" 
       `

      head.appendChild(script)
    }
  }, [verifyToken])

  const handleSubmit = async answers => {
    setSubmitLoading(true);
    try {
      const response = await submitTokenResponse({
        token,
        answers,
        surveyId: getIntFromString(verifyToken.survey.id),
        totalDuration: 1
      });

      // if (response) history.push(`${ROUTE.surveyResponseLink}${response.id}`);
      if (response) {
        removeSurveyCache(getIntFromString(verifyToken?.survey?.id))
        setVisible(true);
      }
      setSubmitLoading(false);
    } catch (e) {
      setSubmitLoading(false);
      message.error('Failed! try again.');
      throw new Error(e.message);
    }
  };
  
  const ResponseSubmit = () => (
    <>
      <Result
        status="success"
        title="Thank you for writing the survey!"
        subTitle=""
        extra={[
          <Button
            type="primary"
            onClick={() => {
              history.push(USER_ROUTE.loginAfterTokenSurvey);
            }}
            size="middle"
          >
            Login
          </Button>,
          <Button
            type="primary"
            ghost
            onClick={() => {
              history.push('/intro');
            }}
            size="middle"
          >
            Close
          </Button>
          // <Button type="primary" onClick={generateReport}>
          //   Get Your Report
          // </Button>
        ]}
      />
    </>
  );
  const EmailSent = () => (
    <>
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        {/* <MailSent>
          <CheckCircleFilled style={{ color: '#009c4a', fontSize: '25px' }} /> &nbsp; Email Sent! <br />
        </MailSent> */}
        <h3>
          This could take a minute.
          <br />
          Generated report will be sent to your registered email.
        </h3>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                setVisible(false);
              }}
              size="middle"
            >
              Close
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                message.success('Redirecting...');
                history.push(HOME_ROUTE.home);
              }}
              size="middle"
            >
              Close
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  return (
    <>
      {loadingVerifyToken && <Loading text={'Verifing token!!'} />}
      {!loadingVerifyToken && verifyToken ? (
        <Spin spinning={submitLoading} size="large">
          {
            showTermsAndCondition && verifyToken?.survey?.termsConditions && (
              <SurveyTermsAndCondition
                termsAndConditon={verifyToken?.survey?.termsConditions}
                accept={() => { setshowTermsAndCondition(false) }}
                back={() => history.push('/intro')}
              />
            )
          }
          <GenericQuizView onSubmit={handleSubmit} setshowTermsAndCondition={setshowTermsAndCondition} orgSurveyById={verifyToken.survey} {...rest} />
          <Modal
            closable={false}
            footer={null}
            centered
            visible={visible}
            onCancel={() => {
              history.push('/intro');
            }}
            bodyStyle={{ padding: '0px' }}
          >
            <ModalBody>
              {reportProgress === 0 && <ResponseSubmit />}
              {reportProgress === 1 && <EmailSent />}
            </ModalBody>
          </Modal>
        </Spin>
      ) : (
        !loadingVerifyToken && (
          <div className="HVCenter">
            <h2>Token is no longer valid!</h2>
          </div>
        )
      )}
    </>
  );
};

export default compose(withApollo, withVerifyToken, withSubmitTokenResponse)(UnSignedQuiz);
const ModalBody = styled.div`
  padding: 24px 48px;
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;

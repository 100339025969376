import React from "react";
import UserCompetecnyAssessmentHomeView from "../components/ca-user-detail-view";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { COMPETENCY_ASSESSMENT_QUERY_BY_EMPLOYEE, MEMBER_TEUNRE_QUERY, RATING_SCALES_LIST, ROLE_BY_ID } from "../graphql/ca-detail-queries.gql";
import { getEmployeeBase64StringFromInt, globalPermissionValidator } from "modules/look";
import { competency_assessment_permission } from "Permissions/competency-assessment";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { Spin } from "antd";
import moment from "moment-timezone";

const UserCompetecyAssessmentDetailContainer = (props) => {

    const { client, match, userPermission } = props
    const [page_loading, setPageLoading] = React.useState(false)
    const [assessment_details, setAssessmentDetails] = React.useState([])
    const [rating_list, setRatingList] = React.useState()
    const [role_detail, SetRoleDetail] = React.useState({})
    const [permission, SetPermission] = React.useState()
    const [dropdown_data_list, setDropdownDataList] = React.useState()
    const [employee,setEmployee] = React.useState()
    const [roleById,SetRoleById] = React.useState()
    const [role_tenure,SetTenure] = React.useState()

    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length && match?.params?.id) {
            let permission = globalPermissionValidator(competency_assessment_permission, userPermission)
            SetPermission(permission)
            if(permission?.required_permission){
                competecnyAssessmentDetail({})
                RoleTenurequery()
                RatingScaleList()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPermission,match?.params?.id])


    React.useEffect(() => {
        if (assessment_details?.length && Object.keys(role_detail)?.length && !dropdown_data_list) {
        
            let role_by_id = Object.values(role_detail)[0]

            let competencyassessment_set = assessment_details?.map(i => i?.competencyassessmentSet?.edges?.map(({ node }) => node))
            competencyassessment_set = [].concat.apply([], competencyassessment_set)
            let category = competencyassessment_set?.map(i => i?.mainCompetency || i?.competency?.competencyCategory)
            var setObj = new Set();
            var unique_category = category?.reduce((acc, item) => {
                if (!setObj.has(item?.id)) {
                    setObj.add(item?.id)
                    acc.push(item)
                }
                return acc;
            }, [])
            
            let role_related_competency =[
                ...role_by_id?.optionalSpecificCompetency?.edges?.map(({node})=>[node?.id,node?.competencyCategory?.id]),
                ...role_by_id?.requiredSpecificCompetency?.edges?.map(({node})=>[node?.id,node?.competencyCategory?.id]),
            ]
            if(role_by_id?.jobfamilySet?.edges?.length){
                let job_family_competency = []
                let job_family = role_by_id?.jobfamilySet?.edges?.map(({node})=>node)
                job_family.forEach(i=>{
                    let competency = i.genericCompetency?.edges?.map(({node})=>node)
                    if(competency?.length){
                        competency.forEach(comp=>{
                            job_family_competency.push([comp?.id,comp?.competencyCategory?.id])
                        })
                    }
                })
                role_related_competency = role_related_competency.concat(job_family_competency)
    
            }
            role_related_competency = [].concat.apply([],role_related_competency)
            let functional_assessment =unique_category.filter(i=>i?.competencyType === 'ROLE_SPECIFIC')
            let generic_assessment = unique_category.filter(i=>i?.competencyType !== 'ROLE_SPECIFIC')
            let only_functional_assessment = functional_assessment?.filter(i=>role_related_competency?.includes(i?.id ))
            let only_generic_assessment = generic_assessment?.filter(i=>role_related_competency?.includes(i?.id))
            let other_assessment = [...generic_assessment,...functional_assessment]?.filter(i=>!role_related_competency?.includes(i?.id))
           
            let list = [
                {title:"Functional competency",
                id:"functional_competency",
                type:'main',
                children:only_functional_assessment?.map(i=>{return{...i,type:'child',}})
                },
                {title:"Generic competency",
                id:"generic_competency",
                type:'main',
                children:only_generic_assessment?.map(i=>{return{...i,type:'child',}})
                },
                {title:"Other competency",
                id:"other_competency",
                type:'main',
                children:other_assessment?.map(i=>{return{...i,type:'child',}})
                }
            ]
            setDropdownDataList( list )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assessment_details, role_detail])

    let chart_filter = React.useRef({})
    const competecnyAssessmentDetail = async (value) => {
        let filter = {...chart_filter?.current,...value}
        chart_filter.current = filter
        setPageLoading(true)
        const { data } = await client.query({
            query: COMPETENCY_ASSESSMENT_QUERY_BY_EMPLOYEE,
            variables: { employee: getEmployeeBase64StringFromInt(match?.params?.id),...filter },
            fetchPolicy: 'network-only'
        });
        if (data?.assessments?.edges?.length) {
            setPageLoading(false)
            let list = data?.assessments?.edges?.map(({ node }) => node)
            let role_ids = list?.map(i => i?.role?.id)
            setAssessmentDetails(list)
            if(!employee){
                setEmployee(list[0]?.employee)
            }
            if(!role_detail[role_ids[0]]){
                getRoleDetail(role_ids[0])
            }
        
        }
        else {
            setAssessmentDetails([])
            setPageLoading(false)
        }
    }

    const RoleTenurequery = async()=>{
        const { data } = await client.query({
            query: MEMBER_TEUNRE_QUERY,
            variables: { toEmployee: getEmployeeBase64StringFromInt(match?.params?.id)},
            fetchPolicy: 'network-only'
        });
        if(data?.members){
            let tenure
            let updated_date =data?.members?.edges[0]?.node?.updatedAt
            let differance_year=moment().diff(moment(updated_date),'years').toPrecision(2)
            if(Number(differance_year)<1){
              let differance_month =moment().diff(moment(updated_date),'months')
              if(Number(differance_month)<1){
                let differency_days =moment().diff(moment(updated_date),'hours')
                tenure=Math.ceil(differency_days/24) +' Day'
              }
              else{
                tenure=differance_month+' Month'
              }
                
            }
            else{
                tenure=differance_year +' Year'
            }
            SetTenure(tenure)
        }
    }
    const getRoleDetail = async (id) => {
        const { data } = await client.query({
            query: ROLE_BY_ID,
            variables: { id },
            fetchPolicy: 'network-only'
        });
        if (data?.roleById) {
            SetRoleById(data?.roleById)
            SetRoleDetail(
                {
                    ...{
                        ...role_detail,
                        [data?.roleById?.id]: data?.roleById
                    }
                }
            )
        }
    }

    const RatingScaleList = async (filter) => {
        const { data } = await client.query({
            query: RATING_SCALES_LIST,
            variables: { ...filter },
            fetchPolicy: 'network-only'
        });
        if (data?.ratingScales) {
            let list = data?.ratingScales?.edges?.map(({ node }, index) => { return { ...node, sequence: index + 1 } })
            setRatingList(list)
        }
    }

    return (
        <>
            {(permission && permission?.required_permission) && (
                <UserCompetecnyAssessmentHomeView
                    {...props}
                    assessment_details={assessment_details}
                    page_loading={page_loading}
                    rating_list={rating_list}
                    permission={permission}
                    dropdown_data_list={dropdown_data_list}
                    competecnyAssessmentDetail={competecnyAssessmentDetail}
                    employee={employee}
                    roleById={roleById}
                    role_tenure={role_tenure}
                />)}
            {(permission && !permission?.required_permission) && (<NoPermissionView />)}
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    )
}

export default compose(withApollo)(UserCompetecyAssessmentDetailContainer)
export const home = "home";
export const dashboard = "dashboard"
export const my_team ="my-team"
export const profile ="profile"
const home_route = {
  userdashboard: `/${home}/${dashboard}`,
  my_team:`/${home}/${my_team}/:id`,
  employee_profile:`/${home}/${my_team}/${profile}/:id`,
}
export default home_route

import React from 'react';

import { compose } from '../../core';

import SideToolFeedbackView from '../components/sideToolFeedbackView';

// import { withToolById } from './ToolOperations';
import { withApollo } from '@apollo/client/react/hoc';
import { TOOL_BY_ID_OPTIMIZED } from 'modules/tool/graphql/ToolByidOptimized.gql';

const SideToolFedback = props => {
  
  const {client, tool_id}=props
  const [getToolById,setToolData]= React.useState()

  const [loading,setloading]= React.useState(true)
  
  const withToolById = async (value) => {
      let id = value;
      try{
        const { data } = await client.query({
          query: TOOL_BY_ID_OPTIMIZED,
          variables: { toolId: Number(id) },
          fetchPolicy: 'network-only'
      });
      if(data?.getToolById){   
        setToolData(data?.getToolById)
        setloading(false)
      }
      setloading(false)
      }catch(e){
        console.error(e)
      }
      
  }

  React.useEffect(()=>{
    if(tool_id){
        withToolById(tool_id)
    }
   
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tool_id])

  return (
    <>
    {getToolById!=null&&<SideToolFeedbackView {...props} getToolById={getToolById} loading={loading}/>}
    </>
  );
};

export default compose(withApollo)(SideToolFedback);

import React from 'react';
import { Select, Spin } from 'antd'
import styled from 'styled-components';
import TeamDiversityBarChart from './teamDiversityBarChart';
import { Diversity_question_ids, diversity_survey_id, diversity_questions_for_in } from '../../../../config';
import { getIntFromString, getQuestionBase64StringFromInt, getSurveyBase64StringFromInt } from 'modules/look';
import { dasboardIcons } from 'modules/home/dashboard-icons';

const TeamDiversityView = (props) => {
    const { choice_list, diversity_user_response, team_employees, chert_loading, getDiversityServeyReasponseList, getquestionChoiceList, currentFilter } = props
    const [filter, setFilter] = React.useState('Generation')
    const [graph_data_list, setGraphDataList] = React.useState()

    React.useEffect(() => {
        if (team_employees?.length > 0 && filter) {
            let employee_list = team_employees?.map(item => getIntFromString(item?.user?.id))
            if (filter === "Generation") {
                getquestionChoiceList(getQuestionBase64StringFromInt(Diversity_question_ids?.diversity_survey_generation_id))
                getDiversityServeyReasponseList({ survey: getSurveyBase64StringFromInt(diversity_survey_id), question: getQuestionBase64StringFromInt(Diversity_question_ids?.diversity_survey_generation_id), user_In: employee_list })
            }
            else if (filter === "Gender") {
                getquestionChoiceList(getQuestionBase64StringFromInt(diversity_questions_for_in?.gender))
                getDiversityServeyReasponseList({ survey: getSurveyBase64StringFromInt(diversity_survey_id), question: getQuestionBase64StringFromInt(diversity_questions_for_in?.gender), user_In: employee_list })
            }
            else if (filter === "Highest Degree") {
                getquestionChoiceList(getQuestionBase64StringFromInt(diversity_questions_for_in?.HighestDegree))
                getDiversityServeyReasponseList({ survey: getSurveyBase64StringFromInt(diversity_survey_id), question: getQuestionBase64StringFromInt(diversity_questions_for_in?.HighestDegree), user_In: employee_list })
            }
            else if (filter === "Race") {
                getquestionChoiceList(getQuestionBase64StringFromInt(Diversity_question_ids?.race))
                getDiversityServeyReasponseList({ survey: getSurveyBase64StringFromInt(diversity_survey_id), question: getQuestionBase64StringFromInt(Diversity_question_ids?.race), user_In: employee_list })
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team_employees, filter])

    const color_list = [
        "rgba(38, 196, 165, 1)",
        "rgba(84, 217, 159, 1)",
        "rgba(172, 224, 140, 1)",
        "rgba(232, 226, 132, 1)",
        "rgba(242, 182, 82, 1)",
    ]
    const generateRandomRGBA = () => {
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);
        const alpha = Math.random() * (1 - 0.5) + 0.5;
        return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    }
    const titleGenerator = (value, filter_text) => {
        if (filter_text === 'Generation') {
            if (value && value === "Baby Boomers (1946-1964)") {
                return "Baby boomers"
            }
            else if (value && value === "Generation X (1965-1980)") {
                return "Gen X"
            }
            else if (value && value === "Millennials (1981 - 1996)") {
                return "Millennials"
            }
            else if (value && value === "Generation Y (1997 - 2012)") {
                return "Gen Y"
            }
            else if (value && value === "Generation Z (2013 - 2025)") {
                return "Gen Z"
            }
        }
        else {
            return value
        }
    }

    const PercentageCalculation = (response, q_id) => {
        let list = response?.filter(item => item?.node?.answerSet?.edges[0]?.node?.answer === q_id)
        return team_employees?.length > 0 ? Math.round((list.length / team_employees?.length) * 100) : 0
    }

    React.useEffect(() => {
        if (diversity_user_response) {
            let random_color = generateRandomRGBA()
            let data_set = choice_list?.map((item, index) => {
                return {
                    title: filter === "Generation" ? titleGenerator(item?.value, filter) : item?.value,
                    value: team_employees?.length > 0 ? PercentageCalculation(diversity_user_response, item?.value) : 0,
                    color: index < 5 ? color_list[index] : random_color,
                    detail: item?.value
                }
            })
            setGraphDataList(data_set)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diversity_user_response])

    return (
        <div className='user-dashboard-okr-performance-container'>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', gap: "1em", alignItems: 'center' }}>
                <h4 className='user-board-section-heading'>Team Diversity</h4>
                <CustomSelect dropdownClassName="content-dropdown" onChange={(e) => { setFilter(e); currentFilter(e) }} defaultValue={filter}>
                    <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontFamily: "Poppins", fontWeight: 500 }} key='Generation' value='Generation' >Generation</Select.Option>
                    <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontFamily: "Poppins", fontWeight: 500 }} key='Gender' value='Gender' >Gender</Select.Option>
                    <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontFamily: "Poppins", fontWeight: 500 }} key='Highest Degree' value='Highest Degree' >Highest Degree</Select.Option>
                    <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontFamily: "Poppins", fontWeight: 500 }} key='Race' value='Race' >Race</Select.Option>
                </CustomSelect>
            </div>
            <Spin spinning={chert_loading}>
                {!chert_loading&&team_employees?.length > 0?
                    <TeamDiversityBarChart filter={filter} graph_data_list={graph_data_list} {...props} /> :
                    <div className='no-performance-container' style={{ border: "none" }}>
                        {!chert_loading&&<><img src={dasboardIcons?.diversity_no_data} alt="diversity_no_data" />
                        <h4 className='baner-tip-title'>Here you will the team diversity</h4></>}
                    </div>
                }

            </Spin>

        </div>
    )
}

export default TeamDiversityView

const CustomSelect = styled(Select)`
.ant-select-selector{
    border: 1px solid #AABAC6 !impoortant;
    color:  #000;
    font-weight: 500;
    font-family: Poppins;
    font-size: .85em;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: center;
    border-radius:.75em !important;
    width:150px !important;
}
`;
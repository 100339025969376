export const mapp_support ="support"
export const create_ticket ="create_ticket"
export const my_ticket ='my_ticket'
export const ticket_detail ='ticket_detail'
const support_route ={
    create_ticket:`/${mapp_support}/${create_ticket}`,
    my_ticket:`/${mapp_support}/${my_ticket}`,
    details:`/${mapp_support}/${ticket_detail}/:id`,
    thank_you:`/${mapp_support}/thank-you`
}
export default support_route
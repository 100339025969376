
import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import ComparisonRequestSent from "../Components/ComparisonRequestSendView";
import { COMPARISON_REQ_SENT } from "../graphql/ComparisonReqSent.gql";
import { withDeleteRequest } from "./lookV2Operation";
const ComparisonRequestSentContainer = (props) =>{
    const { client,survey,me,tab } = props
    const [request_list_info,setRequsetListInfo] = React.useState([])
    React.useEffect(()=>{
        if(survey && me && tab==='outgoing'){
            getRequestList({survey:survey?.id,sender:me?.user?.id})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[survey,me,tab])

    const getRequestList = async (filter)=>{
        const { data } = await client.query({
            query:COMPARISON_REQ_SENT,
            fetchPolicy: 'network-only',
            variables: filter
          });
          if(data?.allComparisonRequestFormUser){
            setRequsetListInfo([].concat([{...data?.allComparisonRequestFormUser,pagination:filter?.pagination}]))
          }
    }

    return (
        <>
            <ComparisonRequestSent {...props} getRequestList={(e)=>{getRequestList(e)}} request_list_info={request_list_info} />
        </>
    )
}

export default compose(withApollo,withDeleteRequest)(ComparisonRequestSentContainer);

// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';
import { message } from "antd"
import { CREATE_BADGE_FAMILY } from '../graphql/CreateBadgeFamily.gql';
import { UPDATE_BADGE_FAMILY } from '../graphql/UpdateBadgeFamily.gql';
import { CREATE_BADGE } from '../graphql/CreateBadge.gql';
import { UPDATE_BADGE } from '../graphql/UpdateBadge.gql';
import { GET_BADGE_BY_ID } from '../graphql/badgebyId.gql';
import { getBadgeStringFromInt} from 'modules/look';
import { CREATE_BADGE_LEVEL } from '../graphql/CreateBadgelevel.gql';
import { DELETE_BADGE } from '../graphql/delete-badge.gql';
import { DELETE_BADGE_FAMILY } from '../graphql/delete-badge-family.gql';

export const withCreateBadgeFamily = Component =>
graphql(CREATE_BADGE_FAMILY, {
  props: ({ mutate, history }) => ({
    createBadgeFamily: async values => {
      try {
        const {
          data: { createBadgeFamily }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        return createBadgeFamily.badgeFamily;
      } catch (e) {
        message.destroy();
        message.error("Failed to create Badge family");
        console.error(e);
      }
    }
  })
})(Component);

export const withUpdateBadgeFamily = Component =>
graphql(UPDATE_BADGE_FAMILY, {
  props: ({ mutate, history }) => ({
    updateBadgeFamily: async values => {
      try {
        const {
          data: { updateBadgeFamily }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        // message.success('Submitted response!!');
        return updateBadgeFamily.badgeFamily;
      } catch (e) {
        message.destroy();
        message.error("Couldn't perform the action");
        console.error(e);
      }
    }
  })
})(Component);

export const withCreateBadge = Component =>
graphql(CREATE_BADGE, {
  props: ({ mutate, history }) => ({
    createBadge: async values => {
      try {
        const {
          data: { createBadge }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        // message.success('Submitted response!!');
        return createBadge.badge;
      } catch (e) {
        message.destroy();
        message.error("Couldn't perform the action");
        console.error(e);
      }
    }
  })
})(Component);

export const withUpdateBadge = Component =>
graphql(UPDATE_BADGE, {
  props: ({ mutate, history }) => ({
    updateBadge: async values => {
      try {
        const {
          data: { updateBadge }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        // message.success('Submitted response!!');
        return updateBadge.badge;
      } catch (e) {
        message.destroy();
        message.error("Couldn't perform the action");
        console.error(e);
      }
    }
  })
})(Component);

export const withBadgeById = Component =>
  graphql(GET_BADGE_BY_ID, {
    options: props => {
     

      return {
        variables: {id: getBadgeStringFromInt( props.match.params.badgeid)},
        fetchPolicy: 'network-only'
      };
    },
    props({ data: { loading, error, badge, subscribeToMore, updateQuery, refetch } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, badge, subscribeToMore, updateQuery, refetch };
    }
  })(Component);

  export const withCreateBadgeLevel = Component =>
graphql(CREATE_BADGE_LEVEL, {
  props: ({ mutate, history }) => ({
    createBadgeLevel: async values => {
      try {
        const {
          data: { createBadgeLevel }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        message.success('Submitted response!!');
        return createBadgeLevel.badgeLevel;
      } catch (e) {
        message.destroy();
        message.error("Couldn't perform the action");
        console.error(e);
      }
    }
  })
})(Component);


export const withDeleteBadge = Component =>
graphql(DELETE_BADGE, {
  props: ({ mutate, history }) => ({
    deleteBadge: async values => {
      try {
        const {
          data: { deleteBadge }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        message.success('Badge deleted');
        return deleteBadge.badge;
      } catch (e) {
        message.destroy();
        const subString = e.toString().includes('because they are referenced through protected foreign keys');
        if(subString){
          message.error("Oops!! Could not perform the action. The kudos that you are trying to delete is already in use.");
        }else{
          message.error("Couldn't perform the action");
        }
        console.error(e)
      }
    }
  })
})(Component);

export const withDeleteBadgeFamily = Component =>
graphql(DELETE_BADGE_FAMILY, {
  props: ({ mutate, history }) => ({
    deleteBadgeFamily: async values => {
      try {
        const {
          data: { deleteBadgeFamily }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();
        message.success('Badge family deleted');
        return deleteBadgeFamily.badgeFamily;
      } catch (e) {
        message.destroy();
        message.error("Couldn't perform the action");
        console.error(e);
      }
    }
  })
})(Component);
// @ts-nocheck
import { message } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

// import surveyResolvers from '../resolvers';
import { PUBLIC_SURVEY_BY_ID_QUERY } from '../graphql/DiversityProfileSurveyQuestions.gql';
import { CREATE_RESPONSE_MUTATION } from '../graphql/CreateResponseMutation.gql'
import { getSurveyBase64StringFromInt } from 'modules/look';
import { SURVEY_BY_ID_NEW_OPTIMIZED } from 'modules/survey/graphql/surveyByIdnewQueryOptimized.gql';

export const withPublicSurveyById = Component =>
    graphql(PUBLIC_SURVEY_BY_ID_QUERY, {
        options: props => {
            let id = '0';
            if (props.match) {
                id = props.match.params.id;
            } else if (props.navigation) {
                id = props.navigation.state.params.id;
            }
            return {
                variables: { id: Number(id) }
            };
        },
        props({ data: { loading, error, publicSurveyById, subscribeToMore, updateQuery } }) {
            // if (error) {
            //   throw new Error(error.message);
            // }
            return { loading, error, publicSurveyById, subscribeToMore, updateQuery };
        }
})(Component);

    export const withSurveyByIdNewOptimized = Component =>
    graphql( SURVEY_BY_ID_NEW_OPTIMIZED, {
      options: props => {
        let id = '0';
        if ( props.match ) {
          id = props.match.params.id;
        } else if ( props.navigation ) {
          id = props.navigation.state.params.id;
        }
        return {
          variables: { id: getSurveyBase64StringFromInt( id ) }
        };
      },
      props( { data: { loading, error, SurveyById, subscribeToMore, updateQuery } } ) {
        // if (error) {
        //   throw new Error(error.message);
        // }
        return { loading, error, SurveyById, subscribeToMore, updateQuery };
      }
    } )( Component );
export const withCreateResponse = Component =>
    graphql(CREATE_RESPONSE_MUTATION, {
        props: ({ mutate, history }) => ({
            createResponse: async values => {
                try {
                    const {
                        data: { createResponse }
                    } = await mutate({
                        variables: {
                            ...values
                        }
                    });

                    message.destroy();
                    message.success('Submitted response!!');
                    return createResponse.response;
                } catch (e) {
                    message.destroy();
                    message.error("Couldn't perform the action");
                    console.error(e);
                }
            }
        })
    })(Component);
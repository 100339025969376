import React from 'react';
import { Button, Row } from 'antd';

const ButtonWithIcon = (props) => {
    const { label, onClick, icon, background, disabled,borderRadius,borderColor } = props
    return (
        <Row>
            <Button className='button-with-icon'
            disabled = {disabled}
                style={{ background: background,borderRadius:borderRadius,borderColor:borderColor }}
                onClick={() => onClick(true)}>
                <img src={icon} style={{ paddingRight: '10px', height: '25px', width: '25px' }} alt='icon'/>
                {label}
            </Button>
        </Row>

    )
}
export default ButtonWithIcon
// @ts-nocheck
import React from 'react';
import '../Css/callhandler.css'
import answecall from '../../assets/answercall.png'
import rejectcall from '../../assets/rejectcall.png'
import { Spin,message,Tooltip } from "antd";
const CallingIncomingState = props => {
    const { callstatus, video_turned_on, audio_turned_on, setVideoStaus, setAudioStaus, system_icons, cancelCall, answerCall,call_soultips,media_availability } = props

    const changeAudio=(value)=>{
        if(media_availability?.audio){
            setAudioStaus(value)
        }else{
            message.error('do not have access to microphone')
        }
    }

    const changeVideo=(value)=>{
        if(media_availability?.video){
            setVideoStaus(value)
        }else{
            message.error('do not have access to camera')
            
        }
    }

    return (
        <div className='call-state-container'>
            <div style={{width:"100%",height:"10px"}}></div>
            <div>
                <div className='dot-flashing-container'>
                    <h4 className='handler-info' style={{ fontSize: "1.2em" }}>{callstatus}</h4>
                    <div className="dot-flashing"></div>
                </div>
                {
                    callstatus === 'Connecting' ?
                        <Spin spinning={true}></Spin> :
                        <div className='call-accept-reject'>
                            <div className='handler-icons' onClick={() => { answerCall() }}>
                                <img src={answecall} style={{ width: "40px", height: "40px", cursor: "pointer" }} alt="" />
                            </div>
                            <div className='decline-icons' onClick={() => { cancelCall() }}>
                                <img src={rejectcall} style={{ width: "40px", height: "40px", cursor: "pointer" }} alt="" />
                            </div>
                        </div>
                }


            </div>

            <div>
                <div className='call-system-icon-container'>
                    <Tooltip title={!media_availability?.audio?'Do not have access to microphone':''}>
                    <img src={audio_turned_on ? system_icons?.mic_on : system_icons?.mic_off} onClick={() => { changeAudio(!audio_turned_on) }} className='call-system-control-icon' alt="" srcset="" />
                    </Tooltip>
                    <Tooltip title={!media_availability?.video?'Do not have access to camera':''}>
                    <img src={video_turned_on ? system_icons?.cam_on : system_icons?.cam_off} onClick={() => { changeVideo(!video_turned_on) }} className='call-system-control-icon' alt="" srcset="" />
                    </Tooltip>
                </div>
                {
                    call_soultips && (
                        <h4 className='handler-info clamp-to-3line'>{call_soultips}</h4>
                    )
                }
            </div>


        </div>
    )

}

export default CallingIncomingState
import ROUTE from "./route";
import UserDasboardContainer from "./containers/userDashboard";
import TeamsDashboardContainer from "./teamsDashboard/containers/teamsDashboardContainer";
import ProfileDetailContainer from "./containers/ProfileDetailContainer";

export default [
  {
    name:"userDasboard",
    path:ROUTE.userdashboard,
    component:UserDasboardContainer,
    protect:true,
    exact:true
  },
  {
    name:"teamsDasboard",
    path:ROUTE.my_team,
    component:TeamsDashboardContainer,
    protect:true,
    exact:true
  },
  {
    name:"Profile Detail",
    path:ROUTE.employee_profile,
    component:ProfileDetailContainer,
    protect:true,
    exact:true
  },
];

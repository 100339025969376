import React from 'react';
import { Form, Input} from 'antd';
import { Tooltip } from 'antd';
import '../css/survey-look.css'
import {EyeInvisibleOutlined,EyeOutlined} from '@ant-design/icons'
const SurveyTextField = props => {
  const { type, value, onChange, disabled, onBlur,margin,placeholder,widthscale ,className,maegin,required,max_length,...rest } = props;
  const FormItem = Form.Item;
  const { TextArea } = Input;


  return (
    <div style={{ marginBottom: margin?margin:'5vh', width:widthscale?widthscale:'100%' }}>
      <FormItem style={{marginBottom:margin?margin:'24px'}} required={props?.required} {...rest}>
        {type === 'textarea' ? (
          <TextArea className={className?className:"generic-survey-input-filed"}  style={{resize:"none"}}defaultValue={value} rows={6} onChange={onChange} disabled={disabled} />
        ) : type === 'number' ? (
          <NumericInput defaultValue={value} onChange={onChange} disabled={disabled} max_length={max_length} />
        ) :type ==='password'?
        <Input.Password 
        className={className?className:"generic-survey-input-filed"} 
        iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
        defaultValue={value} placeholder={placeholder||''} onChange={onChange} onBlur={onBlur} disabled={disabled}/>:
        type ==='url'?
        <Input type='url' className={className?className:"generic-survey-input-filed"} defaultValue={value} placeholder={placeholder||''} onChange={onChange} onBlur={onBlur} disabled={disabled} />:
         (
          <Input className={className?className:"generic-survey-input-filed"} defaultValue={value} placeholder={placeholder||''} onChange={onChange} onBlur={onBlur} disabled={disabled} />
        )}
      </FormItem>
    </div>
  );
};

export default SurveyTextField;

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e?.target;
    const reg = /^[0-9]+$/;
    if(this.props.max_length && value?.length>this.props.max_length){
      return
    }
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      //do not change this conditions
      this.props.onChange(e);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (!(typeof value === 'number' && isFinite(value))) {
      if (value?.charAt(value?.length - 1) === '.' || value === '-') {
        valueTemp = value?.slice(0, -1);
      }
      onChange(valueTemp?.replace(/0*(\d+)/, '$1'));
      if (onBlur) {
        onBlur();
      }
    }
  };

  render() {
    const { value,className } = this.props;
    const reg = /^[0-9]+$/;
    const title = value ? (
      <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    ) : (
      'Input a number'
    );
    return (
      <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
        <Input className={className?className:"generic-survey-input-filed"} {...this.props} value={reg.test(value)?value:''}  onChange={this.onChange} onBlur={this.onBlur}  min={0} maxLength={25} 
         pattern="[0-9]*"
        />
      </Tooltip>
    );
  }
}

function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

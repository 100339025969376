// @ts-nocheck

import React from 'react'
import DashboardHeader from '../components/DashboardHeader';
import { PROGRESS } from '../graphql/okrDahboardQueries.gql';
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc';
import { getOrganisationRoles, getTeamRoles, getVerticalRoles } from './objective-keyresult-operations';
import { getIntFromString } from 'modules/look';


const DashboardHeaderContainer = (props) => {

  const { client, teamRoles, verticalRoles, me, organisationRoles,dateRangeTofilter } = props

  const [individualOverallProgress, setIndividualOverallProgress] = React.useState();
  const [teams, setTeams] = React.useState();
  const [verticals, setVerticals] = React.useState();
  const [organisations, setOrganisation] = React.useState();

  // const dateRangeTofilter = [startOfQuarter, endOfQuarter]

  let currentTabRef = React.useRef({
    Team:0,
    Vertical:0,
    Organization:0
})  


  React.useEffect(()=>{
    if(dateRangeTofilter?.length===2){
      if(teams?.length && currentTabRef['current']){
        let employees = teams[currentTabRef['current']?.Team]?.managers?.edges?.map((node) =>
        getIntFromString(node?.node?.id)
        )
        let sets =teams?.map(item=>{
          return {
            id:item?.id,
            managers:item?.managers,
            name:item?.name
          }
        })
        // setTeams(sets)
        OkrProgress(employees, 'team',currentTabRef['current']?.Team,sets)
      }
      if(verticals?.length && currentTabRef['current']){
        let employees = verticals[currentTabRef['current']?.Vertical]?.heads?.edges?.map((node) =>
        getIntFromString(node?.node?.id)
        )
        
        let sets =verticals?.map(item=>{
          return {
            id:item?.id,
            heads:item?.heads,
            name:item?.name
          }
        })
        // setVerticals(sets)
        OkrProgress(employees, 'vertical', currentTabRef['current']?.Vertical, sets)
      }
      if(organisations?.length && currentTabRef['current']){
        let employees = [getIntFromString(organisations[currentTabRef['current']?.Organization]?.ceo?.id)]
        let sets =organisations?.map(item=>{
          return {
            id:item?.id,
            ceo:item?.ceo,
            name:item?.name
          }
        })
        // setOrganisation(sets)
        OkrProgress(employees, 'org', currentTabRef['current']?.Organization, sets)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[dateRangeTofilter])



  React.useEffect(() => {
    if (teamRoles) {
      let managerSet1 = teamRoles?.employee?.teamManagerEmployee?.edges?.map((node) => node?.node)
      let managerSet2 = teamRoles?.employee?.teamMembersEmployee?.edges?.map((node) => node?.node)
      let allManagers = managerSet1?.concat(managerSet2);
      let uniqueTeams = allManagers.filter((value, index, self) =>
        index === self.findIndex((team) => (
          getIntFromString(team.id) === getIntFromString(value.id)
        )
        )
      )

      let employees = uniqueTeams[0]?.managers?.edges?.map((node) =>
        getIntFromString(node?.node?.id)
      )
  
      if(uniqueTeams?.length && !teams){
        OkrProgress(employees, 'team',0,uniqueTeams)
      }else if(!uniqueTeams?.length && !teams){
        setTeams([])
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamRoles])



  React.useEffect(() => {
    if (me && dateRangeTofilter) {


      let employees = [getIntFromString(me?.employee?.id)]


      OkrProgress(employees, 'individual')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me,dateRangeTofilter])


  React.useEffect(() => {
   
    if (organisationRoles) {
      
      let organisationsData = organisationRoles?.employee?.organizationSet?.edges?.map((node) => node?.node);
      let employees = [getIntFromString(organisationsData[0]?.ceo?.id)]
      if(organisationsData?.length && !organisations){
        OkrProgress(employees, 'org',0,[organisationsData[0]])
      }else if(!organisationsData?.length && !organisations){
        setOrganisation([])
      }
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationRoles])




  React.useEffect(() => {

    if (verticalRoles) {

      let verticalRolesSet1 = verticalRoles?.employee?.verticalHeadEmployee?.edges?.map((node) => node?.node)
      let verticalRolesSet2 = verticalRoles?.employee?.verticalMembersEmployee?.edges?.map((node) => node?.node)
      let allVerticals = verticalRolesSet1?.concat(verticalRolesSet2)

      let uniqueVerticals = allVerticals.filter((value, index, self) =>
        index === self.findIndex((vertical) => (
          getIntFromString(vertical.id) === getIntFromString(value.id)
        )
        )
      )

      // setVerticals(uniqueVerticals)
      let employees = uniqueVerticals[0]?.heads?.edges?.map((node) =>
        getIntFromString(node?.node?.id)
      )
      // setEmployeeSet(employees)
      if(uniqueVerticals?.length && !verticals){
        OkrProgress(employees, 'vertical',0,uniqueVerticals)
      }else if(!uniqueVerticals?.length && !verticals){
        setVerticals([])
      }
      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verticalRoles])




  const OkrProgress = async (employees, type,index,set) => {
    const { data } = await client.query({
      query: PROGRESS,
      variables: { savedOkrType: "OBJECTIVE", employee_In: employees,okr_Duedate_Range: dateRangeTofilter },
      // fetchPolicy: 'network-only'
    });

    if (data?.progress) {

      let progressOfOkrs = data?.progress?.edges?.map((node) =>
        node?.node?.progress
  
      )?.reduce((total, currentValue) => total = total + currentValue, 0);

      let overallProgress = progressOfOkrs / (data?.progress?.edges?.length)
      overallProgress = (!overallProgress || isNaN(overallProgress)||overallProgress===Infinity)?'0':Math.round(overallProgress).toString()

      switch (type) {
        case 'team': {
          set[index]['progress']=overallProgress
          setTeams([].concat(set))
          break
        }

        case 'vertical': {
          set[index]['progress']=overallProgress
          setVerticals([].concat(set))
          break
        }

        case 'individual': {
          setIndividualOverallProgress(overallProgress)
          break
        }
        case 'org': {
          set[index]['progress']=overallProgress
          setOrganisation([].concat(set))
          break
        }
        default:break



      }

    }
  }



    return (
      <div>
        <DashboardHeader {...props}
          teams={teams}
          organisationRoles = {organisationRoles}
          verticals={verticals}
          OkrProgress = {(employees,type,index,set) =>OkrProgress(employees,type,index,set)}
          individualOverallProgress={individualOverallProgress}
          organisations={organisations}
          currentTabRef={(e)=>{currentTabRef['current']=e}}
        />
      </div>
    )
  }



  export default compose(withApollo, getTeamRoles, getVerticalRoles, getOrganisationRoles)(DashboardHeaderContainer)
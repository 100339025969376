import React from 'react';
import { Popover, Form, Row } from 'antd';
import popover_edit from '../../assets/popover-edit.svg'
import pop_over_submit_icon from '../../assets/pop_over_submit_icon.svg'
import styled from 'styled-components';
import '../look-v2-styles/ProgressTrackerStyles.css'

const CustomStep = (props) => {
  const { stepData, popoverupdate } = props
  const [popedit, setpopedit] = React.useState({})
  const [rerender, setrerender] = React.useState(false)
  const [greenBarWidth, setGreenBarWidth] = React.useState();
  const [grayBarWidth, setGrayBarWidth] = React.useState();
  const [popvisible, setPopVisible] = React.useState(false);
  const [popOverData, setPopOverData] = React.useState();
  const [popIndex, setPopIndex] = React.useState();
  const [form] = Form.useForm();
  const FormItem = Form.Item;

  React.useEffect(() => {
    if (stepData && stepData?.length) {
      let greenBar = stepData.filter((element) => element?.id);
      
      let greenWidth = ((900 * greenBar[greenBar?.length - 1]?.progress) / 100)
       
      setGreenBarWidth(greenWidth)
      if (isNaN(greenWidth)) {
        setGrayBarWidth(900)
      } else {
          
        // let grayWidth = (((900 * grayBar[grayBar?.length - 1]?.progress) / 100) - greenWidth)
        let grayWidth = (900 - greenWidth)
        
        setGrayBarWidth(grayWidth)
      }

    }
  }, [stepData])

  const popeditupdate = (id) => {
    let data = popedit
    data[id] = data[id] ? false : true
    setpopedit(data)
    setrerender(!rerender)
  }

  const renderPopover = (index) => {
    return (
      // <Popover visible={popvisible}>
      

      <div style={{ height: "100%", width: "100%" }}  className="pop-over-content"  >
        {
          popOverData && (index === popIndex) && popOverData?.id &&
          <div>
            <div className='row space-between' style={{paddingTop:'7px',paddingLeft:'16px',paddingRight:'16px'}}>
              <div style={{ width: "2px" }}></div>
              <span className='pop-over-title' >{popOverData?.description}</span>
              {
                !popedit[popOverData?.id] ?
                  <img style={{ cursor: "pointer", width: "12.5px", height: '12.5px' }} onClick={(e) => { popeditupdate(popOverData?.id) }} src={popover_edit} alt="" />
                  :
                  <img style={{ cursor: "pointer", width: "12.5px", height: '12.5px' }} onClick={(e) => { popeditupdate(popOverData?.id); popoverupdate({ ...form.getFieldsValue(), ...popOverData }) }} src={pop_over_submit_icon} alt="" />
              }

            </div>
            <Form

              form={form}
            >
              <FormItem name={popOverData?.id}>
                <textarea name={popOverData?.id} style={{textAlign:popOverData?.comment ?  'auto' : 'center',
                paddingTop:popOverData?.comment ? 'auto' : '30px' 
                 
              }} disabled={!popedit[popOverData?.id]} className={!popedit[popOverData?.id] ? 'disable-textarea textarea-scroll' : 'enable-textarea textarea-scroll'} >
                  {popOverData?.comment ? popOverData?.comment : "No Description Provided"}
                  </textarea>
              </FormItem>
            </Form>

          </div>
        }
      </div>


      // </Popover>
    )
  }



  
  const checkIndex = (step,index) =>{
     if(step?.id){
        setPopIndex(index)
     }else{
      setPopIndex(undefined)
     }
  }

  return (
    <>


     {
      stepData && stepData.length > 1 &&
      <ProgressTrackerContainer style={{ marginLeft: '25px' }}>
      <div style={{ position: 'absolute', zIndex: 10 }}  id='pop-modal'>
        {
          stepData &&  stepData?.map((step, index) =>
            <div style={{ display: 'flex' }}>
              
              <div  key={index} style={{ marginLeft: `${((900 * step?.progress) / 100) + 80}px`, position: 'absolute', top: '1px' }}
                onClick={(e) => { setPopOverData(step);checkIndex(step,index)}} className='custom-popover-wrapper'
              >
                  <div>
                <Popover  visible={popvisible && (index === popIndex)} id='popover-element' content={renderPopover(index)}>
                 {
                  // </div>
                  <div id={`tracekr-spheres-${index}`}  style={{
                    background: '#FFFFFF', width: '15px', height: '15px', borderRadius: '50%',
                    boxShadow: '0 0 0 10px #255F63'
                  }}
                  onClick={(e)=>{setPopVisible(!popvisible);}}
                  >
                  </div>
                 } 
                </Popover>
                </div>

              </div>
              <StepContainer style={{ marginTop: '20px', lineHeight: '21px', marginLeft: `${((900 * step?.progress) / 100) + 80}px` }}
                positioning={index % 2 !== 0 ? 'absolute' : 'absolute'}
                top={index % 2 !== 0 ? '-80px' : '15px'}
                left={index % 2 !== 0 ? '-42px' : '-40px'}
                display={index % 2 !== 0 ? 'flex' : 'block'}
                // direction = {index %2 !== 0 ? 'column-reverse' : 'column'}
                direction={index % 2 !== 0 ? 'column-reverse' : 'column'}
                respLeft={index % 2 !== 0 ? '-120px' : '30px'}
                respTop={index % 2 !== 0 ? 'auto' : '-18px'}
                respAlignItems={index % 2 !== 0 ? 'end' : 'start'}
                height={step?.width}

              >
                <div align='center' style={{ color: '#000000', fontWeight: '600', fontFamily: 'Poppins', fontSize: '16px' }}>{step?.title}</div>
                <div align='center' style={{ width: '100px', color: '#255F63', fontWeight: '500', fontFamily: 'Roboto' }}>{step?.description}</div>
              </StepContainer>
            </div>
          )
        }

      </div>
      {/* Green and gray bars */}
      {
        (stepData &&  isNaN(grayBarWidth)) ?
        <div style={{
          display: 'flex', paddingRight: '80px',borderRadius:'50px',
          background: 'linear-gradient(270.07deg, #9CBB42 18.24%, #EAB325 87.83%)',height:'15px'
        }}>
          <div style={{ width: `${grayBarWidth}px`, height: '18px' }}>
          </div>
        </div>
          :
          <Row wrap={false} style={{height:'15px'}} >
            <div className = {grayBarWidth === 0 ? 'full-width-progress' : 'incomplete-progress-width'} >
              <div style={{ width: `${greenBarWidth}px`, height: '18px' }} >
              </div>
            </div>
            {
              grayBarWidth !== 0 &&
              <div style={{
              display: 'flex', paddingRight: '80px', borderTopRightRadius: '50px', borderBottomRightRadius: '50px',
              background: '#F0F0F0'
            }}>
              <div style={{ width: `${grayBarWidth}px`, height: '18px' }}>
              </div>
            </div>
            }
            
          </Row>
      }

      {(popvisible ) ? (<div className='popover-progress-tracker' onClick={() => {  setPopVisible(!popvisible);setPopOverData(undefined);setPopIndex(undefined) }} />) : null}
    </ProgressTrackerContainer>
     
    
     }
    
    </>
   

  )

}

export default CustomStep



const StepContainer = styled.div`



  position: ${props => props.positioning};
  top: ${props => props.top};
  left: ${props => props.left};
  display: ${props => props.display};
  flex-direction: ${props => props.direction};
  left:${props => props.left};

`;


const ProgressTrackerContainer = styled.div`

margin-left:25px;

@media screen and (max-width:420px){
  
  margin-left:0px;

}


.ant-popover-inner-content{
  padding:0px !important;
}


`;
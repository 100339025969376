import React, { useState } from 'react';
import { Input, Button, Select, Spin, Pagination } from 'antd';
import '../css/MyTickets.css';
import support_route from '../route';
import KudosCustomPickerView from 'modules/kudos-user/component/kudosCustomPickerView';
import moment from 'moment-timezone';
import sms_active from '../../assets/sms-active.svg'
import sms_inactive from '../../assets/sms-inactive.svg'
import sms_star_active from '../../assets/sms-star-active.svg'
import sms_star_inactive from '../../assets/sms-star-inactive.svg'
import { displayUtcToUserTimezone, getIntFromString } from 'modules/look';

const tab_lists = [
    {
        key: "all_tickets",
        label: 'Open',
        active_icon: sms_active,
        deactive_icon: sms_inactive,
    },
    {
        key: 'closed',
        label: 'Closed',
        active_icon: sms_star_active,
        deactive_icon: sms_star_inactive,
    }
];
const date_filter = [
    {
        key: "this_month",
        value: "this_month",
        label: "This Month"
    },
    {
        key: "this_week",
        value: "this_week",
        label: "This Week"
    },
    {
        key: "quarter",
        value: "quarter",
        label: "Date",
    }
]

const MyTickets = (props) => {
    const { navigateRoute, allTickets, getMyTickets, ticket_loading, permission, pagination_info } = props;
    const [searchQuery, setSearchQuery] = useState('');
    const [open_date_picker, setOpenDatePicker] = React.useState(false)
    const [current_page, setCurrentPage] = React.useState(1)
    const [selected_date,setSelectedDate] = useState()
    const [range_filter, setRangeFilter] = useState({})
    const [current_tab, setCurrentTab] = React.useState(tab_lists[0])

    React.useEffect(() => {
        MyTicketFilterHandler()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_tab])

    const MyTicketFilterHandler =(filter)=>{
        const baseFilter = current_tab?.key === 'all_tickets'
        ? { status_In: ["Assigned", "New", "Work in Progress"] }
        : { status: 'Resolved' };
        getMyTickets({ ...baseFilter, ...range_filter, ...filter });
    }

    const onSearchChange = (e) => {
        setSearchQuery(e.target.value);
        MyTicketFilterHandler({ title_Icontains: e.target.value})
    };

    const handleDateFilter = (value) => {
        setSelectedDate(value);
        setOpenDatePicker(value === "quarter"); 
        if (value === "this_month" || value === "this_week") {
            const startDate = moment().startOf(value.replace('this_', '')).utc();
            const endDate = moment().endOf(value.replace('this_', '')).utc();
            MyTicketFilterHandler({createdAt_Range: [startDate, endDate],title_Icontains:searchQuery})
            setRangeFilter({ createdAt_Range: [startDate, endDate] })
        }
    };

    const onCloseDate = (key) => {
        setTimeout(() => {
            setOpenDatePicker(key)
        }, 350)
    }

    const onFilterCustomDate = (date) => {
        setOpenDatePicker(false)
        setRangeFilter({ createdAt_Range: [date[0], date[1]] })
        MyTicketFilterHandler({ createdAt_Range: [date[0], date[1]],title_Icontains:searchQuery })
        
    }

    const getPriorityColor = (priority) => {
        switch (priority) {
            case 1:
                return "#FA8A81";
            case 2:
                return "#AA97E1";
            case 3:
                return "#FFCB3D";
            default:
                return "#D9D9D9";
        }
    };

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            return <a>Previous</a>;
        }
        if (type === 'next') {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            return <a>Next</a>;
        }
        return originalElement;
    };
    
    const onPagination = (value) => {
        setCurrentPage(value)
        MyTicketFilterHandler({ offset: (value - 1) * 10,title_Icontains:searchQuery})
    }

    const clearDateFilter = ()=>{
        setSelectedDate(null)
        setRangeFilter(null)
        MyTicketFilterHandler()
    }

    
    return (
        <div className="tickets-list">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em", paddingBottom: "20px" }}>
                <h2 className='ticket-system-main-head'>Tickets</h2>
                {permission?.reqiredPermissions && <Button className='ticket-support-submit-btn' style={{ height: "2.5em" }} onClick={() => navigateRoute(support_route?.create_ticket)}>Create Ticket</Button>}
            </div>
            <div className='ticket-list-container'>
                <div className="filters">
                    <div className='ticketFilterDropdowns'>

                        <Select
                            value={selected_date}
                            onSelect={handleDateFilter}
                            placeholder="Select Duration"
                            className='ticket-form-select'
                            allowClear={selected_date?true:false}
                            onClear={clearDateFilter}
                        >
                            {date_filter.map(date => (
                                <Select.Option key={date?.key} value={date?.value}>{date?.label}</Select.Option>
                            ))}
                        </Select>

                        {/* <Select
                                onChange={onPriorityChange}
                                placeholder="Select Priority"
                                className='ticket-form-select'
                            >
                                {priorities.map(priority => (
                                    <Option key={priority?.key} value={priority?.value}>{priority?.label}</Option>
                                ))}
                            </Select> */}

                    </div>
                    <Input
                        value={searchQuery}
                        onChange={onSearchChange}
                        className='tickets-auto-complete'
                        placeholder="Search by ticket title"
                        style={{ borderRadius: ".5em", border: "1px solid rgba(84, 193, 4, 1)", height: "3em", maxWidth: "20em", width: "100%" }}
                    />
                </div>
                <div className='rd-container rd-sidebar-view'>
                    {
                        tab_lists?.map(sidebar => (
                            <div className={`ticket-sidebar-normal ${(current_tab?.key === sidebar?.key) ? 'ticket-active-sidebar' : ''}`} onClick={() => setCurrentTab(sidebar)}>
                                {current_tab?.key === sidebar?.key ? <img src={sidebar?.active_icon} alt="active_icon" /> :
                                    <img src={sidebar?.deactive_icon} alt="deactive_icon" />}
                                <h4 className={`ticket-sidebar-label ${(current_tab?.key === sidebar?.key) ? 'ticket-active-sidebar-label' : ''}`}>{sidebar?.label}</h4>
                            </div>
                        ))
                    }
                    <div className='ticket-border-line'></div>
                </div>
                <Spin spinning={ticket_loading}>
                    <div className="ticket-list ticket-list-overflow-container" style={{ paddingBottom: "20px" }}>
                        {allTickets?.length > 0 ? allTickets.map(ticket => (
                            <div key={ticket.id} className="custom-ticket-box" style={{ cursor: "pointer" }} onClick={() => navigateRoute(support_route?.details.replace(":id", getIntFromString(ticket?.id)))}>
                                <div className="ticket-header">
                                    <div
                                        style={{
                                            width: "1em",
                                            height: "1em",
                                            borderRadius: "50%",
                                            backgroundColor: getPriorityColor(ticket?.priority), // Dynamically sets color
                                        }}
                                    />

                                    <h3 className='ticket-work-item-id'>Ticket #TK-{displayUtcToUserTimezone(ticket?.createAt, 'YYYY')}-{getIntFromString(ticket?.id)}</h3>
                                    {/* <span
                                            className="ticket-priority"
                                            style={{ backgroundColor: getPriorityColor(ticket.priority) }}
                                        >
                                           { getPriorityText(ticket.priority) } Priority
                                        </span> */}
                                </div>
                                <div className="ticket-details">
                                    <h4 className='ticket-detail-title'>{ticket.title}</h4>
                                    <p className='ticket-detail-description'>{ticket.description}</p>
                                </div>
                                <hr style={{ border: ".5px solid rgba(84, 193, 4, 1)", margin: "0px", padding: "0px" }} />
                                <div className="ticket-footer">
                                    <p className='ticket-detail-description'>Posted At: {displayUtcToUserTimezone(ticket?.createAt, 'DD/MM/YYYY , hh:mm A')}</p>
                                    <span
                                        className="ticket-status"
                                    >
                                        {ticket.status === "RESOLVED" ? "Closed" : ticket?.status === "WORK_IN_PROGRESS" ? "Work In Progress" : "Open"}
                                    </span>
                                </div>
                            </div>
                        )) :
                            (!ticket_loading && <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: "center", paddingTop: "10%" }}>
                                <h4 className='no-ticket-found-text'>No Tickets Found</h4>
                            </div>)}
                    </div>
                    {allTickets?.length > 0 && !ticket_loading && <Pagination
                        total={pagination_info?.totalCount}
                        itemRender={itemRender}
                        responsive={true}
                        showSizeChanger={false}
                        pageSize={10}
                        current={current_page}
                        onChange={(e) => onPagination(e)}
                        className='ticket-custom-pagination' />}
                </Spin>
            </div>
            <KudosCustomPickerView selectedDateRange={(date) => onFilterCustomDate(date)} onClose={(e) => { onCloseDate() }} open_date_picker={open_date_picker} {...props} />
        </div>
    );
}

export default MyTickets;
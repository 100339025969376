import React from 'react';
import { compose } from '../../core';
import AddAgenda from '../components/AddAgenda'
import { withApollo } from '@apollo/client/react/hoc';
import { CONTRIBUTOR_OKR_LIST } from '../graphql/Contributor.gql';
import { DEFAULT_COMMITMENT } from '../graphql/Commitment.gql';
import { COACHING_COMMITMENT } from '../graphql/CoachingCommitment.gql';
import { globalPermissionValidator } from 'modules/look';
import { oneonone_permission } from 'Permissions/oneonone.permission';

const AddAgendaContainer = props => {
    const {client,userPermission}=props
    const [relatedData, setrelatedData] = React.useState([]); 
    const [permission, setpermission] = React.useState()
    React.useEffect(() => {
      if (userPermission?.length) {
        let permission = globalPermissionValidator(oneonone_permission, userPermission)
        setpermission(permission)
      }
      else {
        setpermission({})
      }
    }, [userPermission])

    const getRelatedData = async (detail) => {
      
      if(detail?.type==='OKR'){   
        if(permission && permission?.okr_permission){
          let id = detail?.user?.employee?.id;
          const { data } = await client.query({
              query: CONTRIBUTOR_OKR_LIST,
              variables: { employee: id,requestStatus:"ACCEPTED" },
              fetchPolicy:'network-only'
          });
          if(data){
            let list =  data?.contributors?.edges?.filter(item=>item?.node?.okr?.isDiscarded==null)?.map(({node})=>node?.okr)
            setrelatedData(list)
          }
          else{
            setrelatedData([])
          }
        }else{
          setrelatedData([])
        }
      }
     else if(detail?.type==='Commitment') {
      let id = detail?.user?.id;
      const { data } = await client.query({
          query: DEFAULT_COMMITMENT,
          variables: { user: id },
      });
      if(data?.defaultCommitmentProgress){
        let list =  data?.defaultCommitmentProgress?.edges?.map(({node})=>node?.commitment)
        setrelatedData(list)
      }
      else{
        setrelatedData([])
      }
    }
      else if(detail?.type==='Coaching Commitment'){
        let id = detail?.user?.id;
        const { data } = await client.query({
            query: COACHING_COMMITMENT,
            variables: { user: id },
        });
        if(data?.coachingCommitmentProgress){
          let list =  data?.coachingCommitmentProgress?.edges?.map(({node})=>node?.coachingcommitment)
          setrelatedData(list)
        }  else{
          setrelatedData([])
        }
      }
     }
  

    return (
      <>
      {
        permission && (
          <AddAgenda {...props} selectType={(e)=>{getRelatedData(e)}} permission={permission} relatedData={relatedData} />
        )
      }
      
      </>
    )

};

export default compose(withApollo)(AddAgendaContainer);


import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { ALL_BADGES_LIST } from "modules/kudos-user/graphql/allBadgesList.gql";
import GiveKudosView from "../components/GiveKudosView";
import { withCreateBadgeAward } from "modules/kudos-user/container/badgeAwardOperationFeedback";

import { BADGE_FAMILY_DETAIL, BADGE_FAMILY_FILTER_LIST } from "modules/kudos-user/graphql/badgeFamilyFilterList.gql";


const GiveKudosForFeedbakContainer = (props) => {
    const { client, me,onCancel } = props
    const [loading, setLoading] = React.useState(false)
    const [badgeList, setBadgeList] = React.useState([])
    const [badge_family_list, setBadgeFamilyList] = React.useState()
    // eslint-disable-next-line no-unused-vars
    const [selectedFamily, setSelectedFamily] = React.useState();
    const getBadgeFamilyList = async (filterValue) => {

        try{
            setLoading(true)
            const { data } = await client.query({
                query: ALL_BADGES_LIST,
                variables: { ...filterValue },
                fetchPolicy: "network-only"
            });
            if (data) {
                let values = data?.badgeByTitle?.edges.concat(data?.badgeByDescription?.edges)
                var setObj = new Set();
                var result = values.reduce((acc, item) => {
                   
                    if (!setObj.has(item?.node?.id)) {
                        setObj.add(item?.node?.id)
                        acc.push(item)
                    }
                    return acc;
                }, [])
              
                setLoading(false)
                setBadgeList(result)
            }
        }catch(e){
            console.error(e)
        }
      
    }

    const getBadgesByFamily = async (filterValue) => {

        try{
            setLoading(true)
            const { data } = await client.query({
                query: ALL_BADGES_LIST,
                variables: { ...filterValue },
                fetchPolicy: "network-only"
            });
            if (data) {
                let values = data?.badgeByTitle?.edges.concat(data?.badgeByDescription?.edges)
                var setObj = new Set();
                var result = values.reduce((acc, item) => {
                    if (!setObj.has(item?.node?.id)) {
                        setObj.add(item?.node?.id)
                        acc.push(item)
                    }
                    return acc;
                }, [])
    
                setLoading(false)
                setBadgeList(result)
            }
        }catch(e){
            console.error(e)
        }
     
    }




    const BadgeFamilyList = async (filterData) => {

        try{
            const { data } = await client.query({
                query: BADGE_FAMILY_FILTER_LIST,
                variables: { ...filterData },
                fetchPolicy: "network-only"
            });
            if (data) {
               
                setBadgeFamilyList(data?.BadgeFamily?.edges)
            }
        }catch(e){
            console.error(e)
        }
       
    }

    const BadgeFamilyDetail = async (filterData) => {

        try{
            // eslint-disable-next-line no-unused-vars
            const { data } = await client.query({
                query: BADGE_FAMILY_DETAIL,
                variables: { ...filterData },
                fetchPolicy: "network-only"
            });
        }catch(e){
            console.error(e)
        }
       
    }



    React.useEffect(() => {
        if (me) {
            BadgeFamilyList()
            getBadgeFamilyList()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me])

    return (
        <>
            <GiveKudosView {...props}
                onFilter={(e) => getBadgeFamilyList(e)}
                badge_family_list={badge_family_list}
                setSelectedFamily={setSelectedFamily}
                getBadgesByFamily = {(e)=>getBadgesByFamily(e)}
                getBageFamilyById={(e) => BadgeFamilyDetail(e)}
                getbadgeFailyList={(e) => BadgeFamilyList(e)}
                onSearch={(e) => getBadgeFamilyList(e)} badgeList={badgeList} loading={loading}
                onCancel={()=>onCancel(false)} />
        </>
    )
}

export default compose(withApollo, withCreateBadgeAward)(GiveKudosForFeedbakContainer)

// @ts-nocheck
import React, { useState} from "react";
import { Row} from "antd";

import { getIntFromString, MetaTags, ReactMarkdown } from "../../look";
import AddToolToRecent from "./AddToolToRecent";
import { useHistory } from "react-router-dom";
import Back_icon from '../../assets/soul-back.svg'
import '../ToolStyles/GenericTool.css';
import '../ToolStyles/SoulTool.css';
import GenericUiFlowFormSection from "./generic-tool/GenericUiFlowFormSection";




const GenericUiFlowTool = (props) => {
  const { admin = false, refetch, items,getToolById} = props;
  const [topsection, settopsection] = useState([]);
  const [valuesection, setvaluesection] = useState([]);
  const [formsection, setformsection] = useState([]);
  // const [items, setItems] = React.useState([]);

  
  const history = useHistory();

  
  const goBack=()=>{
    // if(location?.search){
    //   let data =location?.search
    //   let id =data.split('=')
    //   if(id[1]){
    //     history.push(`/survey/response/uiflow/values/3?response=${id[1]}`)

    //   }
    // }
    if(getToolById?.title === "Values And Behaviours"){
      history.push('/soul/values')
    }
    else if(getToolById?.title === "Strengths And Behaviour" ||getToolById?.title === "Strengths and Behaviour"){
      history.push('/soul/strengths')
    }
    else if(getToolById?.title === "Personality And Behaviours"){
      history.push('/soul/personalities')
    }
    else if(getToolById?.title === "Knowledge, Skills And Behaviours"){
      history.push('/soul/knowledge_and_skills')
    }
    else{
      history.goBack()
    }
  }

   React.useEffect(()=>{
    if(items && items?.length){
      let text_section= items?.filter(value=>value?.type==="textsection")
      let top_section = text_section?.filter((value,idx)=>idx!==0)
      let value_section= text_section?.filter((value,idx)=>idx===0)
      let forms_section= items?.filter(value=>value?.type==="formsection")
      settopsection(top_section)
      setvaluesection(value_section)
      setformsection(forms_section)
    }
   },[items])


  


  const RenderTextSection = ({ textSection, idx }) => {
    
    return (
      <div className="text-render-margin">
        <ReactMarkdown>{textSection.renderedText || "<p></p>"}</ReactMarkdown>
      </div>
    );
  };

 

  const renderTextSection = (section,idx) => {
    return section.type === "textsection"?
    (
      <>
         <RenderTextSection
            textSection={section}
            idx={idx}
          />
      </>
    )
    :''
  }



  return (
    <>
      
    <Row className="tool-body-padding">
       
      <AddToolToRecent />
   
      <MetaTags title="Tool Details" description={`This is too details page`} />
      <div className="space-between-row-impact-ui-flow-tool back-arrow-global">
            <img className="back-button" style={{cursor:"pointer"}} src={Back_icon} alt="back" onClick={()=>goBack()}/>
            <h4 className="tool-titile"  >{getToolById?.title}</h4>
            <img className="back-button" style={{cursor:"pointer", visibility: 'hidden'}} src={Back_icon} alt="back" />
    </div>

      <div className="soul-section soul-top-margin tool-background" >
        <div className="soul-fluid-container">
          {
            topsection?.map((item,idx)=>(
                <div className="top-section-container">
                  {renderTextSection(item,idx)}
                </div>
            ))
          }
        </div>
      </div>

      <div className="soul-section soul-top-margin" style={{background:"transparent"}}>
        <div className="soul-fluid-container first-section soul-space-btw">
          {
            valuesection?.map((item,idx)=>(
              <div className="soul-value-section bottom-section-container">
              
                  {renderTextSection(item,idx)}
              
              </div>
            ))
          }
          {
            formsection?.length?
              <div className="bottom-section-container index-form">
                  <GenericUiFlowFormSection
                    admin={admin}
                    formSectionId={getIntFromString(formsection[0]?.id)}
                    formSection={formsection[0]}
                    refetch={refetch}
                    toolHead={getToolById?.title}
                  />
            </div>
            :''
          }
        </div>

        
      </div>

      <div className="soul-section" style={{overflow:"hidden"}}>
        <div style={{background:"transparent"}} className="soul-fluid-container soul-wrap soul-space-btw"> 
          {
            formsection?.map((section,idx)=>(
                idx!==0?
                <div className="bottom-section-container form-wrap-section">
              <GenericUiFlowFormSection
                admin={admin}
                formSectionId={getIntFromString(section?.id)}
                formSection={section}
                refetch={refetch}
                toolHead={getToolById?.title}
              />
                </div>:''
            )) 
          }
        </div>
      </div>

 
     
   
    </Row>
    </>
  );
};

export default GenericUiFlowTool;



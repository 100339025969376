// @ts-nocheck
import { Col, Form, Button, Tooltip, message, Row } from "antd";
import React from "react";
import edit_pencil from '../../assets/edit_pencil.svg'
import plus_btn from '../../assets/plus-btn.svg';
import okr_route from '../route/index'
import close from "../../assets/close.svg";
import { changeToCurrentTime, displayUtcToUserTimezone, getIntFromString } from "modules/look";
import collabrator_disable from '../../assets/collabrator_disable.svg'
import RangeDatePicker from "modules/look-v2/Components/date-range-picker";
import gear_disabled from '../../assets/gear_disabled.svg'
import ProgressUpdateContainer from "../formComponents/Container/progressUpdateContainer";
import black_eye from "../../assets/black_eye.svg";
import blue_eye from "../../assets/blue_eye.svg";
import view from '../../assets/view.svg'
import AddWeightContainer from "../formComponents/Container/AddWeightContainer";
import moment from "moment-timezone";
import CommaSeparateComponent from "../formComponents/CommaSeparatedComponent";
import CustomProgress from "modules/look-v2/Components/Progress";
import black_discard from '../../assets/black_discard.svg'
import delete_outline_black from '../../assets/delete_outline_black.svg'
import TableRowMenu from "modules/look-v2/Components/TableRowMenu";
import ExpandableDropdownContainer from "modules/look-v2/Container/ExpandableDropdownContainer";
import DeleteOkrDetails from "./deleteOkrDetails.";
import keyresult_expnasion_arrow from "../../assets/keyresult_expnasion_arrow.svg";
import grey_expansion from "../../assets/grey_expansion.svg";
import blue_expansion from "../../assets/blue_expansion.svg";
import SubMilestoneDetailTableContainer from "../container/Sub-milestone-Detail-table-container";
import RequestDiscardView from "./requestDiscardView";
import OkrConnectionLine from "modules/look-v2/Components/OkrConnectionLine";
import menu_cascade from '../../assets/menu_cascade.svg'
import menu_del_info from '../../assets/menu_del_info.svg'
import CustomOkrRange from "modules/look-v2/Components/customOkrRangePicker";
import TextInputField from "../formComponents/OkrInputFieldNew";
import CustomOkrRangeTable from "modules/look-v2/Components/customOkrDatePickerForTable";
import kodos_award from '../../assets/kodos-award-icon.svg'
import AwardBadgeContainer from "modules/kudos-user/container/awardBadgeContainer";
const MileStoneDetailTableView = (props) => {
  const { me,customClass, createOkr,allMilestone,addChild, navigateRoute, updateOkr, isDiscarded, keyResultDetail, loading, setloading, hideadd ,okrDiscarded,set_okr_tree,displayConnectionLine,setallMilestone,editMilestones,permission,updateMileStoneRef} = props
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [createMilestoneCounter, setcreateMilestoneCounter] = React.useState([]);
  const [dataSource, setDataSource] = React.useState();
  const [deleteRecord, setDeleteRecord] = React.useState();
  const [expandedRow, setexpandedRow] = React.useState([])
  const [correctionDeadlineAlive, setCorrectionDeadlineAlive] = React.useState();
  const [discardPopupvisibleMile, setDiscardPopupVisibleMile] = React.useState(false);
  const [discardOKRDetail, setdiscardOKRDetail] = React.useState();
  const [deleteModal,setDelateModal] = React.useState(false);
  const [addAward,setAddAward]=React.useState(false)
  const [selectedKudos,setSelectedKudos]=React.useState()
  const [show_pop_over,setPopOver]=React.useState(false)
  let cunterRef = React.useRef(0)
  
  React.useEffect(() => {
    
    if (allMilestone) {
      
      // setDataSource([])
  

      setDataSource(allMilestone?.edges)

      let isCorrectionDateAlive = moment(keyResultDetail?.correctionDeadline).isAfter(moment())
      setCorrectionDeadlineAlive(isCorrectionDateAlive)
      setloading(false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMilestone])

  React.useEffect(() => {
    if (addChild === true) {
      addToMilestone()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addChild])

  const addToMilestone = () => {
   
    if(!createMilestoneCounter?.length){
    cunterRef['current'] = cunterRef['current'] + 1
    let list = [].concat(createMilestoneCounter);
    list.push({ key: `MileStone_${cunterRef?.current}` });
    setcreateMilestoneCounter(list);
    }
  };

  const expand = (id) => {
    let list = [].concat(expandedRow)
    if (!list?.includes(id)) {
      list.push(id)
    } else {
      list = list.filter(item => item !== id)
    }
    setexpandedRow(list)
  }


  const onFinish = async (key) => {
    
    try {
      let value = form.getFieldsValue()
      //  new to do change parent id from props
      if (value[`${key}_goalStatement`] && value[`${key}_goalStatement`]?.length<=150 && value[`${key}_duedate`]?.length === 2) {
        let due_date = changeToCurrentTime(value[`${key}_duedate`][1])
        let okrData = {
          goalStatement: value[`${key}_goalStatement`],
          duedate: due_date?.toISOString(),
          startdate: value[`${key}_duedate`][0].toISOString(),
          parentId: getIntFromString(keyResultDetail?.id)
        }
        const response = await createOkr({ okrData })
        if (response && response?.id) {
          // createContributorData(response)
          removecreateMilestone({ key })
        }
      }
    }
    catch (err) {
      console.error('kr create catch', err);
    }
  };

  const removecreateMilestone = (value) => {
    let list = [].concat(createMilestoneCounter)
    setcreateMilestoneCounter(list.filter(item => item?.key !== value?.key))
  };

  React.useEffect(() => {
    
    if (!loading && !dataSource?.length && dataSource && isDiscarded !== "APPROVED"&&keyResultDetail && 
    !keyResultDetail?.contributorSet?.edges?.find((item) => item?.node?.role === 'ASSIGNED')) {
      
      cunterRef['current'] = cunterRef['current'] + 1
      if(moment(keyResultDetail?.duedate).isAfter(moment())){
        setcreateMilestoneCounter([{ key: `MileStone_${cunterRef?.current}` }]);
      }
    }
  }, [dataSource, loading, isDiscarded,keyResultDetail])

  const updateDelete = (id) => {
    setDeleteRecord(null)
    let list = [].concat(dataSource)
    list = list.filter(item => item?.id !== id)
    setallMilestone({edges:list})
    updateMileStoneRef({record:{id},type:'DELETE'})
  }


  const onTableMenuClickAction = (record, action) => {

    if (action === 'view') {
      navigateRoute(okr_route?.milestoneDetail?.replace(':okrId', getIntFromString(record?.id)))
    } else if (action === 'edit') {
      updateQue(record, true)
    } else if (action === 'discard') {
      setdiscardOKRDetail(record);
      setDiscardPopupVisibleMile(true);
    } else if (action === 'delete') {
      setDeleteRecord(record);
      setDelateModal(true)
    }
    else if (action === 'tree') {
      set_okr_tree(record);
  }else if(action==='kodos'){
    setAddAward(true)
    setSelectedKudos(record)
}
  }


  const onUpdate = async (okr) => {
    let formValue = updateForm.getFieldsValue()
    let data = {}
    if (formValue[`${okr?.id}_goalStatement`]) {
      data['goalStatement'] = formValue[`${okr?.id}_goalStatement`]
    }

    if(data['goalStatement'] && data['goalStatement']?.length>150){
      return
    }

    if (formValue[`${okr?.id}_duedate`]?.length === 2) {
      let dates = formValue[`${okr?.id}_duedate`]
      data['startdate'] = dates[0].toISOString()
      let due_date = changeToCurrentTime(dates[1])
      data['duedate'] = due_date?.toISOString()
    }

    if (!Object.keys(data)?.length) {
      message.error('update the form')
    } else {
      data['id'] = okr?.id
      let response = await updateOkr(data)
      if (response?.id) {
        let list = [].concat(dataSource)
        let item = list?.find(val => val?.id === okr?.id)
        if (item) {
          item['duedate'] = response?.duedate
          item['startdate'] = response?.startdate
          item['title'] = response?.title
          item['goalStatement'] = response?.goalStatement
          item.isUpdate = false
        }
        let update_data ={
          id:response?.id,
          title:response?.title,
          startdate:response?.startdate,
          duedate:response?.duedate,
          goalStatement:response?.goalStatement
        }
        updateMileStoneRef({record:update_data,type:'UPDATE'})
        setDataSource(list)
      } else {
        message.error('failed to update')
      }
    }
  }

  const updateQue = (record, isUpdate) => {
    let list = [].concat(dataSource)
    let item = list?.find(okr => okr?.id === record?.id)
    item['isUpdate'] = isUpdate
    setDataSource(list)
  }



  const addContributor=(contributor,item)=>{
    let list = [].concat(dataSource)
    let record = list?.find(val=>val?.id===item?.id)
    if(record){
      let filter_list =record?.contributors?.filter(user=>user?.id===me?.employee?.user?.id)
      let updates = Object.values(contributor?.data)?.map(({contributor})=>contributor?.employee?.user)
      if(updates?.length){
        record.contributors=[...filter_list,...updates]
        setDataSource(list)
      }

    }

  }
  const updateChildsetofMilestone =(detail)=>{
    let list = [].concat(dataSource)
    let okr = list?.find(item=>item?.id===detail?.okrId)
    if(okr){
      okr.childSet=detail.childSet
    }
    setDataSource(list)
  }

  const updateDiscardRow = (record) => {
    setDiscardPopupVisibleMile(false);
    setdiscardOKRDetail(null)
  }
  const update_progress=(okrid,progress)=>{
    let data_source_progress = [].concat(dataSource)
    let okr = data_source_progress.find(item=>item?.id===okrid)
    
    if(okr){
        okr.progress=progress
    }
    setDataSource(data_source_progress)
  }


  return (
    <>
      {
      deleteRecord &&
      <DeleteOkrDetails visible={deleteModal} type="Milestone" deletesuccess={(e) => { updateDelete(e) }} closeModal={() => { setDeleteRecord(null) }} okr={deleteRecord?.id} {...props} />
    }

     <RequestDiscardView onClose={()=>{setDiscardPopupVisibleMile(false)}} visible={discardPopupvisibleMile} {...props} discardData={{ id: discardOKRDetail?.id }}
      discardedSuccess={(e) => { updateDiscardRow(e) }}   />

      <Col className="custom-table-v2">

      {
         
          <Form
          form={form}
          name={"key_result"}
          autoComplete="off"
        >
          {createMilestoneCounter?.map((item, index) => (
            <Col>
              <div className="row">
                {
                  displayConnectionLine && <OkrConnectionLine/>
                }
              
                    <Row align="middle" style={{color: '#595959',fontWeight:'500',fontSize:'16px',marginBottom:'23px'}}>
                      M
                    </Row>
                <div
                  className="custom-table-tr table-body-tr-border"
                  style={{ borderLeft: "5px solid #B4E045" }}
                >
                  <div
                    className="table-section-td"
                    style={{ width: "215px", minWidth: "290px" }}
                  >
 
                    <TextInputField
                      name={`${item?.key}_goalStatement`}
                      show_pop_over = {show_pop_over}
                      setPopOver = {setPopOver}
                      placeholder="Executed by..."
                      rules={[
                        { required: true, message: "title required" },
                      ]}
                    />
                  </div>
 
                  <div
                    className="table-section-td center"
                    style={{ width: "280px", minWidth: "280px" }}
                  >
                    <div>
                      <div className="custom-okr-picker">
                        <CustomOkrRangeTable
                            name={`${item?.key}_duedate`}
                            disabledRange={{
                              duedate: keyResultDetail?.duedate,
                              startdate: moment(keyResultDetail?.startdate).isAfter(moment()) ? keyResultDetail?.startdate : moment()?._d
                            }}
                            selectedDateRange={(e)=>form.setFieldsValue({
                              [`${item?.key}_duedate`]: e,
                            })} 
                            currentSelected={false}
                            disabledType={true}
                       />
                      </div>
                      <div className="okr-picker">
                        <RangeDatePicker
                          name={`${item?.key}_duedate`}
                          dateFormat="DD/MM/YY"
                          disabledRange={{
                            duedate: keyResultDetail?.duedate,
                            startdate: moment(keyResultDetail?.startdate).isAfter(moment()) ? keyResultDetail?.startdate : moment()?._d
                          }}
                        />
                      </div>
                   </div>
                  </div>
 
                  <div
                    className="table-section-td center"
                    style={{ width: "200px", minWidth: "200px" }}
                  >
                    <div className="row text-transform-capitalize" style={{ color: "#D9D9D9" }}>
                      {me?.firstName}
                      <img src={collabrator_disable} alt="" />
                    </div>
                  </div>
 
                  <div
                    className="table-section-td"
                    style={{ width: "300px", minWidth: "290px" }}
                  >
                    <img src={gear_disabled} alt="" style={{ cursor: "pointer" }} />
                    <div style={{ width: "100%" }} align="center">
                      <Button className="table-save-btn" disabled = {show_pop_over} onClick={() => onFinish(item?.key)}>Save</Button>
                    </div>
                    <img
                      src={close}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        removecreateMilestone(item);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Form>
         
        }


        {
          
          (dataSource?.length) ? 
          <Form
            form={updateForm}
            name="Objective_update"
            autoComplete="off"
          >
            {dataSource?.map((item, index) => (
              <Col>
                <div className="row ">
                  {
                    displayConnectionLine && <OkrConnectionLine/>
                  }
                
                  {!customClass&&<div>
                    {(index + 1 === dataSource?.length && isDiscarded !== 'APPROVED' && !hideadd && moment().utc().isBefore(keyResultDetail?.duedate)) ? (
                      <div className="expand-table-sider" style={{width:'45px',minWidth:'45px'}}  >
                    <Tooltip title="Add Milestone">
                     <img
                        src={plus_btn}
                        onClick={(e) => {
                          addToMilestone();
                        }}
                        style={{ cursor: "pointer" }}
                        alt=""
                      />
                      </Tooltip>
                        <div>M</div>
                      </div>
                    ) : (
                      <Row  style={{color: '#595959',fontWeight:'500',fontSize:'16px',marginTop:'16px'}}>M</Row>
                    )}
                    
                  </div>}
                  <div className="custom-table-tr table-body-tr-border" style={{
                    borderLeft: (item?.isDiscarded?.status === 'APPROVED') ? "5px solid #141414" :
                      (item?.isDiscarded?.status === 'PENDING') ? "5px solid #009AF1" : "5px solid #B4E045",
                    position: (item?.isDiscarded?.status === 'APPROVED' || item?.isDiscarded?.status === 'PENDING') ? "relative" : ""
                  }}>
                    {
                      (item?.isDiscarded?.status === 'APPROVED') ?
                        <div className="table-overlay">
                          <img src={item?.isDiscarded?.status === 'APPROVED' ? black_eye : blue_eye} onClick={() => { navigateRoute( okr_route.milestoneDetail.replace(":okrId",getIntFromString(item?.id))) }} className="table-eye" alt="" style={{ marginLeft: "320px", cursor: "pointer" }} />
                        </div> : ''
                    }
                    <div className="table-section-td" style={{ width: "290px", minWidth: "280px" }}>
                      
                    { item?.childSet && (
                            <img
                            src={
                              item?.isDiscarded?.status === "PENDING"
                                ? blue_expansion
                                : item?.isDiscarded?.status === "APPROVED"
                                ? grey_expansion
                                : keyresult_expnasion_arrow
                            }
                            alt=""
                            onClick={(e) => {
                              expand(item?.id);
                            }}
                            style={{ zIndex: "1" }}
                            className={`expansion-arrow ${
                              expandedRow?.includes(item?.id)
                                ? "expansion-arrow-down"
                                : ""
                            }`}
                          />
                       )}
                      {
                        item?.isUpdate  ?
                          <TextInputField
                            name={`${item?.id}_goalStatement`}
                            show_pop_over = {show_pop_over}
                            setPopOver = {setPopOver}
                            placeholder="Executed by ..."
                            defaultValue={item?.goalStatement}
                          /> :
                          <Tooltip title={item?.title}>
                            <span style={{cursor:'pointer'}} onClick={()=>{onTableMenuClickAction(item,'view')}}>{item?.title}</span>
                          </Tooltip>
                      }
                    </div>

                    <div className="table-section-td center" style={{ width: "280px", minWidth: "280px" }}>

                      {
                       (item?.isUpdate && !okrDiscarded)  ?
                            <div>
                              <div className="custom-okr-picker">
                                <CustomOkrRangeTable
                                  name={`${item?.id}_duedate`}
                                  disabledRange={{
                                    duedate: keyResultDetail?.duedate,
                                    startdate: moment(keyResultDetail?.startdate).isAfter(moment()) ? keyResultDetail?.startdate : moment()?._d
                                  }}
                                  defaultValue={[moment(item?.startdate), moment(item?.duedate)]}
                                  selectedDateRange={(e)=>updateForm.setFieldsValue({
                                    [`${item?.id}_duedate`]: e,
                                  })} 
                                  currentSelected={false}
                                  disabledType={true}
                                />
                              </div>
                              <div className="okr-picker">
                                <RangeDatePicker
                                  name={`${item?.id}_duedate`}
                                  dateFormat="DD/MM/YY"
                                  disabledRange={{
                                    duedate: keyResultDetail?.duedate,
                                    startdate: moment(keyResultDetail?.startdate).isAfter(moment()) ? keyResultDetail?.startdate : moment()?._d
                                  }}
                                  defaultValue={[moment(item?.startdate), moment(item?.duedate)]}
                                /> 
                              </div>
                            </div>
                         
                          :
                          displayUtcToUserTimezone(item?.duedate, 'DD MMM YYYY')
                      }
                    </div>

                    <div
                    className="table-section-td center"
                    style={{ width: "300px", minWidth: "300px" }}
                  >
                    {/* {item?.contributors?.map((user) => user?.firstName)} */}
                    <CommaSeparateComponent showCount={1} textList={item?.contributors?.map(user => { return { id: user?.id, firstName: user?.firstName,lastName:user?.lastName,role:user?.role } })} />
                    <ExpandableDropdownContainer okrId={item?.id} response={(e)=>{addContributor(e,item)}} record={item} {...props}/>
                  </div>

                    <div className="table-section-td" style={{ width: "280px", minWidth: "280px" }}>
                    {item?.childSet && (<AddWeightContainer {...props} okrId={item?.id} />)}
                      {
                        item?.isUpdate   ?
                          <div style={{ width: "100%" }} align="center"><Button className="table-save-btn" onClick={(e) => { onUpdate(item) }}>Update</Button></div>
                          :
                          <div className="row center" style={{ width: "100%", alignItems: "center" }}>
                             {!item?.childSet  &&(<ProgressUpdateContainer editable={ moment(item?.duedate)?.endOf('day').isAfter(moment())} update_progress={(okrid,progress)=>update_progress(okrid,progress)} okr={item} {...props} />)}
                            <CustomProgress showTooltip={true} record={item} progress={item?.progress} />
                          </div>
                      }

                      {
                         (item?.isUpdate && !okrDiscarded)  ?
                          <img src={close} alt="" onClick={() => { updateQue(item, false) }} style={{ cursor: "pointer", paddingRight: "10px" }} />
                          :
                          <TableRowMenu options={[{ label: 'view', key: 'view', icon: view, postLabelIcon: {}, disabled: false },
                          { label: 'Cascade', key: 'tree', icon: menu_cascade, postLabelIcon: {}, disabled: false },
                          { label: 'Edit', key: 'edit', icon: edit_pencil, postLabelIcon: 'icon', disabled:( moment(item?.correctionDeadline).isBefore(moment()) ||moment(item?.duedate).isBefore(moment()) )},
                          { label: 'Kudos', key: 'kodos', icon: kodos_award, postLabelIcon: 'icon', disabled: (permission?.can_award_badge && permission?.okr_permission && item?.contributors?.length >1)?false:true},
                          { label: 'Discard', key: 'discard', icon: black_discard, postLabelIcon: 'icon',post_icon:(item?.discardRequestRaised && item?.isDiscarded?.status!=='PENDING')?menu_del_info:'', tooltip:(item?.discardRequestRaised && item?.isDiscarded?.status!=='PENDING')?"You have already raised the discard request":'', disabled: (item?.discardRequestRaised || item?.isDiscarded?.status==='PENDING'?true:false) },
                          { label: 'Delete', key: 'delete', icon: delete_outline_black, postLabelIcon: 'icon',post_icon:menu_del_info, tooltip:"okr will be deleted permenetly", disabled: (item?.discardRequestRaised||moment(item?.correctionDeadline).isBefore(moment())||moment(item?.duedate).isBefore(moment())) }]}
                            onClick={(e) => onTableMenuClickAction(item, e)}
                          />
                      }
                    </div>
                  </div>
                </div>
                {expandedRow?.includes(item?.id) && (

                <div style={{ width: "100%" }}>
                  <SubMilestoneDetailTableContainer set_okr_tree={set_okr_tree} updateChildset={(e)=>{updateChildsetofMilestone(e)}}  MilestoneDetail={item} {...props} />
                </div>
                )}
              </Col>
            ))}
          </Form>
          :
          <div align='center' style={{fontSize:'18px',fontWeight:'500'}}>No Milestones Found</div>
        }


        {
         !okrDiscarded && displayConnectionLine && correctionDeadlineAlive && editMilestones &&
         <Form
            form={form}
            name={"key_result"}
            autoComplete="off"
          >
            {createMilestoneCounter?.map((item, index) => (
              <Col>
                <div className="row">
                  {
                    displayConnectionLine && <OkrConnectionLine/>
                  }
                
                      <Row align="middle" style={{color: '#595959',fontWeight:'500',fontSize:'16px',marginBottom:'23px'}}>
                        M
                      </Row>
                  <div
                    className="custom-table-tr table-body-tr-border"
                    style={{ borderLeft: "5px solid #B4E045" }}
                  >
                    <div
                      className="table-section-td"
                      style={{ width: "215px", minWidth: "290px" }}
                    >

                      <TextInputField
                        name={`${item?.key}_goalStatement`}
                        placeholder="Executed by..."
                        rules={[
                          { required: true, message: "title required" },
                        ]}
                      />
                    </div>

                    <div
                      className="table-section-td center"
                      style={{ width: "280px", minWidth: "280px" }}
                    >
                      <div>
                        <div className="custom-okr-picker">
                        <CustomOkrRange
                            name={`${item?.key}_duedate`}
                            disabledRange={{
                              duedate: keyResultDetail?.duedate,
                              startdate: moment(keyResultDetail?.startdate).isAfter(moment()) ? keyResultDetail?.startdate : moment()?._d
                            }}
                            selectedDateRange={(e)=>form.setFieldsValue({
                              [`${item?.key}_duedate`]: e,
                            })} 
                            currentSelected={false}
                            disabledType={true}
                          />
                        </div>
                        <div className="okr-picker">
                        <RangeDatePicker
                          name={`${item?.key}_duedate`}
                          dateFormat="DD/MM/YY"
                          disabledRange={{
                            duedate: keyResultDetail?.duedate,
                            startdate: moment(keyResultDetail?.startdate).isAfter(moment()) ? keyResultDetail?.startdate : moment()?._d
                          }}
                        />
                        </div>
                      </div>
                    </div>

                    <div
                      className="table-section-td center"
                      style={{ width: "200px", minWidth: "200px" }}
                    >
                      <div className="row text-transform-capitalize" style={{ color: "#D9D9D9" }}>
                        {me?.firstName}
                        <img src={collabrator_disable} alt="" />
                      </div>
                    </div>

                    <div
                      className="table-section-td"
                      style={{ width: "300px", minWidth: "290px" }}
                    >
                      <img src={gear_disabled} alt="" style={{ cursor: "pointer" }} />
                      <div style={{ width: "100%" }} align="center">
                        <Button className="table-save-btn" onClick={() => onFinish(item?.key)}>Save</Button>
                      </div>
                      <img
                        src={close}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          removecreateMilestone(item);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Form>
        }
      </Col>
      {
          addAward&&<AwardBadgeContainer {...props} visible={addAward} onClose={(e)=>setAddAward(e)} type={'OKR'} selectedItem={selectedKudos} from_detail={true}/>
      }
    </>
  );
};

export default MileStoneDetailTableView
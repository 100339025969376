import React from "react";
import {Button, Divider,Modal } from "antd";

import { ReactMarkdown } from "../../look";
import styled from 'styled-components';

export const CKEditorFieldName = "message";

const SurveyTermsAndCondition = props => {
  const { termsAndConditon,accept,back } = props;
  return (
    <>
      <Modal
       visible={true}
       closable={false}
       footer={false}
       maxHeight={'80vh'}
       width={'60%'}
      >
        <Divider>
          <Heading>Terms And Conditions</Heading>
        </Divider>
        <ReactMarkdown style={{whiteSpace:"normal"}}>{termsAndConditon}</ReactMarkdown>
        <div align="center" style={{marginTop:'30px'}}>
          <TermButton type="primary" onClick={()=>accept(true)}>Accept</TermButton>
          <TermButton type="primary" onClick={()=>back(true)}>Decline</TermButton>
        </div>
      </Modal>
    </>
  );
};

export default SurveyTermsAndCondition;
const TermButton = styled(Button)`
  width:120px;
  margin:10px
`;
const Heading = styled.h3`
 font-size:18px,
 text-align:center,
 white-space:nowrap
`;
import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import { USER_SOUL_REPORT } from "../graphql/CompletedSurvey.gql";
import { globalPermissionValidator } from "modules/look";
import { soul_permission } from "Permissions/soul.permission";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import UserSoulReportView from "../components/soul-user-report-view";


const SoulUserReport = (props) => {
    const { client,userPermission } = props
    const [report_loading, setloading] = React.useState(true)
    const [permission, setPermission] = React.useState() 
    const [data_set,SetData] = React.useState()

    React.useEffect(() => {
 
        if (userPermission?.length) { 
            let soulPermission = globalPermissionValidator(soul_permission, userPermission);
            setPermission(soulPermission)
        }
    }, [userPermission])

    React.useEffect(()=>{
        if(permission?.required_permission){
            
            getSoulReport()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[permission])

    const getSoulReport=async()=>{
        try {
            setloading(true)
            const { data } = await client.query({
                query: USER_SOUL_REPORT,
                variables: {},
                fetchPolicy: 'network-only'
            });

            if (data) {
 
                const {values,strength,personality,knowledge} = data?.me
               
                let data_set = {}
                if(values?.edges[0]?.node?.answerSet?.edges?.length){
                    let answer_set = values?.edges[0]?.node?.answerSet?.edges?.map(({node})=>node)
                    data_set["value"] = answer_set?.sort((a,b)=>a?.question?.sequence - b?.question?.sequence)
                }

                if(strength?.edges[0]?.node?.answerSet?.edges?.length){
                    let answer_set = strength?.edges[0]?.node?.answerSet?.edges?.map(({node})=>node)
                    data_set["strength"] = answer_set?.sort((a,b)=>a?.question?.sequence - b?.question?.sequence)
                }

                if(personality?.edges[0]?.node?.answerSet?.edges?.length){
                    let answer_set = personality?.edges[0]?.node?.answerSet?.edges?.map(({node})=>node)
                    data_set["personality"] = answer_set?.sort((a,b)=>a?.question?.sequence - b?.question?.sequence)
                }

                if(knowledge?.edges[0]?.node?.answerSet?.edges?.length){
                    let answer_set = knowledge?.edges[0]?.node?.answerSet?.edges?.map(({node})=>node)
                    data_set["knowledge"] = answer_set?.sort((a,b)=>a?.question?.sequence - b?.question?.sequence)
                }  
                SetData(data_set)
                setloading(false)
            }
        } catch (error) {

        }
    }

    return (
        <> 
            {
                (permission && permission?.required_permission) &&
                (<UserSoulReportView {...props} data_set={data_set} report_loading={report_loading}/> )
            }
            {
                (permission && !permission?.required_permission) &&
                (<NoPermissionView />)
            }

        </>
    )

}
export default compose(withApollo)(SoulUserReport)
// @ts-nocheck
import React from 'react';
import { LeftOutlined } from "@ant-design/icons";
import {Row,Col,Button,Switch,Spin,message } from "antd";
import styled from "styled-components";
import { MetaTags } from '../../look';
import  TranferUserField  from '../../look-v2/Components/transferUsersView'

const CreateNotificationView = props => {
    const { createNotification,employees } =props
    const [emailNotification,setemailNotification] = React.useState(false);
    const [changeMessage,setchangeMessage]= React.useState();
    const [employeeList,setEmployeeList] = React.useState([]);
    const [loading,setloading] = React.useState(false);
    const [changeMessageValid,setchangeMessageValid]= React.useState(false);
    
    // eslint-disable-next-line no-unused-vars
    const [employeeListValid,setemployeeListValid]= React.useState(false);
    const [reset,setreset]= React.useState(false);

    const back = () => {
        window.history.back();
    };

    const resetData=()=>{
        setreset(true)
        setchangeMessage("")
        // setemailNotification(null)
        setreset(false)
    }

    const SendNotification=async()=>{
      if(changeMessage && employeeList?.length){
        setloading(true)
        setchangeMessageValid(false)
        setemployeeListValid(false)
        try{
            let notificationData= employeeList.map((item)=>{
                return {
                    recipientId:item,
                    changeMessage:changeMessage,
                    emailNotification:emailNotification
                }
            })
            const response = await createNotification({notificationData})
            if(response && response?.length){

                resetData()
                setloading(false)
            }else{
                setloading(false)
            }
        }
        catch{
            setloading(false)
        }
      }
      else{
        if(!changeMessage){
         setchangeMessageValid(true)
        }
        if(!employeeList?.length){
            message.error('please select recipient')
        }
        
      }
    }

    return (
        <> 
            <MetaTags
                title="Create Notification"
                description="This is Create Notification page."
            />
            <Spin spinning={loading}>
            <div style={{padding:'0px 20px'}}>
            <RowDiv >
                   <Col span={21} >
                
                   <h4 style={{fontSize:'20px'}}>Send Notification</h4>

                   </Col>
                   <Button shape="circle"  style={{marginLeft:'20px'}} onClick={back}>
                     <LeftOutlined />
                    </Button>
            </RowDiv>
            <Row>
                <Col lg={{ span: 12 }}  md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Col span={24}>
                    <p style={{fontSize:'15px'}}><span style={{color:'#ff4d4f'}}>*</span> Select Recipients</p>
                        <TranferUserField
                        edges={employees || []}
                        functionType={'create'}
                        dataType={'employee'}
                        isUserid={true}
                        // initialValues={milestoneById?.specificCompetencies?.edges}
                        onDatachange={(e)=>setEmployeeList(e)}
                        reset={reset}
                        />
                    </Col>
                </Col>
                <Col lg={{ span: 12 }}  md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                    <textarea style={{width:'100%',height:'400px',padding:'10px',borderRadius:'5px',resize:'none',border:!changeMessageValid?'1px solid #CACACA':'1px solid #ff4d4f'}} onChange={e => (setchangeMessage(e.target.value))} value={changeMessage} placeholder="Your message"></textarea>
                    <Row style={{marginTop:'15px'}}>
                        <p style={{marginRight:'10px',fontSize:'15px'}}>Send the notification on email as well</p>
                        <Switch value={emailNotification} onChange={()=>setemailNotification(!emailNotification)} />
                    </Row>
                </Col>
            </Row>

            
            <div style={{marginTop:'20px'}} align="center">
            
                    <CreateButton type="primary" onClick={()=>SendNotification()}>
                      Send
                    </CreateButton>

              </div>
            </div>
            </Spin>

        </>
      );
}

export default CreateNotificationView;
const RowDiv =styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`
const CreateButton = styled(Button)`
min-width:90px;
height:40px;
border-radius:5px;
margin-top:10px;
`
// import { withApollo } from '@apollo/client/react/hoc'
import { compose } from 'modules/core'
import React from 'react'
import ScapeReportGraphView from '../components/scape-report-graph-view'

const ScapeReportGraphContainer = (props) => {
    return (
        <ScapeReportGraphView {...props}></ScapeReportGraphView>
    )
}
export default compose()(ScapeReportGraphContainer)

import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import ManagerSelectionView from "../Components/ManagerSelectionView";
import { ORGANIZATION_MANAGERS } from "modules/IDP/graphql/idpCreate.gql";

const ManagerSelectionContainer = (props) =>{
    const { client,me } = props
    const [manager_list,SetManagerList] = React.useState([])
    const [list_loading,SetListLoading] = React.useState(false)
    React.useEffect(()=>{
        if(me){
            getManagerList()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[me])

    const getManagerList = async (filter) => {
       try {
        SetListLoading(true)
        const { data } = await client.query({
          query: ORGANIZATION_MANAGERS,
          variables:filter
        });
        if (data?.me) {
            let employee_list = data?.me?.employee?.organizationSet?.edges[0]?.node?.employees?.edges?.map(({node})=>node)
            let manager_list = employee_list?.filter(i=>((i?.orgCeoEmployee?.totalCount || i?.orgPocEmployee?.totalCount || i?.teamManagerEmployee?.totalCount || i?.verticalHeadEmployee?.totalCount) && i?.id !== me?.employee?.id))
            manager_list = manager_list?.map(i=>{
                return {
                    employee_id:i?.id,
                    user_id:i?.user?.id,
                    name:i?.user?.firstName
                }
            })
            SetManagerList(manager_list)
            SetListLoading(false)
        }
       } catch (error) {
        
       }
      }

    return (
        <>
          <ManagerSelectionView {...props} getManagerList={getManagerList} list_loading={list_loading} manager_list={manager_list} />
        </>
    )
}

export default compose(withApollo)(ManagerSelectionContainer);

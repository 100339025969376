// @ts-nocheck
import React from 'react';
import { Checkbox, Row, Button, Col, Empty, Divider, Space, Spin, Table,Tooltip} from 'antd';
import { ArrowLeftOutlined, BarChartOutlined,CloseOutlined } from '@ant-design/icons';
import { MetaTags, ReactMarkdown, SelectField } from '../../look';
import { useHistory } from 'react-router-dom';
import ReportChart from './reportChart';
import styled from "styled-components";

const ColumnCheckbox = styled(Checkbox.Group)`
.ant-checkbox-group-item {
    display: flex;
}
`
const ReportsComponent = (props) => {
    
    const { loading, onSurvaychange, tableData, surveyLoading, columnFilters, columnFiltersId } = props;
    const history = useHistory();

    // eslint-disable-next-line no-unused-vars
    const [dataSource, setDataSource] = React.useState([]);

    const [questions, setQuestions] = React.useState({});
    const [userAns, setUserAns] = React.useState({});

    const [empIdName, setEmpIdName] = React.useState({});

    const [tableLoader, setTableLoader] = React.useState(false)
    const [cols, setCols] = React.useState([]);
    const [colData, setColData] = React.useState([]);
    const [chartLables, setChartLabels] = React.useState([])
    const [chartTitle, setChartTitle] = React.useState(undefined)
    const [labelCount, setlabelCount] = React.useState({})
    const [surveyList,setSurveyList]=React.useState([])
    
    const [showGraph, setShowGraph] = React.useState(false);
    const [employeeFilter, setEmployeeFilter] = React.useState([]);

    const [currentGraphId, setCurrentGraphId] = React.useState(undefined);
    const [currentGraphTitle, setCurrentGraphTitle] = React.useState(undefined);


    React.useEffect(() => {
        if (showGraph) {
            if ((currentGraphId !== undefined) && (currentGraphTitle !== undefined)){
                showGraphBtn(currentGraphId, currentGraphTitle)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnFiltersId, employeeFilter])

    React.useEffect(()=>{
        setSurveyList(props?.surveyData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props?.surveyData])
    React.useEffect(() => {
        let edges = []
        let mapQuestions = {}
        let empAns = {}
        let empIdName = {}
        // eslint-disable-next-line array-callback-return
        tableData.map((employee) => {
            let qa = {}
            if(employee.responseSet.edges.length>0){
                // eslint-disable-next-line array-callback-return
                employee.responseSet.edges.slice(0, 1).map((data) => {
                    // eslint-disable-next-line array-callback-return
                    data.node.answerSet.edges.map(q => {
                        mapQuestions[q.node.question.id] = (q.node.question.questionText)
                        qa[q.node.question.id] = q?.node?.answer
                    })
                })
                empIdName[employee.id] = employee.firstName
                empAns[employee.id] = qa
            }
        })
        setEmpIdName(empIdName);
        setUserAns(empAns);
        setQuestions(mapQuestions);
        setDataSource(edges);
    }, [tableData]);
    
    
    // data
    React.useEffect(() => {
        const data = []
        let filterInit = {}
        for (const [emp, qa] of Object.entries(userAns)) {
            let d = {}
            for (const [qId] of Object.entries(questions)) {
                d[qId] = qa[qId]===undefined||qa[qId]===""?"Skipped":qa[qId]
                filterInit[qId] = []
            }
            d['key'] = emp
            d['name'] = empIdName[emp]
            data.push(d)
        }
        
        setColData(data)
       
        // setColDataFilter(filterInit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userAns, questions])

    const renderGraph = (v, t) => {
        setCurrentGraphId(v)
        setCurrentGraphTitle(t)
        showGraphBtn(v,t)
    }

    function doNothing() { }

    const showGraphBtn = (value, html) => {
        let chartLables = []
        let labelCount = {}
        let eIds = [];
        // let filteredData = tableData.filter(() => true)
  
        let filteredData = tableData.filter(employee => {
            let r = true
            if (employeeFilter.length === 0 || employeeFilter.includes(employee.id)) {
                if (eIds.indexOf(employee.id) === -1) {
                    eIds.push(employee.id);
                    let qIds = []
                    // eslint-disable-next-line array-callback-return
                    employee.responseSet.edges.slice(0, 1).map((data) => {
                        // eslint-disable-next-line array-callback-return
                        data.node.answerSet.edges.map(q => {
                            if (qIds.indexOf(q.node.question.id) === -1) {
                                qIds.push(q.node.question.id);
                                if (columnFilters[q.node.question.id] === undefined || columnFilters[q.node.question.id]?.includes(q?.node?.answer)) {
                                    return true
                                }else{
                                    r = false
                                    return false
                                }
                                
                            }
                        })
                    })
                }
            }
            return r
        })
        eIds = [];
        // eslint-disable-next-line array-callback-return
        filteredData.map((employee) => {

            if (employeeFilter.length === 0 || employeeFilter.includes(employee.id)){
            if (eIds.indexOf(employee.id) === -1) {
                eIds.push(employee.id);
                let qIds = []
                // eslint-disable-next-line array-callback-return
                employee.responseSet.edges.slice(0, 1).map((data) => {
                    // eslint-disable-next-line array-callback-return
                    data.node.answerSet.edges.map(q => {
                        if(q?.node?.answer===""){
                            q.node.answer="Skipped"
                        }
                        else{
                            q.node.answer=q?.node?.answer
                        }
                        if (qIds.indexOf(q.node.question.id) === -1) {
                            qIds.push(q.node.question.id);                            
                                if (q.node.question.id === value) {
                                    if (columnFilters[q.node.question.id] !== undefined && 
                                        columnFilters[q.node.question.id].includes(q?.node?.answer)) {
                                            chartLables.indexOf(q?.node?.answer) === -1 ? chartLables.push(q?.node?.answer) : doNothing();
                                            if (labelCount[q?.node?.answer]) {
                                                labelCount[q?.node?.answer] = labelCount[q?.node?.answer] + 1
                                            } else {
                                                labelCount[`${q?.node?.answer}`] = 1
                                            }

                                    } else {
                                        chartLables.indexOf(q?.node?.answer) === -1 ? chartLables.push(q?.node?.answer) : doNothing();
                                        if (labelCount[q?.node?.answer]) {
                                            labelCount[q?.node?.answer] = labelCount[q?.node?.answer] + 1
                                        } else {
                                            labelCount[`${q?.node?.answer}`] = 1
                                        }
                                    }
                                } else {
                                    // if (columnFilters[q.node.question.id]?.length > 0) {
                                    //     if (columnFilters[q.node.question.id].includes(q?.node?.answer)) {
                                    //         if (labelCount[q?.node?.answer]) {
                                    //             labelCount[q?.node?.answer] = labelCount[q?.node?.answer] - 1
                                    //         } else {
                                    //             labelCount[`${q?.node?.answer}`] = 1
                                    //         }
                                    //     }
                                    // }
                                }
                            
                        }
                    })

                })
            }
        }
        })
        setChartLabels(chartLables)
        setlabelCount(labelCount)
        setChartTitle(html)
        setShowGraph(true)
    }
    
    //titles
    React.useEffect(() => {
        const FilterData = (key) => {
            var filterData = []
            filterData = colData.map(item => ({
                label: item[key],
                value: item[key]
            }))
            const result = filterData.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.label === thing.label && t.value === thing.value
                ))
            )
            const finalData = result.filter((elem) => elem.label !== undefined && elem.value !== undefined);
            return finalData
        }

        
        if (Object.entries(questions).length > 0) {
            const columns = []
            columns.push({
                title: 'Employee',
                width: 100,
                dataIndex: 'name',
                key: 'name',
                onFilter: (value, record) => {
                    return record.key.includes(value)
                    },
                filterDropdown: ({
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters
                }) => (
                    <div style={{ padding: 8 }}>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <ColumnCheckbox
                            name="checkboxgroupEmployee"
                            onChange={(e) => {
                                setSelectedKeys(e);
                            }}
                            value={selectedKeys}
                            style={{ width: 120, marginBottom: 8, display: "block" }}
                            options={colData.map(data => ({
                                label: data.name,
                                value: data.key,
                            }))}
                        />
                        </Space>
                        <Divider style={{ marginBottom: ".5em" }} />
                        <Space>
                            <Button
                                type="link"
                                onClick={() => {
                                    clearFilters();
                                    setSelectedKeys([])
                                    setEmployeeFilter([]);
                                }}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Reset
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    confirm();
                                    setEmployeeFilter(selectedKeys);
                                }}
                                size="small"
                                style={{ width: 90 }}
                            >
                                OK
                            </Button>
                        </Space>
                    </div>
                ),
                sorter: (a, b) => a.name.length - b.name.length,
                sortDirections: ['ascend', 'descend'],
            })
            for (const [key, value] of Object.entries(questions)) {
                columns.push({
                    title:
                        <Panel>
                            <span style={{
                                whiteSpace: 'nowrap',
                                alignItems: 'baseline'
                            }}>
                                <ReactMarkdown style={{ paddingTop: value.length > 45 ? "10px" : "14px" }}>
                                    {value}
                                </ReactMarkdown>
                            </span>
                            <BarChartOutlined onClick={() => { renderGraph(key, value)}} />
                        </Panel>,
                    width: 100,
                    dataIndex: key,
                    key: key,
                    render(text, record) {
                        return {
                          props: {
                            style: { background: text==="Skipped" ? "#f5f5f5" : "#fff" }
                          },
                          children: <div>{text}</div>
                        };
                      },
                    onFilter: (value, record) =>{
                        return [record[key]].indexOf(value) === 0},
                    filterDropdown: ({
                        setSelectedKeys,
                        selectedKeys,
                        confirm,
                        clearFilters
                    }) => (
                        <div style={{ padding: 8 }}>
                                <ColumnCheckbox
                                    name="checkboxgroup"
                                    onChange={(e) => {
                                        setSelectedKeys(e);
                                    }}
                                    value={selectedKeys}
                                    style={{ width: 120, marginBottom: 8, display: "block" }}
                                    options={FilterData(key)}
                                />                          
                            <Divider style={{ marginBottom: ".5em" }} />
                            <Space>
                                <Button
                                    type="link"
                                    onClick={() => {
                                        clearFilters();
                                        setSelectedKeys([])
                                        props.updateColumnFilters(key, undefined)

                                    }}
                                    size="small"
                                    style={{ width: 90 }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        confirm();
                                        if (selectedKeys.length === 0){
                                            props.updateColumnFilters(key, undefined)
                                            return
                                        }                                       
                                        props.updateColumnFilters(key, selectedKeys)
                                        
                                    }}
                                    size="small"
                                    style={{ width: 90 }}
                                >
                                    OK
                                </Button>
                            </Space>
                        </div>
                    ),
                })
            }
            setCols(columns)
            setTableLoader(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions, colData])


    const onSurveyChange = (val) => {
        setShowGraph(false)
        onSurvaychange(val)
        setTableLoader(true)
    }

const onSelectFeildSearch=(value)=>{
    if(value){
        let data =props.surveyData.filter(item=>item.label.toLowerCase().includes(value.toLowerCase()))
        setSurveyList(data)
    }
    else{
        setSurveyList(props.surveyData)
    }
    
}

const close=()=>{
    setShowGraph(false)
}
    return (
        <>
            <Row justify="center">
                <MetaTags title="Report" description={`This is report page`} />
                <Col
                    span={20}
                    style={{
                        border: "1px solid #EBEBEB",
                        borderRadius: 10,
                        padding: "50px 0px",
                        paddingBottom: 80,
                        backgroundColor: "#fff",
                    }}
                >
                    <Spin spinning={loading || surveyLoading} size="large">
                        <div style={{ minHeight: "70vh" }}>
                            {!loading && (
                                <>
                                    <Row>
                                        <>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: 'end' }}>
                                                <Button
                                                    shape="circle"
                                                    onClick={() => history.goBack()}
                                                    style={{ margin: "2em" }}
                                                >
                                                    <ArrowLeftOutlined />
                                                </Button>
                                            </Col>
                                        </>
                                    </Row>
                                </>
                            )}
                            <Row>
                                <Col span={24}>
                                    <Row>
                                        <Col xs={20} sm={12} md={6} lg={6} xl={6} style={{ textAlign: 'start',paddingLeft:"45px" }}>
                                            <SelectField
                                                closable={false}
                                                otherOption={false}
                                                footer={null}
                                                placeholder="Select Survey"
                                                showSearch
                                                style={{width:"230px"}}
                                                onSearch={(e)=>{onSelectFeildSearch(e)}}
                                                choices={surveyList}
                                                onChangeSelect={onSurveyChange}
                                                filterOption={(input, option) =>
                                                    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                notFoundContent={
                                                    surveyLoading || loading  ? (
                                                        <div align="center">
                                                            <Spin size="small" />
                                                        </div>
                                                    ) : (
                                                        <Empty />
                                                    )
                                                } />
                                        </Col>
                                    </Row>
                                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                                        {showGraph &&
                                            <Row justify="center">
                                                <Col span={22} style={{ border: '1px dashed #EBEBEB', padding: " 15px 30px" }}>
                                                    <Col span={24} align="right">
                                                        <Tooltip title="Close The Graph">
                                                            <Button shape="circle"  style={{cursor:"pointer"}}onClick={close}>
                                                                <CloseOutlined />
                                                            </Button>
                                                        </Tooltip>
                                                    </Col>
                                                    <ReportChart chartLables={chartLables} labelCount={labelCount} title={chartTitle}/>
                                                </Col>
                                            </Row>
                                        }
                                        <Row justify="center">
                                            <Col span={22} style={{ 'overflow': 'auto' }}>
                                                <Spin spinning={tableLoader}>
                                                    <Table
                                                        bordered={true}
                                                        style={{ width: '100%' }}
                                                        columns={cols}
                                                        dataSource={colData}>
                                                    </Table>
                                                </Spin>
                                            </Col>
                                        </Row>
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                    </Spin>
                </Col>
            </Row>
        </>
    )
}
export default ReportsComponent;

const Panel = styled(Space)`
display:flex;
flex-direction:row;
justify-content:end;
align-items:center;
gap:10px
`
// @ts-nocheck
import React from "react";
import { Input, Select, Button, Switch, Spin } from "antd";
import styled from "styled-components";
import { Capitalize, validateEmail } from "modules/look";
import close_icon from '../../../assets/dashboard-kudos-close-icon.svg';
import usePrompt from "modules/usePrompt";
const CreateInvitationMultippleEmployeeView = (props) => {

    const { cancel, sendMultippleInvitation, loading, error_on_mutation } = props;
    const [attach_message, SetAttachMessage] = React.useState(false)
    const [invitation_message_attachment, setInvitationMessage] = React.useState()
    const { isDirty, setIsDirty, PromptComponent } = usePrompt("Are you sure you want to leave? You have unsaved changes.");
    let node_ref = React.useRef(1)
    const [formData, setFormData] = React.useState([
        { email: "", firstname: "", lastname: "", respondent: "", invited: false, error: false, error_message: "", node: node_ref?.current || 1 },
    ]);
    const [successTag, SetSuccessTag] = React.useState([])
    const [showtag, SetShowTag] = React.useState(false)
    const [submitted, setSubmitted] = React.useState(false)

    const handleChange = (index, field, value) => {
        const updatedFormData = [...formData];
        updatedFormData[index][field] = field === "email" ? value?.trim() : value?.trim() === "" ? "" : value;
        setFormData(updatedFormData);
        checkValidation(formData);
        localStorage.setItem("formData", JSON.stringify(formData))
    };

    React.useEffect(() => {
        try {
            if (localStorage.hasOwnProperty("formData") && Object.keys(localStorage).includes("err_at") && localStorage.err_at === "multi_invite") {
                setFormData(JSON.parse(localStorage.formData))
            }
            localStorage.removeItem("formData")
            localStorage.removeItem("err_at")
        } catch (error) {
            localStorage.removeItem("formData")
            localStorage.removeItem("err_at")
        }
    }, [])

    React.useEffect(() => {
        if (error_on_mutation?.length) {
            try {
                let err_list = error_on_mutation?.map(i => {
                    return {
                        message: i?.message,
                        index: parseInt(i?.path[0]?.replace('create', ''))
                    }
                })
                handleErrorData(err_list)
            } catch (error) {

            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error_on_mutation])

    const handleErrorData = (list) => {
        let form_list = [].concat(formData)
        form_list.forEach((i, index) => {
            let error_index = list?.find(er => er?.index === i?.node)
            if (error_index) {
                i.invited = false
                i.error = true
                i.error_message = error_index?.message || "failed to send invitation"
            } else {
                i.invited = true
                i.error = false
                i.error_message = ""
            }
        })
        let success = form_list?.filter(i => i?.invited)
        let error = form_list?.filter(i => !i?.invited)
        setFormData(error)
        let success_tag = [...success, ...successTag]
        SetSuccessTag(success_tag)
        if (success_tag?.length) {
            SetShowTag(true)
        } else {
            SetShowTag(false)
        }
        setSubmitted(false)
    }

    const addForm = () => {
        node_ref.current = node_ref.current + 1
        let form_data = [...formData];
        form_data.push({ email: "", firstname: "", lastname: "", respondent: "", invited: false, error: false, error_message: "", node: node_ref.current })
        setFormData(form_data);
        checkValidation(form_data)
    };



    const handleInvitation = () => {
        setIsDirty(false)
        setSubmitted(true)
        let data = formData?.filter(i => !i?.invited)?.map(i => {
            return {
                ...i,
                message: attach_message ? invitation_message_attachment : ""
            }
        })
        sendMultippleInvitation(data)
    }

    const checkValidation = (value) => {
        value.forEach((obj, index) => {
            const mailArray = value.map((ele, i) => i === index ? "current" : ele.email)
            if (obj.email !== "" && mailArray.includes(obj.email)) {
                obj.error = true;
                obj.error_message = "This email is already in use, please enter a different email"
                return;
            }

            if (value.length === 1 && obj.firstname.trim() === '' && obj.lastname.trim() === '' && obj.respondent.trim() === '' && obj.email.trim() === '') {
                obj.error = false;
                obj.error_message = ""
                return;
            }

            if (obj.firstname.trim() === '' || obj.lastname.trim() === '' || obj.respondent.trim() === '' || obj.email.trim() === '') {
                obj.error = true;
                obj.error_message = "Please fill in all the mandatory fields";
                return;
            }

            const nameRegex = /^[a-zA-Zàâäçéèêëîïôöùûüÿñ\s]+$/;
            if (!nameRegex.test(obj.firstname)) {
                obj.error = true;
                obj.error_message = "First name can only contain letters and spaces";
                return;
            }

            if (!nameRegex.test(obj.lastname)) {
                obj.error = true;
                obj.error_message = "Last name can only contain letters and spaces";
                return;
            }

            if (!validateEmail(obj?.email)) {
                obj.error = true;
                obj.error_message = "Please enter a valid email address";
                return;
            }

            if (obj.firstname.length > 150) {
                obj.error = true;
                obj.error_message = "First name cannot exceed 150 characters";
                return;
            }

            if (obj.lastname.length > 150) {
                obj.error = true;
                obj.error_message = "Last name cannot exceed 150 characters";
                return;
            }

            obj.error = false;
            obj.error_message = "";
        })
    }

    const handleRemoveInvite = (indexToRemove) => {
        const newFormData = formData.filter((ele, index) => index !== indexToRemove).map((ele, index) => ({ ...ele, node: index + 1 }))
        node_ref.current = node_ref.current - 1;
        setFormData(newFormData)
        checkValidation(newFormData);
    }

    const handleDisable = () => {
        if (formData.length === 1) {
            if (formData[0].firstname.trim() === '' && formData[0].lastname.trim() === '' && formData[0].respondent.trim() === '' && formData[0].email.trim() === '') {
                if (isDirty && !submitted)
                    setIsDirty(false)
                return true;
            } else {
                if (!isDirty && !submitted)
                    setIsDirty(true)
                return formData[0].error;
            }
        } else {
            const dirty = !(formData.every(obj => obj.firstname.trim() === '' && obj.lastname.trim() === '' && obj.respondent.trim() === '' && obj.email.trim() === '' && true))
            if (isDirty && !dirty && !submitted)
                setIsDirty(false)
            if (!isDirty && dirty && !submitted) {
                setIsDirty(true)
            }
            return dirty ? !formData.every(obj => obj.error === false) : true;
        }
    }

    return (
        <Spin spinning={loading}>
            {
                (successTag?.length && showtag) ?
                    <div className="marquee-container">
                        <div behavior="smooth" direction="" className="marquee-text">
                            <span>successfully invited {successTag?.length} users.</span>
                            {
                                successTag?.map((user, index) => (
                                    <><span > {user?.email}</span> {(index + 1) !== successTag?.length ? ',' : ''}</>
                                ))
                            }

                        </div>
                        <img src={close_icon} onClick={() => { SetShowTag(false) }} alt="" srcset="" />
                    </div>
                    : ''
            }

            <div
                className="invite-main-content-container"
                style={{
                    marginTop: "1em",
                }}
            >
                {formData?.map((field, index) => (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1em",
                            width: "100%",
                            marginTop: "2em"
                        }}
                    >
                        <div className="invite-multipple-employee-list-container">
                            <h4 className="invite-multipple-employee-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <span style={{ whiteSpace: "nowrap" }}>User : {field?.node}</span>
                                {field?.invited && (
                                    <span style={{ color: "rgba(76, 203, 31, 1)", marginLeft: "1em", fontSize: "1em" }}>Invitation sent successfully</span>
                                )}
                                {(!field?.invited && field?.error && isDirty) && (
                                    <span style={{ color: "#E86161", marginLeft: "1em", marginRight: "1em", fontSize: "0.7em", flexGrow: 1 }}>{field?.error_message}</span>
                                )}
                                {(formData.length > 1 || index !== 0) &&
                                    <img
                                        src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1737026967/MApp/UI/SVGs/close-icon-without-bg_zphwya.svg"
                                        alt=""
                                        onClick={() => { handleRemoveInvite(index) }}
                                        style={{ cursor: "pointer" }}
                                    />
                                }
                            </h4>
                        </div>
                        <div className="invite-wrap-container">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: ".5em",
                                    width: "100%",
                                }}
                            >
                                <h4 className="survey-invite-form-title">
                                    <span className="rd-input-star">*</span>First name
                                </h4>
                                <Input
                                    className="survey-invite-form-title-input"
                                    placeholder="Enter first name"
                                    value={Capitalize(field.firstname)}
                                    onChange={(e) =>
                                        handleChange(index, "firstname", e.target.value)
                                    }
                                    autoComplete="off"
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: ".5em",
                                    width: "100%",
                                }}
                            >
                                <h4 className="survey-invite-form-title">
                                    <span className="rd-input-star">*</span>Last name
                                </h4>
                                <Input
                                    className="survey-invite-form-title-input"
                                    placeholder="Enter last name"
                                    value={field.lastname}
                                    onChange={(e) =>
                                        handleChange(index, "lastname", e.target.value)
                                    }
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className="invite-wrap-container">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: ".5em",
                                    width: "100%",

                                }}
                            >
                                <h4 className="survey-invite-form-title">
                                    <span className="rd-input-star">*</span>Email
                                </h4>
                                <Input
                                    className="survey-invite-form-title-input"
                                    placeholder="Enter Email"
                                    value={field.email}
                                    onChange={(e) =>
                                        handleChange(index, "email", e.target.value)
                                    }
                                    autoComplete="off"
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: ".5em",
                                    width: "100%",
                                }}
                            >
                                <h4 className="survey-invite-form-title">
                                    <span className="rd-input-star">*</span>Respondent is my
                                </h4>
                                <CustomSelect
                                    onChange={(e) => {
                                        handleChange(index, "respondent", e)
                                    }}
                                    value={field.respondent !== "" ? field.respondent : null}
                                    placeholder="Select one"
                                >
                                    <Select.Option
                                        className="dashobard-performance-table-heading"
                                        style={{
                                            color: "#A3A3A3",
                                            fontSize: "1em",
                                            fontWeight: "500",
                                        }}
                                        key="manager"
                                        value="manager"
                                    >
                                        Manager
                                    </Select.Option>
                                    <Select.Option
                                        className="dashobard-performance-table-heading"
                                        style={{
                                            color: "#A3A3A3",
                                            fontSize: "1em",
                                            fontWeight: "500",
                                        }}
                                        key="manager of manager"
                                        value="manager of manager"
                                    >
                                        Manager of Manager
                                    </Select.Option>
                                    <Select.Option
                                        className="dashobard-performance-table-heading"
                                        style={{
                                            color: "#A3A3A3",
                                            fontSize: "1em",
                                            fontWeight: "500",
                                        }}
                                        key="report"
                                        value="report"
                                    >
                                        Report
                                    </Select.Option>
                                </CustomSelect>
                            </div>

                        </div>

                    </div>
                ))}
                <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
                    <button
                        className="user-board-welcome-banner-button"
                        style={{
                            height: "2.5em",
                            fontSize: "1.1em",
                            width: "6em",
                            cursor: "pointer",
                        }}
                        onClick={addForm}
                    >
                        + Add
                    </button>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5em",
                            width: "50%",
                            paddingLeft: "1em"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                                gap: "1em",
                            }}
                        >
                            <h4
                                className="survey-invite-form-title"
                                style={{ margin: "0px" }}
                            >
                                Attach message
                            </h4>
                            <Switch onChange={(e) => SetAttachMessage(e)} />
                        </div>
                        {/* <p className="invite-message-content">{message_content}</p> */}
                        {attach_message ? <textarea
                            className="survey-invite-form-title-input"
                            placeholder="Message"
                            style={{ height: "7em", padding: ".5em", resize: "none", outline: "none" }}
                            value={invitation_message_attachment}
                            onChange={e => (setInvitationMessage(e.target.value?.trim() === "" ? "" : e?.target?.value))}
                            autoComplete="off"
                        /> : null}
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        alignItems: "center",
                        gap: "1em",
                        width: "100%",
                    }}
                >
                    <Button className="survey-invite-cancel-btn" onClick={() => cancel()}>
                        CANCEL
                    </Button>
                    <Button
                        className="survey-invite-send-btn"
                        disabled={handleDisable()}
                        onClick={() => handleInvitation()}
                    >
                        SEND INVITE
                    </Button>
                </div>
            </div>
            {PromptComponent}
        </Spin>
    );
};

export default CreateInvitationMultippleEmployeeView;

const CustomSelect = styled(Select)`
  .ant-select-selector {
    border: 1px solid rgba(170, 186, 198, 1) !impoortant;
    color: #000;
    font-weight: 400;
    font-family: Poppins;
    font-size: 1em;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: left;
    border-radius: 0.5em !important;
    width: 100% !important;
    height: 2.7em !important;
  }
`;

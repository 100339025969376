import { message } from 'antd';
import { graphql } from '@apollo/client/react/hoc';
import { EDIT_DASHBOARD_TEAM } from 'modules/home/graphql/teamDashboardQueries.gql';

export const withUpdateTeam = Component =>
  graphql(EDIT_DASHBOARD_TEAM, {
    props: ({ mutate, history }) => ({
        updateTeam: async values => {
        try {
          const {
            data: { updateTeam }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
        //   message.success('Submitted response!!');
          return updateTeam.team;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);
// @ts-nocheck
import React from "react";
import { Row, Button, message, Dropdown, Spin, Form, Tooltip } from "antd";
import InfoCircle from '../../assets/InfoCircle.svg'
import upArrow from '../../assets/up-arrow.svg'
import { getIntFromString } from 'modules/layout/Layout';
import NumberInputField from '../../Objective-keyresult/formComponents/Number-input-field'
import Reset_button from '../../assets/reset-button.svg'
import weight from '../../assets/weight.svg'
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import ButtonWithIcon from "modules/look-v2/Components/buttonWithIcon";
const AddWeightButton = (props) => {
    const { allokrweights, loading, setloading, border, disabled, tooltipTitle } = props

    const [OpenMenu, setOpenMenu] = React.useState(false)
    const [selectedItems, setSelectedItems] = React.useState([])
    const [form] = Form.useForm();
    const [contributor, setContributor] = React.useState()
    const CREATE_CONTRIBUTOR_DYNAMIC = gql`
    mutation updateContributor {
      ${contributor
            ?.map(
                (item, index) =>
                    `update${index}: updateContributor(id:${item?.id},weight:"${item?.weight}") {
            contributor{
              id
            }
           }
         `
            )
            .join('')}
     }
    `;

    // eslint-disable-next-line no-unused-vars
    const [contributorDefinition, { data, isloading, error }] = useMutation(CREATE_CONTRIBUTOR_DYNAMIC);

    React.useEffect(() => {
        if (contributor && contributor?.length) {
            UpdateContributorMutation()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contributor])

    const UpdateContributorMutation = async () => {
        setloading(true)
        const response = await contributorDefinition()
        // new to do send back the response as props
        if (response) {
            setloading(false)
            setSelectedItems([])
            setOpenMenu(false)
        } else {
            setloading(false)
        }

    }
    React.useEffect(() => {
        if (allokrweights) {
            setSelectedItems([])
            let list = allokrweights?.edges?.map(({ node }) => {
                return {
                    id: node?.id,
                    title: node?.okr?.title,
                    okrId: node?.okr?.id,
                    role: node?.role,
                    weight: node?.weight || 0
                }
            })
            setSelectedItems(list)
            setloading(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allokrweights])


    const onSave = () => {
        let values = form.getFieldsValue()
        let noData = Object.values(values).filter((item) => item === undefined || item === null)
        if (noData?.length) {
            message.error('fill in the data')
            return
        }
        let totalweight = Object.values(values)?.reduce((a, b) => a + b, 0)
        if (Math.ceil(totalweight) !== 100) {
            message.error('Total weight should be 100%')
            return
        }
        let list = Object.keys(values)?.map(item => {
            return {
                id: getIntFromString(item),
                role: selectedItems.find(rol => rol?.id === item)?.role,
                weight: values[item]
            }
        })
        setContributor(list)
        // setOpenMenu(false)
    }
    const RestWeight = () => {
        // form.resetFields()
    }

    React.useEffect(() => {
        const fieldsToUpdate = {};
        selectedItems.forEach(item => {
            fieldsToUpdate[item.id] = item.weight;
        });
        form.setFieldsValue(fieldsToUpdate);
    }, [selectedItems, form]);

    const WegihtSection = () => (
        <div>
            {OpenMenu &&

                <div className='custom-menu'>
                    <Row align='middle' style={{ flexDirection: "column", padding: "20px", position: 'relative' }}>
                        <h4 className='menu-head'>Weights<Tooltip title={tooltipTitle} overlayStyle={{ zIndex: 1160 }}><img src={InfoCircle} style={{ marginLeft: "10px" }} alt="" /></Tooltip></h4>
                        <img src={upArrow} alt="" style={{ position: "absolute", top: '10px', right: "10px", cursor: "pointer" }} onClick={() => { setOpenMenu(false) }} />
                        <Button className="reset-btn" onClick={() => !loading ? RestWeight() : ''}><img src={Reset_button} alt="" style={{ marginRight: "5px" }} /> Reset</Button>
                        <Spin spinning={loading}>
                            <div className='select-content-div'>
                                {
                                    selectedItems?.length > 0 ?
                                        <Form
                                            scrollToFirstError={true}
                                            className="formLabel"
                                            form={form}>
                                            {selectedItems?.length > 0 && selectedItems?.map((value, index) => (
                                                <div className='item-div'>
                                                    <div className='selected-item'>
                                                        <p style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{value?.title}</p>
                                                        {/* <img src={CloseIcon} alt="" style={{cursor:'pointer'}} onClick={()=>remove(value?.id)}/> */}
                                                    </div>
                                                    <NumberInputField className="progress-input" style={{ width: "50px", height: "40px" }} type='number' maxValue={100} name={value?.id}></NumberInputField>
                                                </div>
                                            ))}
                                        </Form> : ''
                                }
                            </div>
                        </Spin>
                    </Row>
                    <Row className='bottom-div' align='middle'>
                        <Button className='assign-btn' disabled={!selectedItems?.length || loading} onClick={() => onSave()}>Save</Button>
                    </Row>
                </div>

            }
        </div>
    );
    return (
        <>

            <Dropdown overlay={WegihtSection} visible={OpenMenu} placement="bottomLeft" className="input-filter-dynamic" style={{ border: border && ' 1px solid #595959' }}>
                <Row align="middle" justify="center"><ButtonWithIcon className="generic-button" borderRadius={'8px'} borderColor='#595959' disabled={disabled} icon={weight} background={'#FFFFFF'} onClick={setOpenMenu} label='Weights' /> </Row>
            </Dropdown>
        </>
    )
}
export default AddWeightButton
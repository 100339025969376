import { compose } from "modules/core"
import { withApollo } from '@apollo/client/react/hoc'
import React from 'react';
import TranscriptionView from "../components/TranscriptionView";
import { getIntFromString } from "modules/look";
import moment from "moment-timezone";
import { transcript_grouping_seconds } from "../../../config";
const TranscriptionContainer = props =>  {
    const {meetingById,transcript_message} = props
    const [message_list,setMessageList] = React.useState([])
    const [member_list,setMemberList] = React.useState([])
    React.useEffect(()=>{
        if(meetingById && transcript_message){
            let members = meetingById?.member?.edges?.map(({node})=>{
                return {
                    ...node?.user,
                    user_id : getIntFromString(node?.user.id),
                }
            })
            let user_list = [{user_id:"start"},{user_id:"stop"}].concat(members)
            setMemberList(user_list)
            setMessageList([].concat(handleNewMsg(transcript_message,user_list)))
        }
    },[transcript_message,meetingById])

    const handleNewMsg=(data,members)=>{
      let list = data.map((item,index)=>{return {...item?.results,id:index+1}}).filter(data=>data?.text && data?.text?.trim() !== "").sort((a,b)=>moment(a?.requested_at).unix()-moment(b?.requested_at).unix())
      let user_group = {}
      members.forEach(item=>{
        user_group[`user_${item?.user_id}`] = list?.filter(data=> data?.user_id===item?.user_id)
      })
      let group_msg_data = []
      Object.entries(user_group).forEach(item=>{
        let list_value = item[1]
        let group_id = []
        list_value.forEach(i=>{
            if(i?.info){
                group_msg_data.push({
                    time_Stap:i?.requested_at,
                    msg_data:[i],
                    user_id:i?.user_id,
                    meeting_id:i?.meeting_id,
                    info:i?.info,
                    intiated_user:i?.intiated_user
                })
            }else{
                let start_time = moment(i?.requested_at)
                let end_time = moment(i?.requested_at).add(transcript_grouping_seconds,'second')
                // eslint-disable-next-line array-callback-return
                let group_data = list_value?.filter(value=>{
                    if( !group_id?.includes(value?.id) && moment(value?.requested_at).isSameOrAfter(start_time) && moment(value?.requested_at).isSameOrBefore(end_time)){
                        return value
                    }
                })
                group_id = group_id.concat(...group_data?.map(obj=>obj?.id))
                if(group_data?.length){
                    let sort_list = group_data.sort((a,b)=>moment(a?.requested_at).unix()-moment(b?.requested_at).unix())
                    group_msg_data.push({
                        time_Stap:sort_list[0]?.requested_at,
                        msg_data:sort_list,
                        user_id:sort_list[0]?.user_id,
                        meeting_id:sort_list[0]?.meeting_id,
                        info:sort_list[0]?.info,
                        intiated_user:sort_list[0]?.intiated_user
                    })
                }
            }
            
        })
      })
      let message_list = group_msg_data?.map(data=>{
        return {
            ...data,
            message:data?.msg_data?.map(v=>v?.text)?.join('')
        }
      })
      return message_list?.sort((a,b)=>moment(a?.time_Stap).unix()-moment(b?.time_Stap).unix())
    }

    React.useEffect(()=>{
        if(message_list?.length){
            let container = document.getElementById('transcript-scroll')
            if(container){
                container.scrollTo({top:container.scrollHeight,behavior:"smooth"})
            }
        }
    },[message_list])

    return (
        <TranscriptionView message_list={message_list} member_list={member_list} {...props} />
    )
}

export default compose(withApollo)(TranscriptionContainer)
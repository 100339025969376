// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Row, Button, Spin, Form, Carousel, Popconfirm, Col, Tooltip} from 'antd';
import moment from 'moment-timezone';

import { compose } from '../../../core';
import { InputValuesObject, getIntFromString, getTypeFromBase64String, isJsonString, getMomentUtcToUserTimezone } from '../../../look';
import {
  getFormSectionResponseById,
  withAddSimpleFieldResponceOptimized,
  withEditSimpleFieldResponceOptimized
} from '../../../form-section/containers/FormSectionOperation';
import { withMe } from '../../../user/containers/UserOperations';

import { withCreateResponseOptimized, withEditResponseOptimized } from '../../../survey/containers/SurveyOperations';
import { QuestionCircleOutlined, PlusOutlined, RightOutlined, LeftOutlined } from '@ant-design/icons';
import GenericUiFlowRenderSimpleReferenceField from './GenericUiFlowSimpleReferenceField';
import { useHistory } from 'react-router-dom';

const getQuestionIdFromReferenceFieldId=(referenceField, referenceFieldId)=> {
  return getIntFromString(referenceField?.filter(ref => ref.id === referenceFieldId)[0]?.question?.id);
}

const getResponseIdFromSimpleFieldId=(simpleField, simpleFieldId, idx)=> {
  return (
    getIntFromString(simpleField?.filter(s => s.id === simpleFieldId)[0]?.simpleFieldResponse?.edges[idx]?.node?.id) || false
  );
}

const getSurveyIdFromReferenceFieldId=(referenceField, referenceFieldId)=>{
  return getIntFromString(referenceField?.filter(ref => ref.id === referenceFieldId)[0]?.question?.group?.survey?.id);
}

const getResponseIdFromQuestionId=(referanceFieldResponse, questionId, idx)=> {
  const filteredReferanceFieldResponse = referanceFieldResponse?.filter(
    ref => ref[0]?.answerSet?.edges?.filter(({ node }) => getIntFromString(node.question.id) === questionId).length
  )[0];
  return getIntFromString(filteredReferanceFieldResponse ? filteredReferanceFieldResponse[idx]?.id : null);
}

const GenericUiFlowFormSection = props => {
  const {
    loading,
    me,
    formSectionId,
    createSimpleFieldResponce,
    createResponse,
    getFormSectionResponseById,
    editResponse,
    editSimpleFieldResponce,
    admin = false,
    toolHead,
  } = props;
  const history =useHistory()
  // eslint-disable-next-line no-unused-vars
  const [formSection, setFormSection] = useState(props.formSection);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [addedSimpleField, setAddedSimpleField] = useState([]);
  const [addedReferenceField, setAddedReferenceField] = useState([]);
  const [noOfResponses, setNoOfResponses] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const ref = React.useRef();
  const [form] = Form.useForm();
  const referanceFieldResponse = [].concat.apply(
    [],
    getFormSectionResponseById?.referanceFieldResponse?.map((response, idx) => {
      const uniqueQuesIds = [...new Set(response[0]?.answerSet?.edges?.map(({ node }) => node?.question?.id))];
      return response.length && uniqueQuesIds.length
        ? uniqueQuesIds?.map(id => ({
            id,
            responseDate: response.map(({ responseDate }) => responseDate),
            answer: [].concat.apply(
              [],
              response?.map(({ answerSet }) =>
                answerSet?.edges?.filter(({ node }) => id === node?.question?.id).map(({ node }) => node?.answer)
              )
            )
          }))
        : [];
    }) || []
  );

  const Back =()=>{
    if(toolHead === "Values And Behaviours"){
      history.push('/soul/values')
    }
    else if(toolHead === "Strengths And Behaviour"||toolHead === "Strengths and Behaviour"){
      history.push('/soul/strengths')
    }
    else if(toolHead === "Personality And Behaviours"){
      history.push('/soul/personalities')
    }
    else if(toolHead === "Knowledge, Skills And Behaviours"){
      history.push('/soul/knowledge_and_skills')
    }
    else{
      history.goBack()
    }
  }
  if (!loading) {
    // eslint-disable-next-line no-unused-expressions, array-callback-return
    getFormSectionResponseById?.simpleField?.edges?.map(({ node }) => {
      const responses = node?.simpleFieldResponse?.edges?.length;
      if (node?.simpleFieldResponse?.edges?.length > noOfResponses) setNoOfResponses(responses);
    });
    // eslint-disable-next-line no-unused-expressions, array-callback-return
    getFormSectionResponseById?.referanceFieldResponse?.map((response, idx) => {
      const responses = response.length;
      if (responses > noOfResponses) setNoOfResponses(responses);
    });
  }

  const checkForPossibleMoment = (value) => {
    // For section items are stored as text
    // to display date/ datetime/ time value needs to be of type moment
    // This function tries to moment init the value if any error it returns original value
    if(!value){
      return value
    }
    const mV = getMomentUtcToUserTimezone(value)
    if(mV.isValid()) {
      return mV
    } else {
      return value
    }
  }

  const getInitialValue = idx =>
    [].concat
      .apply(
        [],
        [
          getFormSectionResponseById?.simpleField?.edges?.map(({ node }) => {
            const obj = {};
            switch (node?.inputType) {
              case InputValuesObject.date:
                obj[node.id] = moment(node?.simpleFieldResponse?.edges[idx]?.node?.answer, 'YYYY/MM/DD');
                return obj;
              case InputValuesObject.datetime:
                obj[node.id] = moment(node?.simpleFieldResponse?.edges[idx]?.node?.answer, 'YYYY/MM/DD HH:mm:ss');
                return obj;
              case InputValuesObject.month:
                obj[node.id] = moment(node?.simpleFieldResponse?.edges[idx]?.node?.answer, 'M');
                return obj;
              case InputValuesObject.time:
                obj[node.id] = moment(node?.simpleFieldResponse?.edges[idx]?.node?.answer, 'HH:mm:ss');
                return obj;
              case InputValuesObject.week:
                obj[node.id] = node?.simpleFieldResponse?.edges[idx]?.node?.answer;
                // .week();
                return obj;
              case InputValuesObject.color:
                const answer = node?.simpleFieldResponse?.edges[idx]?.node?.answer.replace(/'/g, '"');
                obj[node.id] = isJsonString(answer) ? JSON.parse(answer || '{}').hex : answer;
                return obj;
              default:
                obj[node.id] = node?.simpleFieldResponse?.edges[idx]?.node?.answer || '';
                return obj;
            }
            // obj[node.id] = node?.simpleFieldResponse?.edges[0].node?.answer;
            // return obj;
          }),
          formSection?.referencefield?.edges
            ?.map(({ node }) => ({
              referencefieldId: node?.id,
              questionId: node?.question?.id
            }))
            ?.map(r => {
              const response = referanceFieldResponse?.filter(res => res?.id === r?.questionId) || [];
              if (response.length > 0) {
                const obj = {};
                obj[r.referencefieldId] = checkForPossibleMoment(response[0].answer[idx]);
                return obj;
              } else {
                return false;
              }
            })
            .filter(r => r)
        ]
      )
      .reduce(function (result, current) {
        return Object.assign(result, current);
      }, {});

  const getResponseDate = idx =>
    [].concat
      .apply(
        [],
        [
          getFormSectionResponseById?.simpleField?.edges?.map(({ node }) => {
            const obj = {};
            obj[node.id] = node?.simpleFieldResponse?.edges[idx]?.node?.responseDate || '';
            return obj;
            // obj[node.id] = node?.simpleFieldResponse?.edges[0].node?.answer;
            // return obj;
          }),
          formSection?.referencefield?.edges
            ?.map(({ node }) => ({
              referencefieldId: node?.id,
              questionId: node?.question?.id
            }))
            ?.map(r => {
              const response = referanceFieldResponse?.filter(res => res?.id === r?.questionId) || [];
              if (response.length > 0) {
                const obj = {};
                obj[r.referencefieldId] = response[0].responseDate[idx];
                return obj;
              } else {
                return false;
              }
            })
            .filter(r => r)
        ]
      )
      .reduce(function (result, current) {
        return Object.assign(result, current);
      }, {});

  const handleSubmit = async (values, idx) => {
    const referencefieldValues = {};
    setSubmitLoading(true);
    try {
      const simpleFieldIds = [];
      await Promise.all(
        Object.keys(values).map(async key => {
          if (getTypeFromBase64String(key) === 'Custom_SimpleFieldType') {
            const simpleFieldResponcesId = getResponseIdFromSimpleFieldId(
              formSection?.simpleField?.edges?.map(({ node }) => node),
              key,
              idx
            );
            if (
              // false
              simpleFieldResponcesId ||
              addedSimpleField.indexOf(key) !== -1
            )
              await editSimpleFieldResponce({
                answer: values[key] || '',
                formSectionId,
                simpleFieldId: getIntFromString(key),
                simpleFieldResponcesId
              });
            else {
              const res = await createSimpleFieldResponce({
                simpleFieldResponceData: {
                  userId: getIntFromString(me.id),
                  formSectionId,
                  simpleFieldId: getIntFromString(key),
                  answer: values[key]
                }
              });
              res && simpleFieldIds.push(key);
            }
          } else {
            referencefieldValues[key] = values[key];
          }
        })
      );
      setAddedSimpleField([...addedSimpleField, ...simpleFieldIds]);

      const referencefieldInput = [];
      Object.keys(referencefieldValues).map(async key => {
        const questionId = getQuestionIdFromReferenceFieldId(
          formSection?.referencefield?.edges.map(({ node }) => node),
          key
        );
        referencefieldInput.push({
          surveyId: getSurveyIdFromReferenceFieldId(
            formSection?.referencefield?.edges.map(({ node }) => node),
            key
          ),
          responseId: getResponseIdFromQuestionId(
            getFormSectionResponseById?.referanceFieldResponse,
            getIntFromString(questionId),
            idx
          ),
          answers: [
            {
              questionId,
              answer: values[key] || '',
              responseDuration: 0
            }
          ]
        });
      });

      if (referencefieldInput.length > 0) {
        const surveyIds = referencefieldInput.map(input => input.surveyId);
        const surveyIdsThatAreDuplicate = [...new Set(surveyIds.filter((v, i) => surveyIds.indexOf(v) !== i))];
        const inputWithUniqueSurveyIds = new Set(
          referencefieldInput.filter((v, i) => surveyIdsThatAreDuplicate.indexOf(v.surveyId) === -1)
        );
        const allRefenrenceFieldInputs = [
          ...inputWithUniqueSurveyIds,
          // eslint-disable-next-line array-callback-return
          ...(surveyIdsThatAreDuplicate.map(sId => {
            const duplicateReferencefieldInput = referencefieldInput.filter(input => input.surveyId === sId);
            if (duplicateReferencefieldInput.length > 1)
              return {
                surveyId: sId,
                responseId: duplicateReferencefieldInput.map(input => input.responseId).filter(Boolean)[0],
                answers: [].concat.apply([], [...duplicateReferencefieldInput.map(input => input.answers)])
              };
          }) || [])
        ];

        const referencefieldObj = [];
        await Promise.all(
          allRefenrenceFieldInputs.map(async input => {

            if (input.responseId || addedReferenceField.filter(rf => rf.surveyId === input.surveyId).length !== 0)
              await editResponse({
                answers: input.answers,
                responseId:
                  input.responseId || addedReferenceField.filter(rf => rf.surveyId === input.surveyId)[0].responseId
              });
            else {
              const res = await createResponse({
                answers: input.answers,
                surveyId: input.surveyId,
                totalDuration: 0
              });
              referencefieldObj.push({
                surveyId: input.surveyId,
                responseId: getIntFromString(res.id)
              });
            }
          })
        );
        setAddedReferenceField([...addedReferenceField, ...referencefieldObj]);
      }
      setSubmitLoading(false);
      // refetch();
    } catch (e) {
      setSubmitLoading(false);
      throw Error(e.message);
    }
  };

  useEffect(() => {
    if (!loading && ref?.current) ref.current.goTo(noOfResponses);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const carouselSettings = {
    arrows: true,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    center:true,
    ref,
    beforeChange: (current, next) => setCurrentIndex(next),
    nextArrow: (
      <div style={{position:'relative'}}>
        <Button style={{position:'absolute',right:'5px'}}  icon={<RightOutlined />}  ghost disabled={currentIndex + 1 === noOfResponses} />
      </div>
    ),
    prevArrow: (
      <div style={{position:'relative'}}>
        <Button style={{position:'absolute',left:'5px'}}  icon={<LeftOutlined />}  ghost disabled={currentIndex === 0} />
      </div>
    )
  };

  const handleNewResponse = () => {
    setNoOfResponses(noOfResponses + 1);
    if(ref.current){
      ref.current.goTo(noOfResponses + 1);
    }
    
  };

  // const simplefieldIds = formSection?.simpleField?.edges?.map(({ node }) => node.id);
  // const referencefieldIds = formSection?.referencefield?.edges?.map(({ node }) => node.id);


  const formSectionComponent = i => {
    const initialValues = getInitialValue(i);
    const value = field => initialValues[Object.keys(initialValues)?.filter(key => key === field.id)[0]];

    return (
      <Form
        initialValues={initialValues}
        onFinish={values => !admin && handleSubmit(values, i)}
        ref={form}
        // required={false}
        // scrollToFirstError={true}
      >
        <div className={`tool-padding-form ${noOfResponses===0?'extra-padding':''}`} style={{width:"100%" }}>
          
          {[
            ...formSection?.simpleField?.edges?.map(({ node }) => ({
              ...node,
              type: 'simplefield'
            })),
            ...formSection?.referencefield?.edges?.map(({ node }) => ({
              ...node,
              type: 'referencefield'
            }))
          ]
            .sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
            .map(field => (
              <GenericUiFlowRenderSimpleReferenceField
                {...field}
                form={form}
                value={value(field)}
                responseDates={() => getResponseDate(i)}
              />
            ))
            }
          <Row justify="center" style={{gap:"20px"}}>
            <Button type="primary" className='submitResponseButton' style={{marginTop:"10px"}} htmlType="submit">
              Submit 
            </Button>
            <Button type="primary" className='submitResponseButton-back' style={{marginTop:"10px"}} onClick={()=>Back()}>
              Go Back
            </Button>
          </Row>
        </div>
      </Form>
    );
  };

  return (
    <div style={{ display: loading && 'grid',minHeight:"30vh" }} className="specific-tool">
      <Spin spinning={loading || submitLoading} size="small" style={{ margin: loading && 'auto' }}>
        {!loading && (
          <>
            <div className='soul-tool-title-section'>
              <div className='tool-web-screen' style={{width:"10px",height:"10px"}}></div>
              <h3 className="soul-tool-title-label">{formSection.title}</h3>
              <Col>
                <Popconfirm
                  openClassName='new-set-pop'
                  title={'This will create new response for this survey.'}
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  onConfirm={handleNewResponse}
                  okText="Yes"
                >
                  <Tooltip title={'Add new response'} placement="left">
                    {/* <Button  icon={<PlusOutlined />} /> */}
                    <Button className='new-set-btn'>
                    <PlusOutlined />
                    <span className='tool-web-screen'>New set</span>
                    </Button>
                  </Tooltip>
                </Popconfirm>
              </Col>
            </div>

            <br />
            {noOfResponses ? (
              <div className='carouselWrapper soul-form-carousel' >
              <Carousel {...carouselSettings}>
                {[...Array(noOfResponses).keys()]?.map(i => formSectionComponent(i))}
              </Carousel>
              </div>
            ) : (
              formSectionComponent(0)
            )}
          </>
        )}
        <br />
      </Spin>
    </div>
  );
};

export default compose(
  withMe,
  getFormSectionResponseById,
  withAddSimpleFieldResponceOptimized,
  withCreateResponseOptimized,
  withEditResponseOptimized,
  withEditSimpleFieldResponceOptimized
)(GenericUiFlowFormSection);

// export default compose(
//   withMe,
//   getFormSectionResponseById,
//   withAddSimpleFieldResponce,
//   withCreateResponse,
//   withEditResponse,
//   withEditSimpleFieldResponce
// )(RenderFormSection);

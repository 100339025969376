import React from "react";
import styled from "styled-components";
import { Row, Col, Button, Modal, Tooltip, Spin } from "antd";
import { withApollo } from "@apollo/client/react/hoc";
import { UsergroupAddOutlined,BookOutlined,ReconciliationOutlined } from "@ant-design/icons";
import { compose } from "../../core";
import {
  displayDataCheck,
  getIntFromString,
  RenderTableWithLayout,
  UserLayout,
  generateReport,
  getOrderBy,
  readableTime,
  displayUtcToUserTimezone,
} from "../../look";
import { withDeleteResponse } from "../../survey/containers/SurveyOperations";
import { subscribeToMyResponses } from "../../survey/containers/SurveySubscriptions";

import ROUTE from "../route";

import {
  withMyResponse,
  withUserFilterUpdating,
  withUserState,
} from "./UserOperations";
import survey_module_route from "modules/survey-module/routes";

const MyResponse = (props) => {
  const {
    loading,
    allResponsesByImplicitUserId,
    client,
    onOrderByChange,
    orderBy,
    deleteResponse,
    onFiltersRemove,
    subscribeToMore,
    navigateRoute,
    loadData,
    onSort
  } = props;
  const [visible, setVisible] = React.useState(false);
  const [currentSize, setCurrentSize] = React.useState(12);
  const [currentPage, setCurrentPage] = React.useState(1);

  React.useEffect(() => {
    const subscribe = subscribeToMyResponses(
      subscribeToMore /* , props.filter */
    );
    return () => subscribe();
  });
  const columns = [
    {
      title: "Survey Name",
      key: "name",
      sorter: () => onOrderByChange(getOrderBy("name", orderBy)),
      render: (text, record) => (
        <>{record && displayDataCheck(record.survey.name)}</>
      ),
    },
    {
      title: "Response Date",
      key: "responseDate",
      sorter: () => onOrderByChange(getOrderBy("responseDate", orderBy)),
      render: (text, record) => (
        <>{displayUtcToUserTimezone(record.responseDate, "ddd MMM DD YYYY, HH:mm")}</>
      ),
    },
    {
      title: "Response Duration",
      key: "responseDuration",
      sorter: () => onOrderByChange(getOrderBy("responseDuration", orderBy)),
      render: (text, record) =>
        record?.responseDuration && (
          <>{`${readableTime(record?.responseDuration)}`}</>
        ),
    },
    {
      title: "Action",
      key: "action",
      sorter: () => onOrderByChange(getOrderBy("action", orderBy)),
      render: (text, record) => (
        <>
          <Row >
          {record &&
            (record?.survey?.reportQuery ? (
              <Button
                type="primary"
                style={{margin:'5px'}}
                onClick={() => {
                  generateReport(
                    getIntFromString(record.id),
                    getIntFromString(record.survey.id),
                    client
                  );
                  setVisible(true);
                }}
                disabled={!record?.survey?.reportQuery}
              >
               <BookOutlined />
              </Button>
            ) : (
              <Tooltip title="This survey does not have a report yet.">
                <Button
                style={{margin:'5px'}}
                  type="primary"
                  onClick={() => {
                    generateReport(
                      getIntFromString(record.id),
                      getIntFromString(record.survey.id),
                      client
                    );
                    setVisible(true);
                  }}
                  disabled={!record?.survey?.reportQuery}
                >
                  <BookOutlined />
                </Button>
              </Tooltip>
            ))}
          {
            record && (
              record?.survey?.multisourceResponse ? (
                <Tooltip  title="Invite users">
                <Button
                style={{margin:'5px'}}
                type="primary"
                onClick={()=>{navigateRoute( survey_module_route.survey_360?.replace(":surveyid",getIntFromString(record?.survey?.id))?.replace(":responseid",getIntFromString(record?.id)))}}
              >
                <UsergroupAddOutlined />
              </Button>
              </Tooltip>
              ):
              <Tooltip title="This survey does not have 360.">
              <Button
              style={{margin:'5px'}}
              type="primary"
              disabled={true}
            >
              <UsergroupAddOutlined />
            </Button>
            </Tooltip>
            )
          }

          {/* report  */}

          {
            record && (
              record?.survey?.multisourceResponse ? (
                <Tooltip  title="View 360 survey report.">
                  <Button
                  style={{margin:'5px'}}
                  type="primary"
                  onClick={()=>{navigateRoute( survey_module_route.survey_360_report?.replace(":surveyid",getIntFromString(record?.survey?.id)).replace(":responseid",getIntFromString(record?.id)))}}
                  >
                  <ReconciliationOutlined />
                  </Button>
                </Tooltip>
              ):
              <Tooltip title="This survey does not have 360 report.">
              <Button
              style={{margin:'5px'}}
              type="primary"
              disabled={true}
            >
              <ReconciliationOutlined />
            </Button>
            </Tooltip>
            )
          }

          </Row>
        </>
      ),
    },
  ];

  const EmailSent = () => (
    <>
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: "center" }}>
        {/* <MailSent>
          <CheckCircleFilled style={{ color: '#009c4a', fontSize: '25px' }} /> &nbsp; Email Sent! <br />
        </MailSent> */}
        <h3>
          This could take a minute.
          <br />
          Generated report will be sent to your registered email.
        </h3>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                setVisible(false);
              }}
              size="middle"
            >
              Close
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Button
              type="primary"
              ghost
              onClick={() => setVisible(false)}
              size="middle"
            >
              Close
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const fetchMorex = (pagination) => {
    loadData(pagination, '')
    setCurrentSize(pagination['first'])
    setCurrentPage((pagination['offset'] + pagination['first']) / pagination['first'])
  }

  return (
    <Spin spinning={loading}>
      {!loading && allResponsesByImplicitUserId && (
        <UserLayout active={ROUTE.myResponse} history={props.history}>
          <RenderTableWithLayout
            loading={loading}
            title={"My Workplace MApp Report"}
            tableData={allResponsesByImplicitUserId}
            columns={columns}
            onDelete={deleteResponse}
            handleDrawer={() => setVisible(!visible)}
            onFiltersRemove={onFiltersRemove}
            onPaginationChange={fetchMorex}
            onSort={onSort}
            currentSize={currentSize}
            currentPage={currentPage}
            // addLink={'sifoweim'}
            // editLink={'somehign'}
            // showBack={true}
          />
          <Modal
            closable={false}
            footer={null}
            centered
            visible={visible}
            onCancel={() => setVisible(false)}
            bodyStyle={{ padding: "0px" }}
          >
            <ModalBody>
              <EmailSent />
            </ModalBody>
          </Modal>
        </UserLayout>
      )}
    </Spin>
  );
};

export default compose(
  withUserState,
  withApollo,
  withMyResponse,
  withDeleteResponse,
  withUserFilterUpdating
)(MyResponse);

const ModalBody = styled.div`
  padding: 24px 48px;
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;

import React from 'react';
// import { Button, Col, Dropdown, Menu, Row } from 'antd';
// import { PlusOutlined } from "@ant-design/icons";
import chat from '../../assets/chat.svg'
import MessageContainer from 'modules/Objective-keyresult/container/MessageContainer';
const ChatBox = (props) => {
    const { 
        // label, onAdd, 
        okrId, contributors } = props
    const [chatBox, setChatBox] = React.useState(false);
    const ref = React.useRef(null);


    // React.useEffect(() => {
    //     const checkIfClickedOutside = e => {


    //         if (chatBox && ref.current && !ref.current.contains(e.target)) {
    //             setChatBox(false)
    //         }
    //     }

    //     document.addEventListener("mousedown", checkIfClickedOutside)

    //     return () => {
    //         document.removeEventListener("mousedown", checkIfClickedOutside)
    //     }
    // }, [chatBox])

    // eslint-disable-next-line no-unused-vars
    const MenuDropDown = () => (
        <div ref={ref} >
           
        </div>
    )


    return (

        <>
      
           <div className={chatBox?'chat-overlay':''} onClick={(e)=>chatBox?setChatBox(false):''}>
           <div className={`chat-outer-container ${chatBox?'chat-expand':''}`} >
                <div style={{position:"relative",width:"100%"}}  >
                    <img
                    onClick={(e) => setChatBox(!chatBox)}
                    src={chat} 
                    className='chat-icon'
                    alt=""
                    />
                    {chatBox && (
                        
                            <MessageContainer setChatBox={(e)=>{setChatBox(e)}} {...props} okrId={okrId} contributors={contributors} />
                        
                    )}
                </div>
                
            </div>
           </div>

        </>
    )
}
export default ChatBox
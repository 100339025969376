// @ts-nocheck
import { Col, Modal, Radio, Row, Spin } from "antd";
import { getEmployeeBase64StringFromInt } from "modules/look";
import DoughntChartCascaded from "modules/look-v2/Components/DoughnutchartCascaded";
import DoughntChartNonCascaded from "modules/look-v2/Components/DoughnutChartNonCascaded";
import PaginationDropdown from "modules/look-v2/Components/PaginantionDropdowm";
import PerformanceBar from "modules/look-v2/Components/PerformanceBar";
import React from "react";
import styled from "styled-components";
import modal_close from '../../assets/modal-close-icon.svg'

const TeamOkrPerformancePopupView =(props)=>{
    const {getOkrPerformanceData,memberPerformance,visible,getAllCascadedObjectives,getAllNonCascadedObjectives,onClose,teamDetail,getTeamMembersList, dateRangeTofilter}=props
    const [donutTab, setdonutTab] = React.useState('Cascaded');
    const {cascadedOkr,NoncascadedOkr,PerformanceData, barloader,donutloader,me,getCascardRecord,getNonCascadeRecord}=props
    const [performance,setPerformance]=React.useState([])
    const [PerformanceTab, setPerformanceTab] = React.useState('Team');

    let current_result_count = React.useRef({
        Team:5,
        Memebers:5
      })

      React.useEffect(()=>{
        if( performance && dateRangeTofilter){
          current_result_count['current']={
            Team:5,
            Memebers:5
          }
          setPerformanceTab('Team')
        }
      },[ performance,dateRangeTofilter])
    React.useEffect(()=>{
        let performamceData=[]
        if(PerformanceData){
            performamceData= PerformanceData?.edges.map(({node})=>(
                {
                    id:node?.okr?.id,
                    title:node?.okr?.title,
                    progress:node?.progress,
                    okrType:node?.okr?.okrType,
                    isOkr:true
                }
            ))
            setPerformance(performamceData)
          
        }
    },[PerformanceData])

 
    const changeTab=(event)=>{
        setPerformanceTab(event?.target?.value)
        if(event?.target?.value==='Memebers' &&!memberPerformance ){
            getTeamMembersList(5,0,false)
        }
        
  
    }
    const filterData=(first)=>{
        if(PerformanceTab==='Team'){
            getOkrPerformanceData(first,0,false)
            current_result_count['current'].Team=first
        }else{
            getTeamMembersList(first,0,false) 
            current_result_count['current'].Memebers=first
        }
      }
    const changedonutTab=(event)=>{
        setdonutTab(event?.target?.value)
        if(event?.target?.value==='Cascaded' && !cascadedOkr){
            getCascardRecord(me?.employee?.id,0)
        }
        else if(event?.target?.value==='Non_Cascaded' && !NoncascadedOkr){
            getNonCascadeRecord(me?.employee?.id,0)
        }
        
    }
    
    return (
        <>
        <Modal
             footer={null}
             visible={visible}
             closable={false}
             destroyOnClose={true}
             centered
             className="custom-performance-modal"
             >
                
                <CustomModalBody>
                    <Row justifyContent='space-between' align="middle">
                    <h4 className="chart-title" style={{textAlign:"left",textOverflow:'ellipsis',whiteSpace: 'nowrap',overflow: 'hidden',paddingRight: '40px'}}>{teamDetail?.name}’s OKR Performance</h4>
                    <img src={modal_close} alt=""style={{position:"absolute",right:"10px",top:"10px",cursor:"pointer"}} onClick={()=>onClose()}/>
                    </Row>
                
                    <Spin spinning={barloader}>
                    <Row align="center" style={{gap:"30px",width:'100%'}}>
                        <Col lg={13}>
                            <Row justify="space-between" style={{margin:"20px 0px",width:"100%"}}>
                                <Col>
                                    <Radio.Group className="ModalRadioGroup" value={PerformanceTab} onChange={(e) => changeTab(e)}>
                                        <RadioButton style={{borderRight:"none",width:"80px"}} className={`section-cascade ${PerformanceTab==='Team'?'active-cascade':''}`} value="Team">Team</RadioButton>
                                        <RadioButton style={{width:"110px"}}  className={`section-non-cascade ${PerformanceTab ==='Memebers'?'active-non-cascade':''}`} value="Memebers">Members</RadioButton>
                                    </Radio.Group>
                                </Col>
                                {(PerformanceTab==='Team' &&PerformanceData?.totalCount)?<PaginationDropdown current={current_result_count['current'].Memebers} onselect={(e)=>{filterData(e)}} totalCount={PerformanceData?.totalCount}/>:''}
                            {(PerformanceTab==='Memebers' && memberPerformance?.totalCount)?<PaginationDropdown current={current_result_count['current'].Team} onselect={(e)=>{filterData(e)}} totalCount={memberPerformance?.totalCount}/>:''}
                            </Row>
                            {/* <div style={{background: "#FFFFFF",boxShadow:" -1px 3px 66px -25px rgba(0, 0, 0, 0.17)",maxHeight:"400px",width:"100%",padding:"10px 20px"}}>
                                
                                {PerformanceTab==='Team'&&<PerformanceBar Height={'350px'} {...props} performanceData={performance}/>}
                                {PerformanceTab==='Memebers'&&<PerformanceBar Height={'350px'} {...props} performanceData={memberPerformance?.edges}/>}
                            </div> */}
                            <div  style={{background: "#FFFFFF",boxShadow:" -1px 3px 66px -25px rgba(0, 0, 0, 0.17)",maxHeight:"500px",width:"100%",padding:"10px 20px",minHeight:'400px',alignItems:"end",justifyContent:'end',flexDirection:"column"}}>
                                    {
                                        performance&&PerformanceTab==='Team' && (
                                            
                                        performance?.length>0?
                                            <PerformanceBar  {...props} performanceData={performance||[]}/>:
                                            <h4 style={{textAlign:'center',fontSize:"17px",marginTop:"180px"}}>No Records found</h4>
                                            
                                        )
                                    }
                                    {
                                    memberPerformance?.edges&&PerformanceTab==='Memebers' && (
                                        
                                        memberPerformance?.edges?.length?
                                        <PerformanceBar  {...props} performanceData={memberPerformance?.edges||[]}/>:
                                        <h4 style={{textAlign:'center',fontSize:"17px",marginTop:"180px"}}>No Records found</h4>
                                        
                                    )
                                    }
                                </div>
                       
                        </Col>
                        <Col lg={10} style={{maxHeight:"380px",width:"100%",padding:"10px"}}>
                            <Spin spinning={donutloader}>
                                <div style={{minHeight:"360px"}}>
                                <Row align="middle" style={{marginBottom:"20px",marginTop:"10px",width:"100%"}}>
                                    <CustomCol>
                                        <Radio.Group value={donutTab} onChange={(e) => changedonutTab(e)}>
                                            <RadioButtonCascaded style={{borderRight:"none"}} className={`section-cascade-modal ${donutTab==='Cascaded'?'active-cascade-modal':''}`} value="Cascaded">Cascaded</RadioButtonCascaded>
                                            <RadioButtonNonCascaded   className={`section-non-cascade-modal ${donutTab ==='Non_Cascaded'?'active-non-cascade-modal':''}`} value="Non_Cascaded">Non Cascaded</RadioButtonNonCascaded>
                                        </Radio.Group>
                                    </CustomCol>
                                </Row>
                                <Row align="middle" style={{width:"100%",flexDirection:"column"}}>
                                    {donutTab==='Cascaded'?
                                
                                    <DoughntChartCascaded
                                    {...props}
                                    getNextSetOfCascadedOkrs={(e,offset)=>getAllCascadedObjectives(e,offset,true)}
                                    label={'Cascaded Objectives'}
                                    data={cascadedOkr}
                                    height='200px'
                                    topValue = '137px'
                                    rightValue = '90px'
                                    fontSize = '14px'
                                    responsiveFontSize = '10px'
                                    responsiveTopValue = '87px'
                                    responsiverightValue = '52px'
                                    managerids={teamDetail?.managerId?.map(id=> getEmployeeBase64StringFromInt(id) ) || []}
                                    loading={donutloader}
                                />
                                    :
                                
                                    <DoughntChartNonCascaded
                                    {...props}
                                    getNextSetOfNonCascadedOkrs = {(e,offset)=> getAllNonCascadedObjectives(e,offset,true)}
                                    label={'Non cascaded Objectives'}
                                    data={NoncascadedOkr}
                                    height='200px'
                                    topValue = '137px'
                                    rightValue = '90px'
                                    fontSize = '14px'
                                    responsiveFontSize = '10px'
                                    responsiveTopValue = '87px'
                                    responsiverightValue = '52px'
                                    managerids={teamDetail?.managerId?.map(id=> getEmployeeBase64StringFromInt(id) ) || []}
                                    loading={donutloader}
                                />
                                    }
                                </Row>
                                </div>
                            </Spin>
                        </Col> 
                    </Row>
                    </Spin>
                    </CustomModalBody>
            </Modal>
        </>
    )
}

const RadioButton = styled(Radio.Button)`
`;

const RadioButtonCascaded = styled(Radio.Button)`
width:110px;
@media(max-width:380px){
    width:80px;
  font-size:9px;
}

`;

const RadioButtonNonCascaded = styled(Radio.Button)` 
  width:130px;
  @media(max-width:380px){
    width:100px;
  font-size:9px;
}
`;


export default TeamOkrPerformancePopupView

const CustomCol = styled(Col)`
padding-left:90px;
@media(max-width:350px){
    padding-left:0px;
}

@media  (min-width:351px) and (max-width:420px){
    padding-left:20px;
}

@media  (min-width:421px) and (max-width:600px){
    padding-left:85px;
}

@media  (min-width:601px) and (max-width:1200px){
    padding-left:35px;
}

@media  (min-width:1201px) and (max-width:1400px){
    padding-left:85px;
}

@media  (min-width:1800px){
    padding-left:190px;
}

`;


const CustomModalBody = styled.div`
padding:0px 40px;
width:100%;
position:relative;
@media(max-width:1024px){
    padding:0px;
}
`;


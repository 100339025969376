import { Button, Modal, Spin } from "antd";
import React from "react";


const DeleteEmployeeView = (props) => {
    const { delete_user, onCancel, onDelete, delete_loading } = props

    return (
        <>

            <Modal
                visible={true}
                centered
                footer={false}
                destroyOnClose={true}
                closable={false}
                style={{ maxWidth: "43%" }}
                width={"90%"}>
                <Spin spinning={delete_loading}>
                    <div style={{ display: "flex", flexDirection: 'column', gap: '1em', padding: '1em' }}>
                        <h4 className="poc-dashboard-card-title" style={{ fontSize: "1.5em", fontWeight: 600, whiteSpace: "normal" }}>{delete_user?.email} will no longer be able to login to this account. Are you sure you want to continue?</h4>
                        <div style={{ width: "100%", display: 'flex', gap: "1em", justifyContent: 'end', alignItems: "center", marginTop: "1em" }}>
                            <Button className="organization-cancel-btn" onClick={() => onCancel()}>Cancel</Button>
                            <Button className="organization-delete-btn" onClick={() => onDelete(delete_user)}>Remove Employee</Button>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    )
}

export default DeleteEmployeeView;
import React from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { Spin, message } from 'antd';

import { compose } from '../../core';
import { getItem, setItem } from '../../core';

import LoginView from '../components/LoginView';
import ROUTE from '../route';

import { withLogin } from './UserOperations';
import SendEmailVerify from './SendEmailVerify';
import { validateEmail } from '../../look';
import HOME_ROUTE from '../../home/route';
import PasswordSet from './PasswordSet';
import { ACCESS_TOKEN } from 'config';
import { REFRESH_TOKEN } from 'config';

const Login = props => {

  const { setSubscriptionClient } = props


  const [ jwt, setJwt ] = React.useState( null );
  const [ user, setUser ] = React.useState( {} );
  const [ loading, setLoading ] = React.useState( false );
  const [ incorrectPass, setIncorrectPass ] = React.useState( false );
  const [ nonFieldErrors, setNonFieldErrors ] = React.useState( null );
  const { login, history, location /* , passwordReset */ } = props;
  const [ emailVerify, setEmailVerify ] = React.useState( false );
  // const [userData, setUserData] = React.useState(null);
  const [ email, setEmail ] = React.useState( null );
  const [ username, setUsername ] = React.useState( null );
  const [ counter1, setCounter1 ] = React.useState( true );
  const [ msg, setMsg ] = React.useState( false );
  const handlePasswordResetState = React.useRef( () => { } );
  const handleLoggedIn = React.useRef( () => { } );

  handleLoggedIn.current = async () => {
    const storedJwt = await getItem( ACCESS_TOKEN );
    setTimeout( 3000, setJwt( storedJwt ) );
  };

  handlePasswordResetState.current = async () => {
    setMsg( true );
  };

  React.useEffect( () => {
    handleLoggedIn.current();
  }, [] );

  // React.useEffect(() => {
  //   const pathnameArray = location.pathname.split('/');

  //   if (counter && pathnameArray.indexOf('login-after-token-survey') > -1) {
  //     handlePasswordResetState.current();
  //   }
  // });

  
  
  

  const handleSubmit = async values => {
    const isValidEmail = validateEmail( values.email );
    setIncorrectPass( false );
    setLoading( true );
    if ( isValidEmail ) setEmail( values.email );
    else setUsername( values.email );

    try {
      delete values.remember;
      const response = await login( isValidEmail ? values : { username: values.email, password: values.password } );
      // authentication.doLogin({ token: response.token, tokenExpiry: response.payload.exp });
      if ( response.success ) {
        if ( typeof window !== 'undefined' ) {
          setItem( ACCESS_TOKEN, response.token );
          setItem( REFRESH_TOKEN, response.refreshToken );
        }
        setSubscriptionClient(response.token)
        if ( counter1 ) {
          setUser( response.user );
          setJwt( response.token );
          setCounter1( false );
        }
        message.success( 'Logged you in!' );
      } else {
        if (
          response.errors.nonFieldErrors.length > 0 &&
          response.errors.nonFieldErrors[ 0 ].message === 'Please verify your account.'
        ) {
          setEmailVerify( true );
          // sendVerificationEmail(values.email, values.username);
        }
        message.error( 'Login failed' );
      }
      if ( response.errors ) {
        setNonFieldErrors( response.errors.nonFieldErrors );
      }
    } catch ( e ) {
      
      setIncorrectPass( true );

      // throw new Error(e.message);
    }
    setLoading( false );
  };
  if (
    user &&
    user !== {} &&
    // !(user && Object.keys(user).length === 0)
    jwt 
  ) {
    const emailRoute = JSON.parse(localStorage.getItem("emailRoute"));
    const redirectedFrom = localStorage.getItem("redirected_from");

    if(emailRoute){
      history.push( emailRoute?.route ) 
    }else if(redirectedFrom){
      history.push(redirectedFrom)
    }else{
      msg ? 
      history.push( ROUTE.myResponse ) 
      :
      history.push( HOME_ROUTE.userdashboard, { from: location.pathname } );
    }
    setTimeout(() => localStorage.removeItem("redirected_from"), 2000)
  }


  
  return (
    <Spin spinning={loading} size="large">
      {( email || username ) && (
        <SendEmailVerify
          visible={emailVerify}
          setVisible={setEmailVerify}
          
          /* userData={userData} */ email={email}
          username={username}
        />
      )}
      <PasswordSet location={location} />
      <LoginView
        msg={msg}
        nonFieldErrors={nonFieldErrors}
        incorrectPass={incorrectPass}
        {...props}
        onSubmit={handleSubmit}
      />
    </Spin>
  );
};

export default compose( withApollo, withLogin )( Login );

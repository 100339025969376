// @ts-nocheck
import React from 'react';
import styled from 'styled-components';

const ProgressCounterBtn = props => {
  const { idIndex, timeLimit = 5, btn: Btn } = props;
  const [timesup, setTimesup] = React.useState(false);
  const progressBarId = `progressBtn${idIndex}`;
  const baseTimerLabelId = `base-timer-label${idIndex}`;
  const [timeLeft, setTimeLeft] = React.useState(timeLimit);
  const TIME_LIMIT = timeLimit;

  let timerInterval = null;

  function onTimesUp() {
    clearInterval(timerInterval);
    setTimeLeft(TIME_LIMIT);
    setTimesup(true);
  }
  
  function startTimer() {
    timerInterval = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      // document.getElementById(`${baseTimerLabelId}`).innerHTML = /* formatTime */ timeLeft;
      // setCircleDasharray();
      // setRemainingPathColor(timeLeft);

      if (timeLeft === 0) {
        setTimesup(true);
        onTimesUp();
      }
    }, 1000);
  }

  React.useEffect(() => {
    if (timeLeft !== -1) {
      startTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return (
    <>
      <div style={{ position: 'relative', display: timesup && 'none' }} id={progressBarId}>
        <div className="base-timer">
          <svg className="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g className="base-timer__circle">
              <Newcircle cx="50" cy="50" r="45" pathColor={timeLeft}></Newcircle>
              <path
                strokeDasharray="283"
                // className={`base-timer__path-remaining ${remainingPathColor}`}
                pathColor="ff0000red"
                d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
              "
              ></path>
            </g>
          </svg>
          <span id={baseTimerLabelId} className="base-timer__label">
            {/* formatTime */ timeLeft}
          </span>
        </div>
      </div>
      <Btn style={{ display: !timesup ? 'none' : 'block' }} />
    </>
  );
};

export default ProgressCounterBtn;
const Newcircle = styled.circle`
  stroke: ${props => (props.pathColor <= 5 ? '#ff0000' : props.pathColor <= 10 ? '#ffa500' : 'rgb(65, 184, 131)')};
  stroke-width: 7px;
  /* stroke: rgb(65, 184, 131); */
  /* stroke: ${props => props.pathColor}; */
`;


import { Button } from "antd";
// import { ReactMarkdown } from "modules/look";
import React from "react";
import './uiFlowToolMobile.css';
const ImpactNarrativeMobileTab = (props) => {
    const { content } = props
    const [selected_item, setItem] = React.useState()

    React.useEffect(() => {
        if (content && content?.length) {
            setItem(content[0])
        }
    }, [content])

    


    return (
        <>
            <div className="slide-tab-container">
                {content?.map((value, idx) => (
                    <Button className={`slide-btn ${value?.key === selected_item?.key ? 'active-slide-btn' : ""}`} onClick={() => { setItem(value) }}>{value?.title}</Button>
                ))}
            </div>
            {
                selected_item && (
                    <div className="mobile-impact-card">
                        <div className="impact-text-container" >
                            <h4>{selected_item?.text}</h4>
                        </div>
                        <div className="hidden" >
                            <h4>{selected_item?.text}</h4>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ImpactNarrativeMobileTab;
import { ThemeProvider } from '../../look/themeProvider/ThemeProvider';
import React, { useState } from 'react';
import { compose } from '../../core';
import themeConfig from '../theme/themeConfig';
import {
    withCreateResponseOptimized,
    withEditResponseOptimized,
} from './SurveyOperations';
import { SURVEY_BY_ID_GROUP_SET_QUERY } from '../graphql/SurveyByIdQuery.gql';
import { withApollo } from '@apollo/client/react/hoc';
import { getQuestionBase64StringFromInt, getSurveyBase64StringFromInt, globalPermissionValidator } from '../../look'
import { Spin } from 'antd';
import { soul_permission } from 'Permissions/soul.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { PERSONALITY_PART_1,SOUL_SURVEY_RESPONSE_TOOLS,VALUE_SUERVEY_PART_1,STRENGTH_PART_1,KNOWLEDGE_PART_1,diversity_survey_id,diversity_questions_for_in } from '../../../config';
import { COMPLETE_SOUL_SURVEY } from 'modules/page/graphql/CompletedSurvey.gql';

const EditSurveyResponseContainerQuestionBased = props => {
    const {
        me,
        client, userPermission, permissionLoader
    } = props;
    const [ SurveyById, setSurvey ] = React.useState()
    // question and current answer 
    const [ qa, setQa ] = useState( [] )
    const [ allOpts, setAllOpts ] = useState( {} )
    const [soulPermitted, setSoulPermitted] = React.useState(false)
    const [isSoulTool,setIsSoulTool] = React.useState(true);
    //surveyId
    const [ sId, setSId ] = useState( "" )
    const [ responseId, setResponseId ] = useState( "" )
    const [ ui, setUi ] = useState( "SURVEY_EDIT_QUESTION_BASED_SELECT_NEW" )
    const [survayLoading,setSurveyLoading]=React.useState(true)
    const [soul_survey_completion,SetSoulSurveyCompletion] = React.useState()
    //survey name
    const [ sname, setSName ] = useState( "" )

    


    React.useEffect(() => {
        setSName(`Enter your top 5 ${props.match.params.surveyType.replaceAll('-', ' ') }`)
    }, [props])

    React.useEffect(()=>{
       
        if(props.match.params.id){
         let soulTool = SOUL_SURVEY_RESPONSE_TOOLS?.includes(parseInt(props.match.params.id))
         setIsSoulTool(soulTool)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[props.match.params?.id])

      React.useEffect(() => {
        if (userPermission && !permissionLoader && isSoulTool) {
          let soulPermission = globalPermissionValidator(soul_permission, userPermission);
          setSoulPermitted(soulPermission?.required_permission)
        }
      }, [userPermission,permissionLoader,isSoulTool])  

    React.useEffect( () => {
        if ( SurveyById ) {
            let responseData = []
            let choiceSet = {}
            let resId = ""
            /* eslint-disable no-unused-expressions */
            setSId( SurveyById?.id )
            // setSName( SurveyById?.name )
            setUi( SurveyById?.uiFlow || "SURVEY_EDIT_QUESTION_BASED_SELECT_NEW" )
            SurveyById?.groupSet?.edges?.forEach( ( n ) => {
                let questionSet = n.node.questionSet;
                questionSet?.edges?.forEach( ( qs ) => {
                    let q = qs?.node?.questionText
                    let id = qs?.node?.id
                    let a = []
                    let d = {}
                    d[ 'q' ] = q
                    d[ 'id' ] = id
                    d[ 'required' ] = qs?.node?.required
                    qs?.node?.choiceSet?.edges?.forEach( ( cs ) => {
                        a.push( { 'label': cs?.node?.label, 'value': cs?.node?.value } )
                        choiceSet[ id ] = a
                    } )
                    qs?.node?.answerSet?.edges?.forEach( ( as ) => {
                        d[ 'a' ] = as?.node?.answer
                        resId = as?.node?.response?.id
                    } )
                    responseData.push( d )
                } )
            } )
         
            setResponseId( resId )
            setQa( responseData )
            // if(responseData?.length>=5 && getIntFromString(SurveyById?.id) != PERSONALITY_PART_1){
            //     let ans=responseData?.map(i=>i?.a)
            //     let choice ={}
            //     Object.entries(choiceSet).forEach(j=>{
            //         choice[j[0]]=j[1]?.filter(k=>ans?.includes(k?.value))
            //     })
            //     choiceSet=choice
            //   }
            setAllOpts( choiceSet )
        }
    }, [ SurveyById ] )

    const getSurveyDetail = async ( value ) => {
        const { data } = await client.query( {
            query: SURVEY_BY_ID_GROUP_SET_QUERY,
            variables: value,
            fetchPolicy: 'network-only'
        } );

        if ( data?.SurveyById ) {
            setSurvey( data?.SurveyById )
        }
        setTimeout(()=>{
            setSurveyLoading(false)
        },1000)

    }

    React.useEffect( () => {
        if ( me && soulPermitted && isSoulTool ) {
            let id = getSurveyBase64StringFromInt( props.match.params.id );
            let responseUser = me?.id
            getSurveyDetail( { id, responseUser } )
        }else if(!isSoulTool){
            let id = getSurveyBase64StringFromInt( props.match.params.id );
            let responseUser = me?.id
            getSurveyDetail( { id, responseUser } )
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ me ,soulPermitted, isSoulTool] )


    React.useEffect(()=>{
        if(me){
            GetSoulSurveysCompletedCount(me?.employee?.user?.id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[me])

    const GetSoulSurveysCompletedCount = async (userid) => {
        try {
          const { data } = await client.query({
            query: COMPLETE_SOUL_SURVEY,
            variables: {
              user: userid,
              value: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
              strength: getSurveyBase64StringFromInt(STRENGTH_PART_1),
              personality: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
              knowledge: getSurveyBase64StringFromInt(KNOWLEDGE_PART_1),
              diversity: getSurveyBase64StringFromInt(diversity_survey_id),
              gender: getQuestionBase64StringFromInt(diversity_questions_for_in?.gender),
              HighestDegree: getQuestionBase64StringFromInt(diversity_questions_for_in?.HighestDegree),
              YearsOfExperience: getQuestionBase64StringFromInt(diversity_questions_for_in?.YearsOfExperience),
              CurrentNationality: getQuestionBase64StringFromInt(diversity_questions_for_in?.CurrentNationality)
            },
            fetchPolicy: 'network-only'
          });
      
          if (data) {
            let diversity = data?.diversity?.edges[0]?.node
            let diversity_completed = false
            if(diversity && diversity?.CurrentNationality?.totalCount && diversity?.HighestDegree?.totalCount && diversity?.YearsOfExperience?.totalCount && diversity?.gender?.totalCount){
                diversity_completed = true
            }
            let detail = {
                ...data,
                diversity:{
                    totalCount:diversity_completed?1:0
                }
            }
            SetSoulSurveyCompletion(detail)
          }
        } catch (error) {
          
        }
    }


    return (

         <>

         {
             !permissionLoader && 
             <div>
                {
                    ((!soulPermitted && isSoulTool)||(SurveyById==null&&!survayLoading)) ?
                    <NoPermissionView content={"You Don't Have Permission To View This Survey."}/>
                    :
                     ( soulPermitted && isSoulTool&&SurveyById&&!survayLoading) ?
                     <ThemeProvider
                         {...props}
                         soul_survey_completion={soul_survey_completion}
                         config={themeConfig}
                         ui={ui}
                         sname={sname}
                         qa={qa}
                         allOpts={allOpts}
                         sId={sId}
                         responseId={responseId}
                         SurveyById={SurveyById}
                     />
                   :
                   
                   (SurveyById!=null && !isSoulTool) ?
                   <ThemeProvider
                         {...props}
                         soul_survey_completion={soul_survey_completion}
                         config={themeConfig}
                         ui={ui}
                         sname={sname}
                         qa={qa}
                         allOpts={allOpts}
                         sId={sId}
                         responseId={responseId}
                         SurveyById={SurveyById}
                     />
                   :  <div style={{ width: '100%', height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                   <Spin spinning={true} />
               </div>
                }

             </div>
         }
         </>

           



    );
};


export default compose( withApollo, withEditResponseOptimized, withCreateResponseOptimized)( EditSurveyResponseContainerQuestionBased );

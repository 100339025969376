
import { Button, Row } from "antd";
// import { ONEONONE_ADD_EDIT_TOOL_ID } from "config";
import { getIntFromString } from "modules/look";
import React from "react";
import Agenda_close from '../../assets/agenda-close.svg'
import { useHistory } from "react-router-dom";
import one_tick from '../../assets/one-on-one-tick.svg'
import one_on_one_route from '../route/index'

const ScheduleNextMeeting = props => {
  
    const {goOneOnOne, meetingById,setScheduleNextMeeting} = props
  
  const history = useHistory();


  return (
    <>
      <Row align="middle" justify="center" style={{ marginTop: "10px", marginBottom: '6px' }}>
      <Row align="middle" style={{marginBottom:'15px'}}>
      <h2 className="one-on-one-meeting-popup-head" style={{ fontWeight: "600" }}><img src={one_tick} alt="" /> 1:1 Saved Successfully!</h2>
      <img src={Agenda_close} alt="" style={{ position: "absolute", top: "36px", right: "20px", cursor: "pointer" }} onClick={(e) => { history.goBack() }} />
        </Row>
          <p className="one-on-one-meeting-popup-para" >
            Do you want to schedule next 1:1 ?
          </p>
        </Row>
        <Row justify="center" align="center" style={{ gap: "10px", marginTop: "10px" }}>
          <Button onClick={(e) => {setScheduleNextMeeting(false);goOneOnOne()}} style={{ padding: '8px 21px',width:'100px' }} className="one-on-one-cancel-btn-modal">NO</Button>
          <Button style={{ width: '100px' }} onClick={(e) =>history.push(`${one_on_one_route?.addOneonOne}?previous=${getIntFromString(meetingById?.id)}`)} className="one-on-one-save-btn-modal">YES</Button>
        </Row>
    </>
  )

}

export default ScheduleNextMeeting
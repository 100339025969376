

import { Button, Modal, Row, Spin } from "antd";
import { getIntFromString } from "modules/look";
import React from "react";
import '../css/CreateBars.css';
import { CREATE_BARS_ICON } from "../Icon";
import bars_route from "../route";
import BarsDeleteConfirmation from "./BarDeleteConfirmation";
import BarsAddInfo from "./BarsAddInfo";



const BarsCreateView = (props) => {
  const { competencyById, createBulkBehaviourallyAnchoredRatings, ratingScales, allEmployeeLevels, competencyLoading, setCompetencyById, navigateRoute } = props


  const [barsToAdd, setBarsToAdd] = React.useState([]);
  const [subCompetencyModalVisible, setSubCompetencyModalVisible] = React.useState(false)
  const barsRef = React.useRef([])
  const [activeLevel, setActiveLevel] = React.useState(0);
  const [invalidBars, setInvalidBars] = React.useState(false);



  React.useEffect(() => {

    window.scrollTo(0, 0);

  }, [])

  const handleBarsSubmission = async () => {

    let bars = [];
    bars =

      competencyById?.employeeLevel?.map((level) =>

        // eslint-disable-next-line array-callback-return
        level?.ratingScales?.map((scale) => {
          if (scale?.behaviourExample) {
            return {
              competency: { mainCompetencyId: competencyById?.id },
              ratingScaleId: scale?.id,
              employeeLevelId: level?.id,
              behaviourExample: scale?.behaviourExample
            }
          }
        }
        )
      )



        ?.flat()?.filter((value) => value !== undefined);

    if (bars?.length) {
      const response = await createBulkBehaviourallyAnchoredRatings({ behaviourallyAnchoredRatingDataList: bars })

      if (response) {
        let allExpectedBarsQty = competencyById?.employeeLevel?.length * ratingScales?.length

        if (allExpectedBarsQty === response?.behaviourallyAnchoredRating?.length && competencyById?.competencySet?.length) {
          setSubCompetencyModalVisible(true);
        } else {
          navigateRoute(bars_route.bars_home)
        }
      }
    } else {
      setInvalidBars(true)
    }



  }



  const handleCancelSubCompetency = () => {

    setSubCompetencyModalVisible(false);
    navigateRoute(bars_route.bars_home)

  }


  const handleSubCompetencyConfirm = () => {

    setSubCompetencyModalVisible(false)
    localStorage.setItem('mainCompetencyData', JSON.stringify(competencyById?.employeeLevel[0]))
    navigateRoute(bars_route.bars_create_sub_comp?.replace(":competency_id", getIntFromString(competencyById?.id)))


  }






  const handleExampleChange = (e, level, scale) => {

    let data = { ...competencyById }
    setInvalidBars(false);
    let employeeLevel = data?.employeeLevel.find((value) => value?.id === level?.id);
    if (employeeLevel) {
      let ratingScaleArray = employeeLevel?.ratingScales?.slice(0);
      let ratingScale = ratingScaleArray?.find((value) => value?.id === scale?.id);

      if (ratingScale) {
        ratingScale = { ...ratingScale, behaviourExample: e }
        let index = ratingScaleArray?.findIndex((value) => value?.id !== ratingScale?.id)

        let updatedArray = ratingScaleArray.filter((value) => value?.id !== ratingScale?.id).concat([]);
        updatedArray.splice(index, 0, { ...ratingScale });

        let scales = updatedArray?.map((value) => value?.behaviourExample)?.filter((value) => value !== undefined)

        let status;
        if (scales?.length !== ratingScales.length) {
          status = 'pending';
        } else {
          status = 'completed';
        }
        employeeLevel['ratingScales'] = updatedArray.sort((a, b) => a.sequence - b.sequence);
        employeeLevel['status'] = status;
      }

      setCompetencyById(data)

    }

  }



  const handleToggleLevel = (indexOfElements, competency) => {
    let elements = document.getElementsByClassName('ant-tabs-nav-list')[indexOfElements];

    // eslint-disable-next-line array-callback-return
    competency.employeeLevel.map((level, index) => {

      if (level?.status === 'pending') {
        elements.getElementsByClassName("ant-tabs-tab")[index].classList.add('incomplete-level-bars')
      } else {
        elements.getElementsByClassName("ant-tabs-tab")[index].classList.add('complete-level-bars')
      }
    }

    )

  }



  return (

    <Spin spinning={competencyLoading}>
      <div className="create-bars-root poppins">

        <img alt="" src={CREATE_BARS_ICON.new_back_arrow} onClick={(e) => navigateRoute(bars_route.bars_home)} className="create-bar-back-arrow pointer" />

        <Row align="middle" justify="center">
          <div className="create-bars-header">Define <span>BARS</span></div>
        </Row>
        <div className="create-bars-body">
          <div className="create-bar-sub-heading create-bar-margin-seperator">Competency</div>

          <div className="bars-create-competency-title create-bar-margin-seperator">{competencyById?.title}</div>
          {/* Component for adding data into rating scales with respective employee levels */}
          {
            competencyById && 
            <BarsAddInfo competency={competencyById}
            setBarsToAdd={setBarsToAdd}
            barsToAdd={barsToAdd}
            invalidBars={invalidBars}
            indexOfElements={0}
            handleExampleChange={handleExampleChange}
            allEmployeeLevels={allEmployeeLevels}
            barsRef={barsRef}
            activeLevel={activeLevel}
            handleToggleLevel={handleToggleLevel}
            setActiveLevel={setActiveLevel}
            mainCompetency={true}
            ratingScales = {ratingScales}

          />
          }
        
        </div>
        {
          competencyById?.employeeLevel?.length && ratingScales?.length ?
          <Row justify="center">
            <Button className="bars-submit-action" onClick={(e) => handleBarsSubmission()}>Submit</Button>
          </Row>
          : null
        }


        <Modal
          visible={subCompetencyModalVisible}
          footer={null}
          centered
          destroyOnClose={true}
          closable={false}
        >
          <BarsDeleteConfirmation
            message={"Yay! You've defined the competencies! Would you like to continue defining the sub-competencies?"}
            confirm_action_label={"Yes"}
            cancel_action_label={"No"}
            onConfirm={(e) => handleSubCompetencyConfirm()}
            onCancel={(e) => handleCancelSubCompetency()}
            confirm_class="bars-confimation-action"
            cancel_class="bars-cancel-action"
          ></BarsDeleteConfirmation>

        </Modal>

      </div>
    </Spin>


  );
};

export default BarsCreateView;


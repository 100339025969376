import { MoreOutlined } from '@ant-design/icons';
import { withApollo } from '@apollo/client/react/hoc';
import { Card, Col, Dropdown, Empty, Menu, Pagination, Row, Spin, Switch } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { compose } from '../../core';
import {
  CardLayout, getIntFromString, SelectFilterField
} from '../../look';
import ROUTE from '../route';

import { withToolDeleting, withToolFilterUpdating, withToolState } from './ToolOperations';
// import { subscribeToTool } from './ToolSubscription';
import { IMG, PAGINATION_LIMIT, PRIMARY_CLR } from '../../../config';
import ReactMarkdown from '../../look/components/ReactMarkdown';
import { GET_ALL_TOOLS_ADMIN_QUERY } from '../graphql/AllToolsAdminQuery.gql';
import { TOOL_SUBSCRIPTION } from '../graphql/ToolSubscription.gql';
// import SelectFilterField from '../../look/components/SelectFilterField';

const Tool = props => {
  const {
    loading,
    onPaginationChange,
    deleteTool,
    onTitleChange,
    onDescriptionChange,
    // orderBy,
    // onOrderByChange,
    onFiltersRemove,
    // subscribeToMore,
    // editTool,
    pagination: { offset, first },
 client, filter, orderBy

  } = props;
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [toolSet, settoolSet] = React.useState(undefined);
  const [paginationFilter, setPaginationFilter] = React.useState({first: first,offset: offset});
  const [paginationProps,setPaginationProps] = React.useState(undefined);
  const [filterText,setFilterText] = React.useState(undefined);

  const toolDataRef = React.useRef(null);

  let toolSubscription = undefined;
  const m = React.useRef(true)



  React.useEffect(()=>{
    let updatedFilters;
     if(filter?.title_Icontains?.length || filter?.description_Icontains?.length){
       updatedFilters = {...filter}
     }else{
       updatedFilters = {...paginationFilter,...filter}
       setFilterText(undefined)
     }
    
     getAllTools(updatedFilters)
 
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[filter])




   React.useEffect(()=>{
    
    let paginationData = !(toolSet && toolSet.totalCount)
    ? {}
    : {
      defaultCurrent: 1,
      showTotal: (total, range) =>
        toolSet.totalCount && (
          <>

            {total < PAGINATION_LIMIT
              ? `Showing 1 to ${total} of ${total} entries.`
              : `Showing ${paginationFilter?.offset + 1} to ${paginationFilter?.offset + paginationFilter?.first <= total ?
                paginationFilter?.offset + paginationFilter?.first : total
              } of ${total} entries.`}

          </>
        ),
      onChange: (page, pageSize) =>
        getAllTools({
          offset: page * PAGINATION_LIMIT - PAGINATION_LIMIT,
          first: PAGINATION_LIMIT
        }),
      defaultPageSize: PAGINATION_LIMIT,
      total: toolSet.totalCount,
      style: {
        textAlign: 'right'
      }
    };
    setPaginationProps(paginationData)

   // eslint-disable-next-line react-hooks/exhaustive-deps
  },[toolSet])



  const getAllTools = async (pagination) => {
    if(pagination?.first){
      setPaginationFilter({...pagination})
    }
   
    const { data } = await client.query({
      query: GET_ALL_TOOLS_ADMIN_QUERY,
      variables: { ...pagination, ...filter, orderBy },
      fetchPolicy: 'network-only',
    });

    if (data?.getAllToolsAdmin) {

      settoolSet(data?.getAllToolsAdmin);
      toolDataRef.current = data?.getAllToolsAdmin;
      SubscribeToTools();
    }
  }

  React.useEffect(() => {
    toolDataRef.current = toolSet
  }, [toolSet])


  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])


  React.useEffect(() => {
    return () => {
      if (toolSubscription) {
        toolSubscription.unsubscribe();
      }
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const SubscribeToTools = () => {

    toolSubscription = client.subscribe({
      query: TOOL_SUBSCRIPTION,
    }).subscribe({
      next(result) {

        switch (result.data.toolsSubscription.mutation) {

          case 'DELETE':

            if (m.current) {
              let toolSet = toolDataRef.current?.edges
              let toolSetWithDeletedRecord = toolSet?.filter((tool) =>
                tool?.node?.id !== result.data.toolsSubscription?.tool?.id)
              let updatedToolSet = { edgeCount: (toolDataRef.current?.edgeCount - 1), totalCount: (toolDataRef.current?.totalCount - 1), edges: toolSetWithDeletedRecord }
              settoolSet(updatedToolSet);
              toolDataRef.current = updatedToolSet;
            }

            break;
          default:
            break
        }

      }
    })

  }




  const handleSelect = id => {
    setSelectedRowKeys(
      selectedRowKeys.indexOf(id) === -1 ? [...selectedRowKeys, id] : selectedRowKeys.filter(s => s !== id)
    );
  };



 

  const handleDelete = async id => {
    setDeleteLoading(true);
    try {
      await deleteTool({ id: id });
    } catch (e) {
      throw new Error(e.message);
    }
    setDeleteLoading(false);
  };
  
  const menu = id => (
    <Menu>
      <Menu.Item key="0">
        <Link to={`${ROUTE.editToolLink}${id}`}>Edit</Link>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => handleDelete(id)} danger={true}>
        Delete
      </Menu.Item>
      <Menu.Item key="3" onClick={() => handleSelect(id)}>
        Select
      </Menu.Item>
    </Menu>
  );
  const handleEditTool = async (e, field, id) => {
    // eslint-disable-next-line no-unused-vars
    let data = {};
    if (field === 'public') {data = { public: e };}
    if (field === 'shuffle') {data = { shuffle: e };}
    // try {
    //   await editTool({
    //     id: Number(getIntFromString(id)),
    //     ...data
    //   });
    // } catch (e) {
    //   throw new Error(e.message);
    // }
  };
  return (
    <CardLayout
      table={true}
      active={ROUTE.tool}
      title={[{ name: 'Tool' }]}
      loading={loading}
      tableData={toolSet}
      onDelete={deleteTool}
      editLink={ROUTE.editTool}
      addLink={ROUTE.addTool}
      onPaginationChange={onPaginationChange}
      onFiltersRemove={onFiltersRemove}
      selectedRowKeys={selectedRowKeys}
      extra={
        <SelectFilterField
          placeholder="Search Tool by"
          onFiltersRemove={onFiltersRemove}
          filterText={filterText}
          setFilterText={setFilterText}
          options={[
            {
              label: 'Title',
              type: 'text',
              value: onTitleChange
            },
            {
              label: 'Description',
              type: 'text',
              value: onDescriptionChange
            }
          ]}
        />
      }
    >
      <Spin spinning={loading || deleteLoading} size="large">
        <div style={{ minHeight: '70vh' }}>
          <Row gutter={[24, 24]}>
            {toolSet &&
              toolSet.edges.map(({ node }) => (
                <>
                  <Col xs={24} md={12} lg={8}>
                    <Dropdown overlay={() => menu(getIntFromString(node.id))} placement="bottomLeft">
                      <MoreOutlined
                        style={{
                          // fontSize: 'large',
                          position: 'absolute',
                          right: 20,
                          zIndex: 1,
                          top: 20
                        }}
                      />
                    </Dropdown>
                    <Link to={`${ROUTE.toolDetailLink}${getIntFromString(node.id)}`}>
                      <StyledCard
                        active={selectedRowKeys.indexOf(getIntFromString(node.id)) !== -1}
                        cover={
                          <img
                            alt={node.title}
                            src={node.thumbnailImage || IMG}
                            style={{ /* width: '100%', */ height: '250px' }}
                          />
                        }
                      >
                        <Row>
                          <Col>
                            <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>{node.title}</h3>
                            <h5 /* style={{ color: '#CACACA' }} */>
                              {node && <ReactMarkdown>{node.description}</ReactMarkdown>}
                            </h5>
                          </Col>
                        </Row>
                        <br />
                      </StyledCard>
                    </Link>
                    <Row
                      justify="space-between"
                      align="bottom"
                      style={{ position: 'relative', bottom: 80, padding: '0 24px' }}
                    >
                      <Col>
                        <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Status</h4>
                        <h4 style={{ marginBottom: 0, fontWeight: 'normal', color: PRIMARY_CLR }}>
                          {node.status ? 'Published' : 'Not-Published'}
                        </h4>
                      </Col>
                      <Col>
                        <h4 style={{ marginBottom: 0, fontWeight: 'normal' }}>Public</h4>
                        <Switch  style={{cursor:'default'}} checked={node.public} size="small" onClick={e => handleEditTool(e, 'public', node.id)} />
                      </Col>
                    </Row>
                  </Col>
                </>
              ))}
          </Row>
          {toolSet && toolSet.edgeCount === 0 && (
            <div className="HVCenter" style={{ width: '70vw' }}>
              <Empty description="No result found" />
            </div>
          )}
        </div>
        <br />
        <Pagination {...paginationProps} />
      </Spin>
    </CardLayout>
  );
};

export default compose(withToolState, withToolDeleting, withToolFilterUpdating, withApollo)(Tool);

const StyledCard = styled(Card)`
  height: 100%;
  border-color: ${props => props.active && PRIMARY_CLR};
  box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
  &:hover {
    border-color: ${props => PRIMARY_CLR};
  }
`;


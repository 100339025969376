

import { Button, Row } from 'antd';
import React from 'react';
import { CREATE_BARS_ICON } from '../Icon';


const BarsLevelTraversing = (props) => {

    const { status, handleTraversingOfLevels, level, index, invalidBehaviourExamples } = props

  

 
    return (

        <Row className="bars-button-panel-container" justify="center" style={{ gap: '1em' }}>
            <img alt=""
            className={(status === "pending" || invalidBehaviourExamples) ? "create-bar-left-arrow pointer" : "create-bar-left-arrow create-bar-left-arrow-completed pointer"}
            src={CREATE_BARS_ICON.left_arrow_bars_with_group}
            onClick={(e) => { if((status === "pending" || invalidBehaviourExamples)){ handleTraversingOfLevels("Backward")}} } />
            <Button
                disabled = {(status === "pending" || invalidBehaviourExamples) ? true : false}
                className={(status === "pending" || invalidBehaviourExamples) ? "bars-next-level pointer" : "bars-next-level bars-next-level-completed pointer"}
                onClick={(e) => handleTraversingOfLevels("Forward", level, index)} >
                <Row style={{ gap: '0.6em' }} justify="center" wrap={false}>
                    Next Level
                    <img alt=""
                    className={(status === "pending" || invalidBehaviourExamples) ? "create-bars-arrow pointer" : "create-bars-arrow create-bars-arrow-completed pointer"}
                    src={CREATE_BARS_ICON.right_arrow_bars} />
                </Row>
            </Button>
        </Row>

    )
}

export default BarsLevelTraversing;


export const issues_list = [
    { label: 'Login Issue', value: 'login_issue' },
    { label: 'Password Reset', value: 'password_reset' },
    { label: 'Permission Issue', value: 'permission_issue' },
    { label: 'Activate Tool', value: 'activate_tool' },
    { label: 'Report Bug', value: 'report_bug' },
    { label: 'Deactivating User', value: 'deactivating_user' },
    { label: 'Payment Issue', value: 'payment_issue' },
    { label: 'Legal/Copyright', value: 'legal_copyright' },
    { label: 'Email Issue', value: 'email_issue' },
    {label: 'Other', value: 'other' }
  ];

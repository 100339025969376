// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { Row, Col, Button, Input, Form, message, Select } from "antd";
import styled from "styled-components";
import { CKEditorField, getIntFromString, withMarkdownFieldValue } from "../../look";
import { CloseOutlined } from "@ant-design/icons";
import { withCreateSkill } from '../containers/ReadinessOperations';
import { COMPETENCY_CATEGORY_LIST } from 'modules/role_definition/graphql/CompetencyQuries.gql';
import { withApollo } from '@apollo/client/react/hoc';

const AddSkills = props => {
  const { me, createSkill, onClose, onaddSkill,client } = props
  const [title, setTitle] = React.useState();
  const [titleInvalid, settitleInvalid] = React.useState(false);
  const [type, setType] = React.useState();
  const [main_competency, SetMainCompetency] = React.useState();
  // eslint-disable-next-line no-unused-vars
  const [loading, setloading] = React.useState(false);
  const [form] = Form.useForm();
  const [FieldName, setFieldName] = React.useState();
  const [competency_cat_list,setCompetencyCatList]=React.useState()
  React.useEffect(()=>{
    if(main_competency){
      let category = competency_cat_list?.find(i=> String(i?.id) === String(main_competency))
      if(category){
        if(category?.competencyType === "GENERIC"){
          setType('Generic')
      }else{
        setType('Role Specific')
      }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[main_competency])

  React.useEffect(()=>{
   
    if(me){
      getCompetencyCategoryList()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[me])

   // Get all competency category list
   const getCompetencyCategoryList = async (filter) => {
    const { data } = await client.query({
        query: COMPETENCY_CATEGORY_LIST,
        fetchPolicy: 'network-only',
        variables: {...filter,first:50}
    });

    if(data?.allCompetencyCategory){
        setCompetencyCatList(data?.allCompetencyCategory?.edges.map(({node})=>node))
    }
}


  const CKEditorFieldName = "description"

  const handleSubmit = async () => {
    if (title && setType && main_competency) {
      setloading(true)
      settitleInvalid(false)
      let data = withMarkdownFieldValue(CKEditorFieldName, { description: FieldName })
      let competencyData = {
        title: title,
        description: data?.description,
        competencyType: type === 'Generic'?'Generic':'Role_Specific',
        competencyCategoryId:getIntFromString(main_competency)
      }

      const response = await createSkill({ competencyData });

      if (response && response?.id) {
        setloading(false)
        onaddSkill({ close: true, competencyData: response })
      } else {
        setloading(false)
      }
    }
    else {
      if (!title) {
        settitleInvalid(true)
      }
      else if (!type) {
        message.error('select skill type')
      }
      else if(!main_competency){
        message.error('select competency')
      }
    }
  }


  return (
    <>
      <RowDiv>
        <h4 style={{ fontSize: '20px' }}>Add a skill</h4>
        <Button shape="circle" style={{ marginLeft: '20px' }} onClick={() => { onClose({ close: true }) }}>
          <CloseOutlined />
        </Button>
      </RowDiv>
      <Form
        form={form}
        name={"1:1"}
        onFinish={() => { handleSubmit() }}
      >
        <Row gutter={15}>
          <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} >
            <p style={{ fontSize: '15px' }}><span style={{ color: '#ff4d4f' }}>*</span> Title</p>
            <Input
              // disabled={memberlist?.length?false:true}
              style={{
                border: !titleInvalid ? '1px solid #CACACA' : '1px solid #ff4d4f',
              }}
              id="1_1_title"
              value={title}
              onChange={(e) => (setTitle(e.target.value))}
              autoComplete='off'
            />
          </Col>

          <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
            <p style={{ fontSize: '15px' }}>Skill Type</p>
            <Input
              // disabled={memberlist?.length?false:true}
              style={{
                border: '1px solid #CACACA',
              }}
              id="1_1_type"
              value={type}
              readOnly
              autoComplete='off'
            />

          </Col>

          <Col lg={{ span: 12 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{marginTop:"1em"}}>
            <p style={{ fontSize: '15px' }}><span style={{ color: '#ff4d4f' }}>*</span> Competency</p>
            <Select style={{ width: '100%' }} value={main_competency ? main_competency : ''} onChange={(e) => { SetMainCompetency(e) }} filterOption={false} onSearch={(e)=>getCompetencyCategoryList({title_Icontains:e})} showSearch={true}>
             
             {
              competency_cat_list?.map(item=>(
                <Select.Option
                key={item?.id}
                value={item?.id}
              >
                {item?.title}
              </Select.Option>
              ))
             }
            </Select>
          </Col>
        </Row>

        <Col style={{ marginTop: '20px' }}>
          <p style={{ fontSize: '15px' }}>description</p>
          <div >
            <CKEditorField
              changeHandler={(e) => { setFieldName(e) }}
            />
          </div>
        </Col>


        <div align="center">
          {
            <>
              <CancelButton type="default" onClick={(e) => { onClose(true) }} style={{ marginTop: '30px' }}>Cancel</CancelButton>
              <CreateButton type="primary" htmlType="submit" style={{ marginTop: '30px', marginLeft: '20px' }}>Save</CreateButton></>


          }
        </div>
      </Form>
    </>
  )
}

export default compose(withCreateSkill,withApollo)(AddSkills);

const RowDiv = styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`

const CreateButton = styled(Button)`
width:150px;
height:40px;
border-radius:5px;
margin-top:10px;
`

const CancelButton = styled(Button)`
width:150px;
height:40px;
color: #B0CB1F;
border-radius:5px;
border-style: solid;
border-color: #B0CB1F;
margin-top:10px;
&:hover {
    background-color: #B0CB1F;
    color: white;
  }
`

import React from 'react';

import { getItem, setItem } from '../../core';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getBase64StringFromInt } from '../../look';

const SaveInCompleteQuizResponse = props => {
  const { form, itemName, formValues, setFormValues, startTime, setPrevTotalDuration } = props;
  const [cachedResponse, setCachedResponse] = React.useState(null);

  const handleLoggedIn = React.useRef(() => {});
  handleLoggedIn.current = async itemName => {
    const savedResponseJson = await getItem(itemName);

    if (savedResponseJson) {
      const savedResponse = JSON.parse(savedResponseJson);
      if (savedResponse?.formValues?.length > 0) {
        Modal.confirm({
          title: 'Continue quiz where you left off?.',
          icon: <ExclamationCircleOutlined />,
          okText: 'Continue',
          cancelText: 'Re-start',
          bodyStyle: { maring: 0 },
          onOk: () => {
            form.setFields(
              savedResponse?.formValues?.map(s => ({
                name: getBase64StringFromInt('QuestionType', s.questionId),
                value: s.answer
              }))
            );
            setFormValues(savedResponse?.formValues);
            setPrevTotalDuration(savedResponse.totalDuration);
            setCachedResponse(savedResponse);
          }
        });
      }
    }
  };

  React.useEffect(() => {
    function saveAnswerOnUnmount(values) {
      setItem(itemName, JSON.stringify(values));
    }

    return () => {
      const endTime = new Date().getTime();
      saveAnswerOnUnmount({ formValues, totalDuration: cachedResponse?.totalDuration + (endTime - startTime) / 1000 });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemName, formValues]);

  React.useEffect(() => {
    handleLoggedIn.current(itemName);
  }, [itemName]);

  return <></>;
};

export default SaveInCompleteQuizResponse;

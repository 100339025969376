// @ts-nocheck
import { Button, Col, Modal, Row, Spin } from "antd";
import { getMomentUserTimezoneWithFormat, ReactMarkdown } from "modules/look";
import React from "react";
import '../css/JobFamily.css';
import { jobfamily_icons, role_icons } from "../icon";
import RoleDefinitionConfirmation from "../../look-v2/Components/ConfirmationDialog";
import role_definition_route from "../route";

const RoleDefinitionDetailView = (props) => {

    const { row_1, row_2, row_3, pageLoading, entityDetails, entityTitle, setDeleteEntity, deleteRecord, handleDelete, optionalAttribute, editNavigation,
        deletePermission, editPermission,navigateRoute} = props

    const [entityAttributeDetails, setEntityAttributeDetails] = React.useState(undefined);

    const goBackHome =()=>{
        if(entityTitle==='Role'){
            let value =  {"parent_route":{"key":"role","label":"Role"},"child_route":{"key":"role_home_view"}}
            localStorage.setItem("role_definition_navigation",JSON.stringify(value))
        }else{
            let value =  {"parent_route":{"key":"job_family","label":"Job Family"},"child_route":{"key":"jobfamily_home_view"}}
            localStorage.setItem("role_definition_navigation",JSON.stringify(value))
        }
        navigateRoute(role_definition_route.role_definition_home)
    }

    return (
        <>
        <div  className="main-page-card">
            <Spin spinning={pageLoading}>
                {
                    entityDetails &&
                    <div style={{display:"flex",flexDirection:"column"}}>
                        <img className="pointer back-arrow-rd-detail" style={{cursor:"pointer"}} onClick={(e) => goBackHome()} src={role_icons?.new_back_arrow} alt="new_back_arrow"/>
                        <Row justify="center" className="poppins jf-root">

                            <Col span={14}>

                                <Row justify="space-between" align="middle" className="header-info-rd-detail">
                                    <Col>
                                        <div className="jf-title">{entityTitle}: {entityDetails?.title}</div>
                                    </Col>
                                    <Col className="header-rd-detail-actions">
                                        {
                                            editPermission && (
                                                
                                                <Button className="hide-btn">
                                                    <img className="pointer jf-icons" onClick={(e) => editNavigation()} src={jobfamily_icons.edit_role_definition} alt="icon" />
                                                    </Button>
                                            )
                                            
                                        }
                                        {
                                            deletePermission && (
                                                <Button className="hide-btn">
                                                    <img className="pointer jf-icons" onClick={(e) => setDeleteEntity(true)} src={jobfamily_icons.delete_icon} alt="icon"/>
                                                    </Button>
                                                
                                            )
                                            
                                        }
                                    </Col>
                                </Row>

                                <div className="jf-body">
                                    <Row justify="space-between">
                                        <Col>
                                            {
                                                optionalAttribute &&
                                                <div style={{ fontWeight: 500 }}>{optionalAttribute?.title}: {optionalAttribute?.detail?.name}</div>

                                            }
                                        </Col>
                                        <Col>
                                            <Row justify="end">
                                                <Row wrap={false}>
                                                    <div className="jf-side-info">Created: {getMomentUserTimezoneWithFormat(entityDetails?.createdAt, "DD-MM-YYYY")}</div>
                                                    <div className="jf-side-info">Updated: {getMomentUserTimezoneWithFormat(entityDetails?.updatedAt, "DD-MM-YYYY")}</div>
                                                </Row>
                                            </Row>
                                        </Col>
                                    </Row>



                                    {
                                        entityDetails?.description &&
                                        <div className="jf-margin-seperator">
                                            <div style={{ marginBottom: '0.6em' }} className="jf-desc-header">Description</div>
                                            <ReactMarkdown style={{ fontSize: "0.9em", fontWeight: '500' }}>{entityDetails?.description}</ReactMarkdown>
                                        </div>
                                    }

                                    <div className="jf-margin-seperator jf-attr-section-margin-seperator">
                                        {
                                            row_1?.content?.length ?
                                                <div>
                                                    <div className="jf-row-header jf-row-header-seperator">{row_1?.title}</div>
                                                    <Row className="jf-attr-row">
                                                        {
                                                            row_1?.content?.map((record) =>
                                                                <Col
                                                                    className="jf-text-content-body jf-text-body jf-attribute-values pointer"
                                                                    onClick={(e) => setEntityAttributeDetails({ record: record, title: row_1?.popUptitle })} span={8}>{record?.title}</Col>
                                                            )
                                                        }
                                                    </Row>
                                                </div> : null
                                        }
                                    </div>

                                    <div className="jf-margin-seperator jf-attr-section-margin-seperator">
                                        {
                                            row_2?.content?.length ?
                                                <div>
                                                    <div className="jf-row-header jf-row-header-seperator">{row_2?.title}</div>
                                                    <Row className="jf-attr-row">
                                                        {
                                                            row_2?.content?.map((record) =>
                                                                <Col className="jf-text-content-body jf-text-body jf-attribute-values pointer"
                                                                    onClick={(e) => setEntityAttributeDetails({ record: record, title: row_2?.popUptitle })} span={8}>{record?.title}</Col>
                                                            )
                                                        }
                                                    </Row>
                                                </div>
                                                : null
                                        }

                                    </div>

                                    <div className={row_3?.colClass}>
                                        {
                                            row_3?.content?.length ?
                                                <div>
                                                    <div className="jf-row-header jf-row-header-seperator">{row_3?.title}</div>
                                                    <Row className="jf-attr-row">
                                                        {
                                                            row_3?.content?.map((record) =>
                                                                <Col className="jf-text-content-body jf-text-body jf-attribute-values" span={8}>{record?.title}</Col>
                                                            )
                                                        }
                                                    </Row>
                                                </div> : null
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                }
            </Spin>


            <Modal visible={entityAttributeDetails}

                style={{ zIndex: '2',paddin:'1em' }}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-details-modal'
            >


                <div style={{padding:'1.5em 0em'}}>

                    <img className="pointer close-icon-rd-detail" onClick={(e) => setEntityAttributeDetails(undefined)} src={role_icons.cancel_role_definition} alt="cancel_role_definition"/>
                 

                    <div className="poppins rd-detail-root" >

                        <div>
                            <div className="rd-detail-pop-up-title" style={{ zIndex: '9999' }}>
                                <div >{entityAttributeDetails?.title}</div>
                            </div>


                            <div className="rd-detail-popup-margin-spacer"><span className="rd-detail-popup-attbitute-title">Title: </span>
                                <span className="rd-detail-desc">{entityAttributeDetails?.record?.title}</span> </div>

                            {
                                entityAttributeDetails?.record?.description &&
                                <div>
                                    <div style={{ marginBottom: '0.6em' }} className="rd-detail-popup-attbitute-title">Description</div>
                                    <div className="rd-detail-desc">{entityAttributeDetails?.record?.description}</div>
                                </div>
                            }

                        </div>
                    </div>

                </div>



            </Modal>

     
            <Modal
                visible={deleteRecord}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                className='rd-modal'
            >
                <RoleDefinitionConfirmation
                    message={`Do you want to delete the ${entityTitle} ${entityDetails?.title}?`}
                    onCancel={() => setDeleteEntity(false)}
                    onConfirm={() => handleDelete(entityDetails?.id)}
                />
            </Modal>

        </div>

        </>
    )
}
export default RoleDefinitionDetailView;
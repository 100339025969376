import CreateInvitationUserView from "modules/poc-dashboard/components/organization/createInvitationUserView";
import React from "react";
import '../../css/org-invite-style.css';
import { TEAM_LIST, VERTICAL_LIST } from "modules/poc-dashboard/graphql/organizationQuery.gql";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import gql from "graphql-tag";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { message, Spin } from "antd";
import { getIntFromString, globalPermissionValidator } from "modules/look";
import { poc_admin_permission } from "Permissions/poc_admin.permission";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
const CreateInviteUserContainer = (props) => {
  const { client, me, userPermission,navigateRoute } = props
  const [verticals, setVerticals] = React.useState([])
  const [teams, setTeams] = React.useState({})
  const [register_data, SetRegisterData] = React.useState()
  const [register_sucess, SetRegisterSucess] = React.useState([])
  const [getSuccessUserID, SetSuccessUserID] = React.useState()
  const [loadingapi, SetloadingApi] = React.useState(false)
  const [add_employee, SetAddEmployee] = React.useState()
  const [assign_employee_to_org, SetAssignEmployeeToOrg] = React.useState()
  const [assign_employee, SetAssignEmployee] = React.useState()
  const [organization_detail, SetOrganizationDetail] = React.useState()
  const [formData, setFormData] = React.useState([
    { key: 1, vertical: null, team: null, email: null, firstName: null, lastName: null, user_type: null, error: false, error_message: null, success: false }
  ]);
  const [permission, setPermission] = React.useState()
  let register_handle_ref = React.useRef([])
  let vertical_detail_ref = React.useRef({})
  let team_detail_ref = React.useRef({})

  //Permission Check
  React.useEffect(() => {
    if (userPermission?.length && me) {
     
      if(me?.employee?.organizationSet?.edges?.length){
        let org_detail = me?.employee?.organizationSet?.edges[0]?.node
        SetOrganizationDetail(org_detail)
      }
      
      let permission = globalPermissionValidator(poc_admin_permission, userPermission)

      if (permission.required_permission && !me?.employee?.orgPocEmployee?.totalCount) {
        permission.required_permission = false
      }
      if (permission.required_permission && !me?.employee?.organizationSet?.edges?.length) {
        permission.required_permission = false
      }
      setPermission(permission)
    }
  }, [userPermission, me])


  React.useEffect(() => {
    if (permission?.required_permission) {
      getverticalList()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission])


  const REGISTER_MUTATION = gql`
    mutation registerUser {
        ${register_data ?
      `${register_data?.filter(i => !i.success)?.map((i, index) =>
        ` register_${i?.key}: register(email:"${i?.email}",firstName:"${i?.firstName}",lastName:"${i?.lastName}",username:"${i?.email}"){
                    success
                    errors
                  }
                `
      ).join('')
      }
            `
      : null
    }
    }
    `;

  const [registerMutation] = useMutation(REGISTER_MUTATION);

  React.useEffect(() => {
    if (register_data?.length) {
      inviteUser()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register_data])

  const inviteUser = async () => {
    try {
      SetloadingApi(true)
      const response = await registerMutation()
      if (response?.data) {
        register_handle_ref.current = register_data?.map(i => {
          let res = response?.data[`register_${i?.key}`]
          let error_list
          if (!res?.success) {
            error_list = Object.values(res?.errors)
            error_list = [].concat.apply([], error_list)?.map(m => m?.message)
          }

          return {
            ...i,
            success: res?.success,
            error: !res?.success,
            error_message: res?.success ? null : error_list?.length ? error_list[0] : "failed to invite"
          }
        })
        let success_list = register_handle_ref.current?.filter(i => i?.success)
        if (success_list?.length) {
          SetSuccessUserID(success_list)
        } else {
          updateForm()
        }
      }
    } catch (error) {
      SetloadingApi(false)

      message.error('Failed to invite users')
    }
  }

  const GET_USERS = gql`
    query userlist {
    ${getSuccessUserID?.map(
    (item, index) =>
      `user_${item?.key}: listAllUsers(first:1,username:"${item?.email}"){
          edges{
            node{
              id
            }
          }
    }`
  )
      .join('')}
    }
`;

  const [getUsers] = useLazyQuery(GET_USERS, { onCompleted: (data) => { onCompletedGetUser(data) }, onError: (data) => { onErrorGetUser(data) } });

  React.useEffect(() => {
    if (getSuccessUserID && getSuccessUserID?.length) {
      SetloadingApi(true)
      getUsers()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSuccessUserID])

  const onCompletedGetUser = (data) => {
    SetloadingApi(false)
    if (data) {
      register_handle_ref.current = register_handle_ref.current?.map(i => {
        let res = data[`user_${i?.key}`]
        return {
          ...i,
          user_id: res?.edges?.length ? res?.edges[0]?.node?.id : null
        }
      })
      let add_employee = register_handle_ref.current?.filter(i => i?.user_id)
      if (add_employee?.length) {
        SetAddEmployee(add_employee)
      } else {
        updateForm()
      }
    } else {
      updateForm()
    }
  }

  const onErrorGetUser = (data) => {
    message.error('Please try again')
    SetloadingApi(false)
  }

  const EMPLOYEE_MUTATION = gql`
  mutation addEmployee {
      ${add_employee ?
      `${add_employee?.filter(i => i.user_id)?.map((i, index) =>
        `employee_${i?.key}: addEmployee(employeeData:{userId:${getIntFromString(i?.user_id)}}){
            employee{
              id
            }
          }
          `
      ).join('')
      }
          `
      : null
    }
  }
  `;

  const [employeeMutation] = useMutation(EMPLOYEE_MUTATION);

  React.useEffect(() => {
    if (add_employee?.length) {
      addEmployeeData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [add_employee])

  const addEmployeeData = async () => {
    try {
      SetloadingApi(true)
      const response = await employeeMutation()
      if (response?.data) {
        register_handle_ref.current = register_handle_ref.current?.map(i => {
          let res = response?.data[`employee_${i?.key}`]
          return {
            ...i,
            employee_id: res?.employee?.id
          }
        })


        let employee_list = register_handle_ref.current?.filter(i => i?.employee_id)
        if (employee_list?.length) {
          let counter = 0
          let assign_list = []
          employee_list.forEach(i => {
            counter = counter + 1
            assign_list.push(
              {
                key: i?.key,
                operation_key: i?.key,
                operation_type: "create_member",
                reportingLine: "solid",
                related_id: me?.employee?.organizationSet?.edges[0]?.node?.id,
                related_type: "organizationId",
                employeeId: i?.employee_id,
                user_id: i?.user_id
              }
            )
          })
          SetAssignEmployeeToOrg(assign_list)
        } else {
          updateForm()
        }
      }
    } catch (error) {
      SetloadingApi(false)
      message.error('Failed to invite users')

    }
  }

  // assign employee
  const ASSIGN_EMPLOYEE_ORG_MUTATION = gql`
   mutation assign_employee_mutation {
        ${assign_employee_to_org ?
      `${assign_employee_to_org?.filter(i => i.operation_type === "create_member")?.map((i, index) =>
        `create_member_${i?.operation_key}:
                createMember(memberData:
                {
                employeeId:"${i?.employeeId}",
                reportingLine:"${i?.reportingLine}",
                memberFrom:{
                    ${i?.related_type}:"${i?.related_id}"
                    }
                }){
                    member{
                    id}
                    }
                `
      ).join('')
      }
            `
      : null
    }
    }
  `;

  const ASSIGN_EMPLOYEE_MUTATION = gql`
  mutation assign_employee_mutation {
    ${assign_employee ?
      `${assign_employee?.filter(i => i.operation_type === "create_member")?.map((i, index) =>
        `create_member_${i?.operation_key}:
          createMember(memberData:
          {
            employeeId:"${i?.employeeId}",
            reportingLine:"${i?.reportingLine}",
            memberFrom:{
                ${i?.related_type}:"${i?.related_id}"
                }
            }){
              member{
                id
              }
              }
        `
      ).join('')
      }

      ${assign_employee?.filter(i => i.operation_type === "update_vertical_head")?.map((i, index) =>
        `update_vertical_head_${i?.operation_key}:
          updateVertical(id:${getIntFromString(i?.vertical)},heads:[${i?.heads?.map(u => getIntFromString(u))}]){
              vertical {
                id
              }
          }
        `
      ).join('')
      }
      
        ${assign_employee?.filter(i => i.operation_type === "update_team_manager")?.map((i, index) =>
        `update_team_manager_${i?.operation_key}:
          updateTeam(id:${getIntFromString(i?.team)},managers:[${i?.managers?.map(u => getIntFromString(u))}]){
              team {
                id
              }
          }
        `
      ).join('')
      }
      `
      : null
    }
   }
 `;

  const [orgAssignEmployeeMutation] = useMutation(ASSIGN_EMPLOYEE_ORG_MUTATION);
  const [AssignEmployeeMutation] = useMutation(ASSIGN_EMPLOYEE_MUTATION);

  React.useEffect(() => {
    if (assign_employee_to_org?.length) {
      assignEmployeeToOrgApi()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assign_employee_to_org])

  const assignEmployeeToOrgApi = async () => {
    try {
      SetloadingApi(true)
      const response = await orgAssignEmployeeMutation()
      if (response?.data) {
        register_handle_ref.current = register_handle_ref.current?.map(i => {
          let res = response?.data[`create_member_${i?.key}`]

          return {
            ...i,
            org_member: res?.member?.id
          }
        })
        let org_employee = register_handle_ref.current?.filter(i => i?.org_member)
        let counter = 0
        let assign_list = []

        if (org_employee?.length) {
          org_employee.forEach(i => {
            if (String(i?.user_type) === "employee") {
              if (i?.vertical) {
                counter = counter + 1
                assign_list.push(
                  {
                    key: i?.key,
                    operation_key: `operation_${counter}`,
                    operation_type: "create_member",
                    reportingLine: "solid",
                    related_id: i?.vertical,
                    related_type: "verticalId",
                    employeeId: i?.employee_id,
                    user_id: i?.user_id
                  }
                )
              }

              if (i?.team) {
                counter = counter + 1
                assign_list.push(
                  {
                    key: i?.key,
                    operation_key: `operation_${counter}`,
                    operation_type: "create_member",
                    reportingLine: "solid",
                    related_id: i?.team,
                    related_type: "teamId",
                    employeeId: i?.employee_id,
                    user_id: i?.user_id
                  }
                )
              }
            }

            if (String(i?.user_type) === "manager") {
              if (i?.vertical) {
                counter = counter + 1
                assign_list.push(
                  {
                    key: i?.key,
                    operation_key: `operation_${counter}`,
                    operation_type: "create_member",
                    reportingLine: "solid",
                    related_id: i?.vertical,
                    related_type: "verticalId",
                    employeeId: i?.employee_id,
                    user_id: i?.user_id
                  }
                )
              }
            }

            if (String(i?.user_type) === "vertical_head") {
              if (i?.vertical && vertical_detail_ref.current[i?.vertical]?.parent) {
                counter = counter + 1
                assign_list.push(
                  {
                    key: i?.key,
                    operation_key: `operation_${counter}`,
                    operation_type: "create_member",
                    reportingLine: "solid",
                    related_id: vertical_detail_ref.current[i?.vertical]?.parent,
                    related_type: "verticalId",
                    employeeId: i?.employee_id,
                    user_id: i?.user_id
                  }
                )
              }
            }
          })
          let vertical_heads = org_employee?.filter(i => String(i?.user_type) === "vertical_head" && i?.vertical)
          let distinct_vertical = {}
          if (vertical_heads?.length) {
            vertical_heads.forEach(i => {
              if (distinct_vertical[i?.vertical]) {
                distinct_vertical[i.vertical].push(i?.employee_id)
              } else {
                distinct_vertical[i.vertical] = [i?.employee_id]
              }
            })
          }
          if (Object.keys(distinct_vertical)?.length) {
            Object.entries(distinct_vertical).forEach(i => {
              counter = counter + 1
              let heads = i[1]
              if (vertical_detail_ref.current[i[0]]?.heads?.length) {
                heads = heads?.concat(vertical_detail_ref.current[i[0]]?.heads)
              }
              assign_list.push(
                {
                  operation_key: `operation_${counter}`,
                  operation_type: "update_vertical_head",
                  vertical: i[0],
                  heads
                }
              )
            })
          }

          let team_managers = org_employee?.filter(i => String(i?.user_type) === "manager" && i?.team)
          let distinct_team = {}
          if (team_managers?.length) {
            team_managers.forEach(i => {
              if (distinct_team[i?.team]) {
                distinct_team[i.team].push(i?.employee_id)
              } else {
                distinct_team[i.team] = [i?.employee_id]
              }
            })
          }
          if (Object.keys(distinct_team)?.length) {
            Object.entries(distinct_team).forEach(i => {
              counter = counter + 1
              let managers = i[1]
              if (team_detail_ref.current[i[0]]?.managers?.length) {
                managers = managers?.concat(team_detail_ref.current[i[0]]?.managers)
              }
              assign_list.push(
                {
                  operation_key: `operation_${counter}`,
                  operation_type: "update_team_manager",
                  team: i[0],
                  managers
                }
              )
            })
          }

          if (assign_list?.length) {
            SetAssignEmployee(assign_list)
          } else {
            updateForm()
          }

        } else {
          updateForm()
        }
      } else {
        updateForm()
      }

    } catch (error) {
      SetloadingApi(false)
      message.error('Failed to invite users')
    }
  }

  React.useEffect(() => {
    if (assign_employee?.length) {
      assignEmployeeApi()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assign_employee])

  const assignEmployeeApi = async () => {
    try {
      SetloadingApi(true)
      await AssignEmployeeMutation()
      updateForm()

    } catch (error) {
      SetloadingApi(false)
      message.error('Failed to invite users')
    }
  }


  const updateForm = () => {
    let success = register_handle_ref.current?.filter(i => i?.success)
    let list = [].concat(register_sucess)
    list = list.concat(success)
    SetRegisterSucess(list)
    let errors = register_handle_ref.current?.filter(i => !i?.success)
    if (!errors?.length) {
      message.success("Successfully invited all the users")
      navigateRoute("-1")
      return
    }
    setFormData(errors)
    SetloadingApi(false)

  }

  const getverticalList = async (filter) => {
    const { data } = await client.query({
      query: VERTICAL_LIST,
      variables: filter,
      fetchPolicy: 'network-only'
    });
    if (data.verticals) {
      let list = data.verticals?.edges?.map(({ node }) => node)
      list.forEach(v => {
        vertical_detail_ref.current[v.id] = {
          parent: v?.parentvertical?.id,
          heads: v?.heads?.edges?.map(({ node }) => node?.id) || []
        }
      });

      setVerticals(list)
    }
  }
  const getTeamList = async (filter) => {
    if (!filter?.key || !filter?.vertical) {
      return
    }
    const { data } = await client.query({
      query: TEAM_LIST,
      variables: filter,
      fetchPolicy: 'network-only'
    });
    if (data.Teams) {
      let team_data = { ...teams }
      let list = data.Teams?.edges?.map(({ node }) => node)
      list.forEach(t => {
        team_detail_ref.current[t.id] = {
          parent: t?.vertical?.id,
          managers: t?.managers?.edges?.map(({ node }) => node?.id) || []
        }
      });
      team_data[`team_${filter?.key}_${filter?.vertical}`] = list
      setTeams(team_data)
    }
  }
  return (
    <>

      {(permission && !permission?.required_permission) && (<NoPermissionView />)}

      {
        (permission && permission?.required_permission) && (
          <CreateInvitationUserView {...props} verticals={verticals} getverticalList={getverticalList} getTeamList={getTeamList} teams={teams} SetRegisterData={SetRegisterData} setFormData={setFormData} formData={formData} loadingapi={loadingapi} organization_detail={organization_detail}/>
        )
      }
      {
        !permission && (
          <div className="role-no-permission-container">
            <Spin spinning={true} size="large"></Spin>
          </div>
        )
      }

    </>
  )
}


export default compose(withApollo)(CreateInviteUserContainer);
/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { Radio, Divider, Modal, Row, Col, Form, Input, Button, Checkbox } from 'antd';
import styled from 'styled-components';
import { CheckCircleFilled } from '@ant-design/icons';
// @ts-ignore
import { withRouter } from 'react-router-dom';

import ProfileImg from '../../assets/ProfileImg.svg';
import { MetaTags } from '../../look';
import ROUTE from '../route';
import PAGE_ROUTE from "../../page/route";
// @ts-ignore
import { useHistory } from 'react-router-dom';
const PasswordSetView = props => {
  const [ visible, setVisible ] = React.useState( false );
  const [ counter, setCounter ] = React.useState( true );
  const [ forgotPass, setForgotPass ] = React.useState( 0 );
  const [ value, setValue ] = React.useState( 1 );

  const history = useHistory()
  const { location, onPasswordSetMailSubmit, onPasswordSetSubmit } = props;
  const handlePasswordResetState = React.useRef( () => { } );
  handlePasswordResetState.current = async () => {
    setForgotPass( 3 );
    setVisible( true );
    setCounter( false );
  };

  React.useEffect( () => {
    const pathnameArray = location.pathname.split( '/' );
    if ( counter && pathnameArray.indexOf( 'password-set' ) > -1 ) {
      handlePasswordResetState.current();
    }
  } );
  // let label='';
  // @ts-ignore
  const onFinish = values => { };
  const validateMessages = {
    required: '${label} is required!',
    types: { email: '${label} is not a valid email!'}
  };
  const layout = {
    labelCol: {
      span: 24
    },
    wrapperCol: {
      span: 24
    }
  };

  const radioStyle = {
    display: 'block',
    height: '55px'
  };

  const EnterEmailForm = () => {
    const [ form ] = Form.useForm();
    const handleForgotMailPassword = async ( { email } ) => {
      try {
        const response = await onPasswordSetMailSubmit( email );
        //
        if ( response.success ) {
          setForgotPass( 2 );
          setValue( 1 );
        } else {
          response?.errors &&
            form && form.setFields( [
              {
                name: 'email',
                errors: [ 'Something went wrong, no email was sent, please try again in some time or report this issue.' ]
              }
            ] );
        }
      } catch ( e ) {
        form && form.setFields( [
          {
            name: 'email',
            errors: [ 'Something went wrong, no email was sent, please try again in some time or report this issue.' ]
          }
        ] );
        throw new Error( e.message );
      }
    };
    return (
      <>
        <MetaTags title="Forgot Password" description="This is Forgot Password page" />
        <h2>Find your account</h2>

        <LessDivider up />
        <Form
          form={form}
          {...layout}
          requiredMark={false}
          name="serach-email"
          onFinish={handleForgotMailPassword}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="email"
            label="Please enter your email to search your account."
            rules={[
              { required: true, message: 'E Mail is required!' },
              {
                required: true,
                type: 'email',
                message: 'The input is not valid E-mail!'
              }
            ]}
          >
            <Input placeholder="Email" autoComplete='off'/>
          </Form.Item>
          <LessDivider />
          <Button
            type="primary"
            htmlType="submit"
            // onClick={() => setForgotPass(1)}
            size="middle"
          >
            Send
          </Button>
          <Divider type="vertical" />
          <Button type="primary" ghost onClick={() => setVisible( false )} size="middle">
            Cancel
          </Button>
        </Form>
      </>
    );
  };
  const SelectResetOptForm = () => {
    return (
      <>
        <h2>Set Your Password</h2>
        {/* <LessDivider up /> */}
        <h3>How do you want to get the code to reset your password?</h3>
        <Form {...layout} requiredMark={false} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
          <Row gutter={12}>
            <Col span={8} style={{ alignItems: 'flex-end' }}>
              <img src={ProfileImg} alt="" />
              <br />
              <h3>Jody Taylor</h3>
            </Col>
            <Col span={16}>
              <Radio.Group onChange={e => setValue( e.target.value )} value={value}>
                <Radio style={radioStyle} value={1}>
                  Send link on mail <br />
                  <span style={{ marginLeft: '25px', color: 'grey' }}>xyz@gmail.com</span>
                </Radio>
                <Radio style={radioStyle} value={2}>
                  Send OTP on Mobile <br />
                  <span style={{ marginLeft: '25px', color: 'grey' }}>********67</span>
                </Radio>
              </Radio.Group>
            </Col>
            <Col lg={12} md={12} sm={0} xs={0}>
              {/* <LessDivider /> */}
            </Col>
          </Row>
          <Row align="middle">
            <Col lg={12} md={12} sm={0} xs={0}>
              <a href="//#endregion">Can’t access them now?</a>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Row>
                <Col span={11}>
                  <Button block type="primary" htmlType="submit" onClick={() => setForgotPass( 2 )} size="middle">
                    Send
                  </Button>
                </Col>
                <Col span={2} />
                <Col span={11}>
                  <Button block type="primary" ghost onClick={() => setVisible( false )} size="middle">
                    Aren’t you?
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col lg={0} md={0} sm={24} xs={24}>
              {/* <LessDivider /> */}
            </Col>
            <Col lg={0} md={0} sm={24} xs={24}>
              <a href="//#endregion">Can’t access them now?</a>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const EmailSent = () => (
    <>
      <h2>Check Email</h2>
      {/* <LessDivider up /> */}
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        <MailSent>
          <
// @ts-ignore
          CheckCircleFilled /> &nbsp;
          <span>Mail Send</span>
        </MailSent>
        {/* todo change message */}
        <h3>A password reset email is sent to your registered email.</h3>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                setForgotPass( 0 );
                setVisible( false );
                history.push( ROUTE.login );
              }}
              size="middle"
            >
              Login
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                setForgotPass( 0 );
                setVisible( false );
                history.push( ROUTE.login );
              }}
              size="middle"
            >
              Login
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  const EnterOTP = () => (
    <>
      <h2>Enter Your OTP</h2>
      {/* <LessDivider up /> */}
      <Form {...layout} requiredMark={false} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
        <Form.Item
          name={[ 'user', 'otp' ]}
          label="Please check the SMS for OTP "
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input placeholder="Enter your OTP" autoComplete='off'/>
        </Form.Item>
        <span>
          <span style={{ color: 'grey' }}>
            We have sent your code to: <br />
            ********67
          </span>
        </span>
        <Row>
          <Col lg={12} md={12} sm={0} xs={0}>
            {/* <LessDivider /> */}
          </Col>
        </Row>
        <Row align="middle">
          <Col lg={12} md={12} sm={0} xs={0}>
            <Button type="link" onClick={() => setForgotPass( 4 )}>
              Did you not get the OTP ?
            </Button>
          </Col>
          <Col lg={12} md={12} sm={24} xs={24}>
            <Row gutter={16}>
              <Col span={12}>
                <Button block type="primary" htmlType="submit" onClick={() => setForgotPass( 3 )} size="middle">
                  Proceed
                </Button>
              </Col>
              <Col span={12}>
                <Button block type="primary" ghost onClick={() => setVisible( false )} size="middle">
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}>
            {/* <LessDivider /> */}
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Button type="link" onClick={() => setForgotPass( 4 )}>
              Can’t access them now?
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );

  const ResetPassForm = () => {
    const [ check, setCheck ] = React.useState( false );
    const [ errorMsg, seterrorMsg ] = React.useState( );
    const handlePasswordSet = async values => {
      const pathnameArray = location.pathname.split( '/' );
      const index = pathnameArray.indexOf( 'password-set' );
      const token = pathnameArray[ index + 1 ];
      try {
        const response = await onPasswordSetSubmit( { token, ...values } );
        if ( response.success ) {
          setForgotPass( 5 );
          setValue( 1 );
        }
        else if(response?.errors?.nonFieldErrors?.length){
          seterrorMsg(response?.errors?.nonFieldErrors[0])
        }
        else if(response?.errors?.other_field?.length){
          seterrorMsg(response?.errors?.other_field[0])
        }
        else if(response?.errors?.field_name?.length){
          seterrorMsg(response?.errors?.field_name[0])
        }
      } catch ( e ) {
        // throw new Error( e.message );
      }
    };
    return (
      <>
       
        <h2>Set Your Password</h2>
        {/* <LessDivider up /> */}
        <Form
          {...layout}
          requiredMark={false}
          name="resetPassword"
          onFinish={handlePasswordSet}
          validateMessages={validateMessages}
        >
          <Form.Item
            name="newPassword1"
            label="Password"
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              },
              {
                min: 8,
                message: 'Password length should be atleast 8.'
              }
            ]}
            hasFeedback
          >
            <Input.Password autoComplete='off'/>
          </Form.Item>

          <Form.Item
            name="newPassword2"
            label="Confirm Password"
            dependencies={[ 'newPassword1' ]}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              {
                min: 8,
                message: 'Password length should be atleast 8.'
              },
              ( { getFieldValue } ) => ( {
                validator( _, value ) {
                  if ( !value || getFieldValue( 'newPassword1' ) === value ) return Promise.resolve();

                  return Promise.reject( new Error( 'The two passwords that you entered do not match!' ) );
                }
              } )
            ]}
          >
            <Input.Password autoComplete='off'/>
          </Form.Item>
            <Form.Item 
              name='privacyCheckbox'
              label=''
              valuePropName='checked'>
              <Checkbox onClick={() => setCheck( !check )}>
                I accept
                <a href={PAGE_ROUTE.privacy}> Privacy policy </a>
                and
                <a href={PAGE_ROUTE.termsAndCondition}> Terms of Services</a>
              </Checkbox>
            </Form.Item>
          {/* <LessDivider /> */}
          {errorMsg && (
          <p style={{color:'red',marginBottom:'10px'}}>{errorMsg?.message}</p>
          )}
          <Row gutter={16} style={{ paddingTop: "10px" }}>
            <Col lg={12} md={12}>
              <Button block type="primary" htmlType="submit" disabled={!check} /* onClick={() => setForgotPass(5)} */ size="middle">
                Set Password
              </Button>
            </Col>
            <Col lg={12} md={12}>
              <Button block type="primary" ghost onClick={() => setVisible( false )} size="middle">
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const OTPFailed = () => (
    <>
      <h2>No account access</h2>
      {/* <LessDivider up /> */}
      <h3>
        Unfortunately, we cannot give you access to the account you are trying to log in to. This is happening because we are
        not able to confirm that this is your account.{' '}
      </h3>
      <Row>
        <Col lg={0} md={0} sm={24} xs={24}>
          {/* <LessDivider /> */}
        </Col>
        <Row align="middle">
          <Col lg={12} md={12} sm={0} xs={0} />
          <Col lg={12} md={12} sm={24} xs={24}>
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  // onClick={() => setForgotPass(3)}
                  size="middle"
                >
                  Contact Us
                </Button>
              </Col>
              <Col span={12}>
                <Button block type="primary" ghost onClick={() => setVisible( false )} size="middle">
                  Contact Us
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg={0} md={0} sm={24} xs={24}>
            {/* <LessDivider /> */}
          </Col>
        </Row>
      </Row>
    </>
  );

  const ResetPasswordSuccess = () => (
    <>
      {/* <LessDivider /> */}
      <Row>
        <Col lg={24} md={24} sm={0} xs={0}>
          <br />
        </Col>
      </Row>
      <div style={{ textAlign: 'center' }}>
        <MailSent>
          <
// @ts-ignore
          CheckCircleFilled /> &nbsp;
          <span>Your Password has been Set. </span>
        </MailSent>
        <Row>
          <Col lg={0} md={0} sm={24} xs={24}>
            <Button type="primary" ghost onClick={() => {setForgotPass( 0 );setVisible( false );history.push( ROUTE.login );}} size="middle">
              Login
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <br />
          </Col>
        </Row>
        {/* <LessDivider /> */}
        <Row>
          <Col lg={24} md={24} sm={0} xs={0}>
            <Button
              type="primary"
              ghost
              onClick={() => {
                setForgotPass( 0 );
                setVisible( false );
                history.push( ROUTE.login );
              }}
              size="middle"
            >
              Login
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );

  return (
    <>
      {/* <ForgotPass onClick={() => setVisible(true)}>Forgot password?</ForgotPass> */}
      <
// @ts-ignore
      Modal footer={null} centered visible={visible} onCancel={() => {setVisible( false );history.push( ROUTE.login );}} bodyStyle={{ padding: '0px' }}>
        <ModalBody>
          {forgotPass === 0 && <EnterEmailForm />}
          {forgotPass === 1 && <SelectResetOptForm />}
          {forgotPass === 2 && value === 1 && <EmailSent />}
          {forgotPass === 2 && value === 2 && <EnterOTP />}
          {forgotPass === 3 && <ResetPassForm />}
          {forgotPass === 4 && <OTPFailed />}
          {forgotPass === 5 && <ResetPasswordSuccess />}
        </ModalBody>
      </Modal>
    </>
  );
};

export default withRouter( PasswordSetView );

const MailSent = styled.div`
  color: #009c4a;
  font-size: 25px;
`;

const LessDivider = styled( Divider )`
  margin-top: ${props => {
    // @ts-ignore
    return props.up && '0px';
  }};
  @media only screen and (min-width: 480px) {
    margin: 0;
    margin-bottom: 0.5em;
  }
`;

// @ts-ignore
// eslint-disable-next-line no-unused-vars
const ForgotPass = styled.div`
  float: right;
  color: grey;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  /* @media only screen and (max-width: 480px) {

  } */
`;

const ModalBody = styled.div`
  padding: 24px 48px;
  @media only screen and (max-width: 480px) {
    padding: 24px;
  }
`;

// @ts-nocheck
import React from 'react';
import { Row, Col, Button, Dropdown, Menu, Table, Spin,Divider } from 'antd';
import { LeftOutlined,DownOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import ResponseSetTable from '../../../survey/components/Surver360ResponseSetTable'
import TeamMember360ReportView from '../../../survey/components/TeamMember360SurveyReport'
import { Capitalize } from 'modules/look';

const Survey360ManagerReportView = props => {
    const {history,SurveyReport,surveyDetail,employees,changeUser,responseSet,get360Report } = props
    const [loading, setloading] = React.useState(true);
    const [selectedEmployee,SetSelectedEmployee] = React.useState();
    const [userResponseSet, setuserResponseSet] = React.useState([]);
    const [Report360, set360Report] = React.useState();
    const [SurveyReportDetail, setSurveyReportDetail] = React.useState();

    React.useEffect(()=>{
        if(SurveyReport){
            setSurveyReportDetail(SurveyReport)
            setloading(false)    
        }
    },[SurveyReport])

    React.useEffect(()=>{
        if(Report360){
            setloading(true)
            get360Report({user:selectedEmployee?.id,id:Report360?.id})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[Report360])

    React.useEffect(()=>{
        if(surveyDetail){
         setloading(false)
        }
    },[surveyDetail])

    React.useEffect(()=>{
        if(responseSet){
            setSurveyReportDetail(null)
            let user = responseSet?.employee?.organizationSet?.edges[0]?.node?.employees?.edges?.find(item=> item?.node?.user?.id === selectedEmployee?.id)
            if(user){
                let list = user?.node?.user?.responseSet?.edges?.map(({node})=>node)
                setuserResponseSet(list)
            }
            else{
                setuserResponseSet([]) 
            }
            setloading(false) 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[responseSet])

    React.useEffect(()=>{
        if(selectedEmployee){
            setloading(true)
            changeUser({user:selectedEmployee?.id})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedEmployee])

    const back = () => {
    //   window.history.back();
    history.goBack()
    };

    const handleMenuClick = (data)=>{
        SetSelectedEmployee(data)
    }

    const menu =()=> (
        <Menu style={{maxHeight:'200px',overflow:'auto'}}>
          {
            employees?.length?
            employees?.map((data)=>
              <Menu.Item key={data?.employee?.id} onClick={()=>handleMenuClick(data)}>
                {Capitalize(data?.firstName)}
              </Menu.Item>
              )
            :
            <Menu.Item >
             no user found
           </Menu.Item>
          }
        </Menu>
      );

    return (
        <>
         <Spin spinning={loading}>
          <div style={{padding:'2em 20px'}}>
             {
                surveyDetail && (
                   <>
                    <RowDiv >
                    <Col span={18} >
                        <h4 style={{fontSize:'20px'}} id="team_report_title">{selectedEmployee && (<span>{Capitalize(selectedEmployee?.firstName)}'s</span>)} 360 Report for {surveyDetail?.name}</h4>
                    </Col>
               
                        
                        <Button shape="circle" style={{marginLeft:'20px'}} onClick={back}>
                            <LeftOutlined />
                        </Button>
              
                    </RowDiv>
                    <div>
                    <Col>
                       <Dropdown overlay={menu}>
                           <DropdownButton id="fd_recipient">
                       
                               <p style={{marginTop:'10px',width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left'}}>{Capitalize(selectedEmployee?.firstName)??'Select an employee'}</p>
                               <DownOutlined />
                         
                           </DropdownButton>
                         </Dropdown>
                     </Col>

                
                        { 
                            selectedEmployee && (
                                <ColScroll style={{height:'250px',overflow:'auto',margin:'15px 0px',padding:'0px 15px'}}>
                                    <ResponseSetTable
                                    dataSource ={userResponseSet}
                                    setChart = {(e)=>{set360Report(e)}}
                                    selectedDetail = {
                                        {
                                            name:Capitalize(selectedEmployee?.firstName),
                                            survey:surveyDetail?.name
                                        }
                                    }
                                    />
                                </ColScroll>
                               
                            )
                        }
                 

                        {
                            SurveyReportDetail && (
                              <>
                                <Divider style={{ borderColor: "#7cb305" }}/>
                                <TeamMember360ReportView
                                SurveyReport={SurveyReportDetail}
                                downloadComplete={()=>{setSurveyReportDetail(null);set360Report(null)}}
                                loading={(e)=>{setloading(e)}}
                                {...props}
                                />
                              </>
                            )
                        }
                         
                    </div>
                   </>
                ) 
             }
          </div>
         </Spin>
        </>
    )
 
};

export default Survey360ManagerReportView

const RowDiv =styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`
export const MyTable = styled(Table)`
  table {
    th{
        text-align:center !important;
    }
    tbody {
      th,td{
          padding:3px 5px 0px 5px !important;  
          font-size:13px;
          text-align:center;
      }  
      tr {
        td { 
          border: 1px solid rgba(0, 0, 0, 0.06) !important;
          border-style: solid none !important;
        }
        td:first-child {
          border-left-style: solid !important;
        }
        td:last-child {
          border-right-style: solid !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06) !important;
        }
      }
    }
  }
`;

const DropdownButton = styled(Button)`
width:230px;
display:flex;
justify-content:space-between;
align-items:center;
gap:5px
`

const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#B0CB1F;
}
`
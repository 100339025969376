// @ts-nocheck
import { Progress, Tooltip } from "antd";
import { getIntFromString } from "modules/layout/Layout";
import React from "react";
import { Link } from 'react-router-dom';
import Call_Made from '../../assets/call-made.svg'
const PerformanceBar = (props) => {
    const { performanceData, Height, type, loading } = props
    
    return (
        <>
            {type === 'Organization_Dashboard' ? <div className="performamce-overFlow horizontal-overflow-team-dashboard" style={{ maxHeight: Height ? Height : "430px", paddingBottom: "10px" }}>
                {performanceData?.edges?.length > 0 && <div className="performance-graph " style={{ width: '100%', position: "relative", Height: Height ? Height : '100%', minWidth: "480px" }}>
                    {performanceData?.edges?.map((item) => (
                        <div style={{ marginBottom: "25px", position: "relative" }}>
                            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                <Tooltip title={item?.title}>
                                    <h4 className="okr-performance-graph-title">{item?.title}</h4>
                                </Tooltip>
                                {item?.isOkr && <div>
                                    {item?.okrType === 'OBJECTIVE' ? <Link to={`/role/objective-keyresult/view/${getIntFromString(item?.id)}`} target="_blank">
                                        <img src={Call_Made} alt="" style={{ marginLeft: "10px", height: "14px", cursor: 'pointer' }} />
                                    </Link> :
                                        item?.okrType === 'KEY_RESULT' ? <Link to={`/role/objective-keyresult/keyresult/view/${getIntFromString(item.id)}`} target="_blank">
                                            <img src={Call_Made} alt="" style={{ marginLeft: "10px", height: "14px", cursor: 'pointer' }} />
                                        </Link> :
                                            <Link to={`/role/objective-keyresult/milestone/view/${getIntFromString(item.id)}`} target="_blank">
                                                <img src={Call_Made} alt="" style={{ marginLeft: "10px", height: "14px", cursor: 'pointer' }} />
                                            </Link>
                                    }
                                </div>}
                            </div>

                            <Progress percent={(item?.progress < 5 || isNaN(item?.progress)) ? 5 : item?.progress}
                                className="performance-progress"
                                strokeColor={{
                                    "0%": '#25EA68',
                                    "100%": '#02D7A4',
                                }}
                                style={{
                                    display: "block",
                                }}
                                showInfo={false} />
                            {item?.progress > 0 ? <div className="progress-percentage" style={{ left: `${(item?.progress - 8) <= 0 ? 0 : (item?.progress - 8)}%` }}>
                                {Math.round(item?.progress)}%
                            </div> : <div className="progress-percentage">
                                {0}%
                            </div>}
                        </div>
                    )
                    )}
                </div>}
                {performanceData?.edges?.length === 0 && !loading && <div style={{ display: "flex", marginBottom: "25px", width: "100%", paddingTop: "100px", justifyContent: "center", alignItems: 'center' }}>
                    <h4 className="graph-title" style={{ textAlign: "center" }}>No Performance Data</h4>
                </div>}
            </div> : <div className="performamce-overFlow horizontal-overflow-team-dashboard" style={{ maxHeight: Height ? Height : "430px", paddingBottom: "10px" }}>
                {performanceData?.length > 0 && <div className="performance-graph " style={{ width: '100%', position: "relative", height: Height ? Height : '100%', minWidth: "480px" }}>
                    {performanceData?.map((item) => (
                        <div style={{ marginBottom: "25px", position: "relative" }}>
                            <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                                <Tooltip title={item?.title}>
                                    <h4 className="okr-performance-graph-title">{item?.title}</h4>
                                </Tooltip>
                                {item?.isOkr && <div>
                                    {item?.okrType === 'OBJECTIVE' ? <Link to={`/role/objective-keyresult/view/${getIntFromString(item?.id)}`} target="_blank">
                                        <img src={Call_Made} alt="" style={{ marginLeft: "10px", height: "14px", cursor: 'pointer' }} />
                                    </Link> :
                                        item?.okrType === 'KEY_RESULT' ? <Link to={`/role/objective-keyresult/keyresult/view/${getIntFromString(item.id)}`} target="_blank">
                                            <img src={Call_Made} alt="" style={{ marginLeft: "10px", height: "14px", cursor: 'pointer' }} />
                                        </Link> :
                                            <Link to={`/role/objective-keyresult/milestone/view/${getIntFromString(item.id)}`} target="_blank">
                                                <img src={Call_Made} alt="" style={{ marginLeft: "10px", height: "14px", cursor: 'pointer' }} />
                                            </Link>
                                    }
                                </div>}
                            </div>

                            <Progress percent={(item?.progress < 5 || isNaN(item?.progress)) ? 5 : item?.progress}
                                className="performance-progress"
                                strokeColor={{
                                    "0%": '#25EA68',
                                    "100%": '#02D7A4',
                                }}
                                style={{
                                    display: "block",
                                }}
                                showInfo={false} />
                            {item?.progress > 0 ? <div className="progress-percentage" style={{ left: `${(item?.progress - 8) <= 0 ? 0 : (item?.progress - 8)}%` }}>
                                {Math.round(item?.progress)}%
                            </div> : <div className="progress-percentage">
                                {0}%
                            </div>}
                        </div>
                    )
                    )}
                </div>}
                {performanceData?.length === 0 && !loading && <div style={{ display: "flex", marginBottom: "25px", width: "100%", paddingTop: "100px", justifyContent: "center", alignItems: 'center' }}>
                    <h4 className="graph-title" style={{ textAlign: "center" }}>No Performance Data</h4>
                </div>}
            </div>}

        </>
    )
}

export default PerformanceBar


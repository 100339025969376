// @ts-nocheck
import React from "react";
import { Button, Modal } from "antd";
import bim from '../../assets/bin.png'
import accept_icon from '../../assets/accept.png'
import pending_icon from '../../assets/hour_glass.png'
import msg_active from '../../assets/message_active.png'
import msg_inactive from '../../assets/message_inactive.png'
import "../Css/survey-comparison-req.css";
import { displayUtcToUserTimezone } from "modules/look/components/functions";
import ReactMarkdown from "modules/look/components/ReactMarkdown";
import { getIntFromString } from "modules/layout/Layout";
const ComparisonRequestSent = (props) => {
    const { request_list_info, survey, me, deleteResponseCompareRequest, getRequestList } = props
    const [request_list, setRequestList] = React.useState([])
    const [has_next_page, setHasNextPage] = React.useState(false)
    const [cursor, setCursor] = React.useState()
    const [expanded_msg, setExpandedMsg] = React.useState([])
    const [delete_req, setdelete_req] = React.useState()
    React.useEffect(() => {
        if (request_list_info?.length) {
            let data = request_list_info[0]
            setHasNextPage(data?.pageInfo?.hasNextPage)
            setCursor(data?.pageInfo?.endCursor)
            if (data?.pagination) {
                setRequestList(request_list.concat(data?.edges?.map(({ node }) => node)))
            } else {
                setRequestList([].concat(data?.edges?.map(({ node }) => node)))
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request_list_info])

    const openMsg = (data) => {
        let msg_list = [].concat(expanded_msg)
        if (msg_list?.includes(data?.id)) {
            msg_list = msg_list.filter(item => String(item) !== String(data?.id))
        } else {
            msg_list.push(data?.id)
        }
        setExpandedMsg(msg_list)
    }

    const handleDelete = async (item) => {
        try {
            let res = await deleteResponseCompareRequest({ id: getIntFromString(item?.id) })
            if (res) {
                let list = [].concat(request_list)
                list = list?.filter(val => String(val?.id) !== String(item?.id))
                setdelete_req(null)
                setRequestList(list)
            }
        } catch (error) {

        }
    }

    const changePage = () => {
        getRequestList({ survey: survey?.id, sender: me?.user?.id, endCursor: cursor, pagination: true })
    }

    return (
        <>
            {
                request_list?.length ?
                    <>
                        <div className="request-data-container">
                            {request_list?.map(item => (
                                <div className="request-main-padding">
                                    <div className="request-card">
                                        <div className="request-card-section">
                                            <div className="profile-info-contianer">
                                                <div className={`req-user-image-div ${!item?.receiver?.profile?.profileImage ? 'req-user-char-div' : ''}`}>
                                                    {
                                                        item?.receiver?.profile?.profileImage ?
                                                            <img src={item?.receiver?.profile?.profileImage} className="profile-image" alt="" />
                                                            :
                                                            <h4 className="text-transform-capitalize">{item?.receiver?.firstName.charAt(0).toUpperCase()}</h4>
                                                    }

                                                </div>

                                                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                                    <h4 className="request-user-name text-transform-capitalize">{item?.receiver?.firstName} {item?.receiver?.lastName || ""}</h4>
                                                    <h4 className="request-user-email ">{item?.receiver?.email}</h4>
                                                </div>

                                            </div>
                                            <img src={bim} onClick={() => { setdelete_req(item) }} className="request-bin" alt="" />
                                        </div>
                                        <div className="request-card-section" style={{ justifyContent: "start" }}>
                                            <h4>{displayUtcToUserTimezone(item?.requestDate, 'DD/MM/yyy')}</h4>
                                            {
                                                item?.accepted ?
                                                    <h4 className="request-accepted">
                                                        <img src={accept_icon} alt="" /> <span>accepted</span>
                                                    </h4>
                                                    :
                                                    <h4 className="request-pending">
                                                        <img src={pending_icon} alt="" /> <span>pending</span>
                                                    </h4>
                                            }
                                        </div>

                                        <div className="request-card-section">
                                            <h4>{`Requested ${item?.survey?.name} response`}</h4>
                                            <img src={expanded_msg?.includes(item?.id) ? msg_active : msg_inactive} onClick={() => openMsg(item)} className="req-msg-icon" alt="" />
                                        </div>

                                        {
                                            expanded_msg.includes(item?.id) && (
                                                <div>
                                                    <ReactMarkdown>{item?.message}</ReactMarkdown>
                                                </div>
                                            )
                                        }

                                        {/* <div align="center" style={{ width: "100%" }}>
                                            <Button className="comp-request-btn" disabled={!item?.accepted} onClick={() => { handleRequstSelect(item) }} >View</Button>
                                        </div> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {
                            has_next_page && (
                                <div align="center">
                                    <h4 className="req_pagination_view" onClick={() => { changePage() }}>View more</h4>
                                </div>
                            )
                        }
                    </>
                    :
                    <div align="center">
                        <h4>No request found</h4>
                    </div>
            }

            <Modal visible={delete_req} footer={false} destroyOnClose={true} onCancel={() => { setdelete_req(null) }}>
                <div align="center">
                    <h4 style={{ fontSize: "1.2em", margin: ".5em" }}>Are you sure you want to delete this request?</h4>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <Button className="comp-request-btn" style={{ margin: "5px", fontSize: "1em", fontWeight: "500" }} onClick={() => { handleDelete(delete_req) }} >Yes</Button>
                        <Button className="comp-request-btn" style={{ margin: "5px", fontSize: "1em", fontWeight: "500" }} onClick={() => { setdelete_req(null) }} >No</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ComparisonRequestSent;

import React from 'react';
import { BorderOutlined, QuestionOutlined, SnippetsOutlined, GroupOutlined, CodeOutlined } from '@ant-design/icons';

import AllOrganizationSurvey from './containers/AllOrganizationSurvey';
import SurveyQuiz from './containers/SurveyQuiz';
import TokenSurvey from './containers/TokenSurvey';
import SurveyResponse from './containers/SurveyResponse';

import Group from './containers/Group';
import Question from './containers/Question';
import Survey from './containers/Survey';
import Token from './containers/Token';

// import OrgSurvey from './containers/OrgSurvey';
// import VerSurvey from './containers/VerSurvey';
// import TemSurvey from './containers/TemSurvey';

import Response from './containers/Response';
import AddToken from './containers/AddToken';
import AddMultipleToken from './containers/AddMultipleToken';
import AddGroup from './containers/AddGroup';
import AddQuestion from './containers/AddQuestion';
import AddSurvey from './containers/AddSurvey';
import AddResponse from './containers/AddResponse';

// import EditToken from './containers/EditToken';
import EditGroup from './containers/EditGroup';
import EditQuestion from './containers/EditQuestion';
import EditSurvey from './containers/EditSurvey';
import SurveyDetail from './containers/SurveyDetail';
import SurveyQuizResponse from './containers/SurveyQuizResponse';


// import EditResponse from './containers/EditResponse';

import ROUTE from './route';
import TakeTestSurveyContainer from './containers/TakeTestSurvey'
import SoulSurveySetContainer from './containers/SoulSurveySetContainer'
import soulValueSubmissionContainer from './containers/soulValueSubmissionContainer';
import SurveyTokenContainer from './containers/SurveyTokenContainer';
import SurveyTypeHandler from './containers/SurveyTypeHandler';
import threecsReportContainer from './containers/threecsReportContainer';
export default [
  {
    name: 'AllOrganizationSurvey',
    path: ROUTE.allOrganizationSurvey,
    component: AllOrganizationSurvey
  },
  {
    name: 'Group',
    path: ROUTE.group,
    component: Group,
    icon: <GroupOutlined />,
    admin: true
  },
  {
    name: 'Question',
    path: ROUTE.question,
    component: Question,
    icon: <QuestionOutlined />,
    admin: true
  },
  {
    name: 'EditSurvey',
    path: ROUTE.editSurvey,
    component: EditSurvey,
    admin: true
  },
  // {
  //   name: 'OrgSurvey',
  //   path: ROUTE.orgSurvey,
  //   component: OrgSurvey,
  //   admin: true,
  //   icon: <SnippetsOutlined />
  // },
  // {
  //   name: 'VerSurvey',
  //   path: ROUTE.verSurvey,
  //   component: VerSurvey,
  //   admin: true,
  //   icon: <SnippetsOutlined />
  // },
  // {
  //   name: 'TemSurvey',
  //   path: ROUTE.teaSurvey,
  //   component: TemSurvey,
  //   admin: true,
  //   icon: <SnippetsOutlined />
  // },
  {
    name: 'Survey',
    path: ROUTE.survey,
    component: Survey,
    icon: <SnippetsOutlined />,
    adminDropdown: true,
    admin: true,
    protect: true
  },
  {
    name: 'Token',
    path: ROUTE.token,
    component: Token,
    adminDropdown: true,
    icon: <CodeOutlined />,
    admin: true
  },
  {
    name: 'Response',
    path: ROUTE.response,
    component: Response,
    adminDropdown: true,
    icon: <BorderOutlined />,
    admin: true
  },
  {
    name: 'TokenSurvey',
    path: ROUTE.tokenSurvey,
    component: TokenSurvey
  },
  {
    name: 'SurveyDetail',
    path: ROUTE.surveyDetail,
    component: SurveyDetail
    // adminDropdown: true,
    // icon: <MacCommandOutlined />
  },
  {
    name: 'AddSurvey',
    path: ROUTE.addSurvey,
    component: AddSurvey,
    admin: true
  },
  {
    name: 'SurveyQuizView',
    path: ROUTE.surveyQuizResponse,
    component: SurveyQuizResponse,
    protect: true
  },
  {
    name: 'Survey',
    path: ROUTE.surveyQuiz,
    component: SurveyQuiz,
    protect: true
  },
  {
    name: 'AddToken',
    path: ROUTE.addToken,
    component: AddToken,
    admin: true
  },
  {
    name: 'AddMultipleToken',
    path: ROUTE.addMultipleToken,
    component: AddMultipleToken,
    admin: true
  },
  {
    name: 'AddGroup',
    path: ROUTE.addGroup,
    component: AddGroup,
    admin: true
  },
  {
    name: 'AddQuestion',
    path: ROUTE.addQuestion,
    component: AddQuestion,
    admin: true
  },
  {
    name: 'AddResponse',
    path: ROUTE.addResponse,
    component: AddResponse
  },
  // {
  //   name: 'EditToken',
  //   path: ROUTE.editToken,
  //   component: EditToken,admin: true,
  // },
  {
    name: 'EditGroup',
    path: ROUTE.editGroup,
    component: EditGroup,
    admin: true
  },
  {
    name: 'EditQuestion',
    path: ROUTE.editQuestion,
    component: EditQuestion,
    admin: true
  },

  // if uncommeted causes the surveyResponse page to not respond
  // {
  //   name: 'EditResponse',
  //   path: ROUTE.editResponse,
  //   component: EditResponse,admin: true,
  // },
  {
    name: 'SurveyResponse',
    path: ROUTE.surveyResponse,
    component: SurveyResponse,
    exact: true
  },
  {
    name: 'takeTestSurvey',
    path: ROUTE.takeTestLink,
    component: TakeTestSurveyContainer,
    exact: true,
    protect:true
  },
  {
    name: 'soulSurveySet',
    path: ROUTE.soulSurveyset,
    component: SoulSurveySetContainer,
    exact: true,
    protect:true
  },
  {
    name: 'soulSurveySubmission',
    path: ROUTE.soulSurveySubmission,
    component: soulValueSubmissionContainer,
    exact: true,
    protect:true
  },
  {
    name: 'EditSurveyResponse',
    path: ROUTE.uiFlowEditSurveyResponse,
    component:SurveyTypeHandler,
    exact: true,
    admin: false,
    protect:true
  },
  {
    name: '360 survey token',
    path: ROUTE.surveyToken,
    component: SurveyTokenContainer,
    exact: true
  },
  {
    name: '3cs Survey',
    path: ROUTE.surveyQuiz3cs,
    component: SurveyQuiz,
    protect: true
  },
  {
    name: 'Whole',
    path: ROUTE.surveyQuizWhole,
    component: SurveyQuiz,
    protect: true
  },
  {
    name: '3C Report',
    path: ROUTE.report3cs,
    component: threecsReportContainer,
    exact: true,
    protect: true
  }
];

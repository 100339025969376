// @ts-nocheck
import { Col, Form, Button, Tooltip, message } from "antd";
import React from "react";
import edit_pencil from '../../assets/edit_pencil.svg'
import TextInputField from "../formComponents/Text-input-field";
import okr_route from '../route/index'
import close from "../../assets/close.svg";
import { changeToCurrentTime, displayUtcToUserTimezone, getIntFromString } from "modules/look";
import RangeDatePicker from "modules/look-v2/Components/date-range-picker";
import ProgressUpdateContainer from "../formComponents/Container/progressUpdateContainer";
import black_eye from "../../assets/black_eye.svg";
import blue_eye from "../../assets/blue_eye.svg";
import view from '../../assets/view.svg'
import AddWeightContainer from "../formComponents/Container/AddWeightContainer";
import moment from "moment-timezone";
import CommaSeparateComponent from "../formComponents/CommaSeparatedComponent";
import CustomProgress from "modules/look-v2/Components/Progress";
import black_discard from '../../assets/black_discard.svg'
import delete_outline_black from '../../assets/delete_outline_black.svg'
import TableRowMenu from "modules/look-v2/Components/TableRowMenu";
import ExpandableDropdownContainer from "modules/look-v2/Container/ExpandableDropdownContainer";
import DeleteOkrDetails from "./deleteOkrDetails.";
import keyresult_expnasion_arrow from "../../assets/keyresult_expnasion_arrow.svg";
import grey_expansion from "../../assets/grey_expansion.svg";
import blue_expansion from "../../assets/blue_expansion.svg";
import RequestDiscardView from "./requestDiscardView";
import menu_cascade from '../../assets/menu_cascade.svg'
import menu_del_info from '../../assets/menu_del_info.svg'
import kodos_award from '../../assets/kodos-award-icon.svg'
import AwardBadgeContainer from "modules/kudos-user/container/awardBadgeContainer";

import CustomOkrRangeTable from "modules/look-v2/Components/customOkrDatePickerForTable";
const SubMileStoneDetailTableView = (props) => {
  const { me,customClass,allsubMilestone,addChild,updateOkr, isDiscarded, MilestoneDetail,setloading,okrDiscarded,set_okr_tree,permission,navigateRoute} = props
  // eslint-disable-next-line no-unused-vars
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [createMilestoneCounter, setcreateMilestoneCounter] = React.useState([]);
  const [dataSource, setDataSource] = React.useState();
  const [deleteRecord, setDeleteRecord] = React.useState();
  // eslint-disable-next-line no-unused-vars
  const [pageloading, setpageloading] = React.useState(false)
  const [expandedRow, setexpandedRow] = React.useState([])
  const [discardPopupvisible, setDiscardPopupVisible] = React.useState(false);
  const [discardOKRDetail, setdiscardOKRDetail] = React.useState();
  const [deleteModal,setDelateModal] = React.useState(false);
  const [addAward,setAddAward]=React.useState(false)
  const [selectedKudos,setSelectedKudos]=React.useState()
  let cunterRef = React.useRef(0)


  React.useEffect(() => {
    if (allsubMilestone) {
      // setDataSource([])
  
      let incomingData = allsubMilestone?.edges?.map(({ node }) => {
        return {
          id: node?.okr?.id,
          title: node?.okr?.title,
          okrType: node?.okrType,
          startdate: node?.okr?.startdate,
          duedate: node?.okr?.duedate,
          goalStatement: node?.okr?.goalStatement,
          correctionDeadline: node?.okr?.correctionDeadline,
          contributors:node?.okr?.contributorSet?.edges?.map(({node})=>
          {
              return{
                  ...node?.employee?.user,role:node?.role,employee_id:node?.employee?.id
              }
          }),
          progress: node?.okr?.progress ?? 0,
          childSet: node?.okr?.childSet?.totalCount ? true : false,
          discardRequestRaised:node?.okr?.discardRequestRaised.totalCount>0?true:false,
          isDiscarded: {
            status: (isDiscarded === "APPROVED") ? isDiscarded :
              node?.okr?.isDiscarded?.status ?
                node?.okr?.isDiscarded?.status :
                node?.okr?.discardRequest?.totalCount ? "PENDING" : ''
            // id: node?.okr?.isDiscarded?.id ? node?.okr?.isDiscarded?.id :
              // node?.okr?.discardRequest?.totalCount ? node?.okr?.discardRequest?.edges[node?.okr?.discardRequest?.edges?.length - 1]?.node?.id : ''
          }
        }
      })
      
      setDataSource(incomingData)
      setloading(false)

      setpageloading(false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allsubMilestone])

  React.useEffect(() => {
    if (addChild === true) {
      addToMilestone()
    } else if (addChild === false) {
      addToMilestone()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addChild])

  const addToMilestone = () => {
    if(!createMilestoneCounter?.length){
    cunterRef['current'] = cunterRef['current'] + 1
    let list = [].concat(createMilestoneCounter);
    list.push({ key: `MileStone_${cunterRef?.current}` });
    setcreateMilestoneCounter(list);
    }
  };

  const expand = (id) => {
    let list = [].concat(expandedRow)
    if (!list?.includes(id)) {
      list.push(id)
    } else {
      list = list.filter(item => item !== id)
    }
    setexpandedRow(list)
  }



  const updateDelete = (id) => {
    setDeleteRecord(null)
    let list = [].concat(dataSource)
    list = list.filter(item => item?.id !== id)
    setDataSource(list)
  }


  const onTableMenuClickAction = (record, action) => {

    if (action === 'view') {
      navigateRoute(okr_route?.milestoneDetail?.replace(':okrId', getIntFromString(record?.id)))
    } else if (action === 'edit') {
      updateQue(record, true)
    } else if (action === 'discard') {
      setdiscardOKRDetail(record);
      setDiscardPopupVisible(true);
    } else if (action === 'delete') {
      setDeleteRecord(record);
      setDelateModal(true)
    }
    else if (action === 'tree') {
      set_okr_tree(record);
  }else if(action==='kodos'){
    setAddAward(true)
    setSelectedKudos(record)
}
  }


  const onUpdate = async (okr) => {
    let formValue = updateForm.getFieldsValue()
    let data = {}
    if (formValue[`${okr?.id}_goalStatement`]) {
      data['goalStatement'] = formValue[`${okr?.id}_goalStatement`]
    }

    if (formValue[`${okr?.id}_duedate`]?.length === 2) {
      let dates = formValue[`${okr?.id}_duedate`]
      data['startdate'] = dates[0].toISOString()
      let due_date = changeToCurrentTime(dates[1])
      data['duedate'] = due_date?.toISOString()
    }

    if (!Object.keys(data)?.length) {
      message.error('update the form')
    } else {
      data['id'] = okr?.id
      let response = await updateOkr(data)
      if (response?.id) {
        let list = [].concat(dataSource)
        let item = list?.find(val => val?.id === okr?.id)
        if (item) {
          item['duedate'] = response?.duedate
          item['startdate'] = response?.startdate
          item['title'] = response?.title
          item['goalStatement'] = response?.goalStatement
          item.isUpdate = false
        }
        setDataSource(list)
      } else {
        message.error('failed to update')
      }
    }
  }

  const updateQue = (record, isUpdate) => {
    let list = [].concat(dataSource)
    let item = list?.find(okr => okr?.id === record?.id)
    item['isUpdate'] = isUpdate
    setDataSource(list)
  }


  const addContributor=(contributor,item)=>{
    let list = [].concat(dataSource)
    let record = list?.find(val=>val?.id===item?.id)
    if(record){
      let filter_list =record?.contributors?.filter(user=>user?.id===me?.employee?.user?.id)
      let updates = Object.values(contributor?.data)?.map(({contributor})=>contributor?.employee?.user)
      if(updates?.length){
        record.contributors=[...filter_list,...updates]
        setDataSource(list)
      }

    }
  }
  const update_progress=(okrid,progress)=>{
    let data_source_progress = [].concat(dataSource)
    let okr = data_source_progress.find(item=>item?.id===okrid)
    
    if(okr){
        okr.progress=progress
    }
    setDataSource(data_source_progress)
  }


  return (
    <>
      {
      deleteRecord &&
      <DeleteOkrDetails visible={deleteModal} type="Milestone" deletesuccess={(e) => { updateDelete(e) }} closeModal={() => { setDeleteRecord(null) }} okr={deleteRecord?.id} {...props} />
    }
     <RequestDiscardView onClose={()=>{setDiscardPopupVisible(false)}} visible={discardPopupvisible} {...props} discardData={{ id: discardOKRDetail?.id }} />
      <Col className="custom-table-v2">

        {
          
          <Form
            form={updateForm}
            name="Objective_update"
            autoComplete="off"
          >
            {dataSource?.map((item, index) => (
              <Col>
                <div className="row ">
                  {!customClass&&<div className="expand-table-sider" style={{ width: "50px" }} >
                    {/* M */}
                  </div>}
                  <div className="custom-table-tr table-body-tr-border" style={{
                    borderLeft: (item?.isDiscarded?.status === 'APPROVED') ? "5px solid #141414" :
                      (item?.isDiscarded?.status === 'PENDING') ? "5px solid #009AF1" : "5px solid purple",
                    position: (item?.isDiscarded?.status === 'APPROVED' || item?.isDiscarded?.status === 'PENDING') ? "relative" : ""
                  }}>
                    {
                      (item?.isDiscarded?.status === 'APPROVED') ?
                        <div className="table-overlay">
                          <img src={item?.isDiscarded?.status === 'APPROVED' ? black_eye : blue_eye} onClick={() => { navigateRoute( okr_route.milestoneDetail.replace(":id",getIntFromString(item?.id))) }} className="table-eye" alt="" style={{ marginLeft: "320px", cursor: "pointer" }} />
                        </div> : ''
                    }
                    <div className="table-section-td" style={{ width: "290px", minWidth: "280px" }}>
                      
                    { item?.childSet && (
                            <img
                            src={
                              item?.isDiscarded?.status === "PENDING"
                                ? blue_expansion
                                : item?.isDiscarded?.status === "APPROVED"
                                ? grey_expansion
                                : keyresult_expnasion_arrow
                            }
                            alt=""
                            onClick={(e) => {
                              expand(item?.id);
                            }}
                            style={{ zIndex: "1" }}
                            className={`expansion-arrow ${
                              expandedRow?.includes(item?.id)
                                ? "expansion-arrow-down"
                                : ""
                            }`}
                          />
                       )}
                      {
                        item?.isUpdate  ?
                          <TextInputField
                            name={`${item?.id}_goalStatement`}
                            placeholder="Executed by ..."
                            defaultValue={item?.goalStatement}
                          /> :
                          <Tooltip title={item?.title}>
                            <span style={{cursor:'pointer'}} onClick={()=>{onTableMenuClickAction(item,'view')}}>{item?.title}</span>
                          </Tooltip>
                      }
                    </div>

                    <div className="table-section-td center" style={{ width: "220px", minWidth: "220px" }}>

                      {
                       (item?.isUpdate && !okrDiscarded)  ?
                       <div>
                       <div className="custom-okr-picker">
                         <CustomOkrRangeTable
                           name={`${item?.id}_duedate`}
                           disabledRange={{
                            duedate: MilestoneDetail?.duedate,
                            startdate: moment(MilestoneDetail?.startdate).isAfter(moment()) ? MilestoneDetail?.startdate : moment()?._d
                          }}
                          defaultValue={[moment(item?.startdate), moment(item?.duedate)]}
                           selectedDateRange={(e)=>updateForm.setFieldsValue({
                             [`${item?.id}_duedate`]: e,
                           })} 
                           currentSelected={false}
                           disabledType={true}
                         />
                       </div>
                       <div className="okr-picker">
                          <RangeDatePicker
                            name={`${item?.id}_duedate`}
                            dateFormat="DD/MM/YY"
                            disabledRange={{
                              duedate: MilestoneDetail?.duedate,
                              startdate: moment(MilestoneDetail?.startdate).isAfter(moment()) ? MilestoneDetail?.startdate : moment()?._d
                            }}
                            defaultValue={[moment(item?.startdate), moment(item?.duedate)]}
                          /> 
                       </div>
                     </div>:
                          displayUtcToUserTimezone(item?.duedate, 'DD MMM YYYY')
                      }
                    </div>

                    <div
                    className="table-section-td center"
                    style={{ width: "300px", minWidth: "300px" }}
                  >
                    <CommaSeparateComponent showCount={1} textList={item?.contributors?.map(user => { return { id: user?.id, firstName: user?.firstName,lastName:user?.lastName,role:user?.role } })} />
                    <ExpandableDropdownContainer okrId={item?.id} response={(e)=>{addContributor(e,item)}} record={item} {...props}/>
                  </div>

                    <div className="table-section-td" style={{ width: "280px", minWidth: "280px" }}>
                    {item?.childSet && (<AddWeightContainer {...props} okrId={item?.id} />)}
                      {
                        item?.isUpdate   ?
                          <div style={{ width: "100%" }} align="center"><Button className="table-save-btn" onClick={(e) => { onUpdate(item) }}>Update</Button></div>
                          :
                          <div className="row center" style={{ width: "100%", alignItems: "center" }}>
                             {!item?.childSet  &&(<ProgressUpdateContainer editable={ moment(item?.duedate)?.endOf('day').isAfter(moment())} update_progress={(okrid,progress)=>update_progress(okrid,progress)} okr={item} {...props} />)}
                            <CustomProgress showTooltip={true} record={item} progress={item?.progress} />
                          </div>
                      }

                      {
                         (item?.isUpdate && !okrDiscarded)  ?
                          <img src={close} alt="" onClick={() => { updateQue(item, false) }} style={{ cursor: "pointer", paddingRight: "10px" }} />
                          :
                          <TableRowMenu options={[{ label: 'view', key: 'view', icon: view, postLabelIcon: {}, disabled: false },
                          { label: 'Cascade', key: 'tree', icon: menu_cascade, postLabelIcon: {}, disabled: false },
                          { label: 'Edit', key: 'edit', icon: edit_pencil, postLabelIcon: 'icon', disabled: moment(item?.correctionDeadline).isBefore(moment()) },
                          { label: 'Kudos', key: 'kodos', icon: kodos_award, postLabelIcon: 'icon', disabled: (permission?.can_award_badge && permission?.okr_permission && item?.contributors?.length >1)?false:true},
                          { label: 'Discard', key: 'discard', icon: black_discard, postLabelIcon: 'icon',post_icon:(item?.discardRequestRaised && item?.isDiscarded?.status!=='PENDING')?menu_del_info:'', tooltip:(item?.discardRequestRaised && item?.isDiscarded?.status!=='PENDING')?"You have already raised the discard request":'', disabled: (item?.discardRequestRaised || item?.isDiscarded?.status==='PENDING'?true:false) },
                          { label: 'Delete', key: 'delete', icon: delete_outline_black, postLabelIcon: 'icon',post_icon:menu_del_info, tooltip:"okr will be deleted permenetly", disabled: (item?.discardRequestRaised||moment(item?.correctionDeadline).isBefore(moment())) }]}
                            onClick={(e) => onTableMenuClickAction(item, e)}
                          />
                      }
                    </div>
                  </div>
                </div>
           
              </Col>
            ))}
          </Form>
        }
      </Col>
      {
          addAward&&<AwardBadgeContainer {...props} visible={addAward} onClose={(e)=>setAddAward(e)} type={'OKR'} selectedItem={selectedKudos} from_detail={true}/>
      }
    </>
  );
};

export default SubMileStoneDetailTableView


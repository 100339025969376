import React from 'react';
import CreateInvitationView from '../component/CreateInvitationView';
import { compose } from '../../../core';
import { withApollo } from '@apollo/client/react/hoc'
import { withCreateTokenBySurvey, withTokenDelete } from 'modules/survey/containers/SurveyOperations';
import { GET_SURVEY_BY_ID } from 'modules/survey-module/graphql/survey.gql';
import { Capitalize, getIntFromString, getSurveyBase64StringFromInt, validateEmail } from 'modules/look';
import { message } from 'antd'

const CreateInvitationContainer = ( props ) => {
    const { client, me, createSourceResponse, navigateRoute } = props
    const [ loader, setResponseLoader ] = React.useState(false)
    const [ survey_detial, setSurveyDetail ] = React.useState()

    const getSurveyById = async ( id ) => {
        const { data } = await client.query( {
            query: GET_SURVEY_BY_ID,
            variables: { id: id }
        } );
        if ( data?.SurveyById ) {
            setSurveyDetail( data?.SurveyById )
        }
    }

    React.useEffect( () => {
        if ( me ) {
            if ( props?.match?.params?.surveyid ) {
                let id = getSurveyBase64StringFromInt( props?.match?.params?.surveyid )
                getSurveyById( id )
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me, props?.match?.params?.surveyid, getSurveyById]);


    const SendInvitation = async ( value ) => {
        if ( value?.firstName && value?.lastName && value?.respondent && value?.email ) {
            if ( !validateEmail(value?.email)) {
                message.error( 'Please enter a valid email address' )
            }
            else {
                setResponseLoader(true)
                const response = await createSourceResponse( {
                    email: value?.email,
                    firstName: Capitalize(value?.firstName),
                    invite: true,
                    lastName: Capitalize(value?.lastName),
                    message: value?.message,
                    surveyId: props?.match?.params?.surveyid,
                    respondentType: value?.respondent,
                    responseSourceForId: props?.match?.params?.responseid,
                    createdById: getIntFromString( me?.id )
                } );
           
                if ( response && response?.id ) {
                    setResponseLoader(false)
                    message.success( 'Successfully Invited' )
                    // let url = survey_module_route.survey_360?.replace( ":surveyid", props?.match?.params?.surveyid )?.replace( ":responseid", props?.match?.params?.responseid )
            
                    navigateRoute( "-1" )
                }else{
                    setResponseLoader(false)
                }
            }
        }
    }

    return (
        <>
            <CreateInvitationView survey_detial={survey_detial} sendInvitation={( value ) => SendInvitation( value )} loader={loader} {...props} />
        </>
    )
}

export default compose( withApollo, withTokenDelete, withCreateTokenBySurvey )( CreateInvitationContainer )
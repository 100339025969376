/* eslint-disable no-unused-vars */
import React from 'react';
import {Row, Col, Select} from 'antd';

import dropdown_Arrow from '../../../assets/dropdown_Arrow.svg'
import { ReactMarkdown } from 'modules/look';

const Option = Select.Option;

const SelectField = props => {
  const {
    choices,
    onAdd,
    onEdit,
    onDelete,
    margin = true,
    changeSelect,
    offset,
    onSelectChange,
    onBlur,
    onSearch,
    defaultId,
    disabled = false,
    name,
    ...rest
  } = props;
  const [value, setValue] = React.useState('');
  const ref = React.useRef(null);
  React.useEffect(()=>{
    setValue(defaultId)
  },[defaultId])

  React.useEffect(() => {
    if (ref && ref.current && ref.current.props && ref.current.props.value !== '') {
      setValue(ref.current.props.value);
    }
  }, []);

  const choice =
    choices &&
    choices.map((choice, key) => (
      <Option
        key={key}
        value={String(choice.value) === 'false' ? false : choice.value === '' ? '' : choice.value || choice.label}
      >
        {typeof choice.label === 'string' ? <ReactMarkdown>{choice.label}</ReactMarkdown> : choice.label}
      </Option>
    ));

  const handleSelect = params => { 
    setValue(params);
    changeSelect && changeSelect(params);
  };


  return (
    <div style={{ marginBottom: margin ? '2vh' : '' }}>
      <Row gutter={[15, 5]}>
        <Col span={onAdd ? (onEdit ? 18 : 21) : 24}>
          
            <Select name={name} id={name}  suffixIcon={<img src={dropdown_Arrow}  alt="" style={{width:"10px",height:"10px",cursor:"pointer"}}/>} {...props} ref={ref} onSelect={handleSelect} onSearch={onSearch} disabled={disabled}
                filterOption={(input,option)=> option} 
                >
                {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}
                {choice}
            </Select>
        </Col>
       
      </Row>
  
    </div>
  );
};

export default SelectField;

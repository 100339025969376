import { Button, Col, Modal, PageHeader, Row,  Space, Spin } from 'antd';
import React from 'react';
import {SwapOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import { EmployeeField, SelectField } from '../../look';
import { ThemeProvider } from '../../look/themeProvider/ThemeProvider';
import themeConfig from '../../tool/theme/themeConfig';

const NewRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: baseline;
  width: 100%;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const ManagerImpactNarrativeComponent = props => {
    const {
        firstitems,
        secondItems,
        firstUser,
        secondUser,
        tool_loader
    } = props;
    const history = useHistory();
   
    // eslint-disable-next-line no-unused-vars
    let [ui, setUi] = React.useState("TOOL_EDIT_TWO_COLUMN")
    let [selectedTeam, setSelectedTeam] = React.useState(undefined)
    let [secondEmployeeVisible, setSecondEmployeeVisible] = React.useState(false)

    // eslint-disable-next-line no-unused-vars
    let [fistEmployeeId, setFistEmployeeId] = React.useState(0)
    // eslint-disable-next-line no-unused-vars
    let [secondEmployeeId, setSecondEmployeeId] = React.useState(0)


    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [modalText, setModalText] = React.useState('Content of the modal');


    const startCompare = () => {
        if(secondEmployeeVisible){
            
            props.clearSecondEmployee()
        }
        setSecondEmployeeVisible(!secondEmployeeVisible);
    };

    const handleOk = () => {
        setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        setTimeout(() => {
            setVisible(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onEmployeeChange = (val, type) => {
        if (type === 'first') {
            setFistEmployeeId(val)
            props.onUserChange(val, type)
        } else {
            setSecondEmployeeId(val)
            props.onUserChange(val, type)
        }
    }


    return (
        <>
            <Spin spinning={tool_loader} >
                <Row justify="center">
                    <Col
                        span={20}
                        style={{
                            border: "1px solid #EBEBEB",
                            borderRadius: 10,
                            padding: "50px 0px",
                            paddingBottom: 80,
                            backgroundColor: "#fff",
                        }}
                    >
                        <Modal
                            visible={visible}
                            onOk={handleOk}
                            confirmLoading={confirmLoading}
                            onCancel={handleCancel}
                        >
                            <Row>
                                <Col span={24} style={{textAlign: 'center'}}>
                                    <Space style={{ width: '100%', display: 'flex' }} direction="vertical" size="middle">
                                        <SelectField
                                            closable={false}
                                            otherOption={false}
                                            footer={null}
                                            choices={props.teams}
                                            placeholder="Select a team"
                                            showSearch
                                            optionFilterProp="children"
                                            onChangeSelect={(v, k) => { setSelectedTeam(v) }}
                                            filterOption={(input, option) =>
                                                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }                                    
                                        >
                                        </SelectField>
                                        <Button type="primary" loading={props.downloading} onClick={() => {props.downloadCsv(selectedTeam)}}>
                                            Download
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                        </Modal>
                        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <PageHeader
                                    className="site-page-header"
                                    backIcon={false}
                                    style={{ fontWeight: 'bold' }}
                                    title={`Impact Narratives`}
                                />
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ textAlign: 'end' }}>
                                <Button
                                    shape="circle"
                                    onClick={() => history.goBack()}
                                    style={{ margin: "2em" }}
                                >
                                    <ArrowLeftOutlined />
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ minHeight: '5em' }}></Col>
                        </Row>
                        <Row style={{display: 'flex',justifyContent: 'center'}}>
                            <Col 
                                span={20}
                                style={{
                                    border: "1px solid #EBEBEB",
                                    borderRadius: 10,
                                    padding: "5em 4em",
                                    paddingBottom: 80,
                                    backgroundColor: "#E5E5E5",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "baseline"
                                }}>
                                <NewRow>
                                <EmployeeField
                                    name="firstEmployeeId"
                                    label=""
                                    rules={[]}
                                    disabled={false}
                                    onAdd={false}
                                    onEdit={false}
                                    placeholder="Select an employee"
                                    onChange={(value) => onEmployeeChange(value, 'first')}

                                />
                                <Button
                                    shape="circle"
                                    onClick={() => startCompare()}
                                    style={{ margin: "2em" }}
                                >
                                    <SwapOutlined />
                                </Button>
                                {secondEmployeeVisible && (
                                        <EmployeeField
                                            name="secondEmployeeId"
                                            label=""
                                            rules={[]}
                                            disabled={false}
                                            onAdd={false}
                                            onEdit={false}
                                            placeholder="Select an employee"
                                            skipIds={[firstUser]}
                                            onChange={(value) => onEmployeeChange(value, 'second')}
                                        />
                                    )}
                                {/* <Button
                                    shape="circle"
                                    onClick={() => showModal()}
                                    style={{ margin: "2em" }}
                                >
                                    <DownloadOutlined />
                                </Button> */}
                                </NewRow>
                            </Col>
                        </Row>
                        {secondUser &&
                        <Row justify="center">
                            <Col span={20}
                                    style={{
                                        padding: "50px 0px",
                                        paddingBottom: 80,
                                        backgroundColor: "#fff",
                                    }}>
                                    <PageHeader
                                        className="site-page-header"
                                        backIcon={false}
                                        style={{ fontWeight: 'bold' }}
                                        title={`Comparison Report`}
                                    />
                            </Col>
                                
                        </Row>
                        }
                        {secondItems.length === 0 && 
                        <ThemeProvider
                            {...props}
                            ui={ui}
                            config={themeConfig}
                            displayBack={false}
                            items={firstitems}
                            noOfHeaders={2}
                            headerText={'Comparison Report'}
                        />
                        }
                        {secondItems.length !== 0 &&
                            <ThemeProvider
                                {...props}
                                displayBack={false}
                                ui={ui}
                                config={themeConfig}
                                l={firstitems}
                                r={secondItems}
                                noOfHeaders={0}
                                headerText={'Comparison Report'}
                            />
                        }
                        </Col>
                    </Row>
                </Spin>
            </>
    );
}

export default ManagerImpactNarrativeComponent;

import React from 'react';
import { Spin, message } from 'antd';

// import HOME_ROUTE from '../../home/route';
import { compose } from '../../core';
import { ButtonsCatch, withMarkdownFieldValue } from '../../look';

import AddTokenView from '../components/AddTokenView';
import ROUTE from '../route';
import { CKEditorFieldName } from '../components/TokenFormComponent';

import {withAllPublicSurveysQuery, withSurveyState, withCreateTokenOprimized } from './SurveyOperations';

const AddToken = props => {
  const { createToken, history } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    try {
      setSubmitLoading(true);
      const response = await createToken(withMarkdownFieldValue(CKEditorFieldName, values));
      message.success('Token created!!');
      setSubmitLoading(false);
      response && ButtonsCatch(item, ROUTE.token, history);
    } catch (e) {
      setSubmitLoading(false);
      message.error('Failed, try again!');
      throw new Error(e.message);
    }
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <AddTokenView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withSurveyState, withAllPublicSurveysQuery,withCreateTokenOprimized)(AddToken);

import React from 'react';
import { Modal, Form, Transfer, Row, Col, Button } from 'antd';
import Loading from '../Loading';
import { getIntFromString } from '../functions';
import { withAllStoryTags } from '../../../story/containers/StoryOperations';

const TagTransfer = props => {
  const { allStoryTags, initialValues, handleSubmit } = props;
  const { ...rest } = props;
  const FormItem = Form.Item;

  const [mockData, setmockData] = React.useState([]);
  const [targetKeys, setTargetKeys] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {
    const targetKeys = [];
    // const mockData = [];
    // for (let i = 0; i < 20; i++) {
    //   const data = {
    //     key: i.toString(),
    //     title: `content${i + 1}`,
    //     description: `description of content${i + 1}`,
    //     chosen: Math.random() * 2 > 1,
    //   };
    // if (data.chosen) {
    //   targetKeys.push(data.key);
    // }
    // mockData.push(data);
    // }
    const mockData =
      allStoryTags &&
      allStoryTags.edges.map(({ node }) => ({
        title: node.name,
        key: getIntFromString(node.id)
      }));

    initialValues &&
      initialValues.edgeCount > 0 &&
      // eslint-disable-next-line array-callback-return
      initialValues.edges.map(({ node }) => {
        targetKeys.push(getIntFromString(node.id));
      });
    setmockData(mockData);
    setTargetKeys(targetKeys);
  }, [allStoryTags, initialValues]);

  const filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  const handleChange = targetkeys => {
    setTargetKeys(targetkeys);
  };
  return (
    <>
      <Button size="small" type="primary" onClick={() => setVisible(true)}>
        Add Tag
      </Button>
      <Modal
        destroyOnClose
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        title="Add Tag"
        width="45%"
        bodyStyle={{ padding: 12 }}
      >
        <div>
          <Row>
            <Col span={24}>
              {allStoryTags ? (
                <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} {...rest}>
                  <Transfer
                    listStyle={{ height: '50vh', width: '18vw' }}
                    dataSource={mockData}
                    showSearch
                    filterOption={filterOption}
                    targetKeys={targetKeys}
                    onChange={handleChange}
                    // onSearch={handleSearch}
                    render={item => (
                      <>
                        <Row
                          style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          {item.title}
                        </Row>
                      </>
                    )}
                  />
                </FormItem>
              ) : (
                <Loading size="small" />
              )}
            </Col>
          </Row>
          <Row justify="end">
            <Button
              type="primary"
              onClick={async () => {
                await handleSubmit(targetKeys);
                setVisible(false);
              }}
            >
              Add
            </Button>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default withAllStoryTags(TagTransfer);





import { Row } from 'antd';
import React from 'react';
import horizontal_line from '../../assets/horizontal_line.svg'
import straight_line from '../../assets/straight_line.svg'


const OkrConnectionLine = (props) => {

    return (
        <>
            <Row align='middle' style={{ position: 'relative', bottom: '45px', marginLeft: '18px' }}>
                <img style={{ height: '75%', paddingTop: '18px', position: 'relative', top: '10px' }} src={straight_line} alt=''/>
                <img src={horizontal_line} alt=''/>
            </Row>
        </>
    )
}
export default OkrConnectionLine



import { compose } from "modules/core"
import PocOrganizationHomeView from "modules/poc-dashboard/components/organization/oraganizationHomeView"
import React from "react"
import { withApollo } from '@apollo/client/react/hoc';
import { VERTICAL_TOTAL_COUNT } from "modules/poc-dashboard/graphql/pocDashboardQuery.gql";
import { globalPermissionValidator } from "modules/look";
import { poc_admin_permission } from "Permissions/poc_admin.permission";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { Spin } from "antd";
const PocOrganizationHomeContainer = (props) => {
    const { me, client, userPermission } = props
    const [verticals_count, setVerticalCount] = React.useState()
    const [permission, setPermission] = React.useState()

    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length && me) {
            let permission = globalPermissionValidator(poc_admin_permission, userPermission)

            if (permission.required_permission && !me?.employee?.orgPocEmployee?.totalCount) {
                permission.required_permission = false
            }
            if (permission.required_permission && !me?.employee?.organizationSet?.edges?.length) {
                permission.required_permission = false
            }
            setPermission(permission)
        }
    }, [userPermission, me])

    React.useEffect(() => {
        if (permission?.required_permission) {
            verticalCount()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission])

    const verticalCount = async () => {
        try {
            const { data } = await client.query({
                query: VERTICAL_TOTAL_COUNT,
                fetchPolicy: 'network-only'
            });
            if (data.verticals) {
                setVerticalCount(data.verticals?.totalCount)
            }
        }
        catch (e) {

        }
    }
    return (
        <>
            {(permission && !permission?.required_permission) && (<NoPermissionView />)}

            {
                (permission && permission?.required_permission) && (
                    <PocOrganizationHomeView {...props} verticals_count={verticals_count} verticalCount={verticalCount} />
                )
            }
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }




        </>
    )

}

export default compose(withApollo)(PocOrganizationHomeContainer)
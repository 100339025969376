import { compose } from "modules/core"
import { withApollo } from '@apollo/client/react/hoc';
import React from "react"; 
import TipListView from "../component/tip-list.view";
import { AI_TIPS } from "../query/ai-tip-query.gql";
import { GenerateTipTitle, getIntFromString } from "modules/look";
import { withUpdateAiTip } from "./ai-tip-operations";

const TipListContainer = (props)=>{
    const {client,me}=props
    const [tip_list,SetTipList] = React.useState([])
    const [tip_loading,setTipLoading] = React.useState(false)
    const [page_info_tip,SetPageInfoTip] = React.useState()
    let filter_ref = React.useRef({})
    const ai_tip_ref = React.useRef([])

    React.useEffect(()=>{
        ai_tip_ref.current = tip_list
    },[tip_list])
    React.useEffect(()=>{
        if(me){
            getAiTip()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[me])

    const getAiTip = async(filter) => {
        if(filter!==undefined&&Object.keys(filter).length === 0){
            filter_ref.current={}
        }
        else{
            filter_ref.current = { ...filter_ref.current, ...filter }
        }
        
        try {
            setTipLoading(true)
            const {data}= await client.query({
                query: AI_TIPS,
                variables: {...filter_ref.current,user:getIntFromString(me?.id),first:15},
                fetchPolicy: 'network-only'
            })
            if(data?.allRunpodtips){
                let detail = {
                    hasNextPage: data?.allRunpodtips?.pageInfo?.hasNextPage,
                    after: data?.allRunpodtips?.pageInfo?.endCursor
                }
                SetPageInfoTip(detail)
                let data_list =data?.allRunpodtips?.edges?.map(({node})=>{
                    return  {
                        id:node?.id,
                        tipFor:node?.tipFor,
                        user:node?.user,
                        module:node?.tipModule,
                        title: GenerateTipTitle(node),
                        is_saved: node?.favourite,
                        content: node?.tip,
                        created_at: node?.createdAt
                    }
                })
                let list = filter?.after ? ai_tip_ref?.current?.concat(data_list) : data_list
                SetTipList(list)
                setTipLoading(false)
            }
        } catch (error) {
            setTipLoading(false)
        }
    }

    return (
        <TipListView {...props} tip_list={tip_list} tip_loading={tip_loading} page_info_tip={page_info_tip} getAiTip={(filter)=>getAiTip({...filter})}/>
    )
}

export default compose(withApollo,withUpdateAiTip)(TipListContainer)

// eslint-disable-next-line no-unused-vars
import { getTrackingByOKRId ,withCreateTracking, withUpdateTracking } from 'modules/Objective-keyresult/container/objective-keyresult-operations';
import React from 'react';
import { compose } from '../../../core';
import ProgessUpdate from '../progeressUpdate';
import { withApollo } from '@apollo/client/react/hoc';
import { TRACKING_LIST } from 'modules/Objective-keyresult/graphql/trackingList.gql';
const ProgessUpdateContainer = props => {
  const {client,okr}=props
  const [progressloading,setProgressloading] = React.useState(false)
  const [trackings,settrackings]= React.useState()
  const getTrackingByOKRId =async()=>{
    setProgressloading(true)
    const { data } = await client.query({
      query:TRACKING_LIST,
      variables:{ okr: okr?.id },
      fetchPolicy: 'network-only',
    });
    if(data?.trackings){
      settrackings(data?.trackings)
      setProgressloading(false)
    }
  }
  return (
    <>
    <ProgessUpdate trackings={trackings} {...props} progressloading={progressloading} fetchProgress={()=>{getTrackingByOKRId()}} setProgressloading={(e)=>{setProgressloading(e)}}></ProgessUpdate>
    </>
  );
};

export default compose(withApollo,withCreateTracking,withUpdateTracking)(ProgessUpdateContainer);

import gql from 'graphql-tag';

export const ALL_EMPLOYEE_SURVEY = gql`
query allEmployees($surveyId:ID!) {
    allEmployees {
        edges {
            node {
                id
                user {
                    id
                    firstName
                    lastName
                    email
                    responseSet(survey: $surveyId,last:1) {
                        edges {
                            node {
                                id
                                survey {
                                    id
                                    name
                                }
                                answerSet {
                                    edges {
                                        node {
                                            id
                                            answer
                                            question {
                                                id
                                                questionText
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`;

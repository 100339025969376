//@ts-ignore
import React from 'react'
import { compose } from '../../core';
import { EMPLOYEE_READINESS_ASSESSMENT} from '../graphql/ReadinessAssessment.gql'
import { withApollo } from '@apollo/client/react/hoc'
import { readiness_color_zone } from '../config/readiness-color.config';
import { conditionCheck } from 'modules/look';
import EmployeeReadinessAssessmentView from '../components/EmployeeAssessmentView';

const EmployeeReadinessAssessmentContainer = (props) => {
    const { client, me, history,SubmitFilter } = props
    const [readiness_assessment, setReadinessAssessment] = React.useState([])
    const [employee_assessment_loader, SetEmployeeAssessmentLoader] = React.useState(false)
    const [employee_page_info_assessment, SetEmployeePageInfoAssessment] = React.useState()
    let filter_ref = React.useRef({})
    const mountedRef = React.useRef(true)
    const readiness_ref = React.useRef([])

    React.useEffect(()=>{
        readiness_ref.current = readiness_assessment
    },[readiness_assessment])

    React.useEffect(()=>{
        if(SubmitFilter){
            ReadinessAssessment({...SubmitFilter,after:null})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[SubmitFilter])

    React.useEffect(() => {
        return () => {
            mountedRef.current = false
        }
    }, [])

    React.useEffect(() => {
        mountedRef.current = true
    }, [])


    const ReadinessAssessment = async (filterData) => {
        filter_ref.current = { ...filter_ref.current, ...filterData }
        SetEmployeeAssessmentLoader(true)
        const { data } = await client.query({
            query: EMPLOYEE_READINESS_ASSESSMENT,
            variables: {
                ...filter_ref.current,
                user: me?.employee?.id,
                first:25,
                after:filterData?.after,
                orderBy: ["-createdAt", "-updatedAt"]
            },
            fetchPolicy: 'network-only'
        });
        if (data?.employeeReadiness) {
            let detail = {
                hasNextPage: data?.employeeReadiness?.pageInfo?.hasNextPage,
                after: data?.employeeReadiness?.pageInfo?.endCursor
            }
     
            SetEmployeePageInfoAssessment(detail)
            let employee_readiness = data?.employeeReadiness?.edges?.map(({ node }) => node)
            let readiness = [...employee_readiness]
            readiness = handleReadinessStatus(readiness)
            let list = filterData?.after ? readiness_ref?.current?.concat(readiness) : readiness
            setReadinessAssessment(list)
            SetEmployeeAssessmentLoader(false)
        }
  
    }

    const handleReadinessStatus =(list)=>{
        
        let data = list?.map(i=>{
            let answer_set = i?.readinesslevelSet?.edges?.map(({node})=>{
                let response_set = node?.response?.answerSet?.edges?.map(an=>an?.node)?.map(s=>{
                    return {
                        question:s?.question?.id,
                        answer:parseInt(s?.answer),
                        questionText:s?.question?.questionText
                    }
                })
                let rule_validation = {}
                readiness_color_zone.forEach(z=>{
                    rule_validation[z.zone_id] = {}
                    response_set.forEach(rs=>{
                        rule_validation[z.zone_id][rs.question] = true
                        let question_rule = z.rule?.find(rl=>rl?.question_id === rs.question)
                        if(question_rule){
                            question_rule.conditions.forEach(c=>{
                                if(!conditionCheck(rs.answer,c.value,c.operation)){
                                    rule_validation[z.zone_id][rs.question] = false
                                }
                            })
                        }else{
                            rule_validation[z.zone_id][rs.question] = false
                        }
                    })
                })
                let zone_enteries = Object.entries(rule_validation).find(rv=> Object.values(rv[1])?.every(item=>item===true))
                let zone 
                if(zone_enteries){
                    zone = readiness_color_zone?.find(z=>z?.zone_id===zone_enteries[0])
                }
                
                return {
                    ...node,
                    response_set,
                    rule_validation,
                    zone
                }
            })

            return {
                ...i,
                zone_detail:answer_set
            }
        })
        return data
    }

    React.useEffect(() => {
        if (me) {
            ReadinessAssessment({})
        }
        else if (me === null) {
            history.push('/user/login')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me])
    return (
        <EmployeeReadinessAssessmentView {...props} dataSource={readiness_assessment} onfilter={(e) => ReadinessAssessment(e)} employee_assessment_loader={employee_assessment_loader} employee_page_info_assessment={employee_page_info_assessment}/>
    )

}
export default compose(withApollo)(EmployeeReadinessAssessmentContainer);
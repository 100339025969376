import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import BarsSubCompetencyCreateView from "../Components/BarsCreateSubCompetency";
import { ALL_RATING_SCALES, COMPETENCY_BY_ID } from "../graphql/BarsCreateQueries.gql";
import { getCompetencyCategoryStringFromInt, getIntFromString, globalPermissionValidator } from "modules/look";
import { withCreateBarsInBulk } from "./BarsOperations";
import { ALL_EMPLOYEE_LEVELS } from "../graphql/BarsQueries.gql";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { Row, Spin } from "antd";
import { bars_permission } from "Permissions/bars.permission";
import BarsCreateSubCompWithUpdate from "../Components/BarsCreateSubCompWithUpdate";



const BarsCreateSubCompContainer = (props) => {

  const { client, permissionLoader, userPermission, me } = props;

  const [competencyById, setCompetencyById] = React.useState(undefined);
  const [competencyByIdUpdate, setCompetencyByIdUpdate] = React.useState(undefined);
  const [competencyLoading, setCompetencyLoadig] = React.useState(false);
  const [ratingScales, setRatingScales] = React.useState(undefined);
  const [barsPermission, setBarsPermission] = React.useState(undefined);
  const [allEmployeeLevels, setAllEmployeeLevels] = React.useState(undefined);
  const [importModalVisible, setImportModalVisible] = React.useState(false)
  const importLoading = false;

  const scales = React.useRef(null);

  React.useEffect(() => {

    // Checking for permission of user
    if (userPermission?.length) {
      let permission = globalPermissionValidator(bars_permission, userPermission);

      setBarsPermission(permission)
    }

  }, [userPermission])


  const getRatingScalesGenerated = (level, data) => {


    let scales = ratingScales?.map((scale) => {
      let dataToAppend = data?.behaviourallyanchoredratingSet?.edges?.find((value) => value?.node?.ratingScale?.id === scale?.id && value?.node?.employeeLevel?.id === level?.id)

      if (dataToAppend) {
        return { ...scale, behaviourExample: dataToAppend?.node?.behaviourExample, barIntId: getIntFromString(dataToAppend?.node?.id) }
      } else {
        return { ...scale }
      }


    }

    )



    return scales;

  }


  const getStatus = (node) => {

    let behaviourExamples = node?.ratingScales?.map((scale) => scale?.behaviourExample)?.filter((value) => value !== undefined);

    if (behaviourExamples?.length === ratingScales?.length) {
      return 'completed';
    } else {
      return 'pending';
    }

  }

  // Combining employee levels for main competency
  const getEmployeeLevels = (data) => {

    let levels = allEmployeeLevels?.map((node) => {
      return { ...node, ratingScales: getRatingScalesGenerated(node, data) }
    })

    let levelsWithStatus
    if (levels?.length) {
      levelsWithStatus = levels?.map((node) => { return { ...node, status: getStatus(node) } })
    }

    return levelsWithStatus;

  }




  const getDataGenerated = (data) => {

    let record = undefined;

    record = {
      id: data?.id,
      mainCompetency: false,
      title: data?.title,
      competencySet: data?.competencySet?.edges?.map(({ node }) => { return { ...node, employeeLevel: getEmployeeLevels(node) } })

    }

    return record;

  }


  React.useEffect(() => {

    if (ratingScales && allEmployeeLevels) {
      let id = props.match.params.competency_id
      getCompetencyById(id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingScales, allEmployeeLevels, props.match])

  // For fetching all rating scales
  const getAllRatingScales = async () => {
    setCompetencyLoadig(true)

    const { data } = await client.query({
      query: ALL_RATING_SCALES,
      fetchPolicy: 'network-only',
    });
    if (data?.ratingScales) {
      let scales = data?.ratingScales?.edges?.map(({ node }) => node);
      scales.current = scales
      setRatingScales(scales);

    }
  }

  // Fetching all the bars
  const getAllEmployeeLevels = async (filter) => {

    const { data } = await client.query({
      query: ALL_EMPLOYEE_LEVELS,
      variables:{organization:me?.employee?.organizationSet?.edges[0]?.node?.id},
      fetchPolicy: 'network-only',
    });
    if (data?.allEmployeeLevels) {
      let allLevels = data?.allEmployeeLevels?.edges?.map(({ node }) => node);
      setAllEmployeeLevels(allLevels)
    }
  }




  // Fetching the details of competency
  const getCompetencyById = async (id) => {
    setCompetencyLoadig(true)
    let competency_Id = getCompetencyCategoryStringFromInt(id)
    const { data } = await client.query({
      query: COMPETENCY_BY_ID,
      fetchPolicy: 'network-only',
      variables: { id: competency_Id }
    });
    if (data?.competencyCategoryId) {
      let competencyData = getDataGenerated(data?.competencyCategoryId)

      let infoCheck = competencyData?.competencySet?.map((value) => value?.behaviourallyanchoredratingSet?.edges?.length)?.flat()?.reduce((total, currentValue) => total = total + currentValue, 0);
    
      if (infoCheck) {
        setCompetencyByIdUpdate(competencyData)
      } else {
        setCompetencyById(competencyData)
      }

      setCompetencyLoadig(false)

    }
  }

  React.useEffect(() => {

    // based on permission of user bars will be di
    if (barsPermission?.required_permission) {
      getAllRatingScales();
      getAllEmployeeLevels()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barsPermission, scales.current])

  return (
    <>


      <Row justify='center' align='middle' >
        {
          <Spin spinning={permissionLoader} style={{ width: '100%' }} wrapperClassName='permission-check-bars'>
            <div>
              {
                barsPermission !== undefined &&
                <div>
                  {
                    barsPermission?.required_permission ?

                      <div>
                        {
                          competencyByIdUpdate &&
                          <BarsCreateSubCompWithUpdate
                            competencyById={competencyByIdUpdate}
                            setCompetencyById={setCompetencyByIdUpdate}
                            competencyLoading={competencyLoading}
                            ratingScales={ratingScales}
                            importLoading={importLoading}
                            importModalVisible={importModalVisible}
                            setImportModalVisible={setImportModalVisible}
                            allEmployeeLevels = {allEmployeeLevels}
                            {...props}
                          />

                        }

                        {
                          competencyById &&
                          <BarsSubCompetencyCreateView
                            competencyById={competencyById}
                            setCompetencyById={setCompetencyById}
                            competencyLoading={competencyLoading}
                            ratingScales={ratingScales}
                            importLoading={importLoading}
                            importModalVisible={importModalVisible}
                            setImportModalVisible={setImportModalVisible}
                            allEmployeeLevels = {allEmployeeLevels}
                            {...props}
                          />
                        }


                      </div>


                      :
                      <NoPermissionView />
                  }

                </div>
              }
            </div>
          </Spin>
        }
      </Row>

    </>
  );
};

export default compose(withApollo, withCreateBarsInBulk)(BarsCreateSubCompContainer);

import React, { useState } from "react";
import styled from "styled-components";
import { Col, Card, Row, Space, Spin, Progress, Form, Empty } from "antd";

import { getStrokeColor } from "../../look";
import { CloseOutlined } from "@ant-design/icons";

const FormItem = Form.Item;

const EmployeeMAppAnchorsView = (props) => {
  const { loading, MAppAnchors, title = "My MApp" } = props;
  const [visible, setVisible] = useState(null);

  function getAnchor(anchor) {
    if (visible?.anchor === anchor) setVisible(null);
    else
      setVisible({
        anchor,
        anchorData: JSON.parse(MAppAnchors)?.socres[anchor],
      });
  }

  return (
    <Col lg={visible ? 24 : 8} md={visible ? 24 : 12} sm={visible ? 24 : 12} xs={visible ? 24 : 24}>
      <Card
        style={{ display: "grid", height: "100%" }}
        bodyStyle={{
          boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
          borderRadius: "2%",
        }}
        bordered={false}
      >
        <Spin spinning={loading}>
          <Row gutter={24}>
            <Col span={visible ? 7 : 24}>
              <div align="center">
                <Row align="middle">
                  <Col span={24}>
                    <h2 style={{ fontWeight: "normal" }}>
                      {title}
                      <br />
                      Anchors
                    </h2>
                  </Col>
                </Row>

                <Col span={20}>
                  <Row justify="center">
                    {MAppAnchors &&
                    Object.keys(JSON.parse(MAppAnchors)?.socres)?.length ? (
                      Object.keys(JSON.parse(MAppAnchors)?.socres)?.map(
                        (soc, index) => (
                          <Col span={15} align="left">
                            <HoverSpace
                              size="middle"
                              align="center"
                              onClick={() => {
                                getAnchor(soc);
                              }}
                            >
                              <SequenceNo
                                active={visible && visible.anchor}
                                soc={soc}
                              >
                                {index + 1}
                              </SequenceNo>
                              <Colordiv
                                active={visible && visible.anchor}
                                soc={soc}
                              >
                                {soc}
                              </Colordiv>
                            </HoverSpace>
                          </Col>
                        )
                      )
                    ) : (
                      <>
                        <br />
                        <br />
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </>
                    )}
                  </Row>
                </Col>
              </div>
            </Col>

            <Col span={visible ? 17 : 0}>
              <Row justify={"space-between"}>
                <Col span={20}>
                  <h2 style={{ fontWeight: "normal" }}>{visible?.anchor}</h2>
                </Col>
                <Col span={4} align="right">
                  <CloseOutlined onClick={() => setVisible(null)} />
                </Col>

                <Col span={24} style={{ minHeight: "25vh" }}>
                  <Row align={"middle"} style={{ minHeight: "40vh" }}>
                    <Col span={8}>
                      <Progress
                        type="circle"
                        percent={(visible?.anchorData?.overall * 10).toFixed(0)}
                        strokeColor="#B0CB1F"
                        strokeWidth="10"
                      />
                    </Col>

                    <Col span={8}>
                      {visible &&
                        Object.keys(visible?.anchorData?.factors)?.map(
                          (factor) => (
                            <FormItem
                              labelCol={{ span: 24 }}
                              wrapperCol={{ span: 24, offset: 1 }}
                              label={factor}
                            >
                              <Progress
                                percent={(
                                  visible?.anchorData?.factors[factor] * 10
                                ).toFixed(0)}
                                strokeColor={getStrokeColor(
                                  (visible?.anchorData?.factors[
                                    factor
                                  ]).toFixed(2) || 0
                                )}
                              />
                            </FormItem>
                          )
                        )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Spin>
      </Card>
    </Col>
  );
};

export default EmployeeMAppAnchorsView;

const SequenceNo = styled.div`
  border-radius: 50%;
  width: 28px;
  color: ${(props) => (props.active === props.soc ? "#B0CB1F" : "#222")};
  height: 28px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  border: 1px solid #cacaca;
`;

const HoverSpace = styled(Space)`
  margin-bottom: 10px;
  &:hover {
    color: PRIMARY_CLR;
    cursor: pointer;
  }
`;
const Colordiv = styled.div`
  color: ${(props) => (props.active === props.soc ? "#B0CB1F" : "#222")};
`;

// modifications on line
// 11
// 12(2)
// 17
// 46(2)
// 47
// 119

import React from "react";
import "../css/MyTickets.css"
import thank_you from '../../assets/ticket-thankyou-bg.svg'
import { Button } from "antd";
import support_route from "../route";
import back_icon from '../../assets/soul-back.svg'
const ThankyouPage = (props) => {
    const {navigateRoute,location} = props
    const [created_ticket_id,setCreatedTicketId]=React.useState()
    React.useEffect(()=>{
        if(location?.search){
            let search = location.search.replace('?','')
            let data = search.split('=')
            setCreatedTicketId(data[1])
        }
    },[location])
    return (
        <>  
            <div style={{ display: "flex", flexDirection: "row", gap: '1em', justifyContent: 'start', alignItems: "center" }}>
                <img src={back_icon} alt="back_icon" style={{ cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
                <h1 className='ticket-system-main-head'>Mapp Support</h1>
            </div>
            <div className="ticket-thankyou-page-container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',gap:"1em", height: '75vh', textAlign: 'center',background:"#fff",borderRadius:"1em",marginTop:"1em",padding:'1em' }}>
                <img className="ticket-thankyou-banner-bg" src={thank_you} alt="thank you"/>
                <h4 className="ticket-thank-you-title" style={{marginTop:"1em"}}>Thank you we will get back to soon!</h4>
                <h4 className="ticket-thank-you-navigation" style={{marginTop:"1em"}}>Go To Ticket Detail <span style={{color:'#0000EE',cursor:"pointer"}} onClick={()=>navigateRoute(support_route?.details.replace(':id',created_ticket_id))}>Click Here</span>.</h4>
                <Button className='thankyou-submit-btn' onClick={()=>navigateRoute(support_route?.my_ticket)}>Go To my Tickets</Button>
            </div>
        </>
    );
};

export default ThankyouPage;

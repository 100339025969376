// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { MEETING_BY_ID } from '../graphql/MeetingByid.gql';
import { withApollo } from '@apollo/client/react/hoc';
import { withCreateActionItemsEditPage, withDeleteActionItem, withUpdateActionItems, employeeList, withUpdateSeriesMeeting, withDeleteSeriesMeeting } from './One_on_oneOperations';
// import { employeeList } from '../../feedback/containers/feedbackOperations'
import { UPCOMING_MEETING } from '../graphql/UpcomingMeeting.gql';
import {Spin} from "antd";
import moment from 'moment-timezone';
import { getMeetingTypeBase64StringFromInt,globalPermissionValidator } from "../../look";
import { GET_MANAGER_EMPLOYEE_LIST_SEARCH } from '../graphql/getEmployeSerach.gql';
import EditMeetingSeriesView from '../components/EditMeetingSeriesView';
import { oneonone_permission } from 'Permissions/oneonone.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import one_on_one_route from '../route/index'
const EditMeetingSeriesContainer = props => {
  const { client, history, deleteMeetingSeries, userPermission } = props
  const [loading, setloading] = React.useState(false)
  const [meetingById, setMeeting] = React.useState()
  const [upcomingMeeting, setUpcomingMeeting] = React.useState([])
  const [allEmpoloyeeResult, setAllEmpoloyeeResult] = React.useState()
  const [permission, setpermission] = React.useState()


  const getMeetingDetail = async (value) => {
    let id = getMeetingTypeBase64StringFromInt(value);
    const { data } = await client.query({
      query: MEETING_BY_ID,
      variables: { id: id },
    });
    if (data?.meetingById) {
      setMeeting(data?.meetingById)
    }
  }

  React.useEffect(() => {
    if (userPermission?.length) {
      let permission = globalPermissionValidator(oneonone_permission, userPermission)
      setpermission(permission)
    }
    else {
      setpermission(null)
    }
  }, [userPermission])


  const UpcomingMeeting = async (filterData) => {

    const { data } = await client.query({
      query: UPCOMING_MEETING,
      variables: { ...filterData, orderBy: ['-when'] },
      fetchPolicy: 'network-only'
    });
    if (data?.upcomingMeeting) {
      setUpcomingMeeting(data?.upcomingMeeting)
    }

  }

  async function DeleteMeeting(id) {
    setloading(true)
    try {

      setloading(true)
      const response = await deleteMeetingSeries({ seriesId: meetingById?.seriesId });
      if (response) {
        setloading(false)
        history.push(one_on_one_route?.oneOnOneHome?.replace(":type","upcoming"))
      }
      else {
        setloading(false)
      }

    }
    catch {
      setloading(false)
    }
  }

  React.useEffect(() => {
    if (permission?.requried_permission) {
      let id = props?.match?.params?.meetingid
      getMeetingDetail(id)
      let currentDate = moment.utc(new Date()).startOf('day')
      let weekendDate = new Date()
      let dif = 6 - weekendDate.getDay()
      weekendDate.setDate(weekendDate.getDate() + dif)
      let IsoWeekendDate = moment.utc(weekendDate).endOf('day')
      UpcomingMeeting({ when_Range: [currentDate, IsoWeekendDate] })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission])

  const AllEmployeeListSearch = async (searchValue) => {
    const { data } = await client.query({
      query: GET_MANAGER_EMPLOYEE_LIST_SEARCH,
      variables: { user_LastName: searchValue, user_FirstName: searchValue },
      fetchPoicy: 'network-only'
    });
    if (data) {
      let allEmpoloyee = data
      if (allEmpoloyee) {
        setAllEmpoloyeeResult(allEmpoloyee)
      }
    }
  }
  const SearchKey = (value) => {
    AllEmployeeListSearch(value)
  }
  return (
    <>

      {
        (permission && permission?.requried_permission) && (
          <Spin spinning={loading}>
            <EditMeetingSeriesView {...props} permission={permission} upcomingMeeting={upcomingMeeting} meetingById={meetingById} searchKey={e => SearchKey(e)} allEmpoloyeeResult={allEmpoloyeeResult} remove={(e) => DeleteMeeting(e)}></EditMeetingSeriesView>
          </Spin>
        )
      }

      {
        (permission && !permission?.requried_permission) && (
          <NoPermissionView />
        )
      }

    </>
  )


};

export default compose(withApollo, employeeList, withCreateActionItemsEditPage, withDeleteActionItem, withUpdateActionItems, withUpdateSeriesMeeting, withDeleteSeriesMeeting)(EditMeetingSeriesContainer);
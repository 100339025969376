import { Spin,  Button} from "antd";
import React from "react";
import { ca_create_icon } from "../icon";
import '../css/competency-assessment-create.css'
import { threshold_colors } from "../color-config";
// import CompetencySelectorContainer from "../containers/competency-selector-container";
import moment from "moment";
import ca_route from '../route/index'
const CompetencyAssessmentEditView = (props) => {
    const { history, is_loading, SetAssessmentList, assessment_detail, OnSubmitAssessment, selected_member, selected_role, assessment_list } = props
    const [bars_detail_view, SetBarsDetail] = React.useState()
    const [editable,setEditable]= React.useState(false)
 
    const changeBarsExpansion = (id) => {
        if (bars_detail_view === id) {
            SetBarsDetail(null)
        } else {
            SetBarsDetail(id)
        }
    }

    React.useEffect(()=>{
        
        if(assessment_detail?.correctionDeadline){
            let editable = moment().isBefore(assessment_detail?.correctionDeadline)
            setEditable(editable)
        }
    },[assessment_detail])

    const getColor = (ui_type, threshold, key) => {
        let list = assessment_list
        let selected = list?.find(assessment => assessment?.id === key)?.assessmentbarsSet?.find(i=>i?.selected)
        let doc = false
        if(selected?.id === threshold?.id){
            doc = true
        }
        if (ui_type === 'background') {
            return doc ? `rgba(${threshold_colors[`threshold_${threshold?.qualifyingLevelSequence}`]},0.1)` : '#fff'
        }
        else if ((ui_type === 'solid_background' || ui_type === 'border') && doc) {
            return `rgba(${threshold_colors[`threshold_${threshold?.qualifyingLevelSequence}`]})`
        }
        else if ((ui_type === 'solid_background' || ui_type === 'border') && !doc) {
            if (ui_type === 'solid_background') {
                return 'transparent'
            } else {
                return '#AABAC6'
            }
        }
    }

    const changeAssessment=(key,id)=>{
        if(!editable){
            return
        }
        let list = assessment_list.concat([])
        let doc = list?.find(i=>i.id===key)
        if(doc){
            doc.assessmentbarsSet = doc.assessmentbarsSet?.map(asmt=>{
               return{ 
                ...asmt,
                selected:false
            }   
           
            })
            let assessment = doc.assessmentbarsSet?.find(asmt=>asmt?.id===id)
            if(assessment){
                assessment.selected = true
                doc.updated = true
            }
            SetAssessmentList(list)
        }
    }

    const CompetencyListRender = (item, key) => (
        <div className="competency-list-container" id={key}>
            <div className="row-justify-space-between " style={{ gap: "2.69em" }}>
                <h4 className="ca-competency-title">

                    {item?.competency?.title || item?.mainCompetency?.title} :</h4>
                <h4 className="ca-sample-detail" onClick={() => changeBarsExpansion(key)}>
                    {
                        bars_detail_view === key ?
                            'Hide' : 'See detail'
                    }
                </h4>
            </div>
            {/* threshold list */}
            <div className="ca-threshold-container">
                {
                    item?.assessmentbarsSet?.map(threshold => (
                        <div
                            className={`ca-threshold-card ${bars_detail_view === key ? 'ca-threshold-card-expanded' : ''}`}
                            style={{ background: getColor('background', threshold, key), border: `1px solid ${getColor('border', threshold, key)}` }}
                            onClick={() => changeAssessment(key, threshold?.id)}
                        >
                            <div className="ca-threshold-card-tile" >
                                <h4 className="ca-threshold-label">{threshold?.qualifyingLevelName}</h4>
                                {/* custom radio button */}
                                <div className={`ca-radio-outer`} style={{ border: `4px solid ${getColor('border', threshold, key)}` }}>
                                    <div className={`ca-radio-inner`} style={{ background: getColor('solid_background', threshold, key) }}>

                                    </div>
                                </div>
                            </div>
                            {/* Behaviour sample */}
                            {
                                bars_detail_view === key && (
                                    <div className={`ca-behaviour-sample-container `}>
                                    <h4 className="ca-behaviour-sample" style={{ textAlign: 'left'}}>
                                       {threshold?.behaviourExample}
                                    </h4>
                                </div>
                                )
                            }
                        </div>
                    ))
                }
            </div>


        </div>
      
    )

    return (
        <>
            <Spin spinning={is_loading}>
                <div className="ca-heading-container ca-root">
                    <img onClick={(e) => history.push(ca_route?.competency_assessment_home)} style={{ paddingLeft: ".5em", cursor: "pointer" }} src={ca_create_icon?.new_back_arrow} alt="" />
                    <h4 className="ca-main-title">Competency Assessment</h4>
                    <div></div>
                </div>
                <div className="ca-root ca-root-padding ca-page-title-margin" >

                    <div className="ca-container-bg selection-container-padding">

                        {selected_member && (
                            <>
                                <h4 className="ca-role-detail">Employee : <span className="text-transform-capitalize">{selected_member?.user?.firstName || selected_member?.user?.email}</span> <span>{selected_member?.user?.lastName}</span></h4>
                            </>
                        )}
                        {selected_role && (
                            <>
                                <h4 className="ca-role-detail">Role : <span>{selected_role?.title}</span></h4>
                                <h4 className="ca-role-detail">Employee level : <span>{selected_role?.employeeLevel?.name}</span></h4>
                            </>
                        )}

                    </div>

                    {
                        assessment_list && (
                            <div className="ca-container-bg section-container-padding" style={{ marginTop: "1.12em" }} >
                                {assessment_list?.map(item => 
                                    CompetencyListRender(item, item?.id)
                                )}
                            </div>
                        )
                    }

                    {
                        editable && (
                            <div className="ca-action-container">
                            <Button className="global-submit-btn" onClick={() => { OnSubmitAssessment(assessment_list) }} disabled={!assessment_list?.find(i=>i.updated)} style={{ height: "3.4375em", minWidth: "12em", marginTop: "2.62em" }}>Submit</Button>
                        </div>
                        )
                    }
                

                </div>


            </Spin>
        </>
    )

}

export default CompetencyAssessmentEditView
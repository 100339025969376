import React from "react";  
import AiTipsView from "../components/ai-tips-view";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { withCreateSettingsTip, withUpdateSettingsTip } from "./profile-opertations";
import { getIntFromString, globalPermissionValidator } from "modules/look";
import { TIP_SETTINGS_LIST } from "../graphql/profile.gql";
import { ai_tip_settings_permission } from "Permissions/aiTipSettings.permissions";

const AITipsSettingsContainer =(props)=>{
    const { client,createTipsettings,updateTipsettings, userPermission}=props
    const [settings_list,setSettingsList]=React.useState()
    const [settings_loading,setSettingsLoading]=React.useState(true)
    const [permission, SetPermission] = React.useState()
    React.useEffect(()=>{
    
        if (userPermission?.length) {
            let permission = globalPermissionValidator(
                ai_tip_settings_permission,
                userPermission);
            SetPermission(permission)
           
        }
    },[userPermission])

    React.useEffect(() => {
        if (permission?.required_permission) {
            getSttingsList()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission])

    const getSttingsList =async()=>{
        try{
            setSettingsLoading(true)
            const { data } = await client.query({
                query: TIP_SETTINGS_LIST,
                fetchPolicy: 'network-only',
                variables: {}
            }) 
            if(data?.tipSettingsByUser){
                let list =data?.tipSettingsByUser?.edges?.map(({node})=>node)
                setSettingsList(list)
                setSettingsLoading(false)
            }
        }
        catch(e){
            console.error("error",e)
            setSettingsLoading(false)
        }
    }

    const onToggle =(value)=>{
        if(value?.id){
            updatesettings(value)
        }
        else{
            createsettings(value)
        }
    }

    const createsettings =async(value)=>{
        let response = await createTipsettings({active:value?.active,module:value?.module})
        if(response){
            getSttingsList()
        }
    }

    const updatesettings =async (value)=>{
        let response = await updateTipsettings({id:getIntFromString(value?.id),active:value?.active})
        if(response){
            getSttingsList()
        }}

    return (
        <>
            {
                (permission && permission?.required_permission) && 
                (
                    <AiTipsView 
                    settings_loading={settings_loading}
                    toggleAction={(e)=>onToggle(e)}
                    {...props}
                    settings_list={settings_list}/>
                )
            }
        </>
    )
}

export default compose(withApollo,withCreateSettingsTip,withUpdateSettingsTip)(AITipsSettingsContainer);

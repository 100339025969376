/* eslint-disable array-callback-return */
import React from 'react';
import {  Form, Transfer, Row, Col, Button, Spin } from 'antd';
// import { Link } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import gql from 'graphql-tag';
// import { UserAddOutlined } from '@ant-design/icons';
import { IMG } from '../../../../config';
import Loading from '../Loading';
import { getIntFromString } from '../functions';

export const ALL_ORGANIZATION_QUERY = gql`
  query allOrganization(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
    $email_Icontains: String
    $poc: ID
    $ceo: ID
  ) {
    allOrganization(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
      email_Icontains: $email_Icontains
      poc: $poc
      ceo: $ceo
    ) {
      edges {
        node {
          id
          name
          description
        }
      }
    }
  }
`;

const OrganizationTransfer = props => {
  const { loading, allOrganization, initialValues, handleSubmit,  setVisible } = props;
  const { ...rest } = props;
  //
  const FormItem = Form.Item;
  const [mockData, setmockData] = React.useState([]);
  const [targetKeys, setTargetKeys] = React.useState([]);
  React.useEffect(() => {
    const targetKeys = [];
    const mockData =
      allOrganization &&
      allOrganization.edges.map(({ node }) => ({
        title: node.name,
        key: getIntFromString(node.id)
      }));

    initialValues &&
      initialValues.edgeCount > 0 &&
      initialValues.edges.map(({ node }) => {
        targetKeys.push(getIntFromString(node.id));
      });
    setmockData(mockData);
    setTargetKeys(targetKeys);
  }, [allOrganization, initialValues]);

  const filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

  const handleChange = targetkeys => {
    setTargetKeys(targetkeys);
  };
  return (
    <Spin spinning={loading} size="medium">
      <div>
        <Row>
          <Col span={24}>
            {allOrganization ? (
              <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} {...rest}>
                <Transfer
                  listStyle={{ height: '50vh', width: '18vw' }}
                  dataSource={mockData}
                  showSearch
                  filterOption={filterOption}
                  targetKeys={targetKeys}
                  onChange={handleChange}
                  // onSearch={handleSearch}
                  render={item => (
                    <>
                      <Row style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <img src={IMG} alt="" style={{ width: 30, borderRadius: 30, marginRight: 10 }} />
                        {item.title}
                      </Row>
                    </>
                  )}
                />
              </FormItem>
            ) : (
              <Loading size="small" />
            )}
          </Col>
        </Row>
        <Row justify="space-between">
          <Button type="ghost" onClick={() => setVisible(false)}>
            View Assigned Organization
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              await handleSubmit(targetKeys);
              setVisible(false);
            }}
          >
            Save
          </Button>
        </Row>
      </div>
    </Spin>
  );
};

export default graphql(ALL_ORGANIZATION_QUERY, {
  options: ({ filter, orderBy, pagination }) => {
    return { variables: { ...pagination, ...filter, orderBy } };
  },
  props({ data }) {
    const { loading, error, allOrganization, subscribeToMore, updateQuery } = data;
    return { loading, error, allOrganization, subscribeToMore, updateQuery };
  }
})(OrganizationTransfer);

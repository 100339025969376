import React from "react";
import { compose } from "modules/core";
import CompetencyAssessmentHomeView from "../components/ca-home-view";
import { withApollo } from '@apollo/client/react/hoc';
import { COMPETECNY_ASSESSMENT_LISTING_QUERY } from "../graphql/ca-home-queries.gql";
import { globalPermissionValidator } from "modules/look";
import { competency_assessment_permission } from "Permissions/competency-assessment";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { Spin } from "antd";
import { RATING_SCALES_LIST } from "../graphql/ca-detail-queries.gql";
import { threshold_colors } from "../color-config";

const CompetencyAssessmentHomeContainer = ( props ) => {
    const { client, userPermission } = props

    const [ competency_assessment_list, setCompetencyAssessment ] = React.useState()
    const [ pageLoading, setPageLoading ] = React.useState( false )
    const [ permission, SetPermission ] = React.useState()
    const [ rating_list, setRatingList ] = React.useState()
    const [total_page_count,setTotalPageCount] = React.useState()

    const assessment_list_ref = React.useRef([])
    const filter_ref = React.useRef( {} )
    const duplicate_ref = React.useRef([])
    //Permission Check
    React.useEffect( () => {
        if ( userPermission?.length ) {
            let permission = globalPermissionValidator( competency_assessment_permission, userPermission )
            SetPermission( permission )
        }
    }, [ userPermission ] )

    React.useEffect(()=>{
        assessment_list_ref.current = competency_assessment_list
    },[competency_assessment_list])

    React.useEffect( () => {
        if ( permission?.required_permission ) {
            getCompetencyAssessment()
            RatingScaleList()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ permission ] )

    const getCompetencyAssessment = async ( filter ) => {
        setPageLoading( true )
        if(!filter?.offset || Number(filter?.offset) === 0){
            duplicate_ref.current=[]
        }
        filter_ref.current={...filter,after:null} || {}
        const { data } = await client.query( {
            query: COMPETECNY_ASSESSMENT_LISTING_QUERY,
            variables: { ...filter, first: 9 },
            fetchPolicy: 'network-only'
        } );
        if ( data?.assessments ) {
            let total_count =data.assessments?.totalCount
            let page_count = Math.ceil(total_count/9)
            setTotalPageCount(page_count)
            // to set the pagination detail
            let list =data?.assessments?.edges?.map(({node})=>node)
            let data_list = []

            list.filter(i=>i?.employee).forEach(asmt=>{
                if(!duplicate_ref?.current?.includes(asmt?.employee?.id)){
                    duplicate_ref.current.push(asmt?.employee?.id)
                    data_list.push(asmt)
                }
            })
            list.filter(i=>i?.employeeEmail).forEach(asmt=>{
                if(!duplicate_ref?.current?.includes(asmt?.employeeEmail)){
                    duplicate_ref.current.push(asmt?.employeeEmail)
                    data_list.push(asmt)
                }
            })
            data_list = data_list?.map(asmt=>handleAssessmentData(asmt))
            setCompetencyAssessment( data_list )
            setPageLoading( false )
        }

    }

    const handleAssessmentData=(data)=>{
        
        let functional_assessment = data?.competencyassessmentSet?.edges?.map(({node})=>node).filter(i=>(i?.mainCompetency?.competencyType || i?.competency?.competencyType) === 'ROLE_SPECIFIC')
        let generic_assessment = data?.competencyassessmentSet?.edges?.map(({node})=>node).filter(i=>(i?.mainCompetency?.competencyType || i?.competency?.competencyType) !== 'ROLE_SPECIFIC')
        
        let role_related_competency =[
            ...data?.role?.optionalSpecificCompetency?.edges?.map(({node})=>[node?.id,node?.competencyCategory?.id]),
            ...data?.role?.requiredSpecificCompetency?.edges?.map(({node})=>[node?.id,node?.competencyCategory?.id]),
        ]
        if(data?.role?.jobfamilySet?.edges?.length){
            let job_family_competency = []
            let job_family = data?.role?.jobfamilySet?.edges?.map(({node})=>node)
            job_family.forEach(i=>{
                let competency = i.genericCompetency?.edges?.map(({node})=>node)
                if(competency?.length){
                    competency.forEach(comp=>{
                        job_family_competency.push([comp?.id,comp?.competencyCategory?.id])
                    })
                }
            })
            role_related_competency = role_related_competency.concat(job_family_competency)

        }
        role_related_competency = [].concat.apply([],role_related_competency)
        
        let only_functional_assessment = functional_assessment?.filter(i=>role_related_competency?.includes(i?.competency?.id || i?.mainCompetency?.id))
        let only_generic_assessment = generic_assessment?.filter(i=>role_related_competency?.includes(i?.competency?.id || i?.mainCompetency?.id))
        let other_assessment = [...generic_assessment,...functional_assessment]?.filter(i=>!role_related_competency?.includes(i?.competency?.id || i?.mainCompetency?.id))
        
        return {
            id: data?.id,
            user_details: data?.employee,
            email:data?.employeeEmail,
            emplpyee_level: data?.employeeLevel,
            employee_role: data?.role,
            correctionDeadline: data?.correctionDeadline,
            functional_assessment:only_functional_assessment?.length?getColor(only_functional_assessment):null,
            generic_assessment:only_generic_assessment?.length?getColor(only_generic_assessment):null,
            other_assessment:other_assessment?.length?getColor(other_assessment):null,
            assessedBy:data?.assessedBy
        }
    }

    const getColor =(data)=>{
       
        let assessmentbarsSet = data?.map(i=>i?.assessmentbarsSet?.edges[0].node)
        let assessment_summary = mostRepeatedKey(assessmentbarsSet)
        assessment_summary['background'] =`rgba(${threshold_colors[ `threshold_${assessment_summary?.qualifyingLevelSequence}`] || threshold_colors['threshold_4']},0.1)`
        assessment_summary['color'] = `rgba(${threshold_colors[ `threshold_${assessment_summary?.qualifyingLevelSequence}`] || threshold_colors['threshold_4']})`
        assessment_summary['border'] = `rgba(${threshold_colors[ `threshold_${assessment_summary?.qualifyingLevelSequence}`] || threshold_colors['threshold_4']})`
        return assessment_summary
    }

    const mostRepeatedKey = (arr) => {
        let list = {}
        arr.forEach(item => {
            if (list[`id_${item?.qualifyingLevelId}`]) {
                list[`id_${item?.qualifyingLevelId}`].count++
            } else {
                list[`id_${item?.qualifyingLevelId}`] = {
                    count: 1,
                    ...item
                }
            }
        })
        let higest_count = Object.values(list)?.sort((a, b) => b.count - a.count)[0]?.count
        let data = Object.values(list)?.filter(item => item?.count === higest_count).sort((a, b) => b.qualifyingLevelSequence - a.qualifyingLevelSequence )
        return data[0]
    }

    const RatingScaleList = async ( filter ) => {
        const { data } = await client.query( {
            query: RATING_SCALES_LIST,
            variables: { ...filter },
            fetchPolicy: 'network-only'
        } );
        if ( data?.ratingScales ) {
            let list = data?.ratingScales?.edges?.map( ( { node }, index ) => { return { ...node, sequence: index + 1 } } )
            setRatingList( list )
        }
    }

    return (
        <>
            {( permission && permission?.required_permission ) && (
                <CompetencyAssessmentHomeView
                    {...props}
                    competency_assessment_list={competency_assessment_list}
                    pageLoading={pageLoading}
                    rating_list={rating_list}
                    permission={permission}
                    onFilter={(filter)=>getCompetencyAssessment(filter)}
                    total_page_count={total_page_count}
                    Pagination={(e)=>getCompetencyAssessment(e)}
                /> )}
            {( permission && !permission?.required_permission ) && ( <NoPermissionView /> )}
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    )

}

export default compose( withApollo )( CompetencyAssessmentHomeContainer )
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {Button, Col, Form, Empty,message } from 'antd';

import SinglePageSectionComponent from './SinglePageSectionComponent';
import { MetaTags, getIntFromString, InputValuesObject,TextWithDateField,scrolltoview, Capitalize } from '../../look';
import QuizLoading from './QuizLoading';
import SaveInCompleteQuizResponse from './SaveInCompleteQuizResponse';
import SkillField from 'modules/look/components/form-components/SkillField';
import moment from 'moment-timezone';
import OkrFiled from 'modules/look/components/form-components/OkrFiled';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'modules/core';
import check_box from '../../assets/dashboard-check-box-icon.svg'
import GrowSelectField from 'modules/survey-look/containers/grow-select-field';
import SurveyDatePickerField from 'modules/survey-look/containers/survey-datepicker-field';
import GrowAiTipContainer from 'modules/ai-tips/container/grow-ai-tip-container';
import usePrompt from 'modules/usePrompt';

const ALL_EMPLOYEES_QUERY = gql`
  query allEmployees($nameIcontains: String) {
    allEmployees(nameIcontains: $nameIcontains) {
      edges {
        node {
          id
          user {
            id
            username
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const SinglePageQuizView = props => {
    const { loading, orgSurveyById, onSubmit,SurveyById, setPrevTotalDuration, quizStartTime, submitted, showTermsAndCondition, showAdditonalCoahee, showActionItem, setUserId, allEmployees, me, coachingPermitted } =
        props;
    const QuesDiv = {
        paddingRight: '20px'
    };

    const currentSectionRef = React.useRef();
    const showOkRef = React.useRef();

    const [ showOk, setShowOk ] = React.useState( [ false ] );
    const timeoutId = null;
    // eslint-disable-next-line no-unused-vars
    const [ currentSection, setCurrentSection ] = React.useState( 1 );
    const [ formValues, setFormValues ] = React.useState( [] );
    // eslint-disable-next-line no-unused-vars
    const [ currentMatrixQuestion, setCurrentMatrixQuestion ] = useState( 0 );
    const [ enableShortCuts, setEnableShortCuts ] = useState( false );
    //specific to GROW survey
    const [ cocheeVal, setCocheeVal ] = useState();
    const [ conversationType, setConversationType ] = useState();
    const [ selected_skill, setSelectedSkill ] = useState();
    const [choices,setChoices]=React.useState([])
    const [ form ] = Form.useForm();
    const { setIsDirty, PromptComponent } = usePrompt("Are you sure you want to leave? You have unsaved changes.");
     
    React.useEffect(()=>{
        if(allEmployees?.edges?.length){
        let filter_list =allEmployees?.edges?.filter(({node})=>node?.user?.id!==me?.employee?.user?.id)?.map( ( { node } ) => ( {
            label: node.user.firstName && node.user.lastName ? `${Capitalize(node.user.firstName)} ${Capitalize(node.user.lastName)}` : node.user.username,
            value: [ `${node.user.firstName} ${node.user.lastName}`, getIntFromString( node.id ), getIntFromString( node.user.id ) ]

        } ) )
        setChoices(filter_list)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allEmployees])

    useEffect( () => {
        if ( !enableShortCuts ) {
            setEnableShortCuts( true );
        }
    }, [ enableShortCuts ] );

    useEffect(()=>{
        form.resetFields(["okr"])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cocheeVal])
    const question = [].concat.apply(
        [],
        ( orgSurveyById ? orgSurveyById : SurveyById )?.groupSet?.edges
            .sort( function ( a, b ) {
                return a.node.sequence - b.node.sequence;
            } )
            .map( ( { node: grpSet }, idx ) =>
                grpSet.matrix
                    ? { ...grpSet }
                    : grpSet?.questionSet?.edges?.map( ( { node: questionSet } ) => ( {
                        ...grpSet,
                        questionSet: [ questionSet ]
                    } ) )
            )
    );

    const currentIsMatrix = question[ currentSection - 1 ]?.matrix;

    
    
   

    

    const renderFormSections = survey => {

        function closetimer() {
            clearTimeout( timeoutId );
        }

        function getValue( value, name ) {
            if ( !currentIsMatrix ) {
                const questionFiltered = question.filter( q => {
                    return Array.isArray( q?.questionSet ) && q?.questionSet?.filter( qs => qs.id === name ).length > 0;
                } )[ 0 ]?.questionSet[ 0 ];
                switch ( questionFiltered?.choiceSet?.edges[ 0 ]?.node?.inputType ) {
                    case InputValuesObject.date:
                        return value?.format( 'YYYY/MM/DD' );
                    case InputValuesObject.datetime:
                        return value?.format( 'YYYY/MM/DD HH:mm:ss' );
                    case InputValuesObject.month:
                        return value?.format( 'M' );
                    case InputValuesObject.time:
                        return value?.format( 'HH:mm:ss' );
                    case InputValuesObject.week:
                        return value?.week();
                    case InputValuesObject.range:
                        return value === undefined || value === 0 ? 0 : value;
                    default:
                        return value || '';
                }
            } else {
                return value || '';
            }
        }

        



        const requiredRule = [ { required: true, message: 'An answer is required.' } ];

        const SubmitForm = ( value ) => {
            value[ "skill" ] = selected_skill?.value
            value[ "cocheerel" ] = conversationType
            const allContainMoment = value?.when_need?.every(subArray => subArray.some(item => moment.isMoment(item)));
            const allContainMomentNextStep = value?.when_next_step?.every(subArray => subArray.some(item => moment.isMoment(item)));
            if (!allContainMoment&&value?.when_need?.length>0) {
                message.error("Date is missing in What will be your next step and when?")
            }
            else if (!allContainMomentNextStep&&value?.when_next_step?.length>0) {
                message.error("Date is missing in What do you need from me?")
            }
            else{
                setIsDirty(false)
                onSubmit( value ) 
            }

        }
        return (
            <>
                <span ref={currentSectionRef} style={{ display: 'none' }}>
                    1
                </span>

                <span ref={showOkRef} style={{ display: 'none' }}>
                    {JSON.stringify( [ false ] )}
                </span>

                {!submitted && survey?.id && showTermsAndCondition === false && (
                    <SaveInCompleteQuizResponse
                        form={form}
                        formValues={formValues}
                        itemName={survey?.id}
                        getValue={getValue}
                        setFormValues={setFormValues}
                        setPrevTotalDuration={setPrevTotalDuration}
                        startTime={quizStartTime}
                    />
                )}

                {survey?.groupSet.edges.length > 0 ? (
                    <>
                        <MetaTags title={survey[ currentSection ]?.label || survey?.name} description="This is survey page." />
                        <Form
                            form={form}
                            name={survey?.name}
                            // layout={'vertical'}
                            onFinish={values => ( SubmitForm( values ) )}
                            style={{ width: '100%' }}
                        >

                            <div style={{ width: '100%' }}>
                                <div style={QuesDiv}>
                                    {showAdditonalCoahee && (
                                        <div style={{ margin: 'auto 0' }}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: ".5em"}}>
                                                <h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}><span className="rd-input-star">*</span> Select Coachee</h4>
                                                <GrowSelectField
                                                    id="grow_coachee"
                                                    onChange={( value ) => { setCocheeVal( value[ 1 ] ); setUserId( value[ 2 ] );setIsDirty(true)}}
                                                    placeholder="Select a coachee"
                                                    name={"cochee"}
                                                    rules={requiredRule}
                                                    showSearch
                                                    choices={choices}
                                                    required={question?.required} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: "row", gap: "1em", justifyContent: 'start', width: "100%" }}>
                                                <div style={{ display: "flex", flexDirection: "column", gap: ".5em",width:'40%'}}>
                                                    <h4 className='coaching-engagement-form-lable'style={{fontSize:"1.3em"}}><span className="rd-input-star">*</span> This coaching conversation is related to</h4>
                                                    <div style={{ display: "flex", flexDirection: "row", gap: "1em", justifyContent: 'start', alignItems: 'center', paddingLeft: '.5em',marginTop:"1em" }}>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: 'cemter', gap: "1em" }}>
                                                            {conversationType === "okr" ? <img style={{ width: "2em", height: '2em', cursor: "pointer" }} src={check_box} alt="check-box"
                                                                onClick={() => setConversationType( 'okr' )} /> :
                                                                <div style={{ width: "2em", height: '2em', border: "1px solid rgba(140, 140, 161, 1)", borderRadius: ".25em", cursor: "pointer" }}
                                                                    onClick={() => setConversationType( 'okr' )} ></div>
                                                            }
                                                            <h4 className='coaching-engagement-form-lable' style={{ fontSize: "1.3em" }}>OKR</h4>
                                                        </div>
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", alignItems: 'cemter', gap: "1em" }}>
                                                            {conversationType === "skill" ? <img style={{ width: "2em", height: '2em', cursor: "pointer" }} src={check_box} alt="check-box"
                                                                onClick={() => setConversationType( 'skill' )} /> :
                                                                <div style={{ width: "2em", height: '2em', border: "1px solid rgba(140, 140, 161, 1)", borderRadius: ".25em", cursor: "pointer" }}
                                                                    onClick={() => setConversationType( 'skill' )}></div>
                                                            }
                                                            <h4 className='coaching-engagement-form-lable' style={{ fontSize: "1.3em" }}>Skill</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{width:"60%"}}>
                                                    {conversationType === "skill" && cocheeVal !== undefined && (
                                                        <SkillField
                                                            id="grow_skill"
                                                            onClick={( e ) => { scrolltoview( 'grow_skill' ) }}
                                                            name={"skill"}
                                                            owner={cocheeVal}
                                                            rules={requiredRule}
                                                            form={form}
                                                            label={<h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}>Select Skill</h4>}
                                                            style={{width:"100%" }}
                                                            onChange={( e ) => setSelectedSkill( e )}
                                                            closeAfterSelection={true}
                                                        />
                                                    )}
                                                    {conversationType === "okr" && cocheeVal !== undefined && (
                                                        <OkrFiled
                                                            id="grow_okr"
                                                            onClick={( e ) => { scrolltoview( 'grow_okr' ) }}
                                                            name={"okr"}
                                                            employee={cocheeVal}
                                                            rules={requiredRule}
                                                            style={{width:"100%" }}
                                                            label={<h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}>Select OKR</h4>}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            {/* {conversationType === "key_result" && cocheeVal !== undefined && (
                                                <KeyResultField
                                                    id="grow_coachee"
                                                    onClick={( e ) => { scrolltoview( 'grow_coachee' ) }}
                                                    name={"kr"}
                                                    owner={cocheeVal}
                                                    rules={requiredRule}


                                                />
                                            )} */}
                                            {/* {conversationType === "milestone" && cocheeVal !== undefined && (
                                                <MilestoneField
                                                    id="grow_milestone"
                                                    onClick={( e ) => { scrolltoview( 'grow_milestone' ) }}
                                                    name={"msf"}
                                                    owner={cocheeVal}
                                                    rules={requiredRule}

                                                />
                                            )} */}
                                            {/* <SelectField
                                                name={"goal"}
                                                label={"Select your Goal :"}
                                                rules={requiredRule}
                                            /> */}
                                        </div>
                                    )}
                                    {question?.map( ( grpSet, idx ) => (
                                        <Col className='grow_survey'>
                                            <SinglePageSectionComponent
                                                survey={orgSurveyById}
                                                key={idx}
                                                srNo={idx + 1}
                                                form={form}
                                                currentIsMatrix={currentIsMatrix}
                                                currentMatrixQuestion={currentMatrixQuestion}
                                                currentSection={currentSection}
                                                matrix={grpSet?.matrix}
                                                // timeoutFunc={timeoutFunc}
                                                closetimer={closetimer}
                                                sectionLabel={grpSet?.label}
                                                autoscroll={( e ) => { scrolltoview( e ) }}
                                                sectionSequence={grpSet?.sequence}
                                                questionList={
                                                    Array.isArray( grpSet?.questionSet )
                                                        ? grpSet?.questionSet?.map( node => node )
                                                        : grpSet?.questionSet?.edges?.map( ( { node } ) => node )
                                                }
                                                showOk={showOk[ currentSection - 1 ]}
                                                setShowOk={e =>
                                                    setShowOk( question.map( ( q, qi ) => ( e ? qi === currentSection - 1 : false ) ) )
                                                }
                                            />
                                        </Col>
                                    ) )}
                                    {showActionItem && (
                                        <div style={{ width: "100%", display: "flex", flexDirection: 'column', gap: '1em' }}>
                                            <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
                                                <h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}>What will be your next step and when?</h4>
                                                <TextWithDateField
                                                    name={"when_next_step"}
                                                    id="when_next_step"
                                                    onBlur={e => e?.target?.value}
                                                    form={form}
                                                    scrolltoview={e => scrolltoview( e )}
                                                    selectedid="when_next_step"
                                                    margin={"0px"}
                                                    required={question?.required}
                                                />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
                                                <h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}><span className="rd-input-star">*</span> What do you need from me?</h4>
                                                <TextWithDateField
                                                    name={"when_need"}
                                                    id="when_need"
                                                    onBlur={e => e?.target?.value}
                                                    form={form}
                                                    scrolltoview={e => scrolltoview( e )}
                                                    selectedid="when_need"
                                                    margin={"0px"}
                                                    required={question?.required}
                                                    rules={requiredRule}
                                                />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", gap: ".5em" }}>
                                                <h4 className='coaching-engagement-form-lable' style={{fontSize:"1.3em"}}><span className="rd-input-star">*</span> When should we follow up again?</h4>
                                                <SurveyDatePickerField
                                                    style={{ width: "100%", minWidth: "17em", height: "2.5em" }}
                                                    name={"follow_up"}
                                                    id="follow_up"
                                                    disabledDate={( current ) => current.isBefore( moment().subtract( 1, "day" ) )}
                                                    form={form}
                                                    onClick={( e ) => { scrolltoview( 'follow_up' ) }}
                                                    selectedid="follow_up"
                                                    required={question?.required}
                                                    rules={requiredRule}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <br />
                                </div>
                            </div>
                            <Button className="enggement-main-button" style={{ marginTop: "1em" }} htmlType="submit">Submit</Button>
                        </Form>
                    </>

                ) : survey ? (
                    <div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Empty />
                    </div>
                ) : null
                }
                {PromptComponent}
            </>
        );
    };

    return (
        <MobilePadding>
            <div>
                {loading && <QuizLoading />}
                {!loading && orgSurveyById ? renderFormSections( orgSurveyById ) : renderFormSections( SurveyById )}
            </div>
            {coachingPermitted&&coachingPermitted?.runpod_permission&&<GrowAiTipContainer {...props}/>}
        </MobilePadding>
    );
};

export default compose(
    graphql( ALL_EMPLOYEES_QUERY, {
        options: ( { filter, orderBy } ) => {
            return { variables: { ...filter, orderBy } };
        },
        props( { data: { loading, error, allEmployees, subscribeToMore, updateQuery } } ) {
            return { loading, error, allEmployees, subscribeToMore, updateQuery };
        }
    } ),

)( SinglePageQuizView );

const MobilePadding = styled.div`
 
`;

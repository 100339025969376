import gql from "graphql-tag";
// import { CUSTOM_FORM_SECTION_TYPE_CONNECTION } from "modules/form-section/graphql/Custom_FormSectionTypeConnection.gql";

export const RESPONSE_BY_ID =gql`
    query responseByResponseId($id:ID!){
        responseByResponseId(id:$id){
                id
                user{
                    id
                    firstName
                    lastName
                    email
                }
                survey{
                    id
                    name
                }
                responseDate
                answerSet{
                    edges{
                        node{
                            id
                            answer
                            question{
                                id
                                questionText
                                choiceSet{
                                    edges{
                                        node{
                                            id
                                            value
                                            label
                                            answerKey
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
        }
    }
`

export const SURVEY_REPORT_TOOL = gql` 
  query surveyreporttool($id:ID!) {
    responseByResponseId(id:$id){
        id
        user{
            id
            firstName
            lastName
            email
        }
        survey{
            id
            name
            groupSet{
                edges{
                    node{
                        id
                        questionSet{
                            totalCount
                        }
                    }
                }
            }
        }
        responseDate
        report360
    }

 
  }
`;
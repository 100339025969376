import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../look';

import EditQuestionView from '../components/EditQuestionView';
import { CKEditorFieldName, CKEditorFieldName1 } from '../components/QuestionFormComponent';

import { withQuestionById, withEditQuestion, withEditChoice, withAddChoice } from './SurveyOperations';
import ROUTE from '../route';

const EditQuestion = props => {
  const { history, editQuestion, addChoice, editChoice, questionById } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (questionData, choiceData, item) => {
    setSubmitLoading(true);
    try {
      const response = await editQuestion(
        withMarkdownFieldValue(CKEditorFieldName, {
          id: getIntFromString(questionById.id),
          ...withMarkdownFieldValue(CKEditorFieldName1, { id: getIntFromString(questionById.id), ...questionData })
        })
      );
      await Promise.all(
        choiceData.map(({ idx, ...choice }) =>
          choice.id
            ? editChoice({
                ...choice,
                id: getIntFromString(choice.id),
                inputType: String(choice.inputType),
                questionId: Number(getIntFromString(response.id))
              })
            : addChoice({
                ...choice,
                inputType: String(choice.inputType),
                questionId: Number(getIntFromString(response.id))
              })
        )
      );

      response &&
        ButtonsCatch(
          item,
          `${ROUTE.surveyDetailLink}${getIntFromString(response.group.survey.id)}`,
          history,
          item === undefined ? window.location.pathname : `${ROUTE.addQuestionLink}${getIntFromString(response.group.id)}`
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditQuestionView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withQuestionById, withEditQuestion, withEditChoice, withAddChoice)(EditQuestion);

import React, { useState } from "react";
import {Divider, Input, Button, List, Avatar, Spin, } from "antd";
import "../css/TicketDetails.css";
import "../css/MyTickets.css";
import { Capitalize, displayUtcToUserTimezone, getIntFromString } from "modules/look";
import back_icon from '../../assets/soul-back.svg'
import attach_icon from '../../assets/attach-pin.svg'
const TicketDetails = (props) => {
  const { ticket_detail, onSubmit, navigateRoute, ticket_detail_loading, ticket_permission } = props
  const [input, setInput] = useState("");
  const [fileInfo, setFileInfo] = useState([]);

  React.useEffect(() => {
    const fetchFileInfo = async () => {
      if (ticket_detail?.attachments) {
        const data = await getFileInfo(ticket_detail.attachments);
        setFileInfo(data);
      }
    };

    fetchFileInfo();
  }, [ticket_detail]);
  const handleSendMessage = () => {
    if (input.trim()) {
      sendMessage(input);
      setInput("");
    }
  };
  const sendMessage = (inputMessage) => {
    const values = {
      id: ticket_detail?.id,
      content: inputMessage,
    };
    onSubmit(values)
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 1:
        return "#FA8A81";
      case 2:
        return "#AA97E1";
      case 3:
        return "#FFCB3D";
      default:
        return "#D9D9D9";
    }
  };

  const getFileInfo = async (attachments) => {
    try {
      const fileUrls = attachments?.split(",");
  
      const fileInfo = await Promise.all(fileUrls.map(async (url) => {
        const res = await fetch(url, { method: "HEAD" });
        const contentLength = res.headers.get("Content-Length");
      const sizeInMB = contentLength ? (parseInt(contentLength, 10) / 1024 / 1024).toFixed(2) + " MB" : "Unknown";
        return {
          type: res.headers.get("Content-Type") || "Unknown",
          size:sizeInMB,        
          name: url.split("/").pop(),
          url:url
        };
      }));
  
      return fileInfo || [];
    } catch (error) {
      console.error("Error fetching file info:", error);
      return [];
    }
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", gap: '1em', justifyContent: 'start', alignItems: "center" }}>
        <img src={back_icon} alt="back_icon" style={{ cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
        <h1 className='ticket-system-main-head'>Mapp Support</h1>
      </div>
      <div className="ticket-details-container">

        {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "20%" }}>
            <h2 style={{ textAlign: 'left' }}>
              Ticket {ticket_detail?.workitemId}
            </h2>
            <Input
              placeholder="Search by ticket title"
              style={{ borderRadius: ".5em", border: "1px solid rgba(84, 193, 4, 1)", height: "3em", width: "20em" }}
            />
          </div> */}
        <Spin spinning={ticket_detail_loading}>
          {ticket_detail &&
            <div className="ticket-content">
              <div className="ticket-main" style={{ width: "100%" }}>
                <div style={{display:"flex",flexDirection:"column",gap:'.5em'}}>
                  <div className="ticket-tabs-header" style={{ marginTop: "1em" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: '1em' }}>
                      <div
                        style={{
                          width: "1em",
                          height: "1em",
                          borderRadius: "50%",
                          backgroundColor: getPriorityColor(ticket_detail?.priority), // Dynamically sets color
                        }}
                      />
                      <h2 className="ticket-work-item-id">
                        Ticket #TK-{displayUtcToUserTimezone(ticket_detail?.createAt, 'YYYY')}-{getIntFromString(ticket_detail?.id)}
                      </h2>
                    </div>

                    <h3 className='ticket-detail-title'>{ticket_detail?.title}</h3>
                    <h3 className='ticket-detail-description'>{ticket_detail?.description}</h3>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"start",alignItems:"center",gap:"1em",width:"100%",flexWrap:"erap"}}>
                        {fileInfo?.map(item=>(
                          <div style={{display:"flex",flexDirection:"row",justifyContent:"start",alignItems:"start",gap:".5em",cursor:"pointer"}} onClick={()=>window.open(item?.url,"_blank")}>
                            <img src={attach_icon} alt="attach_icon" />
                            <div>
                              <h4 style={{color:"#007AE1",fontSize:".85em",fontWeight:"400",fontFamily:"Poppins",lineHeight:"normal",margin:"0px"}}>{item?.name}</h4>
                              <h4 style={{color:"#007AE1",fontSize:".85em",fontWeight:"400",fontFamily:"Poppins",lineHeight:"normal",margin:"0px"}}>{item?.size}</h4>
                            </div>
                            
                          </div>
                        ))}
                    </div>
                  </div>

                  <Divider style={{ border: ".5px solid rgba(84, 193, 4, 1)" }} />
                  {ticket_detail?.ticketcommentSet?.length > 0 && ticket_permission?.commentTicketPermisions &&
                    <div className="messages-list ticket-comment-overflow-container">
                      <List
                        itemLayout="horizontal"
                        dataSource={ticket_detail?.ticketcommentSet}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start", borderBottom: "0.5px solid #54C1045C", paddingBottom: "1em" }}
                              avatar={<Avatar src={item?.sentBy?.profile?.profileImage} className="ticket-comment-avatar" >
                                {item?.sentBy?.profile?.profileImage ? item?.sentBy?.profile?.profileImage : item?.sentBy?.firstName?.charAt(0).toUpperCase()}
                              </Avatar>}
                              title={<div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: '1em' }}>
                                <h4 className="ticket-comment-user-title" style={{ cursor: "default" }}>{Capitalize(item?.sentBy?.firstName)} {Capitalize(item?.sentBy?.lastName)}</h4>
                                <span className="ticket-user-comment" style={{ textAlign: "right" }}>{displayUtcToUserTimezone(item?.createAt, 'MMM DD')}</span>
                              </div>}
                              description={<h4 className="ticket-user-comment">{item?.comment}</h4>}
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  }
                </div>
                {ticket_permission?.commentTicketPermisions &&ticket_detail?.status !== "RESOLVED"&& <div
                  className="message-box"
                  style={{
                    border: "1px solid rgba(84, 193, 4, 1)",
                    padding: "0px 1em",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: '#FBFBFB',
                    marginTop: "2em",
                    height: "8em",
                    position: "relative"
                  }}
                >
                  <Input
                    type="text"
                    placeholder="Type your comment here..."
                    className="message-input"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    style={{
                      border: "none",
                      outline: "none",
                      flexGrow: 1,
                      padding: "5px",
                      width: "100%",
                      maxWidth: "90%",
                      background: '#FBFBFB',
                      borderBottom: "0.5px solid #54C1045C",
                    }}
                  />
                  {/* <span
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    marginLeft: "10px",
                  }}
                  onClick={() => alert("Attach File")}
                >
                  📎
                </span> */}
                  <Button
                    style={{
                      marginLeft: "10px",
                      backgroundColor: "#FBFBFB",
                      color: "#000000",
                      border: "none",
                      fontFamily: 'Poppins',
                      fontSize: '1em',
                      position: "absolute",
                      right: "0px",
                      bottom: "5px",
                      boxShadow: "none"
                    }}
                    onClick={handleSendMessage} // Trigger sendMessage on click
                  >
                    Send
                  </Button>
                </div>}
              </div>
              <div className="ticket-card">
                <ul className="ticket-details">
                  <li><span className="ticket-detail-data-label" style={{ fontWeight: "600" }}>Ticket ID:</span> <span className="ticket-detail-data-value">#TK-{displayUtcToUserTimezone(ticket_detail?.createAt, 'YYYY')}-{getIntFromString(ticket_detail?.id)}</span></li>
                  <li><span className="ticket-detail-data-label">Reporter name:</span> <span className="ticket-detail-data-value">{ticket_detail?.raisedBy?.firstName ? ticket_detail?.raisedBy?.firstName : '-'}</span></li>
                  <li><span className="ticket-detail-data-label">Created On:</span> <span className="ticket-detail-data-value" style={{ fontWeight: "300" }}>{displayUtcToUserTimezone(ticket_detail?.createAt, 'DD MMM YYYY')}</span></li>
                  <li><span className="ticket-detail-data-label">Assigned To:</span> <span className="ticket-detail-data-value">{ticket_detail?.assignee?.firstName ? ticket_detail?.assignee?.firstName : '-'}</span></li>
                  <li><span className="ticket-detail-data-label">Status:</span> <span className="ticket-detail-data-value">{ticket_detail?.status === "RESOLVED" ? "Closed" : ticket_detail?.status === "WORK_IN_PROGRESS" ? "Work In Progress" : "Open"}</span></li>
                  {ticket_detail?.slaDate&&<li><span className="ticket-detail-data-label">SLA Date:</span> <span className="ticket-detail-data-value">{displayUtcToUserTimezone(ticket_detail?.slaDate, 'DD MMM YYYY')}</span></li>}
                 {ticket_detail?.resolvedAt&&<li><span className="ticket-detail-data-label">Resolved On:</span> <span className="ticket-detail-data-value" style={{ fontWeight: "400" }}>{displayUtcToUserTimezone(ticket_detail?.resolvedAt, 'DD MMM YYYY')}</span></li>}
                </ul>
              </div >
            </div>}
        </Spin>
      </div>
    </div>

  );
};

export default TicketDetails;

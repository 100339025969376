import React from 'react'
import { compose } from '../../core';
import { READINESS_SUBSCRIPTION, SELF_READINESS_ASSESSMENT } from '../graphql/ReadinessAssessment.gql'
import { withApollo } from '@apollo/client/react/hoc'
import { readiness_color_zone } from '../config/readiness-color.config';
import { conditionCheck } from 'modules/look';
import SelfReadinessAssessmentView from '../components/SelfAssessmentTableView';

const SelfReadinessAssessmentContainer = (props) => {
    const { client, me, history, SubmitFilter } = props
    const [readiness_assessment, setReadinessAssessment] = React.useState([])
    const [self_assessment_loader, SetSelfAssessmentLoader] = React.useState(false)
    const [page_info_assessment, SetPageInfoAssessment] = React.useState()
    let filter_ref = React.useRef({})
    const mountedRef = React.useRef(true)
    const readiness_ref = React.useRef([])
    let readinessSub = undefined

    React.useEffect(()=>{
        readiness_ref.current = readiness_assessment
    },[readiness_assessment])

    React.useEffect(() => {
        return () => {
            if (readinessSub) {
                readinessSub.unsubscribe();
            }
        }
    })

    React.useEffect(() => {
        return () => {
            mountedRef.current = false
        }
    }, [])

    React.useEffect(() => {
        mountedRef.current = true
    }, [])

    React.useEffect(()=>{
        if(SubmitFilter){
            ReadinessAssessment({...SubmitFilter,after:null})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[SubmitFilter])

    const ReadinessAssessment = async (filterData) => {
        filter_ref.current = { ...filter_ref.current, ...filterData }
        SetSelfAssessmentLoader(true)
        const { data } = await client.query({
            query: SELF_READINESS_ASSESSMENT,
            variables: {
                ...filter_ref.current,
                user: me?.employee?.id,
                first:25,
                after:filterData?.after,
                orderBy: ["-createdAt", "-updatedAt"]
            },
            fetchPolicy: 'network-only'
        });
        if (data?.selfReadiness) {
            let detail = {
                hasNextPage: data?.selfReadiness?.pageInfo?.hasNextPage,
                after: data?.selfReadiness?.pageInfo?.endCursor
            }
            SetPageInfoAssessment(detail)
            let self_readiness = data?.selfReadiness?.edges?.map(({ node }) => node)
            let readiness = [...self_readiness]
            readiness = handleReadinessStatus(readiness)
            let list = filterData?.after ? readiness_ref?.current?.concat(readiness) : readiness
            setReadinessAssessment(list)
            SetSelfAssessmentLoader(false)
            // if(filterData?.ai_trigger &&list?.length ){
            //     let valueCounts = list?.reduce((acc, item) => {
            //         let value = item?.zone_detail[0]?.zone?.color;
            //         acc[value] = (acc[value] || 0) + 1;
            //         return acc;
            //       }, {});
            //       let mostFrequentValue = Object.keys(valueCounts)?.reduce((a, b) => 
            //         valueCounts[a] > valueCounts[b] ? a : b
            //       );
            //       generateZoneAiSummary(mostFrequentValue)
            // }
        }
        readinessSub = client.subscribe({
            query: READINESS_SUBSCRIPTION,
            variables: filterData
        }).subscribe({
            next(result) {
                let readinessCacheData = readiness_ref.current
                switch (result.data.readinessSubscription.mutation) {
                    case 'CREATE':
                        let readiness_data = result.data.readinessSubscription.readiness
                        if(readiness_data?.readinessTo?.user?.id === readiness_data?.readinessFrom?.user?.id && String(readiness_data?.readinessTo?.user?.id) === String(me?.id)){
                            const deletedIfExists = readinessCacheData?.filter(item =>
                                item.id === result.data.readinessSubscription.readiness.id
                                    ? false
                                    : true).concat([result.data.readinessSubscription.readiness])
                            if (mountedRef.current) {
                                setReadinessAssessment(deletedIfExists)
                            }
                        }
                        break
                    case 'UPDATE':
                        const updated = readinessCacheData?.map(item =>
                            item.id === result.data.readinessSubscription.readiness.id
                                ? result.data.readinessSubscription.readiness
                                : item
                        )
                        if (mountedRef.current) {
                            setReadinessAssessment(updated)
                        }
                        break
                    case 'DELETE':
                        const deleted = readinessCacheData?.filter(item =>
                            item.id === result.data.readinessSubscription.readiness.id
                                ? false
                                : true)
                        if (mountedRef.current) {
                            setReadinessAssessment(deleted)
                        }
                        break
                    default:
                        break
                }
            }
        });

    }

    const handleReadinessStatus =(list)=>{
        
        let data = list?.map(i=>{
            let answer_set = i?.readinesslevelSet?.edges?.map(({node})=>{
                let response_set = node?.response?.answerSet?.edges?.map(an=>an?.node)?.map(s=>{
                    return {
                        question:s?.question?.id,
                        answer:parseInt(s?.answer),
                        questionText:s?.question?.questionText
                    }
                })
                let rule_validation = {}
                readiness_color_zone.forEach(z=>{
                    rule_validation[z.zone_id] = {}
                    response_set.forEach(rs=>{
                        rule_validation[z.zone_id][rs.question] = true
                        let question_rule = z.rule?.find(rl=> String(rl?.question_id) === String(rs.question))
                        if(question_rule){
                            question_rule.conditions.forEach(c=>{
                                if(!conditionCheck(rs.answer,c.value,c.operation)){
                                    rule_validation[z.zone_id][rs.question] = false
                                }
                            })
                        }else{
                            rule_validation[z.zone_id][rs.question] = false
                        }
                    })
                })
                let zone_enteries = Object.entries(rule_validation).find(rv=> Object.values(rv[1])?.every(item=>item===true))
                let zone 
                if(zone_enteries){
                    zone = readiness_color_zone?.find(z=>String(z?.zone_id) === String(zone_enteries[0]))
                }
                
                return {
                    ...node,
                    response_set,
                    rule_validation,
                    zone
                }
            })

            return {
                ...i,
                zone_detail:answer_set
            }
        })
    
        return data
    }

    React.useEffect(() => {
        if (me) {
            ReadinessAssessment({ai_trigger:true})
        }
        else if (me === null) {
            history.push('/user/login')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me])
    return (
        <SelfReadinessAssessmentView {...props} dataSource={readiness_assessment} onFilter={(e)=>{ReadinessAssessment(e)}} self_assessment_loader={self_assessment_loader} page_info_assessment={page_info_assessment}/>
    )

}
export default compose(withApollo)(SelfReadinessAssessmentContainer);
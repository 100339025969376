// @ts-nocheck
import React from 'react';
import '../Css/callhandler.css'
import transcription_icon from '../../assets/transcription_only.png'
import expand from '../../assets/call_expand.png'
import reduce from '../../assets/reduce.png'
import mapp_logo from '../../assets/new-map-logo.svg'
import call_avatar from '../../assets/call_avatar.png'
import local_call_avatar from '../../assets/local_call_avatar.png'
import { Button, Tooltip,message,Spin } from "antd"
const CallConnected = props => {
    const { video_turned_on, audio_turned_on, setVideoStaus, setAudioStaus, system_icons, cancelCall, remoteVideoStream, localVideoStream, transcription, header_text, receivedsignalData,media_availability,reconnection,reconnection_for_other } = props
    const [isvideo_stream_remote, setisVideoStreamRemote] = React.useState(false)
    const [isvideo_stream_local, setisVideoStreamLocal] = React.useState(false)
    const [other_person_unmute, setSetOtherPersonUnMute] = React.useState(false)
    const remoteVideoRef = React.useRef()
    const localVideoRef = React.useRef()
    const [expanded, setexpanded] = React.useState(false)
    const reload_warning = React.useRef(true)
    React.useEffect(() => {
        const handleBeforeUnload = (event) => {
            if(reload_warning?.current){
                event.preventDefault();
                // Chrome requires the returnValue to be set
                event.returnValue = 'Call will be disconnected if you reload the page.';

                return event;
            }
        };
    
        // Attach the event listener to the window object
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        // Clean up the event listener when the component unmounts
        return () => {
          reload_warning.current = false
          window.removeEventListener('beforeunload', ()=>{});
        };
      }, []);
    

    React.useEffect(() => {
        if (remoteVideoStream) {
            let is_video = remoteVideoStream?.getVideoTracks()
            if (is_video && is_video?.length) {
                setisVideoStreamRemote(true)
            } else {
                setisVideoStreamRemote(false)
            }
            let is_mic = remoteVideoStream?.getAudioTracks()
            if (is_mic && is_mic?.length) {
                setSetOtherPersonUnMute(true)
            } else {
                setSetOtherPersonUnMute(false)
            }
            remoteVideoRef.current.srcObject = remoteVideoStream
        } else {
            setSetOtherPersonUnMute(false)
            setisVideoStreamRemote(false)
            remoteVideoRef.current.srcObject = null
        }
    }, [remoteVideoStream])

    React.useEffect(() => {
        if (localVideoStream) {
            let is_video = localVideoStream?.getVideoTracks()
            if (is_video && is_video?.length) {
                setisVideoStreamLocal(true)
            } else {
                setisVideoStreamLocal(false)
            }
            localVideoRef.current.srcObject = localVideoStream
        } else {
            setisVideoStreamLocal(false)
            localVideoRef.current.srcObject = null
        }
    }, [localVideoStream])

    const changeAudio=(value)=>{
        if(media_availability?.audio){
            setAudioStaus(value)
        }else{
            message.error('do not have access to microphone')
        }
    }

    const changeVideo=(value)=>{
        if(media_availability?.video){
            setVideoStaus(value)
        }else{
            message.error('do not have access to camera')
            
        }
    }
    return (
        
        <div className={!expanded ? 'call-connected-col-container' : 'call-connected-exe-container'}>
            {
                (reconnection || reconnection_for_other) && (
                    <div className='call-reconnection'>
                        <Spin spinning={reconnection||reconnection_for_other} size='large' ></Spin>
                        {
                            reconnection && (<h4 className='reconnecting-text'>You lost your connection.<br/> Trying to reconnect.</h4>)
                        }
                        {
                            reconnection_for_other && (<h4 className='reconnecting-text'>Something goes wrong.<br/> Trying to reconnect.</h4>)
                        }
                    </div>
                )
            }

            {
                expanded ?
                    <div className='call-exp-header'>
                        <img src={mapp_logo} className='call-map-logo' alt="" />
                        <h4 className='call-header-text'>{header_text || 'Call'}</h4>
                    </div>
                    : ''
            }

            <div className={!expanded ? 'stream-container' : 'exe-stream-container'}>
                <video style={{ display: `${isvideo_stream_remote ? 'block' : 'none'}` }} className={!expanded ? 'call-remote-stream' : 'exe-call-remote-stream'} ref={remoteVideoRef} autoPlay playsInline />
                <div style={{ display: `${!isvideo_stream_remote ? 'block' : 'none'}`, width: "100%", height: "100%" }} >
                    <div className={!expanded ? 'remote-audio-container' : 'exe-remote-audio-container'} >
                        {
                            receivedsignalData?.user_data && (
                                <div style={{ width: "100%" }}>
                                    <h4 className='call-user-name'>
                                       <span className='text-transform-capitalize'> {receivedsignalData?.user_data?.firstName}</span>
                                       {
                                        !other_person_unmute && (
                                            <span><img src={system_icons?.mic_off} style={{ width: "15px", height: "15px",marginLeft:'5px'}} alt="" srcset="" /></span>
                                        )
                                       }
                                      
                                    </h4>
                                </div>
                            )
                        }
                        <div className='remote-avatar'>
                            <img src={call_avatar} alt="" srcset="" />
                        </div>

                        <div style={{ width: "100%", height: "2px" }}>

                        </div>
                    </div>
                </div>
                <video style={{ display: `${isvideo_stream_local ? 'block' : 'none'}` }} className={!expanded ? 'call-local-stream' : 'exe-call-local-stream'} ref={localVideoRef} autoPlay playsInline muted />
                <div style={{ display: `${!isvideo_stream_local ? 'block' : 'none'}` }} className='local-audio-container'>
                    <div className='local-audio-sub-container'>
                        <div style={{ width: "100%" }}>
                            <h4 className='call-user-name'>You</h4>
                        </div>
                        <div className='local-avatar'>
                            <img src={local_call_avatar} alt="" srcset="" />
                        </div>
                        <div style={{ width: "100%", height: "1px" }}>

                        </div>
                    </div>
                </div>
            </div>
            {
                !expanded ?
                    <div className='call-menu-container'>
                        {
                            transcription && (
                                <Tooltip title="I am transcribing the meeting. Know that once you close this meeting, the transcript will be available in the Previous Meeting tab. Click the view icon for this meeting and then look for the transcript under Summary.">
                                    <img src={transcription_icon} style={{ width: "20px", height: "20px" }} alt="" srcset="" />
                                </Tooltip>
                            )
                        }
                        <img src={expand} onClick={() => { setexpanded(!expanded) }} style={{ width: "20px", height: "20px", cursor: "pointer" }} alt="" srcset="" />
                        <Tooltip title={!media_availability?.audio?'Do not have access to microphone':''}>
                        <img src={audio_turned_on ? system_icons?.mic_on : system_icons?.mic_off} onClick={() => { changeAudio(!audio_turned_on) }} style={{ width: "20px", height: "20px", cursor: "pointer" }} alt="" srcset="" />
                        </Tooltip>
                        <Tooltip title={!media_availability?.video ? 'Do not have access to camera' : ''}>
                        <img src={video_turned_on ? system_icons?.cam_on : system_icons?.cam_off} onClick={() => { changeVideo(!video_turned_on) }} style={{ width: "20px", height: "20px", cursor: "pointer" }} alt="" srcset="" />
                        </Tooltip>
                        <Button className='call-end-btn' onClick={() => { cancelCall() }}>End</Button>
                    </div> :
                    <div className='exe-menu-container'>
                        {
                            transcription && (
                                <Tooltip title="I am transcribing the meeting. Know that once you close this meeting, the transcript will be available in the Previous Meeting tab. Click the view icon for this meeting and then look for the transcript under Summary.">
                                    <img src={transcription_icon} style={{ width: "20px", height: "20px" }} alt="" srcset="" />
                                </Tooltip>
                            )
                        }
                        <Button className='expanded-menu-button' onClick={() => { setexpanded(!expanded) }}><img src={reduce} alt="" srcset="" /></Button>
                        <Button className='expanded-menu-button' onClick={() => { changeAudio(!audio_turned_on) }}>
                            <img src={audio_turned_on ? system_icons?.mic_on : system_icons?.mic_off} alt="" srcset="" />
                        </Button>
                        <Button className='expanded-menu-button' onClick={() => { changeVideo(!video_turned_on) }}><img src={video_turned_on ? system_icons?.cam_on : system_icons?.cam_off} alt="" srcset="" /></Button>
                        <Button className='call-end-btn' onClick={() => { cancelCall() }}>End</Button>
                    </div>
            }

        </div>

    )

}

export default CallConnected
// @ts-nocheck
import { message } from 'antd';
import { graphql } from '@apollo/client/react/hoc';
import { CREATE_1_1_MUTATION } from '../graphql/Create1_.gql'
import { CREATE_ACTIONITEM_MUTATION } from '../graphql/CreateActionsItems.gql'
import { DELETE_MEETING_MUTATION } from '../graphql/DeleteMeetingMutation.gql'
import { DELETE_ACTIONITEM_MUTATION } from '../graphql/DeleteActionItem.gql'
import { UPDATE_ACTIONITEM_MUTATION } from '../graphql/UpdateActionItems.gql'
import { CREATE_ACTIONITEM_EDIT_MUTATION } from '../graphql/CreateActionItemInEdit.gql'
import { UPDATE_MEETIING_MUTATION } from '../graphql/UpdateMeeting.gql'
import { CREATE_MEETING_NOTES_MUTATION } from '../graphql/CreateMeetingNotes.gql'
import { UPDATE_MEETIING_NOTES_MUTATION } from '../graphql/UpdateMeetingNotes.gql'
import {GET_MANAGER_EMPLOYEE_LIST} from '../graphql/getManagersandEmployee.gql'
import { END_MEETING_MUTATION } from '../graphql/EndMeeting.gql';
import { UPDATE_MEETING_SERIES_MUTATION } from '../graphql/updateMeetingSeries.gql';
import { DELETE_MEETING_SERIES_MUTATION } from '../graphql/deleteMeetingSerieses.gql';
import { RATING_FEEDBACK_MUTATION } from '../graphql/ratingFeedback.gql';
import { CHANGE_ACTIONITEM_STATUS_MUTATION } from '../graphql/ActionItemStatusChange,gql';
// Mutation
export const withCreateMeeting = Component =>
  graphql(CREATE_1_1_MUTATION, {
    props: ({ mutate, history }) => ({
        createMeeting: async values => {
        try {
          const {
            data: { createMeeting }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
        //   message.success('Submitted response!!');
          return createMeeting.meeting;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withCreateActionItems = Component =>
  graphql(CREATE_ACTIONITEM_MUTATION, {
    props: ({ mutate, history }) => ({
        createActionItem: async values => {
        try {
          const {
            data: { createActionItem }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
        //   message.success('Submitted response!!');
          return createActionItem.actionItem;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDeleteMeeting = Component =>
  graphql(DELETE_MEETING_MUTATION, {
    props: ({ mutate, history }) => ({
      deleteMeeting: async values => {
        try {
          const {
            // eslint-disable-next-line no-unused-vars
            data: { deleteMeeting }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Successfully deleted');
          return true;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withDeleteActionItem = Component =>
  graphql(DELETE_ACTIONITEM_MUTATION, {
    props: ({ mutate, history }) => ({
      deleteActionItem: async values => {
        try {
          const {
            // eslint-disable-next-line no-unused-vars
            data: { deleteActionItem }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Successfully deleted');
          return true;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withUpdateActionItems = Component =>
  graphql(UPDATE_ACTIONITEM_MUTATION, {
    props: ({ mutate, history }) => ({
      updateActionItem: async values => {
        try {
          const {
            data: { updateActionItem }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Successfully updated!!');
          return updateActionItem.actionItem;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withCreateActionItemsEditPage = Component =>
  graphql(CREATE_ACTIONITEM_EDIT_MUTATION, {
    props: ({ mutate, history }) => ({
        createActionItem: async values => {
        try {
          const {
            data: { createActionItem }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Successfully Created!!');
          return createActionItem.actionItem;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);
  
  export const withUpdateMeeting = Component =>
  graphql(UPDATE_MEETIING_MUTATION, {
    props: ({ mutate, history }) => ({
      updateMeeting: async values => {
        try {
          const {
            data: { updateMeeting }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
        //   message.success('Submitted response!!');
          return updateMeeting.meeting;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);


  export const withCreateMeetingNotes = Component =>
  graphql(CREATE_MEETING_NOTES_MUTATION, {
    props: ({ mutate, history }) => ({
      createMeetingNotes: async values => {
        try {
          const {
            data: { createMeetingNotes }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
        //   message.success('Submitted response!!');
          return createMeetingNotes.meetingNotes;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUpdateMeetingNotes = Component =>
  graphql(UPDATE_MEETIING_NOTES_MUTATION, {
    props: ({ mutate, history }) => ({
      updateMeetingNotes: async values => {
        try {
          const {
            data: { updateMeetingNotes }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
        //   message.success('Submitted response!!');
          return updateMeetingNotes.meetingNotes;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);
  export const withChangeActionItem = Component =>
  graphql(CHANGE_ACTIONITEM_STATUS_MUTATION, {
    props: ({ mutate, history }) => ({
      updateActionItem: async values => {
        try {
          const {
            data: { updateActionItem }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
        //   message.success('Submitted response!!');
          return updateActionItem.actionItem;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);
  export const employeeList = Component =>
  graphql(GET_MANAGER_EMPLOYEE_LIST, {
    option:props =>{
      return {
        fetchPolicy: 'network-only'
      };
    },
    props({ data: { loading, error,allEmpoloyee }}) {
      return { loading, error, allEmpoloyee };
    }
   
  })(Component);

  export const withEndMeeting = Component =>
  graphql(END_MEETING_MUTATION, {
    props: ({ mutate, history }) => ({
      endMeeting: async values => {
        try {
          const {
            data: { endMeeting }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
        //   message.success('Submitted response!!');
          return endMeeting.meeting;
        } catch (e) {
          message.destroy();
          // message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withUpdateSeriesMeeting = Component =>
  graphql(UPDATE_MEETING_SERIES_MUTATION, {
    props: ({ mutate, history }) => ({
      updateMeetingSeries: async values => {
        try {
          const {
            data: { updateMeetingSeries }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
        //   message.success('Submitted response!!');
          return updateMeetingSeries.meeting;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDeleteSeriesMeeting = Component =>
  graphql(DELETE_MEETING_SERIES_MUTATION, {
    props: ({ mutate, history }) => ({
      deleteMeetingSeries: async values => {
        try {
          const {
            // eslint-disable-next-line no-unused-vars
            data: { deleteMeetingSeries }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Successfully deleted');
          return true;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);
  export const withRatingFeedbackMeeting = Component =>
  graphql(RATING_FEEDBACK_MUTATION, {
    props: ({ mutate, history }) => ({
      createMeetingFeedback: async values => {
        try {
          const {
            data: { createMeetingFeedback }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          message.success('Successfully Rated!!');
          return createMeetingFeedback.meetingFeedback;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
          console.error(e);
        }
      }
    })
  })(Component);
// @ts-nocheck

import { Button, Form,  Tooltip } from "antd";
import { getIntFromString, InputValuesObject, MLCheckBoxField, ReactMarkdown, SelectField } from "modules/look";
import React from "react";
import { user_manual_icon } from "../user-manual-icon";
import { isArray } from "@craco/craco/lib/utils";

const UserManualResponseView = (props) => {
    const { respose_list, question_detail, can_edit, question_no, response_id,updateResponse, SetResponseList,className } = props
    const [enable_editing, SetEnableEdit] = React.useState(false) // state to store the flag for editing
    const [initialValues, SetInitialValues] = React.useState()
    const [form] = Form.useForm();
    const edit_counter = React.useRef(0)

    const changeEditFlag = (value) => {
        if (can_edit) {
            edit_counter.current = edit_counter.current + 1
            SetInitialValues({ [`${question_detail?.question?.id}_${edit_counter.current}`]: respose_list?.map(r => r?.value)})
            SetEnableEdit(value)
        }
    }
    const rules = [{ required: true, message: ' ' }];

    const onSubmit = async(data) => {
        try { 
            let submit_data = data[`${question_detail?.question?.id}_${edit_counter.current}`]
    
            let answer 
            if(isArray(submit_data)){
                answer= submit_data?.join(",")
            }else{
                answer= submit_data
            }  
            let AnswerInput={
                 questionId: getIntFromString(question_detail?.question?.id),
                 answer
             }
             let response = await updateResponse({
                 answers:[AnswerInput],
                 responseId:response_id
             }) 
             
             if(response?.id){
                updateResponseData(answer)
                SetInitialValues(null)
                SetEnableEdit(false) 
             } 
         }catch (e) {  
         }
    }

    const updateResponseData=(answer)=>{
        let list=[]
        question_detail.choice_list.forEach(item=>{
            if( answer?.toLowerCase()?.includes(item?.value?.toLowerCase())){
                list.push({
                    value:item?.value,
                    id:item?.id
                })  
            }
        }) 
        SetResponseList(list)
    }

    const onCancel = () => {
        SetInitialValues(null)
        SetEnableEdit(false)
    }

    return (

        <div className={`user-manual-crad-section ${className || ""}`}>
            <div className="um-card-space-btw-row">
                <div className={`um-question-section ${enable_editing ? "display-block-um-emtag":""}`}>
                    <p style={{ color: "rgba(13, 214, 113, 1)", fontWeight: "700" }}>Q{question_no}.</p>
                    <ReactMarkdown >{question_detail?.question?.questionText}</ReactMarkdown>
                </div>
                {
                    (can_edit && !enable_editing) && (  
                        <Tooltip title="Edit Answer">
                            <img src={user_manual_icon.edit_blue_icon} className="um-edit-icon" alt="edit" onClick={() => { changeEditFlag(true) }}  />
                        </Tooltip>
                    )
                }
            </div>
            {
                !enable_editing ?
                    <div className="um-response-section">
                        <p style={{ color: "rgba(13, 214, 113, 1)", fontWeight: "700", whiteSpace: "nowrap", margin: "0px" }}>Answer :</p>
                        <div className="um-response-list">
                            {
                                respose_list?.map(r => (
                                    <div className={`um-response-card ${r?.is_data_equal?"um-active-response":""}`}>
                                        <p className={`um-response-label`}>{r?.value}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div> :
                    <div>
                        {
                            initialValues && (
                                <Form
                                    name={`${question_detail?.question?.id}_${edit_counter.current}`}
                                    id={`${question_detail?.question?.id}_${edit_counter.current}`}
                                    form={form}
                                    onFinish={(values) => onSubmit(values)}
                                    initialValues={initialValues}
                                >
                                    {question_detail?.question?.question_type === InputValuesObject.checkbox && (
                                        <MLCheckBoxField
                                            name={`${question_detail?.question?.id}_${edit_counter.current}`}
                                            id={`${question_detail?.question?.id}_${edit_counter.current}`}
                                            rules={rules}
                                            choices={question_detail?.choice_list}
                                            onChange={e => { }} 
                                        />
                                    )}

                                    {question_detail?.question?.question_type === InputValuesObject.select && (

                                        <div className="um-survey-select">
                                            <SelectField 
                                                name={`${question_detail?.question?.id}_${edit_counter.current}`}
                                                id={`${question_detail?.question?.id}_${edit_counter.current}`}
                                                otherOption={true} 
                                                rules={rules}
                                                choices={question_detail?.choice_list}
                                                onSelectChange={e => { }}
                                                onBlur={e => { }}
                                            />
                                        </div>

                                    )}

                                    <div className="um-card-action-container">
                                        <Button onClick={() => onCancel()} className="user-manual-action-btn um-action-dark">Cancel</Button>
                                        <Button htmlType="submit" className="user-manual-action-btn um-action-green">Save</Button>
                                    </div>
                                </Form>
                            )
                        }


                    </div>
            }

        </div>

    )
}

export default UserManualResponseView;

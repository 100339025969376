import React, { useState } from "react";
import { withApollo } from "@apollo/client/react/hoc";
import { useParams } from "react-router-dom";

import { compose } from "../../core";
import { handleGetReport } from "../../look";
import { message } from "antd";
import { withMe } from "../../user/containers/UserOperations";

import SurveyReport from "../components/SurveyReport";

const SurveyComparisonReport = (props) => {
  const { client } = props;
  const [actionLoading, setActionLoading] = useState(false);
  const { id, username } = useParams();
  const [report, setReport] = React.useState({});
  async function handleReport(responseId, variables) {
    setActionLoading(true);
    try {
      const res = await handleGetReport(
        Number(id),
        client,
        undefined,
        responseId
      );
      setActionLoading(false);
      res && res.report && setReport({ surveyId: id, username, ...res });
      res && res.errors && message.error(res.errors);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  }

  return (
    <SurveyReport
      actionLoading={actionLoading}
      onReport={handleReport}
      userReport={report}
      {...props}
    />
  );
};

export default compose(withMe, withApollo)(SurveyComparisonReport);

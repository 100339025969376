// @ts-nocheck
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { graphql } from '@apollo/client/react/hoc';
import { Button, Col, Divider, Modal, Row, Spin, Tooltip } from 'antd';
import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import { Link, useHistory, useParams } from 'react-router-dom';
import { IMG } from '../../../../config';
import Kudos_Family_Btn_Logo from '../../../assets/Kudos_Family_Btn_Logo.svg';
import { compose } from '../../../core';
import HOME_ROUTE from '../../../home/route';
import { CardLayout, EmployeeTransfer, getIntFromString, getTeamBase64StringFromInt, getVerticalBase64StringFromInt, globalPermissionValidator, ReactMarkdown, SelectFilterField } from '../../../look';
import { withMeLevel } from '../../../user/containers/UserOperations';
import ROUTE from '../../route';
import AddVertical from '../addVertical';
import {
    withDeleteVertical,
    withEditOrganization, withOrganizationById, withOrganizationFilterUpdating, withOrganizationState
} from '../../../organization/containers/OrganizationOperation';
import { kudos_permission } from 'Permissions/kudos.permission';
import { subscribeToOrganizationById, subscribeToVerticalByOrgId } from '../../../organization/containers/OrganizationSubscriptions';
import { TEAM_BY_TEAM_ID_QUERY } from '../../../organization/graphql/TeamByTeamIdQuery.gql';
import { VERTICAL_BY_VERTICAL_Id_QUERY } from '../../../organization/graphql/VerticalByVerticalIdQuery.gql';
import { withOrganizationDetailVerticalsByOrgIdQuery } from './operation';
import { ORG_DETAIL_TEAMS_BY_VERTICAL_ID_QUERY } from './query';
import { module_config } from 'module_config';
import '../../css/organization_view.css'
const OrganizationDetail = props => {
    const {
        loading,
        organizationById,
        verticals,
        editOrganization,
        onNameChange,
        onDescriptionChange,
        onFiltersRemove,
        onSubverticalChange,
        userPermission,
        subscribeToMore,
        subscribeToMoreVerticalsByOrgId,
        match,
        navigation,
        org_modules
    } = props;
    const [visible, setVisible] = React.useState(false);
    const [employee, setEmployee] = React.useState(false);
    const [fetchLoading, setFetchLoading] = React.useState(false);
    const [total, setTotal] = React.useState(0);
    const [member, setMember] = React.useState({ ver: false, tem: false });
    const [verticalId, setVerticalId] = React.useState('');
    const [teamId, setTeamId] = React.useState('');
    const [Treevisible, setTreeVisible] = React.useState({ step1: false, step2: false, verticalId: 0 });
    const actionLoading = false;
    const history = useHistory()
    const [permission, setpermission] = React.useState()
    const { id } = useParams();
    const [admin_list, SetAdminList] = React.useState([])

    React.useEffect(() => {
        if (organizationById) {
            let list = [
                {
                    user: organizationById?.ceo?.user,
                    role: "CEO",
                    employee_id: organizationById?.ceo?.id
                }
            ].concat(
                organizationById?.poc?.edges?.map(({ node }) => {
                    return {
                        user: node?.user,
                        role: "POC",
                        employee_id: node?.id
                    }
                })
            )
            SetAdminList(list)
        }
    }, [organizationById])

    React.useEffect(() => {
        const subscribe = subscribeToVerticalByOrgId(subscribeToMoreVerticalsByOrgId /* , props.filter */);
        const subscribe1 = subscribeToOrganizationById(subscribeToMore /* , props.filter */);
        return () => {
            subscribe();
            subscribe1();
        };
    });

    const handleEmployeeSubmit = async employees => {
        try {
            await editOrganization({
                id: getIntFromString(organizationById.id),
                name: organizationById.name,
                employees
            });
        } catch (e) {
            throw new Error(e.message);
        }
    };



    React.useEffect(() => {

        if (userPermission?.length) {
            let permission = globalPermissionValidator(kudos_permission, userPermission)

            setpermission(permission)
        }
        else {
            setpermission(null)
        }
    }, [userPermission])

    return (
        <Spin spinning={!organizationById} size="large">
            <div style={{ width: '100%', marginTop: '4em' }}>
                <CardLayout
                    active={false}
                    title={[
                        {
                            name: organizationById?.name,
                            link: `${ROUTE.organizationDetailLink}${organizationById && getIntFromString(organizationById.id)}`
                        }
                    ]}
                    showTitle={false}
                    editLink={ROUTE.editOrganizationLink}
                    addLink={ROUTE.addOrganization}
                    backLink={`${HOME_ROUTE.userdashboard}`}
                    showBack={false}
                    onEdit={true}
                    onFiltersRemove={onFiltersRemove}
                    table={false}
                    sidebar={true}
                    rowKey={organizationById && organizationById.id}
                >
                    <Row style={{ paddingTop: '20px' }}>
                        <Col span={24}>
                            <Spin spinning={loading || actionLoading} size="large">
                                <div style={{ minHeight: '70vh' }}>
                                    {organizationById && (
                                        <>

                                            <div className='org-profile-view-container'>
                                                <div className='org-profile-img-container'>
                                                    <img src={organizationById.logo || IMG} alt="org_logo" />
                                                </div>
                                                <div>
                                                    <h3 style={{ marginBottom: 0 }}>{organizationById.name}</h3>
                                                    <h5 style={{ color: '#898A82' }}>{organizationById.email}</h5>
                                                    {

                                                        (permission?.requried_permission && org_modules && (org_modules?.includes(module_config.kudos) || !org_modules?.length)) ?
                                                            <Button className='organization-kudos' onClick={() => history.push('/kudos/badge-family/home')}>Kudos Family<img src={Kudos_Family_Btn_Logo} alt="" style={{ height: "25px", marginLeft: "10px", backgroundColor: '#4CCB1F' }} /></Button>
                                                            : ''
                                                    }

                                                </div>
                                            </div>
                                            {/* admin list */}
                                            <div className='org-admin-profile-list'>
                                                {
                                                    admin_list?.map(i => (
                                                        <div className='org-detail-admin-profile-main-container'>
                                                            <div className='org-detail-admin-profile'>
                                                                <div className='admin-profile-img-container'>
                                                                    <img src={i?.user?.profile?.profileImage || IMG} alt="org_logo" />
                                                                </div>
                                                                <div className='org-admin-info-section'>
                                                                    <h4 style={{ fontWeight: "600", fontSize: "1.2em" }}>{i?.role}</h4>
                                                                    <h4 style={{ textTransform: "capitalize" }}>{i?.user?.firstName} {i?.user?.lastName || ""}</h4>
                                                                    <Tooltip title={i?.user?.email}>
                                                                        <h4 >{i?.user?.email}</h4>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>



                                            <Row
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'baseline',
                                                    paddingTop: '20px'
                                                }}
                                            >
                                                <h3 style={{ marginBottom: 0, fontWeight: 'normal' }}>Description</h3>

                                            </Row>
                                            <div
                                                style={{
                                                    border: '1px solid rgba(202, 202, 202, 0.25)',
                                                    margin: '7px',
                                                    padding: '15px',
                                                    minHeight: '140px',
                                                    borderRadius: '2px'
                                                }}
                                            >
                                                <ReactMarkdown>{organizationById.description}</ReactMarkdown>
                                            </div>

                                            <Row>
                                                <Divider style={{ width: '100%' }} />
                                            </Row>

                                            <Row justify="end" gutter={[24, 24]}>


                                                <Col>
                                                    <SelectFilterField
                                                        placeholder="Search Vertical by"
                                                        onFiltersRemove={onFiltersRemove}
                                                        options={[
                                                            {
                                                                label: 'Name',
                                                                type: 'text',
                                                                value: onNameChange
                                                            },
                                                            {
                                                                label: 'Description',
                                                                type: 'text',
                                                                value: onDescriptionChange
                                                            },
                                                            // {
                                                            //   label: "Employees",
                                                            //   value: null
                                                            // },
                                                            {
                                                                label: 'Subvertical',
                                                                type: 'subvertical',
                                                                value: onSubverticalChange,
                                                                organizationId: id
                                                            }
                                                        ]}
                                                    />
                                                </Col>
                                            </Row>
                                            <br />
                                            <br />
                                            <br />
                                            {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}

                                            {verticals && (
                                                <Row justify="center" style={{ minHeight: '70vh' }}>
                                                    <Col span={1}>
                                                        <Button
                                                            type="ghost"
                                                            icon={<LeftOutlined />}
                                                            shape="circle"
                                                            style={{ marginTop: '4em' }}
                                                            onClick={() => setTotal(total - 1)}
                                                            disabled={total === 0}
                                                        />
                                                    </Col>
                                                    <Col span={22} style={{ overflow: 'hidden' }}>
                                                        <Spin spinning={fetchLoading} size="medium">
                                                            <Tree
                                                                label={
                                                                    <Row>
                                                                        <Col span={24}>
                                                                            <img
                                                                                src={organizationById.logo || IMG}
                                                                                alt=""
                                                                                style={{
                                                                                    width: 70,
                                                                                    height: 70,
                                                                                    borderRadius: 50,
                                                                                    marginBottom: 10,
                                                                                    backgroundColor: '#f3f3f3'
                                                                                }}
                                                                            />
                                                                        </Col>
                                                                        <Col span={24}>{organizationById && organizationById.name}</Col>
                                                                    </Row>
                                                                }
                                                                lineColor={'#CACACA'}
                                                                lineBorderRadius={'5px'}
                                                            >
                                                                {[...Array(verticals.edgeCount > 7 ? 7 : verticals.edgeCount).keys()].map(i => (
                                                                    <TreeNodeComponent
                                                                        node={verticals.edges[total + i].node}
                                                                        setVerticalId={setVerticalId}
                                                                        setMember={setMember}
                                                                        visible={Treevisible}
                                                                        setVisible={setTreeVisible}
                                                                        teamId={teamId}
                                                                        setTeamId={setTeamId}
                                                                        setFetchLoading={setFetchLoading}
                                                                    />
                                                                ))}
                                                            </Tree>
                                                        </Spin>
                                                    </Col>
                                                    <Col span={1}>
                                                        <Button
                                                            type="ghost"
                                                            icon={<RightOutlined />}
                                                            shape="circle"
                                                            style={{ marginTop: '4em' }}
                                                            onClick={() => setTotal(total + 1)}
                                                            disabled={total + 7 >= verticals.edgeCount}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                            {/* </div> */}
                                        </>
                                    )}
                                </div>
                            </Spin>
                        </Col>
                    </Row>

                    <Modal
                        destroyOnClose
                        visible={employee}
                        onCancel={() => setEmployee(false)}
                        footer={null}
                        title="Employee"
                        width="25%"
                        bodyStyle={{ padding: 12, minHeight: '40vh', maxHeight: '65vh', overflow: 'auto' }}
                    >
                        <Row>
                            {organizationById && organizationById.employees && (
                                <EmployeeTransfer
                                    orgId={getIntFromString(organizationById.id)}
                                    initialValues={organizationById.employees}
                                    handleSubmit={handleEmployeeSubmit}
                                />
                            )}
                        </Row>
                        <br />
                        <Row gutter={[24, 24]}>
                            {organizationById &&
                                organizationById.employees &&
                                organizationById.employees.edgeCount > 0 &&
                                organizationById.employees.edges.map(({ node }) => (
                                    <Col span={24}>
                                        <Row
                                            justify="space-between"
                                            style={{
                                                display: 'block',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}
                                        >
                                            <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
                                            <span className='text-transform-capitalize'>{node.user.firstName}</span> <span className='text-transform-capitalize'>{node.user.lastName}</span>
                                        </Row>
                                    </Col>
                                ))}
                        </Row>
                    </Modal>

                    <Modal
                        destroyOnClose
                        visible={member.ver}
                        onCancel={() => setMember({ ver: false, tem: false })}
                        footer={null}
                        title="Members"
                        width="25%"
                        bodyStyle={{ padding: 12 }}
                        centered
                    >
                        <VerticalMember setMember={setMember} verticalId={getIntFromString(verticalId)} />
                    </Modal>

                    <Modal
                        destroyOnClose
                        visible={member.tem}
                        onCancel={() => setMember({ ver: false, tem: false })}
                        footer={null}
                        title="Members"
                        width="25%"
                        bodyStyle={{ padding: 12 }}
                        centered
                    >
                        <TeamMember setMember={setMember} teamId={getIntFromString(teamId)} />
                    </Modal>

                    {organizationById && organizationById.id && (
                        <AddVertical
                            match={match}
                            navigation={navigation}
                            visible={visible}
                            setVisible={setVisible}
                            orgId={getIntFromString(organizationById.id)}
                        />
                    )}
                </CardLayout>
            </div>
        </Spin>
    );
};

export default compose(
    withOrganizationState,
    withOrganizationById,
    withOrganizationDetailVerticalsByOrgIdQuery,
    withDeleteVertical,
    withOrganizationFilterUpdating,
    withEditOrganization,
    withMeLevel
)(OrganizationDetail);

const TreeNodeComponent = props => {
    const { node, setMember, visible, setVisible, setTeamId, setFetchLoading } = props;
    // const [visible, setVisible] = React.useState({ step1: false, step2: false });
    const [isTeam, setIsTeam] = React.useState(false);
    return (
        <TreeNode
            label={
                <Link to={`${ROUTE.verticalDetailLink}${getIntFromString(node?.id)}`}>
                    <Button
                        type="link"
                        style={{
                            boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
                            margin: 5
                        }}
                        onMouseEnter={() => setVisible({ step1: true, step2: false, verticalId: node?.id })}
                    // onMouseLeave={() => setVisible(false)}
                    >
                        {node?.name}
                    </Button>
                </Link>
            }
        >
            {visible.verticalId === node?.id && visible.step1 ? (
                <>
                    {node?.subvertical
                        ? node?.subvertical?.edges?.map(({ node: sv }) => (
                            <>
                                <TreeNode
                                    label={
                                        <Link to={`${ROUTE.verticalDetailLink}${getIntFromString(sv.id)}`}>
                                            <Button
                                                type="link"
                                                style={{
                                                    boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)',
                                                    margin: 5
                                                }}
                                                onMouseEnter={() => setVisible({ step1: true, step2: true, verticalId: node.id, isMember: sv.id })}
                                            // onMouseLeave={() => setVisible(false)}
                                            >
                                                {sv.name}
                                            </Button>
                                        </Link>
                                    }
                                >
                                    {visible.step2 && visible.isMember === sv.id ? (
                                        <>

                                            {isTeam ? null : (
                                                <TeamNode
                                                    setIsTeam={setIsTeam}
                                                    setVisible={setVisible}
                                                    setTeamId={setTeamId}
                                                    setMember={setMember}
                                                    setFetchLoading={setFetchLoading}
                                                    verticalId={getIntFromString(sv.id)}
                                                />
                                            )}
                                        </>
                                    ) : null}
                                </TreeNode>
                            </>
                        ))
                        : null}

                    {isTeam ? null : (
                        <TeamNode
                            setIsTeam={setIsTeam}
                            setTeamId={setTeamId}
                            setMember={setMember}
                            setVisible={setVisible}
                            verticalId={getIntFromString(node.id)}
                            setFetchLoading={setFetchLoading}
                        />
                    )}


                </>
            ) : null}
        </TreeNode>
    );
};
const VerticalMemberComponent = props => {
    const { vertical } = props;
    return (
        <>
            {vertical &&
                vertical.employees &&
                vertical.employees.edgeCount > 0 &&
                vertical.employees.edges.map(({ node }) => (
                    <Row justify="space-between" style={{ paddingTop: 10 }}>
                        <Col>
                            <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
                            <span className='text-transform-capitalize'>{node.user.firstName}</span> <span className='text-transform-capitalize'>{node.user.lastName}</span>
                        </Col>
                        {/* <Col>
                <Button type="link">Edit</Button>
              </Col> */}
                    </Row>
                ))}
        </>
    );
};

const VerticalMember = compose(
    graphql(VERTICAL_BY_VERTICAL_Id_QUERY, {
        options: props => {
            let id = '';
            if (props.verticalId) {
                id = props.verticalId;
            }
            return { variables: { verticalId: id } };
        },
        props({ data }) {
            const { loading, error, vertical, subscribeToMore, updateQuery } = data;
            return { loading, error, vertical, subscribeToMore, updateQuery };
        }
    })
)(VerticalMemberComponent);

const VerticalTeamNode = props => {
    const { Teams, setIsTeam } = props;
    // eslint-disable-next-line no-unused-vars
    const [isMemeber, setIsMember] = React.useState({ visible: false, id: '' });
    if (Teams && Teams.edges.length > 0) {
        setIsTeam(false);
        return Teams.edges.map(({ node }) => (
            <>
                <TreeNode
                    label={
                        <Link to={`${ROUTE.teamDetailLink}${getIntFromString(node?.id)}`}>
                            <Button
                                type={'link'}
                                style={{ boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.1)', margin: 5 }}
                                onMouseEnter={() => setIsMember({ visible: true, id: node.id })}
                            >
                                {node.name}
                            </Button>
                        </Link>
                    }
                >

                </TreeNode>
            </>
        ));
    } else {
        // setIsTeam(true);
        return null;
    }
};
const TeamNode = compose(
    graphql(ORG_DETAIL_TEAMS_BY_VERTICAL_ID_QUERY, {
        options: props => {
            let id = '';
            if (props.verticalId) {
                id = getVerticalBase64StringFromInt(props.verticalId);
            }
            return { variables: { vertical: id } };
        },
        props({ data, ownProps: { setFetchLoading } }) {
            const { loading, error, Teams, subscribeToMore, updateQuery } = data;
            setFetchLoading(loading);
            return { loading, error, Teams, subscribeToMore, updateQuery };
        }
    })
)(VerticalTeamNode);

const TeamMemberComponent = props => {
    const { Team } = props;
    return (
        <>
            {Team &&
                Team.employees &&
                Team.employees.edgeCount > 0 &&
                Team.employees.edges.map(({ node }) => (
                    <Row justify="space-between" style={{ paddingTop: 10 }}>
                        <Col>
                            <img src={IMG} alt="" style={{ width: 48, borderRadius: 30, marginRight: 10 }} />{' '}
                            <span><span className='text-transform-capitalize'>{node.user.firstName}</span> <span className='text-transform-capitalize'>{node.user.lastName}</span></span>
                        </Col>
                        {/* <Col>
                <Button type="link">Edit</Button>
              </Col> */}
                    </Row>
                ))}
        </>
    );
};

const TeamMember = compose(
    graphql(TEAM_BY_TEAM_ID_QUERY, {
        options: props => {
            let id = '';
            if (props.teamId) {
                id = getTeamBase64StringFromInt(props.teamId);
            }
            return { variables: { id: id } };
        },
        props({ data }) {
            const { loading, error, Team, subscribeToMore, updateQuery } = data;
            return { loading, error, Team, subscribeToMore, updateQuery };
        }
    })
)(TeamMemberComponent);

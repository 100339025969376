import { withApollo } from '@apollo/client/react/hoc';
import { Row} from "antd";
import { compose } from "modules/core";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { getJobFamilyTypeBase64StringFromInt, globalPermissionValidator } from "modules/look";
import { jobfamily_permission } from "Permissions/jobfamily.permission";
import React from "react";
import JobFamilyDetailView from "../components/JobFamilyDetailsView";
import { JOB_FAMILY_BY_ID } from "../graphql/JobFamilyQuries.gql";
import { withDeleteJobFamily } from "./JobFamilyOperations";

const JobFamilyDetailContainer = (props) => {

    const { client,userPermission } = props

    const [jobFamilyDetailsLoading, setJobfamilyDetailsLoading] = React.useState(false);

    const [jobFamily, setJobFamily] = React.useState(undefined);
    const [jobFamilyPermission, setJobFamilyPermission] = React.useState(undefined);



    // For checking the permission of job family
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(jobfamily_permission, userPermission)
            if (permission?.job_family_details_permission) {
                // Call the api to get details of job family with id sent from url
                if (props.match.params?.jobFamily_id) {
                    getJobFamilyDetails(props.match.params?.jobFamily_id)
                }

            }
            setJobFamilyPermission(permission)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPermission, props.match.params?.jobFamily_id])


    // method to fetch the details of particular job family
    const getJobFamilyDetails = async (id) => {
        setJobfamilyDetailsLoading(true)
        let jobFamilyId = getJobFamilyTypeBase64StringFromInt(id)
        const { data } = await client.query({
            query: JOB_FAMILY_BY_ID,
            fetchPolicy: 'network-only',
            variables: { id: jobFamilyId }
        });
        if (data?.jobFamilyById) {
            setJobFamily(data?.jobFamilyById)
            setJobfamilyDetailsLoading(false)

        }

    }




    return (

        <Row justify='center' align='middle'  style={{width:"100%"}}>

                {
                    jobFamilyPermission !== undefined &&
                    <div style={{width:"100%"}}>
                        
                        {
                             jobFamilyPermission?.job_family_details_permission ?
                             <JobFamilyDetailView
                             jobFamilyPermission = {jobFamilyPermission}
                             jobFamily={jobFamily}
                             jobFamilyDetailsLoading={jobFamilyDetailsLoading}
                             {...props} />:
                             <NoPermissionView/>
                        }

                    </div>
                }

           </Row>
     
    )

}

export default compose(withApollo, withDeleteJobFamily)(JobFamilyDetailContainer);
// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import ObjectiveTreeView from '../components/ObjectiveTreeView';
import { OKR_BY_SELECTED_NODE } from '../graphql/OKRTreeStructureQuries';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/client';
import { getIntFromString, getOKRBase64StringFromInt } from 'modules/look';
import { message } from 'antd';
const ObjectiveTreeContainer = props => {

  const { me,client,okr,onClose } = props
  const level = 1
  const [actionloader,setactionloader]=React.useState(true)
  let dataRef=React.useRef({})
  const [okrChart, setOkrChart] = React.useState();
  const [dynamicNode,setDynamicNode] = React.useState(null);


  const closeModal =()=>{
    onClose()
  }

  React.useEffect(()=>{
    if(okr){
        getselectedOKRNode(okr?.id)   
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[okr])
  // ,employee:"${item?.employee}"
  const DYNAMIC_NODE_CONTRIBUTORS =gql`
  query contributor_node {
      ${dynamicNode!==undefined&&dynamicNode!==null&&dynamicNode?.length&&dynamicNode?.filter(item=>item?.type==='parent')
        ?.map(
          (item,index) =>
          `parent${index}: contributors(okr:"${item?.okrid}",role:"OWNER"){
            edges{
              node{
                id
                progress
                okrType
                okr{
                  id
                  goalStatement
                  duedate
                  parent{
                    id
                  }
                }
                employee{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
          `
        )
      .join('')}
      ${dynamicNode!==undefined&&dynamicNode!==null&&dynamicNode?.length&&dynamicNode?.filter(item=>item?.type==='child')
        ?.map(
          (item,index) =>
          `child${index}_${getIntFromString(item?.okrid)}: contributors(okr_Parent:"${item?.okrid}",${item?.employee?`employee:"${item?.employee}"`:''},${item?.role_In?.length?`role_In:"${item?.role_In}"`:''}){
            edges{
              node{
                id
                progress
                okrType
                createdBy{
                  id
                }
                okr{
                  id
                  goalStatement
                  duedate
                  parent{
                    id
                  }
                  contributorSet(role_In:["ASSIGNED","COLLABORATOR"]){
                    totalCount
                  }
                  owner{
                    id
                  }
                  childSet{
                    totalCount
                  }
                }
                employee{
                  id
                  user{
                    id
                    firstName
                    lastName
                  }
                }
              }
            }
          }
          `
        )
      .join('')}
      ${dynamicNode!==undefined&&dynamicNode!==null&&dynamicNode?.length&&dynamicNode?.filter(item=>item?.type==='subokr')
      ?.map(
        (item,index) =>
        `subokr${index}_${getIntFromString(item?.okrid)}: contributors(okr:"${item?.okrid}",role_In:["COLLABORATOR","ASSIGNED"],employee_Ne:"${item?.employee}"){
          edges{
            node{
              id
              progress
              okrType
              createdBy{
                id
              }
              okr{
                id
                goalStatement
                duedate
                parent{
                  id
                }
                contributorSet(role_In:["ASSIGNED","COLLABORATOR"]){
                  totalCount
                }
                owner{
                  id
                }
                childSet{
                  totalCount
                }
              }
              employee{
                id
                user{
                  id
                  firstName
                  lastName
                }
              }
            }
          }
        }
        `
      )
      .join('')}
     }
    `;
    
    const [getnodes] = useLazyQuery(DYNAMIC_NODE_CONTRIBUTORS,{onCompleted:(data)=>{fetchNodeData(data)}});
   
    React.useEffect(()=>{
      if(dynamicNode!==undefined&&dynamicNode!==null&&dynamicNode?.length){
        setactionloader(true)
        getnodes()
       
      }
      // else{
      //   setDynamicNode(null)
      // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dynamicNode])

    const fetchNodeData =(data)=>{
      let ifparent = dynamicNode?.find(item=>item?.type==='parent')
      let ifchild = dynamicNode?.find(item=>item?.type==='child')
      let ifsubchild = dynamicNode?.find(item=>item?.type==='subokr')
    
      let child_list= Object.keys(data).filter(item=>item?.includes('child'))?.map(val=>{
        let edges = data[val]?.edges
        return edges?.map(({node})=>{
          return {
          name:`${node?.employee?.user?.firstName} ${node?.employee?.user?.lastName||''}`,
          level:ifchild?.level,
          parentokr:getOKRBase64StringFromInt(val?.split('_')[1]),
          attributes: {
            employee_detail:{firstName:node?.employee?.user?.firstName,lastName:node?.employee?.user?.lastName||''},
            progress:node?.progress||0,
            contribution_id:node?.id,
            createdBy:node?.createdBy?.id,
            okrType:node?.okrType,  //OBJECTIVE,KEYRESULT,MILESTONE
            rect_node: false, 
            loader:false,
            active_node:false,
            employee:node?.employee?.id,
            child:node?.okr?.childSet?.totalCount?true:false,
            child_contributor:node?.okr?.contributorSet?.totalCount?true:false,
            okr:node?.okr
          },
          }
        })
      
      })

      let sub_child_list= Object.keys(data).filter(item=>item?.includes('subokr'))?.map(val=>{
        let edges = data[val]?.edges
        return edges?.map(({node})=>{
          return {
          name:`${node?.employee?.user?.firstName} ${node?.employee?.user?.lastName||''}`,
          level:ifsubchild?.level,
          parentokr:getOKRBase64StringFromInt(val?.split('_')[1]),
          type:"subokr",
          attributes: {
            employee_detail:{firstName:node?.employee?.user?.firstName,lastName:node?.employee?.user?.lastName||''},
            progress:node?.progress||0,
            contribution_id:node?.id,
            createdBy:node?.createdBy?.id,
            okrType:node?.okrType,  //OBJECTIVE,KEYRESULT,MILESTONE
            rect_node: false, 
            loader:false,
            active_node:false,
            employee:node?.employee?.id,
            child:node?.okr?.childSet?.totalCount?true:false,
            child_contributor:node?.okr?.contributorSet?.totalCount?true:false,
            okr:node?.okr
          },
          }
        })
      
      })

      let parent_list= Object.keys(data).filter(item=>item?.includes('parent'))?.map(val=>{
        let edges = data[val]?.edges
        return edges?.map(({node})=>{
          return {
          name:`${node?.employee?.user?.firstName} ${node?.employee?.user?.lastName||''}`,
          level:ifparent?.level,
          parentokr:node?.okr?.parent?.id,
          attributes: {
            employee_detail:{firstName:node?.employee?.user?.firstName,lastName:node?.employee?.user?.lastName||''},
            progress:node?.progress||0,
            contribution_id:node?.id,
            okrType:node?.okrType,
            rect_node: false,
            loader:false,
            active_node:false,
            employee:node?.employee?.id,
            child:true,
            okr:node?.okr
          },
          }
        })
      
      })
      sub_child_list=[].concat.apply([],sub_child_list)
      child_list=[].concat.apply([],child_list)
      parent_list=[].concat.apply([],parent_list)
      if((ifchild || ifsubchild) && !ifparent && ![...sub_child_list,...child_list]?.length){
        message.warn('No child OKR available')
      }else if(![...sub_child_list,...child_list,...parent_list]?.length){
        message.warn('No OKR available')
      }
      let previous_node = dataRef['current']
  
      previous_node=previous_node.filter(item=>!item.attributes?.loader)
      
      // remove duplicates from child list
      child_list = child_list.reduce((acc,item)=>{
        if(!acc?.find(data=>data?.level===item?.level && data?.attributes?.okr?.id ===item?.attributes?.okr?.id && data?.attributes?.employee ===item?.attributes?.employee)){
          acc.push(item)
        }
        return acc;
      },[])
      
      if(child_list && child_list?.length && ifchild){
        previous_node= [...previous_node,...child_list]
      }
      // remove duplicates from sub child list
      sub_child_list = sub_child_list.reduce((acc,item)=>{
        if(!acc?.find(data=>data?.level===item?.level && data?.attributes?.okr?.id ===item?.attributes?.okr?.id && data?.attributes?.employee ===item?.attributes?.employee)){
          acc.push(item)
        }
        return acc;
      },[])

      if(sub_child_list && sub_child_list?.length && ifsubchild){
        previous_node= [...previous_node,...sub_child_list]
      }
      
      if(ifparent && parent_list && parent_list?.length){       
        previous_node= [...previous_node,...parent_list]
      }
     
      handleTreeStructure(previous_node)
      setDynamicNode(null)
      setactionloader(false)
    }
    const getselectedOKRNode = async (id) => {
    const { data } = await client.query({
      query: OKR_BY_SELECTED_NODE,
      variables: {id},
      fetchPolicy: 'network-only'
    });
    if(data?.okrById){
      let tree_data =[
        {
          name:`${me?.firstName} ${me?.lastName}`,
          level:0,
          parentokr:data?.okrById?.parent?.id,
          attributes: {
            employee_detail:{firstName:me?.firstName,lastName:me?.lastName},
            okrType:data?.okrById?.okrType,
            progress:okr?.progress||0,
            contribution_id:'main_node',
            rect_node: true,
            loader:false,
            active_node:true,
            employee:me?.employee?.id,
            child:data?.okrById?.childSet?.totalCount?true:false,
            child_contributor:data?.okrById?.contributorSet?.totalCount?true:false,
            okr:{...okr,owner:data?.okrById?.owner,parent:data?.okrById?.parent}
          }
        },
      ]
      let dynamicNodes =[]
      if(data?.okrById?.parent){
        let parent_data={
          name:'PN',
          level:-1,
          parentokr:'',
          attributes: {
            rect_node: false,
            loader:true,
            okr:{id:data?.okrById?.parent?.id}
          },
        }
        tree_data.push(parent_data)
        dynamicNodes.push({level:-1,type:"parent",okrid:data?.okrById?.parent?.id})
      }
      if(data?.okrById?.childSet?.totalCount){
        let child_data={
          name:'CN',
          level:1,
          parentokr:okr?.id,
          attributes: {
            rect_node: false,
            loader:true,
          },
        }
        
        tree_data.push(child_data)
        dynamicNodes.push({level:1,type:"child",okrid:data?.okrById?.id,role_In:["OWNER"]})
      }

      if(data?.okrById?.contributorSet?.totalCount && data?.okrById?.okrType!=="OBJECTIVE"){
        if(data?.okrById?.contributorSet?.totalCount && !data?.okrById?.childSet?.totalCount){
          let child_data={
            name:'CN',
            level:1,
            parentokr:okr?.id,
            attributes: {
              rect_node: false,
              loader:true,
            },
          }
          
          tree_data.push(child_data)
        }
       
        dynamicNodes.push(
          {
            level:1,
            type:"subokr",
            okrid:okr?.id,
            employee:me?.employee?.id
          }
        )
      }
      handleTreeStructure(tree_data,dynamicNodes)
      
    }
   
  }

  //use this function to generate tree structure 
  const handleTreeStructure=(data,dynamicNodes)=>{

    dataRef['current']=data
    let levels = data?.map(item=>item?.level)
    let parent_level = Math.min(...levels)
    let child_level = Math.max(...levels)
    let tree =data?.slice(0)
    for(let i=child_level;i>parent_level;i--){
     let node = addtoTree(i,i-1,tree)
     tree=node
    }
    setOkrChart(tree[0])
    setactionloader(false)
   
    if(dynamicNodes !== undefined && dynamicNodes !== null && dynamicNodes?.length){
      setDynamicNode(dynamicNodes)
    }
    
  } 

  let sort_level ={
    OBJECTIVE:3,
    KEY_RESULT:1,
    MILESTONE:2
  }
  
  const addtoTree=(child,parent,data)=>{
    let child_nodes = data?.filter(item=>item?.level===child)
    let parent_nodes = data?.filter(item=>item?.level===parent)
    let x = parent_nodes?.map(val=>{
      return {
        ...val,
        children:filterNodes(child,parent,child_nodes,val)?.sort((a,b)=> 
        sort_level[a?.attributes?.okrType]>sort_level[b?.attributes?.okrType]?1:sort_level[a?.attributes?.okrType]<sort_level[b?.attributes?.okrType]?-1:0
        )
      }
    })
    return [...data?.filter(rem=>rem?.level!==child && rem?.level!==parent ),...x]
    
  }

  const filterNodes =(child,parent,child_nodes,val)=>{
 
   let child_list= child_nodes?.filter(i=>child<=1?
    i?.parentokr===val?.attributes?.okr?.id && i?.type!=='subokr':
    i?.parentokr===val?.attributes?.okr?.id && i?.attributes?.employee ===val?.attributes?.employee && i?.type!=='subokr' )
   let sub_child_list = child_nodes?.filter(i=> i?.parentokr===val?.attributes?.okr?.id && i?.type==='subokr' )
   return [...child_list,...sub_child_list]
  }

  const getNextLevelData=(parent,child)=>{
    
    let dynamicNodes =[]
    let previous_data = dataRef['current']
    let active_node = previous_data?.find(item=>item?.attributes?.active_node)
    
    if(child && active_node && (active_node?.attributes?.child || active_node?.attributes?.child_contributor)){
      let fetched_parent = previous_data?.find(item=>item?.attributes?.okr?.id===active_node?.parentokr)
      if(fetched_parent?.attributes?.okr?.id===active_node?.attributes?.okr?.id && active_node?.attributes?.createdBy===active_node?.attributes?.employee){
        message.warn('No child OKR available')
      }else{
        let fetched_child = previous_data?.filter(item=>item?.parentokr === active_node?.attributes?.okr?.id )
        if(!fetched_child?.find(item=>item?.attributes?.employee===active_node?.attributes?.employee && item?.level===active_node?.level+1) && !fetched_child?.find(item=>item?.type==='subokr' && item?.level===active_node?.level+1) && active_node?.level>=1){
          let no_child=true
          if(active_node?.attributes?.child){
          no_child=false
          dynamicNodes.push(
            {
              level:active_node?.level+1,
              type:"child",
              okrid:active_node?.attributes?.okr?.id,
              employee:active_node?.attributes?.employee,
              role_In:["OWNER"]
            }
          )
        }

          if(active_node?.attributes?.okrType!=="OBJECTIVE" && active_node?.attributes?.child_contributor){
            no_child=false
            dynamicNodes.push(
              {
                level:active_node?.level+1,
                type:"subokr",
                okrid:active_node?.attributes?.okr?.id,
                employee:active_node?.attributes?.employee
              }
            )
          }

          if(no_child){
            message.warn('No child OKR available')
            return
          }
          
        }
        else{
          
          if(active_node?.level<=0 && fetched_child?.length){
            let non_active_child=fetched_child?.find(item=>!item?.attributes?.rect_node)
            expandSelectedNode(non_active_child?.attributes?.contribution_id||fetched_child[0]?.attributes?.contribution_id)
          }else{
            let child_nodes = previous_data?.filter(item=>item?.parentokr === active_node?.attributes?.okr?.id && item?.attributes?.employee===active_node?.attributes?.employee && item?.level===active_node?.level+1)
            if(child_nodes?.length){
              let non_active_child=child_nodes?.find(item=>!item?.attributes?.rect_node)
              expandSelectedNode(non_active_child?.attributes?.contribution_id||child_nodes[0]?.attributes?.contribution_id)
            }else{
              let sub_child_nodes = previous_data?.filter(item=>item?.parentokr === active_node?.attributes?.okr?.id && item?.type==='subokr' && item?.level===active_node?.level+1)
              if(sub_child_nodes?.length){
                let non_active_child=sub_child_nodes?.find(item=>!item?.attributes?.rect_node)
                expandSelectedNode(non_active_child?.attributes?.contribution_id||sub_child_nodes[0]?.attributes?.contribution_id)
              }
            }
          }
          
        }
      }
      
    }else if(child){
      message.warn('No child OKR available')
    }
    
    if(parent && active_node && active_node?.attributes?.okr?.parent?.id){
      let fetched_parent = previous_data?.find(item=>item?.attributes?.okr?.id===active_node?.parentokr)
      
      if(!fetched_parent){
        dynamicNodes.push({level:active_node?.level-1,type:"parent",okrid:active_node?.attributes?.okr?.parent?.id})
      }
      else{
        
        if(active_node?.level>1){
          let parent = previous_data?.find(item=>item?.attributes?.okr?.id===active_node?.parentokr && item?.attributes?.employee===active_node?.attributes?.employee && item?.level===active_node?.level-1)
          if(parent){
            expandSelectedNode(parent?.attributes?.contribution_id)
          }else if(active_node?.type==="subokr"){
            let sub_okr_parent = previous_data?.find(item=>item?.attributes?.okr?.id===active_node?.parentokr && item?.attributes?.employee!==active_node?.attributes?.employee && item?.level===active_node?.level-1)
            if(sub_okr_parent){
              expandSelectedNode(sub_okr_parent?.attributes?.contribution_id)
            }
          }
          
        }
        else if(active_node?.level===1){
          expandSelectedNode('main_node')
        }else{
          let parent = previous_data?.find(item=>item?.attributes?.okr?.id===active_node?.parentokr)
          expandSelectedNode(parent?.attributes?.contribution_id)
        }
        
      }
    }
    else if(parent){
      message.warn('No parent OKR available')
    }


    if(!dynamicNode && dynamicNodes !== null &&dynamicNodes !== undefined&& dynamicNodes?.length){
      setDynamicNode(dynamicNodes)
    }
    
  }

  const changeLevel=(level)=>{
    let previous_data = dataRef['current']
    let dynamicNodes =[]
    // && item?.parentokr!==item?.attributes?.okr?.id && item?.attributes?.employee!==item?.attributes?.createdBy
    if(level===2){
      let current_child_list = previous_data?.filter(item=>item?.level===1 && item?.attributes?.child  ) 
      let child_node_data = current_child_list?.map(item=>{
        let fetched_parent = previous_data?.find(val=>val?.attributes?.okr?.id===item?.parentokr)
        if(fetched_parent?.attributes?.okr?.id===item?.attributes?.okr?.id && item?.attributes?.createdBy===item?.attributes?.employee){
          return undefined
        }
        else{
          return {
            level:2,
            type:"child",
            okrid:item?.attributes?.okr?.id,
            employee:item?.attributes?.employee,
            role_In:["OWNER"]
          }
        }
      })
      let current_sub_child_list = previous_data?.filter(item=>item?.level===1 && item?.attributes?.child_contributor && item?.attributes?.okrType!=="OBJECTIVE") 
      let sub_child_node_data = current_sub_child_list?.map(item=>{
        let fetched_parent = previous_data?.find(val=>val?.attributes?.okr?.id===item?.parentokr)
        if(fetched_parent?.attributes?.okr?.id===item?.attributes?.okr?.id && item?.attributes?.createdBy===item?.attributes?.employee){
          return undefined
        }
        else{
          return  {
            level:2,
            type:"subokr",
            okrid:item?.attributes?.okr?.id,
            employee:item?.attributes?.employee
          }
        }
        
      })
      dynamicNodes=dynamicNodes.concat([...child_node_data,...sub_child_node_data]?.filter(i=>i))
     
      let current_parent = previous_data?.find(item=>item?.level===-1 && item?.attributes?.okr?.parent?.id)
      if(current_parent){
        dynamicNodes.push({level:-2,type:"parent",okrid:current_parent?.attributes?.okr?.parent?.id})
      }
    }
    else{
      previous_data= previous_data?.filter(item=>item?.level<=1 && item?.level>=-1)
    }
    if(!dynamicNode && level===2 && dynamicNodes!=null && dynamicNodes !== undefined && dynamicNodes?.length){
      let updated_data = previous_data?.filter(item=>item?.level<2 && item?.level>-2 )
      dataRef['current']=updated_data
      setDynamicNode(dynamicNodes)
    }
    else if(!dynamicNode && level===2 && !dynamicNodes?.length){
      message.warn('No OKR available for this level')
    }
    else if(level===1){
      handleTreeStructure(previous_data)
    }
  }

  const reCenter=()=>{
    let previous_data = dataRef['current']
    let active_node = previous_data?.find(item=>item?.attributes?.active_node)
    if(active_node){
      expandSelectedNode(active_node?.attributes?.contribution_id)
    }
  }

  const expandSelectedNode=(id)=>{
    try{
     let element = document?.getElementById(`fo_${id}`)
     if(element){
      element.click()
     }
    }
    catch(e){
 
    }
    let nodes = dataRef['current']
    let active_nodes = nodes?.filter(item=>item?.attributes?.active_node)
    active_nodes.forEach(item=>item.attributes.active_node=false)

    let selection = nodes?.find(item=>item?.attributes?.contribution_id===id)
   
    if(selection){
      selection.attributes.rect_node=true
      selection.attributes.active_node=true
      handleTreeStructure(nodes)
    }
    
  }

  return (
    <>
     <ObjectiveTreeView
     actionloader={actionloader} 
     closeModal={closeModal} 
     okrChart={okrChart} 
     level={level}
     getNextLevelData={(parent,child)=>{getNextLevelData(parent,child)}}
     changeLevel={(e)=>{changeLevel(e)}}
     expandSelectedNode={(e)=>{expandSelectedNode(e)}}
     reCenter={()=>reCenter()}
     {...props} />
    </>
  );
};

export default compose(withApollo)(ObjectiveTreeContainer);
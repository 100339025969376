// @ts-nocheck
import React from "react";
import { compose } from "../../core";
import '../css/CompetencyStyle.css'
import SubCompetencyMainView from "../components/SubCompetencyView";

const SubCompetencyContainer = (props) => {

    const { back_to_home,SetbackToHome } = props
    const [current_comptency_tab, SetCurrentCompetencyTab] = React.useState()

    // to set the route to local storage when child route is changed
    React.useEffect(() => {
        if (current_comptency_tab) {
            let role_definition_navigation = {
                parent_route: { key: 'sub_competency', label: 'Sub Competency' },
                child_route: current_comptency_tab
            }
            localStorage.setItem('role_definition_navigation', JSON.stringify(role_definition_navigation))
        }
    }, [current_comptency_tab])

    // to handle child route when user navigate back to role definition
    const competency_navigation = JSON.parse(localStorage?.getItem('role_definition_navigation'))
    if (!current_comptency_tab) {
        if (competency_navigation?.child_route?.key?.includes('competency_')) {
            SetCurrentCompetencyTab(competency_navigation?.child_route)
        } else {
            SetCurrentCompetencyTab({ key: "competency_home_view" })
        }
    }

    React.useEffect(()=>{
        if(back_to_home==='competency_home_view'){
            let nav ={
                parent_route: { key: 'sub_competency', label: 'Sub Competency' },
                child_route: { key: "competency_home_view" }
            }
            localStorage.setItem('role_definition_navigation', JSON.stringify(nav))
            SetCurrentCompetencyTab({ key: "competency_home_view" })
            SetbackToHome(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[back_to_home])

    return (
        <SubCompetencyMainView
            {...props}
            current_comptency_tab={current_comptency_tab}
            SetCurrentCompetencyTab={(e) => { SetCurrentCompetencyTab(e) }}
        />
    )

}

export default compose()(SubCompetencyContainer);
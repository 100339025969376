import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Row, Col, Card, Button, Pagination, Spin, Tabs, Empty } from 'antd';
import styled from 'styled-components';

import { getIntFromString, MetaTags, ReactMarkdown } from '../../look';
import ROUTE from '../../survey/route';
import { PAGINATION_LIMIT, PRIMARY_CLR } from '../../../config';

const { TabPane } = Tabs;

const AllOrganizationSurveys = props => {
  const {
    pagination: { first, offset },
    allOrgSurveysByImplicitOrgIdLoading,
    loading,
    allOrgSurveysByImplicitOrgId,
    allPublicSurveys,
    onPaginationChange
  } = props;
  const [activeTab, setActiveTab] = React.useState('1');

  const RenderItem = ({ node }) => {
    return (
      <Col lg={12} xs={24} align="center">
        <Card style={{ boxShadow: '0px 4px 30px 5px rgba(0, 0, 0, 0.1)', borderRadius: '10px' }}>
          <h2>{node.name}</h2>
          <Divider
            style={{
              borderColor: PRIMARY_CLR,
              borderWidth: '3px',
              marginLeft: '5vw',
              marginRight: '5vw',
              width: 'unset',
              minWidth: 'unset'
            }}
          />
          <div align="left" style={{ minHeight: '350px' }}>
            <ReactMarkdown>{node.description}</ReactMarkdown>
          </div>
          <Button type="primary" block>
            <Link to={`${ROUTE.surveyQuizLink}${getIntFromString(node.id)}`}>Start Quiz</Link>
          </Button>
        </Card>
      </Col>
    );
  };

  const pagination = totalCount => (
    <Col span={24} align="right">
      <Pagination
        current={(offset + first) / first}
        responsive={true}
        defaultCurrent={1}
        onChange={(page, pageSize) => onPaginationChange({ offset: page * pageSize - pageSize, first: pageSize })}
        showTotal={(total, range) => totalCount && `Showing ${range[0]} to ${range[1]} of ${total} entries.`}
        defaultPageSize={PAGINATION_LIMIT}
        total={totalCount}
      />
    </Col>
  );

  const assignedSurveyTab = (
    <Spin spinning={allOrgSurveysByImplicitOrgIdLoading} size="large">
      <Row gutter={[24, 24]}>
        {allOrgSurveysByImplicitOrgId && allOrgSurveysByImplicitOrgId.edges.length > 0 && (
          <>
            {allOrgSurveysByImplicitOrgId.edges.map(({ node, index }) => (
              <RenderItem node={node} key={index} />
            ))}
            {allOrgSurveysByImplicitOrgId && pagination(allOrgSurveysByImplicitOrgId.totalCount)}
          </>
        )}
      </Row>
    </Spin>
  );
  const otherSurveyTab = (
    <Spin spinning={loading} size="large">
      <Row gutter={[24, 24]}>
        {allPublicSurveys && allPublicSurveys.edges.length > 0 ? (
          <>
            {allPublicSurveys.edges.map(({ node, index }) => (
              <RenderItem node={node} key={index} />
            ))}
            {allPublicSurveys && pagination(allPublicSurveys.totalCount)}
          </>
        ) : (
          <div className="HVCenter" style={{ width: '80vw', position: 'relative' }}>
            <Empty />
          </div>
        )}
      </Row>
    </Spin>
  );
  return (
    <>
      <MetaTags title="All Organization" description="This is All Organization page" />
      <Row justify="center" align="middle">
        <Col xs={20} md={24}>
          <Spin spinning={loading || allOrgSurveysByImplicitOrgIdLoading} size="large">
            {allOrgSurveysByImplicitOrgId?.edges?.length > 0 ? (
              <CutomTabs
                animated={{ inkBar: true, tabPane: true }}
                defaultActiveKey="1"
                /* centered */
                tabBarGutter={0}
                style={{ padding: '0 5vw' }}
                onChange={setActiveTab}
              >
                <TabPane tab={<h2 style={{ color: activeTab === '1' ? PRIMARY_CLR : '#CACACA' }}>Assigned to you</h2>} key="1">
                  {assignedSurveyTab}
                </TabPane>
                <TabPane tab={<h2 style={{ color: activeTab === '2' ? PRIMARY_CLR : '#CACACA' }}>Other Survey</h2>} key="2">
                  {otherSurveyTab}
                </TabPane>
              </CutomTabs>
            ) : (
              otherSurveyTab
            )}
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default AllOrganizationSurveys;

const CutomTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    display: grid !important;
    grid-template-columns: auto auto !important;
    width: 98% !important;
    gap: 1vw;
    .ant-tabs-tab-btn {
      margin: auto;
    }
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
`;

// import { OmitProps } from "antd/lib/transfer/ListBody";
import { compose } from "modules/core";
import React from "react";
import GetInTouchView from "../components/GetInTouchView";
import { withCreateContactRequest } from "../../page/containers/PageOperations";

const GetInTouchContainer = (props) => {

    return (
        <>
           <GetInTouchView  {...props} />
        </>
    );
};

export default  compose(withCreateContactRequest)(GetInTouchContainer);


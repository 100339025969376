import { graphql } from '@apollo/client/react/hoc';
import { EDIT_VERTICAL_VERTICAL_BY_VERTICAL_Id_QUERY } from './query';
import { getVerticalBase64StringFromInt } from 'modules/look';


export const withEditVerticalVerticalByVerticalId = (
    Component // Admin
) =>
    graphql(EDIT_VERTICAL_VERTICAL_BY_VERTICAL_Id_QUERY, {
        options: props => {
            let id = '';
            if (props.match) {
                id = getVerticalBase64StringFromInt(props.match.params.id);
            } else if (props.navigation) {
                id = getVerticalBase64StringFromInt(props.navigation.state.params.id);
            }

            return {
                variables: { verticalId:id }
            };
        },
        props({ data: { loading, error, vertical, subscribeToMore, updateQuery } }) {
            if (error) {
                throw new Error(error.message);
            }
            return { loadingVerifyToken: loading, error, vertical, subscribeToMore, updateQuery };
        }
    })(Component);
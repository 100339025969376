import gql from "graphql-tag";

export const ALL_TICKET_LIST =gql`
query allTickets($raisedBy: ID,$title_Icontains: String,$createdAt_Range: [DateTime],$priority: Int,$first: Int,$status: String,$status_In: [String],$offset: Int){
    allTickets(raisedBy:$raisedBy,title_Icontains:$title_Icontains,createdAt_Range:$createdAt_Range,priority:$priority,first:$first,status:$status,status_In: $status_In,offset:$offset){
        totalCount
            pageInfo{
            hasNextPage
            endCursor
        }
        edges {
            node{
                id
                title
                description
                createdAt
                attachments
                status
                priority
                updatedAt
                workitemId
                raisedBy{
                id
                firstName
                lastName
                }
            }
        }
    }
}
`
export const ALL_TICKET_LIST_SUBSCIPTION =gql`
    subscription ticketSubscription {
        ticketSubscription{
            mutation
            ticket{
                id
                title
                description
                createdAt
                attachments
                status
                priority
                updatedAt
                workitemId
                raisedBy{
                id
                firstName
                lastName
                }
            }
        }
    }
`
export const TICKET_BY_ID =gql`
query ticketById($id: ID!){
    ticketById(id:$id){
    id
    title
    description
    createdAt
    workitemId
    priority
    attachments
    status
    resolvedAt
    slaDate
    assignee{
      id
      firstName
      lastName
    }
    raisedBy{
      id
      firstName
    }
    ticketcommentSet{
      edges{
        node{
          id
          comment
          createdAt
          sentBy{
            id
            firstName
            lastName
            profile{
              id
              profileImage
            }
          }
        }
      }
		}
	}
}
`
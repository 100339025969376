import React, { useState } from "react";
import { Form, Spin, Switch } from "antd";
import { learningPathIcons } from "modules/learning-path/icons";
import "../css/ai-tips.css";

const AiTipsView = (props) => {
  const {toggleAction,settings_list,settings_loading,navigateRoute}=props
  const [form] = Form.useForm();
  const [sections, setSections] = useState([
    { key: 0, section: "Soul",module:"Soul", enabled: true },
    { key: 1, section: "OKRs",module:"OKR", enabled: true },
    { key: 2, section: "1 on 1",module:"Meeting", enabled: true },
    { key: 3, section: "Readiness zone",module:"Readiness", enabled: true },
    { key: 4, section: "Feedback",module:"Feedback", enabled: true },
    { key: 5, section: "Coaching Engagement",module:"CoachingConversation", enabled: true },
    { key: 6, section: "Managing Performanace",module:"ManagingPerformance", enabled: true },
    { key: 7, section: "IDP",module:"IDP", enabled: true },
    // { key: 8, section: "Shoal",module:"Shoal", enabled: true },
    // { key: 9, section: "Whole",module:"Whole", enabled: true },
    // { key: 10, section: "Survey",module:"Survey", enabled: true },
    // { key: 11, section: "Kudos",module:"Kudos", enabled: true },
  ]);
  React.useEffect(()=>{
    if(settings_list?.length){
      let c = sections.map(item => {
        const match = settings_list.find(bItem => bItem.module === item.module);
        return match
            ? { ...item, id: match.id, enabled: match?.active }
            : { ...item, enabled:true };
    });
    setSections(c)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[settings_list])
  const handleToggleChange = (e, value) => {
    toggleAction({active:e,...value})
    const newSections = sections.map((section) =>
      section.key === value?.key ? { ...section, enabled: e } : section
    );
    setSections(newSections);
  };

  return (
    <div className="ai-tips-root" style={{ maxWidth: "70%" }}>
      <h2 className="ai-tips-title">
        <img src={learningPathIcons?.back_arrow} alt="back_arrow" style={{ width: "20px", cursor: "pointer", marginRight: "10px" }} onClick={() => navigateRoute("-1")} />
      Tips setting
      </h2>
      <Spin spinning={settings_loading}>
        <Form form={form} layout="vertical">
          {sections.length > 0 &&
            sections.map((item, index) => (
              <Form.Item
                style={{
                  width: "100%",
                  fontSize: "1em",
                  fontFamily: "Poppins",
                  display: "flex",
                  margin: "0px",
                }}
                name={"item?.id"}
                key={item?.key}
              >
                <div
                  className={`tip-section-container ${
                    index === 0 ? "first-section" : ""
                  }`}
                  key={item?.key}
                >
                  <div className="section-title-label">{item?.section}</div>
                  <div
                    className={`message-action-container ${
                      index === 0 ? "first-element" : ""
                    }`}
                  >
                    <p className="action-message-label">
                      Turn {item?.enabled ? "off" : "on"} the tips for {item?.section}
                    </p>
                    <Switch
                      className="tip-action-switch"
                      style={{ marginLeft: "14px", backgroundColor: "#F6FBFF !important" }}
                      checked={item?.enabled}
                      onChange={(e) => {
                        handleToggleChange(e,item);
                      }}
                    />
                  </div>
                </div>
              </Form.Item>
            ))}
        </Form>
      </Spin>
    </div>
  );
};

export default AiTipsView;

import React from "react";
import arrow_icon from "../../assets/icons/drop_down_arrow.svg"
import { Spin, Tooltip } from "antd";
import SoulIndex from "./soul-report-index";
import Idppagination from "modules/IDP/components/idpPagination";
const SoulTeamReportView = (props) => {
    const { soul_team_report, report_loading, all_employee_report_page_info, vertical_report_page_info, team_report_page_info, getReportList } = props
    const [show_report, setShowReport] = React.useState()
    const color_codes = [
        {
            key: "Values",
            border: "1px solid #FFDAA2",
            icon_color: 'rgba(234, 157, 39, 1)',
            background: "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 245, 210, 0.5) 100%)"
        },
        {
            key: "Strengths",
            border: "1px solid rgba(254, 189, 190, 1)",
            icon_color: 'rgba(240, 91, 94, 1)',
            background: "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 228, 229, 0.5) 100%)"
        },
        {
            key: "Personality",
            border: "1px solid rgba(147, 255, 239, 1)",
            icon_color: 'rgba(49, 205, 181, 1)',
            background: "linear-gradient(180deg, #FFFFFF 0%, rgba(224, 255, 250, 0.5) 100%)"

        },
        {
            key: "Knowledge & Skills",
            border: "1px solid rgba(137, 179, 255, 1)",
            icon_color: 'rgba(66, 105, 177, 1)',
            background: "linear-gradient(180deg, #FFFFFF 0%, rgba(223, 234, 255, 0.5) 100%)"


        }
    ]

    const showReport = (id) => {
        if (id === show_report) {
            setShowReport(null)
        }
        else {
            setShowReport(id)
        }

    }
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "1em", width: "100%" }}>
            <Spin spinning={report_loading}>
                {soul_team_report?.length > 0 && soul_team_report?.map(item => (
                    <div className="soul-report-container-card-container" style={{ marginTop: "1em", overflow: "auto" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: '1em', width: "100%", cursor: "pointer" }} onClick={() => showReport(item?.id)}>
                            <h4 className="soul-report-card-title text-transform-capitalize" style={{ margin: "0px" }}><span className="text-transform-capitalize">{item?.firstName}</span> <span className="text-transform-capitalize">{item?.lastName}</span></h4>
                            <img src={arrow_icon} alt="arrow_icon" style={{ cursor: "pointer", transition: "transform 0.3s ease", transform: item?.id === show_report ? 'rotate(180deg)' : "rotate(0deg)" }} />
                        </div>
                        {item?.id === show_report && <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", gap: '1em', width: '100%' }}>
                            {item?.response['value']?.length > 0 && <div style={{ background: color_codes[0]?.background, border: color_codes[1]?.border, width: "100%", borderRadius: ".25em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: 'center', overflow: "auto" }}>
                                <div className="soul-team-report-answer" style={{ borderRight: color_codes[0]?.border }}>
                                    <h4 className="soul-team-answer">Values</h4></div>
                                {item?.response['value']?.map((value, index) => (
                                    <div className="soul-team-report-answer"
                                        style={{
                                            borderRight: index !== item?.response['value']?.length - 1
                                                ? color_codes[0]?.border
                                                : 'none'
                                        }}>
                                        <div>
                                            <SoulIndex index={`index_${(index + 1)}`} color={color_codes[0].icon_color} />
                                        </div>

                                        <Tooltip title={value?.answer}>
                                            <h4 className="soul-team-answer">{value?.answer}</h4>
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>}
                            {item?.response['strength']?.length > 0 && <div style={{ background: color_codes[1]?.background, border: color_codes[1]?.border, width: "100%", borderRadius: ".25em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: 'center', overflow: "auto" }}>
                                <div className="soul-team-report-answer" style={{ borderRight: color_codes[1]?.border }}>
                                    <h4 className="soul-team-answer">Strengths</h4></div>
                                {item?.response['strength']?.map((value, index) => (
                                    <div className="soul-team-report-answer"
                                        style={{
                                            borderRight: index !== item?.response['strength']?.length - 1
                                                ? color_codes[1]?.border
                                                : 'none'
                                        }}>
                                        <div>
                                            <SoulIndex index={`index_${(index + 1)}`} color={color_codes[1].icon_color} />
                                        </div>

                                        <Tooltip title={value?.answer}>
                                            <h4 className="soul-team-answer">{value?.answer}</h4>
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>}
                            {item?.response['personality']?.length > 0 && <div style={{ background: color_codes[2]?.background, border: color_codes[2]?.border, width: "100%", borderRadius: ".25em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: 'center', overflow: "auto" }}>
                                <div className="soul-team-report-answer" style={{ borderRight: color_codes[2]?.border }}>
                                    <h4 className="soul-team-answer">Personality</h4></div>
                                {item?.response['personality']?.map((value, index) => (
                                    <div className="soul-team-report-answer"
                                        style={{
                                            borderRight: index !== item?.response['personality']?.length - 1
                                                ? color_codes[2]?.border
                                                : 'none'
                                        }}>
                                        <div>
                                            <SoulIndex index={`index_${(index + 1)}`} color={color_codes[2].icon_color} />
                                        </div>

                                        <Tooltip title={value?.answer}>
                                            <h4 className="soul-team-answer">{value?.answer}</h4>
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>}
                            {item?.response['knowledge']?.length > 0 && <div style={{ background: color_codes[3]?.background, border: color_codes[3]?.border, width: "100%", borderRadius: ".25em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: 'center', gap: '1em', overflow: "auto" }}>
                                <div className="soul-team-report-answer" style={{ borderRight: color_codes[3]?.border }}>
                                    <h4 className="soul-team-answer">Knowledge & Skills</h4></div>
                                {item?.response['knowledge']?.map((value, index) => (
                                    <div className="soul-team-report-answer"
                                        style={{
                                            borderRight: index !== item?.response['knowledge']?.length - 1
                                                ? color_codes[3]?.border
                                                : 'none'
                                        }}>
                                        <div>
                                            <SoulIndex index={`index_${(index + 1)}`} color={color_codes[3].icon_color} />
                                        </div>
                                        <Tooltip title={value?.answer}>
                                            <h4 className="soul-team-answer">{value?.answer}</h4>
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>}
                            {Object.keys(item?.response).length === 0 &&
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: "center", paddingTop: "3em", paddingBottom: "3em" }}>
                                    <h4 className="ca-no-data-text">No Report Found</h4>
                                </div>}
                        </div>}
                    </div>
                ))}
            </Spin>
            {
                ((all_employee_report_page_info?.hasNextPage && all_employee_report_page_info?.cursor) ||
                // eslint-disable-next-line no-mixed-operators
                (vertical_report_page_info?.hasNextPage && vertical_report_page_info?.cursor) ||
                // eslint-disable-next-line no-mixed-operators
                (team_report_page_info?.hasNextPage && team_report_page_info?.cursor) && !report_loading) ? 
                    <Idppagination on_pagination={() => { getReportList({ cursor: all_employee_report_page_info?.cursor || vertical_report_page_info?.cursor || team_report_page_info?.cursor }) }} />:""
                
            }
            {
                soul_team_report?.length === 0 && !report_loading && (
                    <div className='rd-nodata'>
                        <h4 className='rd-nodata-label'>No Report Found</h4>
                    </div>
                )
            }
        </div>
    )
}

export default SoulTeamReportView;
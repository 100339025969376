import React from 'react';
import { Row, Col, Form } from 'antd';

import {
  CKEditorField,
  TextField,
  ButtonsComponent,
  RenderUploadWithCrop
} from '../../look';

export const CKEditorFieldName = 'description';

const ToolFormComponent = props => {
  const { getToolByIdAdmin, onSubmit } = props;
  const [load, setLoad] = React.useState(false);
  const [url, setUrl] = React.useState(getToolByIdAdmin?.thumbnailImage || '');
  const [button, setButton] = React.useState('');

  const initialValue = {
    // commitment: getToolByIdAdmin && getToolByIdAdmin.commitment,
    description: getToolByIdAdmin && getToolByIdAdmin.description,
    uiFlow: getToolByIdAdmin && getToolByIdAdmin.uiFlow,
    // formsection: getToolByIdAdmin && getToolByIdAdmin.formsection,
    // organization: getToolByIdAdmin && getToolByIdAdmin.organization.edges.map(({ node }) => getIntFromString(node.id)),
    // public: getToolByIdAdmin && getToolByIdAdmin.public,
    // status: getToolByIdAdmin && getToolByIdAdmin.status,
    // team: getToolByIdAdmin && getToolByIdAdmin.team.edges.map(({ node }) => getIntFromString(node.id)),
    // textsection: getToolByIdAdmin && getToolByIdAdmin.textsection,
    thumbnailImage: getToolByIdAdmin && getToolByIdAdmin.thumbnailImage,
    title: getToolByIdAdmin && getToolByIdAdmin.title
    // uiFlow: getToolByIdAdmin && getToolByIdAdmin.uiFlow,
    // vertical: getToolByIdAdmin && getToolByIdAdmin.vertical.edges.map(({ node }) => getIntFromString(node.id))
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          layout="vertical"
          initialValues={initialValue}
          onFinish={values => onSubmit({ ...values, thumbnailImage: url }, button)}
          required={false}
          scrollToFirstError={true}
        >
          <Row gutter={24}>
            <Col lg={8} md={24}>
              <TextField name={'title'} label="Title" rules={[{ required: true, message: 'Title is required!' }]} />
            </Col>
            <Col lg={8} md={24}>
              <TextField name={'uiFlow'} label="UI Flow" />
            </Col>
            <Col lg={8} md={24}>
              <RenderUploadWithCrop
                width={300}
                height={250}
                setUrl={setUrl}
                setLoad={setLoad}
                name={'thumbnailImage'}
                label="Image" /* rules={[{ required: true, message: 'Image is required!' }]} */
                value={initialValue?.thumbnailImage}
              />
            </Col>
            <Col span={24}>
              <CKEditorField
                name={CKEditorFieldName}
                label="Description"
                initialValue={getToolByIdAdmin && getToolByIdAdmin.description}
              // rules={[{ required: true, message: 'All questions are required!' }]}
              />
            </Col>
          </Row>
          <ButtonsComponent disabled={load} setButton={setButton} />
        </Form>
      </Col>
    </Row>
  );
};

export default ToolFormComponent;



import { Button, Modal, Row, Spin } from "antd";
import { getIntFromString } from "modules/look";
import React from "react";
import { EDIT_BARS_ICON } from "../Icon";
import bars_route from "../route";
import BarsDeleteConfirmation from "./BarDeleteConfirmation";
import BarsEditDetailsTabContainer from "./BarsEditDetails";





const BarsEditViewNoBarsAvailable = (props) => {
  const { barsDetails, barsDetailsLoading, setBarsDetails, allRatingScales, createBulkBehaviourallyAnchoredRatings,
     empLevelRef,navigateRoute
  } = props

  const [activeLevel, setActiveLevel] = React.useState();
  
  
  React.useEffect(() => {

    window.scrollTo(0, 0);

  }, [])

  React.useEffect(() => {

    if (props.match.params.level_id) {
      setActiveLevel(props.match.params.level_id)
    }

  }, [props.match.params.level_id])

  const [newlyAddedBars, setNewAddedBars] = React.useState([]);
  const [subCompetencyModalVisible, setSubCompetencyModalVisible] = React.useState(false)



  const handleExampleChange = async (e, barsNode, level) => {
    
    let newBars = newlyAddedBars;
    let indexOfUpdateBar = barsDetails?.empLevels?.findIndex((value) =>
      value?.ratingScale?.id === barsNode?.ratingScale?.id && value?.id === level?.id
    );
    
    let updatedBars = barsDetails;
    
    if (updatedBars.empLevels[indexOfUpdateBar]?.bar) {
      updatedBars.empLevels[indexOfUpdateBar].behaviourExample = e;
    } else {
      let indexOfNewlyAddedBar = newlyAddedBars?.findIndex((value) =>

        value?.ratingScaleId === barsNode?.ratingScale?.id && value?.employeeLevelId === level?.id
      );
      
      if (indexOfNewlyAddedBar >= 0) {
        newlyAddedBars[indexOfNewlyAddedBar].behaviourExample = e;
      } else {
      
        let record = {
          competency: { mainCompetencyId: barsDetails?.mainCompetencyId },
          ratingScaleId: barsNode?.ratingScale?.id,
          employeeLevelId: level?.id,
          behaviourExample: e
        }
        newBars = newBars.concat([record])
        
        setNewAddedBars([...newBars]);
      }

    }

    

    let newRecords = newBars?.map((record) => { return { id: record?.employeeLevelId, behaviourExample: record?.behaviourExample } })


    let combindBars = { empLevels: updatedBars?.empLevels.concat(newRecords) }

    let arr = [];
    empLevelRef.current
      .forEach(level => (
        arr.push({ ...level, status: combindBars?.empLevels?.filter(item => item.id === level?.id && item?.behaviourExample)?.length !== allRatingScales?.length ? "pending" : 'completed' })
      ));

    
    empLevelRef.current = arr;
    setBarsDetails({ ...updatedBars });

  }
  
  const handleEditBars = async () => {
    if (newlyAddedBars?.length) {
      await createBulkBehaviourallyAnchoredRatings({ behaviourallyAnchoredRatingDataList: newlyAddedBars })
    }   
  }

  const handleCancelSubCompetency = () => {
    setSubCompetencyModalVisible(false);
    navigateRoute(bars_route.bars_home)
  }

  const handleSubCompetencyConfirm = () => {
    setSubCompetencyModalVisible(false)
    navigateRoute(bars_route.bars_create_sub_comp?.replace(":competency_id",getIntFromString(barsDetails?.mainCompetencyId)))
  }

  return (
    <>

      <Spin spinning={barsDetailsLoading}>

        <div className="create-bars-root poppins" style={{ borderRadius: '0.8em' }}>

          <img src={EDIT_BARS_ICON.new_back_arrow} onClick={(e) =>  navigateRoute(bars_route.bars_home)} className="create-bar-back-arrow pointer" alt=""/>

          <Row align="middle" justify="center">
            <div className="create-bars-header">Edit <span>BARS</span></div>
          </Row>

          <div className="create-bars-body">
            <div className="create-bar-sub-heading create-bar-margin-seperator">Competency</div>

            <div className="bars-create-competency-title create-bar-margin-seperator">{barsDetails?.title}</div>
            {/* Component for adding data into rating scales with respective employee levels */}
            {
              barsDetails ?
              <BarsEditDetailsTabContainer
              barsDetails={barsDetails}
              allEmployeeLevels={empLevelRef.current}
              allRatingScales={allRatingScales}
              handleExampleChange={handleExampleChange}
              indexOfElements={0}
              activeLevel={activeLevel ? activeLevel : 0}
              setActiveLevel={setActiveLevel}
            ></BarsEditDetailsTabContainer>
            : null
            }
           
          </div>
          {
            (empLevelRef.current?.length && allRatingScales?.length) ?
            <Row justify="center">
            <Button className="bars-submit-action" onClick={(e) => handleEditBars()}>Submit</Button>
            </Row>
            :null
          }
         


          <Modal
            visible={subCompetencyModalVisible}
            footer={null}
            centered
            destroyOnClose={true}
            closable={false}
          >
            <BarsDeleteConfirmation
              message={"Yay! You've defined the competencies! Would you like to continue defining the sub-competencies?"}
              confirm_action_label={"Yes"}
              cancel_action_label={"No"}
              onConfirm={(e) => handleSubCompetencyConfirm()}
              onCancel={(e) => handleCancelSubCompetency()}
              confirm_class="bars-confimation-action"
              cancel_class="bars-cancel-action"
            ></BarsDeleteConfirmation>

          </Modal>


        </div>
      </Spin>
    </>
  );
};

export default BarsEditViewNoBarsAvailable;


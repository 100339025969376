import React from 'react';
import { compose } from '../../core';
import SurveySelectorView from '../components/selectSurveyVersion';


const GrowSurveySelectorContainer = props => {
    return (
        <>
            <SurveySelectorView {...props}/>
        </>
    )

}
export default compose()(GrowSurveySelectorContainer);
// @ts-nocheck
import React from "react";
import { compose } from "modules/core";
import RoleAddEditView from "../components/RoleAddEditView";
import { ALL_EMPLOYEE_LEVEL, All_EMPLOYEE_LEVEL_SUBSCRIPTION } from "../graphql/EmployeeLevelQuries.gql";
import { COMPETENCY_LIST_JOBFAMILY } from "../graphql/JobFamilyQuries.gql";
import { RESPONSIBILITY_LIST_FOR_ROLE, ROLE_BY_ID_QUERY, VERTICAL_LIST_FOR_ROLE } from "../graphql/RoleQueries.gql";
import { withCreateRole, withUpdateRole } from "../RoleDefinitionOperation";
import { getIntFromString } from "modules/look";
import role_definition_route from "../route";


const RoleAddEditContainer = (props)=>{

    const { SetRemoveBaground,permission,client,me,createRole,updateRole,current_role_tab,SetRoleTab,navigateRoute,setCurrentTab }=props
    const [submit_loader,SetSubmitLoader] = React.useState(false)
    const [employee_level_list, SetEmployeeLevelList] = React.useState([])
    const [role_specific_competency_list,SetRoleSpecificCompList]= React.useState([])
    const [vertical_list,SetVerticalList]= React.useState([])
    const [reponsibility_list,SetAllResponsibilities]= React.useState([])
    const [role_detail,SetRoleDeatil] = React.useState()

    const employee_level_list_ref = React.useRef([])
    const employee_level_subscription = React.useRef(null)

      // to update the employee list refference when the state gets changed
      React.useEffect(() => {
        employee_level_list_ref.current = employee_level_list
    }, [employee_level_list])

    React.useEffect(()=>{
        if(permission.required_permission && me){
            getEmployeLevel()
            getRoleSpecificCompetencyList()
            getVerticalList()
            if(permission?.responsibility_permission){
                getResponsibilityList()
            }
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[permission,me])

    React.useEffect(()=>{
        if(current_role_tab.role_id){
            getRoleDetail(current_role_tab.role_id)
        }else{
            SetRoleDeatil({})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[current_role_tab])

     // Code to be executed when the component is unmounted
     React.useEffect(() => {
        return () => {
            SetRemoveBaground(false)
            if(employee_level_subscription?.current){
                employee_level_subscription.current.unsubscribe();
                employee_level_subscription.current = null
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRoleDetail=async(id)=>{
        SetSubmitLoader(true)
        const { data } = await client.query( {
            query: ROLE_BY_ID_QUERY,
            variables: {id},
            fetchPolicy: 'network-only'
        } );
        if ( data?.roleById ) {
            let role = data?.roleById
            
            let req_competency = role?.requiredSpecificCompetency?.edges?.map(({node})=>{return{...node,must_have:true,label:node?.title}})
            let opt_competency = role?.optionalSpecificCompetency?.edges?.map(({node})=>{return{...node,must_have:false,label:node?.title}})
            let selected_responsibility = role?.responsibility?.edges?.map(({node})=>{return{...node,label:node?.title}})
            let req_eligibility = role?.requiredEligibility?.edges?.map(({node})=>{
                return {
                    must_have:true,
                    id:node?.id,
                    education:node?.education?.edges?.map((ed)=>{
                        return {
                            ...ed?.node,
                            label:node?.stream
                        }
                    }),
                    eligibilityData:{
                        qualitativeExperience:node?.qualitativeExperience,
                        quantitativeExperience:{
                            quantitativeExperienceMax:node?.quantitativeExperienceMax?.toFixed(1),
                            quantitativeExperienceMin:node?.quantitativeExperienceMin?.toFixed(1)
                        }
                    }
                }
            })
            let opt_eligibility = role?.optionalEligibility?.edges?.map(({node})=>{
                return {
                    must_have:false,
                    id:node?.id,
                    education:node?.education?.edges?.map((ed)=>{
                        return {
                            ...ed?.node,
                            label:node?.stream
                        }
                    }),
                    eligibilityData:{
                        qualitativeExperience:node?.qualitativeExperience,
                        quantitativeExperience:{
                            quantitativeExperienceMax:node?.quantitativeExperienceMax?.toFixed(1),
                            quantitativeExperienceMin:node?.quantitativeExperienceMin?.toFixed(1)
                        }
                    }
                }
            })
            let details = {
                id:role?.id,
                title:role?.title,
                description:role?.description,
                employeeLevelId:role?.employeeLevel?.id,
                verticalId:role?.vertical?.id,
                selected_role_specific_competency:[...req_competency,...opt_competency] || [],
                selected_eligibility:[...req_eligibility,...opt_eligibility],
                selected_responsibility
            } 
            SetRoleDeatil( details )
            SetSubmitLoader(false)
        }
    }

     // qraphql api to fetch employee level data list
     const getEmployeLevel = async (filter) => {
        const { data } = await client.query({
            query: ALL_EMPLOYEE_LEVEL,
            fetchPolicy: 'network-only',
            variables: filter
        });
        if (data?.allEmployeeLevels) {
            let list = data?.allEmployeeLevels?.edges.map(({ node }) => node)
            SetEmployeeLevelList(list)
            if (!employee_level_subscription?.current) {
                employeeLevelsubscription()
            }
        }
    }

    // to add subscription to employee level api
    const employeeLevelsubscription = async () => {
        employee_level_subscription.current = client.subscribe({
            query: All_EMPLOYEE_LEVEL_SUBSCRIPTION,
            fetchPolicy: 'no-cache'
        }).subscribe({
            next(result) {
                let employee_level_cache_data = [].concat(employee_level_list_ref.current)
                let scbscription_data = result.data.employeeLevelSubscription
                switch (scbscription_data.mutation) {
                    case 'CREATE':
                        employee_level_cache_data.push(scbscription_data?.employeeLevel)
                        SetEmployeeLevelList(employee_level_cache_data)
                        break
                    case 'UPDATE':
                        let doc = employee_level_cache_data?.find(item=>item?.id === scbscription_data?.employeeLevel?.id)
                        if(doc){
                            Object.entries(scbscription_data?.employeeLevel).forEach(item=>{
                                doc[item[0]]=item[1]
                            })
                            SetEmployeeLevelList(employee_level_cache_data)
                        }
                        break
                    case 'DELETE':
                        employee_level_cache_data = employee_level_cache_data?.filter(item=>item?.id !== scbscription_data?.employeeLevel?.id)
                        SetEmployeeLevelList(employee_level_cache_data)
                        break
                    default:
                        break
                }
            }
        });
    }

      // get competency list 
      const getRoleSpecificCompetencyList = async ( filter ) => {
        const { data } = await client.query( {
            query: COMPETENCY_LIST_JOBFAMILY,
            variables: {...filter,competencyType:"Role_Specific"},
            fetchPolicy: 'network-only' 
        } );
        if ( data?.allCompetencies ) {
            SetRoleSpecificCompList(data?.allCompetencies?.edges?.map(({node})=>node))
        }else{
            SetRoleSpecificCompList([])
        }
    }

    const getVerticalList =async (filter)=>{
        let id =me?.employee?.organizationSet?.edges[0]?.node?.id
        const { data } = await client.query({
            query: VERTICAL_LIST_FOR_ROLE,
            variables: {organization:id,first:25,...filter},
            fetchPolicy:'network-only'
        });
        if(data.verticals){
            SetVerticalList(data.verticals?.edges?.map(({node})=>node))
        }
      }

      const getResponsibilityList = async (filter) => {
        const { data } = await client.query({
          query: RESPONSIBILITY_LIST_FOR_ROLE,
          variables: {...filter,first:25},
          fetchPolicy: 'network-only'
        });
        if (data.allResponsibilities) {
          SetAllResponsibilities(data.allResponsibilities?.edges?.map(({node})=>node))
        }
      }

      // submit the form to backend by checking if its a update or create
    const submitResponse=async(data)=>{
        try {
          SetSubmitLoader(true)
          if(current_role_tab?.role_id){
            let update ={
                id:getIntFromString(current_role_tab?.role_id),
                ...data?.roleData,
                optionalEligibility:data?.optionalEligibility,
                requiredEligibility:data?.requiredEligibility,
                responsibility:data?.responsibility,
                optionalSpecificCompetency:data?.optionalSpecificCompetency,
                requiredSpecificCompetency:data?.requiredSpecificCompetency
            }
            let response = await updateRole(update)
            if(response?.id){
                let cached_navigation = JSON.parse( localStorage?.getItem( 'role_definition_navigation' ) )
                delete cached_navigation?.child_route?.role_id
                localStorage.setItem('role_definition_navigation', JSON.stringify(cached_navigation))
                SetSubmitLoader(false)
                navigateRoute(role_definition_route.role_detail_page?.replace(":role_id",getIntFromString(current_role_tab?.role_id)))
            }else{
                SetSubmitLoader(false)
            }
          }
          else{ 
              let response = await createRole(data)
              if(response?.id){
                const cached_navigation = JSON.parse( localStorage?.getItem( 'role_definition_navigation' ) )
                if(cached_navigation && cached_navigation?.jobfamily_data){
                    if(cached_navigation?.jobfamily_data?.role){
                        cached_navigation.jobfamily_data.role.push({id:response?.id,label:data?.roleData?.title,...data?.roleData})
                    }
                    let role_definition_navigation = {
                        ...cached_navigation,
                        parent_route: { key: 'job_family', label: 'Job Family' },
                        child_route: { key:'jobfamily_add_edit_view',jobfamily_id:cached_navigation?.jobfamily_data?.id},
                    }
                    localStorage.setItem('role_definition_navigation', JSON.stringify(role_definition_navigation))
                    setCurrentTab({ key: 'job_family', label: 'Job Family' })
                }else{
                    SetRoleTab( {key:'role_home_view'} )
                }
               
              }else{
                  SetSubmitLoader(false)
              }
          }
        } catch (error) {
          SetSubmitLoader(false)
        }
      }

    const navigate =(key)=>{
        if(current_role_tab?.role_id){
            let cached_navigation = JSON.parse( localStorage?.getItem( 'role_definition_navigation' ) )
            delete cached_navigation?.child_route?.role_id
            localStorage.setItem('role_definition_navigation', JSON.stringify(cached_navigation))
            navigateRoute("-1")
        }
        else{
            const cached_navigation = JSON.parse( localStorage?.getItem( 'role_definition_navigation' ) )
            if(cached_navigation?.jobfamily_data){
                let role_definition_navigation = {
                    ...cached_navigation,
                    parent_route: { key: 'job_family', label: 'Job Family' },
                    child_route: { key:'jobfamily_add_edit_view',jobfamily_id:cached_navigation?.jobfamily_data?.id},
                }
                localStorage.setItem('role_definition_navigation', JSON.stringify(role_definition_navigation))
                setCurrentTab({ key: 'job_family', label: 'Job Family' })
            }
            else{
                SetRoleTab( {key} )
            }
            
        }
        
    }

    return (
        <>
        <RoleAddEditView 
        {...props}
        submit_loader={submit_loader}
        onSubmit={(value)=>submitResponse(value)}
        getEmployeLevel={(filter)=>{getEmployeLevel(filter)}}
        employee_level_list={employee_level_list}
        role_specific_competency_list={role_specific_competency_list}
        SetRoleSpecificCompList={(e)=>{SetRoleSpecificCompList(e)}}
        getRoleSpecificCompetencyList={(filter)=>{getRoleSpecificCompetencyList(filter)}}
        getVerticalList={(filter)=>{getVerticalList(filter)}}
        vertical_list={vertical_list}
        SetAllResponsibilities={(e)=>{SetAllResponsibilities(e)}}
        reponsibility_list={reponsibility_list}
        getResponsibilityList={(filter)=>{getResponsibilityList(filter)}}
        onCancel={(e)=>{navigate(e)}}
        role_detail={role_detail}
        />
        </>
    )
}

export default compose(withCreateRole,withUpdateRole)(RoleAddEditContainer)
import React, { useState } from 'react';
import gql from 'graphql-tag';
import {Spin, Empty } from 'antd';
import { compose } from '../../../core';

import SelectField from './SelectField';
// import { getIntFromString } from '../functions';
// import { withLookUserState, withLookUserFilterUpdating } from '../../containers/LookOperations';
import { withApollo } from '@apollo/client/react/hoc';

const EMPLOYEE_MILESTONE_QUERY = gql`
 query milestones($owner:ID!,$title_Icontains:String) {
    milestones(owner:$owner, title_Icontains: $title_Icontains) {
        totalCount
        edges {
          node {
            id
            title
          }
        }
    }
}
`;

const MilestoneField = props => {
    const { loading, margin, owner, client } = props;
    const handleFilterRemove = React.useRef(() => { });

    const [milestones, setMilestones] = useState()
    const [chars, setChars] = useState("")



    React.useEffect(() => {
        const getMileStones = async () => {
            const { data } = await client.query({
                query: EMPLOYEE_MILESTONE_QUERY,
                variables: { owner: owner, title_Icontains: chars },
            });
            setMilestones(data?.milestones?.edges?.map(({ node }) => ({
                label: node.title,
                value: node.id
            })));
        }
        if (owner !== undefined) {
            getMileStones()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chars, props.owner])

    const onSelectFeildSearch = (s) => {
        setChars(s)
    }


    return (
        <SelectField
            margin={margin}
            showSearch
            onSearch={(e) => { onSelectFeildSearch(e) }}
            choices={milestones}
            style={{ width: !margin && 300, marginBottom: !margin && 0 }}
            // labelInValue
            filterOption={false}
            onBlur={handleFilterRemove.current}
            label={"Select Milestone"}
            notFoundContent={
                loading ? (
                    <div align="center">
                        <Spin size="small" />
                    </div>
                ) : (
                    <Empty />
                )
            }
            {...props}
        />
    );
};

export default compose(withApollo)(MilestoneField);

import React from "react";
import { Row, Col,Spin} from "antd";
import TeamComponent from "./TeamComponent";
import DashboardMenuBar from "./DashboardMenuBar";
import Dashboard from "../containers/Dashboard";

import ROUTE from "../route";
import PersonalCommitment from "../containers/PersonalCommitment";
import RecentTools from "../containers/RecentTools";
import MAppToolkit from "../containers/MAppToolkit";

const PersonalDashboardComponent = (props) => {
  const { loading, storypermission } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  return (
    <Spin spinning={loading || submitLoading} size="large">
      <Dashboard active={ROUTE.personal}>
        {!loading && (
          <Row>
            <Col lg={15} span={24}>
              <DashboardMenuBar
                active={ROUTE.personal}
                setActive={props.setActive}
              />
              <br />
              <Row  align="middle" style={{paddingBottom:'20px'}}>
                {/* <Col span={12}>
                  <Card
                    bodyStyle={{
                      boxShadow: "5px 0px 25px 3px rgba(32, 32, 32, 0.1)",
                      borderRadius: "10px",
                    }}
                    hoverable
                    bordered={false}
                  >
                    <Row gutter={[24, 8]}>
                      <Col span={24} align="center">
                        <h2 style={{ fontWeight: "normal" }}>MApp Score</h2>
                      </Col>

                      <Col span={24} align="center">
                        <Row justify="center">
                          <Progress
                            type="circle"
                            percent={100}
                            strokeColor="#E83D2A"
                            strokeWidth="2"
                            format={() => (
                              <h1
                                style={{ margin: "0", fontSize: "xxx-large" }}
                              >
                                {MAppPulseCheck?.toFixed(0) || 0}
                              </h1>
                            )}
                          />
                        </Row>
                      </Col>

                      <Col span={24} align="center">
                        <br />        
                      </Col> 
                    </Row>
                  </Card>
                </Col> */}

                <Col span={12}>
                  <Row gutter={[24, 20]}>
                    {/* <MAppPulseCheck personal /> */}
                    <MAppToolkit />
                    {/* <TakeAssisment /> */}
                  </Row>
                </Col>
              </Row>
            </Col>

            {storypermission&&storypermission?.story_optional_permission&&<TeamComponent me={props.me} />}

            <Col lg={{ span: 24 }}>
              <br />
              <Row gutter={[24, 24]}>
                <PersonalCommitment
                  dashboard={"personal"}
                  setSubmitLoading={setSubmitLoading}
                />

                <RecentTools />

                {/* <MAppAnchors /> */}
              </Row>
            </Col>
          </Row>
        )}
      </Dashboard>
    </Spin>
  );
};

export default PersonalDashboardComponent;

// modification on lines
// 8(2)
// 11(2)
// 12(2)
// 13(2)
// 17
// 46
// 61
// 81
// 89
// 90
// 109

// @ts-nocheck
import React from "react";
import { Row, Col, Button, Modal, Spin } from "antd";
import styled from "styled-components";
import { MetaTags, getIntFromString } from "../../look";
import ReactMarkdown from "../../look/components/ReactMarkdown";
import Modal_close from '../../assets/soul-modal-close.svg'
import rank1 from '../../assets/rank1.svg'
import rank2 from '../../assets/rank2.svg'
import rank3 from '../../assets/rank3.svg'
import rank4 from '../../assets/rank4.svg'
import rank5 from '../../assets/rank5.svg'
import '../css/soulHome.css'
import { personality_questions } from "config";
import { available_personality } from "config";
import SoulAiTipContainer from "modules/ai-tips/container/soul-ai-tip-container";
const SoulView = (props) => {
  const { data, history, dataSet, response_data, loading,permission,survey } = props;
  const [visible, setVisible] = React.useState(false);
  const [Completed, setCompleted] = React.useState(false)
  const [navigation_to_survey, SetNavigationToSurvey] = React.useState(false)
  const [show_navigation_confirmation, SetShowNavigationConfirmation] = React.useState(false)
  React.useEffect(() => {
    return () => {
      // Code to be executed when the component is unmounted
      document.removeEventListener('visibilitychange', () => { SetNavigationToSurvey(false) })
    };
  }, []);

  React.useEffect(() => {
    if (data) {
      if (data?.type === 'strengths' || data?.type === 'personalities') {
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === "visible" && navigation_to_survey && !show_navigation_confirmation) {
            SetShowNavigationConfirmation(true)
          } else {
            SetShowNavigationConfirmation(false)
          }

        })
      }
    }
  })

  const navigateToSurvey = (url) => {
    if (url) {
      if (data.type === 'values' || data.type === 'knowledge_and_skills') {
        history.push(url)
      } else if (data.type === 'strengths' || data.type === 'personalities') {
        setVisible(true)
        // window.open(url)
      }

    }
  }

  const navigateToPages = (url) => {
    SetShowNavigationConfirmation(false)
    if (url) {
      history.push(url)
    }
  }
  const GoToTest = () => {
    setVisible(false)
    SetNavigationToSurvey(true)
    if (data.type === 'strengths') {
      window.open('https://high5test.com/')
    }
    else {
      window.open('https://www.16personalities.com/free-personality-test')
    }
  }

  const ranks = {
    rank1,
    rank2,
    rank3,
    rank4,
    rank5
  }

  React.useEffect(() => {
    if (dataSet) {
      if (data.type === 'values' && dataSet?.ValuesPart1?.totalCount > 0) {
        setCompleted(true)
      }
      else if (data.type === 'strengths' && dataSet?.StrengthsPart1?.totalCount >= 5) {
        setCompleted(true)
      }
      else if (data.type === 'personalities' && dataSet?.PersonalityPart1?.totalCount >= 5) {
        setCompleted(true)
      }
      else if (data.type === 'knowledge_and_skills' && dataSet?.knowledgePart1?.totalCount > 0) {
        setCompleted(true)
      }
      else {
        setCompleted(false)
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet])

  const userPersonalityType = (personality_types) => {
    if (personality_types?.length) {
      let personality = ''

      personality_questions.forEach(q => {
        let ans = personality_types?.find(a => getIntFromString(a.question_id) === Number(q))
        if (ans) {
          personality = personality + (ans?.answer === "Intuitive" ? "N" : ans?.answer === "Observant" ? "S" : ans?.answer?.charAt(0))
        }
      })
      personality = personality?.slice(0, 4)
      if (available_personality?.includes(personality)) {
        return personality
      }
    }
  } 
  
  let tip_module ={
    "survey_values":"Value",
    "survey_strengths":"Strength",
    "survey_personalities":"Personality",
    "survey_knowledge_and_skills":"Knowledge_Skills"
  }
 
  return (
    <>
      <Spin spinning={loading}>
        {
          (permission && permission?.runpod_permission) && (
              <SoulAiTipContainer key={data.type} {...props} tipModule={tip_module[`survey_${data.type}`]}/>
          )
        }
        <MetaTags title={data?.type} description={data?.pageDescription} />

        <Row align="center" justify="center" style={{ position: 'relative', marginTop: '0em', width: '100%' }} className="main-container home-page main-page-card" >
          <Col xl={24} lg={21.5} md={23} sm={24} >
            {data.type === 'values' ? <Col span={24}>
              <Row justify="center" className="back-arrow-global" align="center" style={{ position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1em' }}>

                <h4 className="soul-item-title">Values</h4>

              </Row>
              <Row justify="center" align="center" className="soul-item-bg-container">
                <img
                  src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739428818/MApp/UI/SVGs/soul-value-bg_pb5lhm.svg"
                  alt="logo"
                  className="soul-item-bg forWeb"
                />
                <img
                  src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739440947/MApp/UI/SVGs/soul-values-bg-small_fyzbpy.svg"
                  alt="logo"
                  className="soul-item-bg forMobile"
                />
              </Row>
            </Col> : data.type === 'strengths' ?
              <Col span={24}>
                <Row justify="center" className="back-arrow-global" align="center" style={{ position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1em' }}>

                  <h4 className="soul-item-title">Strengths</h4>

                </Row>
                <Row justify="center" align="center" className="soul-item-bg-container">
                  <img
                    src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739426834/MApp/UI/SVGs/soul-strenght-bg_wvylzc.svg"
                    className="soul-item-bg forWeb"
                    alt="logo"
                  />
                  <img
                    src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739437400/MApp/UI/SVGs/soul-strength-small-bg_jc2okk.svg"
                    className="soul-item-bg forMobile"
                    alt="logo"
                    style={{ width: "95%" }}
                  />
                </Row>
              </Col> :
              data.type === 'personalities' ?
                <Col span={24}>
                  <Row justify="center" className="back-arrow-global" align="center" style={{ position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1em' }}>

                    <h4 className="soul-item-title">Personalities</h4>

                  </Row>
                  <Row justify="center" align="center" className="soul-item-bg-container">
                    <img
                      src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739437589/MApp/UI/SVGs/soul-personality-bg_ryf8lp.svg"
                      alt="logo"
                      className="soul-item-bg forWeb"
                    />
                    <img
                      src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739441583/MApp/UI/SVGs/soul-personality-small-bg_ayiyhn.svg"
                      alt="logo"
                      className="soul-item-bg forMobile"
                      style={{ width: "95%" }}
                    />
                  </Row>
                </Col> :
                data.type === 'knowledge_and_skills' ?
                  <Col span={24}>
                    <Row justify="center" className="back-arrow-global" align="center" style={{ position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '1em', width: '100%' }}>

                      <h4 className="soul-item-title">Knowledge & Skills</h4>

                    </Row>
                    <Row justify="center" align="center" className="soul-item-bg-container">
                      <img
                        src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739432126/MApp/UI/SVGs/soul-knowledge_and_skills-bg_rmfm9x.svg"
                        className="soul-item-bg forWeb"
                        alt="logo"
                      />
                      <img
                        src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1739439703/MApp/UI/SVGs/soul-knowledge-small-bg_tk4vv9.svg"
                        className="soul-item-bg forMobile"
                        alt="logo"
                        style={{ width: "95%" }}
                      />
                    </Row>
                  </Col> : null}
            <Col>
              <h3
                className="soul-detail-content-text"
                style={{ lineHeight: "1.7", textAlign: "justify" }}
              >
                {data?.content}
              </h3>



              <div style={{ padding: '0px 30px', width: "100%" }}>
                {
                  Completed && survey ?
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                      {
                        response_data?.length ?
                          <div className="value-section">
                            <ReactMarkdown >{data?.value_label}</ReactMarkdown>
                            {data.type === 'personalities' && <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                              <h4 style={{ color: "#1F1F1F", fontFamily: "Poppins", fontSize: "1.2em", margin: "0px", fontWeight: "500" }}>{userPersonalityType(response_data)}-{response_data[4]?.answer?.charAt(0)}</h4>
                            </div>}
                            {
                              response_data?.map((d, i) => (
                                <div className="value-list">
                                  <img src={ranks[`rank${i + 1}`]} alt="" srcset="" style={{ marginRight: "10px" }} />
                                  <h4>{d.answer}</h4>
                                </div>
                              ))
                            }
                          </div>
                          : ''
                      }
                      {data.type !== 'knowledge_and_skills' && <h4 className="step-2-go-test forMobile">*Want to Retake the Test? Click <span style={{ cursor: "pointer", textDecoration: "underline", color: "#009AF1" }} onClick={() => navigateToSurvey(data?.button1?.navigateTo)}>here</span>!</h4>}
                      <Row className="button-row" justify="center" align="center" style={{ flexWrap: "wrap", gap: "30px", marginTop: "20px", width: "100%" }}>
                        {data?.button3 && data?.button3?.show && (
                          <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>

                            <NavigateButton onClick={() => navigateToPages(data?.button3?.navigateTo)} className="take-test-btn" style={{ margin: "0.5em" }}>{data?.button3.content}</NavigateButton>

                          </div>
                        )}


                      </Row>
                      {data.type !== 'knowledge_and_skills' && <h4 className="step-2-go-test forWeb">*Want to Retake the Test? Click <span style={{ cursor: "pointer", textDecoration: "underline", color: "#009AF1" }} onClick={() => navigateToSurvey(data?.button1?.navigateTo)}>here</span>!</h4>}
                    </div>
                    :
                    (survey && <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                      {data.type !== 'knowledge_and_skills' && data.type !== 'personalities' && <h4 className="step-2-go-test forMobile">*If you are aware of your top 5 {data?.type}, enter them <span style={{ cursor: "pointer", textDecoration: "underline", color: "#009AF1" }} onClick={() => navigateToPages(data?.button3?.navigateTo)}>here</span></h4>}
                      {data.type !== 'knowledge_and_skills' && data.type === 'personalities' && <h4 className="step-2-go-test forMobile">*If you are aware of your personality orientations, enter them  <span style={{ cursor: "pointer", textDecoration: "underline", color: "#009AF1" }} onClick={() => navigateToPages(data?.button3?.navigateTo)}>here</span></h4>}
                      <Row className="button-row" justify="center" align="center" style={{ flexWrap: "nowrap", gap: "30px", width: "100%", marginTop: "20px" }}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>

                          {data?.button1 && data?.button1?.show && (
                            <NavigateButton onClick={() => navigateToSurvey(data?.button1?.navigateTo)} className="take-test-btn">{data?.button1.content}</NavigateButton>
                          )}
                        </div>

                      </Row>
                      {data.type !== 'knowledge_and_skills' && data.type !== 'personalities' && <h4 className="step-2-go-test forWeb">*If you are aware of your top 5 {data?.type}, enter them <span style={{ cursor: "pointer", textDecoration: "underline", color: "#009AF1" }} onClick={() => navigateToPages(data?.button3?.navigateTo)}>here</span></h4>}
                      {data.type !== 'knowledge_and_skills' && data.type === 'personalities' && <h4 className="step-2-go-test forWeb">*If you are aware of your personality orientations, enter them  <span style={{ cursor: "pointer", textDecoration: "underline", color: "#009AF1" }} onClick={() => navigateToPages(data?.button3?.navigateTo)}>here</span></h4>}
                    </div>)
                }

              </div>
            </Col>
          </Col>
        </Row>
        <Modal
          footer={null}
       /* centered */ visible={visible}
          width='90%'
          style={{ maxWidth: "350px" }}
          destroyOnClose={true}
          centered={true}
          bodyStyle={{ minHeight: '30vh' }}
          closable={false}
        >
          <Col align="center" className="note-modal-container" style={{ display: 'flex', justifyContent: 'center center', flexDirection: 'column', position: 'relative' }}>
            <img src={Modal_close} alt="" onClick={() => setVisible(false)} style={{ position: "absolute", top: "15px", right: "0px", cursor: "pointer", width: "12px" }} />
            <h4 className="note-head">*Note</h4>
            <Col>
              <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "start", gap: "10px", flexWrap: "nowrap", marginTop: "10px" }}>
                <h4 className="note-number">01</h4>
                <h4 className="note-content" style={{ textAlign: "left" }}>Take a test using the third party tool.</h4>
              </div>
              <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "start", gap: "10px", flexWrap: "nowrap", marginTop: "10px" }}>
                <h4 className="note-number">02</h4>
                <h4 className="note-content" style={{ textAlign: "left" }}>Note down your results on completing the test as it will not be saved automatically. </h4>
              </div>
              <div style={{ display: 'flex', flexDirection: "row", justifyContent: "start", alignItems: "start", gap: "10px", flexWrap: "nowrap", marginTop: "10px" }}>
                <h4 className="note-number">03</h4>
                <h4 className="note-content" style={{ textAlign: "left", color: "#4CCB1F" }}>Come back to MApp and add your results.</h4>
              </div>
            </Col>
            <Col style={{ paddingTop: "20px" }}>
              <Button className="modal-button-for-soul-survey" type="primary" onClick={GoToTest}>Go To Test </Button>
            </Col>
            <p className="soul-modal-bottom-text">*You will be redirected to an external site</p>
          </Col>

        </Modal>


        {/* navigation confirmation  */}
        <Modal
          footer={null}
          visible={show_navigation_confirmation}
          width='90%'
          style={{ maxWidth: "450px" }}
          destroyOnClose={true}
          centered={true}
          closable={false}
        >
          {
            data?.type === "strengths" && (
              <h4 className="note-content" style={{ textAlign: "center", fontSize: "18px" }}>Have you identified your strengths?</h4>
            )
          }
          {
            data?.type === "personalities" && (
              <h4 className="note-content" style={{ textAlign: "center", fontSize: "18px" }}>Have you identified your personality type?</h4>
            )
          }
            <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap",width:"100%",justifyContent:"center",alignItems:"center"}}>
              <Button className="modal-button-for-soul-survey" style={{margin:"5px",width:"80px"}} type="primary" onClick={()=>SetShowNavigationConfirmation(false)}>No</Button>
              <Button className="modal-button-for-soul-survey" style={{margin:"5px",width:"80px"}} type="primary" onClick={()=>navigateToPages(data?.button3?.navigateTo)}>Yes</Button>
            </div>

        </Modal>
      </Spin>
    </>
  );
};

export default SoulView;


const NavigateButton = styled(Button)`

}
`
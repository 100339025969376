import { compose } from "modules/core"
import { withApollo } from '@apollo/client/react/hoc';
import React from "react";
import CasperView from "../component/casper-view";
import { withSendMessage, withUpdateMessage } from "./casper-operations";
import { ALL_MESSAGES } from "../query/casper-query.gql";
import { CASPER_SUBSCRIPTION } from "../query/casper-subscription.gql";

const CasperContainer = (props) => {
    const { client } = props;
    const [allMessages, setAllMessages] = React.useState({})
    const [generatingResp, setGeneratingResp] = React.useState(false)
    const [failToSendMsg, setFailToSendMsg] = React.useState(false)
    let casper_subscription_ref = React.useRef();

    React.useEffect(() => {
        return () => {
            if (casper_subscription_ref.current) {
                casper_subscription_ref.current.unsubscribe();
            }
        }
    }, [])

    const getAllMessages = async () => {
        try {
            const { data } = await client.query({
                query: ALL_MESSAGES,
                fetchPolicy: 'network-only'
            })
            if (data.allMessages) {
                setAllMessages(data.allMessages)
            }
        } catch (error) { }
    }

    const casperSubscription = () => {
        casper_subscription_ref.current = client.subscribe({
            query: CASPER_SUBSCRIPTION,
        }).subscribe({
            next(result) {
                let sub_data = result.data.casperSubscription?.casper
                switch (result.data.casperSubscription.mutation) {
                    case 'CREATE':
                        setGeneratingResp(false)
                        if (sub_data?.hasOwnProperty("id") || sub_data !== null) {
                            setAllMessages(prev_value => {
                                const index = prev_value.edges.findIndex(ele => ele?.node.hasOwnProperty('id') && ele.node.id === "-1" && ele.node.userMsg === sub_data.userMsg);
                                if (index !== -1) {
                                    prev_value.edges[index].node = sub_data
                                }
                                return ({ ...prev_value })
                            })
                        } else {
                            setFailToSendMsg(true)
                        }
                        break;
                    case "UPDATE":
                        setAllMessages(prev_value => {
                            const index = prev_value.edges.findIndex(ele => ele?.node.hasOwnProperty('id') && ele.node.id === sub_data.id);
                            if (index !== -1) {
                                prev_value.edges[index].node = sub_data
                            }
                            return ({ ...prev_value })
                        })
                        break;
                    default:
                        console.error("Subscription not handled for ", result.data.casperSubscription.mutation, "mutation")
                }
            }
        });
    }

    React.useEffect(() => {
        getAllMessages()
        if (!casper_subscription_ref.current) {
            try {
                casperSubscription();
            } catch (error) {
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <CasperView
            allMessages={allMessages}
            setAllMessages={setAllMessages}
            generatingResp={generatingResp}
            setGeneratingResp={setGeneratingResp}
            failToSendMsg={failToSendMsg}
            setFailToSendMsg={setFailToSendMsg}
            {...props}
        />
    )
}

export default compose(withApollo, withSendMessage, withUpdateMessage)(CasperContainer)

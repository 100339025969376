import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../core';
import USER_ROUTE from '../../user/route';

import QuizView from '../components/SurveyQuizResponseView';
import {withResponseByResponseIdOptimized } from './SurveyOperations';

const SurveyQuizResponse = props => {
  const { error, history} = props;
  const submitLoading = false;
  if (error) {
    if (error.message === 'GraphQL error: Permission Denied.') {
      // setRedirect(true);
      setTimeout(3000, history.push(USER_ROUTE.login));
    }
  }

  return (
    <Spin spinning={submitLoading} size="large">
      <QuizView {...props} />
    </Spin>
  );
};

export default compose(withResponseByResponseIdOptimized)(SurveyQuizResponse);

// @ts-nocheck
import { Button, Checkbox, Col, Dropdown, Form, Input, Menu, message, Modal, Select, Spin } from "antd";
import { displayUtcToUserTimezone, getIntFromString, MetaTags } from "modules/look";
import React from "react";
// import { useHistory } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import close_icon from '../../assets/dashboard-kudos-close-icon.svg';
import drop_black from '../../assets/drop-arrow-black.svg';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg';
import left_arrow from '../../assets/left-active-carosul.svg';
import right_arrow from '../../assets/right-active-carosul.svg';
import search_icon from '../../assets/search-icon.svg';
import '../../home/css/userDashboard.css';
import "../../kudos/kudos.css";
import '../user-kudos.css';
const IssueNewBadgePopup = ( props ) => {

    const {  onSearch, badgeList, loading, createBadgeAward, selectedItem, me, employeeList, employeeLoading, getOkrList, getMeetingList, getFeedbackList, selectedModuleList, okrList, permission, badge_family_list, onFilter, getbadgeFailyList, getBageFamilyById,  show_popup, onCancel } = props
    const [ search_toggle, setSearchToggle ] = React.useState( false )

    const moduleList = [
        { value: "1:1", label: "1 On 1" },
        { value: "Okr", label: "OKR" },
        { value: "Feedback", label: "Feedback" },
    ]
    const cateList = [
        { value: "team", label: "Team" },
        { value: "vertical", label: "Vertical" },
        { value: "organization", label: "Organization" },
        { value: "none", label: "None" },
    ]
    // const history = useHistory()
    const [ selectedBadge, setSelectedBadge ] = React.useState()
    const [ detail, setdetail ] = React.useState();
    // eslint-disable-next-line no-unused-vars
    const [ Employees, setEmployees ] = React.useState( [] );
    const [ selectedEmployee, setSelectedEmployee ] = React.useState()
    const [ selectedNotify, setSelectedNotify ] = React.useState()
    const [ selectedModule, setSelectedModule ] = React.useState()
    const [ allEmployeeList, setAllEmployeeList ] = React.useState( [] )
    const [ cat_list, setCatList ] = React.useState( cateList )
    const [ moduleSelectionList, setModuleSelectionList ] = React.useState()
    const [ module_list, setModuleList ] = React.useState( moduleList )
    const [ selectedModuleValue, setSelectedModuleValue ] = React.useState()
    const [ relatedtoName, setRelatedtoName ] = React.useState()
    const [ filter_value, setFilterValue ] = React.useState()
    const Option = Select.Option;
    const [ form ] = Form.useForm();


    React.useEffect( () => {
        if ( employeeList?.length ) {
            let list = employeeList?.filter( ( { node } ) => node?.user?.id !== me?.employee?.user?.id )
            setAllEmployeeList( list )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ employeeList ] )
    React.useEffect( () => {
        if ( selectedModuleList?.length ) {
            let list

            if ( selectedModule === '1:1' ) {
                list = selectedModuleList?.map( ( item ) => {
                    return {
                        id: item?.id,
                        title: `${item?.title}-${displayUtcToUserTimezone( item?.createdAt, 'DD MMM YY' )}`
                    }
                } )
            }
            else if ( selectedModule === 'Feedback' ) {
                list = selectedModuleList?.map( ( item ) => {
                    return {
                        id: item?.id,
                        title: `${item?.feedbackType} form ${<span className="text-transform-capitalize">{item?.feedbackTo?.user?.firstName}</span>} - ${displayUtcToUserTimezone( item?.createdAt, 'DD MMM YY' )}`
                    }
                } )
            }
            setModuleSelectionList( list )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ selectedModuleList ] )
    const Relatedmenu = () => (
        <Menu style={{ maxHeight: '200px', overflowY: 'auto', width: "100%" }}>
            {
                okrList?.length ?
                    okrList.map( ( data ) => (
                        <Menu.Item onClick={() => handleRelatedSelection( data )} style={{ maxWidth: "300px", width: "100%" }}>
                            {/* <Row align="middle" style={{flexWrap:"nowrap"}}> */}
                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: 'center', flexWrap: "nowrap" }}>
                                {
                                  (  data?.okrType === "OBJECTIVE" &&
                                    <div style={{ border: '1px solid black', opacity: '0.4', paddingLeft: '5px', paddingRight: '5px', marginRight: '5px', fontSize: '10px', borderColor: '#4CCB1F', color: '#4CCB1F' }}>O</div>
                                   ) ||
                                    (data?.okrType === "KEY_RESULT" &&
                                    <div style={{ border: '1px solid black', opacity: '0.4', paddingLeft: '3px', borderColor: '#009AF1', paddingRight: '3px', marginRight: '5px', fontSize: '10px', color: '#009AF1' }}>KR</div>
                                     ) ||
                                    (data?.okrType === "MILESTONE" &&
                                    <div style={{ border: '1px solid black', opacity: '0.4', paddingLeft: '5px', borderColor: 'rgb(250, 197, 60)', paddingRight: '5px', marginRight: '5px', fontSize: '10px', color: 'rgb(250, 197, 60)' }}>M</div>
                                )}
                                <p className="kudos-user-home-select-option" style={{ margin: "0px", whiteSpace: "nowrap", textOverflow: 'ellipsis', overflow: "hidden" }}>{data?.title}</p>
                            </div>
                            {/* </Row> */}
                        </Menu.Item>
                    ) )
                    :
                    employeeLoading ?
                        <Menu.Item  >
                            <Spin spinning={true}></Spin>
                        </Menu.Item> :
                        <Menu.Item  >
                            no data found
                        </Menu.Item>
            }
        </Menu>
    );

    const handleRelatedSelection = ( value ) => {
        if ( value ) {
            setRelatedtoName( value?.title )
            setSelectedModuleValue( value?.id )
        }
    }
    const handleBadgeSearch = ( event ) => {
        if ( event?.target?.value ) {
            onSearch( { title_Icontains: event?.target?.value, behaviouralDescription_Icontains: event?.target?.value, badgeFamily: filter_value } )
        }
        else {
            onSearch( { title_Icontains: "", behaviouralDescription_Icontains: "", badgeFamily: filter_value } )
        }
    }
    React.useEffect( () => {

        if ( selectedItem ) {
            let emp = selectedItem?.contributors.filter( ( item ) => String(item?.id) !==String(me?.employee?.user?.id) )
            setEmployees( emp )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ selectedItem ] )

    const badgeSelectHandler = ( node ) => {
        setSelectedBadge( node )
    }
    const filter_list = badge_family_list &&
        badge_family_list?.map( ( { node }, key ) => (
            <Option
                key={key}
                value={node?.id}
            >
                {node?.title}
            </Option>
        ) )
    const category_option = cat_list &&
        cat_list.map( ( choice, key ) => (
            <Option style={{ border: "1px solid #000000", borderRadius: "8px" }} className="kudos-user-home-select-option"
                key={key}
                value={choice?.value}
            >
                {choice?.label}
            </Option>
        ) );
    // eslint-disable-next-line no-unused-vars
    const module_option = module_list &&
        module_list.map( ( choice, key ) => (
            <Option
                style={{ border: "1px solid #000000", borderRadius: "8px" }}
                className="kudos-user-home-select-option"
                key={key}
                value={choice?.value}
            >
                {choice?.label}
            </Option>
        ) )

    const module_selection_option = moduleSelectionList &&
        moduleSelectionList.map( ( item ) => (
            <Option
                className="kudos-user-home-select-option"
                key={item?.id}
                value={item?.id}
            >
                {item?.title}
            </Option>
        ) )

    // eslint-disable-next-line no-unused-vars
    const choices = employeeList &&
        employeeList?.map( ( { node } ) => (
            <Option
                className="kudos-user-home-select-option"
                key={node?.user?.id}
                value={node?.user?.id}
            >
                {node?.user?.firstName} {node?.user?.lastName}
            </Option>
        ) )
    const handleSubmit = async ( value ) => {
        if ( detail && selectedEmployee && selectedNotify ) {
            try {
                let badgeAwardData = {
                    recipientId: getIntFromString( selectedEmployee?.node?.id ),
                    badgeId: getIntFromString( selectedBadge?.id ),
                    relatedToId: selectedModuleValue,
                    notify: selectedNotify,
                    evidence: detail
                }
                //   setloading(true)
                const res = await createBadgeAward( { badgeAwardData } )
                if ( res ) {
                    message.success("Badge Award Created Successfully")
                    onCancel()
                    // history.push( '/kudos/awards/badge?issued' )
                }
                //   setloading(false)
            } catch ( error ) {
                //   setloading(false)
            }


        }
    }
    const searchEmployes = ( value ) => {
        if ( value ) {
            let valueList = allEmployeeList?.filter( ( { node } ) => node?.user?.firstName.toLowerCase()?.includes( value?.toLowerCase() ) )
            setAllEmployeeList( valueList )
        }
        else {
            setAllEmployeeList( employeeList )
        }
    }
    // eslint-disable-next-line no-unused-vars
    const searchModule = ( value ) => {
        if ( value ) {
            let valueList = module_list?.filter( item => item?.label.toLowerCase()?.includes( value.toLowerCase() ) )
            setModuleList( valueList )
        }
        else {
            setModuleList( moduleList )
        }
    }

    const searchNotify = ( value ) => {
        if ( value ) {
            let valueList = cat_list?.filter( item => item?.label.toLowerCase()?.includes( value.toLowerCase() ) )
            setCatList( valueList )
        }
        else {
            setCatList( cateList )
        }
    }
    const handleSelectEmployee = ( e ) => {
        if ( e ) {
            let data = allEmployeeList.find( ( { node } ) => node?.id === e )
            setSelectedEmployee( data )
            setSelectedModule()
            setSelectedModuleValue()
            setRelatedtoName()
            setSelectedNotify()
            setdetail()
        }

    }
    const handleSelectNotify = ( e ) => {
        setSelectedNotify( e )
    }
    const handleSelectModuleValue = ( e ) => {
        setSelectedModuleValue( e )
    }
    const handleFamilyFilter = ( e ) => {
        setFilterValue( e )
        setSelectedBadge( null )
        onFilter( { badgeFamily: e } )
        getBageFamilyById( { id: e } )
        filterSearch( null )
    }
    // eslint-disable-next-line no-unused-vars
    const handleSelectModule = ( e ) => {
        setSelectedModule( e )
        if ( e === '1:1' ) {
            getMeetingList( { owner: selectedEmployee?.node?.id, member_User_In: [ getIntFromString( selectedEmployee?.node?.id ) ], title_Icontains: "" } )
        }
        else if ( e === 'Okr' ) {
            getOkrList( { employee: selectedEmployee?.node?.id, okr_Goalstatement_Icontains: "" } )
        }
        else if ( e === 'Feedback' ) {
            getFeedbackList( { feedbackTo_User_FirstName_Icontains: "", $feedbackFrom_User_FirstName_Icontains: "", feedbackTo: selectedEmployee?.node?.id, feedbackFrom: selectedEmployee?.node?.id } )
        }
    }
    const searchSelectedModule = ( value ) => {
        if ( selectedModule === '1:1' ) {
            getMeetingList( { owner: selectedEmployee?.node?.id, member_User_In: [ getIntFromString( selectedEmployee ) ], title_Icontains: "" } )
        }
        else if ( selectedModule === 'Okr' ) {
            getOkrList( { employee: selectedEmployee?.node?.id, okr_Goalstatement_Icontains: value } )
        }
        else if ( selectedModule === 'Feedback' ) {
            getFeedbackList( { feedbackTo_User_FirstName_Icontains: value, $feedbackFrom_User_FirstName_Icontains: value, feedbackTo: selectedEmployee?.node?.id, feedbackFrom: selectedEmployee?.node?.id } )
        }
    }
    const onCheckChangeOneOnOne = ( checkedValues ) => {
        if ( checkedValues?.target?.checked ) {
            setSelectedModule( "1:1" )
            getMeetingList( { owner: selectedEmployee?.node?.id, member_User_In: [ getIntFromString( selectedEmployee?.node?.id ) ], title_Icontains: "" } )
        }
        else {
            setSelectedModule( "" )
        }
    }
    const onCheckChangeOkr = ( checkedValues ) => {
        if ( checkedValues?.target?.checked ) {
            setSelectedModule( "Okr" )
            getOkrList( { employee: selectedEmployee?.node?.id, okr_Goalstatement_Icontains: "" } )
        }
        else {
            setSelectedModule( "" )

        }
    }
    const onCheckChangeFeedback = ( checkedValues ) => {
        if ( checkedValues?.target?.checked ) {
            setSelectedModule( "Feedback" )
            getFeedbackList( { feedbackTo_User_FirstName_Icontains: "", $feedbackFrom_User_FirstName_Icontains: "", feedbackTo: selectedEmployee?.node?.id, feedbackFrom: selectedEmployee?.node?.id } )
        }
        else {
            setSelectedModule( "" )
        }
    }
    const filterSearch = ( value ) => {
        if ( value ) {
            setSelectedBadge( null )
            getbadgeFailyList( { title_Icontains: value } )
        }
        else {
            getbadgeFailyList( { title_Icontains: "" } )
        }

    }

    // eslint-disable-next-line no-unused-vars
    const TooltipData = ( node ) => {
        return (
            <div style={{ width: "100%", maxWidth: "400px", minWidth: "180px" }}>
                <h4 style={{ fontSize: '1.2em', fontWeight: "500", color: "rgba(0, 0, 0, 0.71)", margin: "0" }}>{node?.title}</h4>
                <p style={{ color: "#8C8CA2", fontSize: "1em", margin: "0", textAlign: 'justify' }}>{node?.behaviouralDescription}</p>
            </div>
        )
    }
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        arrows: true,
        nextArrow: <img src={right_arrow} alt='' />,
        prevArrow: <img alt='' src={left_arrow} />,
        slidesToShow: 3,
        // slidesToScroll: 4,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 310,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    // eslint-disable-next-line no-unused-vars
    const onRadioChange = ( e ) => {
        setSelectedModule( e.target.value )
        if ( e?.target?.value === '1:1' ) {
            getMeetingList( { owner: selectedEmployee?.node?.id, member_User_In: [ getIntFromString( selectedEmployee?.node?.id ) ], title_Icontains: "" } )
        }
        else if ( e?.target?.value === 'Okr' ) {
            getOkrList( { employee: selectedEmployee?.node?.id, okr_Goalstatement_Icontains: "" } )
        }
        else if ( e?.target?.value === 'Feedback' ) {
            getFeedbackList( { feedbackTo_User_FirstName_Icontains: "", $feedbackFrom_User_FirstName_Icontains: "", feedbackTo: selectedEmployee?.node?.id, feedbackFrom: selectedEmployee?.node?.id } )
        }
    }
    return (
        <>
            <MetaTags
                title="Add Badge"
                description="This is Add New Badge page."
            />
            <Modal visible={show_popup}
                footer={null}
                centered
                closable={false}
                width={'90%'}
                height={'90%'}
                style={{ maxWidth: "480px" }}>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: "1em", cursor: "pointer", padding: '.5em 1em' }}>
                    <h4 className="user-board-section-heading">Give Kudos</h4>
                    <img src={close_icon} alt="" style={{ cursor: "pointer" }} onClick={() => onCancel()} />
                </div>

                <div className="user-badge-root-class user-badge-popup-container" style={{ display: "flex", flexDirection: "column", gap: "1em", width: "100%", marginTop: "30px", maxHeight: "70vh", overflow: "auto", padding: "0em 1em" }}>
                    <Form
                        form={form}
                        name={"competency"}
                        style={{ width: "100%" }}
                        onFinish={() => { handleSubmit() }}
                        className="formLabel"
                    >
                        <Col className="kudos-form-group">
                            {/* <label style={{ fontSize: "18px", textAlign: "right",color:"#FF0404",fontWeight:"700" }}>*</label> */}
                            <Select
                                className={selectedEmployee ? "user-badge-select-active" : "user-badge-select"}
                                showSearch
                                name={'employee'}
                                id={'employee'}
                                placeholder="Search Employee"
                                suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                                style={{ width: '100%', maxWidth: "100%", backgroundColor: "#fff" }}
                                {...props}
                                onSearch={searchEmployes}
                                onSelect={handleSelectEmployee}
                                filterOption={( input, option ) => option}
                            >
                                {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}
                                {allEmployeeList?.map( ( { node } ) => (
                                    <Option
                                        className="kudos-user-home-select-option"
                                        key={node?.id}
                                        value={node?.id}
                                    >
                                        <span className="text-transform-capitalize">{node?.user?.firstName}</span> <span className="text-transform-capitalize">{node?.user?.lastName}</span>
                                    </Option>
                                ) )}
                            </Select>
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "10px", flexWrap: "wrap", width: "100%", paddingTop: "20px" }}>
                            {
                                permission?.okr_permission && <Checkbox className="badge-check" disabled={!selectedEmployee} onChange={onCheckChangeOkr} checked={selectedModule === "Okr" ? true : false}>OKR</Checkbox>
                            }
                            {
                                permission?.feedback_permission && <Checkbox className="badge-check" disabled={!selectedEmployee} onChange={onCheckChangeFeedback} checked={selectedModule === "Feedback" ? true : false}>Feedback</Checkbox>
                            }
                            {
                                permission?.one_on_one_permission && <Checkbox className="badge-check" disabled={!selectedEmployee} onChange={onCheckChangeOneOnOne} checked={selectedModule === "1:1" ? true : false}>1 : 1</Checkbox>
                            }

                        </Col>
                        {selectedModule !== "" && selectedModule !== undefined && <Col className="kudos-form-group" style={{ paddingTop: "20px" }}>
                            {/* <label style={{ fontSize: "18px" }}> Module </label> */}
                            {selectedModule !== "Okr" ? <Select
                                showSearch
                                className={selectedModuleValue ? "user-badge-select-active" : "user-badge-select"}
                                name={'module'}
                                id={'module'}
                                value={selectedModuleValue}
                                placeholder={`Select ${selectedModule}`}
                                suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                                style={{ width: '100%', maxWidth: "100%", backgroundColor: "#fff" }}
                                {...props}
                                onSelect={handleSelectModuleValue}
                                onSearch={searchSelectedModule}
                                filterOption={( input, option ) => option}
                            >
                                {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}

                                {module_selection_option}

                            </Select> :
                                <Dropdown overlay={Relatedmenu} style={{ width: "100%" }}
                                >
                                    <div
                                        className={selectedModuleValue ? "user-badge-drop-active" : "user-badge-drop"} style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0px 10px", maxWidth: "100%" }}>
                                        <p className="kudos-okr-selection-drop" style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left', whiteSpace: "nowrap", margin: "0px" }}>{relatedtoName ? relatedtoName : <span style={{ color: "#D9D9D9" }}>{`Select ${selectedModule}`}</span>}</p>
                                        <img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />
                                    </div>
                                </Dropdown>}
                        </Col>}
                        <div style={{ display: "flex", flexDirection: "column", border: "1px solid #8F8D8D", borderRadius: '8px', width: "100%", padding: "2px 0px", marginTop: "1em" }}>
                            <div className="dashboard-user-badge-award-popup-input-div" style={{ display: 'flex', flexDirection: "row", borderBottom: "1px solid #8F8D8D", width: "100%" }}>
                                {!search_toggle || filter_value ? <Select
                                    showSearch
                                    className="dasboard-filter-select-user-select"
                                    name={'filter'}
                                    id={'filter'}
                                    placeholder={`Select`}
                                    suffixIcon={<img src={drop_black} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} onClick={() => setSearchToggle( false )} />}
                                    style={{ width: filter_value && search_toggle ? '40%' : '100%', maxWidth: "100%", textOverflow: 'ellipsis', whiteSpace: "nowrap" }}
                                    {...props}
                                    onSelect={handleFamilyFilter}
                                    onSearch={filterSearch}
                                    allowClear
                                    onClear={() => { onFilter()}}
                                    onMouseEnter={() => setSearchToggle( false )}
                                    filterOption={( input, option ) => option}
                                >
                                    {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}

                                    {filter_list}

                                </Select> : <div style={{ borderRadius: "8px 0px 0px 8px", border: "1px solid #8F8D8D", height: "40px", padding: "8px 10px" }}>
                                    <img src={drop_black} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} onClick={() => setSearchToggle( false )} />
                                </div>}
                                <div className="dashboard-search-input-user-kudos" style={{ display: 'flex', flexDirection: 'row' }}>
                                    {search_toggle && <Input placeholder="Search kudos name here" autoComplete='off' style={{ width: "100%", border: "none" }} onChange={( e ) => handleBadgeSearch( e )}></Input>}
                                    <img src={search_icon} alt="" style={{ cursor: 'pointer', transform: " rotate(-270deg)", height: '20px', marginTop: "10px", marginRight: '7px', marginLeft: '7px' }} onClick={() => setSearchToggle( true )} />
                                </div>
                            </div>
                            <div className="slider-container dasboard-badge-award-carosul-container">
                                <Spin spinning={loading}>
                                    {loading && <div style={{width:"100%",minWidth:"400px"}}></div>}
                                    {badgeList?.length > 0 &&!selectedBadge && <Slider {...settings} style={{ width: "100%", maxWidth: "400px" }}>
                                        {badgeList?.length && badgeList?.map( ( { node }, index ) => (
                                            <div key={index} className={`dashboard-user-award-badge-image-selected-container ${node?.id === selectedBadge?.id ? "dashboard-user-award-badge-image-selected-container-selected" : ""}`}
                                                onClick={() => badgeSelectHandler( node )} >
                                                <div style={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: "center", flexDirection: "column" }}>
                                                    <img className="dashboard-user-award-badge-image-selected" src={node?.image} alt={`Slide ${index}`} />
                                                </div>
                                                <h4 className="dashboard-user-award-badge-image-selected-title">{node?.title}</h4>
                                                <div key={index} className="dashboard-user-award-badge-image-selected-detail dashboard-show-kudos-detail-card" style={{ textOverflow: "ellipsis", textAlign: "center" }}>
                                                    {node?.behaviouralDescription}
                                                </div>
                                            </div>
                                        ) )}
                                    </Slider>}
                                    {selectedBadge && <div className="dashboard-selected-kudos-card" style={{ position: "relative" }}>
                                        <img src={close_icon} alt="" style={{ cursor: "pointer", position: "absolute", right: "10px", top: '10px' }} onClick={() => badgeSelectHandler()} />
                                        <div>
                                            <img style={{ height: "em", width: "6em" }} src={selectedBadge?.image} alt="" />
                                        </div>
                                        <div style={{ borderleft: "1px soild #AABAC633", marginLeft: ".5em", paddingLeft: "1em" }}>
                                            <h4 style={{ fontFamily: "Poppins", fontSize: "1em", fontWeight: "600", color: "#4CCB1F" }}>{selectedBadge?.title}</h4>
                                            <p className="dashboard-details-elipsis" style={{ fontFamily: "Poppins", fontSize: ".85em", fontWeight: "400", color: "#303030" }}>{selectedBadge?.behaviouralDescription}</p>
                                        </div>
                                    </div>}
                                    {badgeList?.length === 0 && loading === false &&
                                        <div style={{minWidth:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                            <h4 className="recived-award-image-title" style={{ margin:'auto',justifyContent:'center',textAlign:"center"}}>No Kudos Found</h4>
                                        </div>
                                    }
                                </Spin>
                            </div>
                        </div>

                        <Col className="kudos-form-group no-bg-drop" style={{ paddingTop: "20px" }}>
                            {/* <label style={{ fontSize: "18px",color:"#FF0404",fontWeight:"700" }}> * </label> */}
                            <Select
                                className={selectedNotify ? "user-badge-select-active" : "user-badge-select"}
                                name={'notify_category'}
                                id={'notify_category'}
                                placeholder="Select Whom To Notify"
                                suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                                style={{ width: '100%', backgroundColor: "#fff" }}
                                value={selectedNotify}
                                {...props}
                                onSelect={handleSelectNotify}
                                onSearch={searchNotify}
                            >
                                {category_option}
                            </Select>
                        </Col>
                        {/* </Row> */}

                        <Col style={{ marginTop: '20px' }} className="kudos-form-group" >
                            {/* <label style={{ fontSize: "18px" }}>Rational</label> */}
                            <Col>
                                <textarea style={{ width: "100%", height: "200px", backgroundColor: "#fff", border: detail?.length ? "1px solid #9CD600" : "1px solid #000000", }} placeholder="Rationale" name="description" id="" className="kudos-description" value={detail?.length ? detail : ''} onChange={( e ) => { setdetail( e?.target?.value ) }}></textarea>
                            </Col>
                        </Col>
                    </Form>
                    <div style={{ width: "100%", marginBottom: "15px", display: 'flex', flexDirection: "row", justifyContent: 'flex-end', alignItems: "center", gap: '1em' }}>
                        <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', color: "#8998A2", border: '1px solid #8998A2', width: "100px",background:"#fff" }} onClick={() => onCancel()} >
                            Cancel
                        </Button>
                        <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', width: "100px" }} onClick={() => handleSubmit()} disabled={!selectedBadge || !selectedEmployee || !selectedNotify || !detail}>
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default IssueNewBadgePopup
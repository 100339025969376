import React from 'react';
import { Form, Input, Popover } from 'antd';

const TextInputField = props => {
  const { onChange, disabled,onBlur,margin,placeholder,defaultValue, show_pop_over, setPopOver ,...rest } = props;

  const FormItem = Form.Item;
  
  const valueChange=(value)=>{
    if(value?.target?.value?.length>150){
      setPopOver && setPopOver(true)
    }else{
      setPopOver && setPopOver(false)
    }
    if(onChange){
      onChange(value)
    }
  }

  return (
    <Popover
    // content={<a onClick={hide}>Close</a>}
    visible={show_pop_over}
    title="Goal statement should be less than 150 characters."
  >
      <FormItem  {...rest}>
     
      <Input className="okr-input" autoComplete='off' defaultValue={defaultValue} placeholder={placeholder||''} onChange={valueChange} onBlur={valueChange} disabled={disabled} />
    
      </FormItem>
      </Popover>
  );
};

export default TextInputField;


import React from 'react';
import { Col, Table } from 'antd';
import { sortableContainer, sortableElement} from 'react-sortable-hoc';
import { PAGINATION_LIMIT } from '../../../config';
import arrayMove from 'array-move';
import '../css/tableStyle.css'

import { getIntFromString } from './functions';

const RenderTable = props => {
  const {
    tableData,
    loading,
    rowKey = 'id',
    onPaginationChange,
    columns,
    selectedRowKeys,
    handleChange,
    components,
    onSort,
    currentPage,
    currentSize,
    disable_row_selection
  } = props;
  const [dataSource, setDataSource] = React.useState([]);

  React.useEffect(() => {
    if (components) {
      const edges = [];
      tableData &&
        tableData.edges.length > 0 &&
        // eslint-disable-next-line array-callback-return
        tableData.edges.map(({ node }, index) => {
          edges.push({ ...node, index: index + 1 });
        });
      setDataSource(edges);
    } else {
      setDataSource(tableData);
    }
  }, [components, tableData]);

  const rowSelection = {
    // type: 'radio',
    selectedRowKeys,
    onChange: handleChange
  };
  const SortableItem = sortableElement(props => <tr {...props} />);
  const SortableContainer = sortableContainer(props => (
    <tbody {...props} style={{ background: '#fafafa', border: '1px solid #ccc' }} />
  ));
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
      const indexData = [];
      // eslint-disable-next-line array-callback-return
      newData.map((node, index) => {
        const data = newData[index];
        indexData.push({ ...data, index: index });
      });
      onSort && onSort(indexData);
      setDataSource(indexData);
    }
  };
  const DraggableContainer = props => (
    <SortableContainer useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource && dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const pageParams = () => {
    let cp = {}
    let psize ={}
    let base = {
      defaultCurrent: 1,
      showTotal: (total, range) =>
      <>
        {currentSize && (
          <>
              Showing {range[0]} to {range[0] + currentSize > total ? total : range[0] + currentSize} of {total} entries.
          </>
        )}
        {tableData &&
        tableData.totalCount && !currentSize && (
          <>
            Showing {range[0]} to  {  ((range[1]%10 > 0) ? total :  ((range[1]/10)*(  (range[1]-range[0])+1) )) } of {total} entries.
          </>
        )}
        </>,
      onChange: (page, pageSize) => {
        onPaginationChange({ offset: page * pageSize - pageSize, first: pageSize })
      },
      defaultPageSize: PAGINATION_LIMIT,
      total: tableData && tableData.totalCount
    }
    if (currentPage) {
      cp = { current: currentPage}
    }

    if (currentSize) {
      psize = { pageSize: currentSize }
    }

    return { ...base , ...cp, ...psize}
  }
  return (
    <Col span={24} style={{ overflow: 'auto',paddingBottom:"20px" }}className='containct-us-table'>
    <Table
      style={{ width: '100%' }}
      size={!(tableData && tableData.totalCount) && 'middle'}
      loading={loading}
      rowKey={record => (rowKey === 'id' ? getIntFromString(record[rowKey]) : record[rowKey])}
      pagination={
        !(tableData && tableData.totalCount)
          ? false
          : pageParams()
      }
      rowSelection={disable_row_selection?null:rowSelection}
      dataSource={
        dataSource
          ? components
            ? dataSource.sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
            : dataSource.edges && dataSource.edges.map(({ node }) => node)
          : []
      }
      columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}
      components={
        components && {
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }
      }
    />
    </Col>
  );
};

export default RenderTable;

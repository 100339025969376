import { Col, Modal, Radio, Row, Spin } from "antd";
import DoughntChartCascaded from "modules/look-v2/Components/DoughnutchartCascaded";
import DoughntChartNonCascaded from "modules/look-v2/Components/DoughnutChartNonCascaded";
import PaginationDropdown from "modules/look-v2/Components/PaginantionDropdowm";
import PerformanceBar from "modules/look-v2/Components/PerformanceBar";
import React from "react";
import styled from "styled-components";
import modal_close from '../../assets/modal-close-icon.svg'

const OkrPerformanceView =(props)=>{
    const {visible,getAllCascadedObjectives,getAllNonCascadedObjectives,selectedMember,onClose,cascadeProgress,cascadechartData,NoncascadeProgress,NoncascadechartData,loading,cascadeTotalCount,nonCascadeTotalCount,callNonCascade, dateRangeTofilter,getOkrPerformanceCascaded,getOkrPerformanceNonCascaded,nonchartloading,chartloading}=props
    const [PerformanceTab, setPerformanceTab] = React.useState('Cascaded');

    const changeTab=(event)=>{
        setPerformanceTab(event?.target?.value)
        if(event?.target?.value==='Non-Cascaded'&&!NoncascadeProgress?.length&&!NoncascadechartData?.edges?.length){
            callNonCascade()
        }
    }
    let current_result_count = React.useRef({
        Cascaded:5,
        NonCascaded:5
      })
    
      React.useEffect(()=>{
        if(cascadeProgress && dateRangeTofilter){
          current_result_count['current']={
            Cascaded:5,
            NonCascaded:5
          }
          setPerformanceTab('Cascaded')
        }
      },[cascadeProgress,dateRangeTofilter])

    const filterData=(first)=>{
        if(PerformanceTab==='Cascaded'){
            getOkrPerformanceCascaded(first,0,false)
          current_result_count['current'].Cascaded=first
        }else{
            getOkrPerformanceNonCascaded(first,0,false)
          current_result_count['current'].NonCascaded=first
        }
      }
    return (
        <>
        <Modal
             footer={null}
             visible={visible}
             closable={false}
             destroyOnClose={true}
             centered
             className="custom-performance-modal"
             >
                    <CustomModalBody>
                        <Row justify="space-between">
                        <h4 className="chart-title popup-padding"  style={{textAlign:"left",textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>{selectedMember?.name}’s OKR Performance</h4>
                        <img src={modal_close} alt=""style={{position:"absolute",right:"10px",top:"10px",cursor:"pointer"}} onClick={()=>onClose()}/>
                        </Row>  
                        <Spin spinning={loading}>
                        <Row align="center" style={{gap:"30px",width:'100%'}}>
                            <Col lg={13}>
                                <Row justify="space-between" style={{margin:"20px 0px",width:"100%"}}>
                                    <CustomCol style={{marginBottom:'10px'}}>
                                        <Row style={{width:'100%'}}>
                                        <Radio.Group value={PerformanceTab} onChange={(e) => changeTab(e)}>
                                            <RadioButtonCascaded style={{borderRight:"none"}} className={`section-cascade ${PerformanceTab==='Cascaded'?'active-cascade':''}`} value="Cascaded">Cascaded</RadioButtonCascaded>
                                            <RadioButtonNonCascaded   className={`section-non-cascade ${PerformanceTab ==='Non-Cascaded'?'active-non-cascade':''}`} value="Non-Cascaded">Non Cascaded</RadioButtonNonCascaded>
                                        </Radio.Group>
                                        </Row>
                                    </CustomCol>
                                    {/* <PaginationDropdown totalCount={PerformanceTab==='Cascaded'?cascadeTotalCount:nonCascadeTotalCount}/> */}
                                    {(PerformanceTab==='Cascaded' &&cascadeTotalCount>0)?<PaginationDropdown current={current_result_count['current'].Cascaded} onselect={(e)=>{filterData(e)}} totalCount={cascadeTotalCount}/>:''}
                                    {(PerformanceTab==='Non-Cascaded' && nonCascadeTotalCount)?<PaginationDropdown current={current_result_count['current'].NonCascaded} onselect={(e)=>{filterData(e)}} totalCount={nonCascadeTotalCount}/>:''}
                                </Row>
                                {/* <div style={{background: "#FFFFFF",boxShadow:" -1px 3px 66px -25px rgba(0, 0, 0, 0.17)",maxHeight:"360px",width:"100%",padding:"10px 20px",minHeight:"360px"}}>
                                    <PerformanceBar {...props} performanceData={PerformanceTab==='Cascaded'?cascadeProgress:NoncascadeProgress} Height={'350px'} loading={loading}/>
                                </div> */}
                                <div  style={{background: "#FFFFFF",boxShadow:" -1px 3px 66px -25px rgba(0, 0, 0, 0.17)",maxHeight:"450px",width:"100%",padding:"10px 20px",minHeight:"350px"}}>
                                    {
                                        cascadeProgress&&PerformanceTab==='Cascaded' && (
                                            
                                            cascadeProgress?.length?
                                            <PerformanceBar  {...props} performanceData={cascadeProgress||[]}/>:(!loading&&<h4 style={{textAlign:'center',fontSize:"17px",marginTop:"150px"}}>No Performance data</h4>)
                                            
                                        )
                                    }
                                    {
                                    NoncascadeProgress &&PerformanceTab==='Non-Cascaded' && (
                                        
                                        NoncascadeProgress?.length?
                                        <PerformanceBar  {...props} performanceData={NoncascadeProgress||[]}/>:(!loading&&<h4 style={{textAlign:'center',fontSize:"17px",marginTop:"150px"}}>No Records found</h4>)
                                        
                                        
                                    )
                                    }
                                </div>
                            </Col>
                            <Col lg={10} className='chartContainer' style={{maxHeight:"480px",width:"100%"}}>
                                <Row align="middle" style={{width:"100%",flexDirection:"column",paddingTop:'50px'}}>
                                    <Spin spinning={nonchartloading||chartloading}>
                                        {PerformanceTab==='Cascaded'?<DoughntChartCascaded
                                            {...props}
                                            getNextSetOfCascadedOkrs={(e,offset)=>getAllCascadedObjectives(e,offset)}
                                            label={'Cascaded Objectives'}
                                            data={cascadechartData}
                                            height="200px"
                                            loading={nonchartloading||chartloading}
                                            topValue = '135px'
                                            rightValue = '90px'
                                            fontSize = '14px'
                                            responsiveFontSize = '10px'
                                            responsiveTopValue = '87px'
                                            responsiverightValue = '55px'
                                        />:<DoughntChartNonCascaded
                                            {...props}
                                            getNextSetOfNonCascadedOkrs = {(e,offset)=> getAllNonCascadedObjectives(e,offset)}
                                            label={'Non cascaded Objectives'}
                                            data={NoncascadechartData}
                                            topValue = '135px'
                                            rightValue = '90px'
                                            fontSize = '14px'
                                            responsiveFontSize = '9px'
                                            responsiveTopValue = '87px'
                                            responsiverightValue = '54px'
                                            height="200px"
                                            loading={nonchartloading||chartloading}
                                        />}
                                    </Spin>
                                </Row>
                            </Col>
                        </Row>
                        </Spin>
                        </CustomModalBody>
               
            </Modal>
        </>
    )
}

export default OkrPerformanceView
const CustomCol = styled(Col)`

@media(max-width:350px){
    padding-left:0px;
}

@media  (min-width:351px) and (max-width:420px){
    padding-left:10px;
}

@media  (min-width:421px) and (max-width:600px){
    padding-left:0px;
}

@media  (min-width:601px) and (max-width:1200px){
    padding-left:35px;
}

@media  (min-width:1201px) and (max-width:1400px){
    padding-left:0px;
}



@media  (min-width:1800px){
    padding-left:0px;
}

`;


const RadioButtonCascaded = styled(Radio.Button)`
width:110px;
@media(max-width:380px){
    width:95px;
  font-size:10px;
}

`;

const RadioButtonNonCascaded = styled(Radio.Button)` 
  width:130px;
  @media(max-width:380px){
    width:110px;
  font-size:10px;
}
`;


const CustomModalBody = styled.div`
padding:0px 40px;
width:100%;
position:relative;

.chartContainer{
    padding:10px;
}

@media(max-width:1024px){
    padding:0px;

    .chartContainer{
        padding:0px;
    }
}
`;
// @ts-nocheck

import { withApollo } from '@apollo/client/react/hoc';
import { Button, Col, Spin } from "antd";
import React from "react";
import existing_no_data from '../../assets/existing-no-data.svg';
import { compose } from "../../core";
import BadgeCardView from "../components/badge_cards";
import { BADGE_FAMILY_SUBSCRIPTION } from "../graphql/badgeFamilySub.gql";
import { GET_BADGE_FAMILY } from "../graphql/ListBadgeFamily.gql";


const ExistingBadgeFamilyContainer = (props) => {
  const {client, history,permission,setbadge_family_update_data,search_job_family,subcriptionChange,clearSearch}=props
  const [loading,setloading]=React.useState(false)
  const [badge_family_list,setBadgeFamilyList]= React.useState()

  const badge_family_list_ref = React.useRef()

  React.useEffect(()=>{ 
    badge_family_list_ref.current = badge_family_list
  },[badge_family_list])


  const m = React.useRef( true )

  let badgeFamilySub = undefined

  React.useEffect( () => {
    return () => {
      if ( badgeFamilySub ) {
        badgeFamilySub.unsubscribe();
      }
    }
  } )

  React.useEffect( () => {
    return () => {
      m.current = false
    }
  }, [] )

  React.useEffect( () => {
    m.current = true

  }, [] )
  
  React.useEffect(()=>{
    if(permission){
      if(permission?.requried_permission){
        getBadgeFamilyList()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[permission])

React.useEffect(()=>{
  getBadgeFamilyList()
// eslint-disable-next-line react-hooks/exhaustive-deps
},[search_job_family])

React.useEffect(()=>{
  if(subcriptionChange?.length){
    getBadgeFamilyList()
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[subcriptionChange])

const goToJobFamily=()=>{
  clearSearch()
}
  const getBadgeFamilyList=async (filter)=>{
    setloading(true)
    
    const { data } = await client.query({
      query: GET_BADGE_FAMILY,
      variables: {...filter,title_Icontains:search_job_family,organization_Isnull:true},
      fetchPolicy:"network-only"
    });
    if(data){
      setBadgeFamilyList(data?.BadgeFamily)
      setloading(false)
    }
    badgeFamilySub = client.subscribe( {
      query: BADGE_FAMILY_SUBSCRIPTION,
      variables: {...filter,title_Icontains:search_job_family,organization_Isnull:false}
    } ).subscribe( {
      next( result ) {
        let badgeCacheData = badge_family_list_ref.current 

        
        switch ( result.data.badgeFamilySubscription.mutation ) {
          case 'CREATE':
            const updated = {
              pageInfo:{
                hasNextPage:badgeCacheData?.pageInfo?.hasNextPage
              },
              totalCount:badgeCacheData?.totalCount,
              edgeCount:badgeCacheData?.edgeCount,
              edges:[...badgeCacheData?.edges?.map(({node})=>{return {node}}),{node:result.data.badgeFamilySubscription.badgeFamily}]
            }
            if ( m.current ) { 
              setBadgeFamilyList(updated)
            }
            break
          case 'UPDATE':
            if ( m.current ) {
              let updateValue =badgeCacheData?.edges?.map(({node})=>
                node.id === result.data.badgeFamilySubscription.badgeFamily?.id
                  ? {node:result.data.badgeFamilySubscription.badgeFamily}
                  : {node}
              )   
              setBadgeFamilyList(
                {
                  pageInfo:{
                    hasNextPage:badgeCacheData?.pageInfo?.hasNextPage
                  },
                  totalCount:badgeCacheData?.totalCount,
                  edgeCount:badgeCacheData?.edgeCount,
                  edges: updateValue
                })
            }
            break
          case 'DELETE':
            if ( m.current ) {
              let deleteValue = badgeCacheData?.edges?.filter(({node}) =>
                node.id ===result.data.badgeFamilySubscription.badgeFamily?.id
                  ? false
                  : true
              ) 
              setBadgeFamilyList(
                {
                  pageInfo:{
                    hasNextPage:badgeCacheData?.pageInfo?.hasNextPage
                  },
                  totalCount:badgeCacheData?.totalCount,
                  edgeCount:badgeCacheData?.edgeCount,
                  edges:deleteValue
                })
            }
            break
          default:
            break
        }
      }
    } )
  }



  return (
    <>
     <Spin spinning={loading} size="large">
      {badge_family_list?.edges?.length>0?
      <BadgeCardView  dataSource={badge_family_list} edit={false} setbadge_family_update_data={setbadge_family_update_data} {...props} />
      :
      <div className="kudos-root-class kudos-existing-no-data">
        {!loading && (
          <>
          <img src={existing_no_data} className="kudos-no-data-img" alt="" />
          <Col align="center" className="kudos-root-class">
              <h4 className="kuods-not-found">Kudos Family Not Found</h4>
              <Button onClick={()=>{search_job_family?goToJobFamily():history.goBack()}} className="kudos-go-back-btn">Go Back</Button>
          </Col>
          </>
        )}
      </div>}
     </Spin>
    </>
  );
};

export default compose(withApollo)(ExistingBadgeFamilyContainer);

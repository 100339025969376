/* eslint-disable no-unused-vars */
// @ts-nocheck
import { Button, Row, Col,Input } from "antd";
import { AddButton, BackButton, CustomProgress} from "modules/look-v2";
import React from "react";
import discrd_logo from '../../assets/discrd_logo.svg'
import discard_disabled from '../../assets/discard-disabled.svg'
import delete_outline_24px from '../../assets/delete_outline_24px.svg'
import delete_disabled from '../../assets/delete-disabled.svg'
import RequestDiscardView from "./requestDiscardView";
import RequestCancelDiscard from './RequestCancelView'
import { Capitalize,MetaTags, changeToCurrentTime, displayUtcToUserTimezone, getIntFromString } from "modules/look";
import styled from "styled-components";
import edit_pencil from "../../assets/edit_pencil.svg";
import checkCircle from "../../assets/checkCircle.svg";
import moment from "moment-timezone";
import ChatBox from "modules/look-v2/Components/Chatbox";
import DeleteOkrView from "./deleteOkrView";
import Ellipse_red from '../../assets/Ellipse_red.svg'
import Ellipse_green from '../../assets/Ellipse_green.svg'
import Ellipse_blue from '../../assets/Ellipse_blue.svg'
import AddWeightButtonContainer from "../formComponents/Container/AddWeightButtonContainer";
import CompetencyContainer from "../formComponents/Container/OkrCompetencyContainer";
import ObjDetailMileStoneTableContainer from '../container/DetailpageMilestoneTable'
import brown_circle from '../../assets/brown_circle.svg'
import UpdateCorrectionDeadlineModal from "modules/look-v2/Components/UpdateCorrectionDeadlineModal";
import ObjectiveTreeContainer from "../container/ObjectiveTreeContainer";
import okr_route from "../route";
import CustomOkrRangeTable from "modules/look-v2/Components/customOkrDatePickerForTable";
import RangeDatePicker from "modules/look-v2/Components/date-range-picker";
const KeyResultDetailView = (props) => {
  const { okrById, withAllCompetency, setCompetenciesModalVisible, updateOkr, okrCompetenies, me, refetchOkr, entityHeadRoles, compPermissions, refreshCompetency, navigateRoute, getWightList } = props
  const [addKr, setAddKr] = React.useState();
  const [discardPopupvisibleMainKR, setDiscardPopupVisibleMainKR] = React.useState(false);
  const [discardCancelPopupvisible, setDiscardCancelPopupVisible] = React.useState(false);
  const [selectedCompetency, setSelectedCompetency] = React.useState([])
  const [allCompetency, setAllCompetency] = React.useState([])
  const [compModalVisible, setCompModalVisible] = React.useState(false)
  const [competancies, setCompetancies] = React.useState([])
  const [goalStatement, setGoalStatement] = React.useState(okrById?.goalStatement);
  const [duedate, setDueDate] = React.useState();
  const [discardOKRDetail, setdiscardOKRDetail] = React.useState();
  const [correctionDeadline, setCorrectionDeadline] = React.useState();
  const [editableInputBox, setEditableInputBox] = React.useState(false);
  const [deleteRecord, setDeleteRecord] = React.useState();
  const [weightButtonDisabled, setWeightButtonDisabled] = React.useState(false);
  const [roleSpecificCopetency, setroleSpecificCopetency] = React.useState([]);
  const [genericCompetency, setgenericCompetency] = React.useState([]);
  const [selectedCompetencyList, setSelectedCompetencyList] = React.useState([])
  const [okrDiscarded, setOkrDiscarded] = React.useState(false);
  const [isOwner, setIsOwner] = React.useState(false);
  const [isAssignedOkr, setIsAssignedOkr] = React.useState(false);
  const [isInitiator, setIsInitiator] = React.useState(false);
  const [owner, setOwner] = React.useState('');
  const [width, setWidth] = React.useState(window.innerWidth);
  const [okrStatus, setstatus] = React.useState();
  const [okr_tree, set_okr_tree] = React.useState();
  React.useEffect(() => {
    if (okrCompetenies?.length) {
      let rolespecific = okrCompetenies.filter(({ node }) => node?.competency?.competencyType?.toLowerCase() === 'Role_Specific'.toLowerCase())
      let generic = okrCompetenies.filter(({ node }) => node?.competency?.competencyType?.toLowerCase() === 'Generic'.toLowerCase())

      let allComp = okrCompetenies.map(({ node }) => node)
      setSelectedCompetencyList(allComp)
      setroleSpecificCopetency(rolespecific)
      setgenericCompetency(generic)
    }

  }, [okrCompetenies])




  const updateWindowDimensions = () => {
    const newWidth = window.innerWidth;
    setWidth(newWidth);
  }


  React.useEffect(() => {

    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions)

  })


  React.useEffect(() => {
    if (okrById) {
      setEditableInputBox(false);
      let isOkrDiscarded = okrById?.isDiscarded?.id;
      if (isOkrDiscarded) {
        setOkrDiscarded(true);
      }



      if (me && getIntFromString(okrById?.owner?.user?.id) === getIntFromString(me?.id)) {

        setIsOwner(true);
      }



      let status = okrById?.contributorSet?.edges?.map((element) =>
        (element?.node?.role === 'OWNER' && getIntFromString(element?.node?.employee?.user?.id) === getIntFromString(me?.id)) ?
          element

          :
          ((element?.node?.role === 'ASSIGNED' || element?.node?.role === 'COLLABORATOR') && getIntFromString(element?.node?.employee?.user?.id) === getIntFromString(me?.id)) ?
            element
            : null

      ).filter((node) => node != null)

      if (status?.length >= 2) {
        status = status?.filter(({ node }) => node?.role === 'OWNER')
      }

      setstatus(status)





      let contributors = okrById?.contributorSet?.edges?.filter((element) =>
        getIntFromString(element?.node?.employee?.id) === getIntFromString(me?.employee?.id)
      );

      if (getIntFromString(okrById?.pending?.edges[0]?.node?.initiator?.user?.id) === getIntFromString(me?.employee?.user?.id)) {
        setIsInitiator(true);
      }


      if (contributors.length && contributors?.map((node) => node?.node?.role === 'ASSIGNED')) {
        setIsAssignedOkr(true);
      }

      let owner = `${Capitalize(okrById?.owner?.user?.firstName)} ${Capitalize(okrById?.owner?.user?.lastName)}`
      setOwner(owner);

      setCompetancies([{ title: "competency 1" }])
   

      let isDueDateAlive = moment(okrById?.duedate).isAfter(moment());
      let isCorrectionDateAlive = moment(okrById?.correctionDeadline).isAfter(moment())
      if (isCorrectionDateAlive && isDueDateAlive) {
        setCorrectionDeadline(true);
      } else {
        setCorrectionDeadline(false);
      }

    }
  }, [okrById, me])






  React.useEffect(() => {
    if (withAllCompetency) {
      setAllCompetency(withAllCompetency?.edges)
    }

  }, [withAllCompetency])

  let tempCompetemcies = [];
  React.useEffect(() => {
    // eslint-disable-next-line array-callback-return, no-unused-vars
    let availabelCompetencies = okrCompetenies?.okrcompetencySet?.edges?.map((comp) => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tempCompetemcies = [...tempCompetemcies, { 'key': comp?.node?.competency?.id, 'title': comp?.node?.competency?.title }]
    })
    setSelectedCompetency(tempCompetemcies);

  }, [okrCompetenies])


  const handleSubmit = async (e) => {
    let response = await updateOkr({
      id: okrById?.id,
      duedate: duedate?.length > 1 ? changeToCurrentTime(duedate[1]).toISOString() : okrById?.duedate,
      goalStatement: goalStatement,
      startdate: duedate?.length > 0 ? changeToCurrentTime(duedate[0]).toISOString() : okrById?.startdate
    })
    if (response) {
      refetchOkr()
    }
  }

  const updateDiscardRow = (record) => {
    setDiscardPopupVisibleMainKR(false);
    setdiscardOKRDetail(null)
  }



  return (
    <>
      <MetaTags title="OKR" description="OKR Home" />
      {okr_tree && (<ObjectiveTreeContainer okr={okr_tree} onClose={() => { set_okr_tree(null) }} {...props} />)}

      {/* title section */}
      <div className='okr-detail-titile-section okr-detail-main-layout'>
        <BackButton detailArrow={true} />
        <div style={{ display: "flex", gap: "1em", justifyContent: 'center', alignItems: "center" }}>
          {
            (editableInputBox && correctionDeadline) ?
              <Input autoComplete='off' defaultValue={okrById?.goalStatement} type='text' className='okr-detail-page-title okr-detail-input-box' onChange={(e) => setGoalStatement(e.target.value)} />
              :
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "0.75em", width: "fit-content", flexWrap: "nowrap" }}>
                <h4 className="okr-detail-page-title">{okrById?.goalStatement}</h4>
                {
                  !editableInputBox && correctionDeadline && !okrDiscarded && !(okrById?.isDiscarded?.status || okrById?.pending?.totalCount > 0) && isOwner ?
                    <img src={edit_pencil} onClick={(e) => setEditableInputBox(true)} style={{ cursor: "pointer", width: "1.5em" }} alt="edit_pencil" />
                    :
                    null
                }
              </div>
          }
          {
            editableInputBox && isOwner && !okrDiscarded &&
            <img src={checkCircle} style={{ cursor: "pointer", width: "2.5em" }} onClick={(e) => handleSubmit(e)} alt="checkCircle" />
          }
        </div>
        <Row align="middle" justify="space-between" style={{ paddingRight: "0px", flexWrap: "nowrap", gap: ".25em" }}>
          {
            (isOwner || isAssignedOkr) &&
              (okrById?.discardRequestRaised.totalCount > 0) ?
              <img className="details-page-logos" src={discard_disabled} alt="discard_disabled"/> :
              <img className="details-page-logos" src={discrd_logo} onClick={(e) => setDiscardPopupVisibleMainKR(true)} alt="discrd_logo"/>
          }

          {
            isOwner &&
              (okrById?.discardRequestRaised.totalCount || moment(okrById?.correctionDeadline).isBefore(moment())) ?
              <img className="details-page-logos" src={delete_disabled} alt="delete_disabled"/> :
              <img className="details-page-logos" src={delete_outline_24px} onClick={(e) => setDeleteRecord(true)} alt="delete_outline_24px"/>
          }
        </Row>
      </div>


      {
        okrById && (
          <Row style={{ gap: "20px" }}>
            <div className="okr-detail-main-layout">
              <div className="okr-detail-status-container">
                <div className="okr-detail-status-sub-container" >

                  {/* due date section */}
                  <div className='font-family-poppins okr-detail-status-section' >
                    <h4 className='color-gray'>Due Date</h4>
                    {
                      (editableInputBox && correctionDeadline) ?
                        <div>
                          <div className="custom-okr-picker">
                            <CustomOkrRangeTable
                              defaultValue={[moment(okrById.startdate), moment(okrById.duedate)]}
                              disabledRange={{
                                duedate: okrById.parent?.duedate,
                                startdate: moment(okrById.parent?.startdate).isAfter(moment()) ? okrById.parent?.startdate : moment()?._d
                              }}
                              selectedDateRange={(e) => setDueDate(e)}
                              currentSelected={false}
                              disabledType={true}
                            />
                          </div>
                          <div className="okr-picker">
                            <RangeDatePicker
                              defaultValue={[moment(okrById.startdate), moment(okrById.duedate)]}
                              onChange={(e) => setDueDate(e)}
                              disabledRange={{
                                duedate: okrById.parent?.duedate,
                                startdate: moment(okrById.parent?.startdate).isAfter(moment()) ? okrById.parent?.startdate : moment()?._d
                              }}
                            />
                          </div>
                        </div>
                        :
                        <h4 >{displayUtcToUserTimezone(okrById?.duedate, 'DD MMM YYYY')} </h4>
                    }
                  </div>

                  {/* status section */}
                  <div className='font-family-poppins okr-detail-status-section'>
                    <h4 className='color-gray'>Status</h4>
                    {
                      !okrById?.isDiscarded && okrById?.pending.totalCount && okrById?.disapproved.totalCount === 0 ?
                        <div style={{ display: 'flex', alignItems: "center", width: '100%' }}>
                          <img src={Ellipse_blue} style={{ paddingRight: '10px', width: '23px' }} alt="Ellipse_blue"/>
                          <h4 style={{ color: '#009AF1', fontWeight: '600', fontSize: '16px' }}>Discard Requested</h4>
                        </div>
                        :
                        !okrById?.isDiscarded && okrById?.pending.totalCount && okrById?.disapproved.totalCount ?
                          <div style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                            <img src={Ellipse_blue} style={{ paddingRight: '10px', width: '23px' }} alt="Ellipse_blue"/>
                            <h4 style={{ color: '#E86161', fontWeight: '600', fontSize: '16px' }}>Discard Requested</h4>
                          </div>
                          :
                          !okrById?.isDiscarded && okrById?.pending.totalCount === 0 && okrById?.disapproved.totalCount ?
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Col>
                                <Row align="middle" justify="center"  >
                                  <img src={Ellipse_green} style={{ paddingRight: '10px' }} alt="Ellipse_green"/>
                                  <h4 style={{ color: '#4CCB1F', fontWeight: '600', fontSize: '18px' }}>Active</h4>
                                </Row>
                                <h4 align='center' style={{ fontSize: '14px' }}>(Request Disapproved)</h4>
                                <div align='center' style={{ fontSize: '12px', color: "#009AF1", cursor: "pointer" }}
                                  onClick={(e) => navigateRoute(okr_route.discardDetail.replace(":id", getIntFromString(okrById?.disapproved?.edges[0]?.node?.id)))}
                                >
                                  (See Details)</div>
                              </Col>

                            </div>
                            :
                            okrById?.isDiscarded?.status === "APPROVED" ?
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={Ellipse_red} style={{ paddingRight: '10px' }} alt="Ellipse_red"/>
                                <h4 style={{ color: '#E86161', fontWeight: '600', fontSize: '18px' }}>Discarded</h4>
                              </div>
                              :
                              okrStatus ?
                                okrStatus?.map((node) =>
                                  node?.node?.requestStatus === "PENDING" ?
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <img src={brown_circle} style={{ paddingRight: '10px' }} alt="brown_circle"/>
                                      <h4 style={{ color: '#CB861F', fontWeight: '600', fontSize: '18px' }}>Pending</h4>
                                    </div>
                                    :
                                    node?.node?.requestStatus === "ACCEPTED" ?
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={Ellipse_green} style={{ paddingRight: '10px' }} alt="Ellipse_green"/>
                                        <h4 style={{ color: '#4CCB1F', fontWeight: '600', fontSize: '18px' }}>Accepted</h4>
                                      </div>
                                      :
                                      node?.node?.requestStatus === "NEEDS_DISCUSSION" ?
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <img src={Ellipse_blue} style={{ paddingRight: '10px' }} alt="Ellipse_green"/>
                                          <h4 style={{ color: '#009AF1', fontWeight: '600', fontSize: '18px', whiteSpace: "nowrap" }}>Needs Discussion</h4>
                                        </div>
                                        : null
                                )

                                : null
                    }


                  </div>
                </div>

                {/* progress section */}
                <div className='font-family-poppins okr-detail-status-section okr-status-progress-section '>
                  <h4 className='color-gray'>Progress</h4>
                  <CustomProgress progress={`${okrById?.progress}`} lineHeight={1.8} info={true} />
                </div>
                <div className="okr-detail-status-sub-container" >
                  {/* owner section */}
                  <div className='font-family-poppins okr-detail-status-section'>
                    <h4 className='color-gray'>Owner</h4>
                    <div align='center' className="poppins" style={{ fontWeight: '500', fontSize: '16px', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "150px" }}>
                      {owner}
                    </div>
                  </div>
                </div>

              </div>

              {/* discard request detail section */}
              {
                (okrById?.isDiscarded?.id || okrById?.pending?.totalCount > 0) &&
                <Row align='middle' justify="center" style={{
                  marginTop: '50px', background: '#FFFFFF',
                  boxShadow: '0px 4px 42px 8px rgba(0, 0, 0, 0.07)',
                  borderRadius: '17px',
                  padding: "20px"
                }}>
                  <Col style={{ marginTop: '20px' }} span={16}>
                    <Row align='middle' justify="center">
                      <Col>
                        {
                          okrById?.isDiscarded?.status === "APPROVED" ?
                            <h2 className='objective-title'>Discard Request APPROVED</h2>
                            :
                            okrById?.pending?.totalCount > 0 ?
                              <h2 className='objective-title'>Discard Request</h2>

                              : null
                        }
                      </Col>
                    </Row>
                    {okrById?.isDiscarded?.id && <p className='discard-subhead' style={{ color: '#009AF1', fontWeight: "400", textDecoration: "underline", cursor: "pointer", paddingTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center' }} onClick={() => navigateRoute(okr_route.discardDetail.replace(":id", getIntFromString(okrById?.isDiscarded?.id)))}>See Details</p>}
                    {okrById?.pending?.totalCount > 0 && <p className='discard-subhead' style={{ color: '#009AF1', fontWeight: "400", textDecoration: "underline", cursor: "pointer", paddingTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center' }} onClick={() => navigateRoute(okr_route.discardDetail.replace(":id", getIntFromString(okrById?.pending?.edges[0]?.node?.id)))}>See Details</p>}
                    <Row justify='space-around'>
                      <Col>
                        <p className='discard-small-head'>
                          Created By
                        </p>
                        <h4 className='discard-small-data'>
                          {okrById?.pending?.totalCount > 0 &&
                             <span className="text-transform-capitalize">{okrById?.pending?.edges[0]?.node?.initiator?.user?.firstName} {okrById?.pending?.edges[0]?.node?.initiator?.user?.lastName || ""}</span> 
                          }
                          {okrById?.isDiscarded?.id &&
                            <span className="text-transform-capitalize">{okrById?.isDiscarded?.initiator?.user?.firstName} {okrById?.isDiscarded?.initiator?.user?.lastName || ""}</span> 
                          }
                        </h4>

                      </Col>
                      <Col>
                        <p className='discard-small-head'>
                          Created On
                        </p>
                        <h4 className='discard-small-data'>
                          {okrById?.isDiscarded?.id && displayUtcToUserTimezone(okrById?.isDiscarded?.createdAt, 'DD MMM YYYY')}
                          {okrById?.pending?.totalCount > 0 && displayUtcToUserTimezone(okrById?.pending?.edges[0]?.node?.createdAt, 'DD MMM YYYY')}
                        </h4>

                      </Col>
                      {okrById?.isDiscarded?.id && <Col>
                        <p className='discard-small-head'>
                          Approved on
                        </p>
                        <h4 className='discard-small-data'>
                          {displayUtcToUserTimezone(okrById?.isDiscarded?.actedOn, 'DD MMM YYYY')}
                        </h4>

                      </Col>}
                      <Col>
                        <p className='discard-small-head'>
                          Rationale
                        </p>
                        <h4 className='discard-content' style={{ paddingTop: "10px" }}>
                          {okrById?.isDiscarded?.id && <div align='center'>{okrById?.isDiscarded?.initiatorsRational}</div>}
                          {okrById?.pending?.totalCount > 0 && <div align='center'>{okrById?.pending?.edges[0]?.node?.initiatorsRational}</div>}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }

              <Row align="middle" justify="center" style={{ padding: "30px 0px 30px 0px" }} wrap={true}>
                {
                  !okrDiscarded && isOwner && (
                    <div style={{ margin: "5px" }}>
                      <Button disabled={!compPermissions?.required_permission || !correctionDeadline} className="generic-button okr-competency-button" style={{ border: ' 1px solid #595959' }} onClick={(e) => { setCompetenciesModalVisible(true); setCompModalVisible(true) }}>Competencies</Button>
                    </div>
                  )
                }

                {
                  (!okrDiscarded && isOwner && okrById?.pending?.totalCount === 0 && correctionDeadline &&
                    !okrById?.contributorSet?.edges?.find((item) => item?.node?.role === 'ASSIGNED')) && (
                    <div style={{ margin: "5px" }}>
                      <AddButton fontSize='16px' disabled={okrDiscarded} onAdd={(e) => { setAddKr(!addKr ? true : false) }} label='Milestone' />
                    </div>
                  )

                }

                {
                  (!okrDiscarded && isOwner && okrById?.pending?.totalCount > 0 && isInitiator) &&
                  <Button className="modal-submit-btn" style={{ height: "40px", fontSize: '16px', margin: "5px" }} onClick={() => setDiscardCancelPopupVisible(true)}>Cancel Request</Button>
                }

                {
                  (!okrDiscarded && isOwner) &&
                  <div style={{ margin: "5px" }}>
                    <AddWeightButtonContainer disabled={weightButtonDisabled || !correctionDeadline}  {...props} okrId={okrById?.id}
                      tooltipTitle={"When you have multiple child milestones, by default the weightage will be equally divided amongst all of them. You can adjust the weight according to the value that you would like to assign for each child milesotnes"}
                    />
                  </div>
                }

                {
                  (entityHeadRoles?.isallowed_role && moment(okrById?.correctionDeadline).isAfter(moment()) && moment(okrById?.duedate).isAfter(moment())) &&
                  <div style={{ margin: "5px" }}>
                    <UpdateCorrectionDeadlineModal duedate={okrById?.duedate} startdate={okrById?.startdate} entityHeadRoles={entityHeadRoles} correctionDeadline={okrById?.correctionDeadline} {...props} />
                  </div>
                }

              </Row>
              <div style={{ width: "100%", overflow: "auto" }}>
                <ObjDetailMileStoneTableContainer editMilestones={correctionDeadline} displayConnectionLine={false} set_okr_tree={set_okr_tree} customClass={true} disabled={setWeightButtonDisabled} hideadd={true} addChild={addKr} keyResultDetail={okrById} getWightList={() => getWightList()} {...props} />
              </div>

              
              <div style={{ width: "100%", display: "flex", justifyContent: "start", flexDirection: "column",gap:"20px", paddingTop: "10px" }}>
                {roleSpecificCopetency?.length > 0 &&
                  (
                  <div className="okr-detail-competency-container">
                    <h3 className="comp-head" style={{ paddingTop: "10px",textAlign:"center" }}>
                      <span style={{ color: '#009AF1' }}>Role Specific</span><br />
                      <span style={{ whiteSpace: 'nowrap', paddingTop: "5px" }}>Competencies</span>
                    </h3>
                    <Row align="start" style={{ width: "100%", display: "flex wrap", justifyContent: "start", gap: '10px' }}>
                      {
                        roleSpecificCopetency?.length && roleSpecificCopetency?.map(({ node }) => {
                          return (
                            <div className="okr-competency-card" >
                              {node?.competency?.title}
                            </div>)
                        })
                      }
                    </Row>
                  </div>)
                }
                {genericCompetency?.length > 0 &&
                  (
                    <div className="okr-detail-competency-container">

                      <h3 className="comp-head" style={{ paddingTop: "10px",textAlign:"center" }}>
                        <span style={{ color: '#4CCB1F' }}>Generic</span><br />
                        <span style={{ whiteSpace: 'nowrap', paddingTop: "5px" }}>Competencies</span>
                      </h3>

                      <Row align="start" style={{ width: "100%", display: "flex wrap", justifyContent: "start", gap: '10px' }}>
                        {
                          genericCompetency?.length && genericCompetency?.map(({ node }) => {
                            return (
                              <div className="okr-competency-card" >
                                {node?.competency?.title}
                              </div>)
                          })
                        }
                      </Row>
                    </div>
                  )
                }
              </div>
            </div>
            <Col>
              <ChatBox okrId={okrById?.id} contributors={okrById?.contributors} />
            </Col>

          </Row>
        )
      }
      <RequestCancelDiscard visible={discardCancelPopupvisible} {...props} discardData={okrById} onClose={() => { setDiscardCancelPopupVisible(false) }} />
      <RequestDiscardView visible={discardPopupvisibleMainKR} {...props} discardData={okrById} onClose={() => { setDiscardPopupVisibleMainKR(false); setdiscardOKRDetail(null) }} discardedSuccess={(e) => { updateDiscardRow(e) }} />
      <CompetencyContainer allCompetency={allCompetency} compModalVisible={compModalVisible} okrId={okrById?.id} onClose={() => { setCompModalVisible(false); refreshCompetency(okrById?.id) }} selectedCompetency={selectedCompetencyList}{...props} />
      <DeleteOkrView visible={deleteRecord} type="KeyResult" deletesuccess={(e) => { navigateRoute("-1"); }} closeModal={() => { setDeleteRecord(null) }} okrId={okrById?.id} {...props} />
    </>
  );
};

export default KeyResultDetailView


export const TableDiv = styled(Row)`
padding:0px 60px;
width:100%;
@media (max-width:900px){
  padding:0px 20px;
}
@media (max-width:600px){
  padding:20px 0px;
  width:100%;
}
`
// @ts-nocheck
import { useMutation } from '@apollo/client';
import { Button, Col, Input, message, Select, Spin } from "antd";
import gql from 'graphql-tag';
import { getIntFromString } from "modules/look";
import React from "react";
import { CLOUDINARY_CLOUDNAME, CLOUDINARY_PRESET } from '../../../config';
import new_drop_arrow from '../../assets/new_drop_arrow.svg';
import red_delete from '../../assets/red-delete.svg';
import red_close from '../../assets/red_close.svg';
import transparent_delete from '../../assets/transparent_delete.svg';
import upload_icon from '../../assets/upload-icon.svg';
import "../kudos.css";
const BadgeFamilyUpdateView = (props) => {
  const { setLoading, badgefamily, closeModal, updateBadgeFamily, badge_family_update_data, permission } = props;
  const [title, setTitle] = React.useState();
  const [detail, setdetail] = React.useState();
  const [addclick, setaddclick] = React.useState(false);
  const [scroll_to_badge, setscroll_to_badge] = React.useState(false);
  const [badge_dynamic_mutation_list, setdynamicMutationList] = React.useState();
  const [validation, setValidation] = React.useState()
  const [initial, setInitial] = React.useState(false)
  const Option = Select.Option;
  const cat_list = [
    { value: "academic", label: "Academic", key: "ACADEMIC" },
    { value: "professional", label: "Professional", key: "PROFESSIONAL" },
    { value: "recreational", label: "Recreational", key: "RECREATIONAL" },
  ]

  const [fileList, setFileList] = React.useState()

  const fileKeyRef = React.useRef(1)
  const cloudName = `${CLOUDINARY_CLOUDNAME}/image`;
  const unsignedUploadPreset = `${CLOUDINARY_PRESET}`;
  const cloudinary_url = 'https://api.cloudinary.com/v1_1/' + cloudName + '/upload';

  React.useEffect(() => {
    if (badgefamily && badge_family_update_data) {
      setTitle(badgefamily.title)
      setdetail(badgefamily.description)
      fileKeyRef.current = badgefamily.badgeSet.edges?.length
      if (badgefamily.badgeSet.edges?.length) {
        setFileList(badgefamily.badgeSet.edges.map(({ node }, index) => {
          let category = cat_list?.find(item => item.key === node?.catrgory)
          return {
            ...node,
            key: index,
            catrgory: category?.value,
            order: node?.id === badge_family_update_data?.badge_id ? 0 : 1,
            duration: node.duration ? node.duration / 86400 : ''
          }
        }).sort((a, b) => a.order - b.order)
        )
      }
      else if(permission?.requried_permission) {
        fileKeyRef.current = fileKeyRef.current + 1
        setFileList([
          {
            key: fileKeyRef.current,
            badge_url: ""
          }
        ])
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badgefamily, badge_family_update_data])
  
  React.useEffect(() => {
    if (fileList?.length && !scroll_to_badge && badgefamily && badge_family_update_data?.badge_id) {
      let scroll = document.getElementById(`update_badge_${badge_family_update_data?.badge_id}`)
      if (scroll) {
        scroll.scrollIntoView({ inline: "start", behavior: "smooth" })
      }
      setscroll_to_badge(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList])

  const BADGE_MUTATION_QUERY = gql`
  mutation badgeApi {
    ${badge_dynamic_mutation_list?.filter((i) => !i.id && !i.isDelete)
      ?.map(
        (item) =>
          `create${item.key}: createBadge(badgeData:{
          title:${JSON.stringify(item?.title)},
          ${item?.behaviouralDescription ? `behaviouralDescription:${JSON.stringify(item?.behaviouralDescription)}` : ''}
          catrgory:${JSON.stringify(item?.catrgory)},
          badgeFamilyId:${item?.badgeFamilyId},
          image:${JSON.stringify(item?.image)},
          ${item?.duration ? `duration:${item?.duration}` : ''}
         }) {
          badge{
            id
          }
         }
       `
      )
      .join('')}


    ${badge_dynamic_mutation_list?.filter((i) => i.id && !i.isDelete)
      ?.map(
        (item) =>
          `update${item.key}: updateBadge(
            id:${getIntFromString(item?.id)},
            title:${JSON.stringify(item?.title)},
            behaviouralDescription:${JSON.stringify(item?.behaviouralDescription) || ""}
       
            image:${JSON.stringify(item?.image)},
            duration:${(item?.duration || 0)}
           ) {
            badge{
              id
            }
           }
         `
      )
      .join('')}

      ${badge_dynamic_mutation_list?.filter((i) => i.id && i.isDelete)
      ?.map(
        (item) =>
          `deleteBadge${item.key}: deleteBadge(
            badgeId:${getIntFromString(item?.id)},
           ) {
            badge{
              id
            }
           }
         `
      )
      .join('')}
  }
  `;
  // catrgory:${JSON.stringify(item?.catrgory)},
  // eslint-disable-next-line no-unused-vars
  const [executeBadgeApi, { data, isloading, error }] = useMutation(BADGE_MUTATION_QUERY);


  React.useEffect(() => {
    if (badge_dynamic_mutation_list && badge_dynamic_mutation_list?.length) {
      executeMutation()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badge_dynamic_mutation_list])

  const executeMutation = async () => {
    setLoading(true)
    const response = await executeBadgeApi()
    if (response?.data) {
      message.success('Created successfully');
      setLoading(false)
      closeModal()
    } else {
      setLoading(false)
    }
  }

  const category_option = cat_list &&
    cat_list.map((choice, key) => (
      <Option
        key={key}
        value={choice?.value}
      >
        {choice?.label}
      </Option>
    ));

  const addBadegForm = () => {
    let file_list = fileList.concat([])
    if (fileList.filter(ele => !ele.id).length < 10) {
      fileKeyRef.current = fileKeyRef.current + 1
      let key = fileKeyRef.current

      file_list.push(
        {
          key: key,
          badge_url: ""
        }
      )
      setFileList(file_list)
      setaddclick(true)
    }

  }

  React.useEffect(() => {
    if (addclick) {
      let scroll = document.getElementById('kudos-overflow')
      if (scroll) {
        scroll.scrollIntoView({ inline: "end", behavior: "smooth" })
      }
      setaddclick(false)
    }
  }, [addclick])

  const fileUpload = (event, doc) => {
    let file = event?.target.files[0]
    let file_list = fileList.concat([])
    let current_item = file_list.find(item => item.key === doc?.key)
    if (current_item && file) {
      current_item['image'] = ""
      current_item['badge_url'] = URL.createObjectURL(file)
      setFileList(file_list)
      uploadtocloudnary(file, current_item)
    }
  }

  const uploadtocloudnary = (file, doc) => {
    let file_list = fileList.concat([])
    let current_item = file_list.find(item => item.key === doc?.key)
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', unsignedUploadPreset);
    fetch(cloudinary_url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        current_item['image'] = response?.secure_url
        current_item['badge_url'] = ""
        setFileList(file_list)
      })
      .catch((err) => {
        current_item['badge_url'] = ""
        setFileList(file_list)
      })
  }


  const badgeFormDataChange = (data, doc, field) => {

    let file_list = fileList.concat([])
    let current_item = file_list.find(item => item.key === doc?.key) 
    if (current_item) {
      current_item[field] = data
      setFileList(file_list)
    }
  }

  const changeduration = (data, doc, field) => {
    let file_list = fileList.concat([])
    let current_item = file_list.find(item => item.key === doc?.key)
    if (current_item) {
      if (isNaN(data) || parseInt(data) < 0 || !data) {
        current_item[field] = ""
      } else {
        current_item["duration"] = parseInt(data)
      }
      setFileList(file_list)
    }
  }

  const CheckValidation = () => {
    let main_err = {}
    if (!title || title?.trim() === '') {
      main_err['badgefamily_title'] = "badge family title missing"
    }
    if (!detail || detail?.trim() === '') {
      main_err['badgefamily_detail'] = "badge family description missing"
    }

    let badge_list = fileList.concat([])

    badge_list.forEach(item => {
      let item_err = {}
      if (!item?.title || !item?.title?.trim() === '') {
        item_err['title'] = true
      }
      if (!item?.catrgory) {
        item_err['catrgory'] = true
      }
      if (!item?.image) {
        item_err['image'] = true
      }
      if (Object.keys(item_err).length) {
        main_err[`badge_err_${item.key}`] = item_err
        item['err'] = item_err
      }
    })
    if (Object.keys(main_err).length) {
    } else {
      setInitial(false)
    }

  }
  const handleValidation = () => {
    let main_err = {}
    setValidation()
    if (!title || title?.trim() === '') {
      main_err['badgefamily_title'] = "badge family title missing"
    }
    if (!detail || detail?.trim() === '') {
      main_err['badgefamily_detail'] = "badge family description missing"
    }

    let badge_list = fileList.concat([])

    badge_list.forEach(item => {
      let item_err = {}
      if (!item?.title || !item?.title?.trim() === '') {
        item_err['title'] = true
      }
      if (!item?.catrgory) {
        item_err['catrgory'] = true
      }
      if (!item?.image) {
        item_err['image'] = true
      }
      if (Object.keys(item_err).length) {
        main_err[`badge_err_${item.key}`] = item_err
        item['err'] = item_err
      }
    })
    setValidation(main_err)
    if (Object.keys(main_err).length) {
      setFileList(badge_list)
      message.error('fill in the required fields')
    } else {
      handleSubmit(badge_list)
    }

  }

  const handleSubmit = async (badge_list) => {
    setLoading(true)
    let badgeFamilyData = { title, description: detail, id: getIntFromString(badgefamily.id) }

    if (permission?.requried_permission) {
      const update_badge_family = await updateBadgeFamily(badgeFamilyData)



      if (update_badge_family?.id) {
        if (badge_list.length) {
          if (permission.requried_permission) {
            let list = badge_list?.map(data => {
              return {
                ...data,
                badgeFamilyId: getIntFromString(badgefamily.id)
              }
            })
            setdynamicMutationList(list)
          }
          else {
            let lists = badge_list?.filter(item => item?.id === undefined).map(data => {
              return {
                ...data,
                badgeFamilyId: getIntFromString(badgefamily.id)
              }
            })
            setdynamicMutationList(lists)
          }
        } else {
          setLoading(false)
          message.success('Created successfully');
          closeModal()
        }
      }
    }
    else {
      if (badge_list.length) {
        if (permission.requried_permission) {
          let list = badge_list?.map(data => {
            return {
              ...data,
              badgeFamilyId: getIntFromString(badgefamily.id)
            }
          })
          setdynamicMutationList(list)
        }
        else {
          let lists = badge_list?.filter(item => item?.id === undefined).map(data => {
            return {
              ...data,
              badgeFamilyId: getIntFromString(badgefamily.id)
            }
          })
          setdynamicMutationList(lists)
        }
      } else {
        setLoading(false)
        message.success('Created successfully');
        closeModal()
      }
    }


  }

  const removeForm = (form) => {
    let file_list = fileList.concat([])
    if (!form.id) {
      file_list = file_list.filter(item => item?.key !== form.key)
    } else {
      let element = file_list.find(item => item?.key === form.key)
      element.isDelete = true
    }
  
    setFileList(file_list)
  }

  const Renderbadgelist = (list, isUpdate) => (
    <>
      {(!isUpdate && list?.length && permission?.requried_permission) ? <h4 className="kudos-modal-title">Add Kudos</h4> : ""}
      <div

        name={"badge_orm"}
        className="kudos-badge-form"
      >

        {
          list?.map((item, index) => (
            <>
              {isUpdate && (<p className={`badge-index ${item?.id === badge_family_update_data?.badge_id ? 'selected-badge-index' : ''}`} id={`update_badge_${item?.id}`}>{index + 1}. Kudos</p>)}
              <div className="badge-info-main">

                {
                  (item?.image || item?.badge_url) ?
                    <div className="kudos-form-row">
                      <Spin spinning={(item?.badge_url && !item?.image) ? true : false}>
                        <div className="upload-success-container">
                          <img src={item?.image || item?.badge_url} alt="" />
                        </div>
                      </Spin>
                      <img src={red_delete} className="delete-show-mobile" style={{ cursor: "pointer" }} onClick={() => removeForm(item)} alt="" />
                    </div>
                    :
                    <label htmlFor={`file_input_${item?.key}`} style={{ cursor: "pointer" }} className="kudos-form-row">
                      <div className="upload-container" style={{ border: validation !== undefined && validation[`badge_err_${item?.key}`]?.image ? '1px solid #ff4d4f' : '1px solid #D9D9D9' }}>
                        <img src={upload_icon} alt="" />
                      </div>
                      <img src={red_delete} className="delete-show-mobile" style={{ cursor: "pointer" }} onClick={() => removeForm(item)} alt="" />
                    </label>
                }
                <input autoComplete='off' type="file" id={`file_input_${item?.key}`} onChange={(e) => { fileUpload(e, item); CheckValidation() }} name={`file_input_${item?.key}`} style={{ display: "none" }} />

                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <Col style={{ marginTop: '1em' }} span={24} className="kudos-form-group" >

                    <Input
                      // disabled={memberlist?.length?false:true}
                      style={{
                        border: validation !== undefined && validation[`badge_err_${item?.key}`]?.title ? '1px solid #ff4d4f' : '1px solid #C2C2C2',
                        backgroundColor: "#ffff",
                        height: "40px",
                        borderRadius: "8px",
                      }}
                      value={item?.title}
                      name={`title_${item?.key}`}
                      placeholder="Add Title"
                      onChange={(e) => { badgeFormDataChange(e?.target?.value, item, 'title'); CheckValidation() }}
                      autoComplete='off'
                    />

                  </Col>
                  <div className="badge-info-row" style={{ marginTop: '1em' }}>
                    <Col lg={{ span: 13 }} md={{ span: 13 }} sm={{ span: 13 }} className="kudos-form-group" >

                      <Input
                        // disabled={memberlist?.length?false:true}
                        style={{
                          border: '1px solid #C2C2C2',
                          backgroundColor: "#ffff",
                          height: "40px",
                          borderRadius: "8px",
                        }}
                        value={item?.duration}
                        placeholder="Duration in days (Optional)"
                        name={`duration_${item?.key}`}
                        onChange={(e) => { changeduration(e?.target?.value, item, 'duration') }}
                        autoComplete='off'
                      />

                    </Col>
                    <Col className="kudos-form-group no-border-category" style={{ height: "40px" }}>
                      <Select
                        name={`badge_cat_${item.key}`}
                        suffixIcon={<img src={new_drop_arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                        style={{
                          width: '100%',
                          border: validation !== undefined && validation[`badge_err_${item?.key}`]?.catrgory ? '1px solid #ff4d4f' : '1px solid #C2C2C2',
                          borderRadius: "8px"
                        }}
                        placeholder="Category"
                        {...props}
                        disabled={isUpdate}
                        onSelect={(e) => { badgeFormDataChange(e, item, 'catrgory'); CheckValidation() }}
                        filterOption={(input, option) => option}
                        value={item?.catrgory}
                      >
                        {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}
                        {category_option}
                      </Select>
                    </Col>
                  </div>


                  <Col style={{ marginTop: '1em' }} className="kudos-form-group">

                    <textarea name={`description_${item?.key}`} placeholder="Behavioral Description" maxLength={200} value={item?.behaviouralDescription} className="kudos-description" onChange={(e) => { badgeFormDataChange(e?.target?.value, item, 'behaviouralDescription') }}></textarea>
                  </Col>

                </div>
                <img src={red_delete} style={{ cursor: "pointer" }} className="delete-hide-mobile" onClick={() => removeForm(item)} alt="" />
              </div>
            </>
          ))
        }
      </div>

    </>
  )

  return (
    <div style={{height: badgefamily?.id ? 'auto' : '630px'}}>

      {
         <Spin spinning={(badgefamily?.id) ? false :  true} >

          <div align="end" style={{ marginBottom: "0.25em" }}>
            <img style={{ cursor: "pointer" }} src={red_close} onClick={() => { closeModal() }} alt="" />
          </div>
          <div className="kudos-root-class kudos-modal-overflow" >
            {/* Badge family */}

    
              <div>
                <h4 className="kudos-modal-title">Kudos family</h4>
                <div

                  name={"badge_family"}
                  className=" kudos-badge-form"
                >
                  <div className="badge-info-main">
                    <div className=" dummy-container">

                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                      <Col className="kudos-form-group" >

                        <Input
                          // disabled={memberlist?.length?false:true}
                          readOnly={permission?.requried_permission ? false : true}
                          style={{
                            border: !validation?.badgefamily_title ? '1px solid #C2C2C2' : '1px solid #ff4d4f',
                            backgroundColor: "#ffff",
                            height: "40px",
                            borderRadius: "8px",
                          }}
                          placeholder="Add Title"
                          value={title}
                          onChange={(e) => { setTitle(e?.target?.value); CheckValidation() }}
                          autoComplete='off'
                        />

                      </Col>
                      <Col style={{ marginTop: '1em' }} className="kudos-form-group">

                        <textarea name="description" readOnly={permission?.requried_permission ? false : true} style={{ border: !validation?.badgefamily_detail ? '1px solid #C2C2C2' : '1px solid #ff4d4f' }} placeholder="Description" maxLength={200} id="" className="kudos-description" value={detail} onChange={(e) => { setdetail(e?.target?.value); CheckValidation() }}></textarea>

                      </Col>
                    </div>
                    <img src={transparent_delete} alt="" />

                  </div>

                </div>
              </div>

            


            {/* add badge  */}
            {Renderbadgelist(fileList?.filter(item => item?.id && !item.isDelete), true)}

            {Renderbadgelist(fileList?.filter(item => !item?.id), false)}

            <div align="center" id="kudos-overflow">
              <Button className="kudos-modal-btn kudos-save" disabled={initial} onClick={() => { handleValidation() }}>
                SAVE
              </Button>
              {
                permission.requried_permission ?
                  <Button className="kudos-modal-btn kudos-new-badge" disabled={initial} onClick={() => { addBadegForm() }}>
                    ADD KUDOS
                  </Button> : ''
              }

            </div>
          </div>
        </Spin>
          
      }


    </div>
  )
};

export default BadgeFamilyUpdateView;

import React from 'react';
import { Spin } from 'antd';

import { compose } from '../../../core';
import { ButtonsCatch, getIntFromString, withMarkdownFieldValue } from '../../../look';

import EditFaqQuestionView from './components/EditFaqQuestionView';

import { withEditFaqQuestion, withFaqQuestion } from '../PageOperations';
import { CKEditorFieldName, CKEditorFieldName1 } from './components/FaqQuestionFormComponent';
import ROUTE from '../../route';

const AddFaqQuestion = props => {
  const { history, editFaqQuestion, faqQuestionById } = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    try {
      const response = await editFaqQuestion({
        id: faqQuestionById && getIntFromString(faqQuestionById.id),
        // questionText: values.question,
        answer: withMarkdownFieldValue(CKEditorFieldName1, { answer: values.answer }).answer,
        questionText: withMarkdownFieldValue(CKEditorFieldName, { question: values.question }).question
      });

      response &&
        ButtonsCatch(
          item,
          `${ROUTE.faqQuestionLink}${getIntFromString(faqQuestionById.catagory.id)}`,
          history,
          item === undefined
            ? window.location.pathname
            : `${ROUTE.addFaqQuestionLink}${getIntFromString(faqQuestionById.catagory.id)}`
        );
    } catch (e) {
      throw new Error(e.message);
    }
    setSubmitLoading(false);
  };

  return (
    <Spin spinning={submitLoading} size="large">
      <EditFaqQuestionView onSubmit={handleSubmit} {...props} />
    </Spin>
  );
};

export default compose(withFaqQuestion, withEditFaqQuestion)(AddFaqQuestion);

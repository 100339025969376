import { Select, Spin, Tooltip } from "antd"
import React from "react"
import { user_manual_icon } from "../user-manual-icon"
import { getIntFromString } from "modules/look"
import UserManualResponseContainer from "../containers/userManualResponseContainer"

const TeamUserManualView = (props) => {
    const { data_loading, data_list, SetUserTwoData, user_one_data, user_two_data, getRequestResponse, allowComparison, me, HandleComparison } = props
    const [compare, SetCompare] = React.useState(false)

    const Option = Select.Option;
    const onChangeCompare = () => {
        SetCompare(!compare)
        SetUserTwoData(null)
    }

    const OnSelectuser = (data, type) => {
        let selected_data_detail = data_list?.find(i => String(i?.data_id) === String(data))
        if (!selected_data_detail) {
            return
        }
        if (allowComparison || getIntFromString(me?.id) === Number(data)) {
            getRequestResponse({
                requestedUserId: data,
                type,
                user: selected_data_detail.user,
                data_id: selected_data_detail.data_id
            })
        } else {
            getRequestResponse({
                requestId: data,
                type,
                user: selected_data_detail.user
            })
        }
        OnSearch("")
    }

    const search_timer = React.useRef()
    const OnSearch = (e) => {
        if (search_timer?.current) {
            clearTimeout(search_timer.current)
        }
        search_timer.current = setTimeout(() => {
            if (allowComparison) {
                HandleComparison(
                    { nameIcontains: e }
                )
            } else {
                HandleComparison(
                    { receiver_FirstName_Icontains: e }
                )
            }
        }, 1000);
    }
 
    return (
        <Spin spinning={data_loading}>
            <div style={{ width: "100%", marginTop: "1.5em" }}>
                <div className="um-compare-spacebtw">
                    <div style={{ width: "100%" }}>
                        <Select
                            name={"employee_1"}
                            id={"employee_1"}
                            key={"employee_1"}
                            showSearch={true}
                            placeholder="Select Employee"
                            className="um-employee-selector"
                            style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                            filterOption={false}
                            onSearch={(e) => { OnSearch(e) }}
                            onSelect={(e) => OnSelectuser(e, "employee_1")}
                            suffixIcon={<img src={user_manual_icon?.dropdown_arrow}  alt="" style={{width:"10px",height:"10px",cursor:"pointer"}}/>}
                            // value={user_one_data?.data_id || ""}
                        >
                            {
                                data_list?.map(i => {
                                    return {
                                        value: i?.data_id,
                                        label: `${i?.user?.firstName} ${i?.user?.lastName || ""}`
                                    }
                                })?.map((choice, index) => (
                                    <Option
                                        style={{
                                            color: "#A3A3A3",
                                            fontSize: "1em",
                                            fontWeight: "500",
                                            textTransform:"capitalize"
                                        }}
                                        key={choice?.value}
                                        value={choice?.value}
                                    >
                                        {choice?.label}
                                    </Option>
                                ))
                            }
                        </Select>
                    </div>
                    <Tooltip title="Compare User Manual">
                        <img src={!compare ? user_manual_icon.inactive_compare : user_manual_icon.active_compare} onClick={() => { onChangeCompare() }} alt="compare" className="um-compare-icon" />
                    </Tooltip>
                    <div style={{ width: "100%" }}>
                        {
                            (compare) && (
                                <Select
                                    name={"employee_2"}
                                    id={"employee_2"}
                                    key={"employee_2"}
                                    showSearch={true}
                                    placeholder="Select Employee"
                                    className="um-employee-selector"
                                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                    filterOption={false}
                                    onSearch={(e) => { OnSearch(e) }}
                                    onSelect={(e) => OnSelectuser(e, "employee_2")}
                                    suffixIcon={<img src={user_manual_icon?.dropdown_arrow}  alt="" style={{width:"10px",height:"10px",cursor:"pointer"}}/>}
                                    // value={user_two_data?.data_id || ""}
                                >
                                    {
                                        data_list?.map(i => {
                                            return {
                                                value: i?.data_id,
                                                label: `${i?.user?.firstName} ${i?.user?.lastName || ""}`
                                            }
                                        })?.map((choice, index) => (
                                            <Option
                                                style={{
                                                    color: "#A3A3A3",
                                                    fontSize: "1em",
                                                    fontWeight: "500",
                                                    textTransform:"capitalize"
                                                }}
                                                key={choice?.value}
                                                value={choice?.value}
                                            >
                                                {choice?.label}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            )
                        }

                    </div>
                </div>

                {
                    (user_one_data || user_two_data) && (
                        <div className="um-compare-spacebtw">
                            {
                                user_one_data && (
                                    <div className="user-manual-main-body">
                                        {/* banner section of user manual */}
                                        <div className="user-manual-banner compare-banner">
                                            <h4 className="user-manual-label-compare" style={{ textTransform: "capitalize" }}>{user_one_data?.user?.firstName}'s <span style={{ color: "rgba(76, 203, 31, 1)" }}>User Manual</span></h4>
                                            <img src={user_manual_icon.banner_icon} alt="user_manual_banner" className="user_manual_banner_icon" />
                                        </div>
                                        {
                                            user_one_data?.user_manual_data?.map((i, index) => (
                                                // component to handle the answer card view
                                                <UserManualResponseContainer {...props} answer={i?.node} compare_data={user_two_data?.user_manual_data} can_edit={false} question_no={index + 1} className={(user_one_data && user_two_data) ? "um-response-card-height" : ""} />
                                            ))
                                        }
                                    </div>
                                )
                            }
                            {
                                (user_one_data && user_two_data) && (
                                    <div className="um-compare-icon"></div>
                                )
                            }
                            {
                                user_two_data && (
                                    <div className="user-manual-main-body">
                                        {/* banner section of user manual */}
                                        <div className="user-manual-banner compare-banner">
                                            <h4 className="user-manual-label-compare" style={{ textTransform: "capitalize" }}>{user_two_data?.user?.firstName}'s <span style={{ color: "rgba(76, 203, 31, 1)" }}>User Manual</span></h4>
                                            <img src={user_manual_icon.banner_icon} alt="user_manual_banner" className="user_manual_banner_icon" />
                                        </div>
                                        {
                                            user_two_data?.user_manual_data?.map((i, index) => (
                                                // component to handle the answer card view
                                                <UserManualResponseContainer {...props} answer={i?.node} compare_data={user_one_data?.user_manual_data} can_edit={false} question_no={index + 1} className={(user_one_data && user_two_data) ? "um-response-card-height" : ""} />
                                            ))
                                        }
                                    </div>
                                )
                            }

                        </div>
                    )
                }
            </div>
        </Spin>
    )
}

export default TeamUserManualView
import React from "react";
import MyProfileView from "../components/my-profile-view";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { Capitalize, getIntFromString, getSurveyBase64StringFromInt, globalPermissionValidator } from "modules/look";
import { personality_questions, diversity_questions_for_in, PERSONALITY_PART_1, STRENGTH_PART_1, VALUE_SUERVEY_PART_1, SURVEY_DIVERSITY_PROFILE, available_personality } from "../../../../config";
import { USER_BASIC_DETAIL } from "../graphql/profile.gql";
import { my_profile_permissions } from "Permissions/myprofile.permission";
import { withEditProfile } from "modules/user/containers/UserOperations";
import { message } from "antd";
import { GET_USER_IMPLICIT } from "modules/user/graphql/GetProfileImplicit.gql";
import moment from "moment-timezone";
import { setItem } from '../../../core';

const MyProfileContainer = (props) => {
    const { me, client, userPermission,editProfile } = props
    const [permission, SetPermission] = React.useState()
    const [profile_detail, SetProfileDetail] = React.useState()
    const [getProfileImplicit,setGetProfileImplicit]=React.useState()
    // eslint-disable-next-line no-unused-vars
    const [page_loading,setPageLoading]=React.useState(false)
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(
                my_profile_permissions,
                userPermission);
            SetPermission(permission) 
            
        }
    }, [userPermission])

    React.useEffect(() => {
        if (permission?.required_permission) {
            getProfileData()
            getProfile(me?.profile?.id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission])

    const getProfileData = async () => {
        try {
            setPageLoading(true)
            const { data } = await client.query({
                query: USER_BASIC_DETAIL,
                fetchPolicy: 'network-only',
                variables: {
                    personality_survey: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
                    strength_survey: getSurveyBase64StringFromInt(STRENGTH_PART_1),
                    value_survey: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
                    personality_questions,
                    diversity_survey: getSurveyBase64StringFromInt(SURVEY_DIVERSITY_PROFILE),
                    diversity_questions: [diversity_questions_for_in.YearsOfExperience]
                },
            });
            if (data) {

                let profile = data?.me
                let emp = profile?.employee
                let role = emp?.memberSet?.edges[0]?.node?.role
                let details = {
                    employee_id: emp?.id,
                    user: {
                        id: profile?.id,
                        firstName: Capitalize(profile?.firstName),
                        lastName: Capitalize(profile?.lastName),
                        email: profile?.name,
                        profile: profile?.profile
                    },
                    role,
                    job_family: role?.jobfamilySet?.edges[0]?.node,
                    strength: profile?.strength?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node),
                    value: profile?.value?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node),
                }
                let p = profile?.personality?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => {
                    return {
                        ...node,
                        question: getIntFromString(node?.question?.id)
                    }
                })
                let personality = ''

                personality_questions.forEach(q => {
                    let ans = p?.find(a => a.question === q)
                    if (ans) {
                        personality = personality + (ans?.answer === "Intuitive" ? "N" : ans?.answer === "Observant" ? "S" : ans?.answer?.charAt(0))
                    }
                })

                personality = personality?.slice(0, 4)
                if (available_personality?.includes(personality)) {
                    details['personality'] = personality
                }
                // if (profile?.diversity) {

                //     let set = profile?.diversity?.edges[0]?.node?.answerSet?.edges?.map(({ node }) => node)

                //     let diversity_list = set?.map(i => {
                //         return {
                //             question_id: getIntFromString(i?.question?.id),
                //             answer: i?.answer
                //         }
                //     })

                //     let user_experinece = diversity_list?.find(i => i?.question_id == diversity_questions_for_in.YearsOfExperience)
                //     if (me?.profile?.workExperience) {
                //         details['experinece'] = me?.profile?.workExperience
                //     }
                // }
                if (me?.profile?.workExperience) {
                    details['experinece'] = me?.profile?.workExperience
                }
                SetProfileDetail(details) 
                setPageLoading(false)
            }
        } catch (error) {

        }
    }
    const getProfile= async(id)=>{
        const { data} = await client.query({
          query: GET_USER_IMPLICIT,
          variables: {id:id},
          fetchPolicy: 'network-only'
      });
      if(data?.profile){
        setGetProfileImplicit(data?.profile)
      }
      }
    const updateProfile =async(value)=>{
        try {
            const response = await editProfile({ id: getIntFromString(getProfileImplicit?.id), ...value });
            if (response){
                if(value?.timeZone){
                    setItem("userRequestedTimezone", value?.timeZone)
                    moment.tz.setDefault(value?.timeZone);
                    message.success('Your Time Zone has been updated')
                }
                else{
                    message.success('Profile Image updated Successfully!!');
                    
                }
                getProfileData()
            } 
            else message.error('Failed! try again.');
            return response;
          } catch (e) {
            throw new Error(e.message);
          }
    }

    return (
        <>
            {
               (permission && permission?.required_permission) && (
                    <MyProfileView 
                    {...props} 
                    profile_detail={profile_detail}
                     permission={permission}
                    //  page_loading={page_loading}
                     onSubmit={(value)=>updateProfile(value)}
                     changeTimezone={(value)=>updateProfile(value)} />
               )
            }
            
        </>
    )
}

export default compose(withApollo,withEditProfile)(MyProfileContainer)
import React, { useEffect, useState } from "react";
import { Avatar, List, Spin } from 'antd';
import '../css/engagementBoardView.css'
import kudos_user_route from "../route";
import { Capitalize, getIntFromString } from "modules/look";

const EngagementBoardView = (props) => {
  const { engagement_board_data, engagement_loading,navigateRoute } = props;
  const [reciever, setReciever] = useState([]);
  const [sender, SetSender] = useState([]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (engagement_board_data) {
      setReciever(engagement_board_data.top_recievers);
      SetSender(engagement_board_data.top_issues);
    }
  })

  return (
    <div className="kudos-card-root">
      <div className="kudos-new-card" style={{ minHeight: "30em" }}>
        <Spin spinning={engagement_loading}>
          <h4 className="your-kudos-barde-label" style={{ fontSize: "1.25em" }}>ENGAGEMENT BOARD</h4>
          <h5 className="top-receiver">Top Receiver</h5>
          <div style={{minHeight:"5em"}}>
            {engagement_board_data?.top_recievers?.length>0&&<List
              itemLayout="horizontal"
              dataSource={reciever}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    style={{display:"flex",flexDirection:"row",justifyContent:"start",alignItems: "center"}}
                    avatar={<Avatar src={item?.details?.profile?.profileImage}  className="engagement-board-avatar">
                      {item?.details?.profile?.profileImage ? item?.details?.profile?.profileImage : item?.details?.firstName.charAt(0).toUpperCase()}
                    </Avatar>}
                    title={<h4 className="engagement-board-title" onClick={() => navigateRoute( `${kudos_user_route?.user_detail.replace( ':id', getIntFromString( item?.details?.id ) )}?kudos` )} >{Capitalize(item?.details?.firstName)} {Capitalize(item?.details?.lastName)}</h4>}
                    description={<h4 className="kudos-card-user-role">{item?.details?.role}</h4>}
                  />
                </List.Item>
              )}
            />}
          </div>
          
          <h5 className="top-sender">Top Senders</h5>
          <div style={{minHeight:"5em"}}>
            {engagement_board_data?.top_issues?.length>0&&<List
              itemLayout="horizontal"
              dataSource={sender}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    style={{display:"flex",flexDirection:"row",justifyContent:"start",alignItems: "center"}}
                    avatar={<Avatar src={item?.details?.profile?.profileImage}  className="engagement-board-avatar" >
                      {item.details.profile.profileImage ? item?.details?.profile?.profileImage : item.details.firstName.charAt(0).toUpperCase()}
                    </Avatar>}
                    title={<h4 className="engagement-board-title" style={{cursor:"default"}}>{Capitalize(item?.details?.firstName)} {Capitalize(item?.details?.lastName)}</h4>}
                    description={<h4 className="kudos-card-user-role">{item?.details?.role}</h4>}
                  />
                </List.Item>
              )}
            />}
          </div>
        </Spin>
      </div>
    </div>
  )
}

export default EngagementBoardView;
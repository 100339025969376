// @ts-nocheck
import React from 'react'
import { Bar } from 'react-chartjs-2';
import { ReactMarkdown } from '../../look';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Col } from 'antd';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const ReportChart = (props) => {
    const { chartLables, labelCount, title } = props
    var regex = /(<([^>]+)>)/ig;    
    const titleName =title.replace(regex, "");
    let finaltitle=titleName.replace(/[^a-z A-Z 0-9]/g, "")
    const labels = [...chartLables];
    const titleElement = document.createElement('span');
    titleElement.innerHTML = finaltitle

    const getLableCounts = () => {
        return chartLables.map(label => {
            return labelCount[label]
        })
    }

    const chartData = {
        labels,
        datasets: [{
            label: titleElement.innerText || titleElement.textContent,
            data: getLableCounts(),
            backgroundColor: '#B0CB1F',
        }]
    };
    const options = {
        responsive: true,
        // maintainAspectRatio: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItems) {
                        return tooltipItems.label +" : "+ tooltipItems.formattedValue
                    },
                    title: function (tooltipItems) {
                        return tooltipItems[0].dataset.label
                    }
                }
            },
            legend: {
                display:false
            },
            title: {
                display: true,
                position: 'top',
            }
        },
    };
    
    return (
        <>
            <Col span={24} align="right">
                {/* <Button shape="circle"  style={{cursor:"pointer"}}onClick={close}>
                    <CloseOutlined />
                </Button> */}
            </Col>
            <div align="center">
                <ReactMarkdown>{finaltitle}</ReactMarkdown>
            </div>
            <Bar data={chartData} options={options}/>
        </>
    )
}
export default ReportChart
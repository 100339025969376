//@ts-ignore
import React from "react";
import { Row, Button,message,Dropdown,Menu,Spin,Form,Tooltip } from "antd";
import InfoCircle from '../../assets/InfoCircle.svg'
import upArrow from '../../assets/up-arrow.svg'
import { getIntFromString } from 'modules/layout/Layout';
import NumberInputField from '../../Objective-keyresult/formComponents/Number-input-field'
import Weight_icon from '../../assets/weight-icon.svg'
import gear_active from '../../assets/gear-active-icon.svg'
import gear_disabled from '../../assets/gear_disabled.svg'
import Reset_button from '../../assets/reset-button.svg'
import gql from 'graphql-tag';
import {useMutation } from '@apollo/client';
import moment from 'moment-timezone';
const AddWeight =(props)=>{
    const {getOKRList,allokrweights,loading,setloading,setokrweights,okrDedline,tooltipTitle}=props
    const [OpenMenu,setOpenMenu]=React.useState(false)
    const [selectedItems,setSelectedItems]=React.useState([])
    const [form] = Form.useForm();
    // eslint-disable-next-line no-unused-vars
    const [selectedFilter,setFilter]=React.useState([])
    const filterList=[{key:'Weight',type:"Weight"}]
    const [contributor,setContributor]=React.useState()
    
    
    const CREATE_CONTRIBUTOR_DYNAMIC =gql`
    mutation updateContributor {
      ${contributor
        ?.map(
          (item,index) =>
           `update${index}: updateContributor(id:${item?.id},weight:"${item?.weight}") {
            contributor{
              id
            }
           }
         `
        )
      .join('')}
     }
    `;
  
    const [contributorDefinition] = useMutation(CREATE_CONTRIBUTOR_DYNAMIC);

    React.useEffect(()=>{
        if(contributor && contributor?.length){
            UpdateContributorMutation()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[contributor])

    const UpdateContributorMutation=async()=>{
        setloading(true)
        const response = await contributorDefinition()
        // new to do send back the response as props
        if(response){
            setloading(false)
            setSelectedItems([])
            setokrweights(null)
            setOpenMenu(false)
        }else{
            setloading(false)
        }
        
    }

    React.useEffect(()=>{
        if(OpenMenu){
            getOKRList() 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[OpenMenu])

    React.useEffect(()=>{
        if(allokrweights){
            let list = allokrweights?.edges?.map(({node})=>{
                return {
                    id:node?.id,
                    title:node?.okr?.title,
                    okrId:node?.okr?.id,
                    role:node?.role,
                    weight:node?.weight||0
                }
            })
            setSelectedItems(list)
            setloading(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[allokrweights])
    

    const onSave=()=>{
        let values = form.getFieldsValue()
        let noData = Object.values(values).filter((item)=>item===undefined || item===null)
        if(noData?.length){
            message.error('fill in the data')
            return
        }
        let totalweight = Object.values(values)?.reduce((a, b) => a + b, 0)
        if(Math.ceil(totalweight)!==100){
            message.error('Total weight should be 100%') 
            return
        }
        let list = Object.keys(values)?.map(item=>{
            return {
                id:getIntFromString(item),
                role:selectedItems.find(rol=>rol?.id===item)?.role,
                weight:values[item]
            }
        })
        setContributor(list)
        // setOpenMenu(false)
    }
    const RestWeight=()=>{
        if(!selectedItems?.length){
            return
        }
        let weight = 100/selectedItems?.length
        let initial = {}
        selectedItems.forEach(item=>{
            initial[item?.id]=weight
        })
        form.setFieldsValue(initial)
    }

    const FilterItem =()=> (
        <Menu style={{maxHeight:'300px',overflow:'auto'}}>
            {
                filterList && (
                    filterList?.map((data)=>
                <Menu.Item key={data?.key} onClick={()=>{setFilter(data?.key)}}>
                    <div className="dropdown-label" style={{disply:'flex',flexDirection:"row",justifyContent:"center",alignItems:"center",gap:'5px',flexWrap: 'nowrap'}}onClick={()=>setOpenMenu(true)} >
                        <img src={Weight_icon} alt="" style={{width:"18px",marginRight:"5px",marginBottom:"5px"}}/>{data?.type}
                        </div>
                </Menu.Item>
                )
                )
            }
        </Menu>
    );

    const getInitialValues=()=>{
        let initial = {}
        selectedItems.forEach(item=>{
            initial[item?.id]=item?.weight
        })
        return initial
    }

    const WegihtSection =()=> (
        <div>
            {OpenMenu&&
          
                   <div className='custom-menu' style={{minWidth:"310px"}}>
                <Row align='middle' style={{flexDirection:"column",padding:"20px",position:'relative'}}>
                    <h4 className='menu-head'>Weights<Tooltip  title={tooltipTitle}><img src={InfoCircle} style={{marginLeft:"10px"}} alt="" /></Tooltip></h4>
                    <img src={upArrow} alt="" style={{position:"absolute",top:'10px',right:"10px",cursor:"pointer"}} onClick={()=>{setokrweights(null);setOpenMenu(false)}} />
                    <Button className="reset-btn" onClick={()=>!loading?RestWeight():''}><img src={Reset_button } alt="" style={{marginRight:"5px"}}/> Reset</Button>
                    <Spin spinning={loading}>
                    <div className='select-content-div'>
                      {
                        selectedItems?.length?
                        <Form
                        initialValues={getInitialValues()}
                        scrollToFirstError={true}
                        className="formLabel"
                        form={form}> 
                        {selectedItems?.length>0 && selectedItems?.map((value,index)=>(
                            <div className='item-div'>
                                <div className='selected-item'>
                                    <p style={{overflow:"hidden",textOverflow:"ellipsis"}}>{value?.title}</p>
                                    {/* <img src={CloseIcon} alt="" style={{cursor:'pointer'}} onClick={()=>remove(value?.id)}/> */}
                                </div>
                                <NumberInputField className="progress-input" style={{width:"50px",height:"40px"}} type='number' maxValue={100} name={value?.id}></NumberInputField>
                            </div>
                        ))}
                    </Form>:''
                      }
                    </div>
                    </Spin>
                </Row>
                <Row className='bottom-div' align='middle'>
                    <Button className={!selectedItems?.length || loading?'assign-btn-disable':'assign-btn'} disabled={!selectedItems?.length || loading} onClick={()=>onSave()}>Save</Button>
                </Row>
                </div>
        
            }
        </div>
    );
    return (
        <>
        {moment(okrDedline?.correctionDeadline).isBefore(moment())?<img src={gear_disabled} alt="" style={{cursor:"pointer"}}/>:
         <>
         {!OpenMenu&&<Dropdown overlay={FilterItem}  placement="bottomLeft" className="input-filter-dynamic" >
             <img  src={gear_active } alt="" style={{cursor:"pointer"}}/>
         </Dropdown>}
         {OpenMenu ? (<div className='overlay-dynamic-island' onClick={() => setOpenMenu(false)} />) : null}
         {OpenMenu&&<Dropdown overlay={WegihtSection} visible={OpenMenu}  placement="bottomLeft" className="input-filter-dynamic" >
             <img src={gear_active } alt="" style={{cursor:"pointer",display:"hide"}}/>
         </Dropdown>}
        </>
        }
       
        </>
    )
}
export default AddWeight
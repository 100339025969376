/* eslint-disable no-unused-vars */
// @ts-nocheck
import React from "react";
import { Row, Col, Button, Spin, Form,Switch, Tooltip } from "antd";
import { MetaTags, getIntFromString, scrolltoview, withMarkdownFieldValue, placeholderTextForCkeditor,ToolTipTitle } from "../../look";
import ReactMarkdown from '../../look/components/ReactMarkdown';
import CKEditorFieldOneOnOne from "modules/look/components/form-components/CKEditor-one-on-one";
import Agenda_close from '../../assets/agenda-close.svg'
import green_badge from '../../assets/green_badge.svg'
import info_button from '../../assets/info-button.svg'
import GivenKudosEditFeedback from "./GivenKudosEditFeedback";
import radio_inactive from '../../assets/readiness-radio-inactive.svg'
import radio_active from '../../assets/readiness-radio-active.svg'
const EditFeedbackView = props => {
  const { SurveyById, feedbackById, setGiveKudosPopUpVisible, setFeedbackEditModalVisible, givenKudos, setGivenKudos,
    feedbackPermission, setFeedbackPreviewDisplay, setFeedbackDetailsLoading, setFeedbackDetails,setFeedbackDetailSide,
    feedBackType, setokrid, answersList, questionList, feedBackTypeEnum, setAnswers,handleModalClosureEditFeedback,
    setFeedBackType, feedback_detail_ref, setquestionList, setloading, loading, handleValidation, setFeedbackPreviewVisible, setFeedbackReceipient} = props


  const [keyResultsActive, setKeyResultActive] = React.useState(false);

  const [recipientName, setrecipientName] = React.useState();
  const [form] = Form.useForm();

  
  
  const [fillInOption, setfillInOption] = React.useState(false);
  
  
  const [initialdataSet, setinitialdataSet] = React.useState(false);
  
  
  const [questionIds, setQuestionIDs] = React.useState([]);
  const [selectedTabValue, setSelectedTabValue] = React.useState(0)
  const [tabArray, setTabArray] = React.useState([
    { id: 0, value: 'C', active: false },
    { id: 1, value: 'L', active: false },
    { id: 2, value: 'A', active: false },
    { id: 3, value: 'P', active: false },
    { id: String(feedBackType) === '1' ? 4 : 4, value: 'S', active: false }])

  
 

  React.useEffect(() => {
    if (feedbackById && !initialdataSet) {
     
      setFeedbackReceipient(feedbackById?.feedbackTo?.user?.employee?.id)
      setrecipientName(`${feedbackById?.feedbackTo?.user?.firstName} ${feedbackById?.feedbackTo?.user?.lastName}`)
      if (feedbackById?.okr) {
        setKeyResultActive(true)
        setokrid(feedbackById?.okr?.id)
      }
      if (feedbackById?.feedbackType === 'MOTIVATIONAL_RECOGNITION') {
        setFeedBackType("1")
      }
      else if (feedbackById?.feedbackType === 'DEVELOPMENTAL_CONSTRUCTIVE') {
        setFeedBackType("2")
      }
      let answers = []
      let ids = [];
      let data;

      if (!answersList?.length) {

        if (feedbackById?.response?.answerSet?.edges?.length) {
          feedbackById.response.answerSet.edges.forEach(item => {
            data = {
              questionId: getIntFromString(item?.node?.question?.id),
              answer: item?.node?.answer
            }
            answers.push(data)
            ids.push(getIntFromString(item?.node?.question?.id))
          })
        }
        ids = ids.sort()
        setQuestionIDs(ids)
        setAnswers(answers)

      }

      setinitialdataSet(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackById, initialdataSet])


  React.useEffect(() => {
    if (!givenKudos && feedbackById && feedbackById?.badgeAward?.edges?.length) {
      let selectedBadge = feedbackById?.badgeAward?.edges[0]?.node
      let badgeAwardData = {
        badgeId: getIntFromString(selectedBadge?.badge?.id),
        evidence: selectedBadge?.badge?.title,
        image: selectedBadge?.badge?.image,
        title: selectedBadge?.badge?.title,
        behaviouralDescription: selectedBadge?.badge?.behaviouralDescription
      }
      setGivenKudos(badgeAwardData)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackById])

  // do not modify this useEffect
  React.useEffect(() => {
    if (feedbackById) {
      feedback_detail_ref.current = feedbackById
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackById])

  React.useEffect(() => {
    if (SurveyById) {
      let list = SurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges?.map(({ node }) => node)
      setquestionList(list)
      setloading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SurveyById])

  const submitAnswer = (data, value) => {

    let feed = withMarkdownFieldValue("answer", { answer: value })
    let answerSet = {
      questionId: getIntFromString(data?.id),
      ...feed
    }
    let tempAnswer = [].concat(answersList)
    let previous = tempAnswer.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
    if (previous) {
      let index = tempAnswer.indexOf(previous)
      tempAnswer.splice(index, 1)
      tempAnswer.push(answerSet)
    }
    else {
      tempAnswer.push(answerSet)
    }

    setAnswers(tempAnswer)

  }

  const checkAnswerText = (index, questionId) => {
    let indexOfQuestion = answersList?.map((node) => node?.questionId)?.indexOf(questionId)
    if (indexOfQuestion) {
      let ck_editor_to_check = answersList[indexOfQuestion]?.answer;
      if (ck_editor_to_check?.trim() === "") {
        return true
      } else {
        return false
      }
    }

  }


  const handleFeedbackPreview = () => {

    
    setFeedbackPreviewDisplay(true)
    setFeedbackEditModalVisible(false)
    setFeedbackPreviewVisible(true);
    setFeedbackDetailsLoading(true);
    setFeedbackDetailSide('submitted')


    let answersForPreview = answersList?.map((answer) => {
      let indexOfQuestion = questionList?.findIndex((question) => getIntFromString(question?.id) === answer?.questionId)
      return { node: { ...answer, question: { questionText: questionList[indexOfQuestion]?.questionText } } }
    })



    let preview = {
      badgeAward: { edges: givenKudos ? [{ node: { badge: givenKudos } }] : [] },
      detailFillIn: fillInOption,
      feedbackFrom: feedbackById?.feedbackFrom,
      feedbackTo: feedbackById?.feedbackTo,
      feedbackType:feedBackTypeEnum[feedBackType],
      okr: feedbackById?.okr,
      response: {
        answerSet: { edges: answersForPreview }
      }
    }



    setFeedbackDetails(preview);
    setFeedbackDetailsLoading(false);

  }

 


  const showQuestion = (data) => {
   
    let createCustomID = 'fb_' + getIntFromString(data?.id)
    let previous = answersList?.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
    if (!data?.questionAddress) {
      return (
        <Col xs={12} sm={12} md={24} lg={24} style={{ margin: 'auto' }} >
          <Row style={{ marginTop: '15px' }}>

            <h4 className='feedback-pop-up-form-title' style={{ margin: "0px 0px 6px 15px" }}>
              {data?.required && (
                <span style={{ color: '#ff4d4f', marginBottom: '11px' }}>*</span>
              )}
              <ReactMarkdown >{data?.questionText}</ReactMarkdown>
            </h4>

          </Row>
          <div id={createCustomID} className="feedback-pop-up-ck-editor" style={{ paddingRight: '20px', border: " 1px solid #D9D9D9", borderRadius: "8px" }} onClick={() => { scrolltoview(createCustomID) }}>

            <CKEditorFieldOneOnOne
              key={data?.id}
              editorPlaceholder={placeholderTextForCkeditor(data)}
              initialValue={previous?.answer}
              changeHandler={(e) => { submitAnswer(data, e) }}
            />
          </div>

        </Col>
      )
    }
    else {
      let validations = data?.questionAddress?.split(':')
      if (validations?.includes(feedBackType)) {
        return (
          <Col span={24}>
            <Row style={{ marginTop: '15px' }}>

              <h4 className='feedback-pop-up-form-title' style={{ margin: 0, marginLeft: '15px', marginBottom: '6px' }}>
                {data?.required && (
                  <span style={{ color: '#ff4d4f', marginBottom: '20px' }}>*</span>
                )}
                <ReactMarkdown >{data?.questionText}</ReactMarkdown>
              </h4>


            </Row>
            <div id={createCustomID} className="feedback-pop-up-ck-editor" style={{ paddingRight: '20px', border: " 1px solid #D9D9D9", borderRadius: "8px" }} onClick={() => { scrolltoview(createCustomID) }}>

              <CKEditorFieldOneOnOne
                key={data?.id}
                editorPlaceholder={placeholderTextForCkeditor(data)}
                initialValue={previous?.answer}
                changeHandler={(e) => { submitAnswer(data, e) }}
              />
            </div>

          </Col>
        )
      }
    }

  }

  const WithNoOption = (
    <Col span={24}>
      {
        questionList?.length && (
          showQuestion(questionList[selectedTabValue])
        )
      }

    </Col>
  )
  const disabledButon = (value, array) => {
    let filterList = array?.find((item) => item?.id === value)
    let questioId;
    if (!questionIds[value]) {
      questioId = questionIds[value - 1];
    } else {
      questioId = questionIds[value];
    }
    let isAnswerEmpty = checkAnswerText(value, questioId)
    return !filterList?.active && isAnswerEmpty;
  }

  return (
    <>
      <>
        <MetaTags
          title="Update Feedback"
          description="This is Update Feedback page."
        />


         {/* Header of pop-up */}
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <div className="feedback-pop-up-header poppins">Edit Feedback</div>
              <img src={Agenda_close} style={{ cursor: 'pointer' }} onClick={(e) => handleModalClosureEditFeedback()} alt="" />
          </div>

          <div style={{ width:'100%',minHeight: '500px',display:'flex',flexDirection:"column",gap:"1em",justifyContent:"center",alignItems:"center"}}>
          <Spin spinning={loading}>
            {
              SurveyById && (
                <div>
                  <Form
                    form={form}
                    name={"value"}
                    style={{width:"100%",overflow:'auto',maxHeight:"83vh"}}
                  // onFinish={() => handleValidation()}
                  >
                    {/* Edit feedback elements displaying info wrapper */}
                    <div style={{ width: "100%",display:'flex',flexDirection:"column",gap:"1em",marginTop:"1em"}}>
                      <div className="feedback-type-switcher">
                          <div className={feedBackType==="1"?"feedback-type-content-container-active":"feedback-type-content-container"}
                          style={{cursor: 'not-allowed'}}>
                              <h4>Motivational/Recognition</h4>
                          </div>
                          <div className={feedBackType==="2"?"feedback-type-content-container-active":"feedback-type-content-container"}
                          style={{cursor: 'not-allowed'}}>
                              <h4>Developmental/Constructive</h4>
                          </div>
                      </div>
                      {/* Receipient of feedback */}
                      <Row justify="space-between" >
                        <Col span={24}>
                          <p className="fw-500-feedback feedback-input-box-label poppins" style={{ fontSize: '15px' }}>Recipient</p>
                          <div style={{ cursor: 'not-allowed',width:"100%" }} className="receipient-feedback-title poppins">{recipientName}</div>
                        </Col>
                      </Row>
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em", flexWrap: "wrap" }}>
                          {
                            feedbackPermission?.okr_permission&&feedbackById?.okr?
                                <div>
                                  <p className="feedback-input-box-label poppins fw-500-feedback" style={{ fontSize: '1em',margin:"0px" }}>OKR</p>
                                  <div className="poppins okr-title-feedback" >{feedbackById?.okr?.title}</div>
                                </div>:
                              <div style={{display:"flex",flexDirection:"row",justifyContent:"start",alignItems:"center",gap:"1em"}}>
                                  {feedbackById?.okr?.id?
                                  <img src={radio_active} alt="radio_active" style={{cursor:"not-allowed"}}/>
                                  :
                                  <img src={radio_inactive} alt="radio_inactive" style={{cursor:"not-allowed"}}/>
                                  }
                                  <p className="poppins" style={{margin:"0px"}}>OKR</p>
                              </div>
                            }
                          {
                            givenKudos && feedbackPermission?.kudos_permission ? <GivenKudosEditFeedback deleteUpdateKudos={(e) => e && handleValidation('kudos')} setGivenKudos={setGivenKudos} fromGiveFeedback={true} givenKudos={givenKudos} {...props} />
                              :
                              feedBackType === "1" && feedbackPermission?.kudos_permission ?
                                <Button onClick={(e) => { setFeedbackEditModalVisible(false); setGiveKudosPopUpVisible(true) }} className='give-kudos-button'><div style={{ display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:".5em",padding:".5em" }} ><div className='poppins'> Give kudos</div> <img src={green_badge} alt="badge"/></div> </Button>
                                : null
                          }
                      </div>
                    </div>
                    {
                      feedBackType && (
                        <div style={{ marginTop: '20px', width: "100%" }}>
                          {
                            !fillInOption && (
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em", flexWrap: "wrap",width:"100%" }}>
                               <div style={{display:"flex",flexDrection:"row", gap: "1em", flexWrap: "nowrap"}}>
                                  {tabArray?.length && tabArray?.map((item) => (
                                    <Button className={item?.id === selectedTabValue ? "feedback-tab-btn-focus" : !item?.active ? "feedback-tab-btn-active" : "feedback-tab-btn"} disabled={feedBackType === '1' && item?.value === 'P'} onClick={() => setSelectedTabValue(item?.id)}>{item?.value}</Button>
                                  ))}
                                  <Tooltip title={ToolTipTitle} placement='topLeft'  overlayClassName='feedback-info-tooltip'>
                                    <img style={{ position: 'relative', top: '-24px', left: '-24px', width: '15px', zIndex: '1500' }} src={info_button} alt="info"/>
                                  </Tooltip>
                                </div>
                                <Row align="middle">
                                <p style={{ marginTop: '8px', marginLeft: '20px' }} className="feedback-form-title poppins">Detailed</p>
                                <Switch style={{ marginLeft: '14px' }} disabled={true} defaultValue={fillInOption} />
                              </Row>
                              </div>
                            )
                          }
                          <div id="surveyComponent" style={{ width: "100%" }}>{WithNoOption}</div>
                        </div>
                      )
                    }

                    {/* </Col> */}
                    <div align="center">
                      {selectedTabValue === questionList?.length - 1 &&
                        <Row justify="center" align="middle" style={{ gap: '20px', marginTop: '20px' }}>
                          <Button style={{ padding: '7px 21px' }} className="feedback-save-btn-modal" onClick={(e) => handleFeedbackPreview()} disabled={!fillInOption ? disabledButon(selectedTabValue, tabArray) : false} >Preview</Button>
                        </Row>
                      }
                      {/* {selectedTabValue === questionList?.length - 1 && <Button className="feedback-save-btn-modal" htmlType="submit"  style={{ marginTop: '30px' }}>Update</Button>} */}
                      {selectedTabValue !== questionList?.length - 1 &&
                        <Row justify="center" align="center" style={{ gap: "10px" }}>
                          <Button className="feedback-cancel-btn-modal" type="primary" style={{ marginTop: '30px' }} onClick={() => handleModalClosureEditFeedback() }>Cancel</Button>
                          <Button className="feedback-save-btn-modal" disabled={disabledButon(selectedTabValue, tabArray)} style={{ marginTop: '30px' }} onClick={() => setSelectedTabValue((feedBackType === '1' && selectedTabValue === 2) ? selectedTabValue + 2 : selectedTabValue + 1)}>Next</Button>
                        </Row>}
                    </div>
                  </Form>
                </div>
              )
            }

          </Spin>
        </div>
      </>
    </>
  );
};

export default EditFeedbackView;


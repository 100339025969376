import gql from "graphql-tag";
// import { DISCARD_TYPE } from "./DiscardRequestList.gql";
export const DISCARD_REQUEST_LIST_SUBSCRIPTION = gql`
subscription discardRequestSubscription {
    discardRequestSubscription {
        mutation
        discardRequest{
            id
            createdAt
            status
            initiator{
                id
                user{
                    id
                    firstName
                    lastName
                }
            }
            okr{
                id
                title
                okrType
            }
                }
    }
}
`
import {Dropdown, Menu} from "antd";
import React from "react";
import { compose } from '../../core';

import drp_arrow from '../../assets/nav_arrow_down.svg'
import { getTotalPendingCount } from "../container/objective-keyresult-operations";
const OKRVerticalBar = (props) => {
  const { discardCount, AssigneCount, CollabCount, current_tab, navigateRoute } = props;
  const [requestCount, setRequestCount] = React.useState({});
  


  React.useEffect(() => {

    let result
    if (discardCount) {
      let mergeDataForCeo = discardCount?.employee?.orgCeoEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node?.initiatedDiscardRequestSet))
      let mergeDataForTeam = discardCount?.employee?.teamManagerEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node?.initiatedDiscardRequestSet))
      let mergeDataForVertical = discardCount?.employee?.verticalHeadEmployee?.edges?.map(({ node }) => node?.employees?.edges?.map(({ node }) => node?.initiatedDiscardRequestSet))
      let finalDataMerge = [].concat.apply([], [...mergeDataForCeo, ...mergeDataForTeam, ...mergeDataForVertical])
      var setObj = new Set();
      let finalEdges = [].concat.apply([], [...finalDataMerge?.map((item) => item?.edges?.map(({ node }) => node))])
      result = finalEdges.reduce((acc, item) => {
        if (!setObj.has(item?.okr?.id)) {
          setObj.add(item?.okr?.id)
          acc.push(item)
        }
        return acc;
      }, [])
    }
    let requestCount ={
      discard:result?.length,
      collaboration:CollabCount?.totalCount,
      assignment:AssigneCount?.totalCount
    }
    setRequestCount(requestCount)
  }, [discardCount, AssigneCount, CollabCount])

  const NavigateTo = (type) => {
    if (type === 'home') {
      navigateRoute(`/role/objective-keyresult/home`)
    }
    else if (type === 'okrdashboard') {
      navigateRoute(`/role/objective-keyresult/okrdashboard`)
    }
  }

  const sidebar_tab = [
    {
      key: 'home',
      label: 'Objective key results',
    },
    {
      key: 'okrdashboard',
      label: 'Dashboard',
    },
    {
      key: 'request',
      label: 'Request',
      child_menu: [
        {
          key: 'assignment', 
          label: 'Assignment',
          parent: 'request',
          child: true,
          show_count:true
        },
        {
          key: 'collaboration', 
          label: 'Collaboration',
          parent: 'request',
          child: true,
          show_count:true
        },
        {
          key: 'discard', 
          label: 'Discard',
          parent: 'request',
          child: true,
          show_count:true
        }
      ]
    }
  ]

  const onChangeMultiMenu = (type) => {
    if (type === 'discard') {
      navigateRoute(`/role/objective-keyresult/discard`)
    }
    else if(type === 'assignment'){
      navigateRoute(`/role/objective-keyresult/discard?tab=assignment`)
    }
    else if(type === 'collaboration'){
      navigateRoute(`/role/objective-keyresult/discard?tab=collaboration`)
    }
  }

  const Relatedmenu = (sidebar) => {
    return (
      <Menu className='okr-main-menu'>
        {
          sidebar?.child_menu.map((data) => (
              <Menu.Item className='okr-menu-label' onClick={() => { onChangeMultiMenu(data?.key) }}>
                <span style={{marginLeft:"15px"}}>{data?.label}</span> <span>{data?.show_count?`${requestCount[data?.key] || 0}`:''}</span>
              </Menu.Item>

          ))
        }
      </Menu>
    )
  }

  return (
    <>
      <div className='rd-container rd-sidebar-view'>
        {
          sidebar_tab?.map(sidebar => (

            !sidebar?.child_menu?.length ?
              <div className={`rd-sidebar-normal ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar' : ''}`} onClick={() => NavigateTo(sidebar?.key)}>
                <h4 className={`rd-sidebar-label ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar-label' : ''}`}>{sidebar?.label}</h4>
              </div> :

              <Dropdown overlay={Relatedmenu(sidebar)} overlayClassName='content-dropdown' style={{ width: "100%" }} 
              >
                <div className={`rd-sidebar-normal ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar' : ''}`}>
                  <h4 className={`rd-sidebar-label ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar-label' : ''}`}>{sidebar?.label}</h4>
                  <img src={drp_arrow} className="tab-exp-arrow" alt="" />
                </div>
              </Dropdown>
          ))
        }
        <div className='border-line'>

        </div>
      </div>
    </>
  );
};

export default compose(getTotalPendingCount)(OKRVerticalBar);

import React from "react";
import CreateInvitationMultippleEmployeeView from "../component/CreateInvitationForMultippleEmployeeView";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { message } from 'antd'
import survey_module_route from "modules/survey-module/routes";
import { getIntFromString } from "modules/look";
const CreateInvitationForMultippleEmployeeContainer = ( props ) => {
    const { navigateRoute, me } = props
    const [ mutationData, setmutationData ] = React.useState();
    const [ assignloading, setassignLoading ] = React.useState( false )
    const [ error_on_mutation, setErrorOnMutation ] = React.useState( [] )
    const onCancel = () => {
        let url = survey_module_route.survey_360?.replace( ":surveyid", props?.match?.params?.surveyid )?.replace( ":responseid", props?.match?.params?.responseid )
        navigateRoute( url )
    }

    const INVITE_DYNAMIC = gql`
        mutation createSourceResponse {
            ${mutationData
            ?.map(
                ( item, index ) =>
                    `create${item?.node}: createSourceResponse(email:"${item?.email}"firstName:"${item?.firstname}",lastName:"${item?.firstname}",respondentType:"${item?.respondent}",responseSourceForId: ${props?.match?.params?.responseid},surveyId: ${props?.match?.params?.surveyid},message:"${item?.message}",invite:true,createdById: ${getIntFromString( me?.id )}) {
                    token{
                        id
                    }
                }
                `
            )
            .join( "" )}
        }
    `;

    // eslint-disable-next-line no-unused-vars
    const [ inviteEmployeeDefinition, { data, isloading, error } ] = useMutation( INVITE_DYNAMIC,{onError:(err)=>errorHandling(err)} );

    React.useEffect( () => {
        if ( mutationData && mutationData?.length ) {
            InviteEmployeeMutation()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ mutationData ] )

    const errorHandling=(err)=>{
        if(err?.graphQLErrors?.length){
            setErrorOnMutation(err?.graphQLErrors)
        }
    }

    const InviteEmployeeMutation = async () => {
        try {
            setassignLoading( true )
            const response = await inviteEmployeeDefinition()
            // new to do send back the response as props
            if ( response ) {
                setassignLoading( false )
                message.success( "Submitted successfully" )
                onCancel()
            } else {
                setassignLoading( false )
            }
        } catch ( error ) {
            message.error( "Faild to Invite" )
            setassignLoading( false )
        }
    }

    return (
        <>
            <CreateInvitationMultippleEmployeeView
                loading={assignloading}
                error_on_mutation={error_on_mutation}
                sendMultippleInvitation={( value ) => setmutationData( value )}
                cancel={() => onCancel()}
                {...props}
            />
        </>
    );
};

export default CreateInvitationForMultippleEmployeeContainer;

import React, { useEffect, useState } from 'react';
import { Form, Input, Row, Col, Select, Button } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dropdown_Arrow from '../../../assets/dropdown_Arrow.svg';
import { parseMarkDownText } from '../functions';

const FormItem = Form.Item;
const Option = Select.Option;

const SelectFieldSoul = props => {
  const {
    choices,
    updatedAns,
    onAdd,
    onEdit,
    onDelete,
    margin = true,
    otherOption = false,
    onChangeSelect,
    newTab = false,
    offset,
    onSelectChange,
    onBlur,
    onSearch,
    disabled = false,
    selectedValue,
    disabled_choice=[],
    ...rest
  } = props;
  const [value, setValue] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const ref = React.useRef(null);
  const [finalChoices, setFinalChoices] = useState(choices)

  useEffect(() => {
      let list = choices?.filter(i=>!disabled_choice?.includes(i?.value?.toLowerCase()))
      setFinalChoices(list)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled_choice])

  React.useEffect(() => {
    if (ref && ref.current && ref.current.props && ref.current.props.value !== '') {
      setValue(ref.current.props.value);
    }
  }, []);

  const choice =
    finalChoices &&
    finalChoices?.map((choice, key) => (
      <Option
        key={key}
        value={String(choice.value) === 'false' ? false : choice.value === '' ? '' : choice.value || choice.label}
      >
        {typeof choice.label === 'string' ?
          <p>
           
            <span>{parseMarkDownText(choice.label)} </span>
            {
              choice.sequence && (
                <span>({choice.sequence}%)</span>
              )
            }

          </p>
          : `${choice.label} (${choice.sequence}%)`}
      </Option>
    ));

  const otherField = (
    <Option key="other" value="">
      Other...
    </Option>
  );

  const handleChange = params => {
    if (params === '') {
      setVisible(true);
    } else {
      setVisible(false);
      onSelectChange && onSelectChange(params);
    }
  };

  const handleSelect = params => {
    setValue(params);
    onChangeSelect && onChangeSelect(params);
  };

  const extraLinkProps = newTab
    ? {
      target: '_blank'
    }
    : {};
  return (
    <div style={{ marginBottom: margin ? '2vh' : '' }}>
      <Row gutter={[15, 5]}>
        <Col span={onAdd ? (onEdit ? 18 : 21) : 24}>
          <FormItem
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, offset: margin && offset }}
            onChange={onSelectChange}
            {...rest}
          >
            <Select dropdownClassName="content-dropdown" defaultValue={selectedValue} onChange={handleChange} style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }} suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />} {...props} ref={ref} onSelect={handleSelect} onSearch={onSearch} disabled={disabled}
              // filterOption={(input, option) => option}
            >
              {otherOption ? (finalChoices?.length > 0 ? [...choice, otherField] : [otherField]) : choice}
              {/* {choice} */}
            </Select>
          </FormItem>
        </Col>
        {onEdit && (
          <Col lg={2} md={3} style={{ paddingTop: '30px' }}>
            <Button shape="circle" disabled={!value} /* style={{ marginTop: '10px' }} */>
              <Link to={`${onEdit}${value}`}>
                <EditOutlined />
              </Link>
            </Button>
          </Col>
        )}
        {onAdd && (
          <Col lg={2} md={3} style={{ paddingTop: '30px' }}>
            <Button shape="circle" type="primary" ghost /* style={{ marginTop: '10px' }} */>
              <Link to={`${onAdd}`} {...extraLinkProps}>
                <PlusOutlined />
              </Link>
            </Button>
          </Col>
        )}

      </Row>
      {visible && (
        <FormItem validateStatus='success' help='' labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset }} onChange={onSelectChange} {...rest} label={null}>
          <Input className='ca-text-input' onKeyDown={(e) => e.keyCode === 13 ? e.preventDefault() : ''} autoComplete='off' onBlur={onBlur} />
        </FormItem>
      )}
    </div>
  );
};

export default SelectFieldSoul;

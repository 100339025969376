// @ts-nocheck
import React from 'react';
import { compose, getCookieFlag, setCookieFlag } from '../../core';
import {withCreateOKR, withDeleteDiscardRequest, withDeleteOKR,withUpdateCorrectionDeadline, withUpdateOKR } from './objective-keyresult-operations';
import { withApollo } from '@apollo/client/react/hoc';
import KeyResultDetailView from '../components/KeyResultDetailView';
import { getAiTipCacheTimer, getBase64StringFromInt, getIntFromString, getOKRBase64StringFromInt, globalPermissionValidator} from 'modules/look';
import { ALL_COMPETENCY} from 'modules/role/graphql/allCompitency.gql';
import { OKR_COMPETENCIES } from '../graphql/OkrCompetencies.gql';
import { withMe } from 'modules/user/containers/UserOperations';
import { OKR_BY_ID_QUERY } from '../graphql/OKRById.gql';
import { GET_ENTITY_HEAD_ROLE } from '../graphql/ExtendCorrectionDeadline.gql';
import { USER_ROLE } from '../graphql/UserRoles.gql';
import { okr_permission } from 'Permissions/okr.permission';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { okr_competency_permission } from 'Permissions/okrCompetency.permission';
import { DISCARD_SUBSCRIPTION_OKR_DETAILS } from '../graphql/DiscardSubscriptionOkr.gql';
import { CHILD_OKR_CONTRIBUTOR_WEIGHT_QUERY } from '../graphql/ChildOKRContribution.gql';
import OkrAiTipContainer from 'modules/ai-tips/container/okr-ai-tip-container';
import { ai_trigger_content } from 'modules/ai-tips/ai-trigger-content';
const OkrKeyResultTableContainer = props => {
  const { me, client, userPermission, permissionLoader } = props
  const [loading, setloading] = React.useState(true)
  // eslint-disable-next-line no-unused-vars
  const [allMs, setallMs] = React.useState([]);
  const [withAllCompetency, setwithAllCompetency] = React.useState()
  const [okrCompetenies, setOkrCompetencies] = React.useState();
  const [entityHeadRoles, setEntityHEadRoles] = React.useState();
  const [compPermissions, setCompetencyPermissions] = React.useState(0);
  const [okrPermitted, setOkrPermitted] = React.useState(false);
  const [user_level, setUserLevel] = React.useState();
  // eslint-disable-next-line no-unused-vars
  const [comptenciesModalVisible, setCompetenciesModalVisible] = React.useState(false)
  const [okrById, setokrById] = React.useState();
  const [allokrweights, setokrweights] = React.useState()
  const [ai_trigger, SetAITrigger] = React.useState()
  const okrById_ref = React.useRef()
  React.useEffect(() => {
    if (okrById) {
      okrById_ref.current = okrById
    }
  }, [okrById])
  React.useEffect(() => {
    if (me && props.match.params && okrPermitted) {

      // getMSList({ okr_Parent: getBase64StringFromInt('okrType',props.match.params.okrId), createdBy: me?.employee?.id })
      getOkrCompetencies(props.match.params.okrId)
      getOKRWeights()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, props, okrPermitted])


  React.useEffect(() => {
    if (me && okrPermitted) {
      getUserRole()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, okrPermitted])

  const m = React.useRef(true)

  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])

  React.useEffect(() => {
    if (userPermission && !permissionLoader) {
      let okrPermission = globalPermissionValidator(okr_permission, userPermission)
      setOkrPermitted(okrPermission);
      let okrcompetencyPermission = globalPermissionValidator(okr_competency_permission, userPermission)
      setCompetencyPermissions(okrcompetencyPermission);
    }
  }, [userPermission, permissionLoader])



  const getUserRole = async () => {

    const { data } = await client.query({
      query: USER_ROLE,
      fetchPolicy: 'network-only',
    });
    if (data?.user_level) {
      setUserLevel(data?.user_level)
    }
  }




  const getOkrCompetencies = async (id) => {
    let krId = getBase64StringFromInt('okrType', id);
    const { data } = await client.query({
      query: OKR_COMPETENCIES,
      fetchPolicy: 'network-only',
      variables: { id: krId }
    });
    if (data) {
      setOkrCompetencies(data?.okrById?.okrcompetencySet?.edges);
    }
  }
  const getOKRWeights = async () => {
    const { data } = await client.query({
      query: CHILD_OKR_CONTRIBUTOR_WEIGHT_QUERY,
      fetchPolicy: 'network-only',
      variables: { okr_Parent: getBase64StringFromInt('okrType', props.match.params.okrId), createdBy: me?.employee?.id }
    });

    if (data?.contributors) {
      setokrweights(data?.contributors)
    }
  }




  const competencyList = async (search) => {

    const { data } = await client.query({
      query: ALL_COMPETENCY,
      variables: { title_Icontains: search },
      fetchPolicy: 'network-only'
    });

    if (data?.allCompetencies) {
      setwithAllCompetency(data.allCompetencies)
    }
  }
  const OKR_BY_ID = async () => {
    const { data } = await client.query({
      query: OKR_BY_ID_QUERY,
      fetchPolicy: 'network-only',
      variables: { id: getOKRBase64StringFromInt(props.match.params.okrId), createdBy: me?.employee?.id }
    });

    if (data?.okrById) {
      setokrById(data?.okrById);
      if(data?.okrById?.progress<100){
        let cache_data = JSON.stringify({
          optype: "VIEW",
          objectId: data?.okrById?.id,
          type: `okr_summary_${getIntFromString(data?.okrById?.id)}`,
          data: { progress: data?.okrById?.progress,duedate:data?.okrById?.duedate }
        })
        let cached_trigger = await getCookieFlag(`okr_summary_${getIntFromString(data?.okrById?.id)}_${getIntFromString(me?.id)}`)
        if (!cached_trigger || cache_data !== cached_trigger) {
          let trigger = {
            trigger: ai_trigger_content.okr_summary,
            objectId: data?.okrById?.id,
            optype: "VIEW" 
          }
          SetAITrigger({ ...trigger })
          setCookieFlag(`okr_summary_${getIntFromString(data?.okrById?.id)}_${getIntFromString(me?.id)}`, cache_data, { expires: getAiTipCacheTimer() })
        }
      }
      krDiscardSub()
    }
  }

  let krDetailSub = undefined;
  React.useEffect(() => {
    return () => {
      if (krDetailSub) {
        krDetailSub.unsubscribe();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const krDiscardSub = async () => {
    krDetailSub = await client.subscribe({
      query: DISCARD_SUBSCRIPTION_OKR_DETAILS,
      // variables: { id: discardedOkrId },
      fetchPolicy: 'network-only'
    }).subscribe({
      next(result) {
        switch (result.data?.discardRequestSubscription?.mutation) {
          case 'CREATE':
            let discarded_okr = result.data?.discardRequestSubscription?.discardRequest?.okr
            if (okrById_ref.current?.id === discarded_okr?.id) {
              okrById_ref.current.discardRequestRaised.totalCount = 1
              okrById_ref.current.pending = { totalCount: okrById_ref.current.pending?.totalCount + 1, edges: [{ ...okrById_ref.current.pending?.edges, node: result.data?.discardRequestSubscription?.discardRequest }] }
              setokrById({ ...okrById_ref.current })
            }
            break
          case 'UPDATE':

            break
          case 'DELETE':
            let cancel_discarded_okr = result.data?.discardRequestSubscription?.discardRequest?.okr
            if (okrById_ref.current?.id === cancel_discarded_okr?.id) {
              okrById_ref.current.discardRequestRaised.totalCount = 0
              okrById_ref.current.pending = { totalCount: 0, edges: [] }
              setokrById({ ...okrById_ref.current })
            }
            break
          default:
            break
        }
      }
    })
  }


  React.useEffect(() => {
    if (props.match.params.okrId && me && okrPermitted) {
      OKR_BY_ID()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, props.match.params.okrId, okrPermitted])



  React.useEffect(() => {
    if (okrById && me) {
      if (getIntFromString(okrById?.owner?.user?.employee?.id) === getIntFromString(me?.employee?.id)) {
        withEntityHeadRoles({ user: me?.id, organizationid: me?.employee?.organizationSet?.edges[0]?.node?.id })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [okrById, me])


  const withEntityHeadRoles = async (filter) => {
    const { data } = await client.query({
      query: GET_ENTITY_HEAD_ROLE,
      fetchPolicy: 'network-only',
      variables: filter
    });

    if (data?.entityHeadRoles) {
      let manager_detail = data?.entityHeadRoles?.employee?.organizationSet?.edges[0]?.node?.employees?.edges[0]?.node

      if (manager_detail && (manager_detail?.orgCeoEmployee?.totalCount || manager_detail?.orgPocEmployee?.totalCount || manager_detail?.teamManagerEmployee?.totalCount || manager_detail?.verticalHeadEmployee?.totalCount))
        setEntityHEadRoles({ isallowed_role: true });
    }

  }


  return (
    <>

      <div>
        {
          !permissionLoader ?
            <div>

              {
                (okrPermitted?.requried_permission) ?
                  <>

                    {
                      (okrPermitted && okrPermitted?.runpod_permission) && (
                        <OkrAiTipContainer {...props} ai_trigger={ai_trigger}
                          SetAITrigger={SetAITrigger} />
                      )
                    }
                    <KeyResultDetailView allMs={allMs} loading={loading} setloading={(e) => setloading(e)}
                      searchCompetency={(e) => { competencyList(e) }}
                      withAllCompetency={withAllCompetency}
                      okrCompetenies={okrCompetenies}
                      setCompetenciesModalVisible={setCompetenciesModalVisible}
                      okrById={okrById}
                      user_level={user_level}
                      entityHeadRoles={entityHeadRoles}
                      compPermissions={compPermissions}
                      refetchOkr={(e) => { OKR_BY_ID() }
                      }
                      {...props}
                      refreshCompetency={(e) => getOkrCompetencies(props.match.params.okrId)}
                      allokrweights={allokrweights}
                      getWightList={() => getOKRWeights()}

                    />
                  </>
                  :
                  <NoPermissionView />
              }
            </div>
            :
            null
        }
      </div>


    </>
  );
};

export default compose(withApollo, withCreateOKR, withDeleteOKR, withUpdateOKR, withDeleteDiscardRequest, withMe, withUpdateCorrectionDeadline)(OkrKeyResultTableContainer);


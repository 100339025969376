/* eslint-disable array-callback-return */
import React, { useState} from "react";
import _ from "lodash";
import { Col, Row, Switch, Form} from "antd";
import { RadialBar, Sankey } from "@ant-design/charts";
import { NODE_ENV } from "../../../config";
import { compose } from "../../core";
import { withTheirPerceptionSurveyReport } from "../containers/ReportOperations";

const overallProps = ["overall", "WR_workplace", "WR_personalLife"];
const colors = ["#c8dba7", "#FAEACB", "#CD9B9B", "#f5b9ac"];

const RenderReportComponent = (props) => {
  const { userReport, theirReport, loading, actionLoading, receiver, name } =
    props;
  const [isNorm, setIsNorm] = useState(false);
  const [graphLoading, setGraphLoading] = useState(true);
  const [overall, setOverall] = React.useState([]);
  const [individualWorkplace, setIndividualWorkplace] = useState([]);
  const [individualPersonal, setIndividualPersonal] = useState([]);
  const [overallOrbitalWorkplace, setOverallOrbitalWorkplace] = useState([]);
  const [overallOrbitalPersonal, setOverallOrbitalPersonal] = useState([]);
  const isUserReport =
    userReport.report && Object.keys(userReport.report).length > 0;

  React.useEffect(() => {
    if (!loading && theirReport) {
      const finalObj = [];
      overallProps.map((i) => {
        const userObj = {},
          myObj = {};
        const isUserReport =
          userReport.report && Object.keys(userReport.report).length > 0;
        if (isUserReport) {
          const otherReport = Object.keys(userReport.report).filter(
            (stat) => stat === i
          );
          userObj.term = `${otherReport[0]} ${name}`.replace("_", " ");
          userObj.user = _.get(userReport.report, [otherReport[0], "user"]);
          finalObj.push(userObj);
        }
        const myReport = Object.keys(theirReport).filter((stat) => stat === i);
        myObj.term = `${myReport[0]} ${receiver}`.replace("_", " ");
        myObj.user = _.get(theirReport, [myReport[0], "user"]);
        finalObj.push(myObj);
      });
      setOverall(finalObj);
    }
  }, [loading, name, receiver, theirReport, userReport.report]);

  React.useEffect(() => {
    setIsNorm(false);
  }, [userReport.report]);

  React.useEffect(() => {
    if (!loading && theirReport) {
      function getData(data, source) {
        const finalObj = [];
        Object.keys(data).map((stat) => {
          const userObj = {};
          const targetIndex = Object.keys(theirReport.questionIdToText).filter(
            (s) => s === stat
          )[0];
          const target = _.get(theirReport.questionIdToText, [targetIndex]);
          const value = _.get(data, [stat]);
          userObj.source = source;
          userObj.target = target;
          userObj.value = value;
          finalObj.push(userObj);
        });
        return finalObj;
      }
      const isUserReport =
        userReport.report && Object.keys(userReport.report).length > 0;
      const individualWorkplaceUR =
        (isUserReport &&
          getData(
            userReport.report.protectiveFactorPreferance.overallIndividual.user
              .workplace,
            name || "user"
          )) ||
        [];
      const individualWorkplaceMR = getData(
        theirReport.protectiveFactorPreferance.overallIndividual.user.workplace,
        receiver || "my"
      );
      setIndividualWorkplace([
        ...individualWorkplaceMR,
        ...individualWorkplaceUR,
      ]);
      //-----------------------------------------------------
      const individualPersonalUR =
        (isUserReport &&
          getData(
            userReport.report.protectiveFactorPreferance.overallIndividual.user
              .personal,
            name || "user"
          )) ||
        [];
      const individualPersonalMR = getData(
        theirReport.protectiveFactorPreferance.overallIndividual.user.personal,
        receiver || "my"
      );
      setIndividualPersonal([...individualPersonalUR, ...individualPersonalMR]);
      //-------------------------------------------------------
      const overallOrbitalWorkplaceUR =
        (isUserReport &&
          getData(
            userReport.report.protectiveFactorPreferance.overallOrbital.user
              .workplace,
            name || "user"
          )) ||
        [];
      const overallOrbitalWorkplaceMR = getData(
        theirReport.protectiveFactorPreferance.overallOrbital.user.workplace,
        receiver || "my"
      );
      setOverallOrbitalWorkplace([
        ...overallOrbitalWorkplaceUR,
        ...overallOrbitalWorkplaceMR,
      ]);
      //------------------------------------------------------
      const overallOrbitalPersonalUR =
        (isUserReport &&
          getData(
            userReport.report.protectiveFactorPreferance.overallOrbital.user
              .personal,
            name || "user"
          )) ||
        [];
      const overallOrbitalPersonalMR = getData(
        theirReport.protectiveFactorPreferance.overallOrbital.user.personal,
        receiver || "my"
      );
      setOverallOrbitalPersonal([
        ...overallOrbitalPersonalUR,
        ...overallOrbitalPersonalMR,
      ]);
    }
  }, [loading, name, receiver, theirReport, userReport.report]);

  const handleChange = () => {
    const finalObj = [];
    if (!isNorm) {
      const isUserReport =
        userReport.report && Object.keys(userReport.report).length > 0;
      overallProps.map((i) => {
        const userObj = {},
          myObj = {};
        if (isUserReport) {
          const otherReport = Object.keys(userReport.report).filter(
            (stat) => stat === i
          );
          myObj.term = `${otherReport[0]} ${name} norm`.replace("_", " ");
          myObj.user = _.get(userReport.report, [otherReport[0], "norm"]);
          finalObj.push(myObj);
        }
        const myReport = Object.keys(theirReport).filter((stat) => stat === i);
        userObj.term = `${myReport[0]} ${receiver} norm`.replace("_", " ");
        userObj.user = _.get(theirReport, [myReport[0], "norm"]);
        finalObj.push(userObj);
      });
      setOverall([...overall, ...finalObj]);
    } else {
      setOverall(overall.filter((ov) => ov.term.indexOf("norm") === -1));
    }
    if (!isNorm) {
      function getData(data, source) {
        const finalObj = [];
        Object.keys(data).map((stat) => {
          const userObj = {};
          const targetIndex = Object.keys(theirReport.questionIdToText).filter(
            (s) => s === stat
          )[0];
          const target = _.get(theirReport.questionIdToText, [targetIndex]);
          const value = _.get(data, [stat]);
          userObj.source = source;
          userObj.target = target;
          userObj.value = value;
          finalObj.push(userObj);
        });
        return finalObj;
      }
      const individualWorkplaceUR =
        (isUserReport &&
          getData(
            userReport.report.protectiveFactorPreferance.overallIndividual.user
              .overall,
            `${name} (Overall)` || "user (Overall)"
          )) ||
        [];
      const individualWorkplaceMR = getData(
        theirReport.protectiveFactorPreferance.overallIndividual.user.overall,
        `${receiver} (Overall)` || "my (Overall)"
      );
      setIndividualWorkplace([
        ...individualWorkplace,
        ...individualWorkplaceUR,
        ...individualWorkplaceMR,
      ]);
      //-----------------------------------------------------
      const individualPersonalUR =
        (isUserReport &&
          getData(
            userReport.report.protectiveFactorPreferance.overallIndividual.user
              .overall,
            `${name} (Overall)` || "user (Overall)"
          )) ||
        [];
      const individualPersonalMR = getData(
        theirReport.protectiveFactorPreferance.overallIndividual.user.overall,
        `${receiver} (Overall)` || "my (Overall)"
      );
      setIndividualPersonal([
        ...individualPersonal,
        ...individualPersonalUR,
        ...individualPersonalMR,
      ]);
      //-------------------------------------------------------
      const overallOrbitalWorkplaceUR =
        (isUserReport &&
          getData(
            userReport.report.protectiveFactorPreferance.overallOrbital.user
              .overall,
            `${name} (Overall)` || "user (Overall)"
          )) ||
        [];
      const overallOrbitalWorkplaceMR = getData(
        theirReport.protectiveFactorPreferance.overallOrbital.user.overall,
        `${receiver} (Overall)` || "my (Overall)"
      );
      setOverallOrbitalWorkplace([
        ...overallOrbitalWorkplace,
        ...overallOrbitalWorkplaceUR,
        ...overallOrbitalWorkplaceMR,
      ]);
      //------------------------------------------------------
      const overallOrbitalPersonalUR =
        (isUserReport &&
          getData(
            userReport.report.protectiveFactorPreferance.overallOrbital.user
              .overall,
            `${name} (Overall)` || "user (Overall)"
          )) ||
        [];
      const overallOrbitalPersonalMR = getData(
        theirReport.protectiveFactorPreferance.overallOrbital.user.overall,
        `${receiver} (Overall)` || "my (Overall)"
      );
      setOverallOrbitalPersonal([
        ...overallOrbitalPersonal,
        ...overallOrbitalPersonalUR,
        ...overallOrbitalPersonalMR,
      ]);
    } else {
      setIndividualWorkplace(
        individualWorkplace.filter((i) => i.source.indexOf("(Overall)") === -1)
      );
      setIndividualPersonal(
        individualPersonal.filter((i) => i.source.indexOf("(Overall)") === -1)
      );
      setOverallOrbitalWorkplace(
        overallOrbitalWorkplace.filter(
          (i) => i.source.indexOf("(Overall)") === -1
        )
      );
      setOverallOrbitalPersonal(
        overallOrbitalPersonal.filter(
          (i) => i.source.indexOf("(Overall)") === -1
        )
      );
    }
    setIsNorm(!isNorm);
  };

  var configOverall = {
    data: overall,
    xField: "term",
    yField: "user",
    radius: isNorm ? 1 : 0.8,
    innerRadius: isNorm ? 0.2 : 0.3,
    startAngle: Math.PI * 0.5,
    endAngle: Math.PI * 2.5,
    tooltip: { showMarkers: true },
    type: "line",
    onReady: () => setGraphLoading(false),
    loading: loading || actionLoading || graphLoading,
    annotations: [
      {
        type: "text",
        position: ["50%", "50%"],
        content: "Overall\n MApp",
        style: {
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 16,
        },
      },
    ],
    color: function color(_ref) {
      var term = _ref.term;
      if (term.indexOf(receiver) !== -1 && term.indexOf("norm") === -1) {
        return "#B0CB1F";
      } else if (term.indexOf(name) !== -1 && term.indexOf("norm") === -1) {
        return "#2194ff";
      } else if (term.indexOf("norm") !== -1 && term.indexOf(receiver) !== -1) {
        return "#de9ff3";
      } else if (term.indexOf("norm") !== -1 && term.indexOf(name) !== -1) {
        return "#FBEC5D";
      }
    },
  };

  var config = {
    sourceField: "source",
    targetField: "target",
    weightField: "value",
    color: (props) => {
      if (props.name === receiver && props.name.indexOf("norm") === -1) {
        return "#B0CB1F";
      } else if (props.name === name && props.name.indexOf("norm") === -1) {
        return "#2194ff";
      } else if (
        props.name.indexOf("norm") !== -1 &&
        props.name.indexOf(receiver) !== -1
      ) {
        return "#ff4d4f";
      } else if (
        props.name.indexOf("norm") !== -1 &&
        props.name.indexOf(name) !== -1
      ) {
        return "#00E86C";
      } else if (
        props.name.indexOf("(Overall)") !== -1 &&
        props.name.indexOf(receiver) !== -1
      ) {
        return "#de9ff3";
      } else if (
        props.name.indexOf("(Overall)") !== -1 &&
        props.name.indexOf(name) !== -1
      ) {
        return "#FBEC5D";
      } else {
        return _.shuffle(colors)[0];
      }
    },
    onReady: () => setGraphLoading(false),
    loading: loading || actionLoading || graphLoading,
    edgeStyle: {
      fill: "#ccc",
      fillOpacity: 0.4,
    },
  };

  var configIndividualWorkplace = {
    data: individualWorkplace,
    ...config,
  };
  var configIndividualPersonal = {
    data: individualPersonal,
    ...config,
  };
  var configOverallOrbitalWorkplace = {
    data: overallOrbitalWorkplace,
    ...config,
  };

  var configOverallOrbitalPersonal = {
    data: overallOrbitalPersonal,
    ...config,
  };

  return (
    <div /* style={{ height: '115vh' }} */>
      <br />
      {/* {!actionLoading && !loading && ( */}
      <Row>
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <h2>Your Overall MApp</h2>
            <div>
              <Form.Item
                label={"Enable Norm"}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 20, offset: 4 }}
              >
                <Switch checked={isNorm} onClick={handleChange} />
              </Form.Item>
            </div>
          </Row>
        </Col>
        <Col
          span={24}
          style={{ height: isNorm && isUserReport ? "80vh" : "auto" }}
        >
          <RadialBar {...configOverall} />
        </Col>
        <br />
        <br />
        {NODE_ENV !== "production" && (
          <>
            <Col span={24}>
              <h2>Comparison with Select Demographics</h2>
            </Col>
            <Col span={24}>
              <h4>Dont add this section for now.</h4>
            </Col>
          </>
        )}
        <Col span={24}>
          <h2>Preferences for protective factors</h2>
        </Col>
        <Col span={24}>
          <h3>Individual Protective Factors Workplace</h3>
        </Col>
        <Col span={24}>
          <Sankey {...configIndividualWorkplace} />
        </Col>
        <br />
        <br />
        <Col span={24}>
          <h3>Individual Protective Factors Personal</h3>
        </Col>
        <Col span={24}>
          <Sankey {...configIndividualPersonal} />
        </Col>
        <br />
        <br />
        <Col span={24}>
          <h3>Orbital Protective Factors Workplace</h3>
        </Col>
        <Col span={24}>
          <Sankey {...configOverallOrbitalWorkplace} />
        </Col>
        <br />
        <br />
        <Col span={24}>
          <h3>Orbital Protective Factors Personal</h3>
        </Col>
        <Col span={24}>
          <Sankey {...configOverallOrbitalPersonal} />
        </Col>
        <br />
        <br />
        {NODE_ENV !== "production" && (
          <>
            <Col span={24}>
              <h2>Protective Factor Comparison with Select Demographics</h2>
            </Col>
            <Col span={24}>
              <h4>Dont add this section for now.</h4>
            </Col>
          </>
        )}
      </Row>
      {/* )} */}
    </div>
  );
};

export default compose(withTheirPerceptionSurveyReport)(RenderReportComponent);

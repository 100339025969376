import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Dropdown, Menu, message, Modal, Row, Spin, Tooltip } from "antd";
import FeedbackDetailView from "modules/feedback/components/ViewFeedbackPopUp";
import GiveKudosPopupContainer from 'modules/feedback/containers/GiveKudosPopupContainer';
import { displayUtcToUserTimezone, getIntFromString, getSelectedFeedbackType, ReactMarkdown, ToolTipTitle } from "modules/look";
import React from "react";
import Agenda_close from '../../assets/agenda-close.svg';
import delete_icon from '../../assets/delete-agenda.svg';
import edit_icon from '../../assets/edit-agenda.svg';
import info_button from '../../assets/info-button.svg';
import three_dot from '../../assets/three-dot.svg';
import { replaceAnswer1, replaceAnswer2, replaceAnswer3, replaceAnswer4, replaceAnswer5Feedback1, replaceAnswer5Feedback2 } from '../../feedback/containers/textReplace';
import OneOnOneFeedbackUpadteContainer from "../containers/one-on-one-edit-Feedback-Container";
import OneOnOneFeedbackPostContainer from "../containers/One-on-one-feedbackPostContainer";
import { OneonOneDeleteModal } from "./one-on-oneDeleteModal";


const MeetingFeedbackView = (props) => {
  const { feedbackList, meetingByIdData, me, noAddOption, noMenu, deleteFeedback,
    hidePlaceHolder, setHideButtonPanel, permission, createFeedback, updateMeeting, updateFeedback, createBadgeAward,
    deleteBadgeAward, feedbackById, setfeedbackById, getFeedbackById, okrByrecipiant, getOKRByRecepiant } = props
  const { Panel } = Collapse;
  const [memberlist, setmemberlist] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(undefined)
  const [addFeedback, setAddFeedback] = React.useState(false)
  const [editFeedback, setEditFeedback] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [selectedFeedback, setSelectedFeedback] = React.useState(undefined)

  //state variable for details of feedback while editing the feedback


  // state variables for feedback preview popup and post feedback popup
  const [Recipient, setRecipient] = React.useState(undefined);
  const [questionList, setquestionList] = React.useState([]);
  const [answersList, setAnswers] = React.useState([]);
  const [question1Field1, setquestion1Field1] = React.useState();
  const [question1Field2, setquestion1Field2] = React.useState();
  const [question2Field1, setquestion2Field1] = React.useState();
  const [question2Field2, setquestion2Field2] = React.useState();
  const [question2Field3, setquestion2Field3] = React.useState();
  const [question3Field1, setquestion3Field1] = React.useState();
  const [question3Field2, setquestion3Field2] = React.useState();
  const [question4Field1, setquestion4Field1] = React.useState();
  const [question5Field2, setquestion5Field2] = React.useState()
  const [question5Field1, setquestion5Field1] = React.useState()
  const [givenKudos, setGivenKudos] = React.useState(undefined);
  const [giveKudosPopupVisible, setGiveKudosPopUpVisible] = React.useState(false)
  const [newKudosAdded, setNewKudosAdded] = React.useState(false)
  const [badgeAwardId, setBadgeAwardId] = React.useState();
  const [givenBadgeAward, setGivenBadgeAward] = React.useState();
  const [selectedTabValue, setSelectedTabValue] = React.useState(0)
  const feedBackTypeEnum = {
    1: 'MOTIVATIONAL_RECOGNITION',
    2: 'DEVELOPMENTAL_CONSTRUCTIVE'
  }
  let okrDetails = JSON.parse(localStorage.getItem('okrDetails'))
  const [feedBackType, setFeedBackType] = React.useState("1");

  const [tabArray, setTabArray] = React.useState([
    { id: 0, value: 'C', active: true },
    { id: 1, value: 'L', active: true },
    { id: 2, value: 'A', active: true },
    { id: String(feedBackType) === '1' ? null : 3, value: 'P', active: true },
    { id: String(feedBackType) === '1' ? 4 : 4, value: 'S', active: true }])

  const [tabArrayEditFeedback, setTabArrayEditFeedback] = React.useState([
    { id: 0, value: 'C', active: false },
    { id: 1, value: 'L', active: false },
    { id: 2, value: 'A', active: false },
    { id: String(feedBackType) === '1' ? null : 3, value: 'P', active: false },
    { id: String(feedBackType) === '1' ? 4 : 4, value: 'S', active: false }])



  const [feedbackPreviewVisisble, setFeedbackPreviewVisible] = React.useState(false);
  const [feedbackDetailsLoading, setFeedbackDetailsLoading] = React.useState(false);
  const [feedbackDetails, setFeedbackDetails] = React.useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [feedbackPreviewDisplay, setFeedbackPreviewDisplay] = React.useState(false);
  const [fillInOption, setfillInOption] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [okrid, setokrid] = React.useState();
  const [feedbackDetailSide, setFeedbackDetailSide] = React.useState("");
  const [selected_user, setSelectedUser] = React.useState()
  const [selected_user_list, setSelectedUserList] = React.useState([])

  React.useEffect(() => {
  }, [givenKudos])
  
  React.useEffect(() => {
    if (feedbackById) {
      if (feedbackById?.feedbackTo?.user?.employee?.id) {
        getOKRByRecepiant({ employee: feedbackById?.feedbackTo?.user?.employee?.id })
      }


      if (!givenKudos && feedbackById && feedbackById?.badgeAward?.edges?.length) {
        let selectedBadge = feedbackById?.badgeAward?.edges[0]?.node
        let badgeAwardData = {
          badgeId: getIntFromString(selectedBadge?.badge?.id),
          evidence: selectedBadge?.badge?.title,
          image: selectedBadge?.badge?.image,
          title: selectedBadge?.badge?.title,
          behaviouralDescription: selectedBadge?.badge?.behaviouralDescription
        }

        setGivenKudos(badgeAwardData)
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackById])

  const ref = React.useRef();

  const text = (data) => {
    let feedList = data?.response?.answerSet?.edges?.map(({ node }) => node)
    return (
      <>
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "start", alignItems: "center", flexWrap: "wrap", marginTop: '-7px', marginBottom: '0px', position: 'relative', left: '25px' }}>
          <h4 className="action-item-markdown-heades" style={{ whiteSpace: "nowrap", fontSize: "10px" }}>Last updated:<span style={{ color: "#009AF1", paddingLeft: "5px" }}>{displayUtcToUserTimezone(data?.updatedAt, "DD MMM YYYY")}</span> </h4>
          <h4 className="action-item-markdown-heades" style={{ whiteSpace: "nowrap", fontSize: "10px" }}>NATURE:<span style={{ color: "#4CCB1F", paddingLeft: "5px" }}>{data?.content === "DEVELOPMENTAL_CONSTRUCTIVE" ? 'CLAPS' : 'CLAS'}</span></h4>
        </div>
        <Row justify="end">
          <Tooltip title={ToolTipTitle} className="feedback-tooltip" overlayInnerStyle={{ width: '500px' }}>
            <img src={info_button} style={{ position: 'relative', top: '-6px', right: '0px', width: '15px' }} alt=""/>
          </Tooltip>
        </Row>
        <div className="action-item-marckdown" style={{ background: "#FFF", padding: "10px 20px", border: "1px solid #D9D9D9", borderRadius: "8px", minHeight: '50px' }}>
          {(feedList?.length && data?.detailFillIn) ? feedList?.map(item => (
            <div style={{ marginBottom: '10px', width: '100%', display: 'flex', flexDirection: "row", gap: "10px" }}>
              <div style={{ display: 'flex', flexDirection: "row", gap: "5px", marginRight: "5px" }}><ReactMarkdown style={{ color: '#4CCB1F' }}>{item?.question?.questionText[3]}</ReactMarkdown><span style={{ fontWeight: "600", color: '#4CCB1F' }}>:</span></div>
              <div><ReactMarkdown style={{ marginLeft: '5px', marginBottom: '0px' }}>{item?.answer}</ReactMarkdown></div>
            </div>
          ))
            :
            feedList?.map(item => (
              <div style={{ marginBottom: '10px', width: '100%', display: 'flex', flexDirection: "row", gap: "10px" }}>
                <div style={{ display: 'flex', flexDirection: "row", gap: "5px", marginRight: "5px" }}><ReactMarkdown style={{ color: '#4CCB1F' }}>{item?.question?.questionText[3]}</ReactMarkdown><span style={{ fontWeight: "600", color: '#4CCB1F' }}>:</span></div>
                <div><ReactMarkdown style={{ marginLeft: '5px', marginBottom: '0px' }}>{item?.answer}</ReactMarkdown></div>
              </div>
            ))
          }
        </div>

      </>

    )
  }


  React.useEffect(() => {
    let memberlist = meetingByIdData?.member?.edges?.map(({ node }) => node.user)
    let receiver = memberlist?.filter(i => i?.employee?.id !== me?.employee?.id)
    memberlist = memberlist?.filter(i => i?.employee?.id !== meetingByIdData?.owner?.user?.employee?.id)
    setRecipient({ user: receiver[0] });
    setmemberlist(memberlist)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingByIdData])





  const EditActionItem = (item) => {
    setSelectedFeedback(item)
    getFeedbackById(item?.id)
    setEditFeedback(true)
    setHideButtonPanel(true)

  }
  const GiveFeedback = () => {

    if (memberlist?.length === 1) {
      let data = {
        owner: { user: memberlist[0] },
        feedbackList: feedbackList.map(item => getIntFromString(item?.id)),
        meetingid: meetingByIdData?.id
      }
      localStorage.setItem('okrDetails', JSON.stringify(data))
      setAddFeedback(true)
      setHideButtonPanel(true)
    }
    // else if (memberlist?.length>1){
    //   setFeedbackModal(true)
    // }
  }

  const RemoveFeedback = async (value) => {
    if (value) {
      setDeleteModal(true)
      setHideButtonPanel(true)

      let feedbackToRemove = { id: value?.id, content: value?.content, response: value?.response?.answerSet }

      setSelectedItem(feedbackToRemove)
    }
  }
  const deleteSelected = async (value) => {
    if (value) {
      setDeleteModal(true)
      setHideButtonPanel(true)
      setSelectedItem(value)
      try {
        await deleteFeedback({ id: getIntFromString(value) });
        setDeleteModal(false)
        setHideButtonPanel(false)
        setSelectedItem()
      }
      catch {
      }
    }
  }




  const handleValidation = () => {


    if (Recipient && feedBackType) {
      let answerdata = []
      questionList.forEach(data => {
        if (!data?.questionAddress) {
          let answer = answersList.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
          answerdata.push(answer)
        }
        else {
          let typeValidations = data?.questionAddress?.split(':')
          if (typeValidations?.includes(feedBackType)) {
            let answer = answersList.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
            answerdata.push(answer)
          }
        }
      });


      let valid = true
      questionList.forEach(data => {

        if (!data?.questionAddress) {
          if (data?.required) {
            let answer = answerdata.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id) && item?.answer?.trim() !== '')
            if (!answer) {
              valid = false
            }
          }

        }
        else {
          let typeValidations = data?.questionAddress?.split(':')
          if (typeValidations?.includes(feedBackType) && data?.required) {
            let answer = answerdata.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id) && item?.answer?.trim() !== '')
            if (!answer) {
              valid = false
            }
          }
        }
      });

      if (valid) {
        handleSubmit(answerdata)
      }
      else {
        message.error('please fill in the required field')
      }
    }
    else {
      message.error('please fill in the required field')
    }
  }

  const resetFeedbackForm = () => {

    setquestion1Field1(undefined);
    setquestion1Field2(undefined);
    setquestion2Field1(undefined);
    setquestion2Field2(undefined);
    setquestion2Field3(undefined);
    setquestion3Field1(undefined);
    setquestion3Field2(undefined);
    setquestion4Field1(undefined);
    // setGivenKudos();
    setfillInOption(false);
    setSelectedTabValue(0);
    setTabArray([
      { id: 0, value: 'C', active: true },
      { id: 1, value: 'L', active: true },
      { id: 2, value: 'A', active: true },
      { id: 3, value: 'P', active: true },
      { id: String(feedBackType) === '1' ? 4 : 4, value: 'S', active: true }]);

    setAnswers([]);

  }


  const resetFeedbackDetails = () => {


    setFeedBackType(undefined);
    setHideButtonPanel(false)
    setAddFeedback(false)
    setNewKudosAdded(false);
    setfeedbackById(undefined);
    setFeedbackPreviewVisible(false)
    resetFeedbackForm()


  }

  const handleKudosSubmit = async (createdFeedbackId, editFeedback) => {

    if (givenKudos) {
      try {
        let badgeAwardData = {
          badgeId: givenKudos?.badgeId, evidence: givenKudos?.evidence, relatedToId: createdFeedbackId,
          recipientId: getIntFromString(Recipient?.user?.employee?.id),
          notify: 'employee'
        }
        const res = await createBadgeAward({ badgeAwardData })
        if (res) {
          if (editFeedback) {
            message.success('Kudos given and Feedback Submitted Successfully')
          }

          if (feedbackById) {
            setEditFeedback(false)
          } else {
            setAddFeedback(false)
          }
        }
      } catch (error) {
        console.error(error);

      }
    }

  }



  async function handleSubmitEditFeedback(value) {
    let selectedFeedbackType = getSelectedFeedbackType(feedBackTypeEnum, feedBackType)
    const response = await updateFeedback({
      id: getIntFromString(feedbackById?.id),
      answers: answersList,
      detailFillIn: feedbackById?.detailFillIn,
      feedbackType: selectedFeedbackType,
      okrId: getIntFromString(okrid)
    });
    if (response && response?.id) {
      if (givenKudos) {

        handleKudosSubmit(response?.id, true);
      } else {
        message.success('Feedback Submitted Successfully')

      }

      resetFeedbackDetails()
    }
  }



  async function handleSubmit(value) {
    let feedbackreceiver = meetingByIdData?.member?.edges?.filter((node) =>
      node?.node?.user?.employee?.id !== me?.employee?.id
    )

    let selectedFeedbackType = getSelectedFeedbackType(feedBackTypeEnum, feedBackType)

    let feedbackData = {
      feedbackToId: getIntFromString(feedbackreceiver[0]?.node?.user?.employee?.id),
      okrId: getIntFromString(okrid),
      feedbackType: selectedFeedbackType,
      detailFillIn: fillInOption
    }



    //  while editing feedback

    if (feedbackById) {
      handleSubmitEditFeedback()
    }
    // else condition while new feedback is created
    else {
      const response = await createFeedback({ answers: value, feedbackData: feedbackData, surveyId: 15 });
      if (response && response?.id) {
        if (givenKudos) {
          handleKudosSubmit(response?.id, false);
        } else if (okrDetails?.meetingid) {
          let feedback = okrDetails?.feedbackList || []
          feedback.push(getIntFromString(response?.id))
          const FeedbackToMeeting = await updateMeeting({ id: getIntFromString(okrDetails?.meetingid), feedbacks: feedback })

          if (FeedbackToMeeting) {

            if (givenKudos) {
              message.success('Kudos given and Feedback Submitted Successfully')
            } else {
              message.success('Feedback Submitted Successfully')
            }

          }



          localStorage.removeItem("okrDetails")
        }
        else {
        }
        resetFeedbackDetails()

      } else {
      }
    }


  }



  const handleValidationwithFillin = (submitData) => {
    if (Recipient && feedBackType) {
      let answerdata = []
      if (feedBackType === "1") {
        if (!question1Field1 || !question1Field2 || !question2Field1 || !question2Field2 || !question2Field3 || !question3Field1 || !question3Field2) {
          message.error('please fill in the required field')
          return
        }
      }
      else if (feedBackType === '2') {
        if (!question1Field1 || !question1Field2 || !question2Field1 || !question2Field2 || !question2Field3 || !question3Field1 || !question3Field2 || !question4Field1) {
          message.error('please fill in the required field')
          return
        }
      }

      questionList.forEach(question => {
        let typeValidations = question?.questionAddress?.split(':')
        if (!question?.questionAddress || typeValidations?.includes(feedBackType)) {
          let response = {
            questionId: getIntFromString(question?.id),
            answer: ''
          }
          if (getIntFromString(question?.id) === 683) {
            response.answer = replaceAnswer1(question1Field1, question1Field2)
          }
          else if (getIntFromString(question?.id) === 684) {
            response.answer = replaceAnswer2(question2Field1, question2Field2, question2Field3)
          }
          else if (getIntFromString(question?.id) === 685) {
            response.answer = replaceAnswer3(question3Field1, question3Field2)
          }
          else if (getIntFromString(question?.id) === 686) {
            response.answer = replaceAnswer4(question4Field1)
          }
          else if (getIntFromString(question?.id) === 687) {
            response.answer = feedBackType === "1" ? replaceAnswer5Feedback1(question5Field1) : feedBackType === "2" ? replaceAnswer5Feedback2(question5Field2) : ''
          }
          answerdata.push(response)
        }

      });
      if (submitData) {
        handleSubmit(answerdata)
      }

      return answerdata;

    }
    else {
      message.error('please fill in the required field')
    }
  }



  const addFeedbackPreview = () => {

    setAddFeedback(false)

    let answers = [];
    if (fillInOption) {
      answers = handleValidationwithFillin(false)
    }

    let answersTofilter = [];
    if (answers?.length) {
      answersTofilter = [...answers]

    } else {
      answersTofilter = [...answersList]
    }

    return answersTofilter;

  }


  const editFeedbackPreview = () => {

    setEditFeedback(false);

    let answersTofilter = [];
    answersTofilter = [...answersList];
    return answersTofilter;
  }

  const handleFeedbackPreview = () => {
    setFeedbackPreviewVisible(true)
    let answersTofilter = undefined;

    if (addFeedback) {
      answersTofilter = addFeedbackPreview()
    } else if (editFeedback) {
      answersTofilter = editFeedbackPreview()
    }
    setFeedbackDetailSide("submitted")
    setFeedbackDetailsLoading(true);

    let answersForPreview = answersTofilter?.map((answer) => {
      let indexOfQuestion = questionList?.findIndex((question) => getIntFromString(question?.id) === answer?.questionId)
      return { node: { ...answer, question: { questionText: questionList[indexOfQuestion]?.questionText } } }
    })

    let preview = {
      badgeAward: { edges: givenKudos ? [{ node: { badge: givenKudos } }] : [] },
      detailFillIn: fillInOption,
      feedbackFrom: me?.employee,
      feedbackTo: Recipient,
      feedbackType: feedBackTypeEnum[feedBackType],
      response: {
        answerSet: { edges: answersForPreview }
      }
    }
    setFeedbackDetails(preview);
    setFeedbackDetailsLoading(false);
  }
  
  return (
    <>
      <div className='meeting-overflow agenda-list-container' style={{ marginTop: "10px" }}>
        {(!noAddOption && !addFeedback && !editFeedback) &&
          <div className="one-on-one-meeting-items-add-btn" onClick={() => { GiveFeedback() }}>
            + New Feedback
          </div>}
        {/* {!addAgenda&& */}
        <div className="overflow-feedbacks">


          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined className='collapse-right-outlined-icon' rotate={isActive ? 90 : 0} />}
            style={{ background: '#FFF' }}
          >
            {feedbackList?.length ? feedbackList?.map((item, index) => (
              <Panel className="agend-list-div" style={{ marginTop: "10px", marginBottom: 14, border: 'none', borderRadius: "8px", padding: "0px 0px" }} header={<div style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "space-between", alignItems: "center" }} onClick={(e) => e.stopPropagation()}>
                <h4 className="one-on-one-agenda-title" style={{ textAlign: "left" }}>{item?.content && item?.content === "DEVELOPMENTAL_CONSTRUCTIVE" ? "Developmental/Constructive Feedback" : "Motivational/Recognition Feedback"}</h4>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", flexWrap: "nowrap", gap: "10px" }}>
                  {/* <img src={bookmark_disabel} alt="" style={{cursor:"pointer"}} /> */}
                  {!noMenu && item?.feedbackTo?.id !== me?.employee?.id && <Dropdown overlay={
                    <Menu style={{ borderRadius: " 6.5px", boxShadow: " -0.5px 3px 13.5px -4.5px rgba(0, 0, 0, 0.31)", width: "80px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                      <Menu.Item key="edit" style={{ display: "flex", flexDirection: "row", justifyContent: 'left', alignItems: "center", gap: "5px", fontFamily: "Poppins", fontWeight: 500, fontSize: "12px", cursor: "pointer", width: "100%" }} onClick={() => EditActionItem(item)}><img src={edit_icon} alt="" /> Edit</Menu.Item>
                      <Menu.Item key="delete" style={{ display: "flex", flexDirection: "row", justifyContent: 'left', alignItems: "center", gap: "5px", fontFamily: "Poppins", fontWeight: 500, fontSize: "12px", cursor: "pointer", width: "100%" }} onClick={() => RemoveFeedback(item)}><img src={delete_icon} alt="" />Delete</Menu.Item>
                    </Menu>} placement="bottomCenter"><img src={three_dot} alt="" style={{ cursor: "pointer" }} />
                  </Dropdown>}
                </div>
              </div>}
                key={index + 1}>
                {text(item)}
              </Panel>
            )) : <div style={{ justifyContent: "center", alignItems: "center", display: "flex", width: "100%", paddingTop: "50px" }}>

              {!hidePlaceHolder ? <h4 className='no-data' style={{ color: '#BFBFBF' }}>No Feedback Found</h4> : <div></div>}
            </div>}
          </Collapse>
        </div>
        {/* } */}
      </div>


      <Modal
        footer={null}
        centered
        visible={addFeedback}
        destroyOnClose={true}
        closable={false}
        style={{ minWidth: "40em" }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div className="feedback-pop-up-header poppins">New Feedback</div>
          <img src={Agenda_close} style={{ cursor: 'pointer' }} onClick={() => { setAddFeedback(false); setHideButtonPanel(false); resetFeedbackDetails() }} alt="" />
        </div>
        <OneOnOneFeedbackPostContainer ref={ref} me={me} meetingByIdData={meetingByIdData} onCancel={() => { setAddFeedback(false); setHideButtonPanel(false) }}
          fillInOption={fillInOption} setfillInOption={setfillInOption} setAddFeedback={setAddFeedback} setFeedbackPreviewVisible={setFeedbackPreviewVisible}
          setFeedbackDetailsLoading={setFeedbackDetailsLoading} setFeedbackDetailSide={setFeedbackDetailSide}
          handleFeedbackPreview={handleFeedbackPreview}
          Recipient={Recipient} resetFeedbackForm={resetFeedbackForm}
          questionList={questionList} setquestionList={setquestionList}
          answersList={answersList} setAnswers={setAnswers}
          setFeedBackType={setFeedBackType} feedBackType={feedBackType}
          handleValidationwithFillin={handleValidationwithFillin}
          setFeedbackDetails={setFeedbackDetails} newKudosAdded={newKudosAdded}
          tabArray={tabArray} setTabArray={setTabArray}
          question1Field1={question1Field1} setquestion1Field1={setquestion1Field1}
          question2Field1={question2Field1} setquestion2Field1={setquestion2Field1}
          question2Field2={question2Field2} setquestion2Field2={setquestion2Field2}
          question2Field3={question2Field3} setquestion2Field3={setquestion2Field3}
          question1Field2={question1Field2} setquestion1Field2={setquestion1Field2}
          question3Field1={question3Field1} setquestion3Field1={setquestion3Field1}
          question3Field2={question3Field2} setquestion3Field2={setquestion3Field2}
          question4Field1={question4Field1} setquestion4Field1={setquestion4Field1}
          question5Field2={question5Field2} setquestion5Field2={setquestion5Field2}
          question5Field1={question5Field1} setquestion5Field1={setquestion5Field1}
          feedBackTypeEnum={feedBackTypeEnum}
          okrDetails={okrDetails}
          setGiveKudosPopUpVisible={setGiveKudosPopUpVisible}
          givenKudos={givenKudos} setGivenKudos={setGivenKudos}
          selectedTabValue={selectedTabValue} setSelectedTabValue={setSelectedTabValue}
          {...props} />
      </Modal>
      <Modal
        footer={null}
        centered
        visible={editFeedback}
        destroyOnClose={true}
        style={{ minWidth: "40em" }}
        closable={false}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <div className="feedback-pop-up-header poppins">Edit Feedback</div>
          <img src={Agenda_close} style={{ cursor: 'pointer' }} onClick={() => { setEditFeedback(false); setHideButtonPanel(false); resetFeedbackDetails() }} alt="" />
        </div>
        <OneOnOneFeedbackUpadteContainer feedbackId={selectedFeedback?.id} onCancel={() => { setEditFeedback(false); setHideButtonPanel(false) }}
          setEditFeedback={setEditFeedback} handleFeedbackPreview={handleFeedbackPreview}
          questionList={questionList} setquestionList={setquestionList}
          answersList={answersList} setAnswers={setAnswers}
          tabArray={tabArrayEditFeedback} setTabArray={setTabArrayEditFeedback} feedBackTypeEnum={feedBackTypeEnum}
          Recipient={Recipient} setRecipient={setRecipient}
          permission={permission} okrByrecipiant={okrByrecipiant}
          newKudosAdded={newKudosAdded} getOKRByRecepiant={getOKRByRecepiant}
          setFeedBackType={setFeedBackType} feedBackType={feedBackType}
          feedbackById={feedbackById} setfeedbackById={setfeedbackById}
          setGiveKudosPopUpVisible={setGiveKudosPopUpVisible} deleteBadgeAward={deleteBadgeAward}
          givenKudos={givenKudos} setGivenKudos={setGivenKudos}
          me={me} setNewKudosAdded={setNewKudosAdded}
          givenBadgeAward={givenBadgeAward} setGivenBadgeAward={setGivenBadgeAward}
          selected_user={selected_user}
          setSelectedUser={setSelectedUser}
          selected_user_list={selected_user_list}
          setSelectedUserList={setSelectedUserList}
        />
      </Modal>
      <Modal
        footer={null}
        centered
        className="delete-modal-meeting"
        visible={deleteModal}
        destroyOnClose={true}
        style={{ height: "300px" }}
        closable={false}>
        <div style={{ position: "relative", width: "100%", paddingTop: '17px' }} >
          <h2 className="one-on-one-meeting-popup-head" style={{ textAlign: "center" }}>Warning</h2>
          <img src={Agenda_close} alt="" style={{ position: "absolute", right: "0px", top: "0px", cursor: "pointer" }} onClick={() => { setDeleteModal(false); setSelectedItem();; setHideButtonPanel(false) }} />
        </div>
        <OneonOneDeleteModal title={'Are you sure you want to delete the feedback?'} selectedData={selectedItem} onCancel={() => { setDeleteModal(false);; setHideButtonPanel(false) }} deleteSeletedFeedback={e => deleteSelected(e)} type={'feedback'} />
      </Modal>

      {/* Feedback preview pop up */}
      <Modal visible={feedbackPreviewVisisble}
        footer={null}
        centered
        destroyOnClose={true}
        closable={false}
      >
        <Spin spinning={feedbackDetailsLoading}>
          <FeedbackDetailView
            feedbackPermission={permission} feedbackPreviewDisplay={true}
            feedbackDetails={feedbackDetails} setFeedbackModalVisible={setFeedbackPreviewVisible}
            setFeedbackPreviewDisplay={setFeedbackPreviewDisplay} 
            me={me}
            fillInOption={fillInOption}
            givenKudos={givenKudos}
            setFeedbackEditModalVisible={setEditFeedback}
            setFeedbackOperationsModalVisible={setAddFeedback}
            handleValidation={handleValidation}
            setFeedbackDetails={setFeedbackDetails}
            feedbackDetailSide={feedbackDetailSide}
            handleValidationwithFillin={handleValidationwithFillin}
            editFeedbackPreview={feedbackById ? true : false}
          />
        </Spin>
      </Modal>

      {/* Give kudos Popup */}
      <Modal visible={giveKudosPopupVisible}
        footer={null}
        centered
        closable={false}
        width={"fit=content"}>
        <GiveKudosPopupContainer setNewKudosAdded={setNewKudosAdded} feedbackById={feedbackById} setBadgeAwardId={setBadgeAwardId}
          badgeAwardId={badgeAwardId} setGivenKudos={setGivenKudos} feedbackEditModalVisible={editFeedback}
          setFeedbackEditModalVisible={setEditFeedback} setFeedbackOperationsModalVisible={setAddFeedback}
          setGiveKudosPopUpVisible={setGiveKudosPopUpVisible} {...props} ></GiveKudosPopupContainer>
      </Modal>

    </>
  )
}


export default MeetingFeedbackView
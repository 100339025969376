// @ts-nocheck
import React, { useEffect } from 'react';
import { compose } from '../../core';
import { withCreateContributor, withCreateOKR } from './objective-keyresult-operations';
import { withApollo } from '@apollo/client/react/hoc';
import { OKR_CHILD_LIST_QUERY, OKR_CHILD_SUBSCRIPTION } from '../graphql/ChildOKRList.gql';
import MileStoneTableView from '../components/MileStoneTableView';
import moment from 'moment-timezone';
import { getIntFromString, globalPermissionValidator } from 'modules/look';
import { kudos_permission } from 'Permissions/kudos.permission';


const OkrMileStoneTableContainer = props => {

  const {me,client,keyResultDetail,userPermission, milestoneDiscardState }=props;

  const [loading,setloading]= React.useState(true)
  const [allMilestone,setallMilestone]= React.useState()
  const [permission,setpermission]= React.useState()
  const [refUpdater,setRefUpdater] = React.useState()
  const dataRef = React.useRef()
  const m = React.useRef( true )

  useEffect(()=>{
      if(milestoneDiscardState){
        let msList = [];
        
        msList = dataRef?.current
        if (msList){
         dataRef.current = refUpdater
          let markAsPending = msList?.map(item => {
           if (item.id === milestoneDiscardState?.discardRequest?.okr?.id){
             item.discardRequestRaised = true
             item.isDiscarded = {status: 'PENDING'}
             return item;
           } else {
             return item
           }
          })

          setRefUpdater(markAsPending)
          dataRef.current = markAsPending;
          setallMilestone([...markAsPending])
        }

      }
    // }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milestoneDiscardState])

  React.useEffect( () => {
    return () => {
      m.current = false
    }
  }, [] )

  React.useEffect( () => {
    m.current = true

  }, [] )



  let dataRefUpdater = (value) => {
        if (value) {
      dataRef.current = value
    }
  }

  const changeMilestoneReference = (value) => {
    const {okrId, type} = value
    let list = dataRef.current

    if (type === 'DELETE'){
      let newDelArray = list?.filter(item => String(item?.id) !== String(okrId))
      dataRef.current = newDelArray

    }

  }

  React.useEffect(()=>{
    if(userPermission?.length){
      let permission=globalPermissionValidator(kudos_permission,userPermission)
      setpermission(permission)
    }
    else{
      setpermission(null)
    }
  },[userPermission])

  const getMilestoneList =async(filter) => {
    setloading(true)
    const { data } = await client.query({
      query:OKR_CHILD_LIST_QUERY,
      fetchPolicy: 'network-only',
      variables: {okr_Parent:keyResultDetail?.id,createdBy:me?.employee?.id,...filter,tz:moment.tz.guess(),recipient:me?.employee?.id} 
    });

    if(data){
        let incomingDataFromQuery = data?.contributors?.edges?.map(({ node }) => {
          return {
            id: node?.okr?.id,
            title: node?.okr?.title,
            startdate: node?.okr?.startdate,
            duedate: node?.okr?.duedate,
            goalStatement: node?.okr?.goalStatement,
            correctionDeadline: node?.okr?.correctionDeadline,
            badge_award:node?.okr?.badgeAward?.edges,
            contributors: node?.okr?.contributorSet?.edges?.map(({ node }) => {
              return { ...node?.employee, role: node?.role }
            }),
            progress: node?.progress ?? 0,
            childSet: node?.okr?.childSet?.totalCount ? true : false,
            discardRequestRaised:node?.okr?.discardRequestRaised.totalCount>0?true:false,
            isDiscarded: {
              status:
                keyResultDetail?.isDiscarded?.status === "APPROVED"
                  ? "APPROVED"
                  : node?.okr?.isDiscarded?.status
                    ? node?.okr?.isDiscarded?.status
                    : node?.okr?.discardRequest?.totalCount
                      ? "PENDING"
                      : "",
            },
          };
        });
        setallMilestone(incomingDataFromQuery)
        dataRef.current = incomingDataFromQuery
        setRefUpdater(incomingDataFromQuery)
        await client.subscribe( {
          query: OKR_CHILD_SUBSCRIPTION,
          variables: {okr_Parent:keyResultDetail?.id,createdBy:me?.employee?.id,...filter}, 
          fetchPolicy: 'network-only'
        } ).subscribe( {
          next( result ) {
            let contributorsCacheData = dataRef.current
            if(result?.data?.contributorSubscription?.contributor?.okrType==='MILESTONE' && result?.data?.contributorSubscription?.contributor?.role ==="OWNER" && getIntFromString(result?.data?.contributorSubscription?.contributor?.okr?.parent?.id) === getIntFromString(keyResultDetail?.id) ){
              switch ( result.data.contributorSubscription.mutation ) {
                
              case 'CREATE':

                const create = contributorsCacheData?.edges?.filter(item =>
                  item.id === result.data?.contributorSubscription?.contributor?.id
                      ? false
                      : true).concat([{node:result?.data?.contributorSubscription?.contributor}])
                      let incomingData = create?.map(({ node }) => {
                        return {
                          id: node?.okr?.id,
                          title: node?.okr?.title,
                          startdate: node?.okr?.startdate,
                          duedate: node?.okr?.duedate,
                          goalStatement: node?.okr?.goalStatement,
                          correctionDeadline: node?.okr?.correctionDeadline,
                          badge_award:node?.badge_award || node?.okr?.badgeAward?.edges,
                          contributors: node?.okr?.contributorSet?.edges?.map(({ node }) => {
                            return { ...node?.employee, role: node?.role }
                          }),
                          progress: node?.progress ?? 0,
                          childSet: node?.okr?.childSet?.totalCount ? true : false,
                          discardRequestRaised:node?.okr?.discardRequestRaised.totalCount>0?true:false,
                          isDiscarded: {
                            status:
                              keyResultDetail?.isDiscarded?.status === "APPROVED"
                                ? "APPROVED"
                                : node?.okr?.isDiscarded?.status
                                  ? node?.okr?.isDiscarded?.status
                                  : node?.okr?.discardRequest?.totalCount
                                    ? "PENDING"
                                    : "",
                          },
                        };
                      });
                if ( m.current ) {
                  if(create){
                    setallMilestone(incomingData)
                    setRefUpdater(incomingData)
                    dataRef.current =  incomingData
                    
                  } 
                }

                break
              case 'UPDATE':
                if(result?.data?.contributorSubscription?.contributor?.okrType==='MILESTONE'){
                  if ( m.current ) {
                    const Update =contributorsCacheData?.edges?.map( item =>
                        item?.node?.id === result?.data?.contributorSubscription.contributor?.id
                          ? result.data.contributorSubscription.contributor
                          : item
                      ) 
                      if(Update){
                        setallMilestone({edges:Update})
                        dataRef.current = {edges:Update}
                        setRefUpdater({edges:Update})
                      }
                      
                  }
                }
                break
                case 'DELETE':
                  if(result?.data?.contributorSubscription?.contributor?.okrType==='MILESTONE'){
                    if ( m.current ) {
                      const deleted=contributorsCacheData?.edges?.filter( item =>
                          item?.node?.id === result?.data?.contributorSubscription.contributor?.id
                            ? false
                            : true
                        ) 
                        if(deleted){
                          setallMilestone({edges:deleted})
                          dataRef.current = {edges:deleted}
                          setRefUpdater({edges:deleted})
                        }
                      
                    }
                  }
                  break
                default:
                  break
            }
          }
          }})
          
    }
  
}


React.useEffect(()=>{
  if(me && keyResultDetail){
    getMilestoneList()
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[me,keyResultDetail])
  return (
    <>
    <MileStoneTableView dataRefUpdater={(e)=> dataRefUpdater(e)} changeMilestoneReference={(e)=> changeMilestoneReference(e)} allMilestone={allMilestone} setRefUpdater = {setRefUpdater} dataRef = {dataRef} loading={loading} setloading={(e)=>setloading(e)} {...props} permission={permission} />
    </>
  );
};

export default  compose(withApollo,withCreateOKR,withCreateContributor)(OkrMileStoneTableContainer);

import React from "react";

import { compose } from "../../core";
import CommitmentView from "../../dashboard/components/CommitmentView";
import {
  withEditCustomUserCommitment,
  withEditDefaultToolCommitment,
} from "../../dashboard/containers/DashboardOperations";
// import { getIntFromString } from "../../look";
// import { withMe } from "../../user/containers/UserOperations";

// import CoachingCommitmentView from '../components/CoachingCommitmentView';
import {
  withGetAllCustomUserCommitmentsForReportingEmployee,
  withGetAllDefaultCommitmentsProgressForReportingEmployee,
} from "./ManagerOperations";

const CoachingCommitment = (props) => {
  const {
    setSubmitLoading,
    updateDefaultToolCommitment,
    updateCustomUserCommitment,
    getAllCustomUserCommitmentsForReportingEmployee,
    getAllDefaultCommitmentsProgressForReportingEmployee,
  } = props;

  async function handleDefaultCommitmentProgress(values) {
    setSubmitLoading(true);
    try {
      const response = await updateDefaultToolCommitment(values);
      response && setSubmitLoading(false);
      return response && true;
    } catch (err) {
      setSubmitLoading(false);
      return false;
      //throw new Error(err.message);
    }
  }

  async function handleCustomUserCommitment(values) {
    setSubmitLoading(true);
    try {
      const response = await updateCustomUserCommitment(values);
      response && setSubmitLoading(false);
      return response && true;
    } catch (err) {
      setSubmitLoading(false);
      return false;
      // throw new Error(err.message);
    }
  }

  return (
    <CommitmentView
      customUserCommitments={getAllCustomUserCommitmentsForReportingEmployee?.edges}
      defaultCustomUserCommitments={
        getAllDefaultCommitmentsProgressForReportingEmployee
      }
      onDefaultCommitmentProgress={handleDefaultCommitmentProgress}
      onCustomUserCommitment={handleCustomUserCommitment}
      {...props}
    />
  );
};

export default compose(
  withEditDefaultToolCommitment,
  withGetAllCustomUserCommitmentsForReportingEmployee,
  withGetAllDefaultCommitmentsProgressForReportingEmployee,
  withEditCustomUserCommitment
)(CoachingCommitment);

import React from "react";
import { compose } from '../../core';
import {getIntFromString,} from "../../look";
import { Row, Col, Button,Modal  } from "antd";
import {  withDeleteDiscardRequest } from "../container/objective-keyresult-operations";
import close_small from '../../assets/close-small.svg'
const RequestCancelDiscard = props => {
    const {discardData,deleteDiscardRequest,visible,onClose}=props

    const Discard = async()=>{
        const discardRequestId = getIntFromString(discardData?.pending?.edges[0]?.node?.id)
        const response =await deleteDiscardRequest({id:discardRequestId})
        if(response?.id){
            onClose()
        }
      
    }
    const cancel =()=>{
        onClose()
    }

    return (
        <>
           <Modal
              footer={null}
              visible={visible}
              centered
              closable={false}
              destroyOnClose={true}
              className='custom-new-modal'>
                <Col >
                
                    <div align="end" style={{width:"100%"}}>
                        <img style={{cursor:'pointer'}} src={ close_small} alt="" onClick={()=>{cancel()}}/>
                    </div>
                <Col>
                <h3 className="modal-head">Cancel Discard Request</h3>
                    <p className="modal-sub-head">{discardData?.title}</p>
                    <Col style={{paddingTop:"20px"}}>
                        <p className="modal-sub-head">Are you sure you want to cancel the discard request ?</p>
                        <p className="modal-sub-head" style={{margin:0}}><span>Note</span>: You can only send a discard request for <br/> approval once foR each particular OKR</p>
                        <Row style={{width:"100%",gap:"20px",paddingTop:"30px"}} justify="center">
                            <Button className="modal-cancel-btn" onClick={cancel}>Go Back</Button>
                            <Button className="modal-submit-btn" onClick={Discard}>Submit</Button>
                        </Row>
                    </Col>
                </Col>
                </Col>
            </Modal>
        
        </>
    )
}
export default compose( withDeleteDiscardRequest)(RequestCancelDiscard)
/* eslint-disable no-mixed-operators */
import React from "react"
import "../css/casper-view.css"
import casper_icon from "../../assets/casper_icon.svg"
import { ReactComponent as Close } from "../../assets/close-icon-without-bg.svg"
import { ReactComponent as Settings } from '../../assets/settings-gear-black-icon.svg'
import { ReactComponent as CasperListening } from '../../assets/casper-listening.svg'
import { ReactComponent as CasperNotListening } from '../../assets/casper-not-listening.svg'
import { ReactComponent as ReplyButton } from '../../assets/casper_reply.svg'
import { ReactComponent as CancelReply } from '../../assets/casper-cancel-reply.svg'
import { Dropdown, Input, Switch } from "antd"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import ReactMarkdown from "markdown-to-jsx"
import moment from "moment-timezone"
import { ALL_MESSAGES } from "../query/casper-query.gql"

const CasperView = (props) => {

    const {
        me,
        casperExpanded,
        tipsExpanded,
        placement,
        setCasperExpanded,
        updateMessage,
        allMessages,
        createMessage,
        setAllMessages,
        generatingResp,
        setGeneratingResp,
        failToSendMsg,
        setFailToSendMsg,
        client
    } = props;
    const [checked, setChecked] = React.useState(false)
    const [dropdownVisible, setDropdownVisible] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const [supportsSpeechRecognition, setSupportsSpeechRecognition] = React.useState(true)
    const [replyToMessage, setReplyToMessage] = React.useState({ replyTo: null, replyToId: null, message: "" })
    const messageListRef = React.useRef(null)
    const messageRef = React.useRef("")
    const inputRef = React.useRef(null)
    const responseTimeout = React.useRef({ timeout: null, interval: null, count: 0 })
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    const handleToggleChange = (e) => {
        setChecked(e)
    };

    if (!browserSupportsSpeechRecognition) {
        setSupportsSpeechRecognition(false)
    }

    React.useEffect(() => {
        if (casperExpanded) {
            try {
                // eslint-disable-next-line no-unused-vars
                const data = allMessages?.edges?.filter(ele => ele?.node?.id && (ele.node.replyMsg || ele.node.replyMsg === "") && !ele.node.readTimestamp).forEach(async (ele) => {
                    const now = moment().utc();
                    const readTimestamp = now.format('YYYY-MM-DD HH:mm:ss.SSS000');
                    await updateMessage({ id: ele.node.id, readTimestamp: readTimestamp })
                });
            }
            catch (error) {
                console.error("Failed to update Read timestamp", error)
            }
        }
        try {
            if (allMessages && messageListRef.current && messageListRef.current.lastChild) {
                // eslint-disable-next-line no-unused-vars
                const scrolled = messageListRef.current.lastChild?.scrollIntoView({ behavior: "auto", block: "end" });
            }
        } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [casperExpanded, allMessages]);

    React.useEffect(() => {
        if ((tipsExpanded || !casperExpanded) && dropdownVisible) {
            setDropdownVisible(false)
        }

        if (!casperExpanded) {
            setMessage("")
            setReplyToMessage({ replyTo: null, replyToId: null, message: "" })
            SpeechRecognition.stopListening();
            resetTranscript();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipsExpanded, casperExpanded])

    const handlePopupClose = () => {
        setDropdownVisible(false)
        setCasperExpanded(false)
    }

    const handleInputChange = (e) => {
        const tempMessage = e.target.value;
        if (!listening) {
            setMessage(tempMessage)
        } else {
            handleStopListening(tempMessage, inputRef.current.input.selectionStart);
        }
    }

    const handleStopListening = (value, index) => {
        index !== undefined ? setMessage(value) : setMessage(`${messageRef.current}${message}`);
        SpeechRecognition.stopListening();
        resetTranscript();
    }

    const handleStartListening = () => {
        messageRef.current = message;
        setMessage("");
        SpeechRecognition.startListening({ continuous: true });
    }

    React.useEffect(() => {
        listening && setMessage(transcript);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transcript]);

    React.useEffect(() => {
        if (!generatingResp) {
            if (responseTimeout.current.timeout) clearTimeout(responseTimeout.current.timeout)
            if (responseTimeout.current.interval) clearInterval(responseTimeout.current.interval)
            responseTimeout.current.interval = null;
            responseTimeout.current.timeout = null;
            responseTimeout.current.count = 0;
        }
    }, [generatingResp])

    const sendMessageToCasper = async () => {
        if (message.trim()) {
            setGeneratingResp(true)
            const messageToSend = {
                userId: me.id,
                userMsg: message
            }

            const newNode = { userMsg: messageToSend.userMsg, id: "-1" };
            if (replyToMessage.replyToId !== null) {
                messageToSend.replyToId = replyToMessage.replyToId;
                messageToSend.replyTo = replyToMessage.replyTo === "userMsg" ? "user_msg" : "reply_msg";
                newNode.replyToId = { id: replyToMessage.replyToId };
                newNode.replyTo = replyToMessage.replyTo === "userMsg" ? "USER_MSG" : "REPLY_MSG";
            }

            if (listening) {
                messageToSend.userMsg = `${messageRef.current}${message}`;
                newNode.userMsg = messageToSend.userMsg;
                SpeechRecognition.stopListening();
                resetTranscript();
            }
            try {
                setReplyToMessage({ replyTo: null, replyToId: null, message: "" })
                setMessage("");
                allMessages.edges.push({ node: newNode })
                const newMessages = JSON.parse(JSON.stringify(allMessages));
                setAllMessages(newMessages);
                await createMessage({ messageInput: messageToSend });
                responseTimeout.current.timeout = setTimeout(() => { handleResponseTimeout() }, 90000)
            }
            catch (error) {
                console.error(error)
            }
            finally {
                setReplyToMessage({ replyTo: null, replyToId: null, message: "" })
                setMessage("");
            }
        }
    }

    const handleResponseTimeout = async () => {
        try {
            const checkForGeneratedReply = async () => {
                const { data } = await client.query({
                    query: ALL_MESSAGES,
                    fetchPolicy: 'network-only'
                })
                if (data?.allMessages?.edgeCount === allMessages.edges.length) {
                    const oldMsgIds = allMessages.edges.map(ele => ele.node.id).filter((ele) => ele !== "-1")
                    const newMsgIds = data.allMessages.edges.map(ele => ele.node.id).filter((ele) => ele !== "-1")
                    const newConversationId = newMsgIds.filter(ele => !oldMsgIds.includes(ele))
                    if (newConversationId.length) {
                        const newMsgData = data.allMessages.edges.filter(ele => ele.node.id === newConversationId[0])[0];
                        if (newMsgData.hasOwnProperty("replyMsg")) {
                            setAllMessages(prev_value => {
                                const index = prev_value.edges.findIndex(ele => ele?.node.hasOwnProperty('id') && ele.node.id === "-1" && ele.node.userMsg === newMsgData.userMsg);
                                if (index !== -1) {
                                    prev_value.edges[index].node = newMsgData
                                }
                                return ({ ...prev_value })
                            })
                        }
                    }
                    if (responseTimeout.current.interval) {
                        clearInterval(responseTimeout.current.interval)
                        responseTimeout.current.interval = null;
                    }
                } else {
                    return "404";
                }
            }

            const { data } = await client.query({
                query: ALL_MESSAGES,
                fetchPolicy: 'network-only'
            })
            if (data?.allMessages?.edgeCount === allMessages.edges.length) {
                checkForGeneratedReply()
            } else {
                responseTimeout.current.interval = setInterval(async () => {
                    const status = await checkForGeneratedReply();
                    ++responseTimeout.current.count;
                    if (status === "404") {
                        if (responseTimeout.current.count === 3) {
                            setFailToSendMsg(true);
                            setGeneratingResp(false)
                            clearInterval(responseTimeout.current.interval);
                            responseTimeout.current.interval = null;
                        }
                    } else {
                        setGeneratingResp(false)
                        clearInterval(responseTimeout.current.interval);
                        responseTimeout.current.interval = null;
                    }
                }, 30000);
            }

        } catch (error) {
            console.error("err while handling timeout:", error)
        }
    }

    const resendMsg = async () => {
        try {
            const messageToResend = allMessages.edges.filter(ele => ele.node.id === "-1")[0];
            const messageToSend = {
                userId: me.id,
                userMsg: messageToResend.node.userMsg
            }
            if (messageToResend.hasOwnProperty("replyToId")) {
                messageToSend.replyToId = messageToResend.replyToId;
                messageToSend.replyTo = messageToResend.replyTo === "USER_MSG" ? "user_msg" : "reply_msg";
            }
            await createMessage({ messageInput: messageToSend });
            setGeneratingResp(true)
            setFailToSendMsg(false)
        } catch (error) {
            console.error("error while resending message", error)
        }
    }

    const getReplyToMessageText = (conversation, allConversations) => {
        const userRepliedTo = allConversations.filter(ele => ele.id === conversation.replyToId.id)[0]
        if (userRepliedTo) {
            return conversation.replyTo === "USER_MSG" ? userRepliedTo.userMsg : userRepliedTo.replyMsg
        }
    }

    const handleReplyClicked = (e) => {
        const replyToMessageNode = allMessages.edges.filter(ele => ele.node.id === e.currentTarget.dataset.id)[0];
        setReplyToMessage({ replyToId: e.currentTarget.dataset.id, replyTo: e.currentTarget.dataset.replyTo, message: replyToMessageNode.node[e.currentTarget.dataset.replyTo] })
        inputRef.current.focus();
    }

    const settingsMenu = () => {
        return (
            <div className="custom-setting-container">
                <div className="casper-card casper-section-container">
                    <div className="casper-action-container ">
                        <div className="setting-label">Turn {checked ? 'off' : 'on'} message sync</div>
                        <Switch
                            className="casper-action-switch"
                            style={{ backgroundColor: "#F6FBFF !important" }}
                            checked={checked}
                            onChange={(e) => {
                                handleToggleChange(e);
                            }}
                        />
                    </div>
                </div>

            </div>
        )
    }

    const SettingDropdown = () => {
        const casperOverlayStyle = {
            "--top-pos": "230px",
            "--width-px": "400px"
        }
        try {
            const dimensions = messageListRef.current.getBoundingClientRect()
            if (dimensions) {
                casperOverlayStyle["--top-pos"] = !dimensions?.top ? "230px" : `${dimensions?.top}px`;
                casperOverlayStyle["--width-px"] = !dimensions?.width ? "400px" : `${dimensions?.width - 25}px`;
            }
        } catch (error) { }
        return (
            <Dropdown overlayClassName="casper-setting-overlay" overlayStyle={{ zIndex: 10001, ...casperOverlayStyle }} className="casper-settings-dropdown" visible={dropdownVisible} overlay={settingsMenu} placement={"bottomRight"}>
                <Settings style={{ cursor: "pointer", fill: "#A93AD5", width: "20px" }} onClick={() => setDropdownVisible(!dropdownVisible)} />
            </Dropdown>
        )
    }
    return (
        <>
            <div className="casper-fixed-container">
                <div className="casper-relative-container">
                    <div className={casperExpanded ? `casper-view-mode ${placement}` : `casper-hide-mode ${placement}`}>
                        <div className="casper-view-container">
                            <div className="casper-header-section">
                                <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center" }}>
                                    <img src={casper_icon} style={{ width: "45px", margin: "10px" }} alt="" />
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", gap: "1em", alignItems: "center", paddingRight: ".5em" }}>
                                    <SettingDropdown />
                                    {dropdownVisible ? (<div className='overlay-dynamic-island' onClick={() => { setDropdownVisible(false) }} />) : null}
                                    <Close onClick={handlePopupClose} style={{ cursor: "pointer", color: "#A93AD5", width: "17px", position: "relative", zIndex: "10002" }} />
                                </div>
                            </div>

                            {/* Casper body section with message list */}
                            <div className={`casper-list ${replyToMessage.replyToId !== null && `dynamic-width`}`} ref={messageListRef}>
                                {
                                    allMessages?.edges?.map(({ node }, index) => {
                                        return (node?.userMsg && (
                                            <>
                                                <div className="user-msg-container" key={`${index}_1`}>
                                                    <div className="message-content">
                                                        {
                                                            node && node?.replyToId &&
                                                            <div className={`reply-to-message ${node.replyTo === "USER_MSG" && "dynamic-color"}`}>
                                                                {getReplyToMessageText(node, allMessages.edges.map(edge => edge.node))}
                                                            </div>
                                                        }
                                                        {node.userMsg}
                                                        {
                                                            node && node?.id !== "-1" &&
                                                            <div className="reply-button">
                                                                <ReplyButton data-id={node.id} data-reply-to="userMsg" onClick={handleReplyClicked} />
                                                            </div>
                                                        }
                                                        {
                                                            failToSendMsg && node && node?.id === "-1" &&
                                                            <div className="error-msg" onClick={resendMsg}>
                                                                <div className="msg-content">
                                                                    click to resend
                                                                </div>
                                                                <div className="icon-container" style={{ height: "0px" }}>
                                                                    <img
                                                                        src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1736250679/MApp/UI/SVGs/resend_message_q0dxl0.svg"
                                                                        style={{
                                                                            width: "12px",
                                                                            height: "12px",
                                                                            position: "relative",
                                                                            top: "-6px",
                                                                            marginLeft: "4px"
                                                                        }} 
                                                                        alt=""
                                                                        />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="casper-profile">
                                                        {me?.profile?.profileImage ? <img src={me?.profile?.profileImage} style={{ width: "100%" }} alt="" /> : `${me.firstName[0]}${me.lastName[0]}`}
                                                    </div>
                                                </div>
                                                {node?.replyMsg && <div className="reply-msg-container" key={`${index}_2`}>
                                                    <div className="casper-profile">
                                                        <img src={casper_icon} style={{ width: "24px" }} alt="" />
                                                    </div>
                                                    <div className="message-content">
                                                        <ReactMarkdown>
                                                            {node.replyMsg.trim()}
                                                        </ReactMarkdown>
                                                        <div className="reply-button">
                                                            <ReplyButton data-id={node.id} data-reply-to="replyMsg" onClick={handleReplyClicked} />
                                                        </div>
                                                    </div>
                                                </div>}
                                            </>
                                        ))
                                    })
                                }
                                {
                                    generatingResp && <div className="reply-msg-container" key={-1}>
                                        <div className="casper-profile">
                                            <img src={casper_icon} style={{ width: "24px" }} alt="" />
                                        </div>
                                        <div className="message-content generating-response">
                                            <img
                                                src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1736252281/MApp/UI/SVGs/generating-response_sc2i7w.svg"
                                                style={{ maxWidth: "40px", marginTop: "10px" }}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                }
                                {!allMessages || !(allMessages && allMessages.edges && allMessages.edges.length) &&
                                    <div className="empty-casper-body">
                                        Ask Casper!
                                    </div>
                                }
                            </div>
                            <div className={`casper-footer-section ${replyToMessage.replyToId !== null && `dynamic-width`}`}>
                                {
                                    supportsSpeechRecognition &&
                                    (
                                        listening
                                            ? <CasperListening style={{ width: "41px", marginBottom: "4px", marginLeft: "7px" }} onClick={handleStopListening} />
                                            : <CasperNotListening style={{ width: "50px" }} onClick={handleStartListening} />
                                    )
                                }
                                <div className={`input-message-container ${replyToMessage.replyTo !== null && "dynamic-width"}`}>
                                    {replyToMessage.replyTo !== null && <div className="reply-message-container">
                                        <div className={`reply-message ${(replyToMessage.replyTo === "userMsg" && "dynamic-color") || replyToMessage.replyTo}`}>
                                            {replyToMessage.message}
                                        </div>
                                        <CancelReply
                                            style={{ width: "20px", height: "20px", marginBottom: "20px", cursor: "pointer" }}
                                            onClick={() => { setReplyToMessage({ replyTo: null, replyToId: null, message: "" }) }}
                                        />
                                    </div>}
                                    <Input
                                        ref={inputRef}
                                        className={replyToMessage.replyTo === null ? "capser-message-input" : "capser-message-input-with-reply"}
                                        placeholder="Chat With Casper"
                                        value={listening ? `${messageRef.current}${message}` : message}
                                        onChange={handleInputChange}
                                        onKeyDown={(e) => { if (e.key === "Enter" && !generatingResp) sendMessageToCasper() }}
                                    />
                                </div>
                                {
                                    <img
                                        src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1736249741/MApp/UI/SVGs/send-message_nnfesc.svg"
                                        onClick={!generatingResp ? sendMessageToCasper : () => { }}
                                        style={{ width: "40px", opacity: !generatingResp ? "100%" : "50%" }}
                                        alt=""
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CasperView

import React from 'react';
import '../css/global.css';
import '../css/create-scape.css'
import { Button, Form, Input, Select, Switch } from "antd";
import TextArea from 'antd/lib/input/TextArea';
import { createScapeIcons, scapeHomeIcons } from '../scapeAdminIcons';
import { useHistory } from 'react-router-dom';

const CreateScapeVew = ( props ) => {

    const { onSubmit, survey_list, getSurveyLists, onCancel, selected_scape } = props
    const { Option } = Select;
    const [ switch_action, setSwitchAction ] = React.useState( false )
    const [ form ] = Form.useForm();
    const history = useHistory()

    const changeSwitch = ( e ) => {
        setSwitchAction( e )
    }
    React.useEffect(()=>{
        setSwitchAction( selected_scape?.active )   
    },[selected_scape])
    React.useEffect( () => {

        if ( selected_scape ) {
            form.setFieldsValue( {
                name: selected_scape?.name,
                description: selected_scape?.description,
                survey: selected_scape?.survey?.id
            } );
        }
    }, [ selected_scape, form ] )

    return (
        <div className='sa-root'>
            <div className="scape-heading-container">
                <img onClick={( e ) => history.push( '/scape-report-admin' )} style={{ paddingLeft: ".5em", cursor: "pointer" }} src={scapeHomeIcons?.new_back_arrow} alt="" />
                <h4 className='sa-create-main-title'>{selected_scape !== undefined ? 'Edit Scape' : 'Create Scape'}</h4>
                <div></div>
            </div>
            <div className='sc-main-container-section'>
                <div className='sc-main-content-container'>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={values => onSubmit( { value: values, action: switch_action } )}
                        scrollToFirstError={true}
                        className='rd-align-center '
                        style={{ gap: '1em' }}

                    >
                        <div className='sa-input-container'>
                            <h4 className='sa-create-title'><span className='rd-input-star'>*</span>Scape Name</h4>

                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input a scape name',
                                    },
                                ]}
                                style={{ width: '100%' }}
                            >
                                <Input className="sa-text-input" placeholder='Enter Scape Name' />
                            </Form.Item>
                        </div>
                        <div className='sa-input-container'>
                            <h4 className='sa-create-title'>Description</h4>
                            <Form.Item
                                name="description"
                                style={{ width: '100%' }}
                            >
                                <TextArea name='description' className="sa-text-area" />
                            </Form.Item>
                        </div>
                        <div className='sa-form-container' style={{ display: 'flex', flexDirection: 'row', gap: '1em', alignItems: "center", width: "100%" }}>
                            <div className='sa-input-container'>
                                <h4 className='sa-create-title'> <span className='rd-input-star'>*</span> Select survey</h4>
                                <Form.Item
                                    name="survey"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a survey',
                                        },
                                    ]}
                                    style={{ width: '100%' }}

                                >
                                    <Select
                                        className="sa-select-filed sa-type-select"
                                        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                        disabled={selected_scape !== undefined ? true : false}
                                        suffixIcon={<img src={createScapeIcons?.dropdown_Arrow} alt="" style={{ width: "10px", height: "10px" }} />}
                                        showSearch
                                        onSearch={( e ) => getSurveyLists( { name_Icontains: e } )}
                                        placeholder='select survey'
                                        filterOption={false}
                                        {...props}
                                    >
                                        {survey_list?.map( value => (
                                            <Option
                                                className='sa-select-option'
                                                key={value?.id}
                                                value={value?.id}
                                            >
                                                {value?.name}
                                            </Option>
                                        ) )}
                                    </Select>
                                </Form.Item>
                            </div>
                            <Switch checked={switch_action} className='sa-switch' checkedChildren="Enabled" unCheckedChildren="Disabled" onChange={( e ) => changeSwitch( e )} />
                        </div>
                        <div className='sa-confirmation-action-container'>
                            <Button className='sa-global-submit-btn' htmlType="submit">Save</Button>
                            <Button className='sa-global-cancel-btn' onClick={() => onCancel()}>Cancel</Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default CreateScapeVew
import { compose } from "modules/core";
import DeleteEmployeeView from "modules/poc-dashboard/components/organization/deleteEmployeeView";
import React from "react";
import { withDeletePocEmployee } from "./organizationOperations";
import { withApollo } from '@apollo/client/react/hoc';
import { getIntFromString } from "modules/look";
import { EMPLOYEE_BY_ID_DETAIL } from "modules/poc-dashboard/graphql/organizationQuery.gql";
import { message } from "antd";
import { gql, useMutation } from "@apollo/react-hooks";
import moment from "moment-timezone";

const DeleteEmployeeContainer = (props) => {
    const { delete_user,onSuccess,client } = props;
    const [delete_loading,SetDeleteLoading] = React.useState(false)
    const [deleteList,SetDeleteList] = React.useState()

    const REMOVE_EMPLOYEE_MUTATION = gql`
    mutation remove_employee_mutation {
      ${deleteList ?
        `${deleteList?.filter(i => i.delete_type === "member_set")?.map((i, index) =>
          `delete${index}:deleteMember(id:"${i.id}"){
                member{
                    id
                }
            }`
        ).join('')
        }
  
        ${deleteList?.filter(i => i.delete_type === "vertical_update")?.map((i, index) =>
          `update_vertical_head_${index}:
            updateVertical(id:${getIntFromString(i?.id)},heads:[${i?.heads?.map(u => getIntFromString(u))}]){
                vertical {
                  id
                }
            }
          `
        ).join('')
        }
        
        ${deleteList?.filter(i => i.delete_type === "team_update")?.map((i, index) =>
          `update_team_manager_${index}:
            updateTeam(id:${getIntFromString(i?.id)},managers:[${i?.managers?.map(u => getIntFromString(u))}]){
                team {
                  id
                }
            }
          `
        ).join('')
        }
         ${deleteList?.filter(i => i.delete_type === "update_user")?.map((i, index) =>
          `update_employee_${index}:
            updateEmployee(id:${getIntFromString(i?.id)},status:false,exitDate:"${i?.exitDate}"){
                employee {
                  id
                }
            }
          `
        ).join('')
        }
        `
        : null
      }
     }
   `;

    const [RemoveEmployeeMutation] = useMutation(REMOVE_EMPLOYEE_MUTATION);

    React.useEffect(()=>{
        if(deleteList?.length){
            deleteEmployeeData()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[deleteList])

    const deleteEmployeeData=async()=>{
        try {
            let res = await RemoveEmployeeMutation()
            if(res){
                onSuccess(delete_user) 
                SetDeleteLoading(false)
            }else{
                SetDeleteList(null)
                SetDeleteLoading(false)
                message.error("Failed to remove employee")
            }
            
        } catch (error) {
            
        }
    }

    const onDelete = async (value) => {
        try {
            SetDeleteLoading(true)
            const { data } = await client.query({
                query: EMPLOYEE_BY_ID_DETAIL,
                fetchPolicy: 'network-only',
                variables:{id:value?.id}
            });
            if(data?.employeeById){
                let employee = data?.employeeById
                let list = []
                let member_set = employee?.memberSet?.edges?.map(({node})=>{
                    return {
                        ...node,
                        delete_type:"member_set"
                    }
                }).filter(i=>!i?.fromOrganization)
                
                list = list?.concat(member_set)

                let vertical_update = employee?.verticalHeadEmployee?.edges?.map(({node})=>{
                    let heads = node?.heads?.edges?.map(i=>i?.node?.id)?.filter(i=> String(i) !== String(value?.id))
                    return {
                        id:node?.id,
                        heads,
                        delete_type:"vertical_update"
                    }
                })
                list = list?.concat(vertical_update)

                let team_update = employee?.teamManagerEmployee?.edges?.map(({node})=>{
                    let managers = node?.managers?.edges?.map(i=> i?.node?.id)?.filter(i=> String(i) !== String(value?.id))
                    return {
                        id:node?.id,
                        managers,
                        delete_type:"team_update"
                    }
                })
                list = list?.concat(team_update)
                list.push({
                    delete_type:"update_user",
                    id:value?.id,
                    exitDate:moment().utc().toISOString()
                })
                SetDeleteList(list)
            }else{
                SetDeleteLoading(false)
                message.error("Failed to remove employee")
            }
   
        } catch (error) {
            SetDeleteLoading(false)
            message.error("Failed to remove employee")
         
        }
    }

    return (
        <>
            <DeleteEmployeeView delete_loading={delete_loading} onDelete={(value) => onDelete(value)} {...props} />
        </>
    )
}

export default compose(withDeletePocEmployee, withApollo)(DeleteEmployeeContainer);
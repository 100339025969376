// @ts-nocheck
import React from 'react'
import { Dropdown} from 'antd';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'

const SkillCustomDropdown = (props) => {

  const { onSelect, placeholder, onSearch, choice_list,add_btn,on_add,selected_label,customCss,closeAfterSelection ,skill_detail} = props

  const container_ref = React.useRef(null);
  const [search_term,SetSearchTerm] = React.useState("")

  React.useEffect(()=>{
    SetSearchTerm("")
  },[selected_label])

  // to handle the event propogation to stop the dropdown from closing after each clicks and set the data
  const changeSelection=(event,data)=>{
    SetSearchTerm("")
    onSelect(data)
    if(!closeAfterSelection){
      event.stopPropagation()
    }
 
  }

  // content to be rendered inside the dropdown
  const menuOverlay = () => (
    <div className='custom-multiselectdropdown-menu' style={{width:`${container_ref.current?.offsetWidth}px`||"100%"}}>
      <div style={{width:"100%",padding:"1em"}} className='custom-multiselectdropdown-scroll'>
      {
        choice_list?.map(item => (
          <div key={item?.id} className='custom-multiselectdropdown-choice' onClick={(e)=>{changeSelection(e,item)}}>
           <h4 className='multiselectdropdown-choice-label'>{item?.label}</h4>
          </div>
        ))
      }
      {
        !choice_list?.length?
        <div align="center"> <h4 className='rd-nodata-label'>No data found</h4> </div>
        :''
      }
      </div>
      {
        (add_btn && on_add)&& (
          <div className='custom-multiselectdropdown-add'>
             <span style={{cursor:'pointer',textAlign:"center"}} onClick={()=>{on_add()}}>+ {add_btn}</span>
          </div>
        )
      }
    </div>
  );

  return (
    <Dropdown overlay={menuOverlay} trigger='click'>
      <div className='custom-multiselectdropdown' style={{borderRadius:customCss?"8px":"1em"}} ref={container_ref}>
        <input onChange={(e) => { onSearch(e?.target?.value);SetSearchTerm(e?.target?.value) }}
            id={skill_detail?.id || Math.random()}
            name={skill_detail?.id || Math.random()}
        className='custom-multiselectdropdown-search' placeholder={selected_label || placeholder} value={search_term} onBlur={()=>SetSearchTerm("")}></input>
        <div className='custom-multiselectdropdown-icon' style={{right:".85em"}}>
          <img src={dropdown_Arrow} alt=""  style={{width:"10px",height:"10px",}}/>
        </div>
      </div>
    </Dropdown>
  )

}

export default SkillCustomDropdown
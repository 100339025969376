import React  from 'react';
import { compose } from '../../core';
import ReportsComponent from '../components/ReportsComponent';
import { withMeLevel } from '../../user/containers/UserOperations';
import { withApollo } from '@apollo/client/react/hoc';
import { ORG_SURVEY_LIST_FOR_REPORT } from '../../user/graphql/GetSurveyForCeoOrPoc.gql';
import { ALL_EMPLOYEE_SURVEY } from 'modules/user/graphql/getSurveyResponse.gql';


const ReportsContainer = props => {
    const {
        loading,
        level,
        client
    } = props;
    const [surveyData, setSurveyData] = React.useState([])
    const [tableData, setTableData] = React.useState([]) 
    const [surveyLoading, setSurveyLoading] = React.useState(true)

    // eslint-disable-next-line no-unused-vars
    const [columnFilters, setColumnFilters] = React.useState({});
    const [columnFiltersId, setColumnFiltersId] = React.useState([]);


    React.useEffect(() => {
        setSurveyLoading(false)
    }, [surveyData])

    const updateColumnFilters = (id, filters) => {
        if (filters === undefined){
            delete columnFilters[id]; 
            let m = columnFiltersId.filter(function (item) {
                return item !== id
            })
            setColumnFiltersId(m)
            return
        }
        let updatedValue = {};
        // let intId = getIntFromString(id)
        updatedValue[id] = filters ;
        // setColumnFilters(shopCart => ({
        //     ...columnFilters,
        //     ...updatedValue
        // }))
        Object.assign(columnFilters, updatedValue)
        setColumnFiltersId(p => [...p, id])
    }


    React.useEffect(() => {
        //Find level of Employee
        fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[level])

    const SelectedSurvay = (val) => {
        // fetchSurvey(val)
        fetchSurveyResponse(val)
    }

    // get surveys based on title
    /* eslint-disable no-unused-expressions */
    const fetchData = async () => {
      
        const { data } = await client.query({
            query: ORG_SURVEY_LIST_FOR_REPORT,
            variables: { listing:true },
            fetchPolicy: 'network-only'
        });
       if (data?.allOrgSurveysByImplicitOrgId) {
        setSurveyData(data?.allOrgSurveysByImplicitOrgId?.edges?.map(({node})=>{
            return {
                label:node?.name,
                value:node?.id
            }
        }))
       }
       
    }
 
    const fetchSurveyResponse = async(val)=>{
        try {
            let id = '';
            if (props.match) {
                id = props.match.params.id;
            } else if (props.navigation) {
                id = props.navigation.state.params.id;
            }
            if(!id){
                return
            }
            
            const { data } = await client.query({
                query: ALL_EMPLOYEE_SURVEY,
                variables: { surveyId: val },
            })
            if(data?.allEmployees){ 
                setTableData(data?.allEmployees?.edges?.map(({node})=>node?.user))
            }
        } catch (error) {
            
        }
    }

    return (
        <>
            <ReportsComponent {...props} loading={loading} level={level}
            surveyData={surveyData} 
            surveyLoading={surveyLoading}
            updateColumnFilters={updateColumnFilters}
            columnFilters={columnFilters}
                columnFiltersId={columnFiltersId}
            tableData={tableData} onSurvaychange={(e) => SelectedSurvay(e)} />
         </>
    );
};


export default compose(withApollo, withMeLevel)(ReportsContainer);
import React from "react";
import ChangePasswordView from "../components/change-password-view";
import { message } from "antd";
import { withPasswordChange } from "modules/user/containers/UserOperations";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc'

const ChangePasswordContainer =(props)=>{
    const {passwordChange}=props
    const [passErrors, setPassErrors] = React.useState(null);
    const [submitLoading, setSubmitLoading] = React.useState(false);


    const handlePassChange = async values => {
        setSubmitLoading(true);
        try {
          const response = await passwordChange(values);
          if (response.success) {
            message.success('Password updated!!');
          } else {
            message.error('Failed! try again.');
            setPassErrors(response.errors);
          }
          setSubmitLoading(false);
          return response.success;
        } catch (e) {
          setSubmitLoading(false);
          throw new Error(e.message);
        }
      };

    return (
        <>
        <ChangePasswordView
        passErrors={passErrors}
        onPassChange={(value)=>handlePassChange(value)}
        submitLoading={submitLoading}
         {...props}/>
        </>
    )
}
export default compose(withPasswordChange, withApollo)(ChangePasswordContainer)

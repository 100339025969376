// @ts-nocheck
// import { Modal } from "antd";
import React from "react";
import OkrPerformanceView from "../OkrPerformanceView";
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc';
import { MEMBER_CASCADE_DATA } from "modules/Objective-keyresult/graphql/MemebersCascadingData.gql";
// import { endOfQuarter,  startOfQuarter } from 'modules/look';
import { MEMBER_NON_CASCADE_DATA } from "modules/Objective-keyresult/graphql/MemberNonCascadeData.gql";
// import moment from "moment-timezone";
const OkrPerformanceContainer=(props)=>{
    const{client,selectedMember,dateRangeTofilter}=props
    // const [dateRange, setDateRange] = React.useState([startOfQuarter, endOfQuarter])
    const [cascadeProgress,setCascadeProgress]=React.useState([])
    const [cascadechartData,setcascadechartData]=React.useState([])
    const [NoncascadeProgress,setNomCascadeProgress]=React.useState([])
    const [NoncascadechartData,setNoncascadechartData]=React.useState([])
    const [cascadeTotalCount,setCascadeTotalCount]=React.useState()
    const [nonCascadeTotalCount,setNonCascadeTotalCount]=React.useState()
    const [loading,setLoading]=React.useState(false)
    const [nonchartloading,setNonChartLoading]=React.useState(false)
    const [chartloading,setChartLoading]=React.useState(false)
    React.useEffect(()=>{
        if(selectedMember){
            getMemberCascadeData(selectedMember?.id,5,0)
            getMemberCascadeChartData(selectedMember?.id,0)
        }
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedMember])

    const getMemberCascadeData = async(id,first,offset,pagination=false)=>{
        setLoading(true)
        let progressData=[]
        // eslint-disable-next-line no-unused-vars
        let chartData=[]
     
        // let isStartDateSame = moment.utc(dateRangeTofilter[0]).isSame(moment.utc(dateRange[0]))
        // let isEndDateSame = moment(dateRangeTofilter[1]).isSame(moment.utc(dateRange[1]))
        const { data } = await client.query({
            query: MEMBER_CASCADE_DATA,
            variables: {
                okr_Duedate_Range: dateRangeTofilter, employee: id, createdBy_Ne: id, offset: offset, first: first
            },
            // fetchPolicy:(isStartDateSame && isEndDateSame) ? 'cache-first' : 'network-only'
          });
        if(data?.AllCascadingObjectives){
            progressData=data?.AllCascadingObjectives?.edges?.map(({node})=>(
                {
                    id:node?.okr?.id,
                    title:node?.okr?.title,
                    progress:node?.progress,
                    isOkr:true,
                    okrType:node?.okr?.okrType
                }
            ))
            setCascadeProgress(progressData)
            setCascadeTotalCount(data?.AllCascadingObjectives?.totalCount)
            setLoading(false)
        }
    }
    const getMemberCascadeChartData = async(id,offset,pagination=false)=>{
        setChartLoading(true)
        const { data } = await client.query({
            query: MEMBER_CASCADE_DATA,
            variables: {
                okr_Duedate_Range: dateRangeTofilter, employee: id, createdBy_Ne: id, offset: offset*100, first: 100
            },
            // fetchPolicy:(isStartDateSame && isEndDateSame) ? 'cache-first' : 'network-only'
          });
          if(data?.AllCascadingObjectives){
            if (cascadechartData?.edges?.length) {
                let obj = {
                  edgeCount: cascadechartData?.edgeCount + data?.AllCascadingObjectives?.edgeCount,
                  edges: [...cascadechartData?.edges,...data?.AllCascadingObjectives?.edges],
                  totalCount: data?.AllCascadingObjectives?.totalCount
                }
                setcascadechartData(obj)
            } else {
                setcascadechartData(data?.AllCascadingObjectives)
            }
            setChartLoading(false)
          }
    }

    const getMemberNonCascadeData = async(id,first,offset,pagination=false)=>{
        setLoading(true)
        let progressData=[]
       
        const { data } = await client.query({
            query:  MEMBER_NON_CASCADE_DATA,
            variables: {
                okr_Duedate_Range: dateRangeTofilter,createdBy: id, offset: offset, first: first
            },
            // fetchPolicy:(isStartDateSame && isEndDateSame) ? 'cache-first' : 'network-only'
          });
          if(data?.AllNonCascadingObjectives){
            progressData=data?.AllNonCascadingObjectives?.edges?.map(({node})=>(
                {
                    id:node?.okr?.id,
                    title:node?.okr?.title,
                    progress:node?.progress,
                    isOkr:true,
                    okrType:node?.okr?.okrType
                }
            ))
            setNomCascadeProgress(progressData)
            setNonCascadeTotalCount(data?.AllNonCascadingObjectives?.totalCount)
            setLoading(false)
        }
    }

    const getMemberNonCascadeChartData = async(id,offset)=>{
        setNonChartLoading(true)
        // let isStartDateSame = moment.utc(dateRangeTofilter[0]).isSame(moment.utc(dateRange[0]))
        // let isEndDateSame = moment(dateRangeTofilter[1]).isSame(moment.utc(dateRange[1]))
        const { data } = await client.query({
            query:  MEMBER_NON_CASCADE_DATA,
            variables: {
                okr_Duedate_Range: dateRangeTofilter,createdBy: id, offset: offset*100, first: 100
            },
            // fetchPolicy:(isStartDateSame && isEndDateSame) ? 'cache-first' : 'network-only'
          });
          if(data?.AllNonCascadingObjectives){
                if (NoncascadechartData?.edges?.length) {
                    let obj = {
                    edgeCount: NoncascadechartData?.edgeCount + data?.AllNonCascadingObjectives?.edgeCount,
                    edges: [...NoncascadechartData?.edges,...data?.AllNonCascadingObjectives?.edges],
                    totalCount: data?.AllNonCascadingObjectives?.totalCount
                    }
                    setNoncascadechartData(obj)
                } else {
                    setNoncascadechartData(data?.AllNonCascadingObjectives)
                }
                setNonChartLoading(false)
            }
    }

    return (
        <>
            <OkrPerformanceView 
            {...props}
             cascadeProgress={cascadeProgress}
              cascadechartData={cascadechartData}
              NoncascadeProgress={NoncascadeProgress}
              NoncascadechartData={NoncascadechartData}
              loading={loading}
               cascadeTotalCount={cascadeTotalCount}
                nonCascadeTotalCount={nonCascadeTotalCount}
                callNonCascade={()=>{getMemberNonCascadeData(selectedMember?.id,5,0);getMemberNonCascadeChartData(selectedMember?.id,0)}}
                getOkrPerformanceCascaded={(first,offset,pagination)=>{getMemberCascadeData(selectedMember?.id,first,offset,pagination)}}
                getOkrPerformanceNonCascaded={(first,offset,pagination)=>{getMemberNonCascadeData(selectedMember?.id,first,offset,pagination)}}
                getAllNonCascadedObjectives={(e,offset)=>getMemberNonCascadeChartData(e,offset)}
                getAllCascadedObjectives={(e,offset)=> getMemberCascadeChartData(e,offset)}
                nonchartloading={nonchartloading}
                chartloading={chartloading}
                />
        </>
    )
}

export default  compose(withApollo)(OkrPerformanceContainer)
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import { setItem } from '../../core';
import { ACCESS_TOKEN } from 'config';
import { REFRESH_TOKEN } from 'config';

const RedirectAuthenticateView = props => {
  const { location } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const paramsMatch = window.location.href.match(/\?.+/);
    if (paramsMatch) {
      const params = new URLSearchParams(paramsMatch[0]);
      setItem(ACCESS_TOKEN, params.get(ACCESS_TOKEN));
      setItem(REFRESH_TOKEN, params.get(REFRESH_TOKEN));
      setLoading(false);
      if (location) {
        window.location = params.get('redirectTo');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Spin size="large" spinning={loading} tip="Authenticating...">
      <div style={{ width: '80vh', height: '80vh' }}></div>
    </Spin>
  );
};

export default RedirectAuthenticateView;

// @ts-nocheck
import React from "react";
import { compose } from "../../core";
import '../css/RoleDefinition.css'
import RoleDefinitionHomeView from "../components/RoleDefinitionHomeView";
import RoleDefinitionSideBar from "../components/RoleDefinitionSideBar";
import { withApollo } from '@apollo/client/react/hoc';
import { role_definition_permission } from "Permissions/roledefinition.permission";
import { globalPermissionValidator } from "modules/look";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { Spin } from "antd";

const RoleDefinitionHomeContainer = (props) => {

    const { userPermission} = props
    const [permission, setPermission] = React.useState()
    const [current_tab, setCurrentTab] = React.useState()
    const [back_to_home, SetbackToHome] = React.useState()

    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(role_definition_permission, userPermission)
            setPermission(permission)
        }
    }, [userPermission])

    // to set the route to local storage when sidebar selection is changed
    React.useEffect(() => {
        if (current_tab) {
            const cached_navigation = JSON.parse(localStorage?.getItem('role_definition_navigation'))
            let role_definition_navigation = cached_navigation ? { ...cached_navigation, parent_route: current_tab }
                : { parent_route: current_tab }
            localStorage.setItem('role_definition_navigation', JSON.stringify(role_definition_navigation))
        }
    }, [current_tab])

    // to handle route when user navigate back to role definition
    const role_definition_navigation = JSON.parse(localStorage?.getItem('role_definition_navigation'))
    if (!current_tab) {
        if (role_definition_navigation) {
            setCurrentTab(role_definition_navigation?.parent_route)
        } else {
            setCurrentTab({ key: 'employee_level', label: 'Employee Level' })
        }
    }


    return (
        <>

            {(permission && permission?.required_permission) && (
                <div className="rd-root rd-root-style" >
                    <div className="rd-sidebar-container">
                        <RoleDefinitionSideBar current_tab={current_tab} setCurrentTab={(e) => { setCurrentTab(e) }} permission={permission}  {...props} />
                    </div>
                    <div className="rd-router-outlet">
                        <RoleDefinitionHomeView setCurrentTab={(e) => { setCurrentTab(e) }} current_tab={current_tab} permission={permission} SetbackToHome={SetbackToHome} back_to_home={back_to_home} {...props} />
                    </div>
                </div>
            )}
            {(permission && !permission?.required_permission) && (<NoPermissionView />)}
            {
                !permission && (
                    <div className="role-no-permission-container">
                        <Spin spinning={true} size="large"></Spin>
                    </div>
                )
            }
        </>
    );

};

export default compose(withApollo)(RoleDefinitionHomeContainer);

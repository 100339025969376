import React from 'react';

import { AdminLayout } from '../../look';
import ROUTE from '../route';
import { Button} from 'antd';

import StoryFormComponent from './StoryFormComponent';
import { EyeOutlined } from '@ant-design/icons';
import { PREVIEW_DIV_ID, PREVIEW_TITLE_ID} from './AdminStoryDetailView';

const EditStoryView = props => {
  const [previewData, setPreviewData] = React.useState(null);
  const [previewTab, setPreviewTab] = React.useState(null);

  React.useEffect(() => {
    const content = previewTab?.document?.getElementById(PREVIEW_DIV_ID);
    if (content) {
      content.innerHTML = previewData?.storyData?.content;
    }
    setPreviewTab(previewTab);
  }, [previewTab, previewData]);

  // Close the preivew tab on unmount (basically route change)
  const handlePrevieTabClose = React.useRef(() => {});
  if (previewTab) {
    handlePrevieTabClose.current = () => {
      previewTab && previewTab.close();
    };
  }
  React.useEffect(() => {
    return () => handlePrevieTabClose.current();
  }, []);

  if (typeof window !== 'undefined') {
    window.onbeforeunload = e => {
      // I'm about to refresh! do something...
      handlePrevieTabClose.current();
    };
  }

  // Open preview tab with default data
  const openPrivewTab = () => {
    if (typeof window !== 'undefined' && previewData !== null) {
      const tabRef = window.open(ROUTE.storyDetailPreview, 'storyDetailPreview');
      tabRef.addEventListener(
        'load',
        function () {
          const content = tabRef?.document?.getElementById(PREVIEW_DIV_ID);
          const title = tabRef?.document?.getElementById(PREVIEW_TITLE_ID);
          // const tag = tabRef?.document?.getElementById(PREVIEW_TAG_ID);
          if (content) {
            title.innerHTML = `Title : ${previewData?.storyData?.title}`;
            content.innerHTML = previewData?.storyData?.content?.editor?.getData();
            // tag.innerHTML = previewData?.storyData?.storyTags.map(({ node }) => renderTag(node?.name));
          }
        },
        true
      );
      setPreviewTab(tabRef);
    }
  };

  return (
    <AdminLayout
      table={false}
      active={ROUTE.story}
      title="Edit Story"
      backLink={`${ROUTE.story}`}
      FormComponent={props.storyById && <StoryFormComponent setPreviewData={setPreviewData} {...props} />}
      extra={<Button onClick={openPrivewTab} disabled={previewData === null} icon={<EyeOutlined />} shape="circle" />}
    />
  );
};

export default EditStoryView;

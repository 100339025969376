import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Spin } from "antd";
import { SCAPE_PERSONALITY_SURVEY_ID } from "../../../config";
import { getIntFromString } from "modules/look";
import ReUsablePagination from "modules/look-v2/Components/ReUsablePagination";
import React from "react";
import styled from "styled-components";
import ScapePreferneceTabContiner from "../containers/scape-preference-tab-conytainer";
import ScapeReportGraphMainContainer from "../containers/scape-graph-main-container";
import ScapeReportContainer from "../containers/ScapeReportContainer";
import '../css/general-scape.css';
import { scape_report_home_icon } from "../icons";
import OrganizationLeverFilter from "./organization-level-filter";
import ScapeSearchView from "./scape-search-filter";


const ScapeReportHomeView = ( props ) => {

    const { scape_list, organization_level_list, getScapeById, onFilter, table_data, data_loading, organization_loading, selected_survey_id, paginationData, scapeTableData, parametric_groups, group, main_loading } = props


    const [ graph_level, SetGraphLevel ] = React.useState()
    const [ scapeName, setScapeName ] = React.useState()
    const [ selected_Scape_id, setSelectedScapeId ] = React.useState()
    const [ selected_employee_list, selectedSmployeeList ] = React.useState( [] )
    const [ selected_choice, setSelectedChoiceData ] = React.useState()
    const [ tab, SetTab ] = React.useState( 'graph' )
    const [ selected_personality_type, setPersonalityType ] = React.useState(
        {
            id: 1,
            label: 'Personality type Detailed view',
            value: 'personality_type_detailed_view'
        },
    )
    const [ grahp_data_set, SetGraphData ] = React.useState()
    const [ preference_handler, SetPreferenceHandler ] = React.useState()
    const [ choice_selction, setChoiceSelection ] = React.useState()
    const [ selected_preference_choice, setSelelctedPreferenceChoice ] = React.useState()
    const [ effect, setEffect ] = React.useState()
    const [enable_action_btn,setEnableActionBtn]=React.useState()
    const handleSurveyResultMenu = ( data ) => {
        setScapeName( data?.name )
        setSelectedScapeId( data?.id )

    }
    //Filter values
    const filter_data = [
        {
            key: "user_FirstName",
            label: "Employee Name",
            ui_flow: "input_box",
            default: true
        },
        // {
        //     key: "role_Title_Icontains",
        //     label: "Role",
        //     ui_flow: "input_box",
        // },
        // {
        //     key: "employeeLevel_Icontains",
        //     label: "Employee Level",
        //     ui_flow: "input_box",
        // }
    ]

    const personality_type = [
        {
            id: 1,
            label: 'Personality type Detailed view',
            value: 'personality_type_detailed_view'
        },
        {
            id: 2,
            label: 'Personality type Overview',
            value: 'personality_type_overview'
        }
    ]
    const userSurveys = () => (
        <Menu style={{ maxHeight: '200px', overflow: 'auto' }}>
            {
                scape_list?.length ?
                    scape_list.map( ( item ) =>
                        <Menu.Item key={item?.id} className='scape-filter-text' onClick={() => handleSurveyResultMenu( item )}>
                            {item?.name}
                        </Menu.Item>
                    ) :
                    <Menu.Item >
                        No Survey found
                    </Menu.Item>
            }
        </Menu>
    );

    const PersonalityTypes = () => (
        <Menu style={{ maxHeight: '200px', overflow: 'auto' }}>
            {

                personality_type.map( ( item ) =>
                    <Menu.Item key={item?.id} className='scape-filter-text' onClick={() => setPersonalityType( item )}>
                        {item?.label}
                    </Menu.Item>
                )
            }
        </Menu>
    )

    const viewReport = () => {
        getScapeById( { emp_list: selected_employee_list, scape_id: selected_Scape_id } )
    }

    const handlePagination = ( filters ) => {
        scapeTableData( { after: filters.after } )
    }

    React.useEffect( () => {
        if ( table_data && preference_handler?.length && choice_selction && selected_preference_choice && group ) {
            SetGraphData( {
                table_data,
                preference_handler,
                type: choice_selction?.graph_type,
                choice_selction,
                selected_preference_choice,
                group
            } )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ table_data, preference_handler, selected_preference_choice, group ] )

    React.useEffect(() => {
        return () => {
          if (table_data) {
            localStorage.setItem('review_module','Team Dynamics')
          }
        }
      }, [table_data])
    return (
        <div className="scape-root">
            <div className="scape-heading-container" style={{justifyContent:"center"}}>
                {/* <img onClick={( e ) => history.push( '/shoal' )} style={{ paddingLeft: ".5em", cursor: "pointer" }} src={scape_report_home_icon?.new_back_arrow} alt="" /> */}
                <h4 className="scape-main-title" style={{textAlign:"center"}}>Team Dynamics</h4>
                {/* <div></div> */}
            </div>
            {/* <div style={{ position: 'relative' }}>
                <SideToolScapeView
                    {...props}
                    widthOfContainer={24}
                    containerBorder={""}
                    spacing={'0px'}
                    hideTopDivider={true}
                    keepDesign={true}
                    tool_id={SCAPE_TOOL_ID}
                    toolReturn={e => setToolReturn( e )}
                />
            </div> */}
            <div className="scape-data-container">
                <div className="scape-selection-section">
                    <OrganizationLeverFilter {...props} organization_level_list={organization_level_list} organization_loading={organization_loading} selected_choice_data={( e ) => setSelectedChoiceData( e )} selected_employe_list={( e ) => selectedSmployeeList( e )} choice_selection_data={( e ) => setChoiceSelection( e )} enable_button={(e)=>setEnableActionBtn(e)} />
                    <Dropdown overlay={userSurveys} trigger={'click'} overlayStyle={{zIndex:"1050"}}>
                        <DropdownButton className='scape-dropdown' >
                            <p style={{ marginTop: '10px', whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", color: scapeName ? "#303030" : "#BFBFBF" }}>{scapeName ? scapeName : 'Select survey'}</p>
                            <img src={scape_report_home_icon?.dropdown_Arrow} alt="" style={{ width: "13px", height: "10px", marginTop: "-5px" }} />
                            <DownOutlined />
                        </DropdownButton>
                    </Dropdown>
                </div>
                <Button className="scape-view-report-btn" disabled={selected_employee_list?.length === 0 || !selected_Scape_id || enable_action_btn} onClick={() => viewReport()}>View Report</Button>
            </div>
            <Spin size="large" spinning={main_loading}>
                {selected_survey_id && <>
                    <div className="scape-data-container">
                        <div className="scape-space-between-container">
                            <div style={{ display: 'flex', flexDirection: "row", gap: "1em" }}>
                                <div className="scape-toggle-container">
                                    <div className={tab === 'graph' ? "scape-toggle-button-active" : "scape-toggle-button"} onClick={() => SetTab( "graph" )}>Graph</div>
                                    <div className={tab === 'table' ? "scape-toggle-button-active" : "scape-toggle-button"} onClick={() => SetTab( "table" )}>Table</div>
                                </div>
                                {tab === 'table' && getIntFromString( selected_survey_id ) === SCAPE_PERSONALITY_SURVEY_ID && <Dropdown overlay={PersonalityTypes} overlayStyle={{zIndex:"1050"}} trigger={'click'}>
                                    <DropdownButton className='scape-dropdown' >
                                        <p style={{ marginTop: '10px', whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", color: selected_personality_type ? "#303030" : "#BFBFBF" }}>{selected_personality_type?.label ? selected_personality_type?.label : 'Select survey'}</p>
                                        <img src={scape_report_home_icon?.dropdown_Arrow} alt="" style={{ width: "13px", height: "10px", marginTop: "-5px" }} />
                                        <DownOutlined />
                                    </DropdownButton>
                                </Dropdown>}
                            </div>

                            <ScapeSearchView filterData={filter_data} onfilterChange={( e ) => onFilter( { id_filter: { emp_list: selected_employee_list, survey_id: selected_survey_id }, search: e } )} />
                        </div>
                    </div>
                    {
                        tab === 'graph' && ( parametric_groups?.length > 1 || getIntFromString( selected_survey_id ) === SCAPE_PERSONALITY_SURVEY_ID) && (
                            <ScapePreferneceTabContiner {...props} selected_choice={selected_choice} SetPreferenceHandler={SetPreferenceHandler} selected_drop_choice={( e ) => setSelelctedPreferenceChoice( e )} selectedEffect={( e ) => setEffect( e )} graph_level={graph_level} />
                        )
                    }
                    <Spin spinning={data_loading}>

                        <div >
                            {
                                tab === 'table' && (

                                    (parametric_groups?.length > 1 && table_data?.length) ?
                                        <ScapeReportContainer {...props}
                                            personality_survey={getIntFromString( selected_survey_id ) === SCAPE_PERSONALITY_SURVEY_ID}
                                            selected_personality_type={selected_personality_type}
                                        />
                                        : null
                                        
                                )
                            }
                        </div>


                        <div className="scape-data-container scape-space-between-container" style={{width:"100%"}} >
                            {
                                tab === 'graph' &&(
                                    grahp_data_set &&(parametric_groups?.length > 1 || getIntFromString( selected_survey_id ) === SCAPE_PERSONALITY_SURVEY_ID) ?
                                        <ScapeReportGraphMainContainer {...props} setEffect={setEffect} effect={effect} grahp_data_set={grahp_data_set} graph_level={graph_level} SetGraphLevel={SetGraphLevel} is_personality={getIntFromString( selected_survey_id ) === SCAPE_PERSONALITY_SURVEY_ID} />
                                        : (!data_loading&&<div className="poppins" style={{ fontSize: '1.3em', fontWeight: '500', marginTop: '3em',display:"flex",width:"100%",justifyContent:'center',alignItems:'center'}}>No Data Found</div>)
                                )



                            }

                        </div>
                    </Spin>


                </>}
                {table_data?.length === 0 && selected_employee_list?.length > 0 && selected_Scape_id && !data_loading &&!main_loading&&
                    <div className="scape-data-container">
                        <h4 className="scape-filter-text" style={{ marginTop: "10em" }}>No Data Found</h4>
                    </div>}

                {paginationData?.pageInfo?.hasNextPage && <ReUsablePagination
                    resultsCountText={'Results per page'}
                    paginationType="viewMore"
                    size='small'
                    paginationData={paginationData}
                    style={{ margin: '1.3em 0' }}
                    className='pagination_css custom_new_table grow-custom-table'
                    queryFunc={handlePagination}
                    hidePageDropdown={true}
                />}

            </Spin>

        </div>

    )

}

export default ScapeReportHomeView

const DropdownButton = styled( Button )`
// width:100%;
// display:flex;
// justify-content:space-between;
// align-items:center;
// gap:5px
// z-index:0;
`
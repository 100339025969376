import React from "react";
import CreateScapeVew from "../components/createScapeView";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc'
import { GET_ALL_SURVEYS, GET_SCAPE_BY_ID } from "../graphql/listingQueries.gql";
import { withCreateScape, withUpdateScape } from "./scapeReportOperations";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { getIntFromString, getScapeStringFromInt } from "modules/look";

const CreateScapeContainer = ( props ) => {

    const { client, me, createScape, match, updateScape } = props
    const [ survey_list, setSurveyList ] = React.useState()
    const [ selected_scape, setSelectedScape ] = React.useState()
    const history = useHistory()

    React.useEffect( () => {
        if ( match?.params?.id ) {
            getscapeById( match.params.id )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ match ] )
    React.useEffect( () => {
        if ( me ) {
            getSurveyLists()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ me ] )

    const getscapeById = async ( id ) => {
        const { data } = await client.query( {
            query: GET_SCAPE_BY_ID,
            fetchPolicy: 'network-only',
            variables: { id: getScapeStringFromInt( id ) }
        } );
        if ( data?.scapeById ) {
            setSelectedScape( data?.scapeById )
        }
    }

    const getSurveyLists = async ( filter ) => {
        const { data } = await client.query( {
            query: GET_ALL_SURVEYS,
            fetchPolicy: 'network-only',
            variables: { ...filter }
        } );
        if ( data?.allSurveys ) {
            let lists = data?.allSurveys?.edges?.map( ( { node } ) => node )
            setSurveyList( lists )
        }
    }

    const CreateScape = async ( data ) => {
        let scapeDatainput = {
            name: data?.value?.name,
            description: data?.value?.description,
            active: data?.action,
            surveyId: data?.value?.survey
        }
        let response
        if ( selected_scape !== undefined ) {
            response = await updateScape( { name: data?.value?.name, description: data?.value?.description, active: data?.action, id:getIntFromString(selected_scape?.id) } )
        }
        else{
            response = await createScape( { scapeData: scapeDatainput } )
        }
        
        if ( response ) {
            if ( selected_scape !== undefined ) {
                message.success( 'Update scape successfully' )
            }
            else {
                message.success( 'Create new scape successfully' )
            }
            history.push( '/scape-report-admin' )
        }
    }
    return (
        <CreateScapeVew {...props}
            survey_list={survey_list}
            getSurveyLists={( filter ) => getSurveyLists( filter )}
            onSubmit={( value ) => CreateScape( value )}
            onCancel={() => history.push( '/scape-report-admin' )}
            selected_scape={selected_scape}></CreateScapeVew>
    )
}

export default compose( withApollo, withCreateScape, withUpdateScape )( CreateScapeContainer );
import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import CompetencySelectorView from "../components/competency-selector-view";
import { ALL_COMPETENCY_CATEGORY } from "../graphql/ca-create-queries.gql";

const CompetencySelectorContainer = (props) => {
    const { client,permission,onSubmit,existing_category,employee_level } = props
    const [competency_category_list,SetCompetencyCategoryList] = React.useState([])

    React.useEffect(()=>{
        if(permission?.required_permission){
            getCompetencyCategoryList()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[permission])

    const getCompetencyCategoryList=async(filter)=>{
        const { data } = await client.query({
            query: ALL_COMPETENCY_CATEGORY,
            fetchPolicy: 'network-only',
            variables: { ...filter,employeeLevel:employee_level, first: 25 }
        });
        if (data?.allCompetencyCategory) {
            let list = data?.allCompetencyCategory?.edges?.map(({ node }) => node)
            list = list?.filter(item=>!existing_category?.includes(item?.id))
            SetCompetencyCategoryList(list)
        }
    }

    const saveCategory=(id)=>{
        let category = competency_category_list?.find(item=>item?.id===id)
        if(category){
            onSubmit(category)
        }
    }

    return (
        <>
            <CompetencySelectorView
                {...props}
                competency_category_list={competency_category_list}
                getCompetencyCategoryList={getCompetencyCategoryList}
                saveCategory={(id)=>{saveCategory(id)}}
            />
        </>
    )

}

export default compose(withApollo)(CompetencySelectorContainer)
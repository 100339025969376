import gql from 'graphql-tag';


const VERTICAL_TYPE_FIELDS = gql`
  fragment VerticalTypeFields on VerticalType {
    id
    name
    description
  }
`;

const VERTICAL_TYPE = gql`
   ${VERTICAL_TYPE_FIELDS}
  fragment VerticalType on VerticalType {
    ...VerticalTypeFields
    subvertical {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          ...VerticalTypeFields
        }
      }
      totalCount
      edgeCount
    }
  }
`;

const VERTICAL_TYPE_CONNECTION = gql`
  ${VERTICAL_TYPE}
  fragment VerticalTypeConnection on VerticalTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...VerticalType
      }
    }
    totalCount
    edgeCount
  }
`;

export const ORGANIZATION_DETAIL_VERTICALS_BY_ORG_ID_QUERY = gql`
  ${VERTICAL_TYPE_CONNECTION}
  query verticals(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
    $organization: ID
    $subvertical: [ID]
  ) {
    verticals(
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
      organization: $organization
      subvertical: $subvertical
    ) {
      ...VerticalTypeConnection
    }
  }
`;

const TEAM_TYPE = gql`
  fragment TeamType on TeamType {
    id
    name
  }
`;


const TEAM_TYPE_CONNECTION = gql`
  ${TEAM_TYPE}
  fragment TeamTypeConnection on TeamTypeConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...TeamType
      }
    }
    totalCount
    edgeCount
  }
`;


export const ORG_DETAIL_TEAMS_BY_VERTICAL_ID_QUERY = gql`
  ${TEAM_TYPE_CONNECTION}
  query Teams(
    $vertical: ID!
    $first: Int
    $offset: Int
    $orderBy: [String]
    $name_Icontains: String
    $description_Icontains: String
  ) # $organization: ID
  {
    Teams(
      vertical: $vertical
      first: $first
      offset: $offset
      orderBy: $orderBy
      name_Icontains: $name_Icontains
      description_Icontains: $description_Icontains
    ) # organization: $organization
    {
      ...TeamTypeConnection
    }
  }
`;


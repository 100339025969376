import React from 'react';
import { Form, Slider } from 'antd';
import '../css/survey-look.css'
const FormItem = Form.Item;

const SurveyRangeField = props => {
  const { min, max, marks, defaultValue, step, onAfterChange, trackStyle, handleStyle, disabled,selectedId,autoscroll,margin,hideMarker,tip_hidden,required,className, ...rest } = props;
  const [default_marker,setDefaultMarker]=React.useState({})
  // eslint-disable-next-line no-unused-vars
  const [visible,setVisible]=React.useState(false)
  React.useEffect(() => {
    if(max ===100&&!hideMarker){
      setDefaultMarker({
        0: '0',
        10:'10',
        20:'20',
        30:'30',
        40:'40',
        50:'50',
        60:'60',
        70:'70',
        80:'80',
        90:'90',
        100: '100',
      })
    }
    else if(max ===10&&!hideMarker){
      setDefaultMarker({
        0: '0',
        1:'1',
        2:'2',
        3:'3',
        4:'4',
        5:'5',
        6:'6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
      })
    }
    else if(hideMarker) {
      setDefaultMarker({
        [defaultValue]: defaultValue
      })
    }
    else {
      setDefaultMarker({
        [min]: min,
        [max]: max,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[min,max])
  const rangeAfterChange =(value) => {
    onAfterChange(value)

    
  }
  const visibleTooltip =()=>{
    setTimeout(()=>{
      setVisible(true)
    },100)
  }
  return (
    <div className={className || 'generic-survey-range-slider'} style={{ marginBottom: margin?margin:'5vh', padding: '0px 24px 0px 0px'}}>
      <FormItem required={props.required}style={{ marginBottom: margin?margin:'24px'}}  labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset: 1 }} {...rest}>
        <Slider
          id={selectedId}
          min={min}
          max={max}
          marks={default_marker}
          defaultValue={defaultValue}
          step={max===10?0.5:1} 
          onAfterChange={e=>rangeAfterChange(e)}
          trackStyle={trackStyle}
          handleStyle={handleStyle}
          disabled={disabled}
          onChange={e=>{autoscroll&&autoscroll(selectedId);visibleTooltip()}}
        />
      </FormItem>
    </div>
  );
};

export default SurveyRangeField;


import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import { withUpdateBadgeFamily } from "./kudos_operation";
import BadgeFamilyUpdateView from "../components/badge_family_update_view";
import { GET_BADGEFAMILY_BY_ID } from "../graphql/badgefamilyByid.gql";

const BadgeFamilyUpdateContainer = (props) => {
  const {client,badge_family_update_data}=props
  const [loading,setLoading]=React.useState(false)
  const [badgefamily,setbadgefamily]=React.useState()

  React.useEffect(()=>{
    if(badge_family_update_data){
        getBadgeFamily({id:badge_family_update_data.badge_family_id})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[badge_family_update_data])

  const getBadgeFamily=async (filter)=>{
    try {
        setLoading(true)
    const { data } = await client.query({
      query: GET_BADGEFAMILY_BY_ID,
      variables: filter,
      fetchPolicy:"network-only"
    });
    if(data){
      setbadgefamily(data?.badgeFamilyById)
      setLoading(false)
    }
    } catch (error) {
        
    }
  }

  return (
    <>
       <BadgeFamilyUpdateView badgefamily={badgefamily} setLoading={setLoading} loading={loading} {...props} />
    </>
  );
};

export default compose(withApollo,withUpdateBadgeFamily)(BadgeFamilyUpdateContainer);

import gql from "graphql-tag";

export const CREATE_TICKET_MUTATION =gql`
  mutation createTicket($attachments: String,$description: String!,$raisedBy: Int!,$title: String!) {
    createTicket(attachments:$attachments,description:$description,raisedBy:$raisedBy,title:$title) {
        ticket{
            id
        }
    }
}
`
export const CREATE_TICKET_COMMNET =gql`
  mutation createTicketComment($content: String!,$ticketId: ID!) {
    createTicketComment(content:$content,ticketId:$ticketId) {
      ticketComment{
        id
        comment
        createdAt
        sentBy{
          id
          firstName
          lastName
          profile{
            id
            profileImage
          }
        }
      }
    }
  }
`

export const UPDATE_TICKET_MUTATION = gql`
  mutation UpdateTicket($id:Int,$title:String,$description:String,$status:String,) {
    UpdateTicket(UpdateTicketInput: $UpdateTicketInput) {
      ticket {
        id
      }
    }
  }`

// @ts-nocheck
import React from "react";
import { Row, Col, Button,  Menu, Spin, Form, Space, Dropdown, Radio, Select, message } from "antd";
import styled from "styled-components";
import { MetaTags, getIntFromString, scrolltoview, CKEditorField, withMarkdownFieldValue } from "../../look";
import {  DownOutlined } from "@ant-design/icons";
import GenericTool from "../../tool/containers/GenericTool";
import ReactMarkdown from '../../look/components/ReactMarkdown';
import new_back_arrow from '../../assets/new_back_arrow.svg'

const FeedbackUpdate = props => {
  const { getToolById, history, SurveyById, feedbackById, updateFeedback, okrByrecipiant, okrSearch } = props
  const [loading, setloading] = React.useState(false);
  const [keyResultsActive, setKeyResultActive] = React.useState(false);
  const [Recipient, setRecipient] = React.useState();
  const [recipientName, setrecipientName] = React.useState();
  const [form] = Form.useForm();

  const [feedBackType, setFeedBackType] = React.useState();
  const [questionList, setquestionList] = React.useState([]);
  const [answersList, setAnswers] = React.useState([]);
  const [initialdataSet, setinitialdataSet] = React.useState(false);
  const [okrList, setokrList] = React.useState([])
  const [okrid, setokrid] = React.useState();
  const feedBackTypeEnum = {
    1: 'Motivational/Recognition',
    2: 'Developmental/Constructive'
  }

  React.useEffect(() => {
    if (okrByrecipiant) {
      setokrList(okrByrecipiant?.edges?.map(({ node }) => { return { label: node?.okr?.title, id: node?.okr?.id } }))
    }
  }, [okrByrecipiant])

  React.useEffect(() => {
    if (feedbackById && !initialdataSet) {
      setRecipient(feedbackById?.feedbackTo?.user?.employee?.id)
      setrecipientName(`${feedbackById?.feedbackTo?.user?.firstName} ${feedbackById?.feedbackTo?.user?.lastName}`)
      if (feedbackById?.okr) {
        setKeyResultActive(true)
        setokrid(feedbackById?.okr?.id)
      }
      if (feedbackById?.feedbackType === 'MOTIVATIONAL_RECOGNITION') {
        setFeedBackType("1")
      }
      else if (feedbackById?.feedbackType === 'DEVELOPMENTAL_CONSTRUCTIVE') {
        setFeedBackType("2")
      }
      let answers = []
      feedbackById.response.answerSet.edges.forEach(item => {
        let data = {
          questionId: getIntFromString(item?.node?.question?.id),
          answer: item?.node?.answer
        }
        answers.push(data)
      })

      setAnswers(answers)
      setinitialdataSet(true)
    }
  }, [feedbackById, initialdataSet])


  const back = () => {
    history.goBack();
  };


  React.useEffect(() => {
    if (SurveyById) {
      let list = SurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges?.map(({ node }) => node)
      setquestionList(list)
    }
  }, [SurveyById])

  // function handleMenuClick(node) {
  //   setRecipient(getIntFromString(node?.user?.employee?.id))
  //   setrecipientName(node?.user?.firstName)
  // }



  const deselect = () => {
    setokrid(null)
  }


  const FeedBackTypemenu = () => (
    <Menu >
      <Menu.Item key="1" onClick={() => setFeedBackType("1")}>
        Motivational/Recognition
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setFeedBackType("2")}>
        Developmental/Constructive
      </Menu.Item>
    </Menu>
  );


  const submitAnswer = (data, value) => {
    let feed = withMarkdownFieldValue("answer", { answer: value })
    let answerSet = {
      questionId: getIntFromString(data?.id),
      ...feed
    }
    let tempAnswer = [].concat(answersList)
    let previous = tempAnswer.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
    if (previous) {
      let index = tempAnswer.indexOf(previous)
      tempAnswer.splice(index, 1)
      tempAnswer.push(answerSet)
    }
    else {
      tempAnswer.push(answerSet)
    }
    setAnswers(tempAnswer)

  }


  const handleValidation = () => {
    if (Recipient && feedBackType) {
      let answerdata = []
      questionList.forEach(data => {
        if (!data?.questionAddress) {
          let answer = answersList.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
          answerdata.push(answer)
        }
        else {
          let typeValidations = data?.questionAddress?.split(':')
          if (typeValidations?.includes(feedBackType)) {
            let answer = answersList.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
            answerdata.push(answer)
          }
        }
      });


      let valid = true
      questionList.forEach(data => {
        if (!data?.questionAddress) {
          if (data?.required) {
            let answer = answerdata.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id) && item?.answer?.trim() !== '')
            if (!answer) {
              valid = false
            }
          }

        }
        else {
          let typeValidations = data?.questionAddress?.split(':')
          if (typeValidations?.includes(feedBackType) && data?.required) {
            let answer = answerdata.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id) && item?.answer?.trim() !== '')
            if (!answer) {
              valid = false
            }
          }
        }
      });
      if (valid) {
        handleSubmit(answerdata)
      }
      else {
        message.error('please fill in the required field')
      }
    }
    else {
      message.error('please fill in the required field')
    }
  }

  async function handleSubmit(value) {
    setloading(true)
    const response = await updateFeedback({
      id: getIntFromString(feedbackById?.id),
      answers: value,
      detailFillIn: feedbackById?.detailFillIn,
      feedbackType: feedBackTypeEnum[feedBackType],
      okrId: getIntFromString(okrid)
    });
    if (response && response?.id) {
      setloading(false)
      back()
    } else {
      setloading(false)
    }
  }

  const showQuestion = (data) => {
    let createCustomID = 'fb_' + getIntFromString(data?.id)
    let previous = answersList.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
    if (!data?.questionAddress) {
      return (
        <Col span={24}>
          <Row style={{ marginTop: '15px' }}>
            {data?.required && (
              <span style={{ color: '#ff4d4f' }}>*</span>
            )}
            <ReactMarkdown >{data?.questionText}</ReactMarkdown>
          </Row>
          <div id={createCustomID} style={{ paddingRight: '20px' }} onClick={() => { scrolltoview(createCustomID) }}>
            <CKEditorField
              initialValue={previous?.answer}
              changeHandler={(e) => { submitAnswer(data, e) }}
            />
          </div>
          {/* <textarea style={{width:'100%',height:'150px',padding:'10px',resize:'none'}} id={createCustomID} onClick={()=>{scrolltoview(createCustomID)}} defaultValue={previous?previous?.answer:''} onChange={(e)=>{submitAnswer(data,e?.target?.value)}}></textarea> */}
        </Col>
      )
    }
    else {
      let validations = data?.questionAddress?.split(':')
      if (validations?.includes(feedBackType)) {
        return (
          <Col span={24}>
            <Row style={{ marginTop: '15px' }}>
              {data?.required && (
                <span style={{ color: '#ff4d4f' }}>*</span>
              )}
              <ReactMarkdown >{data?.questionText}</ReactMarkdown>
            </Row>
            <div id={createCustomID} style={{ paddingRight: '20px' }} onClick={() => { scrolltoview(createCustomID) }}>
              <CKEditorField
                initialValue={previous?.answer}
                changeHandler={(e) => { submitAnswer(data, e) }}
              />
            </div>
            {/* <textarea style={{width:'100%',height:'150px',padding:'10px',resize:'none'}} id={createCustomID} onClick={()=>{scrolltoview(createCustomID)}} defaultValue={previous?previous?.answer:''} onChange={(e)=>{submitAnswer(data,e?.target?.value)}}></textarea> */}
          </Col>
        )
      }
    }

  }

  const WithNoOption = (
    <Col span={24}>
      {
        questionList?.length && (
          questionList.map((data) =>
            showQuestion(data)
          )
        )
      }

    </Col>
  )
  return (
    <>
      <>
        <MetaTags
          title="Update Feedback"
          description="This is Update Feedback page."
        />
        <Spin spinning={loading}>
          {/* Page Title and Back Arrow */}
          <div className="main-title-container back-arrow-global" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', position: 'relative', margin: '0em 1em', paddingBottom: '1.5em' }}>
            <img onClick={back} src={new_back_arrow} alt="back-arrow" style={{ alignContent: 'center', height: '21%', cursor: 'pointer' }} />
            <p className="goalheader poppins" style={{ margin: 'auto', textAlign: 'center', color: 'black' }}>Update Feedback</p>
            <img src={new_back_arrow} alt="back-arrow" style={{ alignContent: 'center', height: '21%', visibility: 'hidden' }} />
          </div>
          {
            SurveyById && (
              <div style={{ padding: '0px 20px' }}>

                <Form
                  form={form}
                  name={"value"}
                  onFinish={() => handleValidation()}>
                  <Row gutter={5} style={{ borderBottom: "1px solid #d3d3d3", paddingBottom: '10px' }}>
                    <ColScroll lg={{ span: 15 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }} style={{ height: '70vh', overflow: 'auto' }}>

                      <Col>
                        <p style={{ fontSize: '15px' }}>Recipient : {recipientName}</p>
                      </Col>

                      <Col style={{ marginTop: '20px' }} >
                        <p style={{ fontSize: '15px' }}>Is this Feedback related to OKR?</p>
                        <Radio.Group style={{ marginLeft: '15px' }} defaultValue={keyResultsActive} onChange={(e) => { setKeyResultActive(e.target.value); deselect() }}>
                          <Space direction="vertical">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Space>
                        </Radio.Group>
                        {
                          keyResultsActive && Recipient && (
                            <Row gutter={10} style={{ marginTop: '15px' }}>
                              <Col lg={{ span: 12 }} md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }} >
                                <p style={{ fontSize: '15px' }}>OKR</p>
                                <Select
                                  showSearch={true}
                                  onSearch={(e) => { okrSearch({ employee: Recipient, okr_Goalstatement_Icontains: e }) }}
                                  style={{
                                    width: '250px'
                                  }}
                                  filterOption={(input, option) => option}
                                  placeholder="Select OKR"
                                  onChange={(value, options) => { setokrid(value) }}
                                  id="fd_okr" onClick={() => { scrolltoview('fd_okr') }}
                                  value={okrid}
                                >

                                  {
                                    okrList?.map(item => {
                                      return <Select.Option key={item?.id} value={item?.id} >{item?.label}</Select.Option>
                                    }
                                    )
                                  }

                                </Select>
                              </Col>

                            </Row>
                          )
                        }
                      </Col>
                      <Row style={{ marginTop: '30px' }} >
                        <p style={{ fontSize: '15px', marginRight: '15px' }}><span style={{ color: '#ff4d4f' }}>*</span> This feedback is :</p>
                        <Dropdown overlay={FeedBackTypemenu}  >
                          <DropdownButton >

                            <p style={{ marginTop: '10px', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'left' }}>{feedBackType ? feedBackTypeEnum[feedBackType] : 'Select Feedback type'}</p>
                            <DownOutlined />

                          </DropdownButton>
                        </Dropdown>
                      </Row>
                      {/* <Checkbox style={{marginTop:'20px'}} defaultValue={fillInOption} onChange={()=>{setfillInOption(!fillInOption)}}><p style={{fontSize:'15px'}}>Show Detailed Fill-in Option</p></Checkbox> */}

                      {/* survey component */}
                      {
                        feedBackType && (
                          <div style={{ marginTop: '20px' }}>
                            <div>
                              {WithNoOption}
                            </div>
                          </div>
                        )
                      }

                    </ColScroll>
                    <ColScroll lg={{ span: 9 }} md={{ span: 0 }} xs={{ span: 0 }} sm={{ span: 0 }} style={{ height: '70vh', overflow: 'auto' }}>
                      {getToolById ?
                        <div >
                          <Col>

                            <GenericTool
                              {...props}
                              widthOfContainer={40}
                              containerBorder={""}
                              spacing={'0px'}
                              hideTopDivider={true}
                            ></GenericTool>
                          </Col>
                        </div> :
                        <div align="center">
                          <Spin></Spin>
                        </div>
                      }
                    </ColScroll>
                  </Row>

                  <div align="center">
                    <CancelButton type="default" onClick={back} style={{ marginTop: '30px', margin: '1em 1em 0em 1em' }}>Cancel</CancelButton>
                    <CreateButton type="primary" htmlType="submit" style={{ marginTop: '30px', margin: '1em 1em 0em 1em' }}>Update</CreateButton>
                  </div>
                </Form>
              </div>
            )
          }

        </Spin>
      </>
    </>
  );
};

export default FeedbackUpdate;

const CreateButton = styled(Button)`
width:150px;
height:40px;
border-radius:5px;
margin-top:10px;
`
const CancelButton = styled(Button)`
width:150px;
height:40px;
color: #B0CB1F;
border-radius:5px;
border-style: solid;
border-color: #B0CB1F;
margin-top:10px;
&:hover {
    background-color: #B0CB1F;
    color: white;
  }
`

const DropdownButton = styled(Button)`
width:230px;
display:flex;
justify-content:space-between;
align-items:center;
gap:5px
`

const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#B0CB1F;
}
`
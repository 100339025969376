import { Col, Tooltip,Button,Row,Spin } from "antd";
import React from "react";
import InfoCircle from '../../assets/InfoCircle.svg'
import Left_Arrow from '../../assets/left-arrow-new.svg'
import Right_arrow from '../../assets/right-arrow-new.svg'
import Table_section from '../../assets/table-section-icon.svg'
import { displayUtcToUserTimezone, getIntFromString} from "modules/look";
import CollaborateFilter from "./CollaboratorFilter";
import { NumberPaginantion } from "modules/look-v2";
import one_on_one_route from '../../one-on-one/route/index'
 const CollaborateRequestView =(props)=>{
    const {AssignPendingList,AssignAcceptedList,load,me,onfilter,actionData,counts,onPaginantion,navigateRoute}=props 
    const [dataSource, setDataSource] = React.useState([])
    const [ActedData, setActedData] = React.useState([])

    React.useEffect(()=>{
        if(AssignPendingList?.length||AssignAcceptedList?.length){
            setDataSource(AssignPendingList)
            setActedData(AssignAcceptedList)
        }
        else{
            setDataSource([])
            setActedData([])
        }
    },[AssignPendingList,AssignAcceptedList])

    React.useEffect(()=>{
        if(actionData){

        }
    },[actionData])
    const filterData = [
        {type:'Objective',key:'Objective'}
      ]

    const headers=[
        {
            title:"Item",
            info_icon:false,
            width:'300px'    
        },
        {
            title:"Type",
            info_icon:false, 
            width:'100px'     
        },
        {
            title:"Creator",
            info_icon:false,
            width:'150px'   
        },
       
        {
            title:"Created",
            info_icon:false,
            width:'100px'    
        },
        {
            title:"Status",
            info_icon:false,
            width:'150px'    
        },
        {
            title:"Action",
            info_icon:false,
            width:'150px'    
        },
      ]
    
      const SubmitFilter =(data)=>{
        onfilter(data)
      }
    const getMetingData =(value)=>{
        let sortedList =value?.edges?.sort(function(a,b){
            return new Date(b?.node?.createdAt) - new Date(a?.node?.createdAt);
          })
          return getIntFromString(sortedList[0]?.node?.meeting?.id)
    }
    return (
        <>
            <Spin spinning={load}>
                <Row align="center" justify="center"style={{gap:"20px",padding:"20px 1em"}}>
                    {/* <Dropdown overlay={Filtermenu}  >
                        <DropdownButton >
                            <p style={{marginTop:'10px',width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left'}}>{selectedFilter?selectedFilter:'Select'}</p>
                            <DownOutlined />
                        </DropdownButton>
                    </Dropdown> */}
                    <CollaborateFilter
                    me={me}
                    onfilterChange={(e) => SubmitFilter(e)}
                    filterData={filterData}
                    // defaultFilter = {filterData[0]}
                    />
                </Row>
                <div className="overflow-div">
                    <Col className="custom-table-v2-discard">
                            <div className="custom-table-tr">
                                {
                                    headers?.map(item=>(
                                        <div className="table-header-section" style={{width:item?.width||'100px',minWidth:item?.width||'100px'}}>
                                            {item?.title}
                                            {
                                                item?.info_icon && (
                                                    <Tooltip title={item?.info_content}>
                                                        <img src={InfoCircle} alt="" style={{cursor:"pointer"}} />
                                                    </Tooltip>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="custom-table-body custom-table-body-discard">
                                {
                                dataSource?.map(item=>(
                                <Col style={{paddingRight:"5px"}}>
                                    <div className="custom-table-tr table-body-tr-border" style={{borderLeft:item?.node?.createdBy?.user?.id===me?.employee?.user?.id?"5px solid #4CCB1F":"5px solid #B0CB1F"}}>
                                        
                                        <div className="table-section-td" style={{width:"300px",minWidth:"300px"}}>
                                        {item?.node?.createdBy?.user?.id===me?.employee?.user?.id&&<img src={Left_Arrow} alt="" />}
                                        { item?.node?.createdBy?.user?.id!==me?.employee?.user?.id&&<img src={Right_arrow} alt="" />}
                                            <span style={{cursor:"pointer"}} onClick={()=>navigateRoute(`/role/objective-keyresult/${'collaboration'}/detail/${getIntFromString(item?.node?.id)}`)}><Tooltip title={item?.node?.okr?.title} placement="topLeft">{item?.node?.okr?.title}</Tooltip></span>
                                        </div>

                                        {item?.node?.okr?.okrType==='KEY_RESULT'&&<div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                            Key Result
                                        </div>}
                                        {item?.node?.okr?.okrType==='MILESTONE'&&<div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                            Milestone
                                        </div>}
                                        {item?.node?.okr?.okrType==='OBJECTIVE'&&<div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                            Objective
                                        </div>}

                                        <div className="table-section-td center text-transform-capitalize" style={{width:"150px",minWidth:"150px"}}>
                                            {item?.node?.createdBy?.user?.firstName}
                                        </div>
                                        
                                        <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                            {displayUtcToUserTimezone(item?.node?.createdAt,'DD MMM YYYY' )}
                                        </div>
                                        {item?.node?.requestStatus==='PENDING'&&<div className="table-section-td center"  style={{width:"150px",minWidth:"150px",color:"#CB861F"}}>
                                            Pending
                                        </div>}
                                        {item?.node?.requestStatus==='NEEDS_DISCUSSION'&&<div className="table-section-td center"  style={{width:"150px",minWidth:"150px",color:"#009AF1"}}>
                                            Needs Discussion
                                        </div>}
                                        <div className="table-section-td center" style={{width:"150px",minWidth:"150px",justifyContent:'center',alignItems:'center'}}>
                                           {item?.node?.requestStatus==='PENDING'&&<Button className="view-button"  onClick={()=>navigateRoute(`/role/objective-keyresult/${'collaboration'}/detail/${getIntFromString(item?.node?.id)}`)}>View</Button>}
                                           {item?.node?.requestStatus==='NEEDS_DISCUSSION'&&item?.meeting?.edges?.length?<Button className="view-button"onClick={()=>navigateRoute(one_on_one_route?.viewMeeting?.replace(":meetingid",getMetingData(item?.meeting)))}>Go to 1:1</Button>:null}
                                           {item?.node?.requestStatus==='NEEDS_DISCUSSION'&&!item?.meeting?.edges?.length?<Button className="view-button" style={{border:"none",color:"#B0CB1F",boxShadow:"none",fontSize:"16px"}}  onClick={()=>navigateRoute(`/role/objective-keyresult/${'collaboration'}/detail/${getIntFromString(item?.node?.id)}`)}>F2F Discussion</Button>:null}
                                        </div>
                                    </div>
                                </Col>
                                ))
                                }
                                {!dataSource?.length&&(
                                    <Row align="middle"  style={{width:"100%",justifyContent:"center",flexDirection:'column',padding:"40px 0px"}}>
                                            <h4 className="table-section-head"> No Pending Collaboration Requests</h4>
                                    </Row>
                                )}
                            </div>
                            <Row align="middle" style={{width:"100%",justifyContent:"center",flexDirection:'column',padding:"20px 0px"}}>
                                <span style={{width:"60%",height:"2px",background:'#D9D9D9'}}></span>
                                <h4 className="table-section-head"><img src={Table_section} alt="" style={{paddingLeft:"10px",width:"30px"}}/> Resolved Requests</h4>
                            </Row>
                            <div className="custom-table-body custom-table-body-discard">
                                {
                                ActedData?.map(item=>(
                                <Col style={{paddingRight:"5px"}}>
                                    <div className="custom-table-tr table-body-tr-border" style={{borderLeft: item?.node?.createdBy?.user?.id===me?.employee?.user?.id?"5px solid #4CCB1F":"5px solid #B0CB1F"}}>
                                        
                                        <div className="table-section-td" style={{width:"300px",minWidth:"300px"}}>
                                            {item?.node?.createdBy?.user?.id===me?.employee?.user?.id&&<img src={Left_Arrow} alt="" />}
                                            {item?.node?.createdBy?.user?.id!==me?.employee?.user?.id&&<img src={Right_arrow} alt="" />}
                                            <span style={{cursor:"pointer"}} onClick={()=>navigateRoute(`/role/objective-keyresult/${'collaboration'}/detail/${getIntFromString(item?.node?.id)}`)}><Tooltip title={item?.node?.okr?.title} placement="topLeft">{item?.node?.okr?.title}</Tooltip></span>
                                        
                                        </div>

                                        {item?.node?.okr?.okrType==='KEY_RESULT'&&<div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                            Key Result
                                        </div>}
                                        {item?.node?.okr?.okrType==='MILESTONE'&&<div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                            Milestone
                                        </div>}
                                        {item?.node?.okr?.okrType==='OBJECTIVE'&&<div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                            Objective
                                        </div>}
                                        <div className="table-section-td center text-transform-capitalize" style={{width:"150px",minWidth:"150px"}}>
                                            {item?.node?.createdBy?.user?.firstName}
                                        </div>
                                        
                                        <div className="table-section-td center" style={{width:"100px",minWidth:"100px"}}>
                                        {displayUtcToUserTimezone(item?.node?.createdAt,'DD MMM YYYY' )}
                                        </div><div className="table-section-td center"  style={{width:"150px",minWidth:"150px",color:"#4CCB1F"}}>
                                        Accepted
                                        </div>
                                        <div className="table-section-td center" style={{width:"150px",minWidth:"150px",justifyContent:"space-between",alignItems:'center'}}>
                                            <Button className="view-button" onClick={()=>navigateRoute(`/role/objective-keyresult/${'collaboration'}/detail/${getIntFromString(item?.node?.id)}`)}>View</Button>
                                        </div>
                                    </div>
                                </Col>
                                ))
                                }
                                {!ActedData?.length&&(
                                    <Row align="middle" style={{width:"100%",justifyContent:"center",flexDirection:'column',padding:"40px 0px"}}>
                                            <h4 className="table-section-head"> No Resolved Collaboration Requests</h4>
                                    </Row>
                                )}
                            </div>
                    </Col>
                </div>
                <Row justify="end">
                    <NumberPaginantion totalPage={counts?.totalCount} onActive={(e)=>onPaginantion(e)}/>
                </Row>
            </Spin>
        </>
    )
 }

 export default CollaborateRequestView
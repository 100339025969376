import React from 'react';
import { Row, Col, Form } from 'antd';

import {
    TextField,
    VerticalTransferField,
    getIntFromString,
    ButtonsComponent,
    CKEditorField,
    VerticalHeadField,
    SubverticalAutoComplete
} from '../../look';

import ROUTE from '../route';
import { useParams } from 'react-router-dom';

export const CKEditorFieldName = 'description';

const VerticalFormComponent = props => {
    const { onSubmit, vertical, orgId } = props;
    const [button, setButton] = React.useState('');
    const { id } = useParams();

    const initialValue = {
        name: vertical && vertical.name,
        organizationId:
            vertical &&
            vertical.organization &&
            Number(getIntFromString(vertical.organization.id)),
        parentverticalId:
            vertical &&
            vertical.parentverticalId &&
            Number(getIntFromString(vertical.parentverticalId.id)),
        employees:
            vertical &&
            vertical.employees &&
            vertical.employees.edges[0] &&
            vertical.employees.edges.map(({ node }) => getIntFromString(node.id)),
        heads:
            vertical &&
            vertical.heads &&
            vertical.heads?.edges?.map(({ node }) => Number(getIntFromString(node.id))),
        description: vertical && vertical.description && vertical.description
    };
    return (
        <Row>
            <Col span={24}>
                <Form
                    layout="vertical"
                    onFinish={values => onSubmit(values, button)}
                    initialValues={initialValue}
                    required={false}
                    scrollToFirstError={true}
                >
                    <Row>
                        <Col lg={{ span: 10, offset: 1 }} md={10}>
                            <Row>
                                <Col lg={14} md={20}>
                                    <TextField name="name" label="Name" rules={[{ required: true, message: 'Name is required!' }]} />
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={10} md={13} xs={24}>
                            <Row>
                                <Col span={24}>
                                    <VerticalHeadField
                                        orgId={
                                            (vertical &&
                                                vertical.organization &&
                                                getIntFromString(vertical.organization.id)) ||
                                            orgId
                                        }
                                        onAdd={ROUTE.addemployee}
                                        name="heads"
                                        label="Heads"
                                        mode="multiple"
                                    // rules={[{ required: true, message: 'Head is required!' }]}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <SubverticalAutoComplete
                                        name="parentverticalId"
                                        label="Parent-Vertical"
                                        // rules={[{ required: true, message: 'SubVertical is required!' }]}
                                        onAdd={`${ROUTE.organizationDetailLink}${vertical?.organization ? getIntFromString(vertical?.organization?.id) : id
                                            }?args=openmodal&closeonsave`}
                                        onEdit
                                        placeholder={`Search Sub-Vertical`}
                                        enterButton
                                        organizationId={
                                            (vertical &&
                                                vertical.organization &&
                                                Number(getIntFromString(vertical.organization.id))) ||
                                            Number(orgId)
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={3} md={0} xs={0} />
                        <Col span={22} offset={1}>
                            <CKEditorField
                                name={CKEditorFieldName}
                                label="Description"
                                initialValue={vertical && vertical.description}
                            // rules={[{ required: true, message: 'Description is required!' }]}
                            />
                        </Col>
                    </Row>
                    {/* <MarkdownField
            name="description"
            label="Description"
            initialValue={verticalByVerticalId && verticalByVerticalId.description}
            // rules={[{ required: true, message: 'Description is required!' }]}
          /> */}
                    <Row justify="start">
                        <Col span={24}>
                            <VerticalTransferField
                                name="employees"
                                label="Employees"
                                // rules={[{ required: true, message: 'Employees are required!' }]}
                                initialValues={initialValue && initialValue.employees}
                                onAdd={ROUTE.addemployee}
                                orgId={
                                    (vertical &&
                                        vertical.organization &&
                                        Number(getIntFromString(vertical.organization.id))) ||
                                    Number(orgId)
                                }
                            />
                        </Col>
                    </Row>
                    <ButtonsComponent setButton={setButton} />
                </Form>
            </Col>
        </Row>
    );
};

export default VerticalFormComponent;

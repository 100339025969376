//@ts-ignore
import React from 'react';
import { Form, Input } from 'antd';

const NumberInputField = props => {
  const { className, onChange, disabled, offset = 1, onBlur,margin,placeholder,maxValue ,maxLength,...rest } = props;
  const FormItem = Form.Item;

  return (
    <div style={{marginBottom:"-50px"}}>
      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset }} {...rest}>
          <NumericInput className={className||"test-input"} onChange={onChange} disabled={disabled} maxValue={maxValue} type='number' pattern="[0-9]+"
            inputmode="numeric" />
      </FormItem>
    </div>
  );
};

export default NumberInputField;

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const reg = /[+-]?([0-9]*[.])?[0-9]+/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      if(this.props?.maxValue){
        if(Number(value)<=Number(this.props?.maxValue)){
          this.props.onChange(Number(value));
        }
      }else{
        this.props.onChange(Number(value));
      }
      
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (!(typeof value === 'number' && isFinite(value))) {
      if (value?.charAt(value?.length - 1) === '.' || value === '-') {
        valueTemp = value?.slice(0, -1);
      }
      onChange(valueTemp?.replace(/0*(\d+)/, '$1'));
      if (onBlur) {
        onBlur();
      }
    }
  };

  render() {
    const { value } = this.props;
    // eslint-disable-next-line no-unused-vars
    const title = value ? (
      <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    ) : (
      'Input a number'
    );
    return (
        <Input type='number' className='number-input' autoComplete='off' {...this.props} onChange={this.onChange} onBlur={this.onBlur}  maxLength={3} />
    );
  }
}

function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

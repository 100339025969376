import React from "react";
import ExportReportView from "modules/poc-dashboard/components/dashboard/exportReportView";
import { VERTICAL_LIST } from "modules/poc-dashboard/graphql/organizationQuery.gql";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { ORG_POC_VERTICAL_SURVEY_RESPONSE } from "modules/poc-dashboard/graphql/pocDashboardQuery.gql";
import { displayUtcToUserTimezone, exportToCsv } from "modules/look";
const ExportReportContainer = (props) => {
    const { me, client, onCancel } = props;
    const [verticals, setVerticals] = React.useState()
    const [data_list, setDataList] = React.useState()
    const [export_loading, setExportLoading] = React.useState(false)
    const [export_title, setExportTitle] = React.useState()
    React.useEffect(() => {
        if (me) {
            verticalList()
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me])

    React.useEffect(() => {
        if (data_list && data_list?.length) {
            exportToCsv(export_title ? export_title : "survey_assessmemt_report", data_list)
            setExportLoading(false)
            onCancel()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data_list])

    const verticalList = async () => {
        const { data } = await client.query({
            query: VERTICAL_LIST,
            fetchPolicy: 'network-only'
        });
        if (data.verticals?.edges?.length) {
            setVerticals(data.verticals?.edges?.map(({ node }) => node))
        }
    }

    const fetchSurveyData = async (filter) => {
        const { data } = await client.query({
            query: ORG_POC_VERTICAL_SURVEY_RESPONSE,
            variables: {
                ...filter,
                id: filter?.vertical_id?.key,
                survey: filter?.survey_id?.key,
                first: 100,
                offset: filter?.currentPage * 100,
            },
            fetchPolicy: 'network-only',
        });
        return data?.vertical || {};
    };
    const Capitalize = (value) => value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : '';
    const processEmployeeData = (vertical) => {
        return vertical?.employees?.edges?.map(({ node }) => ({
            FirstName: Capitalize(node?.user?.firstName),
            LastName: Capitalize(node?.user?.lastName),
            Status: node?.user?.responseSet?.edges?.length ? 'Completed' : 'Pending',
            ResponseDate: node?.user?.responseSet?.edges[0]?.node?.responseDate ? displayUtcToUserTimezone(node?.user?.responseSet?.edges[0]?.node?.responseDate, 'MMM DD YYYY') : "-",
            Team: node?.teamMembersEmployee?.edges?.length
                ? node?.teamMembersEmployee?.edges.map(team => team?.node?.name).join(', ')
                : node?.teamManagerEmployee?.edges.map(team => team?.node?.name).join(', '),
            Vertical: vertical?.name,
        }));
    };

    const getVerticalSurveyExportData = async (filter) => {
        setExportLoading(true)
        let allEmployeeData = [];
        let currentPage = 0;
        let hasNextPage = true;
        
        while (hasNextPage) {
            const employees = await fetchSurveyData({ ...filter, currentPage });
            allEmployeeData = [...allEmployeeData, ...processEmployeeData(employees)];
            hasNextPage = employees?.pageInfo?.hasNextPage || false;
            currentPage++;
        }
        setDataList(allEmployeeData);
    }
    return (
        <>
            <ExportReportView verticals={verticals} {...props} export_loading={export_loading}
                exportData={(value) => getVerticalSurveyExportData(value)}
                setExportTitle={(title) => setExportTitle(title)} />
        </>
    )
}

export default compose(withApollo)(ExportReportContainer)
/* eslint-disable array-callback-return */


import { Tabs } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import BarsLevelTraversing from "./BarsLevelTraversing";




const BarsAddInfo = (props) => {
    const { competency, handleExampleChange, compData, indexOfElements, activeLevel, ratingScales, 
        setActiveLevel, activeCompetency, invalidBars, mainCompetency, invalidBehaviourExamples, setActiveCompetency,
        subCompetencies } = props



   

    const handleTraversingOfLevels = (direction, level, index) => {
     

        switch (direction) {
            case "Forward":

                if (mainCompetency) {
                    if (activeLevel < (competency?.employeeLevel?.length - 1)) {
                        setActiveLevel(activeLevel + 1)
                    } else {
                        setActiveLevel(0)
                    }
                } else {
                    if (activeLevel < (competency?.employeeLevel?.length - 1)) {
                        setActiveLevel(activeLevel + 1)
                    } else {
                        setActiveLevel(0)
                        if (activeCompetency < (subCompetencies?.length - 1)) {
                            setActiveCompetency(activeCompetency + 1)
                        } else {
                            setActiveCompetency(0)
                        }

                    }
                }

                break;

            case "Backward":

                if (mainCompetency) {
                    if (activeLevel >= (competency?.employeeLevel?.length - 1)) {

                        setActiveLevel(activeLevel - 1)
                    } else if (activeLevel === 0) {
                        setActiveLevel(competency?.employeeLevel?.length - 1)
                    }
                    else if (activeLevel < (competency?.employeeLevel?.length - 1)) {
                        setActiveLevel(activeLevel - 1)
                    }
                    else {
                        setActiveLevel(competency?.employeeLevel?.length - 1)
                    }
                } else {

                }

              
                break;

            default:
                break;

        }

        window.scrollTo(0, 0);

    }




    React.useEffect(() => {

        if (activeCompetency) {
            if (mainCompetency) {
                handleToggleLevel()
            } else {
                handleToggleLevelSubCompetency()
            }

        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCompetency, competency])

    
  

    React.useEffect(() => {

        if (competency) {
            if (mainCompetency) {
                handleToggleLevel()
            } else {
                handleToggleLevelSubCompetency()
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [competency, activeLevel])


    

    const handleToggleLevelSubCompetency = (e) => {
        
        let element = document.getElementById(`rc-tabs-0-panel-${parseInt(activeCompetency)}`);

        if (element?.childNodes?.length) {
            let elementsToChange = element.childNodes[0].childNodes[0].childNodes[0].childNodes[0]
            
            
            if( competency?.employeeLevel?.length){
                competency.employeeLevel.map((level, index) => {
    
                    if( elementsToChange.childNodes[index]){
                        if (level?.status === 'pending') {
                            elementsToChange.childNodes[index].classList.add('incomplete-level-bars')
                        } else {
                            elementsToChange.childNodes[index].classList.add('complete-level-bars')
                           
                        }
                    }
                   
                }
        
                )
            }
          
        }
       


    }



    const handleToggleLevel = (e) => {
        let elements = document.getElementsByClassName('ant-tabs-nav-list')[indexOfElements];

        if(competency?.employeeLevel?.length){
            competency.employeeLevel.map((level, index) => {

                if(elements.getElementsByClassName("ant-tabs-tab")[index]){
                   if( elements.getElementsByClassName("ant-tabs-tab")[index]){
                       if (level?.status === 'pending' ) {
                           elements.getElementsByClassName("ant-tabs-tab")[index].classList.add('incomplete-level-bars')
                       } else {
                           elements.getElementsByClassName("ant-tabs-tab")[index].classList.add('complete-level-bars')
                       }
                   }
                  
                }
           }
   
           )
        }
       

    }


    

    return (
        <>
         {
                           (competency?.employeeLevel?.length > 0) ?

                    <Tabs activeKey={activeLevel.toString()} onChange={(e) => setActiveLevel(parseInt(e))}
                        className='bars-tab-panel' >

                       {
                            competency?.employeeLevel?.map((level, index) =>
                                <Tabs.TabPane id={`comp-${activeCompetency}-${activeLevel}`} className="bars-tab-panel-title" tab={level?.name} key={index} >
                                    <div>
                                        {
                                          (level?.ratingScales?.length) ?  level?.ratingScales?.map((scale) =>
                                                <div>
                                                    <div className="create-bar-sub-heading create-bar-margin-seperator">{scale?.name}</div>
                                                    <TextArea onChange={(e) => handleExampleChange(e.target.value, level, scale, compData)}
                                                        className='bars-rating-scale-text'
                                                        value={scale?.behaviourExample}
                                                        placeholder='Type...'
                                                        defaultValue={scale?.behaviourExample}
                                                    ></TextArea>
                                                </div>   
                                            )
                                            :
                                            <div align='center' style={{fontSize:'1.4em',fontWeight:500,width:'100%'}}>No threshold is available</div>
                                        }

                                        {
                                            invalidBars &&
                                            <p className="poppins" style={{ color: 'red', fontSize: '1em', marginTop: '0.8em' }}>Please enter some values</p>
                                        }

                                        {
                                            invalidBehaviourExamples &&
                                            <p className="poppins" style={{ color: 'red', fontSize: '1em', marginTop: '0.8em' }}>Customize the rating scale per the sub-competency to proceed.</p>
                                        }


                                      
                                    {
                                        (ratingScales?.length) ?
                                        <BarsLevelTraversing handleTraversingOfLevels={handleTraversingOfLevels}
                                        status={competency && competency?.employeeLevel[activeLevel]?.status}
                                        invalidBehaviourExamples={invalidBehaviourExamples}
                                        level={level} index={index} />
                                        :null
                                    }
                                       
                                    </div>
                                </Tabs.TabPane>

                            )

                            // )
                                }
                            
                         

                    </Tabs>
                    :
                    <div align='center' style={{fontSize:'1.2em',fontWeight:500,width:'100%'}}>No employee level is available</div>

                        }
                    

            

        </>
    );
};

export default BarsAddInfo;


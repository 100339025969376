/* eslint-disable no-useless-concat */
import React from 'react';
import {Col,Row, Spin } from 'antd';
import { displayUtcToUserTimezone, getIntFromString } from 'modules/look';
import green_arrow from '../../assets/green_arrow.svg'
import { Link } from 'react-router-dom';
import IndDashboardDropDownContainer from 'modules/look-v2/Container/IndDashboardDropDownContainer';

const UpcomingMilestonesOrganization = (props) => {
  const { upcomingMilestones } = props

  const getOwner = (val) => {
    return `${<span className='text-transform-capitalize'>{val?.node?.okr?.owner?.user?.firstName}</span>}` + ' ' + `${<span className='text-transform-capitalize'>{val?.node?.okr?.owner?.user?.lastName}</span>}`
  }
  return (
    <> 
    <Spin spinning={upcomingMilestones ? false : true}>
        <Row style={{width:'100%',background: "#FFFFFF",flexDirection:'column',padding:"20px"}}>
        <div className="chart-title" style={{textAlign:"left",fontFamily: 'Manrope'}}>Upcoming Milestones</div>
        <div className="performamce-overFlow" style={{maxHeight:"430px",paddingBottom:"10px",height:"100%",minHeight:"430px",paddingTop:"30px"}}>
              {upcomingMilestones?.edges?.length>0 &&
                upcomingMilestones?.edges?.map((val, ind) =>
                    <Row style={{ borderBottom: '1px solid #E8E8E8',width:"100%",gap:"20px",flexWrap:"nowrap",marginTop:"10px" }}>
                      <Col span={18}
                        style={{ marginTop: '20px', marginBottom: '10px' }} >
                        <Row style={{flexWrap:"nowrap"}}>
                          <div className="graph-title" style={{maxWidth:"350px",paddingLeft:"0px",
                            textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', fontWeight: '600',fontFamily: 'Manrope'
                          }}>
                            {val?.node?.okr?.title}
                          </div>
                          <div>
                          <Link to={`/role/objective-keyresult/milestone/view/${getIntFromString(val?.node?.okr?.id)}`} target="_blank">
                            <img style={{ height: '10px', width: '10px',cursor:"pointer",marginLeft:"5px" }}src={green_arrow} alt=''/>
                          </Link>
                          </div>
                        </Row>
                        <Row>
                          <div style={{ marginBottom: '10px', fontStyle: 'normal', paddingRight: '5px', color: '#8D8D8D' }}>{getOwner(val)} </div>
                          <IndDashboardDropDownContainer okrId={getIntFromString(val?.node?.okr?.id)}  record={val?.node?.okr} {...props}/> 
                         
                        </Row>
                      </Col>
                      <Col span={6}
                        style={{ marginTop: '20px', marginBottom: '10px' }} >
                        <div className="graph-title" style={{ overflowWrap: 'break-word', color: '#E86161',paddingLeft:"0px",fontWeight:"400" }}>{displayUtcToUserTimezone(val?.node?.okr?.duedate, "DD MMM YYYY")}
                        </div>
                      </Col>
                    </Row>
                )
              }
               {upcomingMilestones?.edges?.length===0&&<div style={{display:"flex",width:"100%",justifyContent:"center",alignItems:'center',paddingTop:"100px"}}>
            <h4 className="graph-title" style={{textAlign:"center",fontWeight:"400"}}>No Upcomming MileStones</h4>
        </div>}
        </div>
        
        </Row>
       
     </Spin>
    </>
  )
}
export default UpcomingMilestonesOrganization


import { Button, Row } from "antd";
import React from "react";



const BarsDeleteConfirmation = (props) => {
  const { message, subMessage, onCancel, onConfirm, icon, confirm_action_label, cancel_action_label,confirm_class,cancel_class } = props


  return (
    <div className="poppins">
      {
        icon &&
        <Row justify="center">
          <img alt="" style={{width:'4em',marginBottom:'1.5em'}} src={icon} />
        </Row>
      }

      <div className="bars-popup-message">{message}</div>
      <div className="bars-popup-sub-message">{subMessage}</div>

     
      <Row justify="center" style={{gap:'1em'}}>
        {
          confirm_action_label &&
          <Button className={confirm_class} onClick={(e) => onConfirm()} >{confirm_action_label}</Button>
        }
       
       {
          cancel_action_label &&
          <Button className={cancel_class} onClick={(e) => onCancel()} >{cancel_action_label}</Button>
        }
       
      </Row>


    </div>
  );
};

export default BarsDeleteConfirmation;

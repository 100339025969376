// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';
import { message } from "antd"
import { BARS_DELETE } from '../graphql/BarsQueries.gql';
import { CREATE_BARS_IN_BULK_MUTATION } from '../graphql/BarsCreateQueries.gql';



export const withDeleteBar = Component =>
    graphql(BARS_DELETE, {
      props: ({ mutate}) => ({
        deleteBehaviourallyAnchoredRating: async values => {
          try {
            const {
              // eslint-disable-next-line no-unused-vars
              data: { deleteBehaviourallyAnchoredRating }
            } = await mutate({
              variables: {
                ...values
              }
            });
            message.destroy();
            message.success('Successfully deleted');
            return true;
          } catch (e) {
            message.destroy();
            message.error("Couldn't perform the action");
            console.error(e);
          }
        }
      })
    })(Component);


    export const withCreateBarsInBulk = Component =>
graphql(CREATE_BARS_IN_BULK_MUTATION, {
  props: ({ mutate, history }) => ({
    createBulkBehaviourallyAnchoredRatings: async values => {
      try {
        const {
          data: { createBulkBehaviourallyAnchoredRatings }
        } = await mutate({
          variables: {
            ...values
          }
        });

        message.destroy();

        return createBulkBehaviourallyAnchoredRatings;
      } catch (e) {
        message.destroy();
        message.error("Couldn't perform the action");
        console.error(e);
      }
    }
  })
})(Component);
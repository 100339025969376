import React from 'react';
import {  Modal, Spin } from 'antd';

import { compose } from '../../core';
import { AddIcon, EditIcon, getIntFromString, withMarkdownFieldValue } from '../../look';

import OperandFormComponent from '../components/OperandFormComponent';
import { CKEditorFieldName } from '../components/OperandFormComponent';

import { withEditOperand, withAddOperand } from './TextSectionOperations';

const AddOperand = props => {
  const { btn, /* onSubmit, */ createOperand, editOperand,  operand, handleAdd } = props;
  const [visible, setVisible] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);

  const handleSubmit = async ({ weightedQuestions, ...values }) => {
    setActionLoading(true);
    try {
      if (operand?.id) {
        const response = await editOperand(
          withMarkdownFieldValue(CKEditorFieldName, { id: getIntFromString(operand.id), ...values })
        );
        setActionLoading(false);
        response && setVisible(false);
      } else {
        const response = await createOperand({
          operandData: withMarkdownFieldValue(CKEditorFieldName, values),
          weightedQuestions
        });
        setActionLoading(false);
        handleAdd(response.id);
        response && setVisible(false);
        // response && onSubmit(response);
      }
    } catch (e) {
      setActionLoading(false);
      throw Error(e.message);
    }
  };

  return (
    <>
      {btn === 'add' ? (
        <AddIcon tooltipSuffix="Operand" color="primary" size="sm" onClick={() => setVisible(true)} />
      ) : (
        <EditIcon tooltipSuffix="Operand" color="primary" size="sm" onClick={() => setVisible(true)} />
      )}

      <Modal
        destroyOnClose
        centered
        visible={/* true || */ visible}
        onCancel={() => setVisible(false)}
        // footer={null}
        width="75%"
        title={btn === 'add' ? 'Add Operand' : 'Edit Operand'}
        footer={null}
      >
        <Spin spinning={actionLoading} size="medium">
          <OperandFormComponent
            btnText={btn === 'add' ? 'Add' : 'Save'}
            operand={operand}
            setVisible={setVisible}
            onSubmit={handleSubmit}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default compose(withEditOperand, withAddOperand)(AddOperand);

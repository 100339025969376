// @ts-nocheck
import React from "react";
import { compose } from "modules/core";
import CompetencyAddEditView from "../components/CompetencyAddEditView";
import { COMPETENCY_CATEGORY_LIST, COMPETENCY_DETAIL_BY_ID_QUERY, COMPETENCY_LIST } from "../graphql/CompetencyQuries.gql";
import { withCreateCompetencySkill, withUpdateCompetencySkill } from "../RoleDefinitionOperation";
import { getCompetencyTypeBase64StringFromInt, getIntFromString } from "modules/look";


const CompetencyAddEditContainer = (props) => {

    const { current_comptency_tab, client, me, createCompetency, SetCurrentCompetencyTab, updateCompetency, setCurrentTab } = props

    const [competency_cat_list, setCompetencyCatList] = React.useState()
    const [competency_detail, setCompetecnyDetail] = React.useState()
    const [competency_loading, SetCompetencyLoading] = React.useState(false)
    const [competency_list, SetCompetencyList] = React.useState([])
    const [invalidInput, setInvalidInput] = React.useState(false);

    // Get all competency category list
    const getCompetencyCategoryList = async (filter) => {
        const { data } = await client.query({
            query: COMPETENCY_CATEGORY_LIST,
            fetchPolicy: 'network-only',
            variables: { ...filter, first: 50 }
        });
        if (data?.allCompetencyCategory) {
            setCompetencyCatList(data?.allCompetencyCategory?.edges.map(({ node }) => node))
        }
    }
    // qraphql api to fetch competency data list
    const getCompetencyList = async (filter) => {
        SetCompetencyLoading(true)
        const { data } = await client.query({
            query: COMPETENCY_LIST,
            fetchPolicy: 'network-only',
            variables: { ...filter, competencyCategory_Isnull: true }
        });
        if (data?.allCompetencies) {
            let list = data?.allCompetencies?.edges.map(({ node }) => node)
            SetCompetencyLoading(false)
            SetCompetencyList(list)

        }
    }

    //Get Compegency by id Query call
    const getCompetencyData = async (comp_id) => {
        let id = getCompetencyTypeBase64StringFromInt(comp_id)
        const { data } = await client.query({
            query: COMPETENCY_DETAIL_BY_ID_QUERY,
            variables: { id },
            fetchPolicy: 'network-only'
        });
        if (data?.competencyById) {
            setCompetecnyDetail(data?.competencyById)
        }
    }


    React.useEffect(() => {
        if (current_comptency_tab?.competency_id) {
            getCompetencyData(current_comptency_tab?.competency_id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_comptency_tab])

    React.useEffect(() => {
        if (me) {
            getCompetencyCategoryList()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me])

    /* ON Cancel Navigations */
    const navigation = (view) => {
        if (view === 'competencymain_home_view') {
            setCurrentTab({
                key: 'competency',
                label: 'Competency',
            })
        }else{
            SetCurrentCompetencyTab({ key: view })
        }
        
    }

    // create and edit sub competency mutation call
    const submitReasponse = async (value) => {
        if (!invalidInput) {
            let competencyData = {
                title: value?.data?.title,
                competencyCategoryId: current_comptency_tab?.main_competency ? getIntFromString(current_comptency_tab?.main_competency?.id) : getIntFromString(value?.data?.competency_category),
                competencyType: value?.data?.type === 'Generic' ? 'Generic' : 'Role_Specific',
                description: value?.data?.description
            }
            let competencyData_update_Data = {
                id: current_comptency_tab?.competency_id,
                title: value?.data?.title,
                competencyCategoryId: current_comptency_tab?.main_competency ? getIntFromString(current_comptency_tab?.main_competency?.id) : getIntFromString(competency_detail?.competencyCategory?.id),
                competencyType: value?.data?.type === 'Generic' ? 'Generic' : 'Role_Specific',
                description: value?.data?.description
            }

            if (current_comptency_tab?.competency_id !== undefined || value?.type === "update_exist") {
                const response = await updateCompetency(competencyData_update_Data);
                if (response) {
                    navigation('competency_home_view')
                }
            }
            else {
                let response = await createCompetency({ competencyData })
                if (response) {
                    navigation('competency_home_view')
                }
            }
        }

    }

    return (
        <>
            <CompetencyAddEditView {...props}
                competency_list={competency_list}
                competency_loading={competency_loading}
                onSubmit={(value) => submitReasponse(value)}
                competency_category_list={competency_cat_list}
                competency_detail={competency_detail}
                onCancel={(e) => navigation(e)}
                getCompetencyList={(e) => getCompetencyList(e)}
                getCompetencyCategoryList={(e) => getCompetencyCategoryList(e)}
                invalidInput={invalidInput}
                setInvalidInput={setInvalidInput}
            ></CompetencyAddEditView>
        </>
    )

}

export default compose(withCreateCompetencySkill, withUpdateCompetencySkill)(CompetencyAddEditContainer)
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
// import {DISCARD_REQUEST_LIST} from '../graphql/DiscardRequestList.gql'
import { DISCARD_REQUEST_LIST_SUBSCRIPTION } from '../graphql/DiscardRequestSubscription.gql';
import DiscardRequestTableView from '../components/DiscardRequestTableView';
import { withDeleteDiscardRequest } from './objective-keyresult-operations';
import moment from 'moment-timezone';
import { DISCARD_REQUEST_FOR_PENDING, DISCARD_REQUEST_NEW_LIST } from '../graphql/DiscardRequestNewQuery.gql';
// import Item from 'antd/lib/list/Item';
const OkrDiscardTableContainer = (props)=>{
    
const {me,client,location}=props
// const [discard,setDiscard]=React.useState()
const [discardList,setDiscardList]=React.useState()
const [discardPendingList,setDiscardPendingList]=React.useState()
const [loading,setLoading]=React.useState(true)
// eslint-disable-next-line no-unused-vars
const [counts,setCounts]=React.useState({})
const [filterValue,setFilterValue]=React.useState()
const [nextPage,setNextPage]=React.useState(false)
const [nextPendingPage,setNextPendingPage]=React.useState(false)
// const [offset,SetOffset]=React.useState(0)
const [pendingLoading,setPendingLoading]=React.useState(false)
const dataRef = React.useRef();
const pendingRef = React.useRef();
const m = React.useRef( true )

  let discardSub = undefined
  React.useEffect( () => {
    return () => {
      if ( discardSub ) {
        discardSub.unsubscribe();
      }
    }
  })

  React.useEffect( () => {
    return () => {
      m.current = false
    }
  }, [] )

  React.useEffect( () => {
    m.current = true

  }, [] )

const DiscardPendingList= async(filterData)=>{
  if(!filterData?.offset&&!filterData?.first){
    filterData={...filterData,offset:0,first:10}
  }
  setPendingLoading(true)
      const { data } = await client.query({
          query: DISCARD_REQUEST_FOR_PENDING ,
          variables:filterData,
          fetchPolicy: 'network-only'
      });
      if(data){
            let mergeDataForCeo=data?.discardRequest?.employee?.orgCeoEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node?.initiatedDiscardRequestSet))
            let mergeDataForTeam=data?.discardRequest?.employee?.teamManagerEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node?.initiatedDiscardRequestSet))
            let mergeDataForVertical=data?.discardRequest?.employee?.verticalHeadEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node?.initiatedDiscardRequestSet))
            let finalDataMerge =[].concat.apply([],[...mergeDataForCeo,...mergeDataForTeam,...mergeDataForVertical])
            var setObj = new Set();
            let found = finalDataMerge.some(el => el.pageInfo?.hasNextPage === true);
            if(found){
              setNextPendingPage(true)
            }
            else{
              setNextPendingPage(false)
            }
            let finalEdges = [].concat.apply([],[...finalDataMerge?.map((item)=>item?.edges?.map(({node})=>node))])

           let result = finalEdges.reduce((acc,item)=>{
              if(!setObj.has(item?.okr?.id)){
                setObj.add(item?.okr?.id)
                acc.push(item)
              }
              return acc;
            },[])
            if(!filterData?.offset){
              setDiscardPendingList(result)
              pendingRef.current=result
            }
            else{
              let Objective =[...pendingRef.current,...result]
              setDiscardPendingList(Objective)
              pendingRef.current =Objective
            }
            if(filterData?.type==='Incomming'){
              let incomming=pendingRef.current.filter((item)=>item?.initiator?.id!==me?.employee.id)
              setDiscardPendingList(incomming)
            }
            if(filterData?.type==='Outgoing'){
              let Outgoing=pendingRef.current.filter((item)=>item?.initiator?.id===me?.employee.id)
              setDiscardPendingList(Outgoing)
            }
          
        }
      discardSub = await client.subscribe( {
        query: DISCARD_REQUEST_LIST_SUBSCRIPTION,
        variables: filterData,
        fetchPolicy: 'network-only'
      } ).subscribe( {
        next( result ) {
          let discardedRequestCacheData = pendingRef.current
          switch ( result.data.discardRequestSubscription.mutation ) {
            case 'CREATE':
              const updated = [ ...discardedRequestCacheData,result.data.discardRequestSubscription.discardRequest]
              if ( m.current ) {
                let Data = updated?.filter((item)=>item?.status==='PENDING')
                setDiscardPendingList(Data)
                pendingRef.current = Data
              }
              break
            case 'UPDATE':
              if ( m.current ) {
                const Update =discardedRequestCacheData.map( item =>
                    item?.id === result?.data?.discardRequestSubscription?.discardRequest?.id
                      ? result.data.discardRequestSubscription.discardRequest
                      : item
                  ) 
                  let upData = Update?.filter((item)=>item?.status==='PENDING')
                  setDiscardPendingList(upData)
                  pendingRef.current = upData
              }
              break
            case 'DELETE':
              if ( m.current ) {
                const deleted=discardedRequestCacheData.filter( item =>
                    item?.id === result?.data?.discardRequestSubscription?.discardRequest?.id
                      ? false
                      : true
                  ) 
                  let deData = deleted?.filter((item)=>item?.status==='PENDING')
                  setDiscardPendingList(deData)
                  pendingRef.current = deData
              }
              break
            default:
              break
          }
        }

      } )
      setPendingLoading(false)
}

const DiscardList = async (filterData) => {
  if(!filterData?.offset&&!filterData?.first){
    filterData={...filterData,offset:0,first:10}
  }
    setLoading(true)
      const { data } = await client.query({
          query: DISCARD_REQUEST_NEW_LIST ,
          variables:filterData,
          fetchPolicy: 'network-only'
      });
      if(data){
            let mergeDataForCeo=data?.discardRequest?.employee?.orgCeoEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node?.initiatedDiscardRequestSet))
            let mergeDataForTeam=data?.discardRequest?.employee?.teamManagerEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node?.initiatedDiscardRequestSet))
            let mergeDataForVertical=data?.discardRequest?.employee?.verticalHeadEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node?.initiatedDiscardRequestSet))
            let finalDataMerge =[].concat.apply([],[...mergeDataForCeo,...mergeDataForTeam,...mergeDataForVertical])
            var setObj = new Set();
            let found = finalDataMerge.some(el => el.pageInfo?.hasNextPage === true);
            if(found){
              setNextPage(true)
            }
            else{
              setNextPage(false)
            }
            let finalEdges = [].concat.apply([],[...finalDataMerge?.map((item)=>item?.edges?.map(({node})=>node))])
   
           let result = finalEdges.reduce((acc,item)=>{
              if(!setObj.has(item?.okr?.id)){
                setObj.add(item?.okr?.id)
                acc.push(item)
              }
              return acc;
            },[])
            let requestList = result?.filter((item)=>item?.status!=='PENDING')
            if(!filterData?.offset){
              setDiscardList(requestList)
              dataRef.current=requestList
            }
            else{
              let Objective =[...dataRef.current,...requestList]
              setDiscardList(Objective)
              dataRef.current =Objective
            }
            if(filterData?.type==='Incomming'){
              let incomming=dataRef.current.filter((item)=>item?.initiator?.id!==me?.employee.id)
              setDiscardList(incomming)
            }
            if(filterData?.type==='Outgoing'){
              let Outgoing=dataRef.current.filter((item)=>item?.initiator?.id===me?.employee.id)
              setDiscardList(Outgoing)
            }
          
        }
      discardSub = await client.subscribe( {
        query: DISCARD_REQUEST_LIST_SUBSCRIPTION,
        variables: filterData,
        fetchPolicy: 'network-only'
      } ).subscribe( {
        next( result ) {
          let discardedRequestCacheData = dataRef.current
          switch ( result.data.discardRequestSubscription.mutation ) {
            case 'CREATE':
              const updated = [ ...discardedRequestCacheData,result.data.discardRequestSubscription.discardRequest]
              if ( m.current ) {
                let data =updated?.filter((item)=>item?.status!=='PENDING')
                setDiscardList( data )
                dataRef.current = data
              }
              break
            case 'UPDATE':
              if ( m.current ) {
                const Update =discardedRequestCacheData.map( item =>
                    item?.id === result?.data?.discardRequestSubscription?.discardRequest?.id
                      ? result.data.discardRequestSubscription.discardRequest
                      : item
                  ) 
                  let upData = Update?.filter((item)=>item?.status!=='PENDING')
                  setDiscardList(upData)
                  dataRef.current = upData
              }
              break
            case 'DELETE':
              if ( m.current ) {
                const deleted=discardedRequestCacheData.filter( item =>
                    item?.node?.id === result?.data?.discardRequestSubscription?.discardRequest?.id
                      ? false
                      : true
                  ) 
                  let deData=deleted?.filter((item)=>item?.status!=='PENDING')
                  setDiscardList(deData)
                  dataRef.current =deData
              }
              break
            default:
              break
          }
        }

      } )
    setLoading(false)
}


  React.useEffect(()=>{
    if(me){
      DiscardList({offset:0,first:10,tz:moment.tz.guess()})
      DiscardPendingList({offset:0,first:10,tz:moment.tz.guess()})
    }
    else{
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[me,location])
  const PaginantionCall =(value)=>{
    if(value){
      DiscardList({...filterValue,...value,first:10,tz:moment.tz.guess()})
    }
    else{
      DiscardList({offset:0,first:10,tz:moment.tz.guess()})
    }
  }
  const PaginationPendingCall =(value)=>{
    if(value){
      DiscardPendingList({...filterValue,...value,first:10,tz:moment.tz.guess()})
    }
    else{
      DiscardPendingList({offset:0,first:10,tz:moment.tz.guess()})
    }
  }
    return (
        <DiscardRequestTableView {...props} discardList={discardList} load={loading} counts={counts} onfilter={e=>{DiscardList(e);setFilterValue(e);DiscardPendingList(e)}}onPaginantion={e=>PaginantionCall(e)}onPendingPaginantion={e=>PaginationPendingCall(e)}nextPage={nextPage}nextPendingPage={nextPendingPage} discardPendingList={discardPendingList}pendingLoading={pendingLoading}></DiscardRequestTableView>
    )
}
export default compose(withApollo,withDeleteDiscardRequest)(OkrDiscardTableContainer)
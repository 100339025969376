import React from "react";
import AwardBadgeView from "../component/awardBadgeVIew";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { ALL_BADGES_LIST } from "../graphql/allBadgesList.gql";
import { withCreateBadgeAward } from "./badgeAwardOperationFeedback";

import { BADGE_FAMILY_DETAIL, BADGE_FAMILY_FILTER_LIST } from "../graphql/badgeFamilyFilterList.gql";

const AwardBadgeContainer=(props)=>{
    const {client, me}=props
    const [loading,setLoading]=React.useState(false)
    const [badgeList,setBadgeList]=React.useState([])
    const [badge_Family_by_id, setBadgeFamilyById] = React.useState()
    const [badge_family_list, setBadgeFamilyList] = React.useState()

    const BadgeFamilyList = async (filterData) => {
        const { data } = await client.query({
            query: BADGE_FAMILY_FILTER_LIST,
            variables: { ...filterData },
            fetchPolicy: "network-only"
        });
        if (data) {
            setBadgeFamilyList(data?.BadgeFamily?.edges)
        }
    }
    const BadgeFamilyDetail = async (filterData) => {
        const { data } = await client.query({
            query: BADGE_FAMILY_DETAIL,
            variables: { ...filterData },
            fetchPolicy: "network-only"
        });
        if (data) {
            setBadgeFamilyById(data?.badgeFamilyById)
        }
    }
    
    const getBadgeFamilyList=async(filterValue)=>{

        setLoading(true)
        const { data } = await client.query({
          query: ALL_BADGES_LIST,
          variables: {...filterValue},
          fetchPolicy:"network-only"
        });
        if(data){
            let values =data?.badgeByTitle?.edges.concat(data?.badgeByDescription?.edges)
            var setObj = new Set();
            var result = values.reduce((acc,item)=>{
            if(!setObj.has(item?.node?.id)){
            setObj.add(item?.node?.id)
            acc.push(item)
            }
            return acc;
        },[])
            setLoading(false)
            setBadgeList(result)
        }
    }
    React.useEffect(()=>{
    if(me){
        getBadgeFamilyList()
        BadgeFamilyList()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[me])
    
    return (
        <>
            <AwardBadgeView {...props} onSearch={(e)=>getBadgeFamilyList(e)} badgeList={badgeList} loading={loading}
             getBageFamilyById={(e) => BadgeFamilyDetail(e)}
             badge_Family_by_id={badge_Family_by_id}
             badge_family_list={badge_family_list}
             getbadgeFailyList={(e) => BadgeFamilyList(e)}
             onFilter={(e) => getBadgeFamilyList(e)}
             />
        </>
    )
}

export default compose(withApollo,withCreateBadgeAward)(AwardBadgeContainer)
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
// import DiscardRequestView from '../components/DiscardView';
// import { PENDING_COUNTS } from '../graphql/TabPendingCounts.gql';
import { Spin } from 'antd';
// import { MEMEBER_BY_TEAM_ID } from '../graphql/MembersByTeamId.gql';
import { getIntFromString } from 'modules/look';
// import EmployeeList from 'modules/look-v2/Components/EmployeeList';
import { TEAMS_BY_VERTICAL } from '../graphql/TeamsByVertical.gql';
import TeamListComponent from 'modules/look-v2/Components/teamListComponent';
const TeamListContainer = (props)=>{
const [TeamList,setTeamList]=React.useState([])
const [teamLoader,setteamLoader]=React.useState(true)
const {client,verticalDetail}=props

    React.useEffect(()=>{
        if(verticalDetail){
           getTeamList(verticalDetail?.id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[verticalDetail])

    const getTeamList=async(vertical)=>{
        setteamLoader(true)
    const { data } = await client.query({
      query: TEAMS_BY_VERTICAL,
      variables: {vertical},
    //   fetchPolicy: 'network-only'
    });
    if(data?.Teams){
        let list=data?.Teams?.edges?.map(({node})=>{
            return {
                id:node?.id,
                name:node?.name,
                employeeCount:node?.employees?.totalCount,
                managerId:node?.managers?.edges?.map(({node})=>getIntFromString(node?.id)),
            }
        })
        setTeamList(list)
        setteamLoader(false)
    }
    }

    return (

        !teamLoader?
        <TeamListComponent TeamList={TeamList} {...props} />:
        <div align="center">
        <Spin spinning={teamLoader}></Spin>
        </div>

    )
}
export default compose(withApollo)(TeamListContainer)
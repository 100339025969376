
import React from "react"; 
import UserManualResponseView from "../components/usermanualResponseView";
import { getIntFromString } from "modules/look";
import { compose } from "modules/core";
import { withUpdateResponse } from "./userManualOperation";
 
const UserManualResponseContainer = (props) => { 
    const { answer,compare_data } = props
    const [respose_list,SetResponseList] = React.useState([]) // state to store the multiple response 
    const [question_detail,SetQuestionDetail] = React.useState()

    React.useEffect(()=>{
        if(answer){
            handleAnswer(answer)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[answer,compare_data])

    // method to manipulate the answer from backend to desirable format 
    const handleAnswer=(data)=>{
      
        let choice_list = data?.question?.choiceSet.edges.map(({ node }) => node) 
        
        SetQuestionDetail({
            question:{
                id:data?.question?.id,
                questionText:data?.question?.questionText,
                question_type:choice_list[0]?.inputType
            },
            choice_list
        })

        let list=[]
        
        let other_user_data = []
        if(compare_data){
            let current_question_data = compare_data?.map(({node})=>node)?.find(i=>getIntFromString(i?.question?.id)===getIntFromString(data?.question?.id))
            if(current_question_data){
                choice_list.forEach(item=>{
                    if(current_question_data?.answer?.toLowerCase()?.includes(item?.value?.toLowerCase())){
                        other_user_data.push(item?.value)  
                    }
                }) 
            }
        } 
        choice_list.forEach(item=>{
            if(data?.answer?.toLowerCase()?.includes(item?.value?.toLowerCase())){
                list.push({
                    value:item?.value,
                    id:item?.id,
                    is_data_equal:(compare_data && other_user_data?.includes(item?.value))?true:false
                })  
            }
        }) 
        SetResponseList(list)
    }

    return ( 
        <UserManualResponseView {...props} respose_list={respose_list} question_detail={question_detail} SetResponseList={SetResponseList}/>
    )
}

export default compose(withUpdateResponse)(UserManualResponseContainer);

import React from "react";
import CreateChoiceView from "../components/createChoiceView";
import { GET_QUESTION_LIST, GET_SCAPE_BY_ID_CHOICE } from "../graphql/listingQueries.gql";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc'
import { withCreateParameter } from "./scapeReportOperations";
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { message } from "antd";

const CreateChoiceContainer = ( props ) => {
    const { create_choice, client, onCancel} = props
    const [ all_questions_list, setAllquestionList ] = React.useState( [] )
    const [allready_selected_choices,setAllreadySeletedChoices]=React.useState([])
    const [question_loading,setQuestionLoading]=React.useState(false)
    const [mutationData, setmutationData] = React.useState();

    React.useEffect( () => {
        if ( create_choice !== undefined ) {
            getQuestionLists( create_choice?.survey_id )
            setQuestionLoading(true)
            getscapeById(create_choice?.spape_id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ create_choice ] )

    React.useEffect(() => {
        if (mutationData !== undefined) {
            assignEmployeeMutation()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutationData])

    const ASSIGN_CHOICES_DYNAMIC = gql`
    mutation createParameter {
    ${mutationData!==undefined&& mutationData?.choices?.map(
        (item, index) =>
          `create${index}: createParameter(parameterData:{choiceId:"${item}",parametricGroupId:"${mutationData?.parameter_id}",show:"${mutationData?.show}"}) {
            parameter{
                id
            }
        }
       `
      ).join('')}
    }
    `

    // eslint-disable-next-line no-unused-vars
    const [createNewParameter, { data, isloading, error }] = useMutation(ASSIGN_CHOICES_DYNAMIC);

    const assignEmployeeMutation = async () => {
        try {
          const response = await createNewParameter()
          // new to do send back the response as props
          if (response) {
              message.success("Submitted successfully")
              onCancel()
          } else {
          }
        } catch (error) {
        }
      }


    const getQuestionLists = async ( id ) => {
        const { data } = await client.query( {
            query: GET_QUESTION_LIST,
            fetchPolicy: 'network-only',
            variables: { id: id }
        } );
        if ( data?.SurveyById ) {
           
            setAllquestionList( [].concat(...data?.SurveyById?.groupSet?.edges?.map( ( { node } ) => node?.questionSet?.edges?.map( item => item?.node ) )) )
            setQuestionLoading(false)
        }
    }

    const getscapeById =async (id)=>{
        const { data } = await client.query( {
            query: GET_SCAPE_BY_ID_CHOICE,
            fetchPolicy: 'network-only',
            variables: { id: id }
        } );
        if(data?.scapeById){
            let choices =[].concat(...data?.scapeById?.parametricgroupSet?.edges?.map(({node})=>node?.parameterSet?.edges?.map(item=>item?.node?.choice?.id)))
            setAllreadySeletedChoices(choices)
        }
    }

    return (
        <div>
            <CreateChoiceView {...props} all_questions_list={all_questions_list} allready_selected_choices={allready_selected_choices} question_loading={question_loading} setmutationData={(value)=>setmutationData(value)}/>
        </div>
    )
}

export default compose(withApollo,withCreateParameter)(CreateChoiceContainer)
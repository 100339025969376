// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';

import { GET_FORM_SECTION_RESPONSE_BY_ID_QUERY } from '../../form-section/graphql/GetFormSectionResponseById.gql';

import { ADD_FORM_SECTION_MUTATION } from '../graphql/AddFormSectionMutation.gql';
import { ADD_SIMPLE_FIELD_MUTATION } from '../graphql/AddSimpleFieldMutation.gql';
import { ADD_SIMPLE_FIELD_RESPONCE_MUTATION} from '../graphql/AddSimpleFieldResponceMutation.gql';
import { ADD_SIMPLE_FIELD_CHOICE_MUTATION } from '../graphql/AddSimpleFieldChoiceMutation.gql';
import { ADD_REFERENCE_FIELD_MUTATION } from '../graphql/AddReferenceFieldMutation.gql';

import { EDIT_FORM_SECTION } from '../graphql/EditFormSection.gql';
import { EDIT_SIMPLE_FIELD } from '../graphql/EditSimpleField.gql';
import { EDIT_REFERENCE_FIELD } from '../graphql/EditReferenceField.gql';
import { EDIT_SIMPLE_FIELD_CHOICE } from '../graphql/EditSimpleFieldChoice.gql';
import { EDIT_SIMPLE_FIELD_RESPONCE,EDIT_SIMPLE_FIELD_RESPONCE_OPTIMIZED } from '../graphql/EditSimpleFieldResponce.gql';

import { DELETE_FORM_SECTION } from '../graphql/DeleteFormSection.gql';
import { DELETE_SIMPLE_FIELD_CHOICE } from '../graphql/DeleteSimpleFieldChoice.gql';
import { message } from 'antd';

export const getFormSectionResponseById = Component =>
  graphql(GET_FORM_SECTION_RESPONSE_BY_ID_QUERY, {
    options: ({ formSectionId }) => {
      return {
        variables: { formSectionId: Number(formSectionId) }
      };
    },
    props({ data: { loading, error, getFormSectionResponseById, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return {
        loading,
        error,
        getFormSectionResponseById: getFormSectionResponseById?.edges[0]?.node,
        subscribeToMore,
        updateQuery
      };
    }
  })(Component);



  export const withAddFormSection = Component =>
  graphql( ADD_FORM_SECTION_MUTATION, {
    props: ( { mutate } ) => ( {
      createFormSection: async values => {
        try {
          const {
            data: { createFormSection }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Add form Section successfully")
          return createFormSection.formSection;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );


  export const withAddSimpleField = Component =>
  graphql( ADD_SIMPLE_FIELD_MUTATION, {
    props: ( { mutate } ) => ( {
      createSimpleField: async values => {
        try {
          const {
            data: { createSimpleField }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Add Simple Field successfully")
          return createSimpleField.simpleField;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );



  export const withAddSimpleFieldResponce = Component =>
  graphql( ADD_SIMPLE_FIELD_RESPONCE_MUTATION, {
    props: ( { mutate } ) => ( {
      createSimpleFieldResponce: async values => {
        try {
          const {
            data: { createSimpleFieldResponce }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Add Simple Field Response successfully")
          return createSimpleFieldResponce.simpleFieldResponce;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );



  export const withAddSimpleFieldChoice = Component =>
  graphql( ADD_SIMPLE_FIELD_CHOICE_MUTATION, {
    props: ( { mutate } ) => ( {
      createSimpleFieldChoice: async values => {
        try {
          const {
            data: { createSimpleFieldChoice }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Add Simple Field Response successfully")
          return createSimpleFieldChoice.simpleFieldChoice;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );


  export const withAddReferenceField = Component =>
  graphql( ADD_REFERENCE_FIELD_MUTATION, {
    props: ( { mutate } ) => ( {
      createReferenceField: async values => {
        try {
          const {
            data: { createReferenceField }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Add Referal Field successfully")
          return createReferenceField.referenceField;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );


export const withEditFormSection = Component =>
  graphql(EDIT_FORM_SECTION, {
    props: ({ mutate }) => ({
      editFormSection: async values => {
        try {
          const {
            data: { updateFormSection }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateFormSection.formSection;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditSimpleField = Component =>
  graphql(EDIT_SIMPLE_FIELD, {
    props: ({ mutate }) => ({
      editSimpleField: async values => {
        try {
          const {
            data: { updateSimpleField }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateSimpleField.simpleField;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditSimpleFieldChoice = Component =>
  graphql(EDIT_SIMPLE_FIELD_CHOICE, {
    props: ({ mutate }) => ({
      editSimpleFieldChoice: async values => {
        try {
          const {
            data: { updateSimpleFieldChoice }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateSimpleFieldChoice.simpleFieldChoice;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditSimpleFieldResponce = Component =>
  graphql(EDIT_SIMPLE_FIELD_RESPONCE, {
    props: ({ mutate }) => ({
      editSimpleFieldResponce: async values => {
        try {
          const {
            data: { updateSimpleFieldResponce }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateSimpleFieldResponce.simpleFieldResponce;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

export const withEditReferenceField = Component =>
  graphql(EDIT_REFERENCE_FIELD, {
    props: ({ mutate }) => ({
      editReferenceField: async values => {
        try {
          const {
            data: { updateReferenceField }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateReferenceField.referenceField;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

  export const withDeleteFormSection = Component =>
  graphql( DELETE_FORM_SECTION, {
    props: ( { mutate } ) => ( {
      deleteFormSection: async values => {
        try {
          const {
            data: { deleteFormSection }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Delete form section successfully")
          return deleteFormSection.formSection;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );


  export const withDeleteSimpleFieldChoice = Component =>
  graphql( DELETE_SIMPLE_FIELD_CHOICE, {
    props: ( { mutate } ) => ( {
      deleteSimpleFieldChoice: async values => {
        try {
          const {
            data: { deleteSimpleFieldChoice }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Delete simple filed choice successfully")
          return deleteSimpleFieldChoice.simpleFieldChoice;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );


  export const withAddSimpleFieldResponceOptimized = Component =>
  graphql( ADD_SIMPLE_FIELD_RESPONCE_MUTATION, {
    props: ( { mutate } ) => ( {
      createSimpleFieldResponce: async values => {
        try {
          const {
            data: { createSimpleFieldResponce }
          } = await mutate( {
            variables: {
              ...values
            }
          } );
          message.destroy();
          message.success("Add Simple Field Response successfully")
          return createSimpleFieldResponce.simpleFieldResponce;
        } catch ( e ) {
          console.error( e );
        }
      }
    } )
  } )( Component );


  export const withEditSimpleFieldResponceOptimized = Component =>
  graphql(EDIT_SIMPLE_FIELD_RESPONCE_OPTIMIZED, {
    props: ({ mutate }) => ({
      editSimpleFieldResponce: async values => {
        try {
          const {
            data: { updateSimpleFieldResponce }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateSimpleFieldResponce.simpleFieldResponce;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

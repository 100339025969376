import React from 'react';
import { Upload, Button, Form ,Select,Input, message} from 'antd';
import attach_file from '../../assets/Attach.svg'
import '../css/TicketsForm.css'; 
import { issues_list } from '../ticket-system-config';
import { CLOUDINARY_CLOUDNAME,CLOUDINARY_PRESET } from '../../../config';
import back_icon from '../../assets/soul-back.svg'

const { TextArea } = Input;

const TicketForm = (props) => {
  const {me,onSubmit,navigateRoute} = props
  const [form] = Form.useForm();
  const cloudName = `${CLOUDINARY_CLOUDNAME}`;
  const unsignedUploadPreset = `${CLOUDINARY_PRESET}`;
  const cloudinary_data = { upload_preset: unsignedUploadPreset };
  const [attachment_url, setAttachment_url] = React.useState([]);
  const [fileList, setFileList]=React.useState([]) 
  const [cloudinaryUrl, setCloudinaryUrl] = React.useState('https://api.cloudinary.com/v1_1/' + cloudName + '/image/upload');
  React.useEffect(()=>{
    if(me?.employee?.user?.email){
      form.setFieldsValue({
        email: me?.employee?.user?.email
      })
    }
  },[me,form])

  const onFinish = async (value) => {
    onSubmit({...value,file:attachment_url})
  }

  const AuthVreification = (value) => {
    if(value ==="login_issue"||value ==="password_reset"){

    }
  }

  const onChangeHandler = ({ file, fileList }) => {
    if (file.status === "done") {
      if (file.response) {
        let url = file.response.secure_url;
        if (url) {
          setAttachment_url((prev) => [...prev, url]);
        }
      }
    }
    setFileList(fileList);
  };

  const onRemoveHandler = async (file) => {
    const fileToRemove = attachment_url.find((f) => f.url === file.url);
    if (fileToRemove) {
      setAttachment_url((prev) => prev.filter((item) => item.url !== file.url));
    }
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
  };

  const beforeUploadHandler = (file) => {
    const isLt25MB = file.size / 1024 / 1024 < 25;
    if (!isLt25MB) {
      message.error("File must be smaller than 25MB!");
      return Upload.LIST_IGNORE;
    }
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    if (isVideo) {
      setCloudinaryUrl('https://api.cloudinary.com/v1_1/' + cloudName + '/video/upload');
    } else if (isImage) {
      setCloudinaryUrl('https://api.cloudinary.com/v1_1/' + cloudName + '/image/upload');
    } else {
      setCloudinaryUrl('https://api.cloudinary.com/v1_1/' + cloudName + '/raw/upload');
    }
    return true;
  };
  
  
  return (
    <div className='create-titcket-root '>
      <div style={{ display: "flex", flexDirection: "row", gap: '1em', justifyContent: 'start', alignItems: "center" }}>
        <img src={back_icon} alt="back_icon" style={{ cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
        <h1 className='ticket-system-main-head'>Mapp Support</h1>
      </div>
      <div className="ticket-form-container">
          <h4 className='ticket-system-main-head'>Create Ticket</h4>
          <h4 className='ticket-system-main-head' style={{fontSize:"1em",color:'rgba(132, 129, 138, 1)',fontWeight:"500"}}>Write new queries and issues</h4>
        <div className='ticket-form-input-section'>
          <Form
            form={form}
            name="basic"
            layout={'vertical'}
            onFinish={onFinish}
            autoComplete="off"
            className="formLabel-modal">
            <div className='form-input-row-container'>
              <Form.Item
                label="Email"
                name="email"
                className='ticket-form-item-width'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your email',
                  },
                ]}
              >
                <Input type="text" placeholder='Enter Your Email' className="ticket-form-input"style={{ borderRadius: ".5em", border: "1px solid rgba(84, 193, 4, 1)", height: "3em",width:"100%" }}
                  autoComplete='off' readOnly={me?.employee?.user?.email?true:false}/>
              </Form.Item>
              <Form.Item
                label="Issue Type"
                name="type"
                className='ticket-form-item-width'
                rules={[
                  {
                    required: true,
                    message: 'Please select issue type',
                  },
                ]}
              >
                <Select
                  name="type"
                  className="ticket-form-select"
                  showSearch={true}
                  placeholder="Select a issue type"
                  onChange={(e) => AuthVreification(e)}
                >
                  {issues_list?.map((issue) => (
                    <Select.Option key={issue.value} value={issue.value}>{issue.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className='form-input-row-container'>
              <Form.Item
                label="Title"
                name="title"
                className='ticket-form-item-width'
                rules={[
                  {
                    required: true,
                    message: 'Title is required',
                  },
                ]}
              >
                <Input type="text" className="" style={{ borderRadius: ".5em", border: "1px solid rgba(84, 193, 4, 1)", height: "3em",width: "100%" }}
                  autoComplete='off' placeholder='Enter ticket title'/>
              </Form.Item>
              <Form.Item
                name="file"
                 label="Attachment"
                className='ticket-form-item-width ticket-attachment-form-item '
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Upload 
                  className='custom-ticket-upload'
                  action={cloudinaryUrl}
                  data={cloudinary_data}
                  listType="picture"
                  fileList={fileList}
                  beforeUpload={beforeUploadHandler}
                  onChange={onChangeHandler}
                  onRemove={onRemoveHandler}
                  onPreview={(file) => {
                    if (file.url || file.response?.secure_url) {
                      const imageUrl = file.url || file.response.secure_url;
                      window.open(imageUrl, "_blank");
                    }
                  }}
                   style={{width:'100%'}}>
                  {fileList?.length<=5&& <div className="custom-ticket-upload-btn">
                    <img className='custom-ticket-upload-icon' src={attach_file} alt="attach_file" />
                    <span>Attach or Drop file</span>
                  </div>}
                </Upload>
              </Form.Item>
            </div>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Description is required',
                },
              ]}
            >
            <TextArea id="ticket-body" style={{border: "1px solid rgba(84, 193, 4, 1)",borderRadius:".5em",resize:"none",width:'100%',height:"10em"}}
            placeholder='Enter ticket details'/>
            </Form.Item>
            <div className='ticket-form-submit-button-container' style={{display:'flex',justifyContent:"end",width:'100%',gap:"1em"}}>
              <Button type="primary" className="ticket-support-cancel-btn" onClick={()=>navigateRoute("-1")}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" className="ticket-support-submit-btn">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default TicketForm;

import React from "react";
import TicketDetails from "../components/TicketDetails";
import {getTicketBase64StringFromInt, globalPermissionValidator } from "modules/look";
import { TICKET_BY_ID } from "../graphql/ticket-queries.gql";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { withCreateComment } from "./ticketOperators";
import { message } from "antd";
import { ticket_and_support_permissions } from "Permissions/ticketSupport.permissions";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
const TicketDetailsContainer = (props) => {
    const { match, client, createTicketComment,userPermission } = props
    const [ticket_detail, setTicketDetail] = React.useState()
    const [ticket_detail_loading, setTicketDetailLoading] = React.useState(false)
    const [ticket_permission,setTicketPermsion]=React.useState()
    const [permission_loading,setPermissionLoading]=React.useState(true)
    React.useEffect(() => {
        if (match?.params && userPermission?.length) {
            setPermissionLoading(true)
            let permission = globalPermissionValidator(ticket_and_support_permissions, userPermission)
            setTicketPermsion(permission)
            if (permission?.reqiredPermissions) {
            getTicketDetail(getTicketBase64StringFromInt(match?.params?.id))
            }
            setPermissionLoading(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match,userPermission])


    const getTicketDetail = async (id) => {
        try {
            setTicketDetailLoading(true)
            const { data } = await client.query({
                query: TICKET_BY_ID,
                variables: { id: id }
            })
            if (data?.ticketById) {
                let data_set = {
                    ...data?.ticketById,
                    ticketcommentSet: data?.ticketById?.ticketcommentSet?.edges?.map(({ node }) => node)

                }
                setTicketDetail(data_set)
                setTicketDetailLoading(false)
            }
        } catch (error) {
            console.error(error);
            setTicketDetailLoading(false)
        }
    }
    const onSubmit = async (values) => {

        try {
            let CreateCommentInput = {
                ticketId: values?.id,
                content: values?.content,

            }
            let response = await createTicketComment({ ...CreateCommentInput })
            if (response) {
                    message.success("Your comment has been added successfully.")
                    
                    setTicketDetail((prevDetail) => {
                        if (!prevDetail) return prevDetail;
                        return {
                            ...prevDetail,
                            ticketcommentSet: [...(prevDetail?.ticketcommentSet || []), response],
                        };
                    });
            }
        }
        catch (error) {
            console.error(error)
        }
    }

    return (
        <>
           {ticket_permission && ticket_permission?.reqiredPermissions?<TicketDetails
                ticket_detail={ticket_detail}
                onSubmit={(values) => onSubmit(values)}
                ticket_detail_loading={ticket_detail_loading}
                ticket_permission={ticket_permission}
                {...props} />:
                (!permission_loading&&<NoPermissionView />)}

        </>
    )
}

export default compose(withApollo, withCreateComment)(TicketDetailsContainer);



import React from 'react';
import { compose } from '../../core';
// import { withApollo } from '@apollo/client/react/hoc';
import AgendaListView from '../components/agendaListView';
import { globalPermissionValidator } from 'modules/look';
import { oneonone_permission } from 'Permissions/oneonone.permission';

const AgendaListContainer =(props)=>{
    const {userPermission}=props
    const [permission, setpermission] = React.useState()
    React.useEffect(() => {
        if (userPermission?.length) {
          let permission = globalPermissionValidator(oneonone_permission, userPermission)
          setpermission(permission)
        }
        else {
          setpermission(null)
        }
      }, [userPermission])
    return(
        <>
        <AgendaListView permission={permission} {...props}/>
        </>
    )

}

export default (compose)(AgendaListContainer)
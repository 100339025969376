// @ts-nocheck
import React from 'react'

const NotificationCountContainer =(props)=>{
  
    const {notificationCount,notificationCountStyle} = props
    return (
        <>      
        {
             !isNaN(notificationCount) ?
            <div style={notificationCountStyle}>
            { notificationCount >99?'99+':notificationCount}
            </div>
            :
            null
        }
      
        </>
    )
}

export default NotificationCountContainer


import React from "react"
import MyTickets from "../components/MyTickets"
import { compose } from "modules/core"
import { ALL_TICKET_LIST, ALL_TICKET_LIST_SUBSCIPTION } from "../graphql/ticket-queries.gql"
import { withApollo } from '@apollo/client/react/hoc';
import { getIntFromString, globalPermissionValidator } from "modules/look";
import { ticket_and_support_permissions } from "Permissions/ticketSupport.permissions";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
const MyTicketContainer = (props) => {
    const { me, client, userPermission } = props
    const [allTickets, setAllTickets] = React.useState([])
    const [ticket_loading, setTicketLoading] = React.useState(false)
    const [permission,setPrmission]=React.useState()
    const [permissionLoader,setpermissionLoader]=React.useState(true)
    const [pagination_info,setPaginationInfo] = React.useState()
    let filter_ref = React.useRef({})
    const mountedRef = React.useRef(true)
    const all_ticket_ref = React.useRef([])
    let ticketsSub = undefined

    // React.useEffect(()=>{
    //     readiness_ref.current = readiness_assessment
    // },[readiness_assessment])

    React.useEffect(() => {
        return () => {
            if (ticketsSub) {
                ticketsSub.unsubscribe();
            }
        }
    })

    React.useEffect(() => {
        return () => {
            mountedRef.current = false
        }
    }, [])

    React.useEffect(() => {
        mountedRef.current = true
    }, [])

    React.useEffect(() => {
        if (userPermission?.length > 0) {
            setpermissionLoader(true)
            let permission = globalPermissionValidator(ticket_and_support_permissions, userPermission)
            setPrmission(permission)
            
            if (permission?.reqiredPermissions) {
                getAllMyTickets({status_In:["Assigned","New","Work in Progress"],offset:0})
            }
            setpermissionLoader(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPermission])
    
    React.useEffect(() => {
        all_ticket_ref.current = allTickets
    }, [allTickets])
    const getAllMyTickets = async (filter) => {
        try {
            filter_ref.current = { ...filter } || {}
            setTicketLoading(true)
            const { data } = await client.query({
                query: ALL_TICKET_LIST,
                variables: { ...filter, raisedBy: getIntFromString(me?.id),first:10 },
                fetchPolicy: 'network-only'
            })
            if (data?.allTickets) {
                setPaginationInfo(
                    {
                        totalCount:data?.allTickets?.totalCount,
                        pageInfo:data?.allTickets?.pageInfo
                    }
                )
                setAllTickets(data?.allTickets?.edges?.map(({ node }) => node))
                setTicketLoading(false)
            }
            ticketsSub = client.subscribe({
                query: ALL_TICKET_LIST_SUBSCIPTION,
                variables: filter
            }).subscribe({
                next(result) {
                    let ticketCacheData = all_ticket_ref.current
                    switch (result.data.ticketSubscription.mutation) {
                        case 'CREATE':
                            const deletedIfExists = ticketCacheData?.filter(item =>
                                item.id === result.data.ticketSubscription.ticket.id
                                    ? false
                                    : true).concat([result.data.ticketSubscription.ticket])
                            if (mountedRef.current) {
                                setAllTickets(deletedIfExists)
                            }
                            break
                        case 'UPDATE':
                            const updated = ticketCacheData?.map(item =>
                                item.id === result.data.ticketSubscription.ticket.id
                                    ? result.data.ticketSubscription.ticket
                                    : item
                            )
                            if (mountedRef.current) {
                                setAllTickets(updated)
                            }
                            break
                        case 'DELETE':
                            const deleted = ticketCacheData?.filter(item =>
                                item.id === result.data.ticketSubscription.ticket.id
                                    ? false
                                    : true)
                            if (mountedRef.current) {
                                setAllTickets(deleted)
                            }
                            break
                        default:
                            break
                    }
                }
            })

        } catch (error) {
            console.error(error)
            setTicketLoading(false)
        }
    }
    return (
        <>  
            {permission && permission?.reqiredPermissions?<MyTickets
                allTickets={allTickets}
                getMyTickets={(filter) =>getAllMyTickets({ ...filter })}
                ticket_loading={ticket_loading}
                permission={permission}
                pagination_info={pagination_info}
                {...props} />:
            (!permissionLoader&&<NoPermissionView />)}
        </>
    )
}

export default compose(withApollo)(MyTicketContainer)    
import { Modal} from "antd";
import { displayUtcToUserTimezone } from "modules/look";
import React from "react";
import modal_close from '../../assets/agenda-close.svg'
// import ShareModal from "../component/shareModalView";

const AwardDetailPopup = ( props ) => {

    const { selectedAward, onClose, visible } = props

    // const [Shared,setShared]=React.useState(false)

    return (
        <>
            <Modal
                closable={false}
                visible={visible}
                destroyOnClose={true}
                footer={false}
                centered={true}
                className="user-badge-award-detail-modal">
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", position: "relative",width:"100%" }}>
                        <img src={modal_close} alt="" style={{ cursor: "pointer", position: "absolute", right: "-5px", top: "-5px" }} onClick={() => onClose( false )} />
                        <h4 className="user-badge-award-popup-title" style={{ fontWeight: "500",margin:"0px" }}>About Kudos </h4>
                        <div className="user-badge-award-popup-overflow" style={{ overflow: 'auto', maxHeight: "450px", padding: "0px 10px 10px 10px",width:"100%" }}>
                            <div className="user-badge-award-popup-inner-container" style={{width:"100%"}}>
                                <div className="user-profile-award-detail-left-container">
                                    <h4 className="user-badge-award-subhead" style={{ fontWeight: "400", whiteSpace: "nowrap" }}>{selectedAward?.badge?.badgeFamily?.title}</h4>
                                    <img src={selectedAward?.badge?.image} alt="" style={{width:"100%"}}/>
                                </div>
                                <div className="user-profile-award-detail-right-container" style={{width:"100%"}}>
                                    <div className="user-profile-award-detail-right-container-inner">
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "end", alignContent: "end", width: "100%" }}>
                                            <h4 className="user-badge-award-selected-description-modal" style={{ color: "#0098F0", fontWeight: "400",marginLeft:"-10px" }}>EXPIRY DATE: {displayUtcToUserTimezone( selectedAward?.expirationDate, 'DD MMM YYYY' )}</h4>
                                        </div>
                                        <h4 className="user-badge-award-selected-title-modal" style={{ margin: 0, fontWeight: "500",paddingTop:"-10px" }}>1. {selectedAward?.badge?.title}</h4>
                                        <p className="user-badge-award-selected-description-modal" style={{paddingLeft:"15px"}}>{selectedAward?.badge?.behaviouralDescription}</p>
                                        <h4 className="user-badge-award-selected-description-modal" style={{ color: "#000000", fontWeight: "500", paddingTop: "10px", paddingBottom: "10px",whiteSpace:"nowrap",paddingLeft:"15px" }}>AWARDED BY:  <span className="text-transform-capitalize">{selectedAward?.issuer?.user?.firstName}</span> <span className="text-transform-capitalize">{selectedAward?.issuer?.user?.lastName}</span></h4>
                                        <p className="user-badge-award-selected-description-modal" style={{paddingLeft:"15px"}}>Rationale: {selectedAward?.evidence}</p>
                                    </div>
                                </div>
                            </div>
                           {/* {!hideShare&&<Row justify="center" align="center" style={{width:"100%",marginTop:"10px"}}>
                                <Button className="save-btn share-kudos-button" style={{ width: "fit-content" }} onClick={()=>shareAward(true)} disabled={true}>SHARE YOUR KUDOS</Button>
                            </Row>} */}
                            
                        </div>
                    </div>
            </Modal>
        </>
    )
}

export default AwardDetailPopup
// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { SUBVERTICAL_AND_TEAMS_UNDER_VERTICAL} from '../graphql/VerticalDashboardQueries';
import { getIntFromString, getVerticalBase64StringFromInt } from 'modules/look';
import SubVerticalAndTeamsView from '../components/SubVerticalAndTeamsview';
const SubVerticalAndTeamsContainer = props => {
  const {me,client,dateRangeTofilter,verticalDetail,setTeam}=props
  const [dataSource, setdataSource] = React.useState();
  const [dataSourceLoader, setdataSourceLoader] = React.useState(true);
  React.useEffect(()=>{
    if(verticalDetail && me && dateRangeTofilter){
        getSubverticalAndTeams(getIntFromString(verticalDetail?.id),true,verticalDetail?.heads?.edges?.map(({node})=>getIntFromString(node?.id)))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[verticalDetail,me,dateRangeTofilter])

  const getSubverticalAndTeams = async(verticalid,addteam=false,managerId)=>{
    setdataSourceLoader(true)
    const { data } = await client.query({
      query: SUBVERTICAL_AND_TEAMS_UNDER_VERTICAL,
      variables: {
        id:getVerticalBase64StringFromInt(verticalid),
        savedOkrType: "OBJECTIVE",
        okr_Duedate_Range: dateRangeTofilter,
        role_In: ["COLLABORATOR", "ASSIGNED"],
        managerId: managerId||[],
        orderBy: ["okr__duedate"]
      },
    //   fetchPolicy: 'network-only'
    });
    if(data?.vertical){
        const vertical = data?.vertical
        if(addteam){
            setTeam(vertical?.teamSet?.edges)
        }
        let sub_vertical_list = vertical?.subvertical?.edges?.map(({node})=>{
            return {
                type:'vertical',
                id:node?.id,
                name:node?.name,
                subvertical:node?.subvertical?.totalCount,
                teamSet:node?.teamSet?.totalCount,
                employeesList:node?.employees,
                progress:calculateProgress(node?.heads?.edges),
                managerId:node?.heads?.edges?.map(({node})=>getIntFromString(node?.id))
            }
        })
        let teams_list =vertical?.teamSet?.edges?.map(({node})=>{
          return {
            type:"team",
            id:node?.id,
            name:node?.name,
            employeeCount:node?.employees?.totalCount,
            managerId:node?.managers?.edges?.map(({node})=>getIntFromString(node?.id))
          }
        })
        let edges=[...sub_vertical_list,...teams_list]
        setdataSource({edges,vertical})
        setdataSourceLoader(false)
        
    }
  }

  const calculateProgress=(userlist)=>{
    let contributors_progress = userlist?.map(({node})=>node?.okrContributorSet?.edges?.map(({node})=>node?.progress))
    contributors_progress = [].concat.apply([],contributors_progress) 
    let progress =contributors_progress.reduce((a, b) => a + b, 0)
    return (!progress || isNaN(progress) || isNaN(progress/contributors_progress?.length)||(progress/contributors_progress?.length)===Infinity)?'0%':`${(progress/contributors_progress?.length)?.toString()?.includes('.')?(progress/contributors_progress?.length).toFixed(2):(progress/contributors_progress?.length)}%`
  }
 
  
  return (
    <>
    <SubVerticalAndTeamsView 
        setdataSourceLoader={(e)=>setdataSourceLoader(e)} 
        dataSourceLoader={dataSourceLoader} 
        dataSource={dataSource}
        getSubverticalAndTeams={(e)=>{getSubverticalAndTeams(e)}}
       {...props}/>
    </>
  );
};

export default compose()(SubVerticalAndTeamsContainer);

// @ts-nocheck
import React from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import { DISCARD_DETAIL_BY_ID } from '../graphql/DiscardRequestById.gql';
import { getDiscardTypeBase64StringFromInt } from 'modules/look';
import DiscardRequestDetailView from '../components/DiscardRequestDetailView';
import { withUpdateDiscardRequest } from './objective-keyresult-operations';
import moment from 'moment-timezone';
import { DISCARD_SUBSCRIPTION_OKR_DETAILS } from '../graphql/DiscardSubscriptionOkr.gql';

const DiscardRequestDetailContainer = (props) => {
    const { client, match } = props
    const [discardData, setDiscardData] = React.useState()
    const [loading, setLoading] = React.useState(false)
    const discard_request_ref = React.useRef()
    const [request_deleted, setRequestDeleted] = React.useState(false)
    React.useEffect(() => {
        if (discardData) {
            discard_request_ref.current = discardData
        }
    }, [discardData])

    let discardSub = undefined;

    React.useEffect(() => {

        localStorage.removeItem('emailRoute')

        return () => {
            if (discardSub) {
                discardSub.unsubscribe();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const DiscardList = async (value) => {
        const { data } = await client.query({
            query: DISCARD_DETAIL_BY_ID,
            variables: value,
            fetchPolicy: 'network-only'
        });
        if (data?.discardRequestById) {
            discardSubscription()
            setDiscardData(data?.discardRequestById)
            setLoading(false)
        }
    }

    const discardSubscription = async () => {
        discardSub = await client.subscribe({
            query: DISCARD_SUBSCRIPTION_OKR_DETAILS,
            // variables: { id: discardedOkrId },
            fetchPolicy: 'network-only'
        }).subscribe({
            next(result) {
                let discarded_sub_detail = result.data?.discardRequestSubscription?.discardRequest
                switch (result.data?.discardRequestSubscription?.mutation) {
                    case 'CREATE':

                        break
                    case 'UPDATE':
                        if (discarded_sub_detail?.id === discard_request_ref.current?.id) {
                            setRequestDeleted(false)
                            let updated_discard_request = discard_request_ref.current
                            updated_discard_request['status'] = discarded_sub_detail?.status
                            updated_discard_request['actorsComment'] = discarded_sub_detail?.actorsComment
                            setDiscardData({ ...updated_discard_request })
                        }
                        break
                    case 'DELETE':
                        if (discarded_sub_detail?.id === discard_request_ref.current?.id) {
                            setRequestDeleted(true)
                        }
                        break
                    default:
                        break
                }
            }
        })
    }

    React.useEffect(() => {
        setLoading(true)
        DiscardList({ id: getDiscardTypeBase64StringFromInt(match?.params?.id), tz: moment.tz.guess() })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match])

    return (
        <DiscardRequestDetailView {...props} request_deleted={request_deleted} discardData={discardData} loading={loading}></DiscardRequestDetailView>
    )

}

export default compose(withApollo, withUpdateDiscardRequest)(DiscardRequestDetailContainer)
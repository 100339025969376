import React from 'react';
import gql from 'graphql-tag';
import { message, Spin, Empty } from 'antd';
import { graphql } from '@apollo/client/react/hoc';

import * as ROUTE from '../../../organization/route';
import { compose } from '../../../core';

import SelectField from './SelectField';
import { Capitalize, getIntFromString } from '../functions';
import { withLookUserState, withLookUserFilterUpdating } from '../../containers/LookOperations';

const ALL_EMPLOYEES_QUERY = gql`
  query allEmployees($nameIcontains: String) {
    allEmployees(nameIcontains: $nameIcontains) {
      edges {
        node {
          id
          organizationSet{
            edges{
              node{
                id
              }
            }
          }
          user {
            id
            username
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const EmployeeField = props => {
  const { allEmployees, loading, deleteEmployee, margin, onNameChange, onFiltersRemove, skipIds = [], coachingConversation, disable_edit, disable_add, default_value_choice, skip_other_org_employee, org_id } = props;
  const handleFilterRemove = React.useRef(() => { });
  const [search_data, SetSearchData] = React.useState()
  handleFilterRemove.current = () => {
    onFiltersRemove();
  };

  React.useEffect(() => {
    return () => handleFilterRemove.current();
  }, []);

  const handleDelete = async id => {
    try {
      await deleteEmployee({ id: id });
      // response ? message.success('Action Successfull!') : message.error('Cannot delete this employee!');
    } catch (e) {
      message.error('Action Failed!');
      throw new Error(e.message);
    }
  };



  const getChoiceList = () => {
    let list = allEmployees?.edges?.filter(({ node }) => {
      return !skipIds.includes(getIntFromString(node.user.id))
    })
      .map(({ node }) => ({
        label: node.user.firstName && node.user.lastName ? `${Capitalize(node.user.firstName)} ${Capitalize(node.user.lastName)}` : node.user.username,
        value: coachingConversation ? [`${node.user.firstName} ${node.user.lastName}`, getIntFromString(node.id), getIntFromString(node.user.id)] : getIntFromString(node.id),
        org_ids: node?.organizationSet?.edges?.map((org) => getIntFromString(org?.node?.id))
      }))
    if (skip_other_org_employee) {
      if (!org_id) {
        list = list?.filter(i => !i?.org_ids?.length)
      }
    }
    let data_list = list?.concat(default_value_choice?.map(i => {
      return {
        label: Capitalize(`${i?.user?.firstName} ${i?.user?.lastName}`),
        value: getIntFromString(i?.id)
      }
    }))
    var setObj = new Set();
    data_list = data_list?.reduce((acc, item) => {
      if (!setObj.has(item?.value)) {
        setObj.add(item?.value)
        acc.push(item)
      }
      return acc;
    }, [])
    let list_data = search_data ? data_list?.filter(i => i?.label?.toLowerCase()?.includes(search_data?.toLowerCase())) : data_list
    return list_data
  } 
  
  const getDefaultChoiceValue = ()=>{
      return default_value_choice?.map(i => {
        return {
          label: Capitalize(`${i?.user?.firstName} ${i?.user?.lastName}`),
          value: getIntFromString(i?.id)
        }
      })
  }

  const choice_list = allEmployees?getChoiceList():getDefaultChoiceValue()

  return (

      <SelectField
        onAdd={!disable_add ? (props.onAdd ? props.onAdd : ROUTE.addEmployee) : null}
        onEdit={!disable_edit ? ROUTE.editEmployeeLink : null}
        onDelete={handleDelete}
        margin={margin}
        showSearch
        choices={choice_list?.length ? choice_list : []}
        style={{ width: !margin && 300, marginBottom: !margin && 0 }}
        // labelInValue
        filterOption={false}
        onSearch={(e) => { onNameChange(e); SetSearchData(e) }}
        onBlur={handleFilterRemove.current}
        notFoundContent={
          loading ? (
            <div align="center">
              <Spin size="small" />
            </div>
          ) : (
            <Empty />
          )
        }
        {...props}
      /> 
  );
};

export default compose(
  withLookUserState,
  graphql(ALL_EMPLOYEES_QUERY, {
    options: ({ filter, orderBy }) => {
      return { variables: { ...filter, orderBy } };
    },
    props({ data: { loading, error, allEmployees, subscribeToMore, updateQuery } }) {
      return { loading, error, allEmployees, subscribeToMore, updateQuery };
    }
  }),
  withLookUserFilterUpdating
)(EmployeeField);

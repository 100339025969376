
import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc'; 
import { CHECK_ORG_COMPARISON } from "../graphql/checkOrgComparison";
import { SURVEY_BY_ID } from "../graphql/surveyById.gql";
import { getSurveyBase64StringFromInt, globalPermissionValidator } from "modules/look";
import NoPermissionView from "modules/kudos-user/component/NoPermissionComponent";
import { user_manual_permissions } from "Permissions/usermanual.permission";
import { Spin } from "antd";
import UserManualMainView from "../components/userManualMainView";

const UserManualMainContainer = (props) => {
    const { client, userPermission } = props

    const [allowComparison, setallowComparison] = React.useState() 
    const [survey_by_id, setSurveyById] = React.useState() 
    const [permission, setPermission] = React.useState()

    React.useEffect(() => {
        if (permission?.required_permission) {
            checkOrgComparison()
           
            surveyById()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission])


    //Permission Check
    React.useEffect(() => {
        if (userPermission?.length) {
            let permission = globalPermissionValidator(user_manual_permissions, userPermission)
            setPermission(permission)
        }
    }, [userPermission])

    const checkOrgComparison = async () => {
      try {
        const { data } = await client.query({
            query: CHECK_ORG_COMPARISON,
            variables: {},
            fetchPolicy: "network-only"
        });
        if (data?.me?.employee?.organizationSet) {
            setallowComparison(data?.me?.employee?.organizationSet?.edges[0]?.node?.allowComparison)
        }
      } catch (error) {
        
      }
    }

    const surveyById = async () => {
        const { data } = await client.query({
            query: SURVEY_BY_ID,
            variables: { id: getSurveyBase64StringFromInt(props.match.params.survey_id) },
            fetchPolicy: "network-only"
        });
        if (data) {
            setSurveyById(data?.SurveyById)
        }
    }

   
 
    return (
        <>
            {
                (permission && permission?.required_permission) && (
                    <UserManualMainView {...props} allowComparison={allowComparison}  survey_by_id={survey_by_id} permission={permission}/>
                )
            }
            {
                (permission && !permission?.required_permission) && (
                    <NoPermissionView />
                )
            }

            {
                !permission && (
                    <div style={{ width: "100%", height: "80vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spin spinning={true}>

                        </Spin>
                    </div>
                )
            }

          
        </>
    )
}

export default compose(withApollo)(UserManualMainContainer);

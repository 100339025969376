import { compose } from "modules/core"
import React from "react"
import AddUserToOrgView from "../components/AddUserToOrgView"
import { withApollo } from '@apollo/client/react/hoc';
import { ALL_ORGANIZATION_FILTER_LIST, ALL_TEAMS, ALL_VERTICALS } from "modules/organization/graphql/AllOrganizationQuery.gql";
import { withAddUserToOrg } from "./UserOperations";
import { Spin, message } from "antd";
import { gql, useMutation } from "@apollo/react-hooks";
import { withAddEmployeeOptimized } from "modules/organization/containers/OrganizationOperation";

const AddUserToOrgContainer = (props) => {
    const { me,client,onsucess,goBack } = props
    const [org_list,setOrgList] = React.useState([])
    const [vertical_list,setVerticalList] = React.useState([])
    const [team_list,setTeamList] = React.useState([])
    const [loading,setloading] = React.useState(false)
    const [member_data,SetMemberData] = React.useState()

    const CREATE_MEMBER = gql`
    mutation createMember {
      ${member_data && member_data
    ?.map(
      (item, index) => {
          return `create${index}: createMember(memberData:{
            memberFrom:{
                ${item?.type}:"${item?.memberFrom}"
            },
            employeeId:"${item?.employeeId}",
            reportingLine:"dotted"
          }) {
                member{
                  id
                }
               }
             `
      }

    )
    .join('')}
    }
    `;

    React.useEffect(()=>{
        if(member_data?.length){
            onSubmit()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[member_data])


// eslint-disable-next-line no-unused-vars
const [createMemberDefinition, { data, error }] = useMutation(CREATE_MEMBER);

    React.useEffect(() => {
        if (me) {
            getOrgList()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me])

    const getOrgList = async(filter) => {
        try {
            const { data } = await client.query({
                query: ALL_ORGANIZATION_FILTER_LIST,
                variables: filter,
                fetchPolicy: 'network-only'
            });
            if(data?.allOrganization){
                let list = data?.allOrganization?.edges?.map(({node})=>node)
                setOrgList(list)
            }
        } catch (error) {
            
        }
    }

    const getVerticalList = async(filter) => {
        try {
            const { data } = await client.query({
                query: ALL_VERTICALS,
                variables: filter,
                fetchPolicy: 'network-only'
            });
            if(data?.verticals){
                let list = data?.verticals?.edges?.map(({node})=>node)
                setVerticalList(list)
            }
        } catch (error) {
            
        }
    }

    const getTeamList = async(filter) => {
        try {
            const { data } = await client.query({
                query: ALL_TEAMS,
                variables: filter,
                fetchPolicy: 'network-only'
            });
            if(data?.Teams){
                let list = data?.Teams?.edges?.map(({node})=>node)
                setTeamList(list)
            }
        } catch (error) {
            
        }
    }

    const onSubmit = async()=>{
        try {
            setloading(true)
            let response = await createMemberDefinition()
            if(response?.data?.create0?.member?.id){
                setloading(false)
                message.success('successfully added user to organization')
                if(goBack){
                    goBack()
                }
                if(onsucess){
                    onsucess({user:member_data[0]})
                }
                
            }else{
                setloading(false)
                message.error('failed to added user to organization')
            }
        } catch (error) {
            setloading(false)
            message.error('failed to added user to organization')
        }
    }

 

    return (
        <>
        <Spin spinning={loading}>
            <AddUserToOrgView org_list={org_list} getOrgList={getOrgList} getVerticalList={getVerticalList} getTeamList={getTeamList} vertical_list={vertical_list} team_list={team_list} setVerticalList={setVerticalList} setTeamList={setTeamList} SetMemberData={SetMemberData} setloading={setloading} {...props} />
            </Spin>
        </>
    )
}

export default compose(withApollo,withAddUserToOrg,withAddEmployeeOptimized)(AddUserToOrgContainer)
import { Button, Col, Modal, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { getIntFromString } from 'modules/layout/Layout';
import React from 'react';
import styled from 'styled-components';

const EditActionItemModal =(props)=>{
    
  const[modalVisible,setModalVisible] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [actionItemId,setActionItemId] = React.useState();
  

    const {visible,setActionItemDetails, actionItemStatus ,setVisible, coachingConversationById, actionItemDetails,newUpdateActionItem, actionItem,setActionItemStatus} = props

    React.useEffect(()=>{
      setModalVisible(visible);     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[visible])

    React.useEffect(()=>{
      if(actionItem){
        setActionItemDetails(actionItem?.details);
        setActionItemId(actionItem?.id);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[actionItem])
  





    const handleEditActionItem = async() =>{
      const response = await newUpdateActionItem({id:getIntFromString(actionItem?.id),details:actionItemDetails,status:actionItemStatus})
      if(response){
        setActionItemDetails(undefined);
        setActionItemId(undefined)
        setVisible(false);
        setModalVisible(false);
       }
    }
  

    return (
       <>
         <EditActionItem visible={modalVisible} footer={false} >
          <div>
              <div align='center' style={{fontSize:'16px',marginBottom:'20px'}}>Related to : <span style={{fontWeight:'600'}}>{coachingConversationById?.relatedTo?.title}</span></div>
              <Row  style={{marginBottom:'20px',marginLeft:'30px'}} >
                <Col xs={10} sm={10} md={10} lg={5}>
                <div>Add Details : </div> 
                </Col>
                <Col span={12}>
                  <div style={{margin:'5px auto',color:'gray', fontSize:'10px'}}>*<span>Optional</span></div>
                  
                <TextArea  value={actionItemDetails} onChange={(e)=>{setActionItemDetails(e?.target?.value)}}></TextArea>
                </Col>
              </Row>
              <Row style={{marginBottom:'20px',marginLeft:'30px'}} align='middle'>
              <Col xs={10} sm={10} md={10} lg={5}>
                <div> Status :</div>
                </Col>
                <Col span={6}>
                <Select value={actionItemStatus} placeholder='Please select a status' 
              onChange={(value,options)=>{setActionItemStatus(value);}}>
                      <Select.Option key='NOT_STARTED'>Not Started</Select.Option>
                      <Select.Option key='IN_PROGRESS'>In Progress</Select.Option>
                      <Select.Option key='FACING_CHALLENGES'>Facing Challenges</Select.Option>
                      <Select.Option key='DISCARDED'>Discarded</Select.Option>
                      <Select.Option key='COMPLETED'>Completed</Select.Option>
                      <Select.Option key='UNSUCCESSFUL'>Unsuccessful</Select.Option>
              </Select>
                </Col>
              </Row>
              <Row justify="center">
                <Button style={{marginRight:'10px',background:'#B0CB1F',color:'#fff'}} onClick={(e)=> handleEditActionItem(e)} >Update</Button>
                <Button onClick={(e)=>setVisible(false)} >Cancel</Button>
              </Row>
          </div>
      </EditActionItem>
       </>
    )
}

export default EditActionItemModal



const EditActionItem = styled(Modal)`


.ant-modal-close-x{
  display:none !important;
}

`  
// @ts-nocheck
import React from "react";
import _ from "lodash";
import {
  Row,
  Button,
  Col,
  Form,
  Modal,
  Steps,
  message,

} from "antd";

import {
  InputValuesObject,
  MetaTags,
  getIntFromString,
  getSurveyBase64StringFromInt,
  getSurveyCache,
  getUniqueListBy,
  removeSurveyCache,
  setSurveyCache,
} from "../../look";

import GenericSurveyView from "modules/survey-look/containers/generic-survey-view";
import quit_icon from '../../assets/quit-survey-icon.svg'
import warning_icon from '../../assets/warning-icon.svg'
import step_icon from '../../assets/stepper-complete-icon.svg'
import stepper_pending from '../../assets/stepper-pending.svg'
import { survey_with_preview, VALUE_SURVEY_ID } from "../../../config";
import SurveyPreview from "modules/survey-look/components/survey-preview";
const SoulSurveySetView = (props) => {
  const {
    data,
    navigateRoute,
  } = props;

  const QuesDiv = {
    margin: 'auto',
    // minHeight: '50vh',
    // maxWidth: '800px',
    width: ' 100%',
    padding: `${props => !props.currentIsMatrix && '24px'}`
  };
  const ref = React.useRef();

  const mobileRef = React.useRef();
  // const currentSectionRef = React.useRef();
  // const showOkRef = React.useRef();
  // eslint-disable-next-line no-unused-vars
  const [selectedq_id, setselectedId] = React.useState()
  const [showOk, setShowOk] = React.useState([false]);
  // eslint-disable-next-line no-unused-vars
  const [timeoutId, setTimeoutId] = React.useState(null);
  const [formValues, setFormValues] = React.useState([]);
  const [startTime, setStartTime] = React.useState(new Date().getTime());
  const [isForw, setIsForw] = React.useState(true);
  const [infoPopup, setInfoPopup] = React.useState(false);
  const [page_count, setPageCount] = React.useState()
  const [currentPage, setCurrentPage] = React.useState(1)
  const [questionValues, setQuestionValues] = React.useState([])
  const [totalquestionLength, setTotalQuestionLength] = React.useState()
  const [allQuestions, setAllquestions] = React.useState([])
  const [quit_survey, setQuitSurvey] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [selected_value_list, setSelectedValueList] = React.useState({})
  const [form] = Form.useForm();
  const Step = Steps.Step;
  const [open_questions, SetOpenQuestion] = React.useState([])
  // const groupSet = SurveyById?.groupSet?.edges

  const [currentSection, setCurrentSection] = React.useState(1);

  const form_values = form.getFieldsValue(true);
  const [preview_detail, SetPreviewDetail] = React.useState()
  React.useEffect(() => {
    if (questionValues?.length) {
      let opened_questions = [questionValues[0]?.node?.id].concat([...open_questions, ...Object.keys(form_values)])
      SetOpenQuestion(opened_questions)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionValues])

  React.useEffect(() => {
    const surveyId = getIntFromString(VALUE_SURVEY_ID);
    const { data } = getSurveyCache(surveyId);
    if (data?.length) {
      setFormValues(data);
      let form_values = {};
      data.forEach(i => {
        form_values[i?.questionId] = i?.answer;
      });
      form.setFieldsValue(form_values)
      setInfoPopup(false)
    }
    else {
      setInfoPopup(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

React.useEffect(() => {
  if (!formValues) return;
  const surveyId = getIntFromString(VALUE_SURVEY_ID);
  setSurveyCache(surveyId, JSON.stringify({ data: formValues }));

}, [formValues]);

React.useEffect(()=>{
  return () => {
      const surveyId = getIntFromString(VALUE_SURVEY_ID);
      removeSurveyCache(surveyId)
  }
},[])

  // eslint-disable-next-line no-unused-vars
  const carouselSettings = {
    accessibility: false,
    // ...carouseSettingChange,
    beforeChange,
    // afterChange,
    // ref,
    ref: mobileRef,
    dotPosition: "left",
    dots: false,
    autoplay: false,
    infinite: false,
  };

  function beforeChange(current) {
    if (!isForw && current === 0) {
      return false;
    }
    // setCurrentSection( isForw ? current + 2 : current );
    // setCurrentSectionFromRef(isForw ? current + 2 : current);
    // setCurrentMatrixQuestion(0);
  }
  // function setCurrentSectionFromRef(value) {
  //   currentSectionRef.current.innerHTML = Number(value);
  // }
  function timeoutFunc(srNo) {
    // eslint-disable-next-line no-unused-vars
    const id =
      setTimeout(() => {
        nextStep(form.getFieldsValue(true));
      }, 500)
  }
  function closetimer() {
    clearTimeout(timeoutId);
  }

  React.useEffect(() => {
    if (data) {
      if (document) {
        if (document.body) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      }
      let question = data?.questions
      setAllquestions(question)
      setTotalQuestionLength(question?.length)
      let page_count = Math.ceil(question?.length / 10)
      setPageCount(page_count)

      setQuestionValues(question.slice(0, 10))
    }
  },
    [data])

  const jumbTonext = (index, values, page_last_question) => {
    if (index) {
      const current_player = document.getElementById(`${index - 1}`)
      if (current_player) {
        current_player.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    }
  }

  const nextStep = (values) => {
    setSelectedValueList(values)
    setIsForw(true);
    if (currentSection !== totalquestionLength) ref.current && ref.current.next()
    closetimer();
    setShowOk(questionValues.map(() => false));
    const endTime = new Date().getTime();
    setCurrentSection(Object.keys(values)?.length + 1)

    let input = [];

    // questionId: getIntFromString(k),
    // eslint-disable-next-line array-callback-return
    Object.keys(values).map((k, i) => {
      input.push({
        questionId: k,
        answer: String(getValue(values[k], k)),
        responseDuration: getResponseDuration(endTime, values[k], k),
      });
    });
    setFormValues(getUniqueListBy([...formValues, ...input], "questionId"));
    setStartTime(endTime);
    let result = getUniqueListBy([...formValues, ...input], "questionId");
    localStorage.setItem(
      "formValues",
      JSON.stringify({ submitValues: result })
    );

    return result
  };

  const goToPrevious = () => {
    let prev_page = currentPage - 1
    let list = allQuestions.slice((prev_page - 1) * 10, ((prev_page - 1) * 10) + 10)
    setQuestionValues(list)
    setCurrentPage(prev_page)
  }

  const gotToNext = () => {
    let index = (currentPage - 1) * 10
    let list_question = allQuestions.slice(index, index + 10)?.map(({ node }) => node?.id)
    let page_form_valid = true
    let values = { ...form_values }
    Object.entries(values).forEach(i => {
      let unwanted = [undefined, null, ""]
      if (unwanted?.includes(i[1])) {
        delete values[i[0]]
      }
    })

    list_question.forEach(i => {
      if (!Object.keys(values).includes(i)) {
        page_form_valid = false
      }
    })
    if (page_form_valid) {
      goToNextPage(currentPage)
      if (document) {
        if (document.body) {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      }
    }
    else {
      let not_answered = list_question?.find(i => !Object.keys(values).includes(i))
      let not_answered_index = list_question?.indexOf(not_answered)
      message.error('Please Answer all the questions')
      let opened_questions = [not_answered].concat(open_questions)
      SetOpenQuestion(opened_questions)
      jumbTonext(((currentPage - 1) * 10) + not_answered_index + 1)
    }
  }

  const goToNextPage = (currentPage) => {
    if (currentPage < page_count) {
      let nextPage = currentPage + 1
      if (nextPage === page_count) {
        let nextStart = currentPage * 10
        setQuestionValues(allQuestions.slice(nextStart, allQuestions?.length))
        setCurrentPage(nextPage)
      }
      else {
        let nextStart = currentPage * 10
        setQuestionValues(allQuestions.slice(nextStart, nextStart + 10))
        setCurrentPage(nextPage)
      }

    }
  }

  function getValue(value, name) {
    return value || 0;
  }

  function getResponseDuration(endTime, value, k) {
    let time = 0;
    const existingValue = formValues.filter(
      (f) => f.questionId === getIntFromString(k)
    )[0];

    time = existingValue
      ? existingValue?.answer === "" || existingValue?.answer === undefined
        ? value !== "" || value !== undefined
          ? 0
          : (endTime - startTime) / 1000
        : existingValue?.responseDuration
      : value === "" || value === undefined
        ? 0
        : (endTime - startTime) / 1000;

    return time;
  }

  const navigateTo = () => {
    if (onInvalidSubmit()) {
      if ((!survey_with_preview || !survey_with_preview?.length || survey_with_preview?.includes(VALUE_SURVEY_ID))) {
 
        SetPreviewDetail(formValues?.map(i=>{
          return {
            ...i,
            questionId:getIntFromString(i?.questionId)
          }
        }))
        if (document) {
          if (document.body) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
          }
        }
      } else {
        navigateRoute("/soul/values/survey/submit/3");
      }

    }
  };

  // eslint-disable-next-line no-unused-vars
  const prevStep = () => {
    if (currentSection === 0) {
      return false;
    }
    closetimer();
    setIsForw(false);
    // ref.current.prev();
    mobileRef.current.prev();
    // if (document) {
    //   if (document.body) {
    //     document.body.scrollTop = 0;
    //     document.documentElement.scrollTop = 0;
    //   }
    // }
  };
  const quitSurvey = () => {
    localStorage.removeItem('selectedSoulValue')
    localStorage.removeItem('formValues')
    navigateRoute("/soul/values")
  }


  const customDot = (icon, { status }) => {
    switch (status) {
      case 'process':
        return <span className="custom-dot custom-dot-process"><img src={step_icon} alt="" style={{ width: "25px", marginLeft: "-5px" }} /></span>;
      case 'wait':
        return <span className="custom-dot custom-dot-finish"><img src={stepper_pending} alt="" style={{ width: "18px", marginLeft: "-2px" }} /></span>;
      default:
        return <span className={`custom-dot custom-dot-${status}`}><img src={stepper_pending} alt="" style={{ width: "18px", marginLeft: "-2px" }} /></span>;
    }
  };

  const onAnswerChange = (id, value, type) => {
    let index = (currentPage - 1) * 10
    let list_question = allQuestions.slice(index, index + 10)?.map(({ node }) => node?.id)
    let index_of_question = list_question?.indexOf(id)
    if (index_of_question >= 0) {
      let next_question = list_question[(index_of_question + 1)]
      if (next_question && !form_values[next_question]) {
        let opened_questions = [next_question].concat(open_questions)
        SetOpenQuestion(opened_questions)
        jumbTonext(((currentPage - 1) * 10) + index_of_question + 2)
      }
    }

    if (type === InputValuesObject.range && value === 0) {
      form.setFieldsValue(
        _.set(
          form.getFieldsValue(true),
          id,
          value
        )
      )
    }

    if (type === InputValuesObject.range) {
      let input = [
        {
          questionId: id,
          answer: String(value),
          responseDuration: 0
        }
      ]
      setFormValues(getUniqueListBy([...formValues, ...input], "questionId"));
    }
  }


  const onInvalidSubmit = () => {
    let index = (currentPage - 1) * 10
    let list_question = allQuestions.slice(index, index + 10)?.map(({ node }) => node?.id)
    let page_form_valid = true
    let values = { ...form_values }
    Object.entries(values).forEach(i => {
      let unwanted = [undefined, null, ""]
      if (unwanted?.includes(i[1])) {
        delete values[i[0]]
      }
    })
    list_question.forEach(i => {
      if (!Object.keys(values).includes(i)) {
        page_form_valid = false
      }
    })
    if (!page_form_valid) {
      let not_answered = list_question?.find(i => !Object.keys(values).includes(i))
      let not_answered_index = list_question?.indexOf(not_answered)
      message.destroy()
      message.error('Please Answer all the questions')
      let opened_questions = [not_answered].concat(open_questions)
      SetOpenQuestion(opened_questions)
      jumbTonext(((currentPage - 1) * 10) + not_answered_index + 1)
    }
    return page_form_valid
  }

  const RetakeSurvey = () => {
    setFormValues([])
    localStorage.removeItem('formValues')
    form.resetFields()
    let list = allQuestions.slice(0, 10)
    setQuestionValues(list)
    setCurrentPage(1)
    SetPreviewDetail(null) 
    if (document) {
      if (document.body) {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }

  } 
  return (
    <>
      <MetaTags title="soul" description="This is survey page." />

      <Modal
        footer={null}
        centered
        visible={/* true || */ infoPopup}
        width={"90%"}
        destroyOnClose={true}
        onCancel={() => setInfoPopup(false)}
        closable={false}
        style={{ maxWidth: "600px" }}
      //  bodyStyle={{ border: '0px' }}
      >
        <Row justify="center" align="center" style={{ flexDirection: "column", width: "100%", padding: "1rem", gap: "10px", justifyContent: 'center', alignItems: 'center' }}>
          <h3 className="generic-survey-quit-title">Congratulations! You've identified your top 10 values</h3>
          <div>
            <h3 className="generic-survey-quit-para">The next step is to narrow the top 10 down to top 5, so it's easier for you to remember your values and be more intentional about them. To identify your top 5 values, you'll be completing a survey.</h3>
            <span className="generic-survey-quit-para" style={{ fontWeight: "bold", paddingTop: "16px", textAlign: "left" }}>Instructions: </span><br />
            <ol style={{ marginTop: ".5em" }}>
              <li className="generic-survey-quit-para">Read each statement and click the scale to indicate how strongly you agree with the statement.</li>
              <li className="generic-survey-quit-para" style={{ marginTop: ".75em" }}>0 = you do not agree at all; 10 = you completely agree with the statement.</li>
            </ol>
          </div>
          <Row justify="center" align="center" style={{ marginTop: '1em' }}>
            <button
              className="generic-survey-submit-btn"
              onClick={() => setInfoPopup(false)}
            >
              Ok
            </button>
          </Row>

        </Row>
      </Modal>
      <Form
        form={form}
        name={"value"}
        // layout={'vertical'}
        onFinish={(values) =>
          (Object.keys(form_values)?.length === totalquestionLength && currentPage === page_count)
            ? navigateTo(nextStep(values))
            : nextStep(values)
        }

      // requiredMark={false}
      // scrollToFirstError={true}
      // initialValues={initialValues}
      >
        <Row style={{ padding: "0rem 2rem", marginTop: "1rem" }} justify='space-between' align='center'>
          <h3 className="generic-survey-assessment-heading">
            Assessment
          </h3>
          {/* <Tooltip title={'Shortcuts'}>
            <img src={survey_shotcuts} alt=''style={{cursor:"pointer"}} onClick={() => setInfoPopup( true )} />
          </Tooltip> */}
        </Row>
        {
          !preview_detail ?
            <>
              <Col style={{ width: '100%' }}>
                <div style={QuesDiv}>
                  <div className='generic-survey-skil-stepper'>
                    <Steps direction="vertical" current={currentPage > 1 ? (currentSection - 1) - ((currentPage - 1) * 10) : currentSection - 1} progressDot={customDot}>
                      {questionValues?.map((grpSet, idx) => (
                        <Step className='step-sroll-transition' key={currentSection - 1} id={((currentPage - 1) * 10) + idx} description={
                          <GenericSurveyView
                            onAnswerChange={(id, value, type) => { onAnswerChange(id, value, type) }}
                            key={((currentPage - 1) * 10) + idx}
                            srNo={(((currentPage - 1) * 10) + idx) + 1}
                            form={form}
                            formValues={formValues}
                            form_values={form_values}
                            currentSection={currentSection}
                            sectionLabel="value"
                            sectionSequence={grpSet.node?.sequence}
                            timeoutFunc={timeoutFunc}
                            closetimer={closetimer}
                            selectedId={e => setselectedId(e)}
                            nextStep={(e) => nextStep(form.getFieldsValue(true), e)}
                            questionList={[grpSet.node]}
                            showOk={showOk[currentSection - 1]}
                            is_survey_soul={true}
                            open_questions={open_questions}
                            setShowOk={(e) =>
                              setShowOk(
                                questionValues.map((q, qi) =>
                                  e ? qi === currentSection - 1 : false
                                )
                              )
                            }
                          />}
                        />
                      ))}
                      <Step />
                    </Steps>
                  </div>
                </div>
              </Col>
              <Row className='generic-survey-skil-stepper' justify='space-between' align='center'>
                <Button className='generic-survey-quit-btn' style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px", marginTop: "10px", cursor: "pointer" }} onClick={() => setQuitSurvey(true)}>
                  <img src={quit_icon} alt="" />
                  Quit survey
                </Button>
                <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                  {
                    currentPage > 1 && (
                      <Button className='generic-survey-next-btn' onClick={() => goToPrevious()}>
                        PREVIOUS
                      </Button>
                    )
                  }
                  {currentPage !== page_count ? <Button className='generic-survey-next-btn' onClick={() => gotToNext()}>
                    NEXT
                  </Button> :
                    <button className='generic-survey-action-btn' htmlType="submit" onClick={() => { onInvalidSubmit() }}>
                      {(!survey_with_preview || !survey_with_preview?.length || survey_with_preview?.includes(VALUE_SURVEY_ID)) ? "PREVIEW" : "NEXT"}
                    </button>}
                </div>
                <div></div>
              </Row>
            </> : <SurveyPreview preview_detail={preview_detail} survey={{id:getSurveyBase64StringFromInt(VALUE_SURVEY_ID)}} survey_question_list={allQuestions?.map(({node})=>node)} {...props} RetakeSurvey={RetakeSurvey}  onSubmit={()=>{navigateRoute("/soul/values/survey/submit/3")}} action_btn_label="NEXT"/>
        }

      </Form>
      <Modal
        footer={null}
        style={{ padding: 10, borderRadius: "20px", maxWidth: "600px" }}
        visible={quit_survey}
        closable={false}
        width='100%'
        centered={true}
      >
        <Row justify='center' align='center' style={{ padding: "1rem", width: "100%", flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
          <img src={warning_icon} alt="" />
          <h3 className="generic-survey-quit-title" style={{ marginTop: "1rem" }}>Are you sure you want to exit this survey ?</h3>
          {/* <p className="generic-survey-quit-para">We will save all your current responses. you can choose to continue from here the next time you start this survey.</p> */}
          <Row justify="center" align="center" >
            <button className="generic-survey-remove-btn" style={{ margin: "5px" }} onClick={() => setQuitSurvey(false)}>NO</button>
            <button className="generic-survey-submit-btn" style={{ margin: "5px" }} onClick={() => quitSurvey()}>YES</button>
          </Row>
        </Row>
      </Modal>
    </>
  );
};
export default SoulSurveySetView;

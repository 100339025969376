// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc'
import TranscriptionSummaryView from '../components/TranscriptionSummaryView'
import { MEETING_BY_ID_TRANSCRIPTION_DATA } from '../graphql/meetingByidTranscriptionData.gql';
import { getIntFromString } from 'modules/look';
import { getItem } from "../../../modules/core/clientStorage";
import { ACCESS_TOKEN, TRANSCRIPTION_SERVER_URI } from '../../../config'
const TranscriptionSummaryContainer = props => {
    const { client, meetingById } = props
    const [transcription_loading, SetTranscriptionLoading] = useState(false)
    const [hasNextPage, SethasNextPage] = useState(false)
    const [search, setSearch] = useState("")
    let next_page_info = useRef({ page: 1 })
    let transcription_list_ref = useRef([])
    let played_audio_ref = useRef([])
    let aduio_cache_ref = useRef({})
    const [transcription_list, SetTranscriptionList] = useState([])
    const [download_list, Setdownload_list] = useState([])
    useEffect(() => {
        if (meetingById) {
            next_page_info.current = { page: 1 }
            getTranscriptionData({ page: 1, textIcontains: search })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meetingById, search])

    const getTranscriptionData = async (filter) => {
        try {
            SetTranscriptionLoading(true)
            const { data } = await client.query({
                query: MEETING_BY_ID_TRANSCRIPTION_DATA,
                variables: { ...filter, id: meetingById?.id },
            });
            if (data?.meetingById?.transcription) {
                let list = generateData(data?.meetingById?.transcription?.page)
                if (filter.page > 1) {
                    list = transcription_list_ref.current.concat(list)
                }
                list = removeDuplicate(list)
                transcription_list_ref.current = list
                SetTranscriptionList(list)
                // if (list.length) {
                //     cached_audio(list[0].chunk_id)
                // }
                if (data?.meetingById?.transcription?.pageInfo?.hasNextPage) {
                    SethasNextPage(true)
                    next_page_info.current = { page: filter?.page + 1 }
                } else {
                    SethasNextPage(false)
                }
                SetTranscriptionLoading(false)
            }
        } catch (error) {
            SetTranscriptionLoading(false)
        }
    }

    const generateData = (data) => {
        let cached_audio = aduio_cache_ref.current
        let available_transcription = data?.filter(item => item?.transcribedText)
        let members = meetingById?.member?.edges.map(({ node }) => node)
        let list = available_transcription?.map((item, i) => {
            let user_detail = members?.find(emp => getIntFromString(emp?.user?.id) === Number(item?.userId))
            return {
                user_id: user_detail?.user?.id,
                profile_image: user_detail?.user?.profile?.profileImage || null,
                employee_id: user_detail?.id,
                firstName: user_detail?.user?.firstName || 'User',
                lastName: user_detail?.user?.lastName,
                message: item?.transcribedText,
                audio: cached_audio[`chunk_${item?.id}`] || null,
                requsted_date: item?.requestedAt,
                chunk_id: item?.id
            }
        })

        return list || []
    }

    const cached_audio = (chunk_id) => {
        let cached_audio = aduio_cache_ref.current
        if (cached_audio[`chunk_${chunk_id}`]) {
            let list = transcription_list_ref.current
            let doc = list.find(item => String(item?.chunk_id) === String(chunk_id))
            doc.audio = cached_audio[`chunk_${chunk_id}`]
            SetTranscriptionList([].concat(list))
        } else if (!download_list.includes(chunk_id)) {
            getAudioFile(chunk_id)
        }
    }

    const getAudioFile = async (chunk_id) => {
        let token = await getItem(ACCESS_TOKEN)
        Setdownload_list(download_list.concat([chunk_id]))
        try {
            fetch(`${TRANSCRIPTION_SERVER_URI}/audio?chunkId=${chunk_id}`, {
                method: 'GET',
                headers: {
                    Authorization: `JWT ${token}`,
                }
            })
                .then((res) => res.arrayBuffer())
                .then((response) => {
                    let audio_list = ['audio/m4a', 'audio/wav', 'audio/aac', 'audio/mp3', 'audio/aif']
                    var audioContext = new AudioContext();
                    let moded_array = response?.slice(0)
                    // eslint-disable-next-line no-unused-vars
                    var buffer = audioContext.decodeAudioData(moded_array).then(value=>{
                        let audio_url_list = audio_list?.map(item => create_audiofile(response, item))
                        let list = transcription_list_ref.current
                        let doc = list.find(item => String(item?.chunk_id) === String(chunk_id))
                        doc.audio = audio_url_list
                        doc.duration=value?.duration || 8
                        aduio_cache_ref.current[`chunk_${chunk_id}`] = audio_url_list
                        SetTranscriptionList([].concat(list))
                        // var source = audioContext.createBufferSource();
                        // source.buffer = value;
                        // source.connect(audioContext.destination);
                        // source.start(0);
                    });
                
                })
                .catch((err) => {
                    console.error('catch err api', err);
                })
        } catch (error) {
            console.error('catch error file api', error);
        }
    };

    const create_audiofile = (response, type) => {
        const audioBlob = new Blob([response], { type });
        const audioUrl = URL.createObjectURL(audioBlob);
        return {
            url: audioUrl,
            type
        }
    }

    const removeDuplicate = (list) => {
        var setObj = new Set();
        let unique_list = list?.reduce((acc, item) => {
            if (!setObj.has(item?.chunk_id)) {
                setObj.add(item?.chunk_id)
                acc.push(item)
            }
            return acc;
        }, [])
        return unique_list.map((item, i) => { return { ...item, index: i + 1 } })
    }

    const pagination = () => {
        if (hasNextPage && !transcription_loading) {
            getTranscriptionData({ page: next_page_info.current.page, textIcontains: search })
        }

    }

    return (
        <TranscriptionSummaryView search={search} setSearch={(e) => setSearch(e)} transcription_loading={transcription_loading} transcription_list={transcription_list} next_page_info={next_page_info.current} hasNextPage={hasNextPage} pagination={pagination} cached_audio={cached_audio} download_list={download_list} {...props} played_audio_ref={played_audio_ref.current} />
    )
}

export default compose(withApollo)(TranscriptionSummaryContainer);

import { withDeleteContributor } from "modules/Objective-keyresult/container/objective-keyresult-operations";
import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc';
import { OKR_CONTRIBUTOR_LIST_QUERY } from "modules/Objective-keyresult/graphql/OKRContributor.qgl";
import { ASSIGNE_UNDER_ORG } from "../graphql/AssigneeUnderORG.gql";
import { ASSIGNE_VERTICAL } from "../graphql/MyVerticals.gql";
import { ASSIGNE_ALL_VERTICAL } from "../graphql/AllVerticals.gql";
import { ASSIGNE_MY_TEAM } from "../graphql/MyTeam.gql";
import { ASSIGNE_ALL_TEAMS } from "../graphql/AllTeam.gql";
import { COLLAB_UNDER_ORG } from "../graphql/CollabForOrg.gql";
import { COLLAB_VERTICAL } from "../graphql/CollabMyVerticals.gql";
import { COLLAB_ALL_VERTICAL } from "../graphql/CollabAllVerticals.gql";
import { COLLAB_MY_TEAM } from "../graphql/CollabMyTeam.gql";
import { COLLAB_ALL_TEAMS } from "../graphql/CollabAllTeam.gql";
import IndDashboardExpDropDown from "../Components/IndDashboardExpDropDown";

const IndDashboardDropDownContainer = (props) => {
    const { client, me,okrId } = props
    const [loadingcollab,setloadingcollab]= React.useState(true)
    const [allokrweights,setokrweights]= React.useState()
    const [collaborator,setcollaborator]= React.useState()
    const [assignes,setassignes]= React.useState()
  


    

    const getOKRContributorList=async ()=>{
        setloadingcollab(true)
        const { data } = await client.query({
          query:OKR_CONTRIBUTOR_LIST_QUERY,
          fetchPolicy: 'network-only',
          variables: {okr:okrId,role_In:["COLLABORATOR", "ASSIGNED"]} 
        });
        if(data?.contributors){
            setokrweights(data?.contributors)
            setloadingcollab(false)
        }
    }

    const selectCollabQuery=(type)=>{
        if(type==='My_Organization'){
            return COLLAB_UNDER_ORG
        }
        else if(type==='My_Vertical'){
            return COLLAB_VERTICAL
        }
        else if(type==="All_Vertical"){
            return COLLAB_ALL_VERTICAL
        }
        else if(type==="My_Team"){
            return COLLAB_MY_TEAM
        }
        else if(type==="All_Teams"){
            return COLLAB_ALL_TEAMS
        }
        
    }

    const getcollaboratorList=async (filter)=>{
        const { data } = await client.query({
          query:selectCollabQuery(filter?.type),
          variables:filter
        //   fetchPolicy: 'network-only',
        });
        if(data?.collab){
            if(filter?.type==='My_Organization'){
                collabMyOrg(data?.collab)
            }
            else if(filter?.type==='My_Vertical'){
                collabMyVertical(data?.collab)
            }
            else if(filter?.type==="All_Vertical"){
                collabALLVertical(data?.collab)
            }
            else if(filter?.type==="My_Team"){
                CollabMyTeam(data?.collab)
            }
            else if (filter?.type==="All_Teams"){
                collabALLTeams(data?.collab)
            }
        }
    }

    const collabMyOrg =(res)=>{
        let orgCeoEmployee =res?.employee?.orgCeoEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let orgPocEmployee =res?.employee?.orgPocEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let list =[].concat.apply([],[...orgCeoEmployee,...orgPocEmployee])
        var setObj = new Set();
        var result = list.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])
        setcollaborator(result)
    }

    const collabMyVertical =(res)=>{
        let verticalMembersEmployee =res?.employee?.verticalMembersEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let list =[].concat.apply([],[...verticalMembersEmployee])
        var setObj = new Set();
        var result = list.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])
        setcollaborator(result)
    }

    const collabALLVertical =(res)=>{
        let allVerticalEmployees =res?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let list =[].concat.apply([],[...allVerticalEmployees])
        var setObj = new Set();
        var result = list.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])

        setcollaborator(result)
    }

    const CollabMyTeam =(res)=>{
        let teamMembersEmployee =res?.employee?.teamMembersEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let list =[].concat.apply([],[...teamMembersEmployee])
        var setObj = new Set();
        var result = list.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])
        result = result?.filter(user=>user?.id!==me?.employee?.id)

        setcollaborator(result)
    }

    const collabALLTeams =(res)=>{
        let allTeamEmployees =res?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let list =[].concat.apply([],[...allTeamEmployees])
        var setObj = new Set();
        var result = list.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])

        setcollaborator(result)
    }
    
    const selectAssigneQuery=(type)=>{
        if(type==='My_Organization'){
            return ASSIGNE_UNDER_ORG
        }
        else if(type==='My_Vertical'){
            return ASSIGNE_VERTICAL
        }
        else if(type==="All_Vertical"){
            return ASSIGNE_ALL_VERTICAL
        }
        else if(type==="My_Team"){
            return ASSIGNE_MY_TEAM
        }
        else if(type==="All_Teams"){
            return ASSIGNE_ALL_TEAMS
        }
        
    }
    const getassigneList=async (filter)=>{
        const { data } = await client.query({
          query:selectAssigneQuery(filter?.type),
          variables:filter
        //   fetchPolicy: 'network-only',
        });
       
        if(data?.assignes){
           
            // setassignes(data?.assignes)
            if(filter?.type==='My_Organization'){
                assigneMyOrg(data?.assignes)
            }
            else if(filter?.type==='My_Vertical'){
                assigneMyVertical(data?.assignes)
            }
            else if(filter?.type==="All_Vertical"){
                assigneALLVertical(data?.assignes)
            }
            else if(filter?.type==="My_Team"){
                assigneMyTeam(data?.assignes)
            }
            else if (filter?.type==="All_Teams"){
                assigneALLTeams(data?.assignes)
            }
        }
    }

    const assigneMyOrg =(res)=>{
        let orgCeoEmployee =res?.employee?.orgCeoEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let orgPocEmployee =res?.employee?.orgPocEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let list =[].concat.apply([],[...orgCeoEmployee,...orgPocEmployee])
        var setObj = new Set();
        var result = list.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])
        result = result?.filter(user=>user?.id!==me?.employee?.id)
        setassignes(result)
    }

    const assigneMyVertical =(res)=>{
        let verticalHeadEmployee =res?.employee?.verticalHeadEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let list =[].concat.apply([],[...verticalHeadEmployee])
        var setObj = new Set();
        var result = list.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])
        result = result?.filter(user=>user?.id!==me?.employee?.id)
        setassignes(result)
    }

    const assigneALLVertical =(res)=>{
        let allVerticalEmployees =res?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let list =[].concat.apply([],[...allVerticalEmployees])
        var setObj = new Set();
        var result = list.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])
        result = result?.filter(user=>user?.id!==me?.employee?.id)
        setassignes(result)
    }

    const assigneMyTeam =(res)=>{
        let teamManagerEmployee =res?.employee?.teamManagerEmployee?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let list =[].concat.apply([],[...teamManagerEmployee])
        var setObj = new Set();
        var result = list.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])
        result = result?.filter(user=>user?.id!==me?.employee?.id)

        setassignes(result)
    }

    const assigneALLTeams =(res)=>{
        let allTeamEmployees =res?.edges?.map(({node})=>node?.employees?.edges?.map(({node})=>node))
        let list =[].concat.apply([],[...allTeamEmployees])
        var setObj = new Set();
        var result = list.reduce((acc,item)=>{
          if(!setObj.has(item?.id)){
            setObj.add(item?.id)
            acc.push(item)
          }
          return acc;
        },[])
        result = result?.filter(user=>user?.id!==me?.employee?.id)
        setassignes(result)
    }


 
   

    return (
        <>
            <IndDashboardExpDropDown
                allokrweights={allokrweights} 
                collaborator={collaborator} 
                searchcollab={(e)=>{getcollaboratorList(e)}} 
                setokrweights={(e)=>{setokrweights(e)}} 
                loadingcollab={loadingcollab} 
                setloadingcollab={(e)=>setloadingcollab(e)} 
                getOKRContributorList={(e)=>{getOKRContributorList()}}
                assignes={assignes}
                searchassignes={(e)=>{getassigneList(e)}}
                {...props}
            />
        </>
    );
};

export default compose(withApollo,withDeleteContributor)(IndDashboardDropDownContainer);

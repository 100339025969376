import { Button, Col, Form, Row, Spin, Switch } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import React from 'react';
import { Capitalize, CardLayout, getIntFromString, SelectField, SurveyAutoComplete, TextField } from '../../look';
import '../css/SurveyToken.css';
import ROUTE from '../route';

const SurveyTokenView = props => {
    const { allEmployees, createSourceResponse, getSurveyResponsesByUser, responses, responsesForToken,
        loading, setLoading, getAllEmployees, user_detail, getUserProfile, setUserDetail } = props;

    const [form] = Form.useForm();
    const [respondent, setrespondent] = React.useState(undefined);
    const [selectedEmployee, setSelectedEmployee] = React.useState(undefined);
    const [selectedEmployeeDetail, setSelectedEmployeeDetail] = React.useState(undefined);
    const [responseSourceForId, setResponseSourceForId] = React.useState(undefined);
    const [invite, setInvite] = React.useState(false);
    const [allResponses, setallResponses] = React.useState(undefined);
    const [selectedResponse, setSelectedResponse] = React.useState(undefined);
    const [choices, setChoices] = React.useState([]);
    const [choicesEmployee, setChoicesEmployee] = React.useState([]);
    const [respondentChoices, setRespondentChoices] = React.useState([{ label: 'manager', value: 'manager' }, { label: 'manager of manager', value: 'manager of manager' },
    { label: 'report', value: 'report' }])

    React.useEffect(() => {
        if (user_detail) {
            form.setFieldsValue({
                firstName: Capitalize(user_detail?.firstName),
                lastName: Capitalize(user_detail?.lastName),
            })
        } else {
            form.setFieldsValue({
                firstName: '',
                lastName: '',
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_detail])

    React.useEffect(() => {

        if (allEmployees) {
            let employees =

                allEmployees &&
                allEmployees?.length > 0 &&
                allEmployees?.map((node) => ({
                    label: `${Capitalize(node?.user?.firstName)} ${Capitalize(node?.user?.lastName)}`,
                    value: node?.user?.id,
                    employee_id: node?.id
                }));
            setChoicesEmployee(employees)
        }


    }, [allEmployees])


    React.useEffect(() => {

        if (allResponses) {
            let data =

                allResponses &&
                allResponses?.length > 0 &&
                allResponses?.map((node) => ({
                    label: node.name,
                    value: getIntFromString(node.id),
                    response_id: node?.id
                }));

            setChoices(data)

        }

    }, [allResponses])


    // on submission of form values
    const onSubmit = async (values) => {

        let formFields = form.getFieldsValue()

        let data = {
            firstName: Capitalize(formFields?.firstName),
            lastName: Capitalize(formFields?.lastName),
            surveyId: formFields?.surveyId,
            email: formFields?.email,
            invite: invite,
            message: formFields?.message,
            respondentType: respondent,
            responseSourceForId: responseSourceForId,
            createdById: getIntFromString(selectedEmployeeDetail?.value)
        }

        setLoading(true)

        const response = await createSourceResponse(data);

        if (response) {
            form.resetFields();
            setrespondent(undefined);
            setSelectedEmployee(undefined);
            setUserDetail(null)
            setInvite(false)
            setLoading(false)
        }


    }


    React.useEffect(() => {

        if (responses) {
            setallResponses([...responses])
        }

    }, [responses])



    React.useEffect(() => {
        // responseSourceForId will be set when responses for specific survey are received and latest 
        // response will be sent for creating the token

        if (responsesForToken) {
            setResponseSourceForId(getIntFromString(responsesForToken[responsesForToken?.length - 1]?.node?.id))
        }

    }, [responsesForToken])

    React.useEffect(() => {

        if (selectedEmployee && !selectedResponse) {
            getSurveyResponsesByUser({ user: selectedEmployee, first: 12 })

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEmployee, selectedResponse])



    const searchRespondentype = (e) => {


        if (e?.length) {
            let data = respondentChoices?.filter((value) => value?.label.includes(e));
            setRespondentChoices(data)
        } else {
            let resetData = [{ label: 'manager', value: 'manager' }, { label: 'manager of manager', value: 'manager of manager' },
            { label: 'report', value: 'report' }]

            setRespondentChoices(resetData)
        }

    }


    const selectEmployee = (e) => {
        setSelectedEmployee(e)
        setChoices([])
        setResponseSourceForId(null)
        let emp = choicesEmployee?.find(i => String(i?.value) === String(e))
        if (emp) {
            setSelectedEmployeeDetail(emp)
        }
    }

    const selectResponse = (e) => {
        setSelectedResponse(e)
        if (selectedEmployee) {
            let survey = choices?.find(i => String(i?.value) === String(e))
            if (survey) {
                getSurveyResponsesByUser({ user: selectedEmployee, first: 1, survey: survey?.response_id })
            }

        }
    }

    const fetchUserDetail = (email) => {
        if (email?.includes('@')) {
            getUserProfile(email)
        } else {
            setUserDetail(null)
        }
    }
    return (
        <CardLayout
            table={true}
            active={ROUTE.survey}
            title={[{ name: '360 Survey' }]}>

            <Spin spinning={loading} size="large">






                <div style={{ minHeight: '70vh' }}>

                    <Form form={form}
                        layout="vertical" onFinish={values => onSubmit(values)} scrollToFirstError={true}>

                        <div style={{ marginBottom: '20px', fontSize: '16px', fontWeight: '600' }}>From:</div>
                        <Row justify="start" align="middle">

                            <Col span={12}>

                                <Form.Item className="select-employee-survey" style={{ width: '80%' }} name="employees" label="Employee" >
                                    <SelectField
                                        showSearch
                                        choices={choicesEmployee}
                                        onChangeSelect={e => { selectEmployee(e) }}
                                        filterOption={false}
                                        allowClear
                                        onSearch={(e) => { getAllEmployees({ nameIcontains: e, user_LastName: e }) }}
                                    // {...props}
                                    />

                                </Form.Item>
                            </Col>


                            <Col span={12}>

                                <Form.Item style={{ width: '80%' }} name="responsesByUser" label="Survey Responses" >

                                    <SelectField
                                        showSearch
                                        choices={choices}
                                        onChangeSelect={e => { selectResponse(e) }}
                                        // labelInValue
                                        filterOption={false}
                                        allowClear
                                        onSearch={(e) => getSurveyResponsesByUser({ user: selectedEmployee, first: 12, survey_Name_Icontains: e })}
                                    // {...props}
                                    />

                                </Form.Item>

                            </Col>

                        </Row>




                        <Form.Item style={{ width: '90%' }} name="message" label="Message" >
                            <TextArea  ></TextArea>
                        </Form.Item>


                        <div style={{ marginBottom: '20px', fontSize: '16px', fontWeight: '600' }}>To:</div>

                        <Row>
                            <Col span={12} offset={1}>

                                <Row>
                                    <Col span={14}>

                                        <TextField
                                            name="email"
                                            label="E-mail"
                                            rules={[
                                                {
                                                    type: "email",
                                                    message: "The input is not valid E-mail!"
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (
                                                            (value === undefined && getFieldValue("mobileNumber") === undefined) ||
                                                            (value === "" && getFieldValue("mobileNumber") === "")
                                                        ) {
                                                            return Promise.reject(new Error("Either email or mobile number is required!"));
                                                        }

                                                        return Promise.resolve();
                                                    }
                                                })
                                            ]}
                                            onChange={(e) => { fetchUserDetail(e?.target?.value) }}
                                            className='token-input-field'
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={14}>
                                        <TextField
                                            className='token-input-field'
                                            name="firstName"
                                            label="First Name"
                                            rules={[{ required: true, message: "firstName is required!", whitespace: true }]}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={14}>
                                        <TextField
                                            name="mobileNumber"
                                            label="Mobile Number"
                                            className='token-input-field'
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (
                                                            (value === undefined && getFieldValue("email") === undefined) ||
                                                            (value === "" && getFieldValue("email") === "")
                                                        ) {
                                                            return Promise.reject(new Error("Either email or mobile number is required!"));
                                                        }
                                                        if (value !== undefined && !(value.match('[0-9]{10}'))) {
                                                            return Promise.reject(new Error("Please provide valid phone number"));
                                                        }
                                                        return Promise.resolve();
                                                    }
                                                })
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={10}>
                                <Row>
                                    <Col span={24}>
                                        <SurveyAutoComplete
                                            name="surveyId"
                                            className='token-input-field'
                                            onChange={(e) => { /* setSurvey(e)*/ }}
                                            label="Survey"
                                            rules={[{ required: true, message: "survey is required!" }]}
                                            onAdd={"add"}
                                            onEdit={"edit"}
                                            margin={true}
                                            allowClear
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={14}>
                                        <TextField
                                            name="lastName"
                                            className='token-input-field'
                                            label="Last Name"
                                            rules={[{ required: true, message: "lastName is required!", whitespace: true }]}
                                        />
                                    </Col>
                                </Row>
                                <Row>

                                    <Col span={24}>
                                        <Form.Item className="select-employee-survey" style={{ width: '80%' }} name="responseType" label="Response Type" >

                                            <SelectField
                                                showSearch
                                                choices={respondentChoices}
                                                onChangeSelect={e => { setrespondent(e) }}
                                                // labelInValue
                                                filterOption={false}
                                                allowClear
                                                onSearch={(e) => searchRespondentype(e)}
                                            />
                                        </Form.Item>
                                    </Col>

                                </Row>

                            </Col>
                            <Col span={3} />

                        </Row>
                        <Form.Item style={{ marginBottom: '30px' }} name="invite" label="Invite" valuePropName="checked">
                            <Switch checked={invite} onChange={(e) => setInvite(!invite)} />
                        </Form.Item>
                        <Button className="poppins" style={{ width: '110px', height: '40px', borderRadius: '8px', background: '#8aa611', fontSize: '14px', color: '#fff' }} htmlType="submit">Submit</Button>
                    </Form>
                </div>
                <br />
            </Spin>
        </CardLayout>
    );
};

export default SurveyTokenView;